import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ManageProfilesComponent } from '../manage-profiles/manage-profiles.component';

@Component({
  selector: 'app-manage-members',
  templateUrl: './manage-members.component.html',
  styleUrls: ['./manage-members.component.scss']
})

export class ManageMembersComponent implements OnInit {
	public global_group:any;
	meta:any;
	orgs:any;
	orgID:number;
	modal:any;
	count:number;
	orgName:any;
	checkcount:number;
	group:any;
	membersByPage:number;
	query_string:string;
	isLoading:boolean;
	pageLen:number;
	curPage:any;
	item:any;
	current:number;
    name:any;


  	constructor(public manageProfiles: ManageProfilesComponent, public http: HttpClient, public modalService: NgbModal) { }

  	ngOnInit() {
        console.log('mm component')
  		this.current = 0;
	  	this.membersByPage = 5;
	  	this.isLoading = true;
	  	this.query_string = environment.profileURL+`/api/v1/organizations.json?per_page=5`;	  	
	  	this.http.get(`${environment.profileURL}/api/v1/organizations.json?per_page=5`).subscribe(data => {
            console.log(data);
            this.orgs = data['organizations'];
            this.meta = data['meta']; 
            this.count = this.meta.total_count;
            this.isLoading = false;
           	this.pageLen = this.meta.total_pages;
           	this.curPage = 0;
           	this.item = [];
           	for(var i = 1; i<=this.pageLen;i++){
			    this.item.push(i);
			}
			if(this.meta.total_count > this.membersByPage){
				this.render( this.curPage, this.item, true );
	            console.log(data);
	        }           
        },
            error => console.log('oops', error)
        )
        let items= localStorage.getItem('globalGroups')
        if(items) {
            this.global_group = JSON.parse(items);
        }
  	}

  	public gotoTeam(index){
  		this.current = 0;
  		this.orgID = index;
  	}

  	public closeOrg(){
  		this.orgID = null;
  	}

  	public open(){
        // this.modal = this.modalService.open(content, { windowClass: 'in grey' });
        $('.orgs-modal').css('display','block');
    }

    public close() {
        $('.orgs-modal').css('display', 'none');
    }
    
    public addOrg(){
    	var data = {
    		name:this.orgName
    	};
    	this.http.post(`${environment.profileURL}/api/v1/organizations.json`,data).subscribe(result => {
            console.log(result);
            $('.modal-dialog').addClass('hide');
            $('body').removeClass('modal-open');
            this.orgs.push(result['organization']);
            this.count += 1;
            this.close();
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.success('The Org was added successfully!', 'Nice!');
            }, 1000);

        },
            error => {
                console.log('oops', error)
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 2000
                    };
                    this.toastr.error(error.error.errors, 'Oops!');
                }, 1000);
            }
        )
    }

    public checkAll(){
    	var select = jQuery('#select_all:checkbox:checked').length > 0;
        if(select == true){
            jQuery('.checkedbox').prop('checked',true);
            this.checkedbox();
        }
        else{
            jQuery('.checkedbox').prop('checked',false);
            this.checkedbox();
        }
    }

    public checkedbox(){
    	this.group = [];
    	this.checkcount = 0;
    	var that = this
        jQuery(".checkedbox:checkbox:checked").each(function() {
            var value=jQuery(this).val();
            console.log(value);
        	for(var i=0;i<that.global_group.length;i++){
                if(that.global_group[i].id == value){
                    that.checkcount += 1;
                    that.group.push({
                        id: that.global_group[i].id,
			          	name: that.global_group[i].name,
			          	email: that.global_group[i].email,
			          	organization: that.global_group[i].organization,
			          	picture_url: that.global_group[i].picture_url,
			          	default_profile: that.global_group[i].default_profile
                    });
            	}
            }
        })
        console.log(this.group);
    }

    public removeGroupMember(index){
    	this.global_group.splice(index, 1);
    	this.checkedbox();
    	localStorage.setItem('globalGroups',JSON.stringify(this.global_group));
    }

    public isPageInRange = function( curPage, index, maxPages, pageBefore, pageAfter ) {
        if (index <= 1) {
	        // first 2 pages
	        return true;
        }
        if (index >= maxPages - 2) {
	        // last 2 pages
	        return true;
        }
        if (index >= curPage - pageBefore && index <= curPage + pageAfter) {
	        return true;
        }
	}
	public render = function( curPage, item, first ) {
        var html = '', separatorAdded = false;
        for(var i in item){
            if ( this.isPageInRange( curPage, i, this.pageLen, 2, 2 ) ) {
                html += '<li data-page="' + i + '">' + item[i] + '</li>';
                // as we added a page, we reset the separator Added
                separatorAdded = false;
            } else {
                if (!separatorAdded) {
                    // only add a separator when it wasn't added before
                    html += '<li class="separator" />';
                    separatorAdded = true;
                }
            }
        }
        var holder = document.querySelector('#holder');  
        if(this.meta.total_count > this.membersByPage){
            holder.innerHTML = html;
            if(document.querySelector('#holder>li[data-page="' + curPage + '"]') != null){document.querySelector('#holder>li[data-page="' + curPage + '"]').classList.add('active');}
            if ( first ) {
                holder.addEventListener('click', e => {
                    console.log(e.target['attributes']['data-page'].value);
                    if (!e.target['attributes']['data-page'].value) {
                        // no relevant item clicked (you could however offer expand here )
                        return;
                    }

                    curPage = parseInt(e.target['attributes']['data-page'].value);
                    var page_number = curPage+1;
                    this.isLoading = true;
                    this.http.get(`${this.query_string}&page=${page_number}`).subscribe(data => {
                    	console.log(data);
                        this.orgs = data['organizations'];
                		this.meta = data['meta']; 
                		this.count = this.meta.total_count;
                        this.pageLen = this.meta.total_pages;
                        this.isLoading = false;
                        this.curPage = 0;
                        this.item = [];
                        for(var i = 1; i<=this.pageLen;i++){
                            this.item.push(i);
                        }

                    },
                        error => console.log('oops', error)
                    )
                    this.render( curPage, item );
                });
            }
        }else{
            holder.innerHTML = null;
        }
    }

    public Filter(){
        this.orgs = [];
    	this.query_string = environment.profileURL+`/api/v1/organizations.json?per_page=5`;
        this.name = jQuery('#orgName').val();
        if(this.name){
            this.query_string += '&name=' + this.name;
        }        
        this.isLoading=true;
        console.log(this.query_string);
        this.http.get(`${this.query_string}`).subscribe(data => {
            this.orgs = data['organizations'];
            this.meta = data['meta']; 
            this.count = this.meta.total_count;
            this.pageLen = this.meta.total_pages;
            this.curPage = 0;
            this.item = [];
            this.isLoading = false;
            for(var i = 1; i<=this.pageLen;i++){
                this.item.push(i);
            }
            console.log(true);
        	this.render( this.curPage, this.item, true );
        	console.log(data);        
        },
            error => console.log('oops', error)

        )
    }

    public resetOrgName() {
        let orgName =  jQuery('#orgName').val('');
        if(orgName.length == 0 || orgName.length >= 3) {
            this.Filter();
        }
    }

    public teams(){
    	this.current = 0;
    }

    public sessions(){
    	this.current = 1;
    }

}
