import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute,Router, Params } from '@angular/router';
import { CustomPfService } from '../custom-pf.service';
import { environment } from './../../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-custom-dashboard',
  templateUrl: './custom-dashboard.component.html',
  styleUrls: ['./custom-dashboard.component.scss']
})
export class CustomDashboardComponent implements OnInit {

  env;
  profiles:any;
  @Output() contentChange = new EventEmitter();
  @ViewChild('activate_pp') activatePopup;
  fs_account:boolean;
  assessment_img:string;
  modal;
  ngrxTcwaProfile: any;

  constructor(private http:HttpClient, public activatedRoute:ActivatedRoute, public customPfService:CustomPfService,private router:Router,private modalService: NgbModal,public store: Store<any>) { }

  ngOnInit(): void {
    this.ngrxTcwaProfile = this.store.select<any>((state) => state.profile.tcwaProfile)
    var that = this;
    this.env = environment;

    this.ngrxTcwaProfile.subscribe(res => {
      this.profiles = res ['profile'];
      this.fs_account = this.profiles?.active_account;
      console.warn(res)
      if(res?.status == 404 || res?.profile) {
        $('.loader-container').css('display','none');
      }
    })

    this.http.get(`${this.env.assessment_admin}api/v1/public/products.json`).subscribe(data => {
      console.log(data['products'][0]?.image_url)
      this.assessment_img = data['products'][0]?.image_url
    })
  }

  // update content view value
  public changeContent(content, scroll = false){
    this.contentChange.emit(content);
    if(scroll == true) {
    	setTimeout(function() {
					$('html,body').animate({
			            scrollTop: $('.fn-profile.section-padding').offset().top},
			        'slow');
			}, 800);
    }
  }

  // activate you account() 
  public claim(type= '') {
    if(type == 'log in') {
      this.router.navigate(['/login']);
    } else {
      this.router.navigate(['/signup']);
    }
  }


}
