import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { environment } from './../../../../../environments/environment';
// import { Angulartics2Segment } from 'angulartics2';
import { Location } from '@angular/common';

declare var jQuery:any;

@Component({
  selector: 'app-public-store-cart',
  templateUrl: './public-store-cart.component.html',
  styleUrls: ['./public-store-cart.component.scss']
})
export class PublicStoreCartComponent implements OnInit {

	public cart:any;
	public discounted_items_price:any;
	public cartId:any;
	listMembers:any;
	@Output() cartChange = new EventEmitter<any>();

  	constructor(public location: Location, public http:HttpClient) { }

  	ngOnInit() {
  		if(localStorage.getItem('cart_id')){
  			this.cartId = localStorage.getItem('cart_id');
	  		this.http.get(`${environment.assessment_admin}api/v1/public/carts/`+this.cartId).subscribe(data => {
	            console.log(data);
	            this.cart = data['order']['line_items'];
	            this.cartChange.emit({data :data['order']['line_items']});
				this.discounted_items_price = data['order']['discounted_items_price'];
				var products = [];
				for(var i= 0; i< this.cart.length;i++){
					products.push({
	            		product_id: this.cart[i].variant.product_id,
						sku: this.cart[i].variant.sku,
						name: this.cart[i].variant.name,
						price: this.cart[i].variant.price,
						image_url: this.cart[i].variant.image_url
	            	})
				}
				// this.angulartics2Segment.eventTrack('Cart Viewed', 
	            //     { 
	            //     	cart_id: this.cartId,
	            //         products: products
	            //     }
	            // );
	        },
	            error => {
	            	this.showError(error);
				}
	        )
	  	}else{
	  		this.cart = [];
	  	}
  	}

  	public remove(id){
		this.http.get(`${environment.assessment_admin}api/v1/public/carts/`+this.cartId+`/remove_item?variant_id=${id}`).subscribe(data => {
            console.log(data);
            this.cart = data['order']['line_items'];
            this.cartChange.emit({data :data['order']['line_items']});
			this.discounted_items_price = data['order']['discounted_items_price'];
			// this.angulartics2Segment.eventTrack('Product Removed', 
            //     { 
            //     	cart_id: this.cartId,
            //         product_id: id
            //     }
            // );
        },
            error => {
            	this.showError(error);
			}
    	)
	}

	public updatePrice(nmbr){
		if(jQuery('#'+nmbr).val() != ''){
			var counter = 0;
	    	this.listMembers = [];
	    	console.log($('#'+nmbr).val());
			this.cart.forEach(list =>{
				if(nmbr == list.variant.id){
					if(list.organization_id != null){
						this.listMembers[counter] = { 'variant_id': parseInt(list.variant.id), 'quantity': parseInt(jQuery('#'+nmbr).val()), 'organization_id': parseInt(list.organization_id) };
						counter++;
					}else{
						this.listMembers[counter] = { 'variant_id': parseInt(list.variant.id), 'quantity': parseInt(jQuery('#'+nmbr).val()) };
						counter++;
					}

				}else{
					if(list.organization_id != null){
						this.listMembers[counter] = { 'variant_id': parseInt(list.variant.id), 'quantity': parseInt(list.quantity), 'organization_id': parseInt(list.organization_id) };
						counter++; 
					}else{
						this.listMembers[counter] = { 'variant_id': parseInt(list.variant.id), 'quantity': parseInt(list.quantity) };
						counter++;
					}
				}   		  			
			});
			this.http.post(`${environment.assessment_admin}api/v1/public/carts/`+this.cartId+`/update_items`,{"line_items":this.listMembers}).subscribe(data => {
	            console.log(data);
	            this.cart = data['order']['line_items'];
	            this.cartChange.emit({data :data['order']['line_items']});
				this.discounted_items_price = data['order']['discounted_items_price'];
	        },
	            error => {
	            	this.showError(error);
				}
	    	)
		}
	}

	public emptyCart(){
		this.http.get(`${environment.assessment_admin}api/v1/public/carts/`+this.cartId+`/empty`).subscribe(data => {
            console.log(data);
            this.cart = data['order']['line_items'];
            this.cartChange.emit({data :data['order']['line_items']});
			this.discounted_items_price = data['order']['discounted_items_price'];
        },
            error => {
            	this.showError(error);
			}
    	)
	}

	public showError(error){
    	console.log('oops', error);
    	if(error.status == 500){
			setTimeout(function() {
				this.toastr.options = {
				  closeButton: true,
				  timeOut: 2000
				};
				this.toastr.error('Something went wrong!', 'Sorry!');
			}, 1000);
		}
		else if(error.status == 422){
			setTimeout(function() {
				this.toastr.options = {
				  closeButton: true,
				  timeOut: 4000
				};
				if(error.error.errors.number[0]){
					this.toastr.error(error.error.errors.number[0], 'oops!');
				}
				if(error.error.errors.base[0]){
					this.toastr.error(error.error.errors.base[0], 'oops!');
				}
				if(!error.error.errors.base[0] && !error.error.errors.number[0]){
					this.toastr.error(error.error.errors, 'oops!');
				}
			}, 1000);
		}
		else{
			setTimeout(function() {
				this.toastr.options = {
				  closeButton: true,
				  timeOut: 2000
				};
				this.toastr.error('Something went wrong!', 'oops!');
			}, 1000);
		}

  	}

}
