import { Component, OnInit } from '@angular/core';
import { TeamReportServiceService } from './../team-report-service.service';

@Component({
  selector: 'app-team-together',
  templateUrl: './team-together.component.html',
  styleUrls: ['./team-together.component.scss']
})
export class TeamTogetherComponent implements OnInit {
	together:any;

  	constructor(public service: TeamReportServiceService) { }

  	ngOnInit() {
  		this.together = this.service.together;
  	}

}
