import { Component, OnInit } from '@angular/core';
import { TeamReportServiceService } from './../team-report-service.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-four-preferences',
  templateUrl: './four-preferences.component.html',
  styleUrls: ['./four-preferences.component.scss']
})
export class FourPreferencesComponent implements OnInit {
	fourpreferences:any;
	items:any;
	item:any;
	env:any;

  	constructor(public service: TeamReportServiceService) { }

  	ngOnInit() {
  		this.fourpreferences = this.service.fourpreferences;
  		this.env = environment;
  	}

}
