import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as auth0 from 'auth0-js';
import {AUTH_CONFIG} from './auth0-variables'
import { Observable, Observer, timer, of } from 'rxjs';
import { environment } from './../../environments/environment';
import { mergeMap } from 'rxjs/operators';
import {Cache} from '@aws-amplify/cache';

@Injectable()
export class Auth {

    auth0 = new auth0.WebAuth({
        clientID: AUTH_CONFIG.clientID,
        // domain: AUTH_CONFIG.auth0Domain,
        domain: AUTH_CONFIG.domain,
        // overrides: {
        //     __tenant: AUTH_CONFIG.auth0Tenant,
        //     __token_issuer: AUTH_CONFIG.auth0Domain
        // },
        responseType: 'token id_token',
        audience: AUTH_CONFIG.apiUrl,
        redirectUri: AUTH_CONFIG.callbackURL,      
        scope: 'openid profile email',
        prompt: 'none'
        // connection:'Username-Password-Authentication'
    });
    userProfile:any;
    refreshSubscription: any;
    observer: Observer<boolean>;
    ssoAuthComplete$: Observable<boolean> = new Observable(
        obs => (this.observer = obs)
    );

    constructor(public router: Router) {}

    public login(): void {
        this.auth0.authorize();
    }

    public signup(): void {
        this.auth0.authorize({
            mode: 'signUp'
        });
    }

    public handleAuthentication(): void {
        this.auth0.parseHash({ __enableImpersonation: true}, (err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                // window.location.hash = '';
                this.setSession(authResult);
                localStorage.setItem('FS login', 'true');                
                this.router.navigate(['/dashboard']);
            } else if (err) {
                localStorage.setItem('FS login', 'true');
                console.log(err);
            }
        });
    }

    public getProfile(cb): void {
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
            throw new Error('Access token must exist to fetch profile');
        }
        const self = this;
        this.auth0.client.userInfo(accessToken, (err, profile) => {
            if (profile) {
                localStorage.setItem('picture_url',profile.picture);
                localStorage.setItem('auth_id',profile.sub);
                if(profile.email == localStorage.getItem('email')){
                    
                }else{
                    var names=[];
                    localStorage.removeItem('email')
                    localStorage.setItem('email',profile.email);
                }
                self.userProfile = profile;
                console.log(profile);
            }
            cb(err, profile);
        });
    }

    private setSession(authResult): void {
        // Set the time that the access token will expire a
        var names=[];
        const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
        localStorage.setItem('access_token', authResult.accessToken);
        localStorage.setItem('id_token', authResult.idToken);
        localStorage.setItem('expires_at', expiresAt);
        localStorage.setItem('impersonation', 'false');
        var temp = {
            token:  authResult.idToken
        }
        Cache.setItem('federatedInfo', temp);
        this.scheduleRenewal();
    }

    public logout(): void {
        // Remove tokens and expiry time from localStorage
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('impersonation');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('customer_id');
        localStorage.removeItem('nav_fields');
        localStorage.removeItem('app_type');
        localStorage.removeItem('member_id');
        localStorage.removeItem('picture_url');
        localStorage.removeItem('team_id');
        localStorage.removeItem('organization_id');
        localStorage.removeItem('first_name');
        localStorage.removeItem('last_name');
        localStorage.removeItem('childGroup');
        localStorage.removeItem('globalGroups');
        localStorage.removeItem('intent_secret');
        localStorage.removeItem('auth_id');
        localStorage.removeItem('email');
        localStorage.removeItem('FS login');
        localStorage.removeItem('store');
        this.unscheduleRenewal();

        // Go back to the home route
        this.router.navigate(['/']);
    }

    public isAuthenticated(): boolean {
        // Check whether the current time is past the
        // access token's expiry time
        const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
        return new Date().getTime() < expiresAt;
    }

    public getToken(){
        return localStorage.getItem('id_token');
    }

    public renewToken() {
        this.auth0.renewAuth({
            audience: AUTH_CONFIG.apiUrl,
            redirectUri: environment.redirectURL,
            usePostMessage: true,
            postMessageDataType: 'auth0:silent-auth'
        },
        (err, result) => {
            if (err) {
                alert(
                    `After 45 minutes of inactivity, we log you out to protect your account. Sorry for the inconvenience. Feel free to log right back in.`
                );
                this.login();
            } else {
                this.setSession(result);
                this.observer.next(true);
            }
        });
    }

    
    public scheduleRenewal() {
        if (!this.isAuthenticated()) return;
        this.unscheduleRenewal();
        const expiresAt = JSON.parse(window.localStorage.getItem('expires_at'));
        const source = of(expiresAt).pipe(mergeMap(expiresAt => {
            const now = Date.now();
            // Use the delay in a timer to
            // run the refresh at the proper time
            return timer(Math.max(1, expiresAt - now));
        }));
        // Once the delay time from above is
        // reached, get a new JWT and schedule
        // additional refreshes
        this.refreshSubscription = source.subscribe(() => {
            // this.renewToken();
            this.scheduleRenewal();
        });
    }

    public unscheduleRenewal() {
        if (!this.refreshSubscription) return;
        this.refreshSubscription.unsubscribe();
    }

}