import { Component } from '@angular/core';
import { smoothlyMenu } from '../../../app.helpers';
declare var jQuery:any;

@Component({
    selector: 'topnavbar',
    templateUrl: 'topnavbar.template.html'
})
export class TopNavbarComponent {

    toggleNavigation(): void {
        jQuery("body").toggleClass("mini-navbar");
        smoothlyMenu();
    }

    changeLanguage(lang): void {
        console.log(lang);
        // window.document.locale = lang;
        // location.reload(true);
    }
    public minimalize = function () {
        $('body').toggleClass('mini-navbar');
        if (!$('body').hasClass('mini-navbar') || $('body').hasClass('body-small')) {
            // Hide menu in order to smoothly turn on when maximize menu
            $('#side-menu').hide();
            // For smoothly turn on menu
                setTimeout(function () {
                    $('#side-menu').fadeIn(500);
                }, 100);
        } else {
            // Remove all inline style from jquery fadeIn function to reset menu state
            $('#side-menu').removeAttr('style');
        }

        if($('.owl-carousel').length){
            setTimeout(function () {
                var owl = $(".owl-carousel").data('owlCarousel');
                owl.updateVars();
            }, 500);
        }

    };

}
