import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-cn-print-intro',
  templateUrl: './cn-print-intro.component.html',
  styleUrls: ['./cn-print-intro.component.scss']
})
export class CnPrintIntroComponent implements OnInit {

  public modal:any;
  public env:any;
  constructor(private modalService: NgbModal, public aroute: ActivatedRoute) { }

  ngOnInit(): void {
    this.env = environment;
  }

  public open(content){
      this.modal = this.modalService.open(content, { windowClass: 'in grey' });
  }

}
