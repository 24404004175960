import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { LambdaFunctionService } from '../../../service/lambda-function.service';
import { TeamReportServiceService } from '../../team-report/team-report-service.service';

@Component({
  selector: 'app-selected-group-profile-plus',
  templateUrl: './selected-group-profile-plus.component.html',
  styleUrls: ['./selected-group-profile-plus.component.scss']
})
export class SelectedGroupProfilePlusComponent implements OnInit {
  id:any;
  members:any;
  teamName:any;
  orgName:any;
  clarifier_low:number;
  clarifier_high:number;
  ideator_low:number;
  ideator_high:number;
  developer_low:number;
  developer_high:number;
  implementor_low:number;
  implementor_high:number;
  integrator:number;
  highest_score:number;
  app_type:any;
  pdf_type:any;
  download_profile:any;
  Email:any;
  today_date:any;
  orgId:any;
  args:any;
  name:string= 'fs';
  organization_title= 'Organization';
  Date;

  constructor(public activatedRoute:ActivatedRoute, public router: Router, public http: HttpClient,public teamReportService: TeamReportServiceService,public lmda_service: LambdaFunctionService) { }

  ngOnInit(): void {
    this.today_date = new Date();
    this.download_profile = false;

    this.getGroupProfileResult();    

  }

  // get group profile data
  public getGroupProfileResult() {
    let allProfiles = ['','Clarifier','Ideator','Developer','Implementer','Early-bird','Analyst','Accelerator','Theorist','Driver','Finisher','Hare','Idea-broker','Optimist','Integrator','Realist'];
    var len= this.teamReportService.teamSelectedArr.length;
            var clNeu = [];
            var clLow = [];
            var clHigh = [];
            var idNeu = [];
            var idLow = [];
            var idHigh = [];
            var deNeu = [];
            var deLow = [];
            var deHigh = [];
            var imNeu = [];
            var imLow = [];
            var imHigh = [];
            var integrator = [];
            var date  = new Date();
            // var highContast = this.teamReportService.teamSelectedArr[0]?.high_contrast;
            var highContast = 0;
            // this.teamReportService.teamSelectedArr.forEach(item => {
            //     if(item?.high_contrast) {
            //         highContast = item?.high_contrast;
            //     }
            // });
            console.log(this.teamReportService.teamSelectedArr);
            for(let i = 0 ; i < len; i++) {
                let pf = allProfiles[this.teamReportService.teamSelectedArr[i]?.profile_type_id]
                console.log(pf)
                // if(highContast > this.teamReportService.teamSelectedArr[i]?.high_contrast) {
                //     highContast = this.teamReportService.teamSelectedArr[i]?.high_contrast;
                // }
                if(pf == "Clarifier") {
                    if(this.teamReportService.teamSelectedArr[i]?.clarifier_score == 'High') {
                        clHigh.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                    } else if(this.teamReportService.teamSelectedArr[i]?.clarifier_score == 'Neutral') {
                        clNeu.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                    } else {
                        clLow.push(this.teamReportService.teamSelectedArr[i]?.first_name)
                    }
                } else  if(pf == "Ideator") {
                    if(this.teamReportService.teamSelectedArr[i]?.ideator_score == 'High') {
                        idHigh.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                    } else if(this.teamReportService.teamSelectedArr[i]?.ideator_score == 'Neutral') {
                        idNeu.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                    } else {
                        idLow.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                    }
                } else  if(pf == 'Developer') {
                    if(this.teamReportService.teamSelectedArr[i]?.developer_score == 'High') {
                        deHigh.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                    } else if (this.teamReportService.teamSelectedArr[i]?.developer_score == 'Neutral') {
                        deNeu.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                    } else {
                        deLow.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                    }
                } else if(pf == 'Implementer') {
                    if(this.teamReportService.teamSelectedArr[i]?.implementer_score == 'High') {
                        imHigh.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                    } else if(this.teamReportService.teamSelectedArr[i]?.implementer_score == 'Neutral') {
                        imNeu.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                    } else {
                        imLow.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                    }
                }  else if(pf == "Integrator") {
                    integrator.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                } else {
                    if(this.teamReportService.teamSelectedArr[i]?.clarifier_score == 'High') {
                        clHigh.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                    }
                    if(this.teamReportService.teamSelectedArr[i]?.ideator_score == 'High') {
                        idHigh.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                    } 
                    if(this.teamReportService.teamSelectedArr[i]?.developer_score == 'High') {
                        deHigh.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                    }
                    if(this.teamReportService.teamSelectedArr[i]?.implementer_score == 'High') {
                        imHigh.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                    }
                }
            }
            this.id = this.teamReportService.teamSelectedArr[0]?.team_id;
            this.members = len;
            this.teamName = this.teamReportService.teamSelectedArr[0]?.team_name
            this.orgName = this.teamReportService.teamSelectedArr[0]?.organization_name
            this.clarifier_low= clLow.length;
            this.clarifier_high = clHigh.length;
            this.ideator_low = idLow.length;
            this.ideator_high = idHigh.length;
            this.developer_low = deLow.length;
            this.developer_high = deHigh.length;
            this.implementor_low = imLow.length;
            this.implementor_high = imHigh.length;
            this.integrator = integrator.length;
            highContast = this.clarifier_low;
            if(this.clarifier_high > highContast) {
                highContast = this.clarifier_high
            }
            if(this.ideator_high > highContast) {
                highContast = this.ideator_high
            }
            if(this.ideator_low > highContast) {
                highContast = this.ideator_low
            }
            if(this.developer_high > highContast) {
                highContast = this.developer_high
            }
            if(this.developer_high > highContast) {
                highContast = this.developer_high
            }
            if(this.implementor_high > highContast) {
                highContast = this.implementor_high
            }
            if(this.implementor_low > highContast) {
                highContast = this.implementor_low
            }
            if(highContast == undefined) {
                highContast = 1;
            }
            console.warn(highContast)
            this.highest_score = highContast;
            this.args = {
                "function_name": "team-profile-dev-pdf",
                "invocation_type": "RequestResponse",
                "log_type": "None",
                "id": this.teamReportService.teamSelectedArr[0]?.team_id,
                "template": "team_pf.html",
                "filename": "team-pdf",
                "memberships_count": len,
                "name": "05 Feb 2023",
                "organization_title": "Organization",
                "organization_id": this.teamReportService.teamSelectedArr[0]?.organization_id,
                "organization_name": this.teamReportService.teamSelectedArr[0]?.organization_name,
                "clarifier_low": clLow.length,
                "clarifier_neutral": clNeu.length,
                "clarifier_high": clHigh.length,
                "ideator_low": idLow.length,
                "ideator_neutral": idNeu.length,
                "ideator_high": idHigh.length,
                "developer_low": deLow.length,
                "developer_neutral": deNeu.length,
                "developer_high": deHigh.length,
                "implementor_low": imLow.length,
                "implementor_neutral": imNeu.length,
                "implementor_high": imHigh.length,
                "integrator": integrator.length,
                "highest_score": highContast,
                "app_type": "Group Profile Plus",
                "pdf_type": "letter",
                "email": "sarah@blairmiller.com",
                "today_date": date,
                "content_type": "pdf",
                "profile_type": "Ask",
                "description": "Look at your graph. What do you notice? What, if anything, is surprising? Where does this group need to pay deliberate attention so they can succeed? Think of a project or a challenge this group has recently been engaged in. Describe how the group approached the project using the group profile as a guide.",
                "footer_imptext": "FourSightÂ® Group Profile",
                "footer_text": " The chart above shows the high and low thinking preferences of your group. Because neutral preferences are hidden, the numbers in each vertical bar may not add up to the group total.",
                "remember": "Ask",
                "team_des": "FourSight is a measure of thinking preference, not ability. A preference is a measure of energy, not talent. People with a high preference tend to gravitate to that stage of the problem-solving process. People with a low preference tend to move quickly through it. People with a neutral preference tend to do the work and move on. All three approaches can lead to problem-solving success. Teams are more likely to succeed when people are both self-aware and process aware.",
                "footer_des": null,
                "team_profiles": {
                    "clarifier_high": clHigh,
                    "clarifier_neutral": clNeu,
                    "clarifier_low": clLow,
                    "ideator_high": idHigh,
                    "ideator_neutral": idNeu,
                    "ideator_low": idLow,
                    "developer_high": deHigh,
                    "developer_neutral": deNeu,
                    "developer_low": deLow,
                    "implementer_high": imHigh,
                    "implementer_neutral": imNeu,
                    "implementer_low": imLow
                }
            }
                console.warn(this.args)
                // this.lmda_service.lambda_invoke('team-profile-dev-pdf',this.args);
  }

  // download group profile pdf
  public downloadGroupProfilePdf() {
    $('.pdf-modal').css('display', 'flex');
      this.lmda_service.lambda_invoke('team-profile-dev-pdf',this.args,'Group Profile Plus');
        // $('.team-loader').css('display', 'block');
        // setTimeout(() =>{
        //     $('.team-loader').css('display', 'none');
        // },2000)
  }

}
