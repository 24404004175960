import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-challenges-nav',
  templateUrl: './challenges-nav.component.html',
  styleUrls: ['./challenges-nav.component.scss']
})
export class ChallengesNavComponent implements OnInit {

  constructor(public router:Router) { }

  ngOnInit(): void {
  }

  activeRoute(routename: string): boolean{
    return this.router.url.indexOf(routename) > -1;
  }

}
