import { Component, OnInit } from '@angular/core';
import { Auth } from '../../auth/auth.service';

// import { AuthService } from '@auth0/auth0-angular';

import { Router } from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    constructor(public auth: Auth, public router: Router) {}

    ngOnInit() {
        if(this.router.url == '/logout'){
            this.auth.logout();
        }else{
            if (this.auth.isAuthenticated() === true) {
                // this.auth.login();
                this.router.navigate(['/dashboard']);
            } else {
                if(this.router.url.indexOf('signup') != -1){
                    this.auth.signup();
                }else{
                    this.auth.login();
                }
                // this.auth.renewToken();
                
            }
        }
    }
}
