import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CnEditmodeService {
  editmode = new BehaviorSubject(false);

  constructor() { }
}
