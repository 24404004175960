import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { environment } from './../../../../environments/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-innovation-introduction',
  	templateUrl: './innovation-introduction.component.html',
  	styleUrls: ['./innovation-introduction.component.scss']
})
export class InnovationIntroductionComponent implements OnInit {
	public env;
	public modal:any;
	read_fac:boolean;

  	constructor(private modalService: NgbModal, public aroute: ActivatedRoute) { }

  	ngOnInit() {
  		this.env = environment;
  		this.read_fac = false;
  	}
  	public open(content){
        this.modal = this.modalService.open(content, { windowClass: 'in grey' });
    }

    public show_fac(){
    	this.read_fac = true;
    }
}
