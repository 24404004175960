import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from './../../../../environments/environment';

@Component({
      selector: 'app-cig-here-your-foursight-graph',
      templateUrl: './cig-here-your-foursight-graph.component.html',
      styleUrls: ['./cig-here-your-foursight-graph.component.scss']
})
export class CigHereYourFoursightGraphComponent implements OnInit {

    @Input() profile:Array<Object>;
    @Input() slides;
    @Input() author;
    public env;
    public profiles : any;
    low_preference: any = 'Low Preference';
    high_preference: any = 'High Preference';
    contrast: any = 'Neutral';
    clarify:any = 'Clarify';
    ideate:any = 'Ideate';
    develop:any = 'Develop';
    implement:any = 'Implement';

    constructor() { }

    ngOnInit() {
        this.env=environment;
        this.profiles = this.profile;
        console.log(this.slides)
    }

    public ngOnChanges( changes: SimpleChanges ) : void {

        if ( changes.profile && ! changes.profile.isFirstChange() ) {
            this.profiles = changes.profile.currentValue;

        }

    }

}
