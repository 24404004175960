import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import {AccessGroupService} from './../access-group.service';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-toolcards',
    templateUrl: './toolcards.component.html',
    styleUrls: ['./toolcards.component.scss', '../innovation-station/innovation-station.component.scss']
})
export class ToolcardsComponent implements OnInit {

    routerSubscription: Subscription;
    ngrxAccessGroup:any;

    constructor(public accessgroup:AccessGroupService, private router: Router,public store: Store<any>) {}

    ngOnInit() {
        this.ngrxAccessGroup = this.store.select<any>((state) => state.profile.accessGroupData);
        // it re-directs url to the dashboard page if access to this page is not permitted.
        this.ngrxAccessGroup.subscribe(message => { 
            if(message && message.length > 0){                
                if(message['main_menu'].tools.toolcards != true){
                    this.router.navigate(['/dashboard']);
                    return false;
                }
            }

        },
        err => {
            // Log errors if any
            console.log(err);
        })

        this.routerSubscription = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
            ).subscribe(event => {
              document.body.scrollTop = 0;
            });
    }

    ngOnDestroy() {
        this.routerSubscription.unsubscribe();
    }

}
