import { Component, Injector } from '@angular/core';
import { TeamsComponent } from '../teams.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-myteams-user-tools',
  templateUrl: './myteams-user-tools.component.html',
  styleUrls: ['./myteams-user-tools.component.scss']
})
export class MyteamsUserToolsComponent {
  teamCompo;

  constructor(private injector: Injector,public router: ActivatedRoute) {
    this.router.params.subscribe((params)=> {
      if(!params.id) {
        this.teamCompo = this.injector.get(TeamsComponent);
      }
    })
  }

}
