import { NgModule } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import{ Router, RouterModule } from '@angular/router';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { APIService } from '../../../API.service';
// import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ScrollStrategy, ScrollStrategyOptions } from "@angular/cdk/overlay";
import {ChallengeSummaryService} from './../../challenge-summary.service';
// import swal from 'sweetalert2/dist/sweetalert2.js';
// import 'sweetalert2/src/sweetalert2.scss';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { SlideoutContentService } from '../../../service/slideout-content.service';
import { CnEditmodeService } from '../challenges_service/cn-editmode.service';
import { DatepickerOptions } from 'ng2-datepicker';
import locale from 'date-fns/locale/en-US';
import { getYear } from 'date-fns';
import { ChallengesComponent } from '../challenges.component';

@Component({
  selector: 'app-implement-challenge',
  templateUrl: './implement-challenge.component.html',
  styleUrls: ['./implement-challenge.component.scss']
})

export class ImplementChallengeComponent implements OnInit {

	next_content:any = [];
	prev_next_content:any = [];
	edit_next:boolean = false;
	prev_plan_content:any;
	short_content:any = [];
	mid_content:any = [];
	long_content:any = [];
	edit_plan:boolean = false;
	next24:any = [0];
	showPlan:boolean = false;
	showNext:boolean = false;
	Nxtupdate:boolean = false;
	NxtId:string = '';
	Planupdate:boolean = false;
	PlanId:string = '';
	selected_next:string;
	loading_next: boolean = false;
	loading_plan: boolean = false;
	strengthen_content:any = '';
	thinking_content:any = [];
	opp_content:any = [];
	plus_content:any = [];
	scrollStrategy: ScrollStrategy;
	process_type: string;
	popup_type: string;
	all_data: any = [];
	reset_confirm: string = '';
	modal:any;
	edit_strengthenContent:boolean= false;
	doing_content:any = '';
	loading_doing: boolean = false;
	doing_dropdown:any = '';
	Doingupdate:boolean = false;
	edit_doing:boolean = false;
	DoingId:string = '';
	prev_doing_content:any = '';
	showDoing: boolean = false;
	showStrength: boolean = true;
	edit_strengthen:boolean = false;
	prev_strengthen_content:any = '';
	deleteDatavalue:number;
	loading_strengthen: boolean = false;
	Stupdate:boolean = true;
	StId:string = '';
	showDivCon:boolean = false;
	//slideout variable
	slideoutSolution:any;
	slideoutAction:any;
	slideoutInNext24:any;
	slideoutDivergeContent:any;
	slideoutConvergeContent:any;
	// edit mode variable
	pin_challenge: boolean = false;
	editthinkmode:boolean = false;
	editSolutionView:boolean = false;
	editActionView:boolean = true;
	editInNextView:boolean = true;
	hideOnEditMode:boolean = true;
	EditModeIcon:boolean = false;
	gridViewClass:string = '';
	currentTracker:string = '';
	PreviousTracker:string = '';
	trackLabel:boolean = false;
	trackbtn:boolean = false;
	eyeIcon:boolean = false;
	hideDivLabel:string = 'unhide';
	hideDiv:boolean= true;
	solutionInput:boolean = true;
	options: DatepickerOptions = {
		minYear: getYear(new Date()) - 50, // minimum available and selectable year
		maxYear: getYear(new Date()) + 50, // maximum available and selectable year
		placeholder: '', // placeholder in case date model is null | undefined, example: 'Please pick a date'
		format: "MM/dd/yyyy", // date format to display in input
		formatTitle: 'LLLL YYYYY',
		formatDays: 'EEEEE',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: locale, // date-fns locale
		position: 'bottom',
		inputClass: '', // custom input CSS class to be applied
		calendarClass: 'datepicker-default', // custom datepicker calendar CSS class to be applied
		scrollBarColor: '#dfe3e9', // in case you customize you theme, here you define scroll bar color
	};

  	constructor(private readonly sso: ScrollStrategyOptions, private api: APIService, public router: Router, public allChallenge: ChallengeSummaryService, private modalService: NgbModal,public slideContent:SlideoutContentService,public editmodeService:CnEditmodeService,public chlngCompo: ChallengesComponent) { 
  		this.scrollStrategy = this.sso.noop();
	}

	ngOnDestroy(){
		// sidebar
		let togglemenu = document.querySelector('.trigger-mini-bar') as HTMLElement;
		if(togglemenu.classList.contains('squeeze')){
			togglemenu.click();
			togglemenu.classList.remove('squeeze');
		}
		this.openEditmode(true,true,true,true,'block','#fff');
		// this.allChallenge.delData.unsubscribe();
	}

  	async ngOnInit() {
		$('.implement-loader').css('display','flex');
		//change editmode background
		if(this.editmodeService.editmode.value){
			let page = document.getElementById('page-wrapper');
			page.style.background = '#fff';
			let footer = document.querySelector('.footer') as HTMLElement;
			footer.style.backgroundColor = '#fff';
			footer.style.border = 'none';
			// let togglemenu = document.querySelector('.trigger-mini-bar') as HTMLElement;
			// togglemenu.click();
			this.editmodeService.editmode.next(false);
			// let main_nav = document.querySelector('.row.wrapper') as HTMLElement;
			// main_nav.style.display ='block';
		}

		// sidebar
		let togglemenu = document.querySelector('.trigger-mini-bar') as HTMLElement;
		if(!togglemenu.classList.contains('squeeze')){
			togglemenu.click();
			togglemenu.classList.add('squeeze');
		}

		// diverge,converge,solution,action,inNext24 content respectively 
		this.slideoutDivergeContent = this.slideContent.divergeConvergeContent().implement.diverge;
		this.slideoutConvergeContent = this.slideContent.divergeConvergeContent().implement.converge;
		this.slideoutSolution = this.slideContent.divergeConvergeContent().implement.solution;
		this.slideoutAction = this.slideContent.divergeConvergeContent().implement.action;
		this.slideoutInNext24 = this.slideContent.divergeConvergeContent().implement.inNext24;

	  	var that = this;

		let implementPromise = new Promise((resolve,error)=>{

			let PtFilter = {email:{eq:localStorage.getItem('email')}, processTypeId:{eq:"9d455c40-4e27-4db2-ac3f-d5743d88887e"}, type:{eq:"process-stage"}, title:{eq:"point"}};
			this.api.ListFrameworks(PtFilter,10000).then(function (data) {
				if(data.items.length == 0){

				}else{
					var content = JSON.parse(data.items[0].content);
					var think_content = JSON.parse(content.point.new_thinking);
					for(var l=0; l<think_content.length; l++){
						that.thinking_content[l] = JSON.parse(think_content[l]);
					}
					that.plus_content = JSON.parse(content.point.pluses);
					that.opp_content = JSON.parse(content.point.opportunities);
				}
			})

			let StFilter = {email:{eq:localStorage.getItem('email')}, processTypeId:{eq:"9d455c40-4e27-4db2-ac3f-d5743d88887e"}, type:{eq:"process-stage"}, title:{eq:"strengthen-solution"}};
			this.api.ListFrameworks(StFilter,10000).then(function (data) {
				if(data.items.length == 0){

				}else{
					that.strengthen_content = data.items[0].content;
					that.StId = data.items[0].id;
				}
			})

			let NxtFilter = {email:{eq:localStorage.getItem('email')}, processTypeId:{eq:"addec506-467b-4832-8853-b09e941ea2dc"}, type:{eq:"process-stage"}, title:{eq:"next-24-hours"}};
			this.api.ListFrameworks(NxtFilter,10000).then(function (data) {
				if(data.items.length == 0){
					that.edit_next = false;
					that.next_content[0] = '';
					that.next24[0] = '';
					that.next_content[1] = '';
					that.next24[1] = '';
				}else{
					// console.log(data.items[0].content);
					that.NxtId = data.items[0].id;
					that.Nxtupdate = true;
					that.next24 = JSON.parse(data.items[0].content);
					that.next_content = JSON.parse(data.items[0].content);
					that.prev_next_content = JSON.parse(data.items[0].content);
					that.selected_next = that.next_content[0];
					that.edit_next = false;
					that.showNext = true;
				}
			})

			let PlanFilter = {email:{eq:localStorage.getItem('email')}, processTypeId:{eq:"addec506-467b-4832-8853-b09e941ea2dc"}, type:{eq:"process-stage"}, title:{eq:"action-plan"}};
			this.api.ListFrameworks(PlanFilter,10000).then(function (data) {
				if(data.items.length == 0){
					that.edit_plan = false;
					that.short_content[0] = {};
					that.mid_content[0] = {};
					that.long_content[0] = {};
				}else{
					// console.log(data.items[0].content);
					that.PlanId = data.items[0].id;
					that.Planupdate = true;
					that.prev_plan_content = JSON.parse(data.items[0].content);
					var all_data = JSON.parse(data.items[0].content);
					that.short_content = JSON.parse(all_data.short_term);
					that.mid_content = JSON.parse(all_data.mid_term);
					that.long_content = JSON.parse(all_data.long_term);
					that.short_content.forEach(item => {
						if(item.when) {
							item.when = new Date(item.when);
						}
					});
					that.mid_content.forEach(item => {
						if(item.when) {
							item.when = new Date(item.when);
						}
					});
					that.long_content.forEach(item => {
						if(item.when) {
							item.when = new Date(item.when);
						}
					});
					that.edit_plan = false;
					that.showPlan = true;
				}
				console.warn(that.short_content)
				console.warn(new Date(that.short_content[0].when))
			})


			setTimeout(() => {
				resolve([PtFilter,StFilter,NxtFilter,PlanFilter]);
			}, 1000);
		})

		implementPromise.then(()=> {
			$('.implement-loader').css('display','none');
		})

	    jQuery(document).on("click", ".popaccordian__rowhead", function(e) {
	    	e.stopPropagation();
	  		var thisPar = jQuery(this).parents(".popaccordian__row");
	

	  		if( !jQuery(thisPar).hasClass("popaccordian__row--active") ) {
	  			thisPar.find(".popaccordian__rowbody").slideDown();
	  			thisPar.siblings().find(".popaccordian__rowbody").slideUp();
	  			thisPar.addClass("popaccordian__row--active");
	  			thisPar.siblings().removeClass("popaccordian__row--active");
	  		} else {
	  			thisPar.siblings().find(".popaccordian__rowbody").slideUp();
	  		}
	    })

		// Catching the Click Event
	    jQuery(document).click(function(event){
	    	// console.log(event);
			$("body").click(function() {
				$(".dotactions__dropdown").hide();
			});	

			$(".dotactions__top, .dotactions__dropdown").click(function(e) {
				e.stopPropagation();
			});
		});

		// come from develop solution
		// console.warn(this.short_content.length)
		// console.warn(this.mid_content.length)
		// console.warn(this.long_content.length)
		if(localStorage.getItem('gotoimplement') == 'true'){
			localStorage.setItem('gotoimplement','false');
			// $('.loader-container').css('display','flex');
			// await setTimeout(() => {
			// 	that.PlanStart();
			// }, 1000);
		}
  	}

	// Start In next 24 hours Step 
	public NxtStart(){
		// this.NxtDiverge();
		console.log('next24', this.next24)
		if(this.next24?.length == 0) {
			this.next24 = ['', ''];
		}
		console.log('next24', this.next24)
		this.edit_next = true;
		this.NxtId = '0';
		this.PreviousTracker = 'In next 24 hours'
		this.openEditmode(false,false,true,true)
		// console.warn('showNext-->' + this.showNext);
		// console.warn('edit_next-->' +this.edit_next);
	}

	public NxtConverge(){
		this.cancelNext();
		this.showNext = false;
		this.edit_next = false;
	}

	// Start Diverge for In next 24 hours step
	public NxtDiverge(trackit = 0){
		console.log(this.next24)
		if(this.next24?.length == 0) {
			this.next24 = ['', ''];
		}
		this.cancelPlan(1);
		this.showNext = false;
		this.edit_next = true;
		if(trackit == 1) {
			this.openEditmode(false,false,true,false);
		}else{
			this.openEditmode(false,false,true,true);
		}
		this.PreviousTracker = 'In next 24 hours'
		var that = this;
		setTimeout(function(){
			$(document).scrollTop(0);
		}, 10);
		setTimeout(function(){
			that.stickChallenge();
		},300)
	}

  	public submitNext(){
  		var change_selected = 0;
  		for(var i=1;i<this.next_content.length;i++){
  			if(this.next_content[i] == this.next_content[0]){
  				change_selected = 1;
  			}
  			if(this.next_content[i] == null || this.next_content[i] == ''){
  				this.next_content.splice(i,1);
  				this.next24.splice(this.next24.length-1,1);
  			}
  			if(i == this.next_content.length - 1 && change_selected == 0){
  				this.next_content[0] = '';
  			}
  		}
	  	if(this.next_content.length > 1){
	  		var that = this;
	  		this.loading_next = true;
	  		if(!this.Nxtupdate){
	  			this.api.CreateFramework({email:localStorage.getItem('email'), title:"next-24-hours", processTypeId:"addec506-467b-4832-8853-b09e941ea2dc",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:JSON.stringify(this.next_content)}).then(function (data){
		  			that.edit_next = false;
		  			that.NxtId = data.id;
		  			that.Nxtupdate = true;
		  			that.next24 = JSON.parse(data.content);
		        	that.next_content = JSON.parse(data.content);
		        	that.prev_next_content = JSON.parse(data.content);
		        	that.selected_next = that.next_content[0];
					console.log(this.next24,this.next_content)
		        	that.loading_next = false;
		        	that.showNext = false;
			  		setTimeout(function() {
			            this.toastr.options = {
			                closeButton: true,
			                progressBar: true,
			                timeOut: 2000
			            };
			            this.toastr.success('Your thought has been updated!', 'Nice!');
			        }, 1000);
		  		}) 
	  		}else{
	  			this.api.UpdateFramework({id:this.NxtId,email:localStorage.getItem('email'), title:"next-24-hours", processTypeId:"addec506-467b-4832-8853-b09e941ea2dc",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:JSON.stringify(this.next_content)}).then(function (data){
		  			that.edit_next = false;
		  			that.next24 = JSON.parse(data.content);
	        		that.next_content = JSON.parse(data.content);
	        		that.prev_next_content = JSON.parse(data.content);
	        		that.selected_next = that.next_content[0];
	        		that.loading_next = false;
	        		that.showNext = false;
			  		setTimeout(function() {
			            this.toastr.options = {
			                closeButton: true,
			                progressBar: true,
			                timeOut: 2000
			            };
			            this.toastr.success('Your thoughts have been updated!', 'Nice!');
			        }, 1000);
		  		}) 
	  		}
	  	}else{
	  		if(this.NxtId && this.NxtId != '0'){
	  			this.delNext();
	  		}
	  		this.NxtId = '';
	  		this.edit_next = false;
	  	}
		this.openEditmode(true,true,true,true,'block','#fff');
  	}

  	public delNext(){  	
	  	var that = this;
	  	var remove = confirm("Are you sure you want to delete data from this step? This cannot be undone!");
	  	if(remove == true){
			try {
				this.api.DeleteFramework({id:this.NxtId}).then(function(){
					that.Nxtupdate = false;
					that.next_content = [];
					that.prev_next_content = [];
					that.NxtId = '';
					that.next24 = [];
					that.next_content[0] = '';
				  that.next24[0] = '';
				  that.next_content[1] = '';
				  that.next24[1] = '';
					that.edit_next = false;
					setTimeout(function() {
					  this.toastr.options = {
						  closeButton: true,
						  progressBar: true,
						  timeOut: 2000
					  };
					  this.toastr.success('Your thoughts has been deleted!', 'Nice!');
				  }, 1000);
				})
			} catch (err) {
				console.log(err)
			}
		}
		this.openEditmode(true,true,true,true,'block','#fff');
  	}

  	public submit_converge_next(){
  		this.edit_next = false;
  		this.showNext = true;
  	}

  	// public addNxtLine(){
  	// 	if(document.activeElement.id){
	//   		if(this.next24.length <= parseInt(document.activeElement.id.replace("Nxt","")) + 1 ){
	//   			this.next24.push(this.next24.length);
	//   		}else{
	//   			for(var i=parseInt(document.activeElement.id.replace("Nxt",""))+1; i<=this.next24.length-1; i++){
	//   				if(this.next_content[i] == '' || this.next_content[i] == undefined){
	//   					document.getElementById("Nxt"+ (i)).focus();
	//   					break;
	//   				}
	//   				if(i == this.next24.length - 1 && this.next_content[i] != '' && this.next_content[i] != undefined){
	//   					this.next24.push(this.next24.length);
	//   				}
	//   			}
	//   		}
	//   	}else{
	//   		this.next24.push(this.next24.length);
	//   	}
  	// }

  	public subNextLine(i){
  		this.next_content.splice(i,1);
  		this.next24.splice(this.next24.length-1,1);
  	}

  	public async NxtOrderChange(event) {
	  	// console.log(event);
	  	var that = this;
	  	moveItemInArray(this.next_content, event.previousIndex, event.currentIndex);
	  	this.api.UpdateFramework({id:this.NxtId,email:localStorage.getItem('email'), title:"next-24-hours", processTypeId:"addec506-467b-4832-8853-b09e941ea2dc",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:JSON.stringify(this.next_content)}).then(function (data){
			that.edit_next = false;
			that.next24 = JSON.parse(data.content);
			that.next_content = JSON.parse(data.content);
			that.prev_next_content = JSON.parse(data.content);
	  		setTimeout(function() {
	            this.toastr.options = {
	                closeButton: true,
	                progressBar: true,
	                timeOut: 2000
	            };
	            this.toastr.success('Your thoughts has been updated!', 'Nice!');
	        }, 1000);
		})
  	}

  	public PlanStart(){
		this.PlanId = '0';
		this.edit_plan = true;
		this.PlanDiverge();
		// this.pin_challenge = true;
		// setTimeout(function(){
		// 	var solutionHeight = $('.card.card-default:nth-child(1)').outerHeight(true);
		// 	$('.stick-challenge').animate({
		// 		scrollTop :  solutionHeight + 15
		// 	}, 300);
		// },1000);
	}

	// Start Diverge Step for Action Plan
  	public PlanDiverge(trackit = 0){
		this.showPlan = false;
  		this.edit_plan = true;
  		this.cancelNext();
  		if(JSON.stringify(this.short_content) == "[]"){
  			this.short_content[0] = {};
  		}
  		if(JSON.stringify(this.mid_content) == "[]"){
  			this.mid_content[0] = {};
  		}
  		if(JSON.stringify(this.long_content) == "[]"){
  			this.long_content[0] = {};
  		}
		if(trackit == 1) {
			this.openEditmode(false,true,false);
		}else {
		this.openEditmode(false,true,false,true);
		}
		this.PreviousTracker = 'Action Plan';
		this.trackbtn = false;
		this.trackLabel = false;
		var that = this;
		setTimeout(function(){
			$(document).scrollTop(0);
		}, 10);
		// setTimeout(function(){
		// 	that.stickChallenge(false,'action');
		// },300)
		$('.implement-loader').css('display','none');
		setTimeout(function(){
			let allBtDelIcon = document.querySelectorAll('.card-body__minus');
			console.log(allBtDelIcon)
			if(allBtDelIcon.length == 3) {
				allBtDelIcon[0].classList.add('card-body__minus--hide');
				allBtDelIcon[1].classList.add('card-body__minus--hide');
				allBtDelIcon[2].classList.add('card-body__minus--hide');
			}
		}, 10);
	}

  	public submitPlan(){
  		// console.log(JSON.stringify(this.short_content));
		let checkValueHead = ()=> {
			return (JSON.stringify(this.short_content) != "[{}]" || JSON.stringify(this.mid_content) != "[{}]" || JSON.stringify(this.long_content) != "[{}]")
		}
	  	if(checkValueHead()){
	  		var that = this;

			let isEmptyItem = (item) => {
			return !item.todo && !item.who && !item.when && !item.report;
			};

			// Function to filter out empty items from a given array
			let filterContent = (contentArray) => {
				return contentArray.filter(item => !isEmptyItem(item));
			};

			// Filter out empty items from each content array
			this.short_content = filterContent(this.short_content);
			this.mid_content = filterContent(this.mid_content);
			this.long_content = filterContent(this.long_content);

	  		var content = {
				short_term:JSON.stringify(this.short_content),
				mid_term:JSON.stringify(this.mid_content),
				long_term:JSON.stringify(this.long_content)
			}
			// console.log(content);
			if(content.short_term != "[]" || content.mid_term != "[]" || content.long_term != "[]"){
				this.loading_plan = true;
		  		if(!this.Planupdate){
		  			this.api.CreateFramework({email:localStorage.getItem('email'), title:"action-plan", processTypeId:"addec506-467b-4832-8853-b09e941ea2dc",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:JSON.stringify(content)}).then(function (data){
			  			that.edit_plan = false;
			  			that.PlanId = data.id;
			  			that.Planupdate = true;
			  			that.loading_plan = false;
			  			that.prev_plan_content = JSON.parse(data.content);
			  			var all_data = JSON?.parse(data.content);
			  			that.short_content = JSON?.parse(all_data.short_term);
			  			that.mid_content = JSON?.parse(all_data.mid_term);
			  			that.long_content = JSON?.parse(all_data.long_term);
						  that.short_content.forEach(item => {
							if(item.when) {
								item.when = new Date(item.when);
							}
						});
						that.mid_content.forEach(item => {
							if(item.when) {
								item.when = new Date(item.when);
							}
						});
						that.long_content.forEach(item => {
							if(item.when) {
								item.when = new Date(item.when);
							}
						});
				  		setTimeout(function() {
				            this.toastr.options = {
				                closeButton: true,
				                progressBar: true,
				                timeOut: 2000
				            };
				            this.toastr.success('Your thought has been updated!', 'Nice!');
				        }, 1000);
				  		that.showPlan = true;
			  		}) 
		  		}else{
		  			this.api.UpdateFramework({id:this.PlanId,email:localStorage.getItem('email'), title:"action-plan", processTypeId:"addec506-467b-4832-8853-b09e941ea2dc",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:JSON.stringify(content)}).then(function (data){
			  			that.edit_plan = false;
			  			that.loading_plan = false;
			  			var all_data = JSON.parse(data.content);
			  			that.prev_plan_content = JSON.parse(data.content);
			  			that.short_content = JSON.parse(all_data.short_term);
			  			that.mid_content = JSON.parse(all_data.mid_term);
			  			that.long_content = JSON.parse(all_data.long_term);
						that.short_content.forEach(item => {
							if(item.when) {
								item.when = new Date(item.when);
							}
						});
						that.mid_content.forEach(item => {
							if(item.when) {
								item.when = new Date(item.when);
							}
						});
						that.long_content.forEach(item => {
							if(item.when) {
								item.when = new Date(item.when);
							}
						});
				  		setTimeout(function() {
				            this.toastr.options = {
				                closeButton: true,
				                progressBar: true,
				                timeOut: 2000
				            };
				            this.toastr.success('Your thoughts have been updated!', 'Nice!');
				        }, 1000);
				  		that.showPlan = true;
			  		}) 
		  		}
		  	}else{
		  		if(this.PlanId && this.PlanId != '0'){
		  			this.delPlan();
		  		}
		  		this.PlanId = '';
		  		this.edit_plan = false;
		  	}
	  	}else{
	  		if(this.PlanId && this.PlanId != '0'){
	  			this.delPlan();
	  		}
	  		this.PlanId = '';
	  		this.edit_plan = false;
	  	}
		this.openEditmode(true,true,true,true,'block','#fff');
		this.stickChallenge(true);
		// this.trackbtn = false;
		// this.trackLabel = false;
	}
	//delete data from this step modal
	public DataModel(value){
		this.deleteDatavalue = value;
		$('.cn-dmodal').css('display', 'block');
		this.allChallenge.delData.subscribe((data)=> {
			console.log(data);
			if(data) {
				this.deletedata();
				this.allChallenge.delData.next(false)
			}
		})
		// this.modal = this.modalService.open(content, { windowClass: 'in grey' });
	}

	// delete data
	public deletedata(){
		// this.modal.close();
		$('.cn-dmodal').css('display', 'none');
		this.openEditmode(true,true,true,true,'block','#fff');
		var that = this;
		if(this.deleteDatavalue==1){
			try {
				this.api.DeleteFramework({id:this.PlanId}).then(function(){
					that.Planupdate = false;
					that.short_content = [];
					that.mid_content = [];
					that.long_content = [];
					that.short_content[0] = {};
					that.mid_content[0] = {};
					that.long_content[0] = {};
					that.edit_plan = false;
					that.PlanId = '';
					that.prev_plan_content = [];
					setTimeout(function() {
					  this.toastr.options = {
						  closeButton: true,
						  progressBar: true,
						  timeOut: 2000
					  };
					  this.toastr.success('Your thoughts has been deleted!', 'Nice!');
				  }, 1000);
				})
			} catch (err) {
				console.log(err)
			}
		}
		if(this.deleteDatavalue == 2){
			try {
				this.api.DeleteFramework({id:this.NxtId}).then(function(){
					that.Nxtupdate = false;
					that.next_content = [];
					that.prev_next_content = [];
					that.NxtId = '';
					that.next24 = [];
					that.next_content[0] = '';
					  that.next24[0] = '';
					  that.next_content[1] = '';
					  that.next24[1] = '';
					that.edit_next = false;
					setTimeout(function() {
					  this.toastr.options = {
						  closeButton: true,
						  progressBar: true,
						  timeOut: 2000
					  };
					  this.toastr.success('Your thoughts has been deleted!', 'Nice!');
				  }, 1000);
				})
			} catch (err) {
				console.log(err)
			}
		}
	}

	// delete plans
  	public delPlan(){ 
	  	var that = this;
		try {
			this.api.DeleteFramework({id:this.PlanId}).then(function(){
				that.Planupdate = false;
				that.short_content = [];
				that.mid_content = [];
				that.long_content = [];
				that.short_content[0] = {};
				that.mid_content[0] = {};
				that.long_content[0] = {};
				that.edit_plan = false;
				that.PlanId = '';
				that.prev_plan_content = [];
				setTimeout(function() {
				  this.toastr.options = {
					  closeButton: true,
					  progressBar: true,
					  timeOut: 2000
				  };
				  this.toastr.success('Your thoughts has been deleted!', 'Nice!');
			  }, 1000);
			})
		} catch (err) {
			console.log(err)
		}
  	}

	// add short term line
  	public addSLLine(){
		// console.log('add short')
	  	this.short_content.push(this.short_content[this.short_content.length]);
	  	this.short_content[this.short_content.length - 1] = {};
	  	// console.log(this.short_content)
		  setTimeout(function(){
			let allBtDelIcon = document.querySelectorAll('.card-body__minus');
			console.log(allBtDelIcon)
			if(allBtDelIcon.length > 3) {
				allBtDelIcon[0].classList.remove('card-body__minus--hide');
				allBtDelIcon[1].classList.remove('card-body__minus--hide');
				allBtDelIcon[2].classList.remove('card-body__minus--hide');
			}
		}, 10);
  	}

	// add middle term line
  	public addMLLine(){
		// console.log('add middle')
	  	this.mid_content.push(this.mid_content[this.mid_content.length]);
	  	this.mid_content[this.mid_content.length - 1] = {};
		  setTimeout(function(){
			let allBtDelIcon = document.querySelectorAll('.card-body__minus');
			console.log(allBtDelIcon)
			if(allBtDelIcon.length > 3) {
				allBtDelIcon[0].classList.remove('card-body__minus--hide');
				allBtDelIcon[1].classList.remove('card-body__minus--hide');
				allBtDelIcon[2].classList.remove('card-body__minus--hide');
			}
		}, 10);
  	}

	// add long term line
  	public addLLLine(){
		// console.log('add short3')
	  	this.long_content.push(this.long_content[this.long_content.length]);
	  	this.long_content[this.long_content.length - 1] = {};
		  setTimeout(function(){
			let allBtDelIcon = document.querySelectorAll('.card-body__minus');
			console.log(allBtDelIcon)
			if(allBtDelIcon.length > 3) {
				allBtDelIcon[0].classList.remove('card-body__minus--hide');
				allBtDelIcon[1].classList.remove('card-body__minus--hide');
				allBtDelIcon[2].classList.remove('card-body__minus--hide');
			}
		}, 10);
  	}

  	public subSLLine(i){
  		// console.log(this.short_content)
  		this.short_content.splice(i,1);
		  setTimeout(function(){
			let allBtDelIcon = document.querySelectorAll('.card-body__minus');
			console.log(allBtDelIcon)
			if(allBtDelIcon.length == 3) {
				allBtDelIcon[0].classList.add('card-body__minus--hide');
				allBtDelIcon[1].classList.add('card-body__minus--hide');
				allBtDelIcon[2].classList.add('card-body__minus--hide');
			}
		}, 10);
  		// console.log(this.short_content)
  	}

  	public subMLLine(i){
  		this.mid_content.splice(i,1);
		  setTimeout(function(){
			let allBtDelIcon = document.querySelectorAll('.card-body__minus');
			console.log(allBtDelIcon)
			if(allBtDelIcon.length == 3) {
				allBtDelIcon[0].classList.add('card-body__minus--hide');
				allBtDelIcon[1].classList.add('card-body__minus--hide');
				allBtDelIcon[2].classList.add('card-body__minus--hide');
			}
		}, 10);
  	}

  	public subLLLine(i){
  		this.long_content.splice(i,1);
		  setTimeout(function(){
			let allBtDelIcon = document.querySelectorAll('.card-body__minus');
			console.log(allBtDelIcon)
			if(allBtDelIcon.length == 3) {
				allBtDelIcon[0].classList.add('card-body__minus--hide');
				allBtDelIcon[1].classList.add('card-body__minus--hide');
				allBtDelIcon[2].classList.add('card-body__minus--hide');
			}
		}, 10);
  	}

  	public checkNext(content){
	  	this.selected_next = content;
	  	this.next_content[0] = content;
	  	this.submitNext();
  	}

  	public cancelNext(closeedit = false){
  		this.edit_next = false;
  		var next = this.prev_next_content;
  		this.next_content = [];
  		this.next24 = [];
  		for(var i=0;i<next.length;i++){
    		let data_str = next[i];
    		this.next_content[i] = data_str;
    		this.next24[i] = i;
    	}
    	if(this.NxtId == '0'){
    		this.edit_next = false;
    		this.NxtId = '';
    		this.next_content[0] = '';
        	this.next24[0] = '';
        	this.next_content[1] = '';
        	this.next24[1] = '';
    	}
    	if(this.NxtId != '' && this.NxtId != '0'){
    		this.showNext = true;
    	}
		if(closeedit == true){
			this.openEditmode(true,true,true,true,'block','#fff');
			this.trackLabel = false;
			this.trackbtn = false;
		}
		this.stickChallenge(true);
  	}

  	public cancelPlan(trackit = 0){
		console.log("my plan",this.PlanId)
  		if(this.PlanId == '0'){
    		this.edit_plan = false;
    		this.PlanId = '';
    		this.short_content[0] = {};
  			this.mid_content[0] = {};
  			this.long_content[0] = {};
    	}else{
	  		this.edit_plan = false;
	  		var all_data = this.prev_plan_content;
	  		this.short_content = [];
	  		this.mid_content = [];
	  		this.long_content = [];

			// Initialize arrays and optimize above code
			// Convert 'when' to Date objects
			const convertDates = (content) => content.forEach(item => item.when = new Date(item.when));

			convertDates(this.short_content);
			convertDates(this.mid_content);
			convertDates(this.long_content);
		
			// Filter out items where all properties are null or empty
			const isEmptyItem = (item) =>
				!item.todo && !item.who && !item.when && !item.report;
		
			this.short_content = this.short_content.filter(item => !isEmptyItem(item));
			this.mid_content = this.mid_content.filter(item => !isEmptyItem(item));
			this.long_content = this.long_content.filter(item => !isEmptyItem(item));
	    	if(this.PlanId != '' && this.PlanId != '0'){
	    		this.showPlan = true;
	    	}
	    }

		trackit === 1 ? this.openEditmode(true, true, true) : this.openEditmode(true,true,true,true,'block','#fff');
		this.stickChallenge(true);
  	}

	public StDiverge(trackit = 0, currentTrack = ''){
		this.router.navigate(['/challenges/develop']);
		localStorage.setItem('implementTodevelop','true');
		// this.edit_strengthenContent= !this.edit_strengthenContent;
		// this.showStrength = false;
		// this.edit_strengthen = true;
  		// var strength = this.strengthen_content;
  		// this.prev_strengthen_content = '';
  		// this.prev_strengthen_content = strength;
		// if(trackit == 1){
		// 	// console.log('stdiverge');
		// 	this.trackLabel = true;
		// 	this.trackbtn = true;
		// 	this.currentTracker = currentTrack;
		// 	this.openEditmode(true,false,false);
		// }else{
		// 	// console.log('stdivergeelse')
		// 	this.trackLabel = false;
		// 	this.trackbtn = false;
		// 	this.PreviousTracker = 'Solution';
		// 	this.openEditmode(true,false,false,true);
		// }
	}

	// Save Solution
	public async submitDoing(trackit = 0){
		if(this.doing_content && this.doing_content != ''){
			var that = this;
			this.loading_doing = true;
			var content1 = this.doing_dropdown.concat("\n"+this.doing_content);
			if(!this.Doingupdate){
				await this.api.CreateFramework({email:localStorage.getItem('email'), title:"myself-doing", processTypeId:"9d455c40-4e27-4db2-ac3f-d5743d88887e",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:content1}).then(function (data){
						that.edit_doing = false;
						that.DoingId = data.id;
						that.Doingupdate = true;
						that.loading_doing = false;
						that.doing_content = data.content.substr(data.content.indexOf("\n")+1);
						that.doing_dropdown = data.content.substr(0,data.content.indexOf("\n"));
						that.prev_doing_content = data.content;
						that.showDoing = true;
						setTimeout(function() {
							this.toastr.options = {
								closeButton: true,
								progressBar: true,
								timeOut: 2000
							};
							this.toastr.success('Your thought has been updated!', 'Nice!');
						}, 1000);
					}) 
			}else{
				await this.api.UpdateFramework({id:this.DoingId,email:localStorage.getItem('email'), title:"myself-doing", processTypeId:"9d455c40-4e27-4db2-ac3f-d5743d88887e",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:content1}).then(function (data){
						that.edit_doing = false;
						that.loading_doing = false;
						that.doing_content = data.content.substr(data.content.indexOf("\n")+1);
						that.doing_dropdown = data.content.substr(0,data.content.indexOf("\n"));
						that.prev_doing_content = data.content;
						that.showDoing = true;
						setTimeout(function() {
							this.toastr.options = {
								closeButton: true,
								progressBar: true,
								timeOut: 2000
							};
							this.toastr.success('Your thoughts have been updated!', 'Nice!');
						}, 1000);
					}) 
			}
		}else{
			if(this.DoingId && this.DoingId != '0'){
				this.delDoing();
			}
			this.DoingId = '';
			this.edit_doing = false;
		}
		if(trackit == 1){
			this.trackLabel = false;
			this.trackbtn = false;
			// console.log(this.PreviousTracker)
			switch(this.PreviousTracker){
				case 'Action Plan':
					// console.log('action plan');
					this.PlanDiverge();
					break;
				case 'In next 24 hours':
					// console.log('in next 24 hours');
					this.NxtDiverge();
					break;
				default :
					this.openEditmode(true,true,true,true,'block','#fff');
					break;
			}
		}
	}
	public submitStrengthen(trackit = 0){
		this.edit_strengthenContent = !this.edit_strengthenContent;
		var that = this;	
		if(this.strengthen_content && this.strengthen_content != ''){
			this.loading_strengthen = true;
			if(!this.Stupdate){
				this.api.CreateFramework({email:localStorage.getItem('email'), title:"strengthen-solution", processTypeId:"9d455c40-4e27-4db2-ac3f-d5743d88887e",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:this.strengthen_content}).then(function (data){
					that.edit_strengthen = false;
					that.StId = data.id;
					that.Stupdate = true;
					that.loading_strengthen = false;
				  	that.strengthen_content = data.content;
				  	that.prev_strengthen_content = data.content;
				  	that.showStrength = true;
					setTimeout(function() {
					  this.toastr.options = {
						  closeButton: true,
						  progressBar: true,
						  timeOut: 2000
					  };
					  this.toastr.success('Your thought has been updated!', 'Nice!');
				  }, 1000);
				}) 
			}else{
				this.api.UpdateFramework({id:this.StId,email:localStorage.getItem('email'), title:"strengthen-solution", processTypeId:"9d455c40-4e27-4db2-ac3f-d5743d88887e",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:this.strengthen_content}).then(function (data){
					that.edit_strengthen = false;
					that.loading_strengthen = false;
				  	that.strengthen_content = data.content;
				  	that.prev_strengthen_content = data.content;
				  	that.showStrength = true;
					setTimeout(function() {
					  this.toastr.options = {
						  closeButton: true,
						  progressBar: true,
						  timeOut: 2000
					  };
					  this.toastr.success('Your thoughts have been updated!', 'Nice!');
				  }, 1000);
				}) 
			}
		}else{
			if(this.StId && this.StId != '0'){
				this.delStrengthen();
			}
			this.StId = '';
			this.edit_strengthen = false;
		}
		if(trackit == 1){
			this.trackLabel = false;
			this.trackbtn = false;
			// console.log(this.PreviousTracker)
			switch(this.PreviousTracker){
				case 'Action Plan':
					// console.log('action plan');
					this.PlanDiverge(1);
					break;
				case 'In next 24 hours':
					// console.log('in next 24 hours');
					this.NxtDiverge(1);
					break;
				default :
					this.openEditmode(true,true,true,true,'block','#fff');
					break;
			}
		}else {
			this.openEditmode(true,true,true,true,'block','#fff');
		}

	}
	public delStrengthen(){
		var that = this;
		var remove = confirm("Are you sure, you want to delete this? This cannot be undone!");
		if(remove == true){
			try {
				this.api.DeleteFramework({id:this.StId}).then(function(){
					that.Stupdate = false;
					that.strengthen_content = '';
					that.prev_strengthen_content = '';
					that.edit_strengthen = false;
					that.StId = '';
					setTimeout(function() {
					  this.toastr.options = {
						  closeButton: true,
						  progressBar: true,
						  timeOut: 2000
					  };
					  this.toastr.success('Your thoughts has been deleted!', 'Nice!');
				  }, 1000);
				})
			} catch (err) {
				console.log(err)
			}
	  }
	}

	public cancelDoing(closeedit = false){
		this.edit_strengthenContent = false;
		this.showStrength = true;
		this.edit_doing = false;
		var doing = this.prev_doing_content;
		this.doing_content = this.prev_doing_content.substr(this.prev_doing_content.indexOf("\n")+1);
	  	this.doing_dropdown = this.prev_doing_content.substr(0,this.prev_doing_content.indexOf("\n"));
		if(this.DoingId == '0'){
		  this.edit_doing = false;
		  this.DoingId = '';
		  this.doing_content = '';
		  this.doing_dropdown = 'What I see myself doing is...';
		  this.prev_doing_content = '';
	  }
	  
	  if(this.DoingId != '' && this.DoingId != '0'){
		  this.showDoing = true;
	  }
	  if(closeedit == true){
		  this.openEditmode(true,true,true,true,'block','#fff');
		  this.trackLabel = false;
		  this.trackbtn = false;
	  }
	  this.stickChallenge(true);
	}

	public delDoing(){
		var that = this;
		var remove = confirm("Are you sure, you want to delete this? This cannot be undone!");
		if(remove == true){
			try {
				this.api.DeleteFramework({id:this.DoingId}).then(function(){
					that.Doingupdate = false;
					that.doing_content = '';
					that.doing_dropdown = 'What I see myself doing is...';
					that.prev_doing_content = '';
					that.edit_doing = false;
					that.DoingId = '';
					setTimeout(function() {
					  this.toastr.options = {
						  closeButton: true,
						  progressBar: true,
						  timeOut: 2000
					  };
					  this.toastr.success('Your thoughts has been deleted!', 'Nice!');
				  }, 1000);
				})
			} catch (err) {
				console.log(err)
			}
	  }
	}

  	public openPopup(content,value=0){
		this.showDivCon = false;
  		$('.tooltippopup').addClass('tooltippopup--active');
  		this.process_type = content;
  		this.popup_type = 'info';
  		var that = this;
  		this.allChallenge.getChallenge().then(function (challenge) {
  			// console.log(challenge);
  			that.all_data = challenge;
			that.all_data['thinkCon'] = JSON.parse(that.all_data['thinkCon'][0]);
  		});
		if(value > 0){
			this.showDivCon = true;
			setTimeout(function(){
				jQuery('.popaccordian__row:nth-child(2) .popaccordian__rowhead').trigger( "click" );
				//// console.log('clicked');
			}, 10);
		}
  	}

  	public closePopup(){
  		$('.tooltippopup').removeClass('tooltippopup--active');
  		this.process_type = '';
  		this.popup_type = '';
  	}

  	public popupDetail(content){
  		this.popup_type = content;
  	}

  	public gotoSection(section){
  		this.closePopup();
  		$('html,body').animate({
            scrollTop: $('.card-default:nth-child('+section+')').offset().top},
        'slow');
  	}

  	public allContentPDF() { 
  		// localStorage.setItem('pdf_type','allContent');
	    // this.router.navigate(['/challenges/summary']);
		$('.pdf-modal').css('display','flex');
		this.chlngCompo.arguments('allContent');
  	}

  	public summaryPDF() { 
  		// localStorage.setItem('pdf_type','summary');
	    // this.router.navigate(['/challenges/summary']);
		$('.pdf-modal').css('display','flex');
		this.chlngCompo.arguments('summary');
  	}

  	// public resetData(){
  	// 	var that = this;
  	// 	this.allChallenge.getChallengeIds().then(function (challenge) {
  	// 		// console.log(challenge);
  	// 		for(var i=0; i<challenge.length;i++){
  	// 			if(challenge[i]){
  	// 				that.api.DeleteFramework({id:challenge[i]}).then(function(){
	//   					// console.log(i, challenge.length);
	// 			  	})
  	// 			}
  	// 		}
  	// 		// console.log(that.router);
  	// 		setTimeout(function() {
	//             this.toastr.options = {
	//                 closeButton: true,
	//                 progressBar: true,
	//                 timeOut: 2000
	//             };
	//             this.toastr.success('Your thoughts has been deleted!', 'Nice!');
	//         }, 1000);
  	// 		that.router.navigate(['/challenges/clarify']);
  	// 	});
  	// }

  	public async resetConfirm() {
  		this.reset_confirm = '';
		$('.reset-modal').css('display', 'block');
	}

  	// private async deleteConfirm() {
	// 	if(this.reset_confirm == 'RESET'){
	// 		this.modal.close();
	// 		this.waytoPDF();
	// 		this.resetData();
	// 	}
	// }

	// public waytoPDF() { 
	// 	// console.log(window.location);
  	// 	localStorage.setItem('pdf_type','allContent');
	//     // this.router.navigate(['/challenges/summary']);
	//     window.open(window.location.origin + '/challenges/summary','_blank');
  	// }

	//open edit view for goal,data and challenges
	public openEditmode(editsol,editaction,editinnext,sidenav=false,displaynone='none',bgcolor="#EBEBEB"){
		// console.warn("openeditmode---->")
		this.solutionInput = editsol;
		this.editActionView = editaction;
		this.editInNextView = editinnext;
		// let togglemenu = document.querySelector('.trigger-mini-bar') as HTMLElement;
		// let mainnav = document.querySelector('.row.wrapper') as HTMLElement;
		let page = document.getElementById('page-wrapper');
		page.style.backgroundColor = bgcolor;
		let footer = document.querySelector('.footer') as HTMLElement;
		footer.style.backgroundColor = bgcolor;
		footer.style.border = '1px solid #CFDBE2'
		// if(sidenav){
		// 	togglemenu.click();
		// }
		if(editsol && !editaction && !editinnext){
			this.solutionInput = false;
			this.editSolutionView = true;
			this.EditModeIcon = true;
			// mainnav.style.display = displaynone;
			this.editmodeService.editmode.next(true);
			this.hideOnEditMode = false;
		}else if(!editsol && editaction && !editinnext){
			this.solutionInput = true;
			this.EditModeIcon = true;
			// mainnav.style.display = displaynone;
			this.editmodeService.editmode.next(true);
			this.hideOnEditMode = false;
		}else if(!editsol  && !editaction && editinnext){
			this.solutionInput = true;
			this.EditModeIcon = true;
			// mainnav.style.display = displaynone;
			this.editmodeService.editmode.next(true);
			this.hideOnEditMode = false;
		}else{
		// console.warn("else block ");
		this.solutionInput = true;
		this.hideOnEditMode = true;
		this.pin_challenge = false;
		this.EditModeIcon = false;
		this.editSolutionView = false;
		this.editActionView = true;
		this.editInNextView = true;
		this.eyeIcon = false;
		// mainnav.style.display = displaynone;
		this.editmodeService.editmode.next(false);
		}
	}

	// pin challenge
	public stickChallenge(pinval = false,val = ''){
		// console.warn(this.pin_challenge)
		if(pinval){
			this.pin_challenge = false;
		}else{
			this.pin_challenge = !this.pin_challenge;	
		}
		let headDivHight = $('.info.card__headactive').outerHeight();
		let inputDivTop = $('.info.card__headactive').offset()?.top;
		let winHeight = jQuery(window).outerHeight();
		// console.log(inputDivTop);
		if(this.pin_challenge){
			$('.card__implementtable').height(winHeight - headDivHight- inputDivTop - 250 );
			$('.card__implementtable').height(winHeight - inputDivTop - 250 );
			if(val != 'action')
			$('.card-body__edit').height(winHeight - inputDivTop - 250 );
			console.log($('.card-body__edit').outerHeight())
			if($('.card-body__edit').outerHeight() == 0){
				$('.card-body__edit').height("auto");
			}
		}else{
			$('.card-body__edit').height("auto");
			$('.card__implementtable').height('auto');
			$('.card__implementtable').height('auto');
		}
	}

}

