import { Component, Input } from '@angular/core';
import { OrgIndividualTeamsComponent } from '../../org-individual-teams/org-individual-teams.component';
import { OrgIndividualSessionsComponent } from '../../org-individual-sessions/org-individual-sessions.component';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../environments/environment'

@Component({
  selector: 'app-teams-input-popup',
  templateUrl: './teams-input-popup.component.html',
  styleUrls: ['./teams-input-popup.component.scss']
})
export class TeamsInputPopupComponent {

  @Input() popupType;
  userDetail = [1];
  newUserEmail = ''; 
  newUserLstName = '';
  newUserFstName = '';
  loading = 3;

  constructor(public orgIndivTeams: OrgIndividualTeamsComponent,public orgIndivSesn: OrgIndividualSessionsComponent,public http:HttpClient) {}

  public close() {
    this.orgIndivTeams.createNewTeamBtn = false;
    if(this.popupType == 'team') {
      $('.team-modal').css('display', 'none');
    } else if(this.popupType == 'session') {
      $('.session-modal').css('display', 'none');
    } else if(this.popupType == 'teamMate') {
      $('.team-modal').css('display', 'none');
    }
  }

  increaseUserDetail(){
    this.userDetail.push(1)
  }

  addRosterNoProfileMem() {
    console.log("without profile member",this.newUserFstName,this.newUserLstName,this.newUserEmail)
    let  email = {
      'email': this.newUserEmail,
      'first_name': this.newUserFstName,
      'last_name': this.newUserLstName
    }
    try {
      this.http.post(`${environment.profileURL}/api/v1/roster_organizations/${this?.orgIndivTeams?.id}/add_member.json`,email).subscribe((res:any)=> {
        let newUserData = {
          "default_identity": {
            "email": this.newUserEmail
          },
          "non_admin_unassgined_teams_count" : 0
        }
        newUserData.default_identity = res.memberships[res.memberships.length - 1];
        console.log('result',newUserData)
        console.log("before",this.orgIndivTeams.teamsData);
        this.orgIndivTeams.teamsData.members.push(newUserData)
        console.log("after", this.orgIndivTeams.teamsData)
        this.close();
        this.orgIndivTeams.totalMembers = parseInt(this.orgIndivTeams.totalMembers) +  1 
        this.newUserEmail = ''
        setTimeout(function() {
          this.toastr.options = {
              closeButton: true,
              progressBar: true,
              timeOut: 2000
          };
          this.toastr.success('Member Added successfully.', 'Nice!');
      }, 1000);
      })
    } catch(err) {
      setTimeout(function() {
        this.toastr.options = {
            closeButton: true,
            progressBar: true,
            timeOut: 2000
        };
        this.toastr.error('Something went wrong!', 'Oops!');
    }, 1000);
    }
  }
}
