import { Component, AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { environment } from './../../../environments/environment';
import { Subscription } from 'rxjs';
import {AccessGroupService} from './../access-group.service';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-innovation-station',
    templateUrl: './innovation-station.component.html',
    styleUrls: ['./innovation-station.component.scss']
})
export class InnovationStationComponent implements AfterViewInit{

    public env;
    routerSubscription: Subscription;
    ngrxAccessGroup;

    constructor(public accessgroup:AccessGroupService, private router: Router, public aroute: ActivatedRoute,public store:Store<any>) {}


    ngAfterViewInit(){
    }

    ngOnInit() {
        this.ngrxAccessGroup = this.store.select<any>((state) => state.profile.accessGroupData);
        // it re-directs url to the dashboard page if access to this page is not permitted.
        this.ngrxAccessGroup.subscribe(message => { 
            if(message['main_menu'].tools && message.length > 0){                
                if(!message['main_menu'].tools.innovation_station){
                    this.router.navigate(['/dashboard']);
                    return false;
                }
            }
            else{
                // this.router.navigate(['/dashboard']);
                // return false;
            }

        },
        err => {
            // Log errors if any
            console.log(err);
        })

        this.env = environment;

        this.routerSubscription = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
            ).subscribe(event => {
                document.body.scrollTop = 0;
            });

            // prevent cut copy
            // $(document).ready(function () {
            //     $('body').bind('cut copy paste', function (e) {
            //         e.preventDefault();
            //     });
            // })

    }

    ngOnDestroy() {
        this.routerSubscription.unsubscribe();
    }

}
