import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common'
import {AccessGroupService} from './../access-group.service';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-account-downloads',
  templateUrl: './account-downloads.component.html',
  styleUrls: ['./account-downloads.component.scss']
})
export class AccountDownloadsComponent implements OnInit {
    downloads:any;
    itemsByPage:number;
    query_string:string;
    meta:any;
    count:number;
    pageLen:number;
    curPage:number;
    isLoading:boolean;
    item:any;
    Query_String:string;
    refresh_all:boolean;
    ngrxAccessGroup:any 

  	constructor(public location: Location, public accessgroup: AccessGroupService, public http: HttpClient, private router: Router, public store:Store<any>) { }

  	ngOnInit() {
        this.ngrxAccessGroup = this.store.select<any>((state) => state.profile.accessGroupData);
        console.log("working...",this.ngrxAccessGroup);
        this.ngrxAccessGroup.subscribe((message)=> {
            if(message && message.length > 0){
                if(!message['menu_account']['account_downloads']){
                    this.router.navigate(['/dashboard']);
                    return false;
                }
            }
        })
  		// this.ngrxAccessGroup.subscribe(message => {
        //     if(message){
        //         if(!message['menu_account']['account_downloads']){
        //             this.router.navigate(['/dashboard']);
        //             return false;
        //         }
        //     }

        // },
        // err => {
        //     // Log errors if any
        //     console.log(err);
        // })

        this.itemsByPage = 20;
        this.query_string = environment.profileURL+`/api/v1/download_managers.json?`;
        this.Query_String = this.query_string;
        this.isLoading = true;
        this.http.get(`${environment.profileURL}/api/v1/download_managers.json?per_page=${this.itemsByPage}`).subscribe(data => {
            console.log(data);
            this.downloads = data['download_managers'];
            this.refresh_all = false;
            this.meta = data['meta'];
            this.count = data['meta']['total_count'];
            this.pageLen = this.meta['total_pages'];
            this.curPage = 0;
            this.isLoading = false;
            this.item = [];
            for(var i = 1; i<=this.pageLen;i++){
                this.item.push(i);
            }
            if(this.count > this.itemsByPage){
                this.render( this.curPage, this.item, true );
                console.log(data);
            }
        },
            error => console.log('oops', error))

  	}

      public isPageInRange = function( curPage, index, maxPages, pageBefore, pageAfter ) {
        if (index <= 1) {
            // first 2 pages
            return true;
        }
        if (index >= maxPages - 2) {
            // last 2 pages
            return true;
        }
        if (index >= curPage - pageBefore && index <= curPage + pageAfter) {
            return true;
        }
    }

    public render = function( curPage, item, first ) {
        var html = '', separatorAdded = false;
        for(var i in item){
            if ( this.isPageInRange( curPage, i, this.pageLen, 2, 2 ) ) {
                html += '<li data-page="' + i + '">' + item[i] + '</li>';
                // as we added a page, we reset the separator Added
                separatorAdded = false;
            } else {
                if (!separatorAdded) {
                    // only add a separator when it wasn't added before
                    html += '<li class="separator" />';
                    separatorAdded = true;
                }
            }
        }
        var holder = document.querySelector('#holder');
        if(this.meta.total_count > this.itemsByPage){
            holder.innerHTML = html;
            if(document.querySelector('#holder>li[data-page="' + curPage + '"]') != null){document.querySelector('#holder>li[data-page="' + curPage + '"]').classList.add('active');}
            if ( first ) {
                holder.addEventListener('click', e => {
                    console.log(e.target['attributes']['data-page'].value);
                    if (!e.target['attributes']['data-page'].value) {
                        // no relevant item clicked (you could however offer expand here )
                        return;
                    }

                    curPage = parseInt(e.target['attributes']['data-page'].value);
                    var page_number = curPage+1;
                    this.isLoading = true;
                    this.http.get(`${this.query_string}&page=${page_number}`).subscribe(data => {
                        this.downloads = data['download_managers'];
                        this.meta = data['meta'];
                        this.count = data['meta'].total_count;
                        this.pageLen = this.meta.total_pages;
                        this.isLoading = false;
                        this.curPage = 0;
                        this.item = [];
                        for(var i = 1; i<=this.pageLen;i++){
                            this.item.push(i);
                        }

                    },
                        error => console.log('oops', error)
                    )
                    this.render( curPage, item );
                });
            }
        }else{
            holder.innerHTML = null;
        }
    }

    public updatePerpage(){
        this.isLoading=true;
        this.query_string = this.Query_String+'per_page=' + this.itemsByPage;
        this.http.get(`${this.query_string}`).subscribe(data => {
            this.isLoading = false;
            this.downloads = data['download_managers'];
            this.meta = data['meta'];
            this.count = data['meta']['total_count'];
            this.pageLen = this.meta['total_pages'];
            this.curPage = 0;
            this.item = [];
            for(var i = 1; i<=this.pageLen;i++){
                this.item.push(i);
            }
            this.render( this.curPage, this.item, true );

        },
            error => console.log('oops', error))
    }

  	public cancel() {
  		this.location.back(); // <-- go back to previous location on cancel
  	} 

    public refresh() {
        this.ngOnInit();
        this.refresh_all = true;
    }

}
