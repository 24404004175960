import { Component, OnInit } from '@angular/core';
import { SlideoutContentService } from '../../../service/slideout-content.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-cn-print-implement',
  templateUrl: './cn-print-implement.component.html',
  styleUrls: ['./cn-print-implement.component.scss']
})
export class CnPrintImplementComponent implements OnInit {

  env:any;
  constructor(public slideContent:SlideoutContentService) { }

  ngOnInit(): void {
    this.env = environment;
  }

  // open side popup
  public openPopup(content) {
    console.log(content)
    this.slideContent.process_type.next(content);
  }

}
