import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonDataService } from './../../common-data.service';
import { Location } from '@angular/common'
import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {Router, ActivatedRoute} from '@angular/router';
import { Store } from '@ngrx/store';

@Component({
  selector: "app-your-certificate",
  templateUrl: "./your-certificate.component.html",
  styleUrls: ["./your-certificate.component.scss"],
})
export class YourCertificateComponent implements OnInit {
  public profile: any;
  public certi_date: any;
  public env: any;
  public certificate: any;
  public certi_err: boolean = false;
  firstname: string = "";
  lastname: string = "";

  constructor(
    public router: Router,
    public http: HttpClient,
    public location: Location,
    public service: CommonDataService,
	public store: Store<any>,
  ) {}

  ngOnInit() {
    var Data = {
      email: localStorage.getItem("email"),
      property: "certz",
    };
    var firstName = {
      email: localStorage.getItem("email"),
      property: "firstname",
    };
    var lastName = {
      email: localStorage.getItem("email"),
      property: "lastname",
    };

    this.http
      .post(`${environment.profileURL}/api/v1/hubspot/details`, firstName)
      .subscribe(
        (res) => {
          this.firstname = res["firstname"];
        },
        (err) => {
          console.log(err.error);
          var that = this;
          setTimeout(function () {
            this.toastr.options = {
              closeButton: true,
              progressBar: true,
              timeOut: 2000,
            };
            this.toastr.error("Oops something went wrong!");
            that.location.back();
          }, 0);
        }
      );
    this.http
      .post(`${environment.profileURL}/api/v1/hubspot/details`, lastName)
      .subscribe(
        (res) => {
          this.lastname = res["lastname"];
        },
        (err) => {
          console.log(err.error);
          var that = this;
          setTimeout(function () {
            this.toastr.options = {
              closeButton: true,
              progressBar: true,
              timeOut: 2000,
            };
            this.toastr.error("Oops something went wrong!");
            that.location.back();
          }, 0);
        }
      );
    var css = "@page { size: landscape; }",
      head = document.head || document.getElementsByTagName("head")[0],
      style = document.createElement("style");

    style.type = "text/css";
    style.media = "print";

    style.appendChild(document.createTextNode(css));

    head.appendChild(style);

    this.http
      .post(`${environment.profileURL}/api/v1/hubspot/details`, Data)
      .subscribe(
        (res) => {
          var that = this;
          console.log(res);
          if (res["certz"] == null) {
            this.certificate = [];
          } else {
            this.certificate = res["certz"].replace(/\s/g, "").split(";");
            if (this.router.url.includes("qmp")) {
              for (var i = 0; i < this.certificate.length; i++) {
                if (this.certificate[i] != "qmp") {
                  this.certificate.splice(i, 1);
                  i--;
                }
              }
            }
            if (this.router.url.includes("cmf")) {
              for (var i = 0; i < this.certificate.length; i++) {
                if (this.certificate[i] != "cmf") {
                  this.certificate.splice(i, 1);
                  i--;
                }
              }
            }
            console.log("All Certificates");
            console.log(this.certificate);
          }
        },
        (err) => {
          console.log(err.error);
          var that = this;
          setTimeout(function () {
            this.toastr.options = {
              closeButton: true,
              progressBar: true,
              timeOut: 2000,
            };
            this.toastr.error("Oops something went wrong!");
            that.location.back();
          }, 0);
        }
      );

    this.env = environment;
    this.certi_date = new Date();
    this.store
      .select<any>((state) => state.profile?.account.default_profile)
      .subscribe(
        (message) => {
          if (message) {
            this.profile = message;
            console.log(message);
            var that = this;
            setTimeout(function () {
              $(".navbar-default").hide();
              $("body").addClass("print-body");
              window.print();
              $(".navbar-default").show();
              $("body").removeClass("print-body");
              that.location.back();
            }, 3000);
          }
        },
        (err) => {
          // Log errors if any
          console.log(err);
        }
      );
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  printCertificate() {
    $(".navbar-default").hide();
    $("body").addClass("print-body");
    window.print();
    $(".navbar-default").show();
    $("body").removeClass("print-body");
  }
}
