import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import {CommonDataService} from './../common-data.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { environment } from './../../../environments/environment';
import {AccessGroupService} from './../access-group.service';
import { Store } from '@ngrx/store';

declare var jQuery:any;

@Component({
  selector: 'app-account-emails',
  templateUrl: './account-emails.component.html',
   encapsulation: ViewEncapsulation.None,
  styleUrls: ['./account-emails.component.scss']
})
export class AccountEmailsComponent implements OnInit {

	public profiles:any;
	public modal:any;
	public profilefirstName:string;
	public profilelastName:string;
	public profilemail:string;
	public profilephone:string;
	public profileskype:string;
	public emails:any;
    claims_pending:any;
    ngrxAccessGroup:any;

  	constructor(public accessgroup:AccessGroupService, public service:CommonDataService, private modalService: NgbModal, public http: HttpClient, public router: Router, public store:Store<any>) { }

  	ngOnInit() {
        this.ngrxAccessGroup = this.store.select<any>((state) => state.profile.accessGroupData)
        // it re-directs url to the dashboard page if access to this page is not permitted.
        this.ngrxAccessGroup.subscribe(message => { 
            if(message){                
                this.emails = message['menu_account']?.sharing;
                if(!message['menu_account']?.sharing && message.length > 0){
                    this.router.navigate(['/dashboard']);
                    return false;
                }
            }
        },
        err => {
            // Log errors if any
            console.log(err);
        })

        this.http.get(`${environment.profileURL}/api/v1/identity_claims.json`).subscribe(data => {
           console.log(data);
           this.claims_pending = data['identity_claims'];
        },
            error => console.log('oops', error)
        )

		this.service.Profile().subscribe(message => { 
			if(message){
		    	this.profiles = message;
		    	console.log(this.profiles);
			}

		},
		err => {
            // Log errors if any
            console.log(err);
        })
         
  	}
  	public open(){
        $('.new-profile-modal').css('display','block');
  		// this.modal = this.modalService.open(content, { windowClass: 'in grey' });
  	}

  	// To change default profile
  	public updateProfile(nmbr){
  		console.log(nmbr);
  		this.http.patch(`${environment.profileURL}/api/v1/profiles/${this.profiles[nmbr].id}/mark_default.json`,this.profiles[nmbr]).subscribe(res => {
            console.log(res);
  			setTimeout(function() {
	            this.toastr.options = {
	                closeButton: true,
                    progressBar: true,
	                timeOut: 2000
	            };
	            this.toastr.success('Your profile preference has been updated successfully!', 'Nice!');
        	}, 1000);
        },
            error => console.log('oops', error))
        localStorage.setItem('member_id',this.profiles[nmbr].id);
  		
  	}

  	// To add profile
  	public addProfile(){
  		var Data = {
  			email: this.profilemail,
  			first_name: this.profilefirstName,
  			last_name: this.profilelastName, 
  			phone: this.profilephone, 
  			skype: this.profileskype
  		}
  		console.log(Data);
  		if(this.profilemail){
  		this.http.post(`${environment.profileURL}/api/v1/identity_claims.json`,Data)
    	.subscribe(
    		res => {	    		
	    		console.log(res);
	  			setTimeout(function() {
		            this.toastr.options = {
		                closeButton: true,
                        progressBar: true,
		                timeOut: 2000
		            };
		            this.toastr.success('Please check your email for confirmation!', 'Nice!');
	        	}, 1000);
	        	this.modal.close();
                this.ngOnInit();
			},
			err =>{
				console.log(err.error);
	  			setTimeout(function() {
		            this.toastr.options = {
		                closeButton: true,
                        progressBar: true,
		                timeOut: 2000
		            };
		            this.toastr.error(err.error.errors, 'oops!');
	        	}, 1000);
			}
		)   
		}             
  	}

    public resendConfirm(id){
        console.log(id);
        this.http.get(`${environment.profileURL}/api/v1/identity_claims/`+id+`/resend_confirmation_mail.json`).subscribe(data => {
           console.log(data);
           setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.success('Please check your email for confirmation!', 'Nice!');
            }, 1000);
        },
        err =>{
                console.log(err.error);
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 2000
                    };
                    this.toastr.error(err.error.error, 'oops!');
                }, 1000);
            }
        )
    }

    public deleteIdentity(id){
        console.log(id);
        this.http.delete(`${environment.profileURL}/api/v1/identity_claims/`+id+`.json`).subscribe(data => {
           console.log(data);
           setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.success('Your Identity Claim has been deleted!', 'Nice!');
            }, 1000);
            this.http.get(`${environment.profileURL}/api/v1/identity_claims.json`).subscribe(data => {
                console.log(data);
                this.claims_pending = data['identity_claims'];
            },
                error => console.log('oops', error)
            )
        },
        err =>{
                console.log(err.error);
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 2000
                    };
                    this.toastr.error(err.error.error, 'oops!');
                }, 1000);
            }
        )
    }

}
