import { Component } from '@angular/core';

@Component({
  selector: 'app-myteams-indvidual',
  templateUrl: './myteams-indvidual.component.html',
  styleUrls: ['./myteams-indvidual.component.scss']
})
export class MyteamsIndvidualComponent {

  private individualId;

  ngOnInit() {
    
  }
}
