import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment';
@Component({
	selector: 'app-page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
	
	cart;
	public cartId:any;
	env;

	constructor(public http:HttpClient) { }

	ngOnInit() {

        $(".child-trigger").on("click" , function(e) {
            e.preventDefault();
            if( window.innerWidth <= 1080 ) {
                if($(this).hasClass("child-open")) {
                    $(this).removeClass("child-open");
                    $(this).next("ul.hs-menu-children-wrapper").slideUp();
                } else {
                    $(this).addClass("child-open");
                    $(this).next("ul.hs-menu-children-wrapper").slideDown();
                }
            }
        });

		if(localStorage.getItem('cart_id')){
            this.cartId = localStorage.getItem('cart_id');
            this.http.get(`${environment.assessment_admin}api/v1/public/carts/`+this.cartId).subscribe(data => {
                console.log(data);
                this.cart = data['order']['line_items'];
            },
                error => {
                    console.log('oops', error);
                    if(error.status == 500){
                        setTimeout(function() {
                            this.toastr.options = {
                              closeButton: true,
                              timeOut: 2000
                            };
                            this.toastr.error('Something went wrong!', 'Sorry!');
                        }, 1000);
                    }
                    else if(error.status == 422){
                        setTimeout(function() {
                            this.toastr.options = {
                              closeButton: true,
                              timeOut: 4000
                            };
                            if(!error.error.errors.base[0]){
                                this.toastr.error(error.error.errors, 'oops!');
                            }else{
                                this.toastr.error(error.error.errors.base[0], 'oops!');
                            }
                        }, 1000);
                    }else{
                        setTimeout(function() {
                            this.toastr.options = {
                              closeButton: true,
                              timeOut: 2000
                            };
                            this.toastr.error('Something went wrong!', 'oops!');
                        }, 1000);
                    }
                }
            )
        }else{
            this.cart = [];
        }
	}

	public showMenu(){        
        if($('.mobile-trigger').hasClass('active-re')){
            $('.mobile-trigger').removeClass('active-re');
            $('.bm-menu__inner').slideUp();
        }else{
            $('.mobile-trigger').addClass('active-re');
            $('.bm-menu__inner').slideDown();
        }
    }

    public hideMenu(){
        $('.mobile-trigger').addClass('active-re');
    }

}
