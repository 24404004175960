import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CommonDataService } from './../common-data.service';
import { HttpClient } from '@angular/common/http';
import { Validators } from '@angular/forms';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-my-branding',
  templateUrl: './my-branding.component.html',
  styleUrls: ['./my-branding.component.scss']
})
export class MyBrandingComponent implements OnInit {

	public env;
	public brand_identity:any;
	public oldValue:any;
	public editStart: number = 1;
	public new_brand_identity:any = [];
    public member_id:any = localStorage.getItem('member_id');
    email_validation:boolean = true;
    phone_validation:boolean = true;
    accountNgrx:any;

  	constructor(private http:HttpClient, public service:CommonDataService,public store:Store<any>  ) { }

  	ngOnInit() {
        this.accountNgrx = this.store.select<any>((state) => state.profile.account);
  		this.env = environment;
        $('.loader-container').css('display','flex');
        this.getBrandingData();
  	}

  	public editProfile(edit:number){
  		this.editStart = edit;
  		if(this.brand_identity){
            this.new_brand_identity = this.brand_identity;
        }
        else{
            this.new_brand_identity['first_name'] = '';
            this.new_brand_identity['last_name'] = '';
            this.new_brand_identity['email'] = '';
            this.new_brand_identity['phone'] = '';
            this.new_brand_identity['company'] = '';
            this.new_brand_identity['org_website'] = '';
            this.new_brand_identity['profile_type'] = '';
        }
        $('.loader-container').css('display','none');
  	}

  	public cancelProfile(){
  		this.editStart = 0;
        // this.http.get(`${environment.profileURL}/api/v1/members/`+this.member_id+`.json`).subscribe(result => {
        //     console.log(result);
        //     this.brand_identity = result['member']['brand_identity'];
        // },
        // error => console.log('oops', error)
        // )
        this.accountNgrx.subscribe(result => {
            console.log(result);
            this.brand_identity = result["default_profile"]?.source_owner?.cobranding_identity;
        },
        error => console.log('oops', error)
        )
  	}

  	public saveProfile(){ 
        $('.loader-container').css('display','flex');
        console.log(this.new_brand_identity?.profile_title);
        let allprofiles  = ["Developer","Clarifier","Ideator","Implementer","Early Bird","Analyst","Accelerator","Theorist","Driver","Finisher","Hare","Idea Broker","Optimist","Integrator","Realist"];
        console.log(allprofiles.indexOf(this.new_brand_identity?.profile_title))
        let index = allprofiles.indexOf(this.new_brand_identity?.profile_title) + 1;
        
        // let phone_pattern= /^[+]\d{1}?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
        let email_pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

        // if(email_pattern.test(this.new_brand_identity?.email)) {
        //     this.email_validation = false;
        // } else {
        //     this.email_validation = true;
        // }

        // if(phone_pattern.test(this.new_brand_identity?.phone)){
        //    this.phone_validation = false;
        // } else {
        //     this.phone_validation = true;
        // }
       

        if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.new_brand_identity?.email)){
            console.log('ad');
            this.http.post(`${environment.profileURL}/api/v1/users/update_cobranding_identity.json`,{ first_name: this.new_brand_identity.first_name, last_name: this.new_brand_identity.last_name,organization: this.new_brand_identity.organization, website: this.new_brand_identity.website, email: this.new_brand_identity?.email, phone: this.new_brand_identity?.phone, profile_type_id: index }).subscribe(result => {
                console.log(result);
                this.cancelProfile();
            },
            error => console.log('oops', error)
            )
        } else {
            console.log('else blk');
            // this.validatePhone();
            this.validateEmail();
        }
        setTimeout(() => {
            $('.loader-container').css('display','none');
        },500)

        console.log(this.phone_validation);
        console.log(this.email_validation);
  	}

    public validateEmail(){
        if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.new_brand_identity?.email)){
            this.email_validation = true;
        } else {
            this.email_validation = false;
        }
    }
    
    // public validatePhone(){
    //     if(/^[+]\d{1}?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(this.new_brand_identity?.phone)){
    //         this.phone_validation = true;
    //     } else {
    //         this.phone_validation = false;
    //     }
    // }

    // get branding data 
    public getBrandingData() {
        this.http.get(`${environment.profileURL}/api/v1/users/cobranding_identity.json`).subscribe(res=>{
            console.log(res);
            this.brand_identity = res["branding_identity"];
            this.editProfile(0);
            if(localStorage.getItem('branding') == "true") {
                this.editProfile(1);
                document.getElementById("brandEmail").focus();
                localStorage.removeItem('branding')
            }
        }, err => {
            console.log(err)
        });
    }

}
