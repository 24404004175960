// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-imitation.right-form-section {
  padding: 30px;
  min-height: auto !important;
  height: auto;
}

::ng-deep.image-imitation.right-form-section form .hs-submit .actions {
  padding-bottom: 0 !important;
}

::ng-deep.btn-primary {
  white-space: normal;
}`, "",{"version":3,"sources":["webpack://./src/app/components/learner-maps/new-pricing-model/new-pricing-card-single/new-pricing-card-single.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,2BAAA;EACA,YAAA;AACJ;;AAEA;EACC,4BAAA;AACD;;AAEA;EACC,mBAAA;AACD","sourcesContent":[".image-imitation.right-form-section {\n    padding: 30px;\n    min-height: auto !important;\n    height: auto;\n    \n}\n::ng-deep.image-imitation.right-form-section form .hs-submit .actions {\n\tpadding-bottom: 0 !important;\n}\n\n::ng-deep.btn-primary{\n\twhite-space: normal;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
