import { Component, OnInit } from '@angular/core';
// import { Auth } from '../../auth/auth.service';
import { Location } from '@angular/common'
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {CommonDataService} from './../common-data.service';
import { environment } from './../../../environments/environment';
import {AccessGroupService} from './../access-group.service';
import { Store } from '@ngrx/store';
import { CigSlideshowService } from './cig-slideshow.service';

declare var jQuery: any;

@Component({
    selector: 'app-cig',
    templateUrl: './cig.component.html',
    styleUrls: ['./cig.component.scss']
})
export class CigComponent implements OnInit {
	

  	public profiles:any;
  	public slides:Object[]
  	public slides_text:Object;
  	public author:Object;
  	public profileLabel:Object[];
  	public foursightassets:Object;
    public env;
    public currentSlide : number = 1;
    public textSlide : boolean = false;
    audio_mute:boolean = false;
    ngrxAccessGroup:any;

  	constructor( public accessgroup:AccessGroupService, private location:Location, public http: HttpClient,public service:CommonDataService, public router: Router, public store:Store<any>, public cigService: CigSlideshowService) { }
  	
  	cancel() {
    	this.location.back(); // <-- go back to previous location on cancel
  	}
	
	ngOnInit() {
        this.ngrxAccessGroup  = this.store.select<any>((state) => state.profile.accessGroupData);
        // it re-directs url to the dashboard page if access to this page is not permitted.
        this.ngrxAccessGroup.subscribe(message => { 
            if(message && message.length > 0){                
                if(message['main_menu'].profile.profile_slideshow.slideshow != true){
                    this.router.navigate(['/dashboard']);
                    return false;
                }
            }

        },
        err => {
            // Log errors if any
            console.log(err);
        })

        this.env=environment;

        this.getDataFromApi();

        this.slides_text = this.cigService.slides_text;
        this.slides = this.cigService.slides;
        this.slides[1]["from"] = "from cig "
        this.profileLabel = this.cigService.profileLabel;
        this.author = this.cigService.foursightassets;
		// OWL
        this.owlSlider();
		// $(window).on('load resize orientationchange', function () {
        
  	}

    public getDataFromApi() {
        this.http.get(`${environment.profileURL}/api/v1/profiles.json`,{params:{lang:"en"}}).subscribe(res => {
            if(res != null){
                for(var i=0;i<res['profiles'].length;i++){
                    if(res['profiles'][i].default==true){
                        this.profiles = res['profiles'][i];
                    }
                }
                
            }
            if(res == null){
                alert('Unable to get the required info. Please contact support in the chat window below.');
            }
            console.log(res);
        },
        err => {
            // Log errors if any
            console.log(err);
            alert('Unable to get the required info. Please contact support in the chat window below.');
        })
    }

    public owlSlider() {
        var global = this;
		jQuery(document).ready(function($){

		    var owl = $('#owl-demo');
		    owl.owlCarousel({

		        navigation : true, // Show next and prev buttons
		        slideSpeed : 300,
		        paginationSpeed : 400,
                items: 3,
		        // responsiveBaseWidth: "#page-wrapper",
		        // Responsive
		        responsive: true,
		        mouseDrag: false,
		        touchDrag: false,
		        responsiveRefreshRate : 200,
		        responsiveBaseWidth: "#page-wrapper",

		        singleItem:true,

		        //Callbacks
		        afterMove: function(elem){
                    var that = this;

                    var currentItem = that.currentItem + 1;
                    global.currentSlide = currentItem;
                    var counter = 0;
		            $('.owl-pagination .owl-page').removeClass('active');

		            $('.owl-pagination .owl-page').each(function(index, el){
    	                if(counter == currentItem){
    	                    return false;
    	                }else{
    	                    $(el).addClass('active');
	                    }

		                counter++;
                    });
                    
                    if (that.currentItem == 27) {
                        $('.owl-next').on('click', function () {
                            if (that.currentItem == 0) {
                                global.router.navigate(['/fs-profile']);
                            }
                        });
                    }

	                setTimeout(function () {
                        $('audio').trigger("pause");
                        $('.owl-item:nth-child('+currentItem+') .feedback-footer audio').css("width", "40px");

	                    setTimeout(function(){
	                        $('.owl-item:nth-child('+currentItem+') .feedback-footer audio').css("width", "70%").trigger("play");
                        }, 0);
	                }, 250);
		        }

		    });

		    $('#full-screen').on('click', function(e){
		        e.preventDefault();
		        $('body').addClass('full-screen');
	            if($('.owl-carousel').length){
		            setTimeout(function () {
		                var owl = $(".owl-carousel").data('owlCarousel');
		                owl.updateVars();
		            }, 500);
		        }

		    });

		    $('#exit-screen').on('click', function(e){
		        e.preventDefault();
		        $('body').removeClass('full-screen');
		        if($('.owl-carousel').length){
		            setTimeout(function () {
    		            var owl = $(".owl-carousel").data('owlCarousel');
    		            owl.updateVars();
		            }, 500);
		          }
		    });
		});
    }

    public printProfile(){
        $('.navbar-default').hide();
        $('body').addClass('print-body');
        window.print();
        $('.navbar-default').show();
        $('body').removeClass('print-body');
    }

    showTranslation(slide){
        this.textSlide = slide;
    }

    public mute(val){
        $('audio').prop('muted', val);
        this.audio_mute = val;
    }
}

