import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Router, ActivatedRoute, Params} from '@angular/router';
// import { Auth } from '../../../auth/auth.service';
import { environment } from './../../../../environments/environment';
// import swal from 'sweetalert2/dist/sweetalert2.js';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
// import { Angulartics2Segment } from 'angulartics2';
import { Location } from '@angular/common';
import {AccessGroupService} from './../../access-group.service';

declare var jQuery:any;
declare var crs:any;
declare var Stripe:any;

@Component({
	selector: 'app-store-cart',
	templateUrl: './store-cart.component.html',
	styleUrls: ['./store-cart.component.scss']
})

export class StoreCartComponent implements OnInit {
	public cart:any;
	public blocker: string = '';
	public data:Array<Object> = [];
	public total:number=0;
	public net_amount:string;
	public tax:string;
	public shipping_charges: string;
	discounted_items_price:string;
	shipping_country:string;
	billing_country:string;
	shipping_state:string;
	shipping_city:string;
	shipping_postcode:string;
	shipping_method:string;
	orgs:any;
	cart_pdts:any;
	showDetails:boolean;
	showMethod:boolean;
	billing_address:any;
	shipMethod:any;
	showAdrs:any;
	showBillAdrs:any;
	billing_state:any;
	billing_city:any;
	billing_postcode:any;
	payment:any;
	showCard:any;
	billing_first_name:string;
	billing_last_name:string;
	shipping_first_name:string;
	shipping_last_name:string;
	org_shipping:string;
	ad_line1_shipping:string;
	ad_line1_billing:string;
	ad_line2_shipping:string;
	ad_line2_billing:string;
	card_number:any;
	card_cvv:any;
	card_expiry:any;
	card_first_name:string = "First ";
	card_last_name:string = "Last";
	addresses:any;
	getBillingAdrs:any;
	addBillAdrs:boolean;
	getShippingAdrs:any;
	addShipAdrs:boolean;
	showShipAdrs:boolean;
	adrsData:any;
	ship_methods:any;
	listMembers:any;
	org_billing:any;
	email_billing:string;
	email_shipping:string;
	shipping_nmbr:any;
	billing_nmbr:any;
	adrs_type_shipping:string;
	adrs_type_billing:string;
	cards:any;
	card_save:any;
	SaveCard:any;
	orderStatus:any;
	orderID:any;
	payment_status:any;
	total_discount:any;
	promo_discount:any;
	volume_discount:any;
	first_shipId:any;
	status1:boolean;
	update_org:string;
	update_address_name:any;
	update_country:any;
	update_state:any;
	update_city:any;
	update_postal_code:any;
	update_address_first_name:string;
	update_address_last_name:string;
	update_address_email:string;
	update_address_line1:string;
	update_address_nmbr:any;
	update_address_line2:string;
	needs_shipping:boolean;
	editBillAdrs:boolean;
	env:any;
	modal:any;
	modal1:any;
	new_card:boolean = false;
	editShipAdrs:boolean;
	updateAdrsId:any;
	weight_error:boolean = false;
	showBillDetails:boolean = false;
	ship_weight_error: string;
	loading:boolean = false;
	public estTime;
	public cstTime;
	shipMethod_loading:boolean = false;
	card_loading:boolean = false;
	coupon: string = '';
	loading_coupon:boolean = false;
	afterShipping_amount:number = 0;
	applied_code: string = '';
	shipping_method_name: string = '';
	cartId:any;
	store : any;
	save_card:any = true;
	selected_card_last4;
	shipping_alert:string = '';
	@ViewChild('stripecard') stripe_card;

	constructor(public accessgroup:AccessGroupService, public location: Location, private activatedRoute: ActivatedRoute, private router: Router,public http:HttpClient, private modalService: NgbModal) { }

	ngOnInit() {
        this.store = localStorage.getItem('store');
		this.updateAdrsId = 0;
		this.env = environment;
		this.editShipAdrs = false;
		this.editBillAdrs = false;
		this.needs_shipping = false;
		this.status1 = true;
		this.volume_discount = '';
		this.promo_discount = '';
		this.total_discount = '';
		this.orderID = '';
		this.payment_status = '';
		this.SaveCard = false;
		this.card_save = '';
		this.shipping_nmbr = '';
		this.billing_nmbr = '';
		this.email_billing = '';
		this.email_shipping = '';
		this.card_number = '';
		this.card_expiry = '';
		this.card_cvv = '';
		this.ship_methods = [];
		this.adrsData = '';
		this.getBillingAdrs = 0;
		this.getShippingAdrs = 0;
		this.addBillAdrs = false;
		this.addShipAdrs = false;
		this.showCard = false;
		this.orderStatus = false;
		this.billing_first_name = '';
		this.billing_last_name = '';
		this.org_shipping = '';
		this.org_billing = '';
		this.shipping_first_name = '';
		this.shipping_last_name = '';
		this.ad_line1_shipping = '';
		this.ad_line1_billing = '';
		this.ad_line2_shipping = '';
		this.ad_line2_billing = '';
		this.payment = false;
		this.showAdrs = true;
		this.showBillAdrs = false;
		this.showShipAdrs = true;
		this.showMethod = false;
		this.shipMethod = false;
		this.showDetails = true;
		this.shipping_postcode = '';
		this.shipping_city = '';
		this.shipping_method = '';
		this.shipping_country = '';
		this.shipping_state = '';
		this.billing_postcode = '';
		this.billing_city = '';
		this.billing_country = '';
		this.billing_state = '';
		this.adrs_type_shipping = '';
		this.adrs_type_billing = '';
		this.total = 0;
		this.cartId;
		this.http.get(`${environment.assessment_admin}api/v1/addresses`).subscribe(data => {
            console.log(data);
            if(data['errors']){
        		setTimeout(function() {
					this.toastr.options = {
					  closeButton: true,
					  timeOut: 10000
					};
					this.toastr.error(data['errors'], 'oops!');
				}, 1000);
        	}else{
	            this.addresses = data['addresses'];
	            if(data['addresses'][0].id){
		            // this.getBillingAdrs = data[0].id;
		            // this.first_shipId = data[0].id;
		        }else{
		        	this.new_card = false;
		        }
		    }
         //    console.log(this.addresses[0])
        },
            error => {
            	this.showError(error);
			}
        )

        this.http.get(`${environment.assessment_admin}api/v1/carts`).subscribe(data => {
            console.log(data);
            this.total_discount = data['order']['total_discount'];
            this.volume_discount = data['order']['volume_discount'];
            this.promo_discount = data['order']['promo_discount'];
            this.cartId = data['order']['id'];
            this.cart = data['order']['line_items'];
            if(data['order']['line_items'].length > 0){
            	this.status1 = false;
            }
            if(data['order']['needs_shipping'] == true){
            	this.needs_shipping = true;
            	console.log(1);
            }else{
            	this.needs_shipping = false;
            	this.showBillAdrs = true;
            	this.showBillDetails = true;
            }
            if(data['order']['applied_discount_code']){
            	this.applied_code = data['order']['applied_discount_code'];
            }
            this.total = data['order']['subtotal_amount'];
            this.net_amount = data['order']['net_amount'];
			this.tax = data['order']['tax_amount'];
			this.shipping_charges = data['order']['shipping_amount'];
			this.discounted_items_price = data['order']['discounted_items_price'];
			this.afterShipping_amount = parseFloat(this.net_amount.replace(',','')) - parseFloat(this.shipping_charges.replace(',',''));
			var products = [];
			for(var i= 0; i< this.cart.length;i++){
				products.push({
	        		product_id: this.cart[i].variant.product_id,
					sku: this.cart[i].variant.sku,
					name: this.cart[i].variant.name,
					price: this.cart[i].variant.price,
					image_url: this.cart[i].variant.image_url
	        	})
			}
			// this.angulartics2Segment.eventTrack('Cart Viewed', 
	        //     { 
	        //     	cart_id: this.cartId,
	        //         products: products
	        //     }
	        // );
			console.warn(this.cart)
        },
            error => {
            	this.showError(error);
			}
        )

        this.http.get(`${environment.assessment_admin}api/v1/credit_cards`).subscribe(data => {
            console.log(data);
            this.cards = data['credit_cards'];
            if(this.cards.length < 1 && this.getBillingAdrs != 0){
            	this.new_card = true;
            }
        },
            error => {
            	this.showError(error);
			}
        )  
        // this.angulartics2Segment.eventTrack('Page', 
        //     { 
        //         email: localStorage.getItem('email'),
        //         funnel: 'Store',
        //         cta_location: 'Cart Page',
        //         url: window.location.origin + this.location.path()
        //     }
        // );  

		
    	
		$('.price-hide').hide();
		setTimeout(function() {
            crs.init();
        }, 0);

        setInterval(() => this.displayEST(), 1000);

		this.delay_delv_notifications()
	}

	public delay_delv_notifications() {
		this.http.get(`${this.env.assessment_admin}api/v1/public/system_settings.json`).subscribe(data => {
        	// this.system_notifications = data;
			this.shipping_alert = data[2]['shipping_alert'];
			// var result = data.find(item => item.id === 2);
        },
            error => {
                console.log('oops', error);
                if(error.status == 500){
                    setTimeout(function() {
                        this.toastr.options = {
                          closeButton: true,
                          timeOut: 2000
                        };
                        this.toastr.error('Something went wrong!', 'Sorry!');
                    }, 1000);
                }
			})
	}

  	public complimentaryPdt(){
  		setTimeout(function() {
	  		this.toastr.options = {
				closeButton: true,
				timeOut: 5000
			};
			this.toastr.success('This product is a complement to the product above it and cannot be edited independently.', 'Hey!');
		}, 500);
  	}

	// update the price when quantity of product changes
	public updatePrice(nmbr){
		this.weight_error = false;
		$('.price-hide').hide();
		this.showBillAdrs = true;
		this.ship_methods = [];
		this.showShipAdrs = false;
		this.shipMethod = false;
		this.showMethod = false;
		this.payment = false;
		this.showCard = false;
		if(jQuery('#'+nmbr).val() != ''){
			var counter = 0;
	    	this.listMembers = [];
	    	console.log($('#'+nmbr).val())
			this.cart.forEach(list =>{
				if(nmbr == list.variant.id){
					if(list.organization_id != null){
						this.listMembers[counter] = { 'variant_id': parseInt(list.variant.id), 'quantity': parseInt(jQuery('#'+nmbr).val()), 'organization_id': parseInt(list.organization_id) };
						counter++;
					}else{
						this.listMembers[counter] = { 'variant_id': parseInt(list.variant.id), 'quantity': parseInt(jQuery('#'+nmbr).val()) };
						counter++;
					}

				}else{
					if(list.organization_id != null){
						this.listMembers[counter] = { 'variant_id': parseInt(list.variant.id), 'quantity': parseInt(list.quantity), 'organization_id': parseInt(list.organization_id) };
						counter++; 
					}else{
						this.listMembers[counter] = { 'variant_id': parseInt(list.variant.id), 'quantity': parseInt(list.quantity) };
						counter++;
					}
				}   		  			
			});
			this.http.post(`${environment.assessment_admin}api/v1/carts/update_items`,{"line_items":this.listMembers}).subscribe(data => {
	            console.log(data);
	            this.total_discount = data['order']['total_discount'];
	            this.volume_discount = data['order']['volume_discount'];
	            this.promo_discount = data['order']['promo_discount'];
	            this.cart = data['order']['line_items'];
	            if(data['order']['needs_shipping'] == true){
	            	this.needs_shipping = true;
	            	this.showAdrs = true;
	            	this.showBillDetails = false;
	            	this.showBillAdrs = false;
	            }else{
	            	this.needs_shipping = false;
	            	this.showBillAdrs = true;
	            	this.showBillDetails = true;
	            }
	            if(data['order']['applied_discount_code']){
	            	this.applied_code = data['order']['applied_discount_code'];
	            }
	            this.total = data['order']['subtotal_amount'];
	            this.net_amount = data['order']['net_amount'];
				this.tax = data['order']['tax_amount'];
				this.shipping_charges = data['order']['shipping_amount'];
				this.discounted_items_price = data['order']['discounted_items_price'];
				this.afterShipping_amount = parseFloat(this.net_amount.replace(',','')) - parseFloat(this.shipping_charges.replace(',',''));
				this.getShippingAdrs = 0;
				this.shipping_method = '';
				this.payment = false;
				this.showCard = false;
				this.card_loading = false;
	        },
	            error => {
	            	this.showError(error);
				}
	    	)
		}
	}

	// update cart when a product is removed
	public remove(id){
		this.http.get(`${environment.assessment_admin}api/v1/carts/remove_item?variant_id=${id}`).subscribe(data => {
            console.log(data);
            this.total_discount = data['order']['total_discount'];
            this.volume_discount = data['order']['volume_discount'];
            this.promo_discount = data['order']['promo_discount'];
            this.cart = data['order']['line_items'];
            if(data['order']['needs_shipping'] == true){
            	this.needs_shipping = true;
            	this.showAdrs = true;
            	this.showBillDetails = false;
            	this.showBillAdrs = false;
            }else{
            	this.needs_shipping = false;
            	this.showBillAdrs = true;
            	this.showBillDetails = true;
            }
            if(data['order']['applied_discount_code']){
            	this.applied_code = data['order']['applied_discount_code'];
            }else{
            	this.applied_code = '';
            }
            this.total = data['order']['subtotal_amount'];
            this.net_amount = data['order']['net_amount'];
			this.tax = data['order']['tax_amount'];
			this.getShippingAdrs = 0;
			this.shipping_method = '';
			this.shipping_charges = data['order']['shipping_amount'];
			this.discounted_items_price = data['order']['discounted_items_price'];
			this.afterShipping_amount = parseFloat(this.net_amount.replace(',','')) - parseFloat(this.shipping_charges.replace(',',''));
			this.payment = false;
			this.showCard = false;
			this.card_loading = false;
        },
            error => {
            	this.showError(error);
			}
    	)
	}

	  	// will apply coupon 
  	public applyCoupon(){
  		if(this.coupon != ''){
  			this.loading_coupon = true;
	  		this.http.post(`${environment.assessment_admin}api/v1/carts/apply_discount_code`,{code: this.coupon}).subscribe(data => {
	            console.log(data);
	            this.loading_coupon = false;
				if(data['order']['applied_discount_code']){
	            	this.applied_code = data['order']['applied_discount_code'];
	            }
				$('.price-hide').show();
				this.total_discount = data['order']['total_discount'];
				this.volume_discount = data['order']['volume_discount'];
				this.promo_discount = data['order']['promo_discount'];
				this.cart = data['order']['line_items'];
				this.total = data['order']['subtotal_amount'];
				this.net_amount = data['order']['net_amount'];
				this.tax = data['order']['tax_amount'];
				this.shipping_charges = data['order']['shipping_amount'];
				this.discounted_items_price = data['order']['discounted_items_price'];
				this.afterShipping_amount = parseFloat(this.net_amount.replace(',','')) - parseFloat(this.shipping_charges.replace(',',''));
	        },
	            error => {
	            	console.log(error)
	            	this.loading_coupon = false;
	            	if(error.status == 422){
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 4000
							};
							this.toastr.error(error.error.errors, 'oops!');
						}, 1000);
					}else{
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 2000
							};
							this.toastr.error('Something went wrong!', 'oops!');
						}, 1000);
					}
				}
	    	)
	  	}
  	}

  	// remove existing coupon
  	public removeCoupon(){
  		if(this.applied_code && this.applied_code != ''){
  			this.http.get(`${environment.assessment_admin}api/v1/carts/remove_discount_code`).subscribe(data => {
	            console.log(data);
            	this.applied_code = data['order']['applied_discount_code'];
            	this.total_discount = data['order']['total_discount'];
				this.volume_discount = data['order']['volume_discount'];
				this.promo_discount = data['order']['promo_discount'];
				this.cart = data['order']['line_items'];
				this.total = data['order']['subtotal_amount'];
				this.net_amount = data['order']['net_amount'];
				this.tax = data['order']['tax_amount'];
				this.shipping_charges = data['order']['shipping_amount'];
				this.discounted_items_price = data['order']['discounted_items_price'];
				this.afterShipping_amount = parseFloat(this.net_amount.replace(',','')) - parseFloat(this.shipping_charges.replace(',',''));
				this.loading_coupon = false;
	        },
	            error => {
	            	this.loading_coupon = false;
	            	console.log(error)
	            	if(error.status == 422){
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 4000
							};
							this.toastr.error(error.error.errors, 'oops!');
						}, 1000);
					}else{
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 2000
							};
							this.toastr.error('Something went wrong!', 'oops!');
						}, 1000);
					}
				}
	    	)
  		}
  	}	

	public notNumber(evt){
		console.log(evt)
	    if ((evt.which < 48 || evt.which > 57) && (evt.which != 43)){
	        evt.preventDefault();
	    }
	}

	public changeShippingState(){
		this.weight_error = false;
		// this.shipping_city = '';
		// this.shipping_postcode = '';
		this.shipping_method = '';
		this.shipMethod = false;
		this.showMethod = false;
		this.payment = false;
		this.showCard = false;
		this.proceed();
	}

	public changeShippingCity(){
		this.weight_error = false;
		this.shipMethod = false;
		this.showMethod = false;
		this.payment = false;
		this.showCard = false;
	}

		public getShipAdrs(id){
		this.weight_error = false;
		this.showBillAdrs = false;
		this.showBillDetails = false;
		this.ship_methods = [];
		$('.price-hide').hide();
		this.addShipAdrs = false;
		this.editShipAdrs = false;
		this.payment = false;
		this.showCard = false;
		this.shipMethod = false;
		this.showMethod = false;
		this.shipping_method = '';
		this.proceedShipping();
	}

	// add new shipping address
	public addNewShip(){
		this.weight_error = false;
		this.editShipAdrs = false;
		this.shipping_method = '';
		$('.price-hide').hide();
		this.adrs_type_shipping = '';
		this.org_shipping = '';
		this.shipping_first_name = '';
		this.shipping_last_name = '';
		this.email_shipping = '';
		this.ad_line1_shipping = '';
		this.ad_line2_shipping = '';
		this.shipping_country = '';
		this.shipping_state = '';
		this.shipping_city = '';
		this.shipping_postcode = '';
		this.shipping_nmbr = '';
		this.ship_methods = [];
		this.addShipAdrs = true;
		this.getShippingAdrs = 0;
		this.payment = false;
		this.showCard = false;
		this.shipMethod = false;
		this.showMethod = false;
		this.billing_address = false;
		setTimeout(function() {
            crs.init();
        }, 0);
	}

	public editShipAddress(id){
		this.weight_error = false;
		this.updateAdrsId = id;
		$('.price-hide').hide();
		this.addShipAdrs = false;
		this.payment = false;
		this.showCard = false;
		this.showMethod = false;
		this.shipMethod = false;
		this.getShippingAdrs = 0;
		this.ship_methods = [];
		for(var i=0; i<this.addresses.length; i++){
    		if(this.addresses[i].id == id){
    			var nmbr = i;
    			this.editShipAdrs = true;
    		}
    	}
  		this.update_address_name = this.addresses[nmbr].name;
  		this.update_org = this.addresses[nmbr].organization_name;
  		this.update_address_first_name = this.addresses[nmbr].first_name;
  		this.update_address_last_name = this.addresses[nmbr].last_name;
  		this.update_address_email = this.addresses[nmbr].email;
		this.update_address_line1 = this.addresses[nmbr].address_line1;
		this.update_address_line2 = this.addresses[nmbr].address_line2;
		this.update_address_nmbr = this.addresses[nmbr].phone;
		this.update_city = this.addresses[nmbr].city;
		this.update_state = this.addresses[nmbr].state; 
		this.update_country = this.addresses[nmbr].country;
		this.update_postal_code = this.addresses[nmbr].postal_code;
  		setTimeout(function() {
            crs.init();
        }, 0);
		var that = this;
  		setTimeout(function() {
            $("#update_country").val(that.update_country).trigger('change');
            $("#update_state").val(that.update_state);
        }, 1000);
	}

		// attach shipping address
	public proceedShipping(){
		this.weight_error = false;
		console.log(this.shipping_country, this.billing_state);
		let checkValue = ()=> { 
			return this.adrs_type_shipping && this.shipping_first_name && this.shipping_last_name && this.email_shipping && this.ad_line1_shipping && this.shipping_country && this.shipping_state && this.shipping_city && this.shipping_postcode && this.shipping_nmbr.length >= 10;
		}
		console.log(checkValue())
		if(this.getShippingAdrs != 0 || checkValue()){
			if(this.shipping_nmbr && this.getShippingAdrs == 0){
				var Data = {
		  			name: this.adrs_type_shipping,
		  			organization_name: this.org_shipping,
		  			first_name: this.shipping_first_name,
		  			last_name: this.shipping_last_name,
		  			email: this.email_shipping,
		  			address_line1: this.ad_line1_shipping,
		  			address_line2: this.ad_line2_shipping,
		  			phone: this.shipping_nmbr,
		  			city: this.shipping_city, 
		  			state: this.shipping_state, 
		  			country: this.shipping_country,
		  			postal_code:this.shipping_postcode
		  		}
		  		console.log('hit');
		  		this.shipMethod_loading = true;
		  		this.http.post(`${environment.assessment_admin}api/v1/addresses`,Data)
		    	.subscribe(
		    		res => {	    		
			    		console.log(res);
			    		this.addresses.push(res['address']);
				        this.getShippingAdrs = res['address']['id'].toString();
				        this.getBillingAdrs = res['address']['id'].toString();
				        this.shipMethod_loading = false;
			  			setTimeout(function() {
				            this.toastr.options = {
				                closeButton: true,
		                    	progressBar: true,
				                timeOut: 2000
				            };
				            this.toastr.success('Your new address has been added!', 'Nice!');
			        	}, 1000);
			        	this.proceedShipping();
					},
					err =>{
						this.shipMethod_loading = false;
						console.log(err);
			  			this.showError(err);
					}
				)
			}else{
				if(this.getShippingAdrs != 0){
					this.getBillingAdrs = this.getShippingAdrs;
					this.billing_address = true;
					this.adrsData = {					
						"shipping_address_id": parseInt(this.getShippingAdrs),
						"billing_address_id": parseInt(this.getShippingAdrs)
					}
				}
			
			}
			this.shipMethod_loading = true;
			// add shipping address
			this.http.post(`${environment.assessment_admin}api/v1/carts/save_addresses`,this.adrsData).subscribe(data => {
	            console.log(data);
	            this.shipMethod_loading = false;
	            if(data['order']['applied_discount_code']){
	            	this.applied_code = data['order']['applied_discount_code'];
	            }
	            this.total_discount = data['order']['total_discount'];
	            this.volume_discount = data['order']['volume_discount'];
	            this.promo_discount = data['order']['promo_discount'];
	            this.ship_methods = data['order']['shipment_rates'];
	            this.total = data['order']['subtotal_amount'];
	            this.net_amount = data['order']['net_amount'];
				this.tax = data['order']['tax_amount'];
				this.shipping_charges = data['order']['shipping_amount'];
				this.discounted_items_price = data['order']['discounted_items_price'];
				this.afterShipping_amount = parseFloat(this.net_amount.replace(',','')) - parseFloat(this.shipping_charges.replace(',',''));
	        },
	            err =>{
				console.log(err);
				var that = this;
				this.shipMethod_loading = false;
				if(err.status == 422){
					setTimeout(function() {
						this.toastr.options = {
						  closeButton: true,
						  timeOut: 4000
						};
						if(!err.error.errors.base[0]){
							this.toastr.error(err.error.errors, 'oops!');
						}else{
							if(err.error.errors.base[0].includes('weight of your package') || err.error.errors.base[0].includes('Invalid dimensions')){
								that.weight_error = true;
								that.ship_weight_error = err.error.errors.base[0];
							}else{
								this.toastr.error(err.error.errors.base[0], 'oops!');
							}
						}
					}, 1000);
				}else{
		  			setTimeout(function() {
			            this.toastr.options = {
			                closeButton: true,
	                    	progressBar: true,
			                timeOut: 2000
			            };
			            this.toastr.error(err.error.errors.base[0], 'oops!');
		        	}, 1000);
		  		}
			}
        	)
			
		}
	}

	// add shipping method
	public proceed(){
		console.log(this.shipping_country, this.shipping_state, this.shipping_city, this.shipping_postcode , this.shipping_method);
		if(this.shipping_method){			
			this.http.post(`${environment.assessment_admin}api/v1/carts/save_shipping_method`,{shipping_method_id: this.shipping_method}).subscribe(data => {
	            console.log(data);
				this.showAdrs = false;
				this.showBillAdrs = true;
				this.showBillDetails = true;
				this.showDetails = false;
				this.showMethod = false;
				$('.price-hide').show();
				if(data['order']['applied_discount_code']){
	            	this.applied_code = data['order']['applied_discount_code'];
	            }
	            this.total_discount = data['order']['total_discount'];
				this.volume_discount = data['order']['volume_discount'];
				this.promo_discount = data['order']['promo_discount'];
				this.total = data['order']['subtotal_amount'];
				this.net_amount = data['order']['net_amount'];
				this.tax = data['order']['tax_amount'];
				this.shipping_charges = data['order']['shipping_amount'];
				this.discounted_items_price = data['order']['discounted_items_price'];
				this.afterShipping_amount = parseFloat(this.net_amount.replace(',','')) - parseFloat(this.shipping_charges.replace(',',''));
				// if(this.getBillingAdrs){
				// 	this.proceedBilling();
				// this.angulartics2Segment.eventTrack('Page', 
		        //     { 
		        //         email: localStorage.getItem('email'),
		        //         funnel: 'Store',
		        //         cta_color:'developer-green',
		        //         cta_text:'Proceed',
		        //         cta_type:'button',
		        //         cta_location:'Shipping Info',
		        //         url: window.location.origin + this.location.path()
		        //     }
		        // );
				// }
	        },
	            error => {
	            	this.showError(error);
				}
        	)
		}else{
			$('.price-hide').hide();
		}
	}

  	public saveShipMethod(){
  		this.showBillAdrs = false;
		this.showBillDetails = false;
  		this.payment = false;
  		this.showCard = false;
  		for(var i= 0; i<this.ship_methods.length;i++){
  			if(this.shipping_method == this.ship_methods[i].id){
  				this.shipping_charges = this.ship_methods[i].amount;
  				console.log(this.shipping_charges);
  			}
  		}
  	}
	
	public displayEST(){
		this.estTime = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
		this.estTime = this.estTime.slice(this.estTime.length - 11, this.estTime.length);
		this.cstTime = new Date().getUTCHours();
	}

	public showPdts(){
		this.status1 = true;
		this.showDetails = !this.showDetails;
		this.orderStatus = false;
	}

	public showPayment(){
		if(this.payment){
			this.status1 = false;
			this.showCard = !this.showCard;
			this.orderStatus = false;
		}
	}

	public showBill(){
		if((this.cart.length > 0 && !this.needs_shipping) || (this.needs_shipping && this.shipping_method)){
			this.status1 = false;
			this.orderStatus = false;
			if(!this.showBillAdrs){
				this.showDetails = false;
			}
			this.showBillAdrs = !this.showBillAdrs;
			setTimeout(function() {
	            crs.init();
	        }, 0);
			var that = this;
	        setTimeout(function() {
	            $("#billing_country").val(that.billing_country).trigger('change');
	            $("#two").val(that.billing_state);
	        }, 1000);
	    }
	}

	public showShip(){
		if(this.needs_shipping){
			this.status1 = false;
			this.orderStatus = false;
			if(!this.showAdrs){
				this.showDetails = false;
			}
			this.showAdrs = !this.showAdrs;
			setTimeout(function() {
	            crs.init();
	        }, 0);
			var that = this;
	        setTimeout(function() {
	            $("#country").val(that.shipping_country).trigger('change');
	            $("#one").val(that.shipping_state);
	        }, 1000);
	    }
	}

	public showShipMethod(){
		if(this.ship_methods.length > 0){
			this.status1 = false;
			this.showMethod = !this.showMethod;
			this.orderStatus = false;
		}
	}

	// make billing address same as shipping address
	public sameAddress(){
		this.weight_error = false;
		if(this.getShippingAdrs){
			this.getBillingAdrs = this.getShippingAdrs;
			this.editBillAdrs = false;
			this.addBillAdrs = false;
		}else{
			this.getBillingAdrs = 0;
		}
	}

	// attach billing address
	public proceedBilling(){
		this.weight_error = false;
		let checkValue = ()=> {
			return this.adrs_type_billing && this.billing_first_name && this.billing_last_name && this.email_billing && this.billing_nmbr.length >= 10 && this.ad_line1_billing && this.billing_country && this.billing_city && this.billing_state && this.billing_postcode
		}
		if((this.getBillingAdrs != 0 || checkValue())){
			if(this.billing_nmbr && this.billing_state && this.getBillingAdrs == 0){
				var Data = {
		  			name: this.adrs_type_billing,
		  			organization_name: this.org_billing,
		  			first_name: this.billing_first_name,
		  			last_name: this.billing_last_name,
		  			email: this.email_billing,
		  			address_line1: this.ad_line1_billing,
		  			address_line2: this.ad_line2_billing,
		  			phone: this.billing_nmbr,
		  			city: this.billing_city, 
		  			state: this.billing_state, 
		  			country: this.billing_country,
		  			postal_code:this.billing_postcode
		  		}
		  		this.http.post(`${environment.assessment_admin}api/v1/addresses`,Data)
		    	.subscribe(
		    		res => {	    		
			    		console.log(res);
			    		this.addresses.push(res['address']);
				        this.getBillingAdrs = res['address']['id'].toString();
				        this.editBillAdrs = false;
				        this.addBillAdrs = false;
			  			setTimeout(function() {
				            this.toastr.options = {
				                closeButton: true,
		                    	progressBar: true,
				                timeOut: 2000
				            };
				            this.toastr.success('Your new address has been added!', 'Nice!');
			        	}, 1000);
					},
					err =>{
						console.log(err);
			  			this.showError(err);
					}
				)
			}else{
				console.log(this.needs_shipping);
				if(this.needs_shipping && this.getShippingAdrs > 0){
					this.adrsData = {					
						"billing_address_id": parseInt(this.getBillingAdrs),
						"shipping_address_id": parseInt(this.getShippingAdrs)
					}
				}else{
					this.adrsData = {					
						"billing_address_id": parseInt(this.getBillingAdrs)
					}
				}
				if(parseFloat(this.discounted_items_price) > 0){
					this.card_loading = true;
				}
				// save billing address
				this.http.post(`${environment.assessment_admin}api/v1/carts/save_addresses`,this.adrsData).subscribe(data => {
		            console.log(data);
					$('.price-hide').show();
					var that = this;
					if(data['order']['applied_discount_code']){
		            	this.applied_code = data['order']['applied_discount_code'];
		            }
		            this.total_discount = data['order']['total_discount'];
					this.volume_discount = data['order']['volume_discount'];
					this.promo_discount = data['order']['promo_discount'];
					this.total = data['order']['subtotal_amount'];
					this.net_amount = data['order']['net_amount'];
					this.tax = data['order']['tax_amount'];
					this.shipping_charges = data['order']['shipping_amount'];
					this.discounted_items_price = data['order']['discounted_items_price'];
					this.shipping_method_name = data['order']['shipping_method_name'];
					this.afterShipping_amount = parseFloat(this.net_amount.replace(',','')) - parseFloat(this.shipping_charges.replace(',',''));
					var products = [];
					for(var i= 0; i< this.cart.length;i++){
						products.push({
			        		product_id: this.cart[i].variant.product_id,
							sku: this.cart[i].variant.sku,
							name: this.cart[i].variant.name,
							price: this.cart[i].variant.price,
							image_url: this.cart[i].variant.image_url
			        	})
					}
					// redirect to verify order if payment is zero
					if(data['order']['status'] == 'confirmation_required'){
						this.router.navigate([`/custom-store/order-verify`]);
					}else{
						this.payment = true;
						this.showCard = true;
						this.card_loading = false;
					}
					// this.angulartics2Segment.eventTrack('Page', 
			        //     { 
			        //         email: localStorage.getItem('email'),
			        //         funnel: 'Store',
			        //         cta_color:'developer-green',
			        //         cta_text:'Proceed',
			        //         cta_type:'button',
			        //         cta_location:'Billing Info',
			        //         url: window.location.origin + this.location.path()
			        //     }
			        // );

			        // this.angulartics2Segment.eventTrack('Checkout Started', 
			        //     { 
			        //         order_id: this.cartId,
	                //         total: this.net_amount,
	                //         shipping: this.shipping_charges,
	                //         tax: this.tax,
	                //         discount: this.total_discount,
	                //         coupon: data['order']['applied_discount_code'],
					// 		currency: 'USD',
					// 		products: products
			        //     }
			        // );
					// if(this.cards.length < 1 && this.getBillingAdrs != 0){
		   //          	this.new_card = true;
		   //          }
		        },
		            err =>{
		            	this.card_loading = false;
						console.log(err);
						this.showError(err);
					}
	        	)
			}
		}
	}


	public getBillAdrs(id){
		if(id == this.getShippingAdrs){
			this.billing_address = true;
		}else{
			this.billing_address = false;
		}
		this.showCard = false;
		this.payment = false;
		this.weight_error = false;
		$('.price-hide').hide();
		this.showShipAdrs = false;
		this.addBillAdrs = false;
		this.editBillAdrs = false;
		// this.proceedBilling();
	}

	// add new billing address
	public addNewBill(){
		this.new_card = false;
		this.weight_error = false;
		this.billing_address = false;
		this.editBillAdrs = false;
		this.payment = false;
		$('.price-hide').hide();
		this.adrs_type_billing = '';
		this.billing_first_name = '';
		this.org_billing = '';
		this.billing_last_name = '';
		this.email_billing = '';
		this.ad_line1_billing = '';
		this.ad_line2_billing = '';
		this.billing_country = '';
		this.billing_state = '';
		this.billing_city = '';
		this.billing_postcode = '';
		this.billing_nmbr = '';
		this.addBillAdrs = true;
		this.getBillingAdrs = 0;
		setTimeout(function() {
            crs.init();
        }, 0);
	}


	public saveCard(slcrd){
		console.log(slcrd);
		$('#card_error').hide();
		this.new_card = false;
		this.selected_card_last4 = slcrd.last4;
		localStorage.setItem('sl_card_id',slcrd.id);
	}


	public editBillAddress(id){
		this.new_card = false;
		this.weight_error = false;
		this.billing_address = false;
		this.payment = false;
		this.updateAdrsId = id;
		$('.price-hide').hide();
		this.addBillAdrs = false;
		this.getBillingAdrs = 0;
		for(var i=0; i<this.addresses.length; i++){
    		if(this.addresses[i].id == id){
    			var nmbr = i;
    			this.editBillAdrs = true;
    		}
    	}
  		this.update_address_name = this.addresses[nmbr].name;
  		this.update_org = this.addresses[nmbr].organization_name;
  		this.update_address_first_name = this.addresses[nmbr].first_name;
  		this.update_address_last_name = this.addresses[nmbr].last_name;
  		this.update_address_email = this.addresses[nmbr].email;
		this.update_address_line1 = this.addresses[nmbr].address_line1;
		this.update_address_line2 = this.addresses[nmbr].address_line2;
		this.update_address_nmbr = this.addresses[nmbr].phone;
		this.update_city = this.addresses[nmbr].city;
		this.update_state = this.addresses[nmbr].state; 
		this.update_country = this.addresses[nmbr].country;
		this.update_postal_code = this.addresses[nmbr].postal_code;
  		setTimeout(function() {
            crs.init();
        }, 0);
		var that = this;
  		setTimeout(function() {
            $("#update_country").val(that.update_country).trigger('change');
            $("#update_state").val(that.update_state);
        }, 1000);

	}

	public updateAddress(type, id){
  		var Data = {
  			name: this.update_address_name,
  			organization_name: this.update_org,
  			first_name: this.update_address_first_name,
  			last_name: this.update_address_last_name,
  			email: this.update_address_email,
  			address_line1: this.update_address_line1,
  			address_line2: this.update_address_line2,
  			phone: this.update_address_nmbr,
  			city: this.update_city, 
  			state: this.update_state, 
  			country: this.update_country,
  			postal_code:this.update_postal_code
  		}
  		console.log(Data);

		let checkValue = ()=> {
			return  this.update_state && this.update_address_line1 && this.update_address_nmbr.length >= 10 && this.update_address_first_name && this.update_address_last_name && this.update_address_email && this.update_address_name && this.update_city && this.update_country && this.update_postal_code
		}

  		if (checkValue) {
        this.http
          .put(`${environment.assessment_admin}api/v1/addresses/${id}`, Data)
          .subscribe(
            (res) => {
              console.log(res);
              setTimeout(function () {
                this.toastr.options = {
                  closeButton: true,
                  progressBar: true,
                  timeOut: 2000,
                };
                this.toastr.success("Your address has been updated!", "Nice!");
              }, 1000);
              for (var i = 0; i < this.addresses.length; i++) {
                if (this.addresses[i].id == res["address"]["id"]) {
                  this.addresses[i] = res["address"];
                }
              }
              if (type == "bill") {
                this.editBillAdrs = false;
                this.showCard = false;
                this.getBillingAdrs = this.updateAdrsId.toString();
                if (this.getBillingAdrs == this.getShippingAdrs) {
                  this.billing_address = true;
                } else {
                  this.billing_address = false;
                }
                this.proceedBilling();
              }
              if (type == "ship") {
                this.editShipAdrs = false;
                this.getShippingAdrs = this.updateAdrsId.toString();
                this.proceedShipping();
              }
            },
            (err) => {
              console.log(err);
              this.showError(err);
            }
          );
      }             
  	}

  	// delete saved card
  	public deleteCard(id){
  		Swal.fire({
	        title: "Are you sure?",
	        text: "Are you sure, you want to delete? This cannot be undone!",
	        type: "warning",
	        showCancelButton: true,
	        confirmButtonColor: "#DD6B55",
	        cancelButtonColor: '##D0D0D0',
	        confirmButtonText: "Proceed!"
    	}).then((result) => {
	        if (result['value'] == true) {
	        	jQuery('.swal2-container').addClass('hide');
	    		jQuery('body').removeClass('swal2-shown');
	    		jQuery('html').removeClass('swal2-shown');
	            this.http.delete(`${environment.assessment_admin}api/v1/credit_cards/${id}`).subscribe(data => {
		            console.log(data);
		            for(var i=0; i<this.cards.length; i++){
		            	this.card_save = '';
		            	if(this.cards[i].id == id){
		            		this.cards.splice(i,1);
		            		if(this.cards.length < 1){
		            			this.new_card = true;
		            			this.proceedBilling();
								// this.modal1 = this.modalService.open(this.stripe_card, { windowClass: 'in grey card-payment' , centered: true });
								$('.add-card-modal').css('display','block')
		            			this.checkoutCard();
		            		}
		            	}		            	
		            }
		            setTimeout(function() {
				            this.toastr.options = {
			                closeButton: true,
	                    	progressBar: true,
			                timeOut: 2000
			            };
			            this.toastr.success('Your card has been deleted!', 'Nice!');
		        	}, 1000);
		        },
		            error => {
		            	console.log('oops', error);
		            	if(error.status == 422){
							setTimeout(function() {
								this.toastr.options = {
								  closeButton: true,
								  timeOut: 4000
								};
								if(!error.error.errors.base[0]){
									this.toastr.error(error.error.errors, 'oops!');
								}else{
									this.toastr.error(error.error.errors.base[0], 'oops!');
								}
							}, 1000);
						}else{
				            setTimeout(function() {
								this.toastr.options = {
								  closeButton: true,
								  timeOut: 2000
								};
								this.toastr.error('Card not deleted!', 'Sorry!');
							}, 1000);
				        }
			        }
		        )
	        }else{
                jQuery('.swal2-container').addClass('hide');
                jQuery('body').removeClass('swal2-shown');
                jQuery('html').removeClass('swal2-shown');
            }
    	},function(dismiss){
    		    		// swal({});
    		// swal.clickCancel();
    		jQuery('.swal2-container').addClass('hide');
    		jQuery('body').removeClass('swal2-shown');
    		jQuery('html').removeClass('swal2-shown');
    	});
  	}

  	public showError(error){
    	console.log('oops', error);
    	if(error.status == 500){
			setTimeout(function() {
				this.toastr.options = {
				  closeButton: true,
				  timeOut: 2000
				};
				this.toastr.error('Something went wrong!', 'Sorry!');
			}, 1000);
		}
		else if(error.status == 422){
			setTimeout(function() {
				this.toastr.options = {
				  closeButton: true,
				  timeOut: 4000
				};
				if(error.error.errors.number[0]){
					this.toastr.error(error.error.errors.number[0], 'oops!');
				}
				if(error.error.errors.base[0]){
					this.toastr.error(error.error.errors.base[0], 'oops!');
				}
				if(!error.error.errors.base[0] && !error.error.errors.number[0]){
					this.toastr.error(error.error.errors, 'oops!');
				}
			}, 1000);
		}
		else{
			setTimeout(function() {
				this.toastr.options = {
				  closeButton: true,
				  timeOut: 2000
				};
				this.toastr.error('Something went wrong!', 'oops!');
			}, 1000);
		}

  	}

  	public paymentError(error){
    	console.log('oops', error);
    	if(error.status == 500){
			setTimeout(function() {
				this.toastr.options = {
				  closeButton: true,
				  timeOut: 2000
				};
				this.toastr.error('We are not able to process this payment. Please try again, or choose a different payment method!', 'Sorry!');
			}, 1000);
		}
		else if(error.status == 422){
			setTimeout(function() {
				this.toastr.options = {
				  closeButton: true,
				  timeOut: 4000
				};
				if(error.error.errors.number[0]){
					this.toastr.error(error.error.errors.number[0], 'oops!');
				}
				if(error.error.errors.base[0]){
					this.toastr.error(error.error.errors.base[0], 'oops!');
				}
				if(!error.error.errors.base[0] && !error.error.errors.number[0]){
					this.toastr.error('We are not able to process this payment. Please try again, or choose a different payment method!', 'oops!');
				}
			}, 1000);
		}
		else{
			setTimeout(function() {
				this.toastr.options = {
				  closeButton: true,
				  timeOut: 2000
				};
				this.toastr.error('We are not able to process this payment. Please try again, or choose a different payment method!', 'oops!');
			}, 1000);
		}

  	}

  	public changeCard(){
  		$('#card_error').hide();
  		this.card_save = '';
		$('.add-card-modal').css('display','block');
	  	// this.modal1 = this.modalService.open(content, { windowClass: 'in grey card-payment' , centered: true });
	  	this.new_card = true;
		setTimeout(() => {
			this.checkoutCard();
		}, 100);
  	}

  	public changeAdBlock(blocker){
  		this.blocker = blocker;
  	}

  	public reload(){
  		window.location.reload();
  	}

  	// set up card payment
	public checkoutCard(){
		var stripe = Stripe(this.env.stripe_api_key);
		$('#card_error').hide();
		if(this.getBillingAdrs != 0){
			// add new card
			if(this.new_card){			
				localStorage.setItem('last4','');
				console.log(stripe);
			    var elements = stripe.elements();
			    var cardElement = elements.create('card');
			    console.log(cardElement);
			    var clientSecret = '';
			    setTimeout(function() {
					cardElement.mount('#card-element');
				}, 0);
		    	var that = this;
		    	console.log(cardElement);
		    	// create card payment intent
				var cardButton = document.getElementById('card_button');
					cardButton.addEventListener('click', function(ev) {
						// $('#stripe_checkout').hide();
						// $('#stripe_card').show();
						// $('#card_button').show();
						stripe.createPaymentMethod({
							type: 'card',
							card: cardElement,
							billing_details: {
							// name: 'Jenny Rosen',
							"name": that.shipping_first_name + that.shipping_first_name
							},
						})
						.then(function(result) {
							console.log('res1',result)
							localStorage.setItem('new-card',JSON.stringify({brand:result?.paymentMethod?.card?.brand, last4:result?.paymentMethod?.card?.last4}))
							that.card_save = result?.paymentMethod?.id;
							// that.modal1.close();  // close stripe popup
							// Handle result.error or result.paymentMethod
							if(result?.error) {
								console.log(result?.error)
								let error = document.getElementById('stripe_error');
								error.innerHTML = result['error']['message'];
							} else {
								let data;
								$('.loader-container').css('display','flex');
								console.log(result.paymentMethod.id);
								that.http.post(`${environment.assessment_admin}api/v1/carts/create_payment_method`,data,{params:{payment_method_id:result.paymentMethod.id,future_usage:that.save_card}}).subscribe((result)=> {
									console.log(result);
									// that.modal1.close();
									$('.add-card-modal').css('display','none')
									if(result["order"]["status"] === "confirmation_required") {
										that.router.navigate([`/custom-store/order-verify`]);
									}
								}, err =>{
									console.log('myerror',err)
									if(err["status"] == 422) {
										$('.loader-container').css('display','none');
										// that.modal1 = that.modalService.open(that.stripe_card, { windowClass: 'in grey card-payment' , centered: true });
										let error = document.getElementById('stripe_error');
										error.innerHTML = err['error']['errors']['number'][0];
										// error.innerText = "Something went wrong.Please try again!";
									}
								})
							}
						});

					})
		    }
		    // create payment intent from saved card
		    else{
		    	var that = this;
		    	this.loading = true;
				console.log(that.card_save)
				localStorage.setItem('last4',this.selected_card_last4);
				that.router.navigate([`/custom-store/order-verify`]);	
		    }
		}
		
	}

  	// redirect to paypal payment method
  	public paypal(){
  		this.loading = true;
  		this.http.get(`${environment.assessment_admin}api/v1/carts/save_paypal`).subscribe(data => {
            console.log(data);
            // this.angulartics2Segment.eventTrack('Checkout Step Completed', 
	        //     { 
			// 		step: 2,
			// 		shipping_method: this.shipping_method_name,
			// 		payment_method: 'Paypal'
	        //     }
	        // );
            window.location.href = data['paypal_payment_url'];
            setTimeout(function() {
				this.loading = false;
			}, 3000);
        },
            error => {
            	this.loading = false;
            	console.log(error)
            	if(error.status == 422){
					setTimeout(function() {
						this.toastr.options = {
						  closeButton: true,
						  timeOut: 4000
						};
						this.toastr.error(error.error.errors, 'oops!');
					}, 1000);
				}else{
					setTimeout(function() {
						this.toastr.options = {
						  closeButton: true,
						  timeOut: 2000
						};
						this.toastr.error('Something went wrong!', 'oops!');
					}, 1000);
				}
			}
    	)
  	}

}
