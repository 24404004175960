import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterprofile'
})
export class FilterprofilePipe implements PipeTransform {

	transform(items: any[], searchText: string): any[] {
    	if(!items) return [];
    	if(!searchText) return items;
		searchText = searchText.toLowerCase();
		return items.filter( data => {
			var profile=data.default_profile.profile_title;
      var email= data.email;
      var fullName=data.first_name+' '+data.last_name;
      		return (fullName.toLowerCase().includes(searchText) || email.toLowerCase().includes(searchText) || profile.toLowerCase().includes(searchText));
    	});
   	}

}
