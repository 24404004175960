import { Component, OnInit } from '@angular/core';
import { environment } from './../../../../environments/environment';

@Component({
	selector: 'app-toolcards-assisters-resisters',
	templateUrl: './toolcards-assisters-resisters.component.html',
	styleUrls: ['./toolcards-assisters-resisters.component.scss']
})
export class ToolcardsAssistersResistersComponent implements OnInit {
	public env;

	constructor() { }

  	ngOnInit() {
  		this.env=environment;  		
  	}

}
