import { Component, OnInit } from '@angular/core';
import{ Router, RouterModule } from '@angular/router';
import {AccessGroupService} from './../access-group.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ChallengeSummaryService } from '../challenge-summary.service';
import { APIService } from '../../API.service';
import { Store } from '@ngrx/store';
import { LambdaFunctionService } from '../../service/lambda-function.service';

@Component({
  selector: 'app-challenges',
  templateUrl: './challenges.component.html',
  styleUrls: ['./challenges.component.scss']
})

export class ChallengesComponent implements OnInit {
  modal:any;
  reset_confirm: string = '';
  ngrxAccessGroup:any;
  public env;
	breakthrough_content:any = [];
	breakthrough_dropdown:any = [];
	edit_breakthrough:boolean = false;
	datadump_content:any = [];
	edit_datadump:boolean = false;
	question_content:any = [];
	question_dropdown:any = [];
	edit_question:boolean = false;
	breakthrough:any = [];
	data_dump:any = [0];
	question:any = [0];
	showBreakthrough: boolean = false;
	showDatadump: boolean = false;
	showQuestion: boolean = false;
	BtId:string = '';
	DdId:string = '';
	QtId:string = '';

	brainstorm:any = [0];
	brainstorm_content:any = [];
	prev_brainstorm_content:any = [];
	edit_brainstorm:boolean = true;
	checked_brainstorm:any = [];
	showBrainstorm: boolean = false;
	BsId:string = '';
	IpId:string;
	proposed_content:any = [];
	array_proposed_content:any = [];

	strengthen_content:any = '';
	doing_content:any = '';
	doing_dropdown:any = '';
	prev_doing_content:any = '';
	edit_strengthen:boolean = false;
	edit_doing:boolean = false;
	PtEvaluation_content:any = [];
	plus_content:any = [];
	plus:any = [0];
	thinking:any = [0];
	thinking_content:any = [];
	issue_content:any = [];
	issue_text:any = [];
	opp_content:any = [];
	opp_text:any = [];
	edit_plus:boolean = true;
	edit_opportunity:boolean = false;
	edit_issues:boolean = false;
	edit_thinking:boolean = false;
	edit_PtEvaluation:boolean = true;
	strengthen:any = [0];
	doing:any = [0];
	opportunities:any = [0];
	issues:any = [0];
	showPoint: boolean = false;
	showStrength: boolean = false;
	showDoing: boolean = false;
	StId:string = '';
	DoingId:string = '';
	PtId:string = '';
	selected_strength:string;
	selected_opp:any = [];
	selected_thinking:string;
	selected_issue:any = [];
	showPlus:boolean = false;
	showOpp:boolean = false;
	showIssue:boolean = false;
	showThink:boolean = false;
	count_plus: number = 0;
	count_opp: number = 0;
	count_issue: number = 0;
	count_think: number = 0;

	next_content:any = [];
	edit_next:boolean = false;
	short_content:any = [];
	mid_content:any = [];
	long_content:any = [];
	edit_plan:boolean = false;
	next24:any = [0];
	showPlan:boolean = false;
	showNext:boolean = false;
	NxtId:string = '';
	PlanId:string = '';
	pdf: any;
	pdf_type: string = '';
	args:any;
	email:string;
    

    public main_menu:any;

  	constructor(public accessgroup:AccessGroupService, public router: Router,private modalService: NgbModal,public allChallenge: ChallengeSummaryService,private api: APIService,public store:Store<any>,public lmda_service: LambdaFunctionService) { }

  	async ngOnInit() {
		this.ngrxAccessGroup = this.store.select<any>((state) => state.profile.accessGroupData);
        // it re-directs url to the dashboard page if access to this page is not permitted.
        this.ngrxAccessGroup.subscribe(message => {
            if(message){
                this.main_menu = message['main_menu'];
                if(!message['main_menu']?.innovation_stations?.challenge && message.length > 0){
                    this.router.navigate(['/dashboard']);
                    return false;
                }
            }

        },
        err => {
            // Log errors if any
            console.log(err);
        })

        await this.makePdf(false)
  	}

    // Complete PDF Download  
  	public allContentPDF() { 
      $('.pdf-modal').css('display','flex');
      this.arguments('allContent');
  		// localStorage.setItem('pdf_type','allContent');
	    // this.router.navigate(['/challenges/summary']);
  	}

    // Summary PDF Download  
  	public summaryPDF() { 
      $('.pdf-modal').css('display','flex');
      this.arguments('summary');
  		// localStorage.setItem('pdf_type','summary');
	    // this.router.navigate(['/challenges/summary']);
  	}

    public onActivate(event) {
        window.scroll(0,0);
    }
    
    public async resetConfirm() {
      $('.reset-modal').css('display', 'block');
        // this.modal = this.modalService.open(content, { windowClass: 'in grey' });
    }

    public async makePdf(callPdf= true){
  		var that = this;
	  	
	  	let BtFilter = {email:{eq:localStorage.getItem('email')}, processTypeId:{eq:"c1c17711-b4a3-4dcc-98b5-fe73ee0791d2"}, type:{eq:"process-stage"}, title:{eq:"vision"}};
				await this.api.ListFrameworks(BtFilter,10000).then(function (data) {
	        if(data.items.length == 0){
	        	that.edit_breakthrough = false;
	        	that.breakthrough_content[0] = '';
	        	that.breakthrough[0] = '';
	        	that.breakthrough_dropdown[0] = '';
	        	that.breakthrough_content[1] = '';
	        	that.breakthrough_dropdown[1] = 'It would be great if...';
	        	that.breakthrough[1] = '';
	        }else{
	        	that.BtId = data.items[0].id;
	        	that.breakthrough = JSON.parse(data.items[0].content);
	        	var breakthrough = JSON.parse(data.items[0].content);
	        	for(var i=0;i<breakthrough.length;i++){
	        		let data_str = (breakthrough[i]).split("\n");
	        		console.log(data_str)
	        		that.breakthrough_dropdown[i] = data_str[0];
	        		that.breakthrough_content[i] = data_str[1];
	        	}	        	
	        	if(localStorage.getItem('pdf_type') == 'allContent'){
	        		that.edit_breakthrough = false;
	        		that.showBreakthrough = false;
	        	}else{
	        		that.showBreakthrough = true;
	        	}
	        }
	    })

	    let DdFilter = {email:{eq:localStorage.getItem('email')}, processTypeId:{eq:"c1c17711-b4a3-4dcc-98b5-fe73ee0791d2"}, type:{eq:"process-stage"}, title:{eq:"data-gather"}};
				await this.api.ListFrameworks(DdFilter,10000).then(function (data) {
	        if(data.items.length == 0){
	        	that.edit_datadump = false;
	        	that.datadump_content[0] = '';
	        	that.data_dump[0] = '';
	        	that.datadump_content[1] = '';
	        	that.data_dump[1] = '';
	        }else{
	        	that.DdId = data.items[0].id;
	        	that.data_dump = JSON.parse(data.items[0].content);
	        	that.datadump_content = JSON.parse(data.items[0].content);
	        	if(localStorage.getItem('pdf_type') == 'allContent'){
	        		that.edit_datadump = false;
	        		that.showDatadump = false;
	        	}else{
	        		that.showDatadump = true;
	        	}
	        }
	    })

	    let QtFilter = {email:{eq:localStorage.getItem('email')}, processTypeId:{eq:"c1c17711-b4a3-4dcc-98b5-fe73ee0791d2"}, type:{eq:"process-stage"}, title:{eq:"breakthrough-question"}};
				await this.api.ListFrameworks(QtFilter,10000).then(function (data) {
	        if(data.items.length == 0){
	        	that.edit_question = false;
	        	that.question_content[0] = '';
	        	that.question_dropdown[0] = '';
	        	that.question[0] = '';
	        	that.question_content[1] = '';
	        	that.question_dropdown[1] = 'How to...';
	        	that.question[1] = '';
	        }else{
	        	that.QtId = data.items[0].id;
	        	that.question = JSON.parse(data.items[0].content);
	        	var ques = JSON.parse(data.items[0].content);
	        	for(var i=0;i<ques.length;i++){
	        		let data_str = (ques[i]).split("\n");
	        		console.log(data_str)
	        		that.question_dropdown[i] = data_str[0];
	        		that.question_content[i] = data_str[1];
	        	}
	        	if(localStorage.getItem('pdf_type') == 'allContent'){
	        		that.edit_question = false;
	        		that.showQuestion = false;
	        	}else{
	        		that.showQuestion = true;
	        	}
	        }
	    })

	    let BsFilter = {email:{eq:localStorage.getItem('email')}, processTypeId:{eq:"790b8a8e-9fdd-475d-9ab1-d320bfe69086"}, type:{eq:"process-stage"}, title:{eq:"brainstorm"}};
				await this.api.ListFrameworks(BsFilter,10000).then(function (data) {
	        if(data.items.length == 0){
	        	that.edit_brainstorm = false;
	        	for(var i=0; i<16;i++){
	        		that.brainstorm_content[i] = '';
	        		that.brainstorm[i] = '';
	        	}
	        }else{
	        	that.BsId = data.items[0].id;
	        	that.brainstorm = JSON.parse(data.items[0].content);
	        	that.brainstorm_content = JSON.parse(data.items[0].content);
	        	that.prev_brainstorm_content = JSON.parse(data.items[0].content);
	        	if(localStorage.getItem('pdf_type') == 'allContent'){
	        		that.edit_brainstorm = false;
	        		that.showBrainstorm = false;
	        	}else{
	        		that.showBrainstorm = true;
	        	}
	        }
	    })


	    let IpFilter = {email:{eq:localStorage.getItem('email')}, processTypeId:{eq:"790b8a8e-9fdd-475d-9ab1-d320bfe69086"}, type:{eq:"process-stage"}, title:{eq:"ideas-proposed"}};
				await this.api.ListFrameworks(IpFilter,10000).then(function (data) {
	        if(data.items.length == 0){
	        	that.proposed_content = [];
	        }else{
	        	that.IpId = data.items[0].id;
	        	for(var i=0; i<JSON.parse(data.items[0].content).length; i++){
	        		console.log(JSON.parse(data.items[0].content)[i]);
	        		if(JSON.parse(data.items[0].content)[i].length != 0){
	        			that.proposed_content[i] = JSON.parse(JSON.parse(data.items[0].content)[i]);
	        		}
	        	}
	        }
	    })

	    let PtFilter = {email:{eq:localStorage.getItem('email')}, processTypeId:{eq:"9d455c40-4e27-4db2-ac3f-d5743d88887e"}, type:{eq:"process-stage"}, title:{eq:"point"}};
				await this.api.ListFrameworks(PtFilter,10000).then(function (data) {
	        if(data.items.length == 0){
	        	that.edit_plus = false;
	        	that.edit_PtEvaluation = true;
	        	that.thinking_content = [];
			  	that.thinking_content[0] = '';
			  	that.opp_content = [];
			  	that.opp_text = [];
			  	that.issue_content = [];
			  	that.issue_text = [];
			  	that.plus_content = [];
			  	that.plus = [];
		  		that.PtId = '0';
			  	that.issues = [];
			  	that.opportunities = [];
	        }else{
	        	that.edit_plus = false;
	        	that.edit_PtEvaluation = false;
	        	console.log(data.items[0].content);
	        	console.log(JSON.parse(data.items[0].content));
	        	that.PtId = data.items[0].id;
	        	that.PtEvaluation_content = JSON.parse(data.items[0].content);
	        	console.log(JSON.parse(that.PtEvaluation_content.point.pluses))
	        	that.plus_content = JSON.parse(that.PtEvaluation_content.point.pluses);
	        	that.plus = JSON.parse(that.PtEvaluation_content.point.pluses);	   
	        	that.count_plus = that.plus_content.length; 
	        	if(that.count_plus > 1 && localStorage.getItem('pdf_type') != 'allContent'){
	        		that.showPlus = true;
	        	}    	
			  	var think_content = JSON.parse(that.PtEvaluation_content.point.new_thinking);
			  	console.log(think_content);
			  	for(var l=0; l<think_content.length; l++){
			  		that.thinking_content[l] = JSON.parse(think_content[l]);
			  		that.thinking[l] = JSON.parse(think_content[l]);
			  		if(l>1){
			  			for(var ct=0; ct<JSON.parse(think_content[l]).length; ct++){
			  				that.count_think = that.count_think + 1;
			  			}
			  			
			  		}
			  	}			  	
			  	console.log(that.thinking_content);
			  	for(var y=0; y<that.thinking_content[0].length; y++){
			  		that.thinking_content[0][y] = that.thinking_content[0][y].replace("\n",'');
			  	}
			  	console.log(that.thinking_content);
			  	that.selected_thinking = that.thinking_content[0];
			  	that.opportunities = JSON.parse(that.PtEvaluation_content.point.opportunities);
     			that.opp_content[0] = '';
				that.opp_text[0] = ''; 
	        	for(var opp=1;opp<JSON.parse(that.PtEvaluation_content.point.opportunities).length;opp++){
	        		let data_str3 = (JSON.parse(that.PtEvaluation_content.point.opportunities)[opp]).split("\n");
	        		console.log(data_str3)
	        		that.opp_content[opp] = data_str3[0];
	        		that.opp_text[opp] = data_str3[1];
	        	}
	        	that.selected_opp = JSON.parse(that.PtEvaluation_content.point.opportunities)[0];
	     //    	for(var sel_opp=0; sel_opp<that.selected_opp.length; sel_opp++){
			  	// 	that.selected_opp[sel_opp] = that.selected_opp[sel_opp].replace("\n",'');
			  	// }
			  	console.log(that.opp_text)
	        	that.count_opp = that.opp_text.length;
	        	if(that.count_opp > 1 && localStorage.getItem('pdf_type') != 'allContent'){
	        		that.showOpp = true;
	        	}
			  	that.issues = JSON.parse(that.PtEvaluation_content.point.issues);
     			that.issue_content[0] = '';
				that.issue_text[0] = ''; 
	        	for(var x=1;x<JSON.parse(that.PtEvaluation_content.point.issues).length;x++){
	        		let data_str2 = (JSON.parse(that.PtEvaluation_content.point.issues)[x]).split("\n");
	        		console.log(data_str2)
	        		that.issue_content[x] = data_str2[0];
	        		that.issue_text[x] = data_str2[1];
	        	}
	        	that.selected_issue = JSON.parse(that.PtEvaluation_content.point.issues)[0];
	        	if(that.selected_issue.length > 0 && localStorage.getItem('pdf_type') != 'allContent'){
			  		that.showThink = true;
			  	}
	        	that.count_issue = that.issue_text.length;
	        	if(that.count_issue > 1 && localStorage.getItem('pdf_type') != 'allContent'){
	        		that.showIssue = true;
	        	}
	        	console.log(typeof(that.selected_issue));
	        	that.edit_plus = false;
	        	that.edit_PtEvaluation = false;
	        	that.edit_thinking = false;
	        	that.edit_issues = false;
	        	that.edit_opportunity = false;
	        }
	    })

	    let StFilter = {email:{eq:localStorage.getItem('email')}, processTypeId:{eq:"9d455c40-4e27-4db2-ac3f-d5743d88887e"}, type:{eq:"process-stage"}, title:{eq:"strengthen-solution"}};
				await this.api.ListFrameworks(StFilter,10000).then(function (data) {
	        if(data.items.length == 0){
	        	that.edit_strengthen = false;
	        	that.strengthen_content = '';
	        }else{
	        	that.StId = data.items[0].id;
	        	that.strengthen_content = data.items[0].content;
        		that.showStrength = true;
	        }
	    })

	    let DoingFilter = {email:{eq:localStorage.getItem('email')}, processTypeId:{eq:"9d455c40-4e27-4db2-ac3f-d5743d88887e"}, type:{eq:"process-stage"}, title:{eq:"myself-doing"}};
				await this.api.ListFrameworks(DoingFilter,10000).then(function (data) {
	        if(data.items.length == 0){
	        	that.edit_doing = false;
	        	that.doing_content = '';
	        	that.doing_dropdown = 'What I see myself doing is...';
	        }else{
	        	that.DoingId = data.items[0].id;
	        	var doing_content = data.items[0].content.split("\n");
	        	that.doing_content = doing_content[1];
	        	that.doing_dropdown = doing_content[0];
	        	that.prev_doing_content = data.items[0].content;
        		that.showDoing = true;
	        }
	    })

	    let NxtFilter = {email:{eq:localStorage.getItem('email')}, processTypeId:{eq:"addec506-467b-4832-8853-b09e941ea2dc"}, type:{eq:"process-stage"}, title:{eq:"next-24-hours"}};
			await this.api.ListFrameworks(NxtFilter,10000).then(function (data) {
	        if(data.items.length == 0){
	        	that.edit_next = false;
	        	that.next_content[0] = '';
	        	that.next24[0] = '';
	        	that.next_content[1] = '';
	        	that.next24[1] = '';
	        }else{
	        	that.NxtId = data.items[0].id;
	        	that.next24 = JSON.parse(data.items[0].content);
	        	that.next_content = JSON.parse(data.items[0].content);
	        	if(localStorage.getItem('pdf_type') == 'allContent'){
	        		that.edit_next = false;
	        		that.showNext = false;
	        	}else{
	        		that.showNext = true;
	        	}
	        }
	    })

	    let PlanFilter = {email:{eq:localStorage.getItem('email')}, processTypeId:{eq:"addec506-467b-4832-8853-b09e941ea2dc"}, type:{eq:"process-stage"}, title:{eq:"action-plan"}};
				await this.api.ListFrameworks(PlanFilter,10000).then(function (data) {
	        if(data.items.length == 0){
	        	that.edit_plan = false;
	        	that.short_content[0] = {};
	  			that.mid_content[0] = {};
	  			that.long_content[0] = {};
	  			// that.createPDF();
	        }else{
	        	that.PlanId = data.items[0].id;
	        	var all_data = JSON.parse(data.items[0].content);
	  			that.short_content = JSON.parse(all_data.short_term);
	  			that.mid_content = JSON.parse(all_data.mid_term);
	  			that.long_content = JSON.parse(all_data.long_term);
	        	that.edit_plan = false;
	        	that.showPlan = true;
	        	// that.createPDF();
	        }
	    })
      if(callPdf) {
        this.arguments();
      }
	}

  // making args for lambda function
  public arguments(pdfName:string ='') {
	if(this.next_content == undefined) {
		this.next_content = '';
	}
	if(!this.thinking_content[0]) {
		this.thinking_content[0] ='';
	}
	
    this.args = {
      "format": "A4",
      "organization": "Organization",
      "content_type": "pdf",
      "breakthrough":this.breakthrough,
      "breakthrough_dropdown": this.breakthrough_dropdown,
      "breakthrough_content": this.breakthrough_content,
      "email":this.email,
      "cn_version":'V. 2.03',

      "data_dump":this.data_dump,
      "datadump_content": this.datadump_content,
      
      "question":this.question,
      "question_dropdown": this.question_dropdown,
      "question_content" : this.question_content,
      
      "brainstorm": this.brainstorm,
      "brainstorm_content":this.brainstorm_content,
      "proposed_content":this.proposed_content,
      
      "doing_dropdown":this.doing_dropdown,
      "doing_content" :this.doing_content,
      "plus":this.plus,
      "plus_content" :this.plus_content,
      "opp_content":this.opp_content,
      "opp_text" :this.opp_text,
      "selected_opp" : this.selected_opp,
      "issue_content":this.issue_content,
      "issue_text":this.issue_text,
      "selected_issue":this.selected_issue,
      "thinking_content":this.thinking_content,
      "selected_thinking":this.selected_thinking,
      "strengthen_content":this.strengthen_content,
      
      "short_content":this.short_content,
      "mid_content":this.mid_content,
      "long_content":this.long_content,
      "next24":this.next24,
      "next_content":this.next_content
    }
    
		this.createPDF(pdfName);
  }

  public async createPDF(pdf_type:string=''){
		if(pdf_type == 'summary'){
			console.warn('detail pdf');
			this.args.template = "summary-pdf.html";
			this.args.filename = "";
			var that = this;
			console.log(this.args);
			// this.router.navigate([".."]);
      let res = this.lmda_service.lambda_invoke('summary-pdf-dev-pdf',this.args,'Summary');
		}else{
			console.warn('detail pdf');
			this.args.template = "detailed.html";
			this.args.filename = "";
			console.log(this.args);
      let res = this.lmda_service.lambda_invoke('detail-pdf-dev-pdf',this.args,'Detailed')
  	}
  }

}
