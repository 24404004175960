import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-impersonate',
  templateUrl: './impersonate.component.html',
  styleUrls: ['./impersonate.component.scss']
})
export class ImpersonateComponent implements OnInit {

  	constructor(public http: HttpClient, private router: Router, private activatedRoute: ActivatedRoute) { }

  	ngOnInit() {
  		this.activatedRoute.params.subscribe((params: Params) => {
  			console.log(params);
	      	localStorage.setItem('id_token', params['id']);
	      	const expiresAt = JSON.stringify((7200 * 1000) + new Date().getTime());
        	localStorage.setItem('expires_at', expiresAt);
	      	localStorage.setItem('impersonation', 'true');
            this.http.get(`${environment.profileURL}/api/v1/users/find_by_token`).subscribe(data => {
                console.log(data);
                localStorage.setItem('auth_id', data['uid']);
                this.router.navigate(['/dashboard']);
            },
                error => console.log('oops', error)
            )
	      	
	    });
  	}

}
