import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { APIService } from '../API.service';

@Injectable({
  providedIn: 'root'
})

export class ChallengeSummaryService {
	public challenges: any;
	public sort_challenges: any = [{},{},{},{},{},{},{},{},{},{}];
    public all_data: any = ['',[],'',[],[],'',{},'',{},''];
    public all_ids:any = ['','','','','','','','','','']
    public plusesCon:any;
    public delData = new BehaviorSubject<boolean>(false);

  	constructor(public api: APIService) { 

  	}

  	getChallenge() {
  		let email = localStorage.getItem('email').toLowerCase();
  		let filter = {email:{eq:email}};
  		var that = this;
		return this.api.ListFrameworks(filter,10000).then(function (challenge) {
        	that.challenges = challenge.items;
        	console.log(that.challenges);
            for(var j=that.challenges.length-1; j>=0; j--){
                if(that.challenges[j]['title'] == 'vision'){
                    that.sort_challenges[0] = that.challenges[j];
                    var breakthrough = JSON.parse(that.sort_challenges[0].content);
                    let data_str = (breakthrough[0]).split("\n");
                    that.all_data[0] = data_str[0]+data_str[1];
                }
                if(that.challenges[j]['title'] == 'data-gather'){
                    that.sort_challenges[1] = that.challenges[j];
                    var datadump = JSON.parse(that.sort_challenges[1].content);
                    that.all_data[1] = datadump[0];
                }
                if(that.challenges[j]['title'] == 'breakthrough-question'){
                    that.sort_challenges[2] = that.challenges[j];
                    var question = JSON.parse(that.sort_challenges[2].content);
                    let data_str1 = (question[0]).split("\n");
                    that.all_data[2] = data_str1[0]+data_str1[1];
                }
                if(that.challenges[j]['title'] == 'brainstorm'){
                    that.sort_challenges[3] = that.challenges[j];
                    var brainstorm = JSON.parse(that.sort_challenges[3].content);
                    that.all_data[3] = brainstorm[0];
                }
                if(that.challenges[j]['title'] == 'ideas-proposed'){
                    that.sort_challenges[4] = that.challenges[j];
                    that.all_data[4] = JSON.parse(JSON.parse(that.sort_challenges[4].content)[1]);
                }
                if(that.challenges[j]['title'] == 'myself-doing'){
                    that.sort_challenges[5] = that.challenges[j];
                    that.all_data[5] = that.sort_challenges[5].content.split("\n");
                }
                if(that.challenges[j]['title'] == 'point'){
                    that.sort_challenges[6] = that.challenges[j];
                    that.all_data[6] = that.sort_challenges[6].content.split("\n");
                    
                    that.all_data['plusCon'] =JSON.parse(JSON.parse(that.sort_challenges[6].content).point.pluses)[0];
                    that.all_data['oppCon'] =JSON.parse(JSON.parse(that.sort_challenges[6].content).point.opportunities)[0];
                    that.all_data['issuesCon'] =JSON.parse(JSON.parse(that.sort_challenges[6].content).point.issues)[0];
                    that.all_data['thinkCon'] =[JSON.parse(JSON.parse(that.sort_challenges[6].content).point.new_thinking)[1]];
                    
                    console.log(that.all_data['thinkCon']);
                    // that.all_data[6] = [];
                }
                if(that.challenges[j]['title'] == 'strengthen-solution'){
                    that.sort_challenges[7] = that.challenges[j];
                    that.all_data[7] = that.sort_challenges[7].content;
                }
                if(that.challenges[j]['title'] == 'action-plan'){
                    that.sort_challenges[8] = that.challenges[j];
                    that.all_data[8] = [];
                    var all_terms = JSON.parse(that.sort_challenges[8].content);
                    that.all_data[8].short_content = JSON.parse(all_terms.short_term);
                    that.all_data[8].mid_content = JSON.parse(all_terms.mid_term);
                    that.all_data[8].long_content = JSON.parse(all_terms.long_term);
                }
                if(that.challenges[j]['title'] == 'next-24-hours'){
                    that.sort_challenges[9] = that.challenges[j];
                    var next = JSON.parse(that.sort_challenges[9].content);
                    that.all_data[9] = next[0];
                }
            }

            return that.all_data;
    	})
  	}

    getChallengeIds(){
        let email = localStorage.getItem('email').toLowerCase();
        let filter = {email:{eq:email}};
        var that = this;
        return this.api.ListFrameworks(filter,10000).then(function (challenge) {
            that.challenges = challenge.items;
            console.log(that.challenges);
            for(var j=that.challenges.length-1; j>=0; j--){
                if(that.challenges[j]['title'] == 'vision'){
                    that.all_ids[0] = that.challenges[j]['id'];
                }
                if(that.challenges[j]['title'] == 'data-gather'){
                    that.all_ids[1] = that.challenges[j]['id'];
                }
                if(that.challenges[j]['title'] == 'breakthrough-question'){
                    that.all_ids[2] = that.challenges[j]['id'];
                }
                if(that.challenges[j]['title'] == 'brainstorm'){
                    that.all_ids[3] = that.challenges[j]['id'];
                }
                if(that.challenges[j]['title'] == 'ideas-proposed'){
                    that.all_ids[4] = that.challenges[j]['id'];
                }
                if(that.challenges[j]['title'] == 'myself-doing'){
                    that.all_ids[5] = that.challenges[j]['id'];
                }
                if(that.challenges[j]['title'] == 'point'){
                    that.all_ids[6] = that.challenges[j]['id'];
                }
                if(that.challenges[j]['title'] == 'strengthen-solution'){
                    that.all_ids[7] = that.challenges[j]['id'];
                }
                if(that.challenges[j]['title'] == 'action-plan'){
                    that.all_ids[8] = that.challenges[j]['id'];
                }
                if(that.challenges[j]['title'] == 'next-24-hours'){
                    that.all_ids[9] = that.challenges[j]['id'];
                }
            }

            return that.all_ids;
        })
    }
}
