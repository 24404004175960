import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(public http: HttpClient) { }

  public getRequest(url) {
    console.log('http service get request')
      try {
        return this.http.get(url)

      } catch(err) {
          console.log(err)
          setTimeout(function() {
            this.toastr.options = {
                closeButton: true,
                progressBar: true,
                timeOut: 2000
            };
            this.toastr.error('Something went wrong!', 'Oops!');
        }, 1000);
      }
  }

  public async postRequest(url, data) {
    console.log('http service post request')
    try {
      let res =  await this.http.post(url, data)
      return res;
    } catch(err) {
        console.log(err)
        setTimeout(function() {
          this.toastr.options = {
              closeButton: true,
              progressBar: true,
              timeOut: 2000
          };
          this.toastr.error('Something went wrong!', 'Oops!');
      }, 1000);
    }
  }

  public async putRequest(url,data) {
    console.log('http service put request')
      try {
        return await this.http.put(url, data)
      } catch(err) {
          console.log(err)
          setTimeout(function() {
            this.toastr.options = {
                closeButton: true,
                progressBar: true,
                timeOut: 2000
            };
            this.toastr.error('Something went wrong!', 'Oops!');
        }, 1000);
      }
  }

  public async deleteRequest(url) {
    console.log('http service delete request')
      try {
        let res = await this.http.delete(url)
        return res;
      } catch(err) {
          console.log(err)
          setTimeout(function() {
            this.toastr.options = {
                closeButton: true,
                progressBar: true,
                timeOut: 2000
            };
            this.toastr.error('Something went wrong!', 'Oops!');
        }, 1000);
      }
  }
}
