import { Component } from '@angular/core';
import { DevelopChallengeComponent } from '../../challenges/develop-challenge/develop-challenge.component';

@Component({
  selector: 'app-issue-unchecked-popup',
  templateUrl: './issue-unchecked-popup.component.html',
  styleUrls: ['./issue-unchecked-popup.component.scss']
})
export class IssueUncheckedPopupComponent {
  constructor(public developChlngCompo: DevelopChallengeComponent){}

  public close() {
    $('.issue-uncheck-modal').css('display', 'none');
  }
}
