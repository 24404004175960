import { Component, Input } from '@angular/core';
import { AddressesComponent } from '../../addresses/addresses.component';

@Component({
  selector: 'app-address-popup',
  templateUrl: './address-popup.component.html',
  styleUrls: ['./address-popup.component.scss']
})
export class AddressPopupComponent {
  @Input() newOrUpdate:boolean = false;

  constructor(public addressesCompo: AddressesComponent) {}

  public close() {
    $('.address-modal').css('display', 'none');
  }
}
