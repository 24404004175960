import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { SlideoutContentService } from '../../../service/slideout-content.service';
import {environment} from '../../../../environments/environment';
@Component({
  selector: 'app-cn-print-clarify',
  templateUrl: './cn-print-clarify.component.html',
  styleUrls: ['./cn-print-clarify.component.scss']
})
export class CnPrintClarifyComponent implements OnInit {

  public modal:any;
  env:any;

  constructor(private modalService: NgbModal, public aroute: ActivatedRoute,public slideContent:SlideoutContentService) { }

  ngOnInit(): void {
    this.env = environment;
  }
  
  // open side popup
  public openPopup(content) {
    console.log(content)
    this.slideContent.process_type.next(content);
  }


}
