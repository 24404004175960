import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { environment } from './../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-cig-video-full',
  templateUrl: './cig-video-full.component.html',
  styleUrls: ['./cig-video-full.component.scss']
})
export class CigVideoFullComponent implements OnInit {

  @Input() profile:any;
  @Input() slides;
  @Input() author;
  public env;
  public profiles : any;
  src:any = '';

  constructor( private sanitizer: DomSanitizer) { }

  ngOnInit() {
    console.log(this.slides)
      this.env=environment;
      this.profiles = this.profile;
      this.src = "https://www.youtube.com/embed/" +this.slides[28]?.video_link + "?modestbranding=1&amp;rel=0&amp;showinfo=0";
      this.src = this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
      console.log(this.src)
  }

}
