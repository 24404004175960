import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-myteams-tabs-head',
  templateUrl: './myteams-tabs-head.component.html',
  styleUrls: ['./myteams-tabs-head.component.scss']
})
export class MyteamsTabsHeadComponent {

  @Input() activeTab:any;
  @Input() members;
  @Input() index;
  @Output() activeTabVal:EventEmitter<any>= new EventEmitter();

  ngOnInit() {
    this.activeTab["teamName"] = 'graph' + this.members.id;
    console.warn('activeTab',this.members)
  }

  public tab(tabName:string,index) {
    this.activeTabVal.emit({tabName:tabName, index: index});
    this.activeTab = tabName;
    console.log('activeTab', this.members)
  }
}
