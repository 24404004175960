import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from './../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-cig-video-mp4',
  templateUrl: './cig-video-mp4.component.html',
  styleUrls: ['./cig-video-mp4.component.scss']
})
export class CigVideoMp4Component implements OnInit {

  @Input() profile:any;
  @Input() slides;
  @Input() author;
  public env;
  public profiles : any;
  src;
  poster:string = '';


  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    console.log(this.slides)
    this.env=environment;
    this.profiles = this.profile;
    this.src = this.slides[30]?.video_link;
    this.poster = this.slides[30]?.poster;
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
    console.log(this.src)
  }

}
