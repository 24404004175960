import { Component, OnInit, Input } from '@angular/core';
// import { Auth } from '../../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import {CommonDataService} from './../common-data.service';

@Component({
  selector: 'app-group-profile',
  templateUrl: './group-profile.component.html',
  styleUrls: ['./group-profile.component.scss']
})
export class GroupProfileComponent implements OnInit {
	@Input() group:any;
	// public neutral: number;
	// public height:number;
	// @Input() small;
	// @Input() activated;
	clarifier_low:number;
	clarifier_high:number;
	ideator_low:number;
	ideator_high:number;
	developer_low:number;
	developer_high:number;
	implementor_low:number;
	implementor_high:number;
	integrator:number;
	highest_score:number;
  	constructor( public http: HttpClient,public service:CommonDataService ) { }
  	
	ngOnInit() {

		this.clarifier_low = 0;
	    this.clarifier_high = 0;
	    this.ideator_high = 0;
	    this.ideator_low = 0;
	    this.developer_high = 0;
	    this.developer_low = 0;
	    this.implementor_high = 0;
	    this.implementor_low = 0;
	    this.integrator = 0;
	    this.highest_score = 0;
		this.group.members.forEach(list => {

	        if(list.default_profile.clarifier_score == 'Low'){
	          this.clarifier_low = this.clarifier_low + 1;
	          if( this.clarifier_low > this.highest_score ){
				this.highest_score = this.clarifier_low;
		    }
	        }

	        if(list.default_profile.clarifier_score == 'High'){
	          this.clarifier_high = this.clarifier_high + 1;
	          if( this.clarifier_high > this.highest_score ){
				this.highest_score = this.clarifier_high;
		    }
	        }

	        if(list.default_profile.ideator_score == 'Low'){
	          this.ideator_low = this.ideator_low + 1;
	          if( this.ideator_low > this.highest_score ){
				this.highest_score = this.ideator_low;
		    }
	        }

	        if(list.default_profile.ideator_score == 'High'){
	          this.ideator_high = this.ideator_high + 1;
	          if( this.ideator_high > this.highest_score ){
				this.highest_score = this.ideator_high;
		    }
	        }

	        if(list.default_profile.developer_score == 'Low'){
	          this.developer_low = this.developer_low + 1;
	          if( this.developer_low > this.highest_score ){
				this.highest_score = this.developer_low;
		    }
	        }

	        if(list.default_profile.developer_score == 'High'){
	          this.developer_high = this.developer_high + 1;
	          if( this.developer_high > this.highest_score ){
				this.highest_score = this.developer_high;
		    }
	        }

	        if(list.default_profile.implementer_score == 'Low'){
	          this.implementor_low = this.implementor_low + 1;
	          if( this.implementor_low > this.highest_score ){
				this.highest_score = this.implementor_low;
		    }
	        }

	        if(list.default_profile.implementer_score == 'High'){
	          this.implementor_high = this.implementor_high + 1;
	          if( this.implementor_high > this.highest_score ){
				this.highest_score = this.implementor_high;
		    }
	        }

	        if(list.default_profile.clarifier_score == 'Neutral' && list.default_profile.ideator_score == 'Neutral' && list.default_profile.developer_score == 'Neutral' && list.default_profile.implementer_score == 'Neutral'){
	          this.integrator += 1;
	        }

		});
	}

}