import { Component, OnInit } from '@angular/core';
import {CommonDataService} from './../common-data.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnInit {
	profile:any;
	teams:any;
	meta:any;
	id:string;
	totalRecords:number;
	team:any;
	isLoading:boolean;	
	query_string:string;
	count:number;
	curPage:number;
	pageLen:number;
	item:any;
	membersByPage:number;
    teamName:string;
    teamOrg:string;
    orgs:any;
    accountNgrx:any;

	constructor(public service : CommonDataService, public http: HttpClient, private router: Router,public store:Store<any> ) { }

	ngOnInit() {
        this.teamName = '';
        this.teamOrg = '';
  		this.isLoading = true;
  		// this.id = localStorage.getItem('member_id');
  		this.membersByPage = 20;
          this.accountNgrx = this.store.select<any>((state) => state.profile.account);
          this.accountNgrx.subscribe((res)=> {
            console.log(res["member"]["id"])
            // this.id = localStorage.getItem('member_id');
            this.id = res["member"]["id"]
            this.http.get(`${environment.profileURL}/api/v1/members/`+this.id+`/teams.json?admin=0&per_page=20`).subscribe(data => {
                console.log(data);
                this.teams = data['teams'];
                var orgs = [];
                for(var i=0; i<this.teams.length;i++){
                    orgs.push({id: parseInt(this.teams[i].organization_id), name: this.teams[i].organization_name});
                }
                for(var k=0; k<orgs.length-1; k++){
                    if(orgs[k].id == orgs[k+1].id){
                        orgs.splice(k,1);
                    }
                }
                for(var i=0; i<orgs.length; i++){
                    orgs[i].teams = [];
                    for(var j=0; j<this.teams.length; j++){
                        if(orgs[i].id == this.teams[j].organization_id){
                            orgs[i].teams.push({team_id: parseInt(this.teams[i].id), team_name: this.teams[i].name});
                        }
                    }
                }
                this.orgs = orgs;
                console.log(orgs);
                this.meta = data['meta'];
                this.totalRecords = this.meta.total_count;
                this.count = this.meta.total_count;
                this.isLoading = false;
                   this.pageLen = this.meta.total_pages;
                   this.curPage = 0;
                   this.item = [];
                   for(var i = 1; i<=this.pageLen;i++){
                    this.item.push(i);
                }
                if(this.meta.total_count > this.membersByPage){
                    this.render( this.curPage, this.item, true );
                    console.log(data);
                }            
            },
                error => console.log('oops', error)
            )
        })
  	}

  	public openMembers(record){
  		if(record.open){
        	record.open = false;
      	}else{
        	record.open = true;
      	}
  	}

  	public isPageInRange = function( curPage, index, maxPages, pageBefore, pageAfter ) {
        if (index <= 1) {
	        // first 2 pages
	        return true;
        }
        if (index >= maxPages - 2) {
	        // last 2 pages
	        return true;
        }
        if (index >= curPage - pageBefore && index <= curPage + pageAfter) {
	        return true;
        }
	}
  	public render = function( curPage, item, first ) {
        var html = '', separatorAdded = false;
        for(var i in item){
            if ( this.isPageInRange( curPage, i, this.pageLen, 2, 2 ) ) {
                html += '<li data-page="' + i + '">' + item[i] + '</li>';
                // as we added a page, we reset the separator Added
                separatorAdded = false;
            } else {
                if (!separatorAdded) {
                    // only add a separator when it wasn't added before
                    html += '<li class="separator" />';
                    separatorAdded = true;
                }
            }
        }
        var holder = document.querySelector('#holder'); 
        if(this.meta.total_count > this.membersByPage){ 
            holder.innerHTML = html;
            if(document.querySelector('#holder>li[data-page="' + curPage + '"]') != null){document.querySelector('#holder>li[data-page="' + curPage + '"]').classList.add('active');}
            if ( first ) {
                holder.addEventListener('click', e => {
                    console.log(e.target['attributes']['data-page'].value);
                    if (!e.target['attributes']['data-page'].value) {
                        // no relevant item clicked (you could however offer expand here )
                        return;
                    }

                    curPage = parseInt(e.target['attributes']['data-page'].value);
                    var page_number = curPage+1;
                    this.isLoading = true;
                    this.http.get(`${this.query_string}&page=${page_number}`).subscribe(data => {
                    	console.log(data);
                        this.teams = data['teams'];
                		this.meta = data['meta']; 
                		this.count = this.meta.total_count;
                        this.pageLen = this.meta.total_pages;
                        this.isLoading = false;
                        this.curPage = 0;
                        this.item = [];
                        for(var i = 1; i<=this.pageLen;i++){
                            this.item.push(i);
                        }

                    },
                    error => console.log('oops', error)
                    )
                    this.render( curPage, item );
                });
            }
        }else{
            holder.innerHTML = null;
        }
    }

    public Filter(){
        this.teams = [];
    	this.query_string = environment.profileURL+`/api/v1/members/`+this.id+`/teams.json?admin=0&per_page=20`;
        if(this.teamName){
            this.query_string += '&name=' + this.teamName;
        }  
        if(this.teamOrg){
            this.query_string += '&org_name=' + this.teamOrg;
        }      
        this.isLoading = true;
        console.log(this.query_string);
        this.http.get(`${this.query_string}`).subscribe(data => {
            this.teams = data['teams'];
            this.meta = data['meta']; 
            this.count = this.meta.total_count;
            this.pageLen = this.meta.total_pages;
            this.curPage = 0;
            this.item = [];
            this.isLoading = false;
            for(var i = 1; i<=this.pageLen;i++){
                this.item.push(i);
            }
            console.log(true);
        	this.render( this.curPage, this.item, true );
        	console.log(data);        
        },
            error => console.log('oops', error)
        )
    }

    public length1(){
        if(this.teamName.length >= 3 || this.teamName.length == 0){
            this.Filter();
        }
    }

    public length2(){
        if(this.teamOrg.length >= 3 || this.teamOrg.length == 0){
            this.Filter();
        }
    }

    public resetTeamName() {
        this.teamName = '';
        this.Filter();
    }

    public resetOrgName() {
        this.teamOrg = '';
        this.Filter();
    }

}
