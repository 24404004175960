import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-myteams-memberstab',
  templateUrl: './myteams-memberstab.component.html',
  styleUrls: ['./myteams-memberstab.component.scss']
})
export class MyteamsMemberstabComponent {

  @Input() teamMembers;
  membersByPage:number = 5 
  teamMembersPgBs:Array<object> = [];
  currentPg = 1;


  ngOnInit() {
    console.log('mebers tabs',this.teamMembers)
    this.perPageMembers() 
  }

  public perPageMembers() {
    this.teamMembersPgBs = [];
    this.teamMembers.forEach((item,index)=> {
      console.log('index',this.membersByPage)
      if(index < this.membersByPage) {
        this.teamMembersPgBs.push(item)
      }
    })
  }

  public paginationPage(pageNumber:number) {
    this.teamMembersPgBs = [];
    this.currentPg = pageNumber
    this.teamMembers.forEach((item,index)=> {
      if(((this.membersByPage * (pageNumber -1)) <= index) && (index < this.membersByPage * pageNumber)) {
        console.log('index',index, item)
        this.teamMembersPgBs.push(item)
      }
    })
  }

}
