/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import {API, graphqlOperation } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api/lib/types";
import * as Observable from "zen-observable";

export type CreateLearnerMapInput = {
  id?: string | null;
  title: string;
  image?: string | null;
  location?: string | null;
  owners?: string | null;
  trainingFirstDay?: string | null;
  trainingLastDay?: string | null;
  daysOfPrework?: number | null;
  daysBeforeAssessmentDeadline?: number | null;
  daysAfterTrainingBeforeTestOpens?: number | null;
  daysAfterTrainingBeforeTestCloses?: number | null;
  daysBeforePointDue?: number | null;
  createdAt?: string | null;
  learnerMapOwnerMemberId?: string | null;
  learnerMapMaptypeId?: string | null;
};

export type UpdateLearnerMapInput = {
  id: string;
  title?: string | null;
  image?: string | null;
  location?: string | null;
  owners?: string | null;
  trainingFirstDay?: string | null;
  trainingLastDay?: string | null;
  daysOfPrework?: number | null;
  daysBeforeAssessmentDeadline?: number | null;
  daysAfterTrainingBeforeTestOpens?: number | null;
  daysAfterTrainingBeforeTestCloses?: number | null;
  daysBeforePointDue?: number | null;
  createdAt?: string | null;
  learnerMapOwnerMemberId?: string | null;
  learnerMapMaptypeId?: string | null;
};

export type DeleteLearnerMapInput = {
  id?: string | null;
};

export type CreateMapTypeInput = {
  id?: string | null;
  title: string;
};

export type UpdateMapTypeInput = {
  id: string;
  title?: string | null;
};

export type DeleteMapTypeInput = {
  id?: string | null;
};

export type CreateColumnInput = {
  id?: string | null;
  title: string;
  order?: number | null;
  open?: string | null;
  close?: string | null;
  columnLearnermapId?: string | null;
  columnColumntypeId?: string | null;
};

export type UpdateColumnInput = {
  id: string;
  title?: string | null;
  order?: number | null;
  open?: string | null;
  close?: string | null;
  columnLearnermapId?: string | null;
  columnColumntypeId?: string | null;
};

export type DeleteColumnInput = {
  id?: string | null;
};

export type CreateColumnTypeInput = {
  id?: string | null;
  title: string;
};

export type UpdateColumnTypeInput = {
  id: string;
  title?: string | null;
};

export type DeleteColumnTypeInput = {
  id?: string | null;
};

export type CreateCardInput = {
  id?: string | null;
  title: string;
  active?: boolean | null;
  type?: string | null;
  lesson?: string | null;
  medium?: string | null;
  runtime?: string | null;
  parent?: string | null;
  link?: string | null;
  bg?: string | null;
  button?: string | null;
  text?: string | null;
  body?: string | null;
  target?: string | null;
  open?: string | null;
  close?: string | null;
  order?: number | null;
  cardColumnId?: string | null;
};

export type UpdateCardInput = {
  id: string;
  title?: string | null;
  active?: boolean | null;
  type?: string | null;
  lesson?: string | null;
  medium?: string | null;
  runtime?: string | null;
  parent?: string | null;
  link?: string | null;
  bg?: string | null;
  button?: string | null;
  text?: string | null;
  body?: string | null;
  target?: string | null;
  open?: string | null;
  close?: string | null;
  order?: number | null;
  cardColumnId?: string | null;
};

export type DeleteCardInput = {
  id?: string | null;
};

export type CreateMemberInput = {
  id?: string | null;
  title?: string | null;
  email?: string | null;
  createdAt?: string | null;
};

export type UpdateMemberInput = {
  id: string;
  title?: string | null;
  email?: string | null;
  createdAt?: string | null;
};

export type DeleteMemberInput = {
  id?: string | null;
};

export type CreateConversionInput = {
  id?: string | null;
  email?: string | null;
  createdAt?: string | null;
  cardID?: string | null;
  learnermapID?: string | null;
};

export type UpdateConversionInput = {
  id: string;
  email?: string | null;
  createdAt?: string | null;
  cardID?: string | null;
  learnermapID?: string | null;
};

export type DeleteConversionInput = {
  id?: string | null;
};

export type CreateMarkedInput = {
  id?: string | null;
  marked: boolean;
  email?: string | null;
  createdAt?: string | null;
  cardID?: string | null;
};

export type UpdateMarkedInput = {
  id: string;
  marked?: boolean | null;
  email?: string | null;
  createdAt?: string | null;
  cardID?: string | null;
};

export type DeleteMarkedInput = {
  id?: string | null;
};

export type CreateFrameworkInput = {
  id?: string | null;
  title?: string | null;
  type?: string | null;
  email?: string | null;
  content?: string | null;
  groupId?: string | null;
  processTypeId?: string | null;
  toolId?: string | null;
  order?: number | null;
  minutes?: number | null;
  attributes?: string | null;
  users?: string | null;
};

export type UpdateFrameworkInput = {
  id: string;
  title?: string | null;
  type?: string | null;
  email?: string | null;
  content?: string | null;
  groupId?: string | null;
  processTypeId?: string | null;
  toolId?: string | null;
  order?: number | null;
  minutes?: number | null;
  attributes?: string | null;
  users?: string | null;
};

export type DeleteFrameworkInput = {
  id?: string | null;
};

export type ModelLearnerMapFilterInput = {
  id?: ModelIDFilterInput | null;
  title?: ModelStringFilterInput | null;
  image?: ModelStringFilterInput | null;
  location?: ModelStringFilterInput | null;
  owners?: ModelStringFilterInput | null;
  trainingFirstDay?: ModelStringFilterInput | null;
  trainingLastDay?: ModelStringFilterInput | null;
  daysOfPrework?: ModelIntFilterInput | null;
  daysBeforeAssessmentDeadline?: ModelIntFilterInput | null;
  daysAfterTrainingBeforeTestOpens?: ModelIntFilterInput | null;
  daysAfterTrainingBeforeTestCloses?: ModelIntFilterInput | null;
  daysBeforePointDue?: ModelIntFilterInput | null;
  createdAt?: ModelStringFilterInput | null;
  and?: Array<ModelLearnerMapFilterInput | null> | null;
  or?: Array<ModelLearnerMapFilterInput | null> | null;
  not?: ModelLearnerMapFilterInput | null;
};

export type ModelIDFilterInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelStringFilterInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelIntFilterInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelMapTypeFilterInput = {
  id?: ModelIDFilterInput | null;
  title?: ModelStringFilterInput | null;
  and?: Array<ModelMapTypeFilterInput | null> | null;
  or?: Array<ModelMapTypeFilterInput | null> | null;
  not?: ModelMapTypeFilterInput | null;
};

export type ModelColumnFilterInput = {
  id?: ModelIDFilterInput | null;
  title?: ModelStringFilterInput | null;
  order?: ModelIntFilterInput | null;
  open?: ModelStringFilterInput | null;
  close?: ModelStringFilterInput | null;
  and?: Array<ModelColumnFilterInput | null> | null;
  or?: Array<ModelColumnFilterInput | null> | null;
  not?: ModelColumnFilterInput | null;
};

export type ModelColumnTypeFilterInput = {
  id?: ModelIDFilterInput | null;
  title?: ModelStringFilterInput | null;
  and?: Array<ModelColumnTypeFilterInput | null> | null;
  or?: Array<ModelColumnTypeFilterInput | null> | null;
  not?: ModelColumnTypeFilterInput | null;
};

export type ModelCardFilterInput = {
  id?: ModelIDFilterInput | null;
  title?: ModelStringFilterInput | null;
  active?: ModelBooleanFilterInput | null;
  type?: ModelStringFilterInput | null;
  lesson?: ModelStringFilterInput | null;
  medium?: ModelStringFilterInput | null;
  runtime?: ModelStringFilterInput | null;
  parent?: ModelStringFilterInput | null;
  link?: ModelStringFilterInput | null;
  bg?: ModelStringFilterInput | null;
  button?: ModelStringFilterInput | null;
  text?: ModelStringFilterInput | null;
  body?: ModelStringFilterInput | null;
  target?: ModelStringFilterInput | null;
  open?: ModelStringFilterInput | null;
  close?: ModelStringFilterInput | null;
  order?: ModelIntFilterInput | null;
  and?: Array<ModelCardFilterInput | null> | null;
  or?: Array<ModelCardFilterInput | null> | null;
  not?: ModelCardFilterInput | null;
};

export type ModelBooleanFilterInput = {
  ne?: boolean | null;
  eq?: boolean | null;
};

export type ModelMemberFilterInput = {
  id?: ModelIDFilterInput | null;
  title?: ModelStringFilterInput | null;
  email?: ModelStringFilterInput | null;
  createdAt?: ModelStringFilterInput | null;
  and?: Array<ModelMemberFilterInput | null> | null;
  or?: Array<ModelMemberFilterInput | null> | null;
  not?: ModelMemberFilterInput | null;
};

export type ModelConversionFilterInput = {
  id?: ModelIDFilterInput | null;
  email?: ModelStringFilterInput | null;
  createdAt?: ModelStringFilterInput | null;
  cardID?: ModelStringFilterInput | null;
  learnermapID?: ModelStringFilterInput | null;
  and?: Array<ModelConversionFilterInput | null> | null;
  or?: Array<ModelConversionFilterInput | null> | null;
  not?: ModelConversionFilterInput | null;
};

export type ModelMarkedFilterInput = {
  id?: ModelIDFilterInput | null;
  marked?: ModelBooleanFilterInput | null;
  email?: ModelStringFilterInput | null;
  createdAt?: ModelStringFilterInput | null;
  cardID?: ModelStringFilterInput | null;
  and?: Array<ModelMarkedFilterInput | null> | null;
  or?: Array<ModelMarkedFilterInput | null> | null;
  not?: ModelMarkedFilterInput | null;
};

export type ModelFrameworkFilterInput = {
  id?: ModelIDFilterInput | null;
  title?: ModelStringFilterInput | null;
  type?: ModelStringFilterInput | null;
  email?: ModelStringFilterInput | null;
  content?: ModelStringFilterInput | null;
  groupId?: ModelStringFilterInput | null;
  processTypeId?: ModelStringFilterInput | null;
  toolId?: ModelStringFilterInput | null;
  order?: ModelIntFilterInput | null;
  minutes?: ModelIntFilterInput | null;
  attributes?: ModelStringFilterInput | null;
  users?: ModelStringFilterInput | null;
  and?: Array<ModelFrameworkFilterInput | null> | null;
  or?: Array<ModelFrameworkFilterInput | null> | null;
  not?: ModelFrameworkFilterInput | null;
};

export type CreateLearnerMapMutation = {
  __typename: "LearnerMap";
  id: string;
  title: string;
  image: string | null;
  location: string | null;
  owners: string | null;
  ownerMember: {
    __typename: "Member";
    id: string;
    title: string | null;
    email: string | null;
    createdAt: string | null;
    learnermaps: {
      __typename: "ModelLearnerMapConnection";
      nextToken: string | null;
    } | null;
  } | null;
  trainingFirstDay: string | null;
  trainingLastDay: string | null;
  daysOfPrework: number | null;
  daysBeforeAssessmentDeadline: number | null;
  daysAfterTrainingBeforeTestOpens: number | null;
  daysAfterTrainingBeforeTestCloses: number | null;
  daysBeforePointDue: number | null;
  createdAt: string | null;
  maptype: {
    __typename: "MapType";
    id: string;
    title: string;
    learnermaps: {
      __typename: "ModelLearnerMapConnection";
      nextToken: string | null;
    } | null;
  } | null;
  columns: {
    __typename: "ModelColumnConnection";
    items: Array<{
      __typename: "Column";
      id: string;
      title: string;
      order: number | null;
      open: string | null;
      close: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type UpdateLearnerMapMutation = {
  __typename: "LearnerMap";
  id: string;
  title: string;
  image: string | null;
  location: string | null;
  owners: string | null;
  ownerMember: {
    __typename: "Member";
    id: string;
    title: string | null;
    email: string | null;
    createdAt: string | null;
    learnermaps: {
      __typename: "ModelLearnerMapConnection";
      nextToken: string | null;
    } | null;
  } | null;
  trainingFirstDay: string | null;
  trainingLastDay: string | null;
  daysOfPrework: number | null;
  daysBeforeAssessmentDeadline: number | null;
  daysAfterTrainingBeforeTestOpens: number | null;
  daysAfterTrainingBeforeTestCloses: number | null;
  daysBeforePointDue: number | null;
  createdAt: string | null;
  maptype: {
    __typename: "MapType";
    id: string;
    title: string;
    learnermaps: {
      __typename: "ModelLearnerMapConnection";
      nextToken: string | null;
    } | null;
  } | null;
  columns: {
    __typename: "ModelColumnConnection";
    items: Array<{
      __typename: "Column";
      id: string;
      title: string;
      order: number | null;
      open: string | null;
      close: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type DeleteLearnerMapMutation = {
  __typename: "LearnerMap";
  id: string;
  title: string;
  image: string | null;
  location: string | null;
  owners: string | null;
  ownerMember: {
    __typename: "Member";
    id: string;
    title: string | null;
    email: string | null;
    createdAt: string | null;
    learnermaps: {
      __typename: "ModelLearnerMapConnection";
      nextToken: string | null;
    } | null;
  } | null;
  trainingFirstDay: string | null;
  trainingLastDay: string | null;
  daysOfPrework: number | null;
  daysBeforeAssessmentDeadline: number | null;
  daysAfterTrainingBeforeTestOpens: number | null;
  daysAfterTrainingBeforeTestCloses: number | null;
  daysBeforePointDue: number | null;
  createdAt: string | null;
  maptype: {
    __typename: "MapType";
    id: string;
    title: string;
    learnermaps: {
      __typename: "ModelLearnerMapConnection";
      nextToken: string | null;
    } | null;
  } | null;
  columns: {
    __typename: "ModelColumnConnection";
    items: Array<{
      __typename: "Column";
      id: string;
      title: string;
      order: number | null;
      open: string | null;
      close: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type CreateMapTypeMutation = {
  __typename: "MapType";
  id: string;
  title: string;
  learnermaps: {
    __typename: "ModelLearnerMapConnection";
    items: Array<{
      __typename: "LearnerMap";
      id: string;
      title: string;
      image: string | null;
      location: string | null;
      owners: string | null;
      trainingFirstDay: string | null;
      trainingLastDay: string | null;
      daysOfPrework: number | null;
      daysBeforeAssessmentDeadline: number | null;
      daysAfterTrainingBeforeTestOpens: number | null;
      daysAfterTrainingBeforeTestCloses: number | null;
      daysBeforePointDue: number | null;
      createdAt: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type UpdateMapTypeMutation = {
  __typename: "MapType";
  id: string;
  title: string;
  learnermaps: {
    __typename: "ModelLearnerMapConnection";
    items: Array<{
      __typename: "LearnerMap";
      id: string;
      title: string;
      image: string | null;
      location: string | null;
      owners: string | null;
      trainingFirstDay: string | null;
      trainingLastDay: string | null;
      daysOfPrework: number | null;
      daysBeforeAssessmentDeadline: number | null;
      daysAfterTrainingBeforeTestOpens: number | null;
      daysAfterTrainingBeforeTestCloses: number | null;
      daysBeforePointDue: number | null;
      createdAt: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type DeleteMapTypeMutation = {
  __typename: "MapType";
  id: string;
  title: string;
  learnermaps: {
    __typename: "ModelLearnerMapConnection";
    items: Array<{
      __typename: "LearnerMap";
      id: string;
      title: string;
      image: string | null;
      location: string | null;
      owners: string | null;
      trainingFirstDay: string | null;
      trainingLastDay: string | null;
      daysOfPrework: number | null;
      daysBeforeAssessmentDeadline: number | null;
      daysAfterTrainingBeforeTestOpens: number | null;
      daysAfterTrainingBeforeTestCloses: number | null;
      daysBeforePointDue: number | null;
      createdAt: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type CreateColumnMutation = {
  __typename: "Column";
  id: string;
  title: string;
  order: number | null;
  open: string | null;
  close: string | null;
  learnermap: {
    __typename: "LearnerMap";
    id: string;
    title: string;
    image: string | null;
    location: string | null;
    owners: string | null;
    ownerMember: {
      __typename: "Member";
      id: string;
      title: string | null;
      email: string | null;
      createdAt: string | null;
    } | null;
    trainingFirstDay: string | null;
    trainingLastDay: string | null;
    daysOfPrework: number | null;
    daysBeforeAssessmentDeadline: number | null;
    daysAfterTrainingBeforeTestOpens: number | null;
    daysAfterTrainingBeforeTestCloses: number | null;
    daysBeforePointDue: number | null;
    createdAt: string | null;
    maptype: {
      __typename: "MapType";
      id: string;
      title: string;
    } | null;
    columns: {
      __typename: "ModelColumnConnection";
      nextToken: string | null;
    } | null;
  } | null;
  cards: {
    __typename: "ModelCardConnection";
    items: Array<{
      __typename: "Card";
      id: string;
      title: string;
      active: boolean | null;
      type: string | null;
      lesson: string | null;
      medium: string | null;
      runtime: string | null;
      parent: string | null;
      link: string | null;
      bg: string | null;
      button: string | null;
      text: string | null;
      body: string | null;
      target: string | null;
      open: string | null;
      close: string | null;
      order: number | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  columntype: {
    __typename: "ColumnType";
    id: string;
    title: string;
    columns: {
      __typename: "ModelColumnConnection";
      nextToken: string | null;
    } | null;
  } | null;
};

export type UpdateColumnMutation = {
  __typename: "Column";
  id: string;
  title: string;
  order: number | null;
  open: string | null;
  close: string | null;
  learnermap: {
    __typename: "LearnerMap";
    id: string;
    title: string;
    image: string | null;
    location: string | null;
    owners: string | null;
    ownerMember: {
      __typename: "Member";
      id: string;
      title: string | null;
      email: string | null;
      createdAt: string | null;
    } | null;
    trainingFirstDay: string | null;
    trainingLastDay: string | null;
    daysOfPrework: number | null;
    daysBeforeAssessmentDeadline: number | null;
    daysAfterTrainingBeforeTestOpens: number | null;
    daysAfterTrainingBeforeTestCloses: number | null;
    daysBeforePointDue: number | null;
    createdAt: string | null;
    maptype: {
      __typename: "MapType";
      id: string;
      title: string;
    } | null;
    columns: {
      __typename: "ModelColumnConnection";
      nextToken: string | null;
    } | null;
  } | null;
  cards: {
    __typename: "ModelCardConnection";
    items: Array<{
      __typename: "Card";
      id: string;
      title: string;
      active: boolean | null;
      type: string | null;
      lesson: string | null;
      medium: string | null;
      runtime: string | null;
      parent: string | null;
      link: string | null;
      bg: string | null;
      button: string | null;
      text: string | null;
      body: string | null;
      target: string | null;
      open: string | null;
      close: string | null;
      order: number | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  columntype: {
    __typename: "ColumnType";
    id: string;
    title: string;
    columns: {
      __typename: "ModelColumnConnection";
      nextToken: string | null;
    } | null;
  } | null;
};

export type DeleteColumnMutation = {
  __typename: "Column";
  id: string;
  title: string;
  order: number | null;
  open: string | null;
  close: string | null;
  learnermap: {
    __typename: "LearnerMap";
    id: string;
    title: string;
    image: string | null;
    location: string | null;
    owners: string | null;
    ownerMember: {
      __typename: "Member";
      id: string;
      title: string | null;
      email: string | null;
      createdAt: string | null;
    } | null;
    trainingFirstDay: string | null;
    trainingLastDay: string | null;
    daysOfPrework: number | null;
    daysBeforeAssessmentDeadline: number | null;
    daysAfterTrainingBeforeTestOpens: number | null;
    daysAfterTrainingBeforeTestCloses: number | null;
    daysBeforePointDue: number | null;
    createdAt: string | null;
    maptype: {
      __typename: "MapType";
      id: string;
      title: string;
    } | null;
    columns: {
      __typename: "ModelColumnConnection";
      nextToken: string | null;
    } | null;
  } | null;
  cards: {
    __typename: "ModelCardConnection";
    items: Array<{
      __typename: "Card";
      id: string;
      title: string;
      active: boolean | null;
      type: string | null;
      lesson: string | null;
      medium: string | null;
      runtime: string | null;
      parent: string | null;
      link: string | null;
      bg: string | null;
      button: string | null;
      text: string | null;
      body: string | null;
      target: string | null;
      open: string | null;
      close: string | null;
      order: number | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  columntype: {
    __typename: "ColumnType";
    id: string;
    title: string;
    columns: {
      __typename: "ModelColumnConnection";
      nextToken: string | null;
    } | null;
  } | null;
};

export type CreateColumnTypeMutation = {
  __typename: "ColumnType";
  id: string;
  title: string;
  columns: {
    __typename: "ModelColumnConnection";
    items: Array<{
      __typename: "Column";
      id: string;
      title: string;
      order: number | null;
      open: string | null;
      close: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type UpdateColumnTypeMutation = {
  __typename: "ColumnType";
  id: string;
  title: string;
  columns: {
    __typename: "ModelColumnConnection";
    items: Array<{
      __typename: "Column";
      id: string;
      title: string;
      order: number | null;
      open: string | null;
      close: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type DeleteColumnTypeMutation = {
  __typename: "ColumnType";
  id: string;
  title: string;
  columns: {
    __typename: "ModelColumnConnection";
    items: Array<{
      __typename: "Column";
      id: string;
      title: string;
      order: number | null;
      open: string | null;
      close: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type CreateCardMutation = {
  __typename: "Card";
  id: string;
  title: string;
  active: boolean | null;
  type: string | null;
  lesson: string | null;
  medium: string | null;
  runtime: string | null;
  parent: string | null;
  link: string | null;
  bg: string | null;
  button: string | null;
  text: string | null;
  body: string | null;
  target: string | null;
  open: string | null;
  close: string | null;
  order: number | null;
  column: {
    __typename: "Column";
    id: string;
    title: string;
    order: number | null;
    open: string | null;
    close: string | null;
    learnermap: {
      __typename: "LearnerMap";
      id: string;
      title: string;
      image: string | null;
      location: string | null;
      owners: string | null;
      trainingFirstDay: string | null;
      trainingLastDay: string | null;
      daysOfPrework: number | null;
      daysBeforeAssessmentDeadline: number | null;
      daysAfterTrainingBeforeTestOpens: number | null;
      daysAfterTrainingBeforeTestCloses: number | null;
      daysBeforePointDue: number | null;
      createdAt: string | null;
    } | null;
    cards: {
      __typename: "ModelCardConnection";
      nextToken: string | null;
    } | null;
    columntype: {
      __typename: "ColumnType";
      id: string;
      title: string;
    } | null;
  } | null;
};

export type UpdateCardMutation = {
  __typename: "Card";
  id: string;
  title: string;
  active: boolean | null;
  type: string | null;
  lesson: string | null;
  medium: string | null;
  runtime: string | null;
  parent: string | null;
  link: string | null;
  bg: string | null;
  button: string | null;
  text: string | null;
  body: string | null;
  target: string | null;
  open: string | null;
  close: string | null;
  order: number | null;
  column: {
    __typename: "Column";
    id: string;
    title: string;
    order: number | null;
    open: string | null;
    close: string | null;
    learnermap: {
      __typename: "LearnerMap";
      id: string;
      title: string;
      image: string | null;
      location: string | null;
      owners: string | null;
      trainingFirstDay: string | null;
      trainingLastDay: string | null;
      daysOfPrework: number | null;
      daysBeforeAssessmentDeadline: number | null;
      daysAfterTrainingBeforeTestOpens: number | null;
      daysAfterTrainingBeforeTestCloses: number | null;
      daysBeforePointDue: number | null;
      createdAt: string | null;
    } | null;
    cards: {
      __typename: "ModelCardConnection";
      nextToken: string | null;
    } | null;
    columntype: {
      __typename: "ColumnType";
      id: string;
      title: string;
    } | null;
  } | null;
};

export type DeleteCardMutation = {
  __typename: "Card";
  id: string;
  title: string;
  active: boolean | null;
  type: string | null;
  lesson: string | null;
  medium: string | null;
  runtime: string | null;
  parent: string | null;
  link: string | null;
  bg: string | null;
  button: string | null;
  text: string | null;
  body: string | null;
  target: string | null;
  open: string | null;
  close: string | null;
  order: number | null;
  column: {
    __typename: "Column";
    id: string;
    title: string;
    order: number | null;
    open: string | null;
    close: string | null;
    learnermap: {
      __typename: "LearnerMap";
      id: string;
      title: string;
      image: string | null;
      location: string | null;
      owners: string | null;
      trainingFirstDay: string | null;
      trainingLastDay: string | null;
      daysOfPrework: number | null;
      daysBeforeAssessmentDeadline: number | null;
      daysAfterTrainingBeforeTestOpens: number | null;
      daysAfterTrainingBeforeTestCloses: number | null;
      daysBeforePointDue: number | null;
      createdAt: string | null;
    } | null;
    cards: {
      __typename: "ModelCardConnection";
      nextToken: string | null;
    } | null;
    columntype: {
      __typename: "ColumnType";
      id: string;
      title: string;
    } | null;
  } | null;
};

export type CreateMemberMutation = {
  __typename: "Member";
  id: string;
  title: string | null;
  email: string | null;
  createdAt: string | null;
  learnermaps: {
    __typename: "ModelLearnerMapConnection";
    items: Array<{
      __typename: "LearnerMap";
      id: string;
      title: string;
      image: string | null;
      location: string | null;
      owners: string | null;
      trainingFirstDay: string | null;
      trainingLastDay: string | null;
      daysOfPrework: number | null;
      daysBeforeAssessmentDeadline: number | null;
      daysAfterTrainingBeforeTestOpens: number | null;
      daysAfterTrainingBeforeTestCloses: number | null;
      daysBeforePointDue: number | null;
      createdAt: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type UpdateMemberMutation = {
  __typename: "Member";
  id: string;
  title: string | null;
  email: string | null;
  createdAt: string | null;
  learnermaps: {
    __typename: "ModelLearnerMapConnection";
    items: Array<{
      __typename: "LearnerMap";
      id: string;
      title: string;
      image: string | null;
      location: string | null;
      owners: string | null;
      trainingFirstDay: string | null;
      trainingLastDay: string | null;
      daysOfPrework: number | null;
      daysBeforeAssessmentDeadline: number | null;
      daysAfterTrainingBeforeTestOpens: number | null;
      daysAfterTrainingBeforeTestCloses: number | null;
      daysBeforePointDue: number | null;
      createdAt: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type DeleteMemberMutation = {
  __typename: "Member";
  id: string;
  title: string | null;
  email: string | null;
  createdAt: string | null;
  learnermaps: {
    __typename: "ModelLearnerMapConnection";
    items: Array<{
      __typename: "LearnerMap";
      id: string;
      title: string;
      image: string | null;
      location: string | null;
      owners: string | null;
      trainingFirstDay: string | null;
      trainingLastDay: string | null;
      daysOfPrework: number | null;
      daysBeforeAssessmentDeadline: number | null;
      daysAfterTrainingBeforeTestOpens: number | null;
      daysAfterTrainingBeforeTestCloses: number | null;
      daysBeforePointDue: number | null;
      createdAt: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type CreateConversionMutation = {
  __typename: "Conversion";
  id: string;
  email: string | null;
  createdAt: string | null;
  cardID: string | null;
  learnermapID: string | null;
};

export type UpdateConversionMutation = {
  __typename: "Conversion";
  id: string;
  email: string | null;
  createdAt: string | null;
  cardID: string | null;
  learnermapID: string | null;
};

export type DeleteConversionMutation = {
  __typename: "Conversion";
  id: string;
  email: string | null;
  createdAt: string | null;
  cardID: string | null;
  learnermapID: string | null;
};

export type CreateMarkedMutation = {
  __typename: "Marked";
  id: string;
  marked: boolean;
  email: string | null;
  createdAt: string | null;
  cardID: string | null;
};

export type UpdateMarkedMutation = {
  __typename: "Marked";
  id: string;
  marked: boolean;
  email: string | null;
  createdAt: string | null;
  cardID: string | null;
};

export type DeleteMarkedMutation = {
  __typename: "Marked";
  id: string;
  marked: boolean;
  email: string | null;
  createdAt: string | null;
  cardID: string | null;
};

export type CreateFrameworkMutation = {
  __typename: "Framework";
  id: string;
  title: string | null;
  type: string | null;
  email: string | null;
  content: string | null;
  groupId: string | null;
  processTypeId: string | null;
  toolId: string | null;
  order: number | null;
  minutes: number | null;
  attributes: string | null;
  users: string | null;
};

export type UpdateFrameworkMutation = {
  __typename: "Framework";
  id: string;
  title: string | null;
  type: string | null;
  email: string | null;
  content: string | null;
  groupId: string | null;
  processTypeId: string | null;
  toolId: string | null;
  order: number | null;
  minutes: number | null;
  attributes: string | null;
  users: string | null;
};

export type DeleteFrameworkMutation = {
  __typename: "Framework";
  id: string;
  title: string | null;
  type: string | null;
  email: string | null;
  content: string | null;
  groupId: string | null;
  processTypeId: string | null;
  toolId: string | null;
  order: number | null;
  minutes: number | null;
  attributes: string | null;
  users: string | null;
};

export type GetLearnerMapQuery = {
  __typename: "LearnerMap";
  id: string;
  title: string;
  image: string | null;
  location: string | null;
  owners: string | null;
  ownerMember: {
    __typename: "Member";
    id: string;
    title: string | null;
    email: string | null;
    createdAt: string | null;
    learnermaps: {
      __typename: "ModelLearnerMapConnection";
      nextToken: string | null;
    } | null;
  } | null;
  trainingFirstDay: string | null;
  trainingLastDay: string | null;
  daysOfPrework: number | null;
  daysBeforeAssessmentDeadline: number | null;
  daysAfterTrainingBeforeTestOpens: number | null;
  daysAfterTrainingBeforeTestCloses: number | null;
  daysBeforePointDue: number | null;
  createdAt: string | null;
  maptype: {
    __typename: "MapType";
    id: string;
    title: string;
    learnermaps: {
      __typename: "ModelLearnerMapConnection";
      nextToken: string | null;
    } | null;
  } | null;
  columns: {
    __typename: "ModelColumnConnection";
    items: Array<{
      __typename: "Column";
      id: string;
      title: string;
      order: number | null;
      open: string | null;
      close: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type ListLearnerMapsQuery = {
  __typename: "ModelLearnerMapConnection";
  items: Array<{
    __typename: "LearnerMap";
    id: string;
    title: string;
    image: string | null;
    location: string | null;
    owners: string | null;
    ownerMember: {
      __typename: "Member";
      id: string;
      title: string | null;
      email: string | null;
      createdAt: string | null;
    } | null;
    trainingFirstDay: string | null;
    trainingLastDay: string | null;
    daysOfPrework: number | null;
    daysBeforeAssessmentDeadline: number | null;
    daysAfterTrainingBeforeTestOpens: number | null;
    daysAfterTrainingBeforeTestCloses: number | null;
    daysBeforePointDue: number | null;
    createdAt: string | null;
    maptype: {
      __typename: "MapType";
      id: string;
      title: string;
    } | null;
    columns: {
      __typename: "ModelColumnConnection";
      nextToken: string | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetMapTypeQuery = {
  __typename: "MapType";
  id: string;
  title: string;
  learnermaps: {
    __typename: "ModelLearnerMapConnection";
    items: Array<{
      __typename: "LearnerMap";
      id: string;
      title: string;
      image: string | null;
      location: string | null;
      owners: string | null;
      trainingFirstDay: string | null;
      trainingLastDay: string | null;
      daysOfPrework: number | null;
      daysBeforeAssessmentDeadline: number | null;
      daysAfterTrainingBeforeTestOpens: number | null;
      daysAfterTrainingBeforeTestCloses: number | null;
      daysBeforePointDue: number | null;
      createdAt: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type ListMapTypesQuery = {
  __typename: "ModelMapTypeConnection";
  items: Array<{
    __typename: "MapType";
    id: string;
    title: string;
    learnermaps: {
      __typename: "ModelLearnerMapConnection";
      nextToken: string | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetColumnQuery = {
  __typename: "Column";
  id: string;
  title: string;
  order: number | null;
  open: string | null;
  close: string | null;
  learnermap: {
    __typename: "LearnerMap";
    id: string;
    title: string;
    image: string | null;
    location: string | null;
    owners: string | null;
    ownerMember: {
      __typename: "Member";
      id: string;
      title: string | null;
      email: string | null;
      createdAt: string | null;
    } | null;
    trainingFirstDay: string | null;
    trainingLastDay: string | null;
    daysOfPrework: number | null;
    daysBeforeAssessmentDeadline: number | null;
    daysAfterTrainingBeforeTestOpens: number | null;
    daysAfterTrainingBeforeTestCloses: number | null;
    daysBeforePointDue: number | null;
    createdAt: string | null;
    maptype: {
      __typename: "MapType";
      id: string;
      title: string;
    } | null;
    columns: {
      __typename: "ModelColumnConnection";
      nextToken: string | null;
    } | null;
  } | null;
  cards: {
    __typename: "ModelCardConnection";
    items: Array<{
      __typename: "Card";
      id: string;
      title: string;
      active: boolean | null;
      type: string | null;
      lesson: string | null;
      medium: string | null;
      runtime: string | null;
      parent: string | null;
      link: string | null;
      bg: string | null;
      button: string | null;
      text: string | null;
      body: string | null;
      target: string | null;
      open: string | null;
      close: string | null;
      order: number | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  columntype: {
    __typename: "ColumnType";
    id: string;
    title: string;
    columns: {
      __typename: "ModelColumnConnection";
      nextToken: string | null;
    } | null;
  } | null;
};

export type ListColumnsQuery = {
  __typename: "ModelColumnConnection";
  items: Array<{
    __typename: "Column";
    id: string;
    title: string;
    order: number | null;
    open: string | null;
    close: string | null;
    learnermap: {
      __typename: "LearnerMap";
      id: string;
      title: string;
      image: string | null;
      location: string | null;
      owners: string | null;
      trainingFirstDay: string | null;
      trainingLastDay: string | null;
      daysOfPrework: number | null;
      daysBeforeAssessmentDeadline: number | null;
      daysAfterTrainingBeforeTestOpens: number | null;
      daysAfterTrainingBeforeTestCloses: number | null;
      daysBeforePointDue: number | null;
      createdAt: string | null;
    } | null;
    cards: {
      __typename: "ModelCardConnection";
      nextToken: string | null;
    } | null;
    columntype: {
      __typename: "ColumnType";
      id: string;
      title: string;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetColumnTypeQuery = {
  __typename: "ColumnType";
  id: string;
  title: string;
  columns: {
    __typename: "ModelColumnConnection";
    items: Array<{
      __typename: "Column";
      id: string;
      title: string;
      order: number | null;
      open: string | null;
      close: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type ListColumnTypesQuery = {
  __typename: "ModelColumnTypeConnection";
  items: Array<{
    __typename: "ColumnType";
    id: string;
    title: string;
    columns: {
      __typename: "ModelColumnConnection";
      nextToken: string | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetCardQuery = {
  __typename: "Card";
  id: string;
  title: string;
  active: boolean | null;
  type: string | null;
  lesson: string | null;
  medium: string | null;
  runtime: string | null;
  parent: string | null;
  link: string | null;
  bg: string | null;
  button: string | null;
  text: string | null;
  body: string | null;
  target: string | null;
  open: string | null;
  close: string | null;
  order: number | null;
  column: {
    __typename: "Column";
    id: string;
    title: string;
    order: number | null;
    open: string | null;
    close: string | null;
    learnermap: {
      __typename: "LearnerMap";
      id: string;
      title: string;
      image: string | null;
      location: string | null;
      owners: string | null;
      trainingFirstDay: string | null;
      trainingLastDay: string | null;
      daysOfPrework: number | null;
      daysBeforeAssessmentDeadline: number | null;
      daysAfterTrainingBeforeTestOpens: number | null;
      daysAfterTrainingBeforeTestCloses: number | null;
      daysBeforePointDue: number | null;
      createdAt: string | null;
    } | null;
    cards: {
      __typename: "ModelCardConnection";
      nextToken: string | null;
    } | null;
    columntype: {
      __typename: "ColumnType";
      id: string;
      title: string;
    } | null;
  } | null;
};

export type ListCardsQuery = {
  __typename: "ModelCardConnection";
  items: Array<{
    __typename: "Card";
    id: string;
    title: string;
    active: boolean | null;
    type: string | null;
    lesson: string | null;
    medium: string | null;
    runtime: string | null;
    parent: string | null;
    link: string | null;
    bg: string | null;
    button: string | null;
    text: string | null;
    body: string | null;
    target: string | null;
    open: string | null;
    close: string | null;
    order: number | null;
    column: {
      __typename: "Column";
      id: string;
      title: string;
      order: number | null;
      open: string | null;
      close: string | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetMemberQuery = {
  __typename: "Member";
  id: string;
  title: string | null;
  email: string | null;
  createdAt: string | null;
  learnermaps: {
    __typename: "ModelLearnerMapConnection";
    items: Array<{
      __typename: "LearnerMap";
      id: string;
      title: string;
      image: string | null;
      location: string | null;
      owners: string | null;
      trainingFirstDay: string | null;
      trainingLastDay: string | null;
      daysOfPrework: number | null;
      daysBeforeAssessmentDeadline: number | null;
      daysAfterTrainingBeforeTestOpens: number | null;
      daysAfterTrainingBeforeTestCloses: number | null;
      daysBeforePointDue: number | null;
      createdAt: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type ListMembersQuery = {
  __typename: "ModelMemberConnection";
  items: Array<{
    __typename: "Member";
    id: string;
    title: string | null;
    email: string | null;
    createdAt: string | null;
    learnermaps: {
      __typename: "ModelLearnerMapConnection";
      nextToken: string | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetConversionQuery = {
  __typename: "Conversion";
  id: string;
  email: string | null;
  createdAt: string | null;
  cardID: string | null;
  learnermapID: string | null;
};

export type ListConversionsQuery = {
  __typename: "ModelConversionConnection";
  items: Array<{
    __typename: "Conversion";
    id: string;
    email: string | null;
    createdAt: string | null;
    cardID: string | null;
    learnermapID: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetMarkedQuery = {
  __typename: "Marked";
  id: string;
  marked: boolean;
  email: string | null;
  createdAt: string | null;
  cardID: string | null;
};

export type ListMarkedsQuery = {
  __typename: "ModelMarkedConnection";
  items: Array<{
    __typename: "Marked";
    id: string;
    marked: boolean;
    email: string | null;
    createdAt: string | null;
    cardID: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetFrameworkQuery = {
  __typename: "Framework";
  id: string;
  title: string | null;
  type: string | null;
  email: string | null;
  content: string | null;
  groupId: string | null;
  processTypeId: string | null;
  toolId: string | null;
  order: number | null;
  minutes: number | null;
  attributes: string | null;
  users: string | null;
};

export type ListFrameworksQuery = {
  __typename: "ModelFrameworkConnection";
  items: Array<{
    __typename: "Framework";
    id: string;
    title: string | null;
    type: string | null;
    email: string | null;
    content: string | null;
    groupId: string | null;
    processTypeId: string | null;
    toolId: string | null;
    order: number | null;
    minutes: number | null;
    attributes: string | null;
    users: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type OnCreateLearnerMapSubscription = {
  __typename: "LearnerMap";
  id: string;
  title: string;
  image: string | null;
  location: string | null;
  owners: string | null;
  ownerMember: {
    __typename: "Member";
    id: string;
    title: string | null;
    email: string | null;
    createdAt: string | null;
    learnermaps: {
      __typename: "ModelLearnerMapConnection";
      nextToken: string | null;
    } | null;
  } | null;
  trainingFirstDay: string | null;
  trainingLastDay: string | null;
  daysOfPrework: number | null;
  daysBeforeAssessmentDeadline: number | null;
  daysAfterTrainingBeforeTestOpens: number | null;
  daysAfterTrainingBeforeTestCloses: number | null;
  daysBeforePointDue: number | null;
  createdAt: string | null;
  maptype: {
    __typename: "MapType";
    id: string;
    title: string;
    learnermaps: {
      __typename: "ModelLearnerMapConnection";
      nextToken: string | null;
    } | null;
  } | null;
  columns: {
    __typename: "ModelColumnConnection";
    items: Array<{
      __typename: "Column";
      id: string;
      title: string;
      order: number | null;
      open: string | null;
      close: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type OnUpdateLearnerMapSubscription = {
  __typename: "LearnerMap";
  id: string;
  title: string;
  image: string | null;
  location: string | null;
  owners: string | null;
  ownerMember: {
    __typename: "Member";
    id: string;
    title: string | null;
    email: string | null;
    createdAt: string | null;
    learnermaps: {
      __typename: "ModelLearnerMapConnection";
      nextToken: string | null;
    } | null;
  } | null;
  trainingFirstDay: string | null;
  trainingLastDay: string | null;
  daysOfPrework: number | null;
  daysBeforeAssessmentDeadline: number | null;
  daysAfterTrainingBeforeTestOpens: number | null;
  daysAfterTrainingBeforeTestCloses: number | null;
  daysBeforePointDue: number | null;
  createdAt: string | null;
  maptype: {
    __typename: "MapType";
    id: string;
    title: string;
    learnermaps: {
      __typename: "ModelLearnerMapConnection";
      nextToken: string | null;
    } | null;
  } | null;
  columns: {
    __typename: "ModelColumnConnection";
    items: Array<{
      __typename: "Column";
      id: string;
      title: string;
      order: number | null;
      open: string | null;
      close: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type OnDeleteLearnerMapSubscription = {
  __typename: "LearnerMap";
  id: string;
  title: string;
  image: string | null;
  location: string | null;
  owners: string | null;
  ownerMember: {
    __typename: "Member";
    id: string;
    title: string | null;
    email: string | null;
    createdAt: string | null;
    learnermaps: {
      __typename: "ModelLearnerMapConnection";
      nextToken: string | null;
    } | null;
  } | null;
  trainingFirstDay: string | null;
  trainingLastDay: string | null;
  daysOfPrework: number | null;
  daysBeforeAssessmentDeadline: number | null;
  daysAfterTrainingBeforeTestOpens: number | null;
  daysAfterTrainingBeforeTestCloses: number | null;
  daysBeforePointDue: number | null;
  createdAt: string | null;
  maptype: {
    __typename: "MapType";
    id: string;
    title: string;
    learnermaps: {
      __typename: "ModelLearnerMapConnection";
      nextToken: string | null;
    } | null;
  } | null;
  columns: {
    __typename: "ModelColumnConnection";
    items: Array<{
      __typename: "Column";
      id: string;
      title: string;
      order: number | null;
      open: string | null;
      close: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type OnCreateMapTypeSubscription = {
  __typename: "MapType";
  id: string;
  title: string;
  learnermaps: {
    __typename: "ModelLearnerMapConnection";
    items: Array<{
      __typename: "LearnerMap";
      id: string;
      title: string;
      image: string | null;
      location: string | null;
      owners: string | null;
      trainingFirstDay: string | null;
      trainingLastDay: string | null;
      daysOfPrework: number | null;
      daysBeforeAssessmentDeadline: number | null;
      daysAfterTrainingBeforeTestOpens: number | null;
      daysAfterTrainingBeforeTestCloses: number | null;
      daysBeforePointDue: number | null;
      createdAt: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type OnUpdateMapTypeSubscription = {
  __typename: "MapType";
  id: string;
  title: string;
  learnermaps: {
    __typename: "ModelLearnerMapConnection";
    items: Array<{
      __typename: "LearnerMap";
      id: string;
      title: string;
      image: string | null;
      location: string | null;
      owners: string | null;
      trainingFirstDay: string | null;
      trainingLastDay: string | null;
      daysOfPrework: number | null;
      daysBeforeAssessmentDeadline: number | null;
      daysAfterTrainingBeforeTestOpens: number | null;
      daysAfterTrainingBeforeTestCloses: number | null;
      daysBeforePointDue: number | null;
      createdAt: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type OnDeleteMapTypeSubscription = {
  __typename: "MapType";
  id: string;
  title: string;
  learnermaps: {
    __typename: "ModelLearnerMapConnection";
    items: Array<{
      __typename: "LearnerMap";
      id: string;
      title: string;
      image: string | null;
      location: string | null;
      owners: string | null;
      trainingFirstDay: string | null;
      trainingLastDay: string | null;
      daysOfPrework: number | null;
      daysBeforeAssessmentDeadline: number | null;
      daysAfterTrainingBeforeTestOpens: number | null;
      daysAfterTrainingBeforeTestCloses: number | null;
      daysBeforePointDue: number | null;
      createdAt: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type OnCreateColumnSubscription = {
  __typename: "Column";
  id: string;
  title: string;
  order: number | null;
  open: string | null;
  close: string | null;
  learnermap: {
    __typename: "LearnerMap";
    id: string;
    title: string;
    image: string | null;
    location: string | null;
    owners: string | null;
    ownerMember: {
      __typename: "Member";
      id: string;
      title: string | null;
      email: string | null;
      createdAt: string | null;
    } | null;
    trainingFirstDay: string | null;
    trainingLastDay: string | null;
    daysOfPrework: number | null;
    daysBeforeAssessmentDeadline: number | null;
    daysAfterTrainingBeforeTestOpens: number | null;
    daysAfterTrainingBeforeTestCloses: number | null;
    daysBeforePointDue: number | null;
    createdAt: string | null;
    maptype: {
      __typename: "MapType";
      id: string;
      title: string;
    } | null;
    columns: {
      __typename: "ModelColumnConnection";
      nextToken: string | null;
    } | null;
  } | null;
  cards: {
    __typename: "ModelCardConnection";
    items: Array<{
      __typename: "Card";
      id: string;
      title: string;
      active: boolean | null;
      type: string | null;
      lesson: string | null;
      medium: string | null;
      runtime: string | null;
      parent: string | null;
      link: string | null;
      bg: string | null;
      button: string | null;
      text: string | null;
      body: string | null;
      target: string | null;
      open: string | null;
      close: string | null;
      order: number | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  columntype: {
    __typename: "ColumnType";
    id: string;
    title: string;
    columns: {
      __typename: "ModelColumnConnection";
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnUpdateColumnSubscription = {
  __typename: "Column";
  id: string;
  title: string;
  order: number | null;
  open: string | null;
  close: string | null;
  learnermap: {
    __typename: "LearnerMap";
    id: string;
    title: string;
    image: string | null;
    location: string | null;
    owners: string | null;
    ownerMember: {
      __typename: "Member";
      id: string;
      title: string | null;
      email: string | null;
      createdAt: string | null;
    } | null;
    trainingFirstDay: string | null;
    trainingLastDay: string | null;
    daysOfPrework: number | null;
    daysBeforeAssessmentDeadline: number | null;
    daysAfterTrainingBeforeTestOpens: number | null;
    daysAfterTrainingBeforeTestCloses: number | null;
    daysBeforePointDue: number | null;
    createdAt: string | null;
    maptype: {
      __typename: "MapType";
      id: string;
      title: string;
    } | null;
    columns: {
      __typename: "ModelColumnConnection";
      nextToken: string | null;
    } | null;
  } | null;
  cards: {
    __typename: "ModelCardConnection";
    items: Array<{
      __typename: "Card";
      id: string;
      title: string;
      active: boolean | null;
      type: string | null;
      lesson: string | null;
      medium: string | null;
      runtime: string | null;
      parent: string | null;
      link: string | null;
      bg: string | null;
      button: string | null;
      text: string | null;
      body: string | null;
      target: string | null;
      open: string | null;
      close: string | null;
      order: number | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  columntype: {
    __typename: "ColumnType";
    id: string;
    title: string;
    columns: {
      __typename: "ModelColumnConnection";
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnDeleteColumnSubscription = {
  __typename: "Column";
  id: string;
  title: string;
  order: number | null;
  open: string | null;
  close: string | null;
  learnermap: {
    __typename: "LearnerMap";
    id: string;
    title: string;
    image: string | null;
    location: string | null;
    owners: string | null;
    ownerMember: {
      __typename: "Member";
      id: string;
      title: string | null;
      email: string | null;
      createdAt: string | null;
    } | null;
    trainingFirstDay: string | null;
    trainingLastDay: string | null;
    daysOfPrework: number | null;
    daysBeforeAssessmentDeadline: number | null;
    daysAfterTrainingBeforeTestOpens: number | null;
    daysAfterTrainingBeforeTestCloses: number | null;
    daysBeforePointDue: number | null;
    createdAt: string | null;
    maptype: {
      __typename: "MapType";
      id: string;
      title: string;
    } | null;
    columns: {
      __typename: "ModelColumnConnection";
      nextToken: string | null;
    } | null;
  } | null;
  cards: {
    __typename: "ModelCardConnection";
    items: Array<{
      __typename: "Card";
      id: string;
      title: string;
      active: boolean | null;
      type: string | null;
      lesson: string | null;
      medium: string | null;
      runtime: string | null;
      parent: string | null;
      link: string | null;
      bg: string | null;
      button: string | null;
      text: string | null;
      body: string | null;
      target: string | null;
      open: string | null;
      close: string | null;
      order: number | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  columntype: {
    __typename: "ColumnType";
    id: string;
    title: string;
    columns: {
      __typename: "ModelColumnConnection";
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnCreateColumnTypeSubscription = {
  __typename: "ColumnType";
  id: string;
  title: string;
  columns: {
    __typename: "ModelColumnConnection";
    items: Array<{
      __typename: "Column";
      id: string;
      title: string;
      order: number | null;
      open: string | null;
      close: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type OnUpdateColumnTypeSubscription = {
  __typename: "ColumnType";
  id: string;
  title: string;
  columns: {
    __typename: "ModelColumnConnection";
    items: Array<{
      __typename: "Column";
      id: string;
      title: string;
      order: number | null;
      open: string | null;
      close: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type OnDeleteColumnTypeSubscription = {
  __typename: "ColumnType";
  id: string;
  title: string;
  columns: {
    __typename: "ModelColumnConnection";
    items: Array<{
      __typename: "Column";
      id: string;
      title: string;
      order: number | null;
      open: string | null;
      close: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type OnCreateCardSubscription = {
  __typename: "Card";
  id: string;
  title: string;
  active: boolean | null;
  type: string | null;
  lesson: string | null;
  medium: string | null;
  runtime: string | null;
  parent: string | null;
  link: string | null;
  bg: string | null;
  button: string | null;
  text: string | null;
  body: string | null;
  target: string | null;
  open: string | null;
  close: string | null;
  order: number | null;
  column: {
    __typename: "Column";
    id: string;
    title: string;
    order: number | null;
    open: string | null;
    close: string | null;
    learnermap: {
      __typename: "LearnerMap";
      id: string;
      title: string;
      image: string | null;
      location: string | null;
      owners: string | null;
      trainingFirstDay: string | null;
      trainingLastDay: string | null;
      daysOfPrework: number | null;
      daysBeforeAssessmentDeadline: number | null;
      daysAfterTrainingBeforeTestOpens: number | null;
      daysAfterTrainingBeforeTestCloses: number | null;
      daysBeforePointDue: number | null;
      createdAt: string | null;
    } | null;
    cards: {
      __typename: "ModelCardConnection";
      nextToken: string | null;
    } | null;
    columntype: {
      __typename: "ColumnType";
      id: string;
      title: string;
    } | null;
  } | null;
};

export type OnUpdateCardSubscription = {
  __typename: "Card";
  id: string;
  title: string;
  active: boolean | null;
  type: string | null;
  lesson: string | null;
  medium: string | null;
  runtime: string | null;
  parent: string | null;
  link: string | null;
  bg: string | null;
  button: string | null;
  text: string | null;
  body: string | null;
  target: string | null;
  open: string | null;
  close: string | null;
  order: number | null;
  column: {
    __typename: "Column";
    id: string;
    title: string;
    order: number | null;
    open: string | null;
    close: string | null;
    learnermap: {
      __typename: "LearnerMap";
      id: string;
      title: string;
      image: string | null;
      location: string | null;
      owners: string | null;
      trainingFirstDay: string | null;
      trainingLastDay: string | null;
      daysOfPrework: number | null;
      daysBeforeAssessmentDeadline: number | null;
      daysAfterTrainingBeforeTestOpens: number | null;
      daysAfterTrainingBeforeTestCloses: number | null;
      daysBeforePointDue: number | null;
      createdAt: string | null;
    } | null;
    cards: {
      __typename: "ModelCardConnection";
      nextToken: string | null;
    } | null;
    columntype: {
      __typename: "ColumnType";
      id: string;
      title: string;
    } | null;
  } | null;
};

export type OnDeleteCardSubscription = {
  __typename: "Card";
  id: string;
  title: string;
  active: boolean | null;
  type: string | null;
  lesson: string | null;
  medium: string | null;
  runtime: string | null;
  parent: string | null;
  link: string | null;
  bg: string | null;
  button: string | null;
  text: string | null;
  body: string | null;
  target: string | null;
  open: string | null;
  close: string | null;
  order: number | null;
  column: {
    __typename: "Column";
    id: string;
    title: string;
    order: number | null;
    open: string | null;
    close: string | null;
    learnermap: {
      __typename: "LearnerMap";
      id: string;
      title: string;
      image: string | null;
      location: string | null;
      owners: string | null;
      trainingFirstDay: string | null;
      trainingLastDay: string | null;
      daysOfPrework: number | null;
      daysBeforeAssessmentDeadline: number | null;
      daysAfterTrainingBeforeTestOpens: number | null;
      daysAfterTrainingBeforeTestCloses: number | null;
      daysBeforePointDue: number | null;
      createdAt: string | null;
    } | null;
    cards: {
      __typename: "ModelCardConnection";
      nextToken: string | null;
    } | null;
    columntype: {
      __typename: "ColumnType";
      id: string;
      title: string;
    } | null;
  } | null;
};

export type OnCreateMemberSubscription = {
  __typename: "Member";
  id: string;
  title: string | null;
  email: string | null;
  createdAt: string | null;
  learnermaps: {
    __typename: "ModelLearnerMapConnection";
    items: Array<{
      __typename: "LearnerMap";
      id: string;
      title: string;
      image: string | null;
      location: string | null;
      owners: string | null;
      trainingFirstDay: string | null;
      trainingLastDay: string | null;
      daysOfPrework: number | null;
      daysBeforeAssessmentDeadline: number | null;
      daysAfterTrainingBeforeTestOpens: number | null;
      daysAfterTrainingBeforeTestCloses: number | null;
      daysBeforePointDue: number | null;
      createdAt: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type OnUpdateMemberSubscription = {
  __typename: "Member";
  id: string;
  title: string | null;
  email: string | null;
  createdAt: string | null;
  learnermaps: {
    __typename: "ModelLearnerMapConnection";
    items: Array<{
      __typename: "LearnerMap";
      id: string;
      title: string;
      image: string | null;
      location: string | null;
      owners: string | null;
      trainingFirstDay: string | null;
      trainingLastDay: string | null;
      daysOfPrework: number | null;
      daysBeforeAssessmentDeadline: number | null;
      daysAfterTrainingBeforeTestOpens: number | null;
      daysAfterTrainingBeforeTestCloses: number | null;
      daysBeforePointDue: number | null;
      createdAt: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type OnDeleteMemberSubscription = {
  __typename: "Member";
  id: string;
  title: string | null;
  email: string | null;
  createdAt: string | null;
  learnermaps: {
    __typename: "ModelLearnerMapConnection";
    items: Array<{
      __typename: "LearnerMap";
      id: string;
      title: string;
      image: string | null;
      location: string | null;
      owners: string | null;
      trainingFirstDay: string | null;
      trainingLastDay: string | null;
      daysOfPrework: number | null;
      daysBeforeAssessmentDeadline: number | null;
      daysAfterTrainingBeforeTestOpens: number | null;
      daysAfterTrainingBeforeTestCloses: number | null;
      daysBeforePointDue: number | null;
      createdAt: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type OnCreateConversionSubscription = {
  __typename: "Conversion";
  id: string;
  email: string | null;
  createdAt: string | null;
  cardID: string | null;
  learnermapID: string | null;
};

export type OnUpdateConversionSubscription = {
  __typename: "Conversion";
  id: string;
  email: string | null;
  createdAt: string | null;
  cardID: string | null;
  learnermapID: string | null;
};

export type OnDeleteConversionSubscription = {
  __typename: "Conversion";
  id: string;
  email: string | null;
  createdAt: string | null;
  cardID: string | null;
  learnermapID: string | null;
};

export type OnCreateMarkedSubscription = {
  __typename: "Marked";
  id: string;
  marked: boolean;
  email: string | null;
  createdAt: string | null;
  cardID: string | null;
};

export type OnUpdateMarkedSubscription = {
  __typename: "Marked";
  id: string;
  marked: boolean;
  email: string | null;
  createdAt: string | null;
  cardID: string | null;
};

export type OnDeleteMarkedSubscription = {
  __typename: "Marked";
  id: string;
  marked: boolean;
  email: string | null;
  createdAt: string | null;
  cardID: string | null;
};

export type OnCreateFrameworkSubscription = {
  __typename: "Framework";
  id: string;
  title: string | null;
  type: string | null;
  email: string | null;
  content: string | null;
  groupId: string | null;
  processTypeId: string | null;
  toolId: string | null;
  order: number | null;
  minutes: number | null;
  attributes: string | null;
  users: string | null;
};

export type OnUpdateFrameworkSubscription = {
  __typename: "Framework";
  id: string;
  title: string | null;
  type: string | null;
  email: string | null;
  content: string | null;
  groupId: string | null;
  processTypeId: string | null;
  toolId: string | null;
  order: number | null;
  minutes: number | null;
  attributes: string | null;
  users: string | null;
};

export type OnDeleteFrameworkSubscription = {
  __typename: "Framework";
  id: string;
  title: string | null;
  type: string | null;
  email: string | null;
  content: string | null;
  groupId: string | null;
  processTypeId: string | null;
  toolId: string | null;
  order: number | null;
  minutes: number | null;
  attributes: string | null;
  users: string | null;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateLearnerMap(
    input: CreateLearnerMapInput
  ): Promise<CreateLearnerMapMutation> {
    const statement = `mutation CreateLearnerMap($input: CreateLearnerMapInput!) {
        createLearnerMap(input: $input) {
          __typename
          id
          title
          image
          location
          owners
          ownerMember {
            __typename
            id
            title
            email
            createdAt
            learnermaps {
              __typename
              nextToken
            }
          }
          trainingFirstDay
          trainingLastDay
          daysOfPrework
          daysBeforeAssessmentDeadline
          daysAfterTrainingBeforeTestOpens
          daysAfterTrainingBeforeTestCloses
          daysBeforePointDue
          createdAt
          maptype {
            __typename
            id
            title
            learnermaps {
              __typename
              nextToken
            }
          }
          columns {
            __typename
            items {
              __typename
              id
              title
              order
              open
              close
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLearnerMapMutation>response.data.createLearnerMap;
  }
  async UpdateLearnerMap(
    input: UpdateLearnerMapInput
  ): Promise<UpdateLearnerMapMutation> {
    const statement = `mutation UpdateLearnerMap($input: UpdateLearnerMapInput!) {
        updateLearnerMap(input: $input) {
          __typename
          id
          title
          image
          location
          owners
          ownerMember {
            __typename
            id
            title
            email
            createdAt
            learnermaps {
              __typename
              nextToken
            }
          }
          trainingFirstDay
          trainingLastDay
          daysOfPrework
          daysBeforeAssessmentDeadline
          daysAfterTrainingBeforeTestOpens
          daysAfterTrainingBeforeTestCloses
          daysBeforePointDue
          createdAt
          maptype {
            __typename
            id
            title
            learnermaps {
              __typename
              nextToken
            }
          }
          columns {
            __typename
            items {
              __typename
              id
              title
              order
              open
              close
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLearnerMapMutation>response.data.updateLearnerMap;
  }
  async DeleteLearnerMap(
    input: DeleteLearnerMapInput
  ): Promise<DeleteLearnerMapMutation> {
    const statement = `mutation DeleteLearnerMap($input: DeleteLearnerMapInput!) {
        deleteLearnerMap(input: $input) {
          __typename
          id
          title
          image
          location
          owners
          ownerMember {
            __typename
            id
            title
            email
            createdAt
            learnermaps {
              __typename
              nextToken
            }
          }
          trainingFirstDay
          trainingLastDay
          daysOfPrework
          daysBeforeAssessmentDeadline
          daysAfterTrainingBeforeTestOpens
          daysAfterTrainingBeforeTestCloses
          daysBeforePointDue
          createdAt
          maptype {
            __typename
            id
            title
            learnermaps {
              __typename
              nextToken
            }
          }
          columns {
            __typename
            items {
              __typename
              id
              title
              order
              open
              close
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLearnerMapMutation>response.data.deleteLearnerMap;
  }
  async CreateMapType(
    input: CreateMapTypeInput
  ): Promise<CreateMapTypeMutation> {
    const statement = `mutation CreateMapType($input: CreateMapTypeInput!) {
        createMapType(input: $input) {
          __typename
          id
          title
          learnermaps {
            __typename
            items {
              __typename
              id
              title
              image
              location
              owners
              trainingFirstDay
              trainingLastDay
              daysOfPrework
              daysBeforeAssessmentDeadline
              daysAfterTrainingBeforeTestOpens
              daysAfterTrainingBeforeTestCloses
              daysBeforePointDue
              createdAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMapTypeMutation>response.data.createMapType;
  }
  async UpdateMapType(
    input: UpdateMapTypeInput
  ): Promise<UpdateMapTypeMutation> {
    const statement = `mutation UpdateMapType($input: UpdateMapTypeInput!) {
        updateMapType(input: $input) {
          __typename
          id
          title
          learnermaps {
            __typename
            items {
              __typename
              id
              title
              image
              location
              owners
              trainingFirstDay
              trainingLastDay
              daysOfPrework
              daysBeforeAssessmentDeadline
              daysAfterTrainingBeforeTestOpens
              daysAfterTrainingBeforeTestCloses
              daysBeforePointDue
              createdAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMapTypeMutation>response.data.updateMapType;
  }
  async DeleteMapType(
    input: DeleteMapTypeInput
  ): Promise<DeleteMapTypeMutation> {
    const statement = `mutation DeleteMapType($input: DeleteMapTypeInput!) {
        deleteMapType(input: $input) {
          __typename
          id
          title
          learnermaps {
            __typename
            items {
              __typename
              id
              title
              image
              location
              owners
              trainingFirstDay
              trainingLastDay
              daysOfPrework
              daysBeforeAssessmentDeadline
              daysAfterTrainingBeforeTestOpens
              daysAfterTrainingBeforeTestCloses
              daysBeforePointDue
              createdAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMapTypeMutation>response.data.deleteMapType;
  }
  async CreateColumn(input: CreateColumnInput): Promise<CreateColumnMutation> {
    const statement = `mutation CreateColumn($input: CreateColumnInput!) {
        createColumn(input: $input) {
          __typename
          id
          title
          order
          open
          close
          learnermap {
            __typename
            id
            title
            image
            location
            owners
            ownerMember {
              __typename
              id
              title
              email
              createdAt
            }
            trainingFirstDay
            trainingLastDay
            daysOfPrework
            daysBeforeAssessmentDeadline
            daysAfterTrainingBeforeTestOpens
            daysAfterTrainingBeforeTestCloses
            daysBeforePointDue
            createdAt
            maptype {
              __typename
              id
              title
            }
            columns {
              __typename
              nextToken
            }
          }
          cards {
            __typename
            items {
              __typename
              id
              title
              active
              type
              lesson
              medium
              runtime
              parent
              link
              bg
              button
              text
              body
              target
              open
              close
              order
            }
            nextToken
          }
          columntype {
            __typename
            id
            title
            columns {
              __typename
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateColumnMutation>response.data.createColumn;
  }
  async UpdateColumn(input: UpdateColumnInput): Promise<UpdateColumnMutation> {
    const statement = `mutation UpdateColumn($input: UpdateColumnInput!) {
        updateColumn(input: $input) {
          __typename
          id
          title
          order
          open
          close
          learnermap {
            __typename
            id
            title
            image
            location
            owners
            ownerMember {
              __typename
              id
              title
              email
              createdAt
            }
            trainingFirstDay
            trainingLastDay
            daysOfPrework
            daysBeforeAssessmentDeadline
            daysAfterTrainingBeforeTestOpens
            daysAfterTrainingBeforeTestCloses
            daysBeforePointDue
            createdAt
            maptype {
              __typename
              id
              title
            }
            columns {
              __typename
              nextToken
            }
          }
          cards {
            __typename
            items {
              __typename
              id
              title
              active
              type
              lesson
              medium
              runtime
              parent
              link
              bg
              button
              text
              body
              target
              open
              close
              order
            }
            nextToken
          }
          columntype {
            __typename
            id
            title
            columns {
              __typename
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateColumnMutation>response.data.updateColumn;
  }
  async DeleteColumn(input: DeleteColumnInput): Promise<DeleteColumnMutation> {
    const statement = `mutation DeleteColumn($input: DeleteColumnInput!) {
        deleteColumn(input: $input) {
          __typename
          id
          title
          order
          open
          close
          learnermap {
            __typename
            id
            title
            image
            location
            owners
            ownerMember {
              __typename
              id
              title
              email
              createdAt
            }
            trainingFirstDay
            trainingLastDay
            daysOfPrework
            daysBeforeAssessmentDeadline
            daysAfterTrainingBeforeTestOpens
            daysAfterTrainingBeforeTestCloses
            daysBeforePointDue
            createdAt
            maptype {
              __typename
              id
              title
            }
            columns {
              __typename
              nextToken
            }
          }
          cards {
            __typename
            items {
              __typename
              id
              title
              active
              type
              lesson
              medium
              runtime
              parent
              link
              bg
              button
              text
              body
              target
              open
              close
              order
            }
            nextToken
          }
          columntype {
            __typename
            id
            title
            columns {
              __typename
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteColumnMutation>response.data.deleteColumn;
  }
  async CreateColumnType(
    input: CreateColumnTypeInput
  ): Promise<CreateColumnTypeMutation> {
    const statement = `mutation CreateColumnType($input: CreateColumnTypeInput!) {
        createColumnType(input: $input) {
          __typename
          id
          title
          columns {
            __typename
            items {
              __typename
              id
              title
              order
              open
              close
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateColumnTypeMutation>response.data.createColumnType;
  }
  async UpdateColumnType(
    input: UpdateColumnTypeInput
  ): Promise<UpdateColumnTypeMutation> {
    const statement = `mutation UpdateColumnType($input: UpdateColumnTypeInput!) {
        updateColumnType(input: $input) {
          __typename
          id
          title
          columns {
            __typename
            items {
              __typename
              id
              title
              order
              open
              close
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateColumnTypeMutation>response.data.updateColumnType;
  }
  async DeleteColumnType(
    input: DeleteColumnTypeInput
  ): Promise<DeleteColumnTypeMutation> {
    const statement = `mutation DeleteColumnType($input: DeleteColumnTypeInput!) {
        deleteColumnType(input: $input) {
          __typename
          id
          title
          columns {
            __typename
            items {
              __typename
              id
              title
              order
              open
              close
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteColumnTypeMutation>response.data.deleteColumnType;
  }
  async CreateCard(input: CreateCardInput): Promise<CreateCardMutation> {
    const statement = `mutation CreateCard($input: CreateCardInput!) {
        createCard(input: $input) {
          __typename
          id
          title
          active
          type
          lesson
          medium
          runtime
          parent
          link
          bg
          button
          text
          body
          target
          open
          close
          order
          column {
            __typename
            id
            title
            order
            open
            close
            learnermap {
              __typename
              id
              title
              image
              location
              owners
              trainingFirstDay
              trainingLastDay
              daysOfPrework
              daysBeforeAssessmentDeadline
              daysAfterTrainingBeforeTestOpens
              daysAfterTrainingBeforeTestCloses
              daysBeforePointDue
              createdAt
            }
            cards {
              __typename
              nextToken
            }
            columntype {
              __typename
              id
              title
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCardMutation>response.data.createCard;
  }
  async UpdateCard(input: UpdateCardInput): Promise<UpdateCardMutation> {
    const statement = `mutation UpdateCard($input: UpdateCardInput!) {
        updateCard(input: $input) {
          __typename
          id
          title
          active
          type
          lesson
          medium
          runtime
          parent
          link
          bg
          button
          text
          body
          target
          open
          close
          order
          column {
            __typename
            id
            title
            order
            open
            close
            learnermap {
              __typename
              id
              title
              image
              location
              owners
              trainingFirstDay
              trainingLastDay
              daysOfPrework
              daysBeforeAssessmentDeadline
              daysAfterTrainingBeforeTestOpens
              daysAfterTrainingBeforeTestCloses
              daysBeforePointDue
              createdAt
            }
            cards {
              __typename
              nextToken
            }
            columntype {
              __typename
              id
              title
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCardMutation>response.data.updateCard;
  }
  async DeleteCard(input: DeleteCardInput): Promise<DeleteCardMutation> {
    const statement = `mutation DeleteCard($input: DeleteCardInput!) {
        deleteCard(input: $input) {
          __typename
          id
          title
          active
          type
          lesson
          medium
          runtime
          parent
          link
          bg
          button
          text
          body
          target
          open
          close
          order
          column {
            __typename
            id
            title
            order
            open
            close
            learnermap {
              __typename
              id
              title
              image
              location
              owners
              trainingFirstDay
              trainingLastDay
              daysOfPrework
              daysBeforeAssessmentDeadline
              daysAfterTrainingBeforeTestOpens
              daysAfterTrainingBeforeTestCloses
              daysBeforePointDue
              createdAt
            }
            cards {
              __typename
              nextToken
            }
            columntype {
              __typename
              id
              title
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCardMutation>response.data.deleteCard;
  }
  async CreateMember(input: CreateMemberInput): Promise<CreateMemberMutation> {
    const statement = `mutation CreateMember($input: CreateMemberInput!) {
        createMember(input: $input) {
          __typename
          id
          title
          email
          createdAt
          learnermaps {
            __typename
            items {
              __typename
              id
              title
              image
              location
              owners
              trainingFirstDay
              trainingLastDay
              daysOfPrework
              daysBeforeAssessmentDeadline
              daysAfterTrainingBeforeTestOpens
              daysAfterTrainingBeforeTestCloses
              daysBeforePointDue
              createdAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMemberMutation>response.data.createMember;
  }
  async UpdateMember(input: UpdateMemberInput): Promise<UpdateMemberMutation> {
    const statement = `mutation UpdateMember($input: UpdateMemberInput!) {
        updateMember(input: $input) {
          __typename
          id
          title
          email
          createdAt
          learnermaps {
            __typename
            items {
              __typename
              id
              title
              image
              location
              owners
              trainingFirstDay
              trainingLastDay
              daysOfPrework
              daysBeforeAssessmentDeadline
              daysAfterTrainingBeforeTestOpens
              daysAfterTrainingBeforeTestCloses
              daysBeforePointDue
              createdAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMemberMutation>response.data.updateMember;
  }
  async DeleteMember(input: DeleteMemberInput): Promise<DeleteMemberMutation> {
    const statement = `mutation DeleteMember($input: DeleteMemberInput!) {
        deleteMember(input: $input) {
          __typename
          id
          title
          email
          createdAt
          learnermaps {
            __typename
            items {
              __typename
              id
              title
              image
              location
              owners
              trainingFirstDay
              trainingLastDay
              daysOfPrework
              daysBeforeAssessmentDeadline
              daysAfterTrainingBeforeTestOpens
              daysAfterTrainingBeforeTestCloses
              daysBeforePointDue
              createdAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMemberMutation>response.data.deleteMember;
  }
  async CreateConversion(
    input: CreateConversionInput
  ): Promise<CreateConversionMutation> {
    const statement = `mutation CreateConversion($input: CreateConversionInput!) {
        createConversion(input: $input) {
          __typename
          id
          email
          createdAt
          cardID
          learnermapID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConversionMutation>response.data.createConversion;
  }
  async UpdateConversion(
    input: UpdateConversionInput
  ): Promise<UpdateConversionMutation> {
    const statement = `mutation UpdateConversion($input: UpdateConversionInput!) {
        updateConversion(input: $input) {
          __typename
          id
          email
          createdAt
          cardID
          learnermapID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateConversionMutation>response.data.updateConversion;
  }
  async DeleteConversion(
    input: DeleteConversionInput
  ): Promise<DeleteConversionMutation> {
    const statement = `mutation DeleteConversion($input: DeleteConversionInput!) {
        deleteConversion(input: $input) {
          __typename
          id
          email
          createdAt
          cardID
          learnermapID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteConversionMutation>response.data.deleteConversion;
  }
  async CreateMarked(input: CreateMarkedInput): Promise<CreateMarkedMutation> {
    const statement = `mutation CreateMarked($input: CreateMarkedInput!) {
        createMarked(input: $input) {
          __typename
          id
          marked
          email
          createdAt
          cardID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMarkedMutation>response.data.createMarked;
  }
  async UpdateMarked(input: UpdateMarkedInput): Promise<UpdateMarkedMutation> {
    const statement = `mutation UpdateMarked($input: UpdateMarkedInput!) {
        updateMarked(input: $input) {
          __typename
          id
          marked
          email
          createdAt
          cardID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMarkedMutation>response.data.updateMarked;
  }
  async DeleteMarked(input: DeleteMarkedInput): Promise<DeleteMarkedMutation> {
    const statement = `mutation DeleteMarked($input: DeleteMarkedInput!) {
        deleteMarked(input: $input) {
          __typename
          id
          marked
          email
          createdAt
          cardID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMarkedMutation>response.data.deleteMarked;
  }
  async CreateFramework(
    input: CreateFrameworkInput
  ): Promise<CreateFrameworkMutation> {
    const statement = `mutation CreateFramework($input: CreateFrameworkInput!) {
        createFramework(input: $input) {
          __typename
          id
          title
          type
          email
          content
          groupId
          processTypeId
          toolId
          order
          minutes
          attributes
          users
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateFrameworkMutation>response.data.createFramework;
  }
  async UpdateFramework(
    input: UpdateFrameworkInput
  ): Promise<UpdateFrameworkMutation> {
    const statement = `mutation UpdateFramework($input: UpdateFrameworkInput!) {
        updateFramework(input: $input) {
          __typename
          id
          title
          type
          email
          content
          groupId
          processTypeId
          toolId
          order
          minutes
          attributes
          users
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateFrameworkMutation>response.data.updateFramework;
  }
  async DeleteFramework(
    input: DeleteFrameworkInput
  ): Promise<DeleteFrameworkMutation> {
    const statement = `mutation DeleteFramework($input: DeleteFrameworkInput!) {
        deleteFramework(input: $input) {
          __typename
          id
          title
          type
          email
          content
          groupId
          processTypeId
          toolId
          order
          minutes
          attributes
          users
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteFrameworkMutation>response.data.deleteFramework;
  }
  async GetLearnerMap(id: string): Promise<GetLearnerMapQuery> {
    const statement = `query GetLearnerMap($id: ID!) {
        getLearnerMap(id: $id) {
          __typename
          id
          title
          image
          location
          owners
          ownerMember {
            __typename
            id
            title
            email
            createdAt
            learnermaps {
              __typename
              nextToken
            }
          }
          trainingFirstDay
          trainingLastDay
          daysOfPrework
          daysBeforeAssessmentDeadline
          daysAfterTrainingBeforeTestOpens
          daysAfterTrainingBeforeTestCloses
          daysBeforePointDue
          createdAt
          maptype {
            __typename
            id
            title
            learnermaps {
              __typename
              nextToken
            }
          }
          columns {
            __typename
            items {
              __typename
              id
              title
              order
              open
              close
              learnermap{
                id
              }
              columntype{
                id
              }
              cards{
                items{
                  id
                  title
                  active
                  order
                  text
                  body
                  open
                  close
                  bg
                  runtime
                  type
                  medium
                  link
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLearnerMapQuery>response.data.getLearnerMap;
  }
  async ListLearnerMaps(
    filter?: ModelLearnerMapFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLearnerMapsQuery> {
    const statement = `query ListLearnerMaps($filter: ModelLearnerMapFilterInput, $limit: Int, $nextToken: String) {
        listLearnerMaps(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            title
            image
            location
            owners
            ownerMember {
              __typename
              id
              title
              email
              createdAt
            }
            trainingFirstDay
            trainingLastDay
            daysOfPrework
            daysBeforeAssessmentDeadline
            daysAfterTrainingBeforeTestOpens
            daysAfterTrainingBeforeTestCloses
            daysBeforePointDue
            createdAt
            maptype {
              __typename
              id
              title
            }
            columns {
              __typename
              items{
                id
                title
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLearnerMapsQuery>response.data.listLearnerMaps;
  }
  async GetMapType(id: string): Promise<GetMapTypeQuery> {
    const statement = `query GetMapType($id: ID!) {
        getMapType(id: $id) {
          __typename
          id
          title
          learnermaps {
            __typename
            items {
              __typename
              id
              title
              image
              location
              owners
              trainingFirstDay
              trainingLastDay
              daysOfPrework
              daysBeforeAssessmentDeadline
              daysAfterTrainingBeforeTestOpens
              daysAfterTrainingBeforeTestCloses
              daysBeforePointDue
              createdAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMapTypeQuery>response.data.getMapType;
  }
  async ListMapTypes(
    filter?: ModelMapTypeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListMapTypesQuery> {
    const statement = `query ListMapTypes($filter: ModelMapTypeFilterInput, $limit: Int, $nextToken: String) {
        listMapTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            title
            learnermaps {
              __typename
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMapTypesQuery>response.data.listMapTypes;
  }
  async GetColumn(id: string): Promise<GetColumnQuery> {
    const statement = `query GetColumn($id: ID!) {
        getColumn(id: $id) {
          __typename
          id
          title
          order
          open
          close
          learnermap {
            __typename
            id
            title
            image
            location
            owners
            ownerMember {
              __typename
              id
              title
              email
              createdAt
            }
            trainingFirstDay
            trainingLastDay
            daysOfPrework
            daysBeforeAssessmentDeadline
            daysAfterTrainingBeforeTestOpens
            daysAfterTrainingBeforeTestCloses
            daysBeforePointDue
            createdAt
            maptype {
              __typename
              id
              title
            }
            columns {
              __typename
              nextToken
            }
          }
          cards {
            __typename
            items {
              __typename
              id
              title
              active
              type
              lesson
              medium
              runtime
              parent
              link
              bg
              button
              text
              body
              target
              open
              close
              order
            }
            nextToken
          }
          columntype {
            __typename
            id
            title
            columns {
              __typename
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetColumnQuery>response.data.getColumn;
  }
  async ListColumns(
    filter?: ModelColumnFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListColumnsQuery> {
    const statement = `query ListColumns($filter: ModelColumnFilterInput, $limit: Int, $nextToken: String) {
        listColumns(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            title
            order
            open
            close
            learnermap {
              __typename
              id
              title
              image
              location
              owners
              trainingFirstDay
              trainingLastDay
              daysOfPrework
              daysBeforeAssessmentDeadline
              daysAfterTrainingBeforeTestOpens
              daysAfterTrainingBeforeTestCloses
              daysBeforePointDue
              createdAt
            }
            cards {
              __typename
              nextToken
            }
            columntype {
              __typename
              id
              title
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListColumnsQuery>response.data.listColumns;
  }
  async GetColumnType(id: string): Promise<GetColumnTypeQuery> {
    const statement = `query GetColumnType($id: ID!) {
        getColumnType(id: $id) {
          __typename
          id
          title
          columns {
            __typename
            items {
              __typename
              id
              title
              order
              open
              close
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetColumnTypeQuery>response.data.getColumnType;
  }
  async ListColumnTypes(
    filter?: ModelColumnTypeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListColumnTypesQuery> {
    const statement = `query ListColumnTypes($filter: ModelColumnTypeFilterInput, $limit: Int, $nextToken: String) {
        listColumnTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            title
            columns {
              __typename
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListColumnTypesQuery>response.data.listColumnTypes;
  }
  async GetCard(id: string): Promise<GetCardQuery> {
    const statement = `query GetCard($id: ID!) {
        getCard(id: $id) {
          __typename
          id
          title
          active
          type
          lesson
          medium
          runtime
          parent
          link
          bg
          button
          text
          body
          target
          open
          close
          order
          column {
            __typename
            id
            title
            order
            open
            close
            learnermap {
              __typename
              id
              title
              image
              location
              owners
              trainingFirstDay
              trainingLastDay
              daysOfPrework
              daysBeforeAssessmentDeadline
              daysAfterTrainingBeforeTestOpens
              daysAfterTrainingBeforeTestCloses
              daysBeforePointDue
              createdAt
            }
            cards {
              __typename
              nextToken
            }
            columntype {
              __typename
              id
              title
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCardQuery>response.data.getCard;
  }
  async ListCards(
    filter?: ModelCardFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCardsQuery> {
    const statement = `query ListCards($filter: ModelCardFilterInput, $limit: Int, $nextToken: String) {
        listCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            title
            active
            type
            lesson
            medium
            runtime
            parent
            link
            bg
            button
            text
            body
            target
            open
            close
            order
            column {
              __typename
              id
              title
              order
              open
              close
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCardsQuery>response.data.listCards;
  }
  async GetMember(id: string): Promise<GetMemberQuery> {
    const statement = `query GetMember($id: ID!) {
        getMember(id: $id) {
          __typename
          id
          title
          email
          createdAt
          learnermaps {
            __typename
            items {
              __typename
              id
              title
              image
              location
              owners
              trainingFirstDay
              trainingLastDay
              daysOfPrework
              daysBeforeAssessmentDeadline
              daysAfterTrainingBeforeTestOpens
              daysAfterTrainingBeforeTestCloses
              daysBeforePointDue
              createdAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMemberQuery>response.data.getMember;
  }
  async ListMembers(
    filter?: ModelMemberFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListMembersQuery> {
    const statement = `query ListMembers($filter: ModelMemberFilterInput, $limit: Int, $nextToken: String) {
        listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            title
            email
            createdAt
            learnermaps {
              __typename
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMembersQuery>response.data.listMembers;
  }
  async GetConversion(id: string): Promise<GetConversionQuery> {
    const statement = `query GetConversion($id: ID!) {
        getConversion(id: $id) {
          __typename
          id
          email
          createdAt
          cardID
          learnermapID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConversionQuery>response.data.getConversion;
  }
  async ListConversions(
    filter?: ModelConversionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListConversionsQuery> {
    const statement = `query ListConversions($filter: ModelConversionFilterInput, $limit: Int, $nextToken: String) {
        listConversions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            email
            createdAt
            cardID
            learnermapID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConversionsQuery>response.data.listConversions;
  }
  async GetMarked(id: string): Promise<GetMarkedQuery> {
    const statement = `query GetMarked($id: ID!) {
        getMarked(id: $id) {
          __typename
          id
          marked
          email
          createdAt
          cardID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMarkedQuery>response.data.getMarked;
  }
  async ListMarkeds(
    filter?: ModelMarkedFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListMarkedsQuery> {
    const statement = `query ListMarkeds($filter: ModelMarkedFilterInput, $limit: Int, $nextToken: String) {
        listMarkeds(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            marked
            email
            createdAt
            cardID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMarkedsQuery>response.data.listMarkeds;
  }
  async GetFramework(id: string): Promise<GetFrameworkQuery> {
    const statement = `query GetFramework($id: ID!) {
        getFramework(id: $id) {
          __typename
          id
          title
          type
          email
          content
          groupId
          processTypeId
          toolId
          order
          minutes
          attributes
          users
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetFrameworkQuery>response.data.getFramework;
  }
  async ListFrameworks(
    filter?: ModelFrameworkFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListFrameworksQuery> {
    const statement = `query ListFrameworks($filter: ModelFrameworkFilterInput, $limit: Int, $nextToken: String) {
        listFrameworks(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            title
            type
            email
            content
            groupId
            processTypeId
            toolId
            order
            minutes
            attributes
            users
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListFrameworksQuery>response.data.listFrameworks;
  }
  OnCreateLearnerMapListener: Observable<
    OnCreateLearnerMapSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateLearnerMap {
        onCreateLearnerMap {
          __typename
          id
          title
          image
          location
          owners
          ownerMember {
            __typename
            id
            title
            email
            createdAt
            learnermaps {
              __typename
              nextToken
            }
          }
          trainingFirstDay
          trainingLastDay
          daysOfPrework
          daysBeforeAssessmentDeadline
          daysAfterTrainingBeforeTestOpens
          daysAfterTrainingBeforeTestCloses
          daysBeforePointDue
          createdAt
          maptype {
            __typename
            id
            title
            learnermaps {
              __typename
              nextToken
            }
          }
          columns {
            __typename
            items {
              __typename
              id
              title
              order
              open
              close
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<OnCreateLearnerMapSubscription>;

  OnUpdateLearnerMapListener: Observable<
    OnUpdateLearnerMapSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateLearnerMap {
        onUpdateLearnerMap {
          __typename
          id
          title
          image
          location
          owners
          ownerMember {
            __typename
            id
            title
            email
            createdAt
            learnermaps {
              __typename
              nextToken
            }
          }
          trainingFirstDay
          trainingLastDay
          daysOfPrework
          daysBeforeAssessmentDeadline
          daysAfterTrainingBeforeTestOpens
          daysAfterTrainingBeforeTestCloses
          daysBeforePointDue
          createdAt
          maptype {
            __typename
            id
            title
            learnermaps {
              __typename
              nextToken
            }
          }
          columns {
            __typename
            items {
              __typename
              id
              title
              order
              open
              close
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<OnUpdateLearnerMapSubscription>;

  OnDeleteLearnerMapListener: Observable<
    OnDeleteLearnerMapSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteLearnerMap {
        onDeleteLearnerMap {
          __typename
          id
          title
          image
          location
          owners
          ownerMember {
            __typename
            id
            title
            email
            createdAt
            learnermaps {
              __typename
              nextToken
            }
          }
          trainingFirstDay
          trainingLastDay
          daysOfPrework
          daysBeforeAssessmentDeadline
          daysAfterTrainingBeforeTestOpens
          daysAfterTrainingBeforeTestCloses
          daysBeforePointDue
          createdAt
          maptype {
            __typename
            id
            title
            learnermaps {
              __typename
              nextToken
            }
          }
          columns {
            __typename
            items {
              __typename
              id
              title
              order
              open
              close
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<OnDeleteLearnerMapSubscription>;

  OnCreateMapTypeListener: Observable<
    OnCreateMapTypeSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateMapType {
        onCreateMapType {
          __typename
          id
          title
          learnermaps {
            __typename
            items {
              __typename
              id
              title
              image
              location
              owners
              trainingFirstDay
              trainingLastDay
              daysOfPrework
              daysBeforeAssessmentDeadline
              daysAfterTrainingBeforeTestOpens
              daysAfterTrainingBeforeTestCloses
              daysBeforePointDue
              createdAt
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<OnCreateMapTypeSubscription>;

  OnUpdateMapTypeListener: Observable<
    OnUpdateMapTypeSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateMapType {
        onUpdateMapType {
          __typename
          id
          title
          learnermaps {
            __typename
            items {
              __typename
              id
              title
              image
              location
              owners
              trainingFirstDay
              trainingLastDay
              daysOfPrework
              daysBeforeAssessmentDeadline
              daysAfterTrainingBeforeTestOpens
              daysAfterTrainingBeforeTestCloses
              daysBeforePointDue
              createdAt
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<OnUpdateMapTypeSubscription>;

  OnDeleteMapTypeListener: Observable<
    OnDeleteMapTypeSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteMapType {
        onDeleteMapType {
          __typename
          id
          title
          learnermaps {
            __typename
            items {
              __typename
              id
              title
              image
              location
              owners
              trainingFirstDay
              trainingLastDay
              daysOfPrework
              daysBeforeAssessmentDeadline
              daysAfterTrainingBeforeTestOpens
              daysAfterTrainingBeforeTestCloses
              daysBeforePointDue
              createdAt
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<OnDeleteMapTypeSubscription>;

  OnCreateColumnListener: Observable<OnCreateColumnSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnCreateColumn {
        onCreateColumn {
          __typename
          id
          title
          order
          open
          close
          learnermap {
            __typename
            id
            title
            image
            location
            owners
            ownerMember {
              __typename
              id
              title
              email
              createdAt
            }
            trainingFirstDay
            trainingLastDay
            daysOfPrework
            daysBeforeAssessmentDeadline
            daysAfterTrainingBeforeTestOpens
            daysAfterTrainingBeforeTestCloses
            daysBeforePointDue
            createdAt
            maptype {
              __typename
              id
              title
            }
            columns {
              __typename
              nextToken
            }
          }
          cards {
            __typename
            items {
              __typename
              id
              title
              active
              type
              lesson
              medium
              runtime
              parent
              link
              bg
              button
              text
              body
              target
              open
              close
              order
            }
            nextToken
          }
          columntype {
            __typename
            id
            title
            columns {
              __typename
              nextToken
            }
          }
        }
      }`
    )
  ) as Observable<OnCreateColumnSubscription>;

  OnUpdateColumnListener: Observable<OnUpdateColumnSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnUpdateColumn {
        onUpdateColumn {
          __typename
          id
          title
          order
          open
          close
          learnermap {
            __typename
            id
            title
            image
            location
            owners
            ownerMember {
              __typename
              id
              title
              email
              createdAt
            }
            trainingFirstDay
            trainingLastDay
            daysOfPrework
            daysBeforeAssessmentDeadline
            daysAfterTrainingBeforeTestOpens
            daysAfterTrainingBeforeTestCloses
            daysBeforePointDue
            createdAt
            maptype {
              __typename
              id
              title
            }
            columns {
              __typename
              nextToken
            }
          }
          cards {
            __typename
            items {
              __typename
              id
              title
              active
              type
              lesson
              medium
              runtime
              parent
              link
              bg
              button
              text
              body
              target
              open
              close
              order
            }
            nextToken
          }
          columntype {
            __typename
            id
            title
            columns {
              __typename
              nextToken
            }
          }
        }
      }`
    )
  ) as Observable<OnUpdateColumnSubscription>;

  OnDeleteColumnListener: Observable<OnDeleteColumnSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnDeleteColumn {
        onDeleteColumn {
          __typename
          id
          title
          order
          open
          close
          learnermap {
            __typename
            id
            title
            image
            location
            owners
            ownerMember {
              __typename
              id
              title
              email
              createdAt
            }
            trainingFirstDay
            trainingLastDay
            daysOfPrework
            daysBeforeAssessmentDeadline
            daysAfterTrainingBeforeTestOpens
            daysAfterTrainingBeforeTestCloses
            daysBeforePointDue
            createdAt
            maptype {
              __typename
              id
              title
            }
            columns {
              __typename
              nextToken
            }
          }
          cards {
            __typename
            items {
              __typename
              id
              title
              active
              type
              lesson
              medium
              runtime
              parent
              link
              bg
              button
              text
              body
              target
              open
              close
              order
            }
            nextToken
          }
          columntype {
            __typename
            id
            title
            columns {
              __typename
              nextToken
            }
          }
        }
      }`
    )
  ) as Observable<OnDeleteColumnSubscription>;

  OnCreateColumnTypeListener: Observable<
    OnCreateColumnTypeSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateColumnType {
        onCreateColumnType {
          __typename
          id
          title
          columns {
            __typename
            items {
              __typename
              id
              title
              order
              open
              close
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<OnCreateColumnTypeSubscription>;

  OnUpdateColumnTypeListener: Observable<
    OnUpdateColumnTypeSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateColumnType {
        onUpdateColumnType {
          __typename
          id
          title
          columns {
            __typename
            items {
              __typename
              id
              title
              order
              open
              close
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<OnUpdateColumnTypeSubscription>;

  OnDeleteColumnTypeListener: Observable<
    OnDeleteColumnTypeSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteColumnType {
        onDeleteColumnType {
          __typename
          id
          title
          columns {
            __typename
            items {
              __typename
              id
              title
              order
              open
              close
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<OnDeleteColumnTypeSubscription>;

  OnCreateCardListener: Observable<OnCreateCardSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnCreateCard {
        onCreateCard {
          __typename
          id
          title
          active
          type
          lesson
          medium
          runtime
          parent
          link
          bg
          button
          text
          body
          target
          open
          close
          order
          column {
            __typename
            id
            title
            order
            open
            close
            learnermap {
              __typename
              id
              title
              image
              location
              owners
              trainingFirstDay
              trainingLastDay
              daysOfPrework
              daysBeforeAssessmentDeadline
              daysAfterTrainingBeforeTestOpens
              daysAfterTrainingBeforeTestCloses
              daysBeforePointDue
              createdAt
            }
            cards {
              __typename
              nextToken
            }
            columntype {
              __typename
              id
              title
            }
          }
        }
      }`
    )
  ) as Observable<OnCreateCardSubscription>;

  OnUpdateCardListener: Observable<OnUpdateCardSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCard {
        onUpdateCard {
          __typename
          id
          title
          active
          type
          lesson
          medium
          runtime
          parent
          link
          bg
          button
          text
          body
          target
          open
          close
          order
          column {
            __typename
            id
            title
            order
            open
            close
            learnermap {
              __typename
              id
              title
              image
              location
              owners
              trainingFirstDay
              trainingLastDay
              daysOfPrework
              daysBeforeAssessmentDeadline
              daysAfterTrainingBeforeTestOpens
              daysAfterTrainingBeforeTestCloses
              daysBeforePointDue
              createdAt
            }
            cards {
              __typename
              nextToken
            }
            columntype {
              __typename
              id
              title
            }
          }
        }
      }`
    )
  ) as Observable<OnUpdateCardSubscription>;

  OnDeleteCardListener: Observable<OnDeleteCardSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCard {
        onDeleteCard {
          __typename
          id
          title
          active
          type
          lesson
          medium
          runtime
          parent
          link
          bg
          button
          text
          body
          target
          open
          close
          order
          column {
            __typename
            id
            title
            order
            open
            close
            learnermap {
              __typename
              id
              title
              image
              location
              owners
              trainingFirstDay
              trainingLastDay
              daysOfPrework
              daysBeforeAssessmentDeadline
              daysAfterTrainingBeforeTestOpens
              daysAfterTrainingBeforeTestCloses
              daysBeforePointDue
              createdAt
            }
            cards {
              __typename
              nextToken
            }
            columntype {
              __typename
              id
              title
            }
          }
        }
      }`
    )
  ) as Observable<OnDeleteCardSubscription>;

  OnCreateMemberListener: Observable<OnCreateMemberSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnCreateMember {
        onCreateMember {
          __typename
          id
          title
          email
          createdAt
          learnermaps {
            __typename
            items {
              __typename
              id
              title
              image
              location
              owners
              trainingFirstDay
              trainingLastDay
              daysOfPrework
              daysBeforeAssessmentDeadline
              daysAfterTrainingBeforeTestOpens
              daysAfterTrainingBeforeTestCloses
              daysBeforePointDue
              createdAt
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<OnCreateMemberSubscription>;

  OnUpdateMemberListener: Observable<OnUpdateMemberSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnUpdateMember {
        onUpdateMember {
          __typename
          id
          title
          email
          createdAt
          learnermaps {
            __typename
            items {
              __typename
              id
              title
              image
              location
              owners
              trainingFirstDay
              trainingLastDay
              daysOfPrework
              daysBeforeAssessmentDeadline
              daysAfterTrainingBeforeTestOpens
              daysAfterTrainingBeforeTestCloses
              daysBeforePointDue
              createdAt
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<OnUpdateMemberSubscription>;

  OnDeleteMemberListener: Observable<OnDeleteMemberSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnDeleteMember {
        onDeleteMember {
          __typename
          id
          title
          email
          createdAt
          learnermaps {
            __typename
            items {
              __typename
              id
              title
              image
              location
              owners
              trainingFirstDay
              trainingLastDay
              daysOfPrework
              daysBeforeAssessmentDeadline
              daysAfterTrainingBeforeTestOpens
              daysAfterTrainingBeforeTestCloses
              daysBeforePointDue
              createdAt
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<OnDeleteMemberSubscription>;

  OnCreateConversionListener: Observable<
    OnCreateConversionSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateConversion {
        onCreateConversion {
          __typename
          id
          email
          createdAt
          cardID
          learnermapID
        }
      }`
    )
  ) as Observable<OnCreateConversionSubscription>;

  OnUpdateConversionListener: Observable<
    OnUpdateConversionSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateConversion {
        onUpdateConversion {
          __typename
          id
          email
          createdAt
          cardID
          learnermapID
        }
      }`
    )
  ) as Observable<OnUpdateConversionSubscription>;

  OnDeleteConversionListener: Observable<
    OnDeleteConversionSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteConversion {
        onDeleteConversion {
          __typename
          id
          email
          createdAt
          cardID
          learnermapID
        }
      }`
    )
  ) as Observable<OnDeleteConversionSubscription>;

  OnCreateMarkedListener: Observable<OnCreateMarkedSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnCreateMarked {
        onCreateMarked {
          __typename
          id
          marked
          email
          createdAt
          cardID
        }
      }`
    )
  ) as Observable<OnCreateMarkedSubscription>;

  OnUpdateMarkedListener: Observable<OnUpdateMarkedSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnUpdateMarked {
        onUpdateMarked {
          __typename
          id
          marked
          email
          createdAt
          cardID
        }
      }`
    )
  ) as Observable<OnUpdateMarkedSubscription>;

  OnDeleteMarkedListener: Observable<OnDeleteMarkedSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnDeleteMarked {
        onDeleteMarked {
          __typename
          id
          marked
          email
          createdAt
          cardID
        }
      }`
    )
  ) as Observable<OnDeleteMarkedSubscription>;

  OnCreateFrameworkListener: Observable<
    OnCreateFrameworkSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateFramework {
        onCreateFramework {
          __typename
          id
          title
          type
          email
          content
          groupId
          processTypeId
          toolId
          order
          minutes
          attributes
          users
        }
      }`
    )
  ) as Observable<OnCreateFrameworkSubscription>;

  OnUpdateFrameworkListener: Observable<
    OnUpdateFrameworkSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateFramework {
        onUpdateFramework {
          __typename
          id
          title
          type
          email
          content
          groupId
          processTypeId
          toolId
          order
          minutes
          attributes
          users
        }
      }`
    )
  ) as Observable<OnUpdateFrameworkSubscription>;

  OnDeleteFrameworkListener: Observable<
    OnDeleteFrameworkSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteFramework {
        onDeleteFramework {
          __typename
          id
          title
          type
          email
          content
          groupId
          processTypeId
          toolId
          order
          minutes
          attributes
          users
        }
      }`
    )
  ) as Observable<OnDeleteFrameworkSubscription>;
}
