import { Component } from '@angular/core';
import { AdminLearnerMapsListComponent } from '../../admin-learner-maps-list/admin-learner-maps-list.component';

@Component({
  selector: 'app-addlm-type-popup',
  templateUrl: './addlm-type-popup.component.html',
  styleUrls: ['./addlm-type-popup.component.scss']
})
export class AddlmTypePopupComponent {

  constructor(public lmCompo: AdminLearnerMapsListComponent) {}

  public close() {
    $('.addlmType-modal').css('display', 'none');
  }
}
