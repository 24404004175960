import { Component } from '@angular/core';

@Component({
  selector: 'app-myteams-toolstab',
  templateUrl: './myteams-toolstab.component.html',
  styleUrls: ['./myteams-toolstab.component.scss']
})
export class MyteamsToolstabComponent {

}
