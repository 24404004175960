import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from './../../../../environments/environment';
import { CustomPfService } from '../custom-pf.service';
import {AccessGroupService} from './../../access-group.service';
import { LmTsService } from '../../learner-maps/lm-ts.service';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-fs-reveal-dashboard',
  templateUrl: './fs-reveal-dashboard.component.html',
  styleUrls: ['./fs-reveal-dashboard.component.scss']
})
export class FsRevealDashboardComponent implements OnInit {

  env;
  @Output() contentChange = new EventEmitter();
  @Input() obj_container:any;
  main_app_enable:boolean = false;
  profiles:any;
  menu_links;
  fs_reveal_container = [];
  loading:boolean = false;
  breadcrumb:boolean = true;
  ngrxAccessGroup:any;
  ngrxTcwaProfile: any;

  constructor( public router: Router, public activatedRoute:ActivatedRoute,public customPfService: CustomPfService,public accessgroup:AccessGroupService,public lm_ts: LmTsService,public store:Store<any>) { }

  async ngOnInit() {
    this.ngrxAccessGroup = this.store.select<any>((state) => state.profile.accessGroupData);
    this.ngrxTcwaProfile = this.store.select<any>((state) => state.profile.tcwaProfile)
    console.log(this.obj_container)
    this.env = environment;
    console.log('router',this.router?.url)
    var that = this;

    this.loading = true;
    if(this.router.url.includes('my-account') || this.router.url.includes('fs-admin') || this.router.url.includes('teams-admin')) {
      this.breadcrumb = false;
      this.main_app_enable = true;
      this.loading = false;
    } else {
      if(this.router?.url.includes('fs-reveal')) {
        this.main_app_enable = true;
        await this.ngrxAccessGroup.subscribe(message => { 
          console.log(message["main_menu"]?.dynamic_lms)
          if(message){           
            this.menu_links = message;
            let fs_reveal_lm_id = (this.menu_links["main_menu"]?.dynamic_lms?.presenter_lm?.value);
            localStorage.setItem('fs_reveal_lm_id', fs_reveal_lm_id);
            this.fs_reveal_container = [
              {
                title:this.menu_links["main_menu"]?.dynamic_lms?.presenter_lm?.title,
                img_link:`${this.env.adminUpload}x7j8a1i6uqouuvg5wqvaswq1syl1`,
                active: this.menu_links["main_menu"]?.dynamic_lms?.presenter_lm?.lmproperties,
                lm_id: this.menu_links["main_menu"]?.dynamic_lms?.presenter_lm?.value,
                routerlink: `fs-reveal/presenter-lm`
            },{
                title:this.menu_links["main_menu"]?.dynamic_lms?.presentation?.title,
                img_link:`${this.env.admin_upload}312/original.jpg?1661422918`,
                active: this.menu_links["main_menu"]?.dynamic_lms?.presentation?.lmproperties,
                lm_id:this.menu_links["main_menu"]?.dynamic_lms?.presentation?.value,
                routerlink: `fs-reveal/presentation`
            },
          ]
          this.obj_container = this.fs_reveal_container;
          }
          setTimeout(() => {
            that.loading = false;
          }, 100);
        },
        err => {
            // Log errors if any
            console.log(err);
        })
      }else {
        // profile data 
        this.ngrxTcwaProfile.subscribe(res => {
          this.profiles = res['profile'];
          // data for fs-reveal
          this.fs_reveal_container = [
            {
              title:this.profiles?.access_groups_json?.main_menu?.dlm_slideshow?.title,
              img_link:`${this.env.admin_upload}300/original.jpg?1660299027`,
              active: this.profiles?.access_groups_json?.main_menu?.dlm_slideshow?.lmproperties,
              lm_id: this.profiles?.access_groups_json?.main_menu?.dlm_slideshow?.value
          },{
                title:this.profiles?.access_groups_json?.main_menu?.dlm_slideshow2?.title,
                img_link:`${this.env.admin_upload}312/original.jpg?1661422918`,
                active: this.profiles?.access_groups_json?.main_menu?.dlm_slideshow2?.lmproperties,
                lm_id: this.profiles?.access_groups_json?.main_menu?.dlm_slideshow2?.value,
            },
            ,
        ]
        
        });
      }
    }
  }

  // update content view value
  public changeContent(content,lm_id= ''){
    this.contentChange.emit(content);
    if(lm_id !== '') {
      this.customPfService?.active_lmsd_id.next(lm_id);
    }
  }

  // update route
  public updateRoute(routePath) {
    if(routePath.includes('aa-staging.foursightonline.com')) {
      window.open(routePath,'_blank');
    } else {
      this.router.navigate([`${routePath}`]);
      this.lm_ts.an_slide_no.next(0);
    }
  }
}
