import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-cig-image-without-title',
  templateUrl: './cig-image-without-title.component.html',
  styleUrls: ['./cig-image-without-title.component.scss']
})
export class CigImageWithoutTitleComponent implements OnInit {
  @Input() profile:any;
  @Input() slides;
  @Input() author;
  @Input() imgLink;
  public env;
  public profiles : any;

  constructor() { }

  ngOnInit(): void {
    console.log(this.slides)
    this.env=environment;
    this.profiles = this.profile;
  }

}
