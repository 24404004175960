import { Component } from '@angular/core';
import { TeamReportServiceService } from './../../team-report/team-report-service.service';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { first } from 'rxjs';

@Component({
  selector: 'app-participants-view',
  templateUrl: './participants-view.component.html',
  styleUrls: ['./participants-view.component.scss']
})
export class ParticipantsViewComponent {
  teams:any = [];
  oneRatioOne = false;
  individualTeam = true;
  individualId;
  userAccount;
  ngrxAccount;
  individualTeamDetails:any = {}
  participantData;
  downloadGraphPopup:boolean = false;
  editState:boolean = false;
  fieldEdit:number = 0;
  editedPlanResult = '';
  rightUserDetails:any;
  participantAdminTeams:any= false;

  constructor(public teamReports: TeamReportServiceService, private router: ActivatedRoute,public store:Store<any>, private http: HttpClient, private route: Router){}

  ngOnDestroy() {
    // localStorage.removeItem("participantTeamData")
    // localStorage.removeItem("participantData")
  }
  
  ngOnInit() {
    this.participantAdminTeams = localStorage.getItem("participantsTeamsAdmin")
    this.ngrxAccount = this.store.select<any>((state) => state.profile.account);
    this.ngrxAccount.subscribe((res)=> {
        this.userAccount = res
        console.log('userAccount',res)
    })

    this.teamReports?.participantRightSideMem.subscribe((val)=>{
      console.log('data',val)
      this.rightUserDetails = val
    })

    this.teamReports.oneRatioOne.subscribe((val)=> {
        this.oneRatioOne = val
    })

    console.log(this.route.url)
    
    this.router.params.subscribe((params)=> {
      this.individualId = params['id']
      this.participantData = localStorage.getItem('participantData');
      this.participantData = JSON.parse(this.participantData)
      console.log(this.route.url.includes("member"+ params['id']))
        if(!this.teamReports.participantTeamData) {
          let item:any = localStorage.getItem("participantTeamData")
          this.teamReports.participantTeamData = JSON.parse(item)
          // this.http.get(`${environment.profileURL}/api/v1/teams/`+params['id'] +`/memberships.json?per_page=${this.teamReports?.participantTeamData?.memberships_count}`).subscribe(data => {
            //   console.warn(data)
            // })
          }
        this.individualTeamDetails["name"] = this.teamReports.participantTeamData.name;
        this.teams.push(this.teamReports.participantTeamData);
    })


    console.warn("our particpant data",this.teamReports.participantTeamData)
    
  }

  public cancelPlan(id){
    console.log(this.editedPlanResult)
    this.editState = false;
    this.teams[0].my_plan = this.editedPlanResult;
    this.fieldEdit = 0;
}

// public cancelAgreement(){
//     this.editAgreementsTeam = false;
// }

// save my plan for this team
public savePlan(id, planText:string){
    var that = this;
    this.editState = false;
    this.fieldEdit = 0;
    let data = {my_plan: planText}
    this.teamReports.participantTeamData.my_plan = planText
    localStorage.setItem('participantTeamData', JSON.stringify(this.teamReports?.participantTeamData))
        // console.log('implement',id)
    this.http.post(`${environment.profileURL}/api/v1/teams/${id}/update_my_plan.json`,data)
    .subscribe((res:any) => {	    		
          setTimeout(function() {
              this.toastr.options = {
                  closeButton: true,
                  progressBar: true,
                  timeOut: 2000
              };
              this.toastr.success('Plan updated Successfully!', 'Nice!');
          }, 1000);
        }, err => {
            setTimeout(function() {
              this.toastr.options = {
                  closeButton: true,
                  progressBar: true,
                  timeOut: 2000
              };
              this.toastr.error(err?.error?.errors, 'Oops!');
              that.teams[0].my_plan = this.editedPlanResult;
          }, 1000);
        }
      )
  }

  // edit my plan for this team
  public editPlan(id){
    this.editState = true;
    this.fieldEdit = id;
    console.log(this.teams,this.participantData)
    this.editedPlanResult = this.teams[0].my_plan
    console.log(id, this.editedPlanResult)
  }

  reloadComponent() {
    this.oneRatioOne = false;
    this.teamReports.oneRatioOne.next(false)
    setTimeout(() => {
      this.ngOnInit();
    }, 5000);
  }

}
