// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fixed-loader {
  width: calc(100% - 220px);
  height: 100%;
  text-align: center;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  right: 0;
  top: 0;
}
.fixed-loader i {
  font-size: 8em !important;
}
.fixed-loader h4 {
  display: block;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/store/accept-paypal/accept-paypal.component.scss"],"names":[],"mappings":"AAAA;EACC,yBAAA;EACG,YAAA;EACA,kBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,sBAAA;EACA,QAAA;EACA,MAAA;AACJ;AAAC;EACC,yBAAA;AAEF;AAAC;EACC,cAAA;EACG,WAAA;AAEL","sourcesContent":[".fixed-loader{\n\twidth: calc(100% - 220px);\n    height: 100%;\n    text-align: center;\n    position: fixed;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-wrap: wrap;\n    flex-direction: column;\n    right: 0;\n    top: 0;\n\ti{\n\t\tfont-size: 8em !important;\n\t}\n\th4{\n\t\tdisplay: block;\n    \twidth: 100%;\n\t}\n\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
