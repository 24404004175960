import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { LambdaFunctionService } from './../../service/lambda-function.service';

@Injectable({
  providedIn: 'root'
})
export class TeamReportServiceService {

    teamSelectedArr = [];
    wizardThinkingProfilePopup;
    wizardArgs;
    current_team_members = new BehaviorSubject<number>(0);
    rosterName = new BehaviorSubject<string>('');
    team_members = new BehaviorSubject<any>([]);
    defaultMyTeamsView = new BehaviorSubject<boolean>(false);  //for teams component default view (sidenav myteams)
    totalOrgMembers = new Subject<Number>;
    orgData = new Subject<any>;   // there is no child-parent relationship between orgs.component and org-individual.component
    participantTeamData; // from teams admin to participant view
    oneRatioOne = new Subject<boolean>;
    participantRightSideMem = new BehaviorSubject<any>("");
    
    args:any;
    pdfLink = '';
    downloadGraphPopup = false;
    downloadPreferencePopup = false;
    teamHeadings = {
        profile: "Group Profile",
        preferences: "Preferences",
        energy: "My Energy in the Team",
    }

    public getSelectedTeam() {
        return this.teamSelectedArr
    }

    // teamComponent
    public async downloadGraph(type) {
        var that = this;
        type = type.toLowerCase();
        console.log(this.args,type)
        if(!this.wizardThinkingProfilePopup) this.args.content_type = type;
        $('.mytm-loader').css('display','block');
        $('.team-loader').css('display','block');
        let promise = new Promise((resolve, reject) => {
            this.lmbda_service.lambda_pdf.next([])
            if(this.downloadPreferencePopup) {
                let data  = this.args;
                delete data.clarifier_low;
                delete data.clarifier_high;
                delete data.ideator_low;
                delete data.ideator_high;
                delete data.developer_low;
                delete data.developer_high;
                delete data.implementor_low;
                delete data.implementor_high;
                delete data.integrator;
                data["team_profiles"]["clarifier_high"] = this.args.gppData?.clHigh;
                data["team_profiles"]["clarifier_neutral"] = this.args.gppData?.clNeu;
                data["team_profiles"]["clarifier_low"] = this.args.gppData?.clLow;
                data["team_profiles"]["ideator_high"] = this.args.gppData?.idHigh;
                data["team_profiles"]["ideator_neutral"] = this.args.gppData?.idNeu;
                data["team_profiles"]["ideator_low"] = this.args.gppData?.idLow;
                data["team_profiles"]["developer_high"] = this.args.gppData?.deHigh;
                data["team_profiles"]["developer_neutral"] = this.args.gppData?.deNeu;
                data["team_profiles"]["developer_low"] = this.args.gppData?.deLow;
                data["team_profiles"]["implementer_high"] = this.args.gppData?.imHigh;
                data["team_profiles"]["implementer_neutral"] = this.args.gppData?.imNeu;
                data["team_profiles"]["implementer_low"] = this.args.gppData?.imLow;
                setTimeout(() => {
                    resolve(this.lmbda_service.lambda_invoke('team-profile-dev-pdf',data))
                }, 500);
                
            } else if(this.wizardThinkingProfilePopup){
                setTimeout(() => {
                    resolve(this.lmbda_service.lambda_invoke('multi-profile-pdf-dev-pdf',this.wizardArgs));
                }, 500);
            } else {
                setTimeout(() => {
                    resolve(this.lmbda_service.lambda_invoke('group-profile-dev-pdf',this.args))
                }, 500);
            }
        })
        promise.then(()=> {
            try {
                if(this.wizardThinkingProfilePopup) {
                    this.lmbda_service.lambda_res.subscribe((output)=> {
                        console.log(output)
                        if(output.length) {
                            this.pdfLink = output;
                            setTimeout(() => {
                                $('.mytm-loader').css('display','none');
                                $('.team-loader').css('display','none');
                            }, 100);
                        } 
                      })
                } else {
                    this.lmbda_service.lambda_pdf.subscribe((output)=> {
                        console.log(output)
                        if(output.length) {
                            this.pdfLink = output;
                            setTimeout(() => {
                                $('.mytm-loader').css('display','none');
                                $('.team-loader').css('display','none');
                            }, 100);
                        } 
                    })
    
                }
            } catch(err) {
                console.log(err)
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 2000
                    };
                    this.toastr.error('Something went wrong!', 'Oops!');
                }, 1000);
                setTimeout(() => {
                    $('.mytm-loader').css('display','none');
                    $('.team-loader').css('display','none');
                }, 100);
            }
        })
    }

	cover = {
        "header":      "Your <br>team<br>report",
        "subHeader":   "",
        "owner":    	 "Test Org Final Team ",
        "version":  	 "",
        "copyright":   `<b>foursightonline.com </b> © ${environment.copyright_year} FourSight, LLC This report can be freely reproduced by and for team members.`,
        "reportAdminold": [
            {
                "label":  "Blair Miller"
            },
            {
                "label":  "Sarah Thurber"
            },
            {
                "label":  "Gerard Puccio"
            },
            {
                "label":  "Russell Schoen"
            }
        ]
    };

    fourpreferences = {
    	"header": 				"The Four Preferences",
    	"clarifierHeading":     "clarifier",
    	"clarifierSubheading": 	"Clarifier-at-a-glance…",
    	"clarifierGlance": [
	    	{
	    		"label": 	"Enjoys exploring challenges and opportunities"
	    	},
	    	{
	    		"label": 	"Likes to examine the details"
	    	},
	    	{
	    		"label": 	"Wants a clear understanding of the issue"
	    	},
	    	{
	    		"label": 	"prefers a methodical approach to solving problems"
	    	},
	    	{
	    		"label": 	'May suffer from “analysis paralysis”'
	    	}
    	],

    	"clarifierSubheadingSub1":  "Clarifiers are...",
    	"clarifiers": [
    		{
    			"label": 	"Focused"
    		},
    		{
    			"label": 	"Methodical"
    		},
    		{
    			"label": 	"Orderly"
    		},
    		{
    			"label": 	"Deliberate"
    		},
    		{
    			"label": 	"Serious"
    		},
    		{
    			"label": 	"Organized"
    		}
    	],

    	"clarifierSubheadingSub2":  "Give Clarifiers...",
    	"clarifiergivers": [
    		{
    			"label": 	"order"
    		},
    		{
    			"label": 	"the facts"
    		},
    		{
    			"label": 	"An understanding of history"
    		},
    		{
    			"label": 	"Access to information"
    		},
    		{
    			"label": 	"Permission to ask questions"
    		}
    	],

    	"clarifierSubheadingSub3":  "Clarifiers annoy others by...",
    	"clarifierannoys": [
    		{
    			"label": 	"Asking too many questions"
    		},
    		{
    			"label": 	"Pointing out obstacles"
    		},
    		{
    			"label": 	"Identifying areas that haven’t been well thought out"
    		},
    		{
    			"label": 	"Overloading people with information"
    		},
    		{
    			"label": 	"Being too realistic"
    		}
    	],

    	"ideatorHeading": 		"Ideator",
    	"ideatorSubHeading": 	"Ideator-at-a-glance…",
    	"ideatorGlance":[
    		{
    			"label": 	"Likes to look at the big picture"
    		},
    		{
    			"label": 	"Enjoys toying with ideas and possibilities"
    		},
    		{
    			"label": 	"Likes to stretch his or her imagination"
    		},
    		{
    			"label": 	"Enjoys thinking in more global and abstract terms"
    		},
    		{
    			"label": 	"Takes an intuitive approach to innovation"
    		},
    		{
    			"label": 	"May overlook details"
    		}
    	],

    	"ideatorSubheadingSub1": 	"Ideators are...",
    	"ideators":[
    		{
    			"label": 	"Playful",
    		},
    		{
    			"label": 	"Imaginative",
    		},
    		{
    			"label": 	"Social",
    		},
    		{
    			"label": 	"Adaptable",
    		},
    		{
    			"label": 	"Flexible",
    		},
    		{
    			"label": 	"Adventurous",
    		},
    		{
    			"label": 	"Independent",
    		}
    	],

    	"ideatorSubheadingSub2": 	"Give Ideators...",
    	"ideatorgiver":[
    		{
    			"label": 	"Room to be playful"
    		},
    		{
    			"label": 	"Constant stimulation"
    		},
    		{
    			"label": 	"Variety and change"
    		},
    		{
    			"label": 	"The big picture"
    		}
    	],

    	"ideatorSubheadingSub3": 	"Ideators annoy others by...",
    	"ideatorannoy":[
    		{
    			"label": 	"Drawing attention to themselves"
    		},
    		{
    			"label": 	"Being impatient when others don’t get their ideas"
    		},
    		{
    			"label": 	"Offering ideas that are too off-the-wall"
    		},
    		{
    			"label": 	"Being too abstract"
    		},
    		{
    			"label": 	"Not sticking to one idea"
    		}
    	],

    	"developerHeading": 		"Developer",
    	"developerSubHeading": 		"Developer-at-aglance…",
    	"developerGlance":[
    		{
                "label":    "Enjoys putting together workable solutions"
            },
            {
                "label":    "Likes to examine the pluses and minuses of an idea"
            },
            {
                "label":    "Likes to compare competing solutions"
            },
            {
                "label":    "Enjoys analyzing potential solutions"
            },
            {
                "label":    "Enjoys planning the steps to implement an idea"
            },
            {
                "label":    "May get stuck in developing the perfect solution"
            }
    	],

    	"developerSubheadingSub1": 	"Developers are...",
    	"developers":[
    		{
                "label":    "Reflective"
            },
            {
                "label":    "Cautious"
            },
            {
                "label":    "Pragmatic"
            },
            {
                "label":    "Structured"
            },
            {
                "label":    "Planful"
            }
    	],

    	"developerSubheadingSub2": 	"Give Developers...",
    	"developergiver":[
    		{
    			"label": 	"Time to consider the options"
    		},
    		{
    			"label": 	"Time to evaluate"
    		},
    		{
    			"label": 	"Time to develop ideas"
    		}
    	],

    	"developerSubheadingSub3": 	"Developers annoy others by...",
    	"developerannoy":[
    		{
    			"label": 	"Being too nit-picky"
    		},
    		{
    			"label": 	"Finding flaws in others’ ideas"
    		},
    		{
    			"label": 	"Getting locked into one approach"
    		}
    	],

    	"implementorHeading": 		"Implementer",
    	"implementorSubHeading": 	"Implementer-at-aglance…",
    	"implementorGlance":[
    		{
                "label":    "Likes to see things happen"
            },
            {
                "label":    "Enjoys giving structure to ideas so they become a reality"
            },
            {
                "label":    "Enjoys seeing ideas come to fruition"
            },
            {
                "label":    "Likes to focus on “workable” ideas and solutions"
            },
            {
                "label":    "Takes the Nike approach to innovation (i.e., “Just Do It!”)"
            },
            {
                "label":    "May leap to action too quickly"
            }
    	],

    	"implementorSubheadingSub1": 	"Implementers are...",
    	"implementors":[
    		{
                "label":    "Persistent"
            },
            {
                "label":    "Decisive"
            },
            {
                "label":    "Determined"
            },
            {
                "label":    "Assertive"
            },
            {
                "label":    "Action-oriented"
            }
    	],

    	"implementorSubheadingSub2": 	"Give Implementers...",
    	"implementorgiver":[
    		{
    			"label": 	"The sense that others are moving just as quickly"
    		},
    		{
    			"label": 	"Control"
    		},
    		{
    			"label": 	"Timely responses to their ideas"
    		}
    	],

    	"implementorSubheadingSub3": 	"Implementers annoy  others by...",
    	"implementorannoy":[
    		{
    			"label": 	"Being too pushy"
    		},
    		{
    			"label": 	"Readily expressing their frustration when others do not move as quickly"
    		},
    		{
    			"label": 	"Overselling their ideas"
    		}
    	],

    	"copyright": 	"FourSight, LLC This report can be freely reproduced by and for team members."
    };

    clarifierTeam = {
    	"teamHeading": 	"Clarifiers and teams",
    	"teamHeadingText": "<b>Clarifiers contribute to a team</b> by making sure the team is working on the right challenge. They question assumptions and push the team to rationalize its thinking by insisting on facts and data that build a firm foundation for decision-making. The Clarifier’s determination to base decisions on evidence, research and facts inspires the team to dig deeper and ask probing questions. Clarifying keeps teams from getting blindsided as they move forward and focuses team energy on promising opportunities. ",

    	"contributeHeading": 	"High Clarifiers contribute by…",
    	"clarifierContribute":[
    		{
    			"label": 	"Pushing the team to provide evidence and research"
    		},
    		{
    			"label": 	"Insisting on facts and data"
    		},
    		{
    			"label": 	"Questioning assumptions"
    		},
    		{
    			"label": 	"Encouraging the team to rationalize its thinking"
    		},
    		{
    			"label": 	"Preventing the group from getting blindsided"
    		},
    		{
    			"label": 	"Identifying fruitful opportunities"
    		},
    		{
    			"label": 	"Focusing the team on the right challenge"
    		}
    	],

    	"frustratedHeading": 	"High Clarifiers Frustrate others…",
    	"clarifierFrustrated":[
    		{
    			"label": 	"Lack of information, data, evidence"
    		},
    		{
    			"label": 	"Unstructured meetings, unfounded optimism, people talking in metaphors rather than facts"
    		},
    		{
    			"label": 	"Snap decisions and actions"
    		},
    		{
    			"label": 	"Not being able to ask questions to get more data"
    		}
    	],

    	"clarifierSubHeading": 			"Performance Tip",
    	"clarifierSubText": 			"Make sure everyone understands the challenge in the same way.",

    	"clarifierSubTitleHigh":		"If your team is stacked with HIGH Clarifiers…",
    	"clarifierSubTitleHighText":	"You probably have a very strong sense of the world around you and the information 								and data that’s important to achieving your goals. You may have a knack for 									identifying fruitful opportunities to pursue.",

    	"clarifierSubCatHigh":			"Watch out:",
    	"clarifierSubCatHighText":		"At some point, your understanding of the data has to be “enough.” Be willing to 								move to the next phase of the process, knowing you can always circle back. Don’t 								spend ALL of your time clarifying. Be sure to allot enough time to ideate, develop 								and implement.",

    	"clarifierSubTitleLow":			"If your team is stacked with LOW Clarifiers…",
    	"clarifierSubTitleLowText":		"If you neglect this step, over time you may find your actions are not grounded in 								reality. You may have overlooked key information critical to achieving your goals",

    	"clarifierSubCatLow":			"Tip:",
    	"clarifierSubCatLowText":		"Actively acquire information about the situation. Take the time to develop a 									clearly stated question that frames the opportunity you’re pursuing. Ask it as an 								open-ended question that invites solutions (i.e., How to…? or How might we…?)",

    	"askLabelClarifying": 	"my team has a",
    	"askinputHigh": 		"HIGH",
    	"askinputLow": 			"LOW",
    	"askinputNo": 			"NO preference.",

    	"askLabelMyself": 		"I myself have a",
    	"askSubTitle": 			"Performance implications:",

    	"clarifierasideHigh":	"High Clarifiers",
    	"clarifierasideNo":		"No preference",
    	"clarifierasideLow":	"Low Clarifiers",
    	"textNone":				"None"
    };

    ideatorsTeam = {
    	"teamHeading": 			"Ideators and teams",
    	"teamHeadingText": 		"<b>Ideators contribute to a team by proliferating ideas.</b> They provide energy, global thinking and humor. They enjoy a playful climate, and their endless appetite and capacity for generating options and making connections pushes the group to entertain a broader range of alternatives. Effective Ideators embolden a team to ask “What if…” and move the thinking beyond obvious solutions and assumptions, questioning the familiar with novel angles, options and points of view.",

    	"contributeHeading": 	"High Ideators contribute by…",
    	"ideatorContribute":[
    		{
                "label":    "Providing energy, global thinking and humor"
            },
            {
                "label":    "Pushing the group to strive for more options"
            },
            {
                "label":    "Asking “what if?” to move beyond the obvious"
            },
            {
                "label":    "Mixing up the routine"
            },
            {
                "label":    "Offering novel angles and different points of view"
            },
            {
                "label":    "Using imagination and intuition"
            },
            {
    			"label": 	"Coming up with lots of alternatives"
    		},
    	],

    	"frustratedHeading": 	"High Ideators get frustrated by..",
    	"ideatorFrustrated":[
    		{
                "label":    "Routine, lack of variety, little stimulation and limited networking"
            },
            {
                "label":    'Not being given “the big picture”'
            },
            {
                "label":    "An overemphasis on data and an underappreciation of intuition"
            },
            {
    			"label": 	"An inability to express and communicate their ideas"
    		},
    	],

    	"ideatorSubHeading": 		"Performance Tip",
    	"ideatorSubText": 			"Take the time to generate a meaningful range of options. Then choose.",

    	"ideatorSubTitleHigh":		"If your team is stacked with HIGH Ideators…",
    	"ideatorSubTitleHighText":	"You may have a gift for anticipating new trends and coming up with imaginative ideas and approaches. You are fluent, flexible, global thinkers, who use humor and intuition to challenge assumptions and discover new opportunities.",

    	"ideatorSubCatHigh":		"Watch out:",
    	"ideatorSubCatHighText":	"At some point, thinking up more possibilities becomes counterproductive. Constant idea generation may cause you to overlook the details, fail to follow through or neglect organization. Remind yourself: there’s more to a task than coming up with options. Decide on a solution. Then refine it, deliver it and support it.",

    	"ideatorSubTitleLow":		"If your team is stacked with LOW ideators…",
    	"ideatorSubTitleLowText":	"You may tend to go with the first acceptable idea. Without investing the time and energy it takes to create diverse options, you may deny your team access to solutions that might deliver better results.",

    	"ideatorSubCatLow":			"Tip:",
    	"ideatorSubCatLowText":		"Take time to generate ideas and create more options than you think you need. Set an idea quota. Use divergent tools to generate lots of options. Then pick your best options to move forward.",

    	"askLabelideator": 	       "my team has a",

    	"ideatorasideHigh":	       "High Ideators",
    	"ideatorasideNo":		   "No preference",
    	"ideatorasideLow":	       "Low Ideators"
    };

    developersTeam = {
    	"teamHeading": 			"Developers and teams",
    	"teamHeadingText": 		"<b>Developers contribute to a team</b> by taking possible solutions and figuring out ways to improve them. They are master craftsmen bringing ideas to a high level of resolution and polish. They push the team to fine tune the details that will make the idea feasible and tangible. They elaborate and shape the product or service, reducing embarrassment and rework by ensuring that ideas are well thought out before they are released.",

    	"contributeHeading": 	"High Developers contribute by…",
    	"developerContribute":[
    		{
                "label":    "Making ideas feasible and tangible",
            },
            {
                "label":    "Grounding, shaping and elaborating solutions",
            },{
                "label":    "Pushing the team to identify issues",
            },{
                "label":    "Explaining how things function in reality",
            },{
                "label":    "Figuring out how to move the big ideas forward",
            },{
                "label":    "Developing the details of the plan",
            },{
                "label":    "Reducing the potential for embarrassment and rework",
            }
    	],

    	"frustratedHeading": 	"High Developers get frustrated by…",
    	"developerFrustrated":[
    		{
                "label":    "Lack of time to consider, evaluate and elaborate",
            },
            {
                "label":    "Being rushed into implementing before there’s time to refine a solution",
            },
            {
                "label":    "Solutions that are not fully optimized",
            },
            {
                "label":    "Teams that act impulsively and don’t stick to the plan",
            }
    	],

    	"developerSubHeading": 	"Performance Tip",
    	"developerSubText": 	"Be sure to evaluate and carefully craft your solution. What’s good about it? How might you improve it?",

    	"developerSubTitleHigh":		"If your team is stacked with HIGH Developers…",
    	"developerSubTitleHighText":	"You may impress people with your ability to optimize and refine ideas, solutions and existing programs. Through careful crafting of solutions, you create efficiencies and deliver on the needs of others to a high degree of satisfaction. Your ability to identify areas that need attention and improvement serve your team and others very well",

    	"developerSubCatHigh":			"Watch out:",
    	"developerSubCatHighText":		"Bringing things to high resolution takes time. Balance the need for perfection with the need to move projects into action.",

    	"developerSubTitleLow":			"If your team is stacked with LOW Developers…",
    	"developerSubTitleLowText":		"As a team, you may take on too many tasks and underestimate the time each one will take. You may have trouble prioritizing competing goals and end up moving things forward without attending to the details, leading to embarrassing moments and rework.",

    	"developerSubCatLow":			"Tip:",
    	"developerSubCatLowText":		"Rigorously prioritize goals and tasks. Make time to attend to the details. Identify both strengths and weaknesses of your solution and deliberately overcome any weaknesses.",

    	"askLabeldeveloper": 	       "my team has a",

    	"developerasideHigh":	       "High Developers",
    	"developerasideNo":		   	   "No preference",
    	"developerasideLow":	       "Low Developers"
    };

    implementersTeam = {
        "teamHeading":          "Implementers and teams",
        "teamHeadingText":      "<b>Implementers contribute to a team</b> by getting things done. They are project champions, keeping teams in line with deadlines and time constraints and helping sustain momentum. They are decisive and inclined toward action and unafraid to interact with key stakeholders to ease the adoption of a new idea or product.",

        "contributeHeading":    "High Implementers contribute by…",
        "implementerContribute":[
            {
                "label":    "Championing projects"
            },
            {
                "label":    "Keeping teams in line with deadlines"
            },
            {
                "label":    "Sustaining the momentum on a project"
            },
            {
                "label":    "Pushing for action and deliverables"
            },
            {
                "label":    "Taking risks"
            },
            {
                "label":    "Selling new ideas to key stakeholders"
            },
            {
                "label":    "Getting things done"
            }
        ],

        "frustratedHeading":    "High Implementers get frustrated by…",
        "implementerFrustrated":[
            {
                "label":    "Inaction"
            },
            {
                "label":    "Lack of momentum"
            },
            {
                "label":    "Being bogged down by too much information"
            },
        ],

        "implementerSubHeading":      "Performance Tip",
        "implementerSubText":         "Be sure everyone knows the next steps required to move the solution forward.",

        "implementerSubTitleHigh":        "If your team is stacked with HIGH Implementers…",
        "implementerSubTitleHighText":    "You may be at the center of a whirlwind of activity and have a high capacity for getting things done. You provide energy and drive to get tasks accomplished. Your momentum moves things forward at breathtaking speed.",

        "implementerSubCatHigh":          "Watch out:",
        "implementerSubCatHighText":      "Avoid the pitfall of “Ready. Fire. Aim.” Take the time to stop, look and listen to those who may be encouraging you to understand the dynamics of the current situation and offering you alternative courses of action. Be deliberate about refining potential solutions.",

        "implementerSubTitleLow":         "If your team is stacked with LOW Implementers…",
        "implementerSubTitleLowText":     "You may find that it takes a great deal of energy to get the job finished. While you are constantly busy, you may lack a drive toward closure.",

        "implementerSubCatLow":           "Tip:",
        "implementerSubCatLowText":       "Do the next thing that will move your project toward completion. Take action, even if it’s just one step at a time, to finish the job. Create an action plan detailing who does what by when. Enlist support from people who will assist and hold you accountable.",

        "askLabelimplementer":           "my team has a",

        "implementerasideHigh":          "High Implementors",
        "implementerasideNo":        	 "No preference",
        "implementerasideLow":           "Low Implementors"
    };

    integratorsTeam = {
        "teamHeading":          "Integrators and teams",
        "teamHeadingText":      "<b>Integrators contribute to a team</b> by making sure all voices are being heard. They are natural team players. Empathetic and facilitative by nature, they listen to others’ concerns and ideas. They work for collaboration and demand commitment to the task. They have no strong bias toward any one stage in the process and tend to keep the process moving along.",

        "contributeHeading":    "High Integrators contribute by…",
        "integratorContribute":[
            {
                "label":    "Promoting commitment to the task"
            },
            {
                "label":    "Making sure the team is working together"
            },
            {
                "label":    "Making sure all voices are heard"
            },
            {
                "label":    "Moving the team to the next step in the process"
            },
            {
                "label":    "Keeping things running smoothly"
            }
        ],

        "frustratedHeading":    "Integrators get frustrated by…",
        "integratorFrustrated":[
            {
                "label":    "Lack of cooperation and commitment"
            },
            {
                "label":    "Overly assertive team members"
            },
            {
                "label":    "Conflict"
            },
            {
                "label":    "Teams who get stuck in one phase"
            }
        ],

        "integratorSubHeading":      	 "Performance Tip",
        "integratorSubText":             "Move deliberately through every step in the whole process.",

        "integratorSubTitleLot":        "Teams with LOTS of Integrators…",
        "integratorSubTitleLotText":    "You may show a high commitment towards tasks and get them accomplished with collaboration and cooperation. You move easily to the next step and attend to all aspects of a project. You plug gaps and value the other team members and their experience..",

        "integratorSubCatLot":          "Watch out:",
        "integratorSubCatLotText":      "There may be times when energy and drive are lacking. On important issues, don’t defer. Be sure to share your point of view. Allow and encourage debate. Don’t go along simply to get along..",

        "integratorSubTitleFew":         "Only a FEW Integrators…",
        "integratorSubTitleFewText":     "You may find that not everybody’s voice is being heard. There are times when the team’s relationship needs are not being managed.",

        "integratorSubCatFew":           "Tip:",
        "integratorSubCatFewText":       "Consciously check that everyone is committed to the task. Solicit everyone’s point of view. Be deliberate not to get stuck in any one part of the process.",

        "askLabelintegrator":           "<span>My team has  </span>",
        "askLabelMyselfintegrator": 	"I myself am an Integrator",
        "askinputMany": 				"MANY",
    	"askinputFew": 					"FEW",
    	"askinputNoInt": 				"NO Integrators",
    	"askinputYes": 					"Yes",
    	"askinputNoo": 					"No",
        "integratorasideTitle":          "Integrators"
    };

    communicate = {
        "heading":      	"How to communicate",
        "headingText":      "Need to communicate with people who don’t share your preferences? When working with others — both on and off your team — follow the new golden rule:",

        "imgText":      	"Here are some suggestions for communicating effectively with people according to their preferences:",
        "tHeading1": 		"Preference",
        "tHeading2": 		"They appreciate…",
        "tHeading3": 		"Tactical suggestions for communicating",
        "tHeading4": 		"What they want to know…",

        "rowclarifier": 		"Clarifier",
        "rowclarifiercol1": 	"Facts, background, data references, evidence, logic",
        "rowclarifiercol2": 	"Give a clear, sequential, linear explanation of the facts. Offer access to more information. Use outlines, timelines, grids, graphs, charts and tables",
        "rowclarifiercol3": 	"What’s the real problem to solve?",

        "rowideator": 			"Ideator",
        "rowideatorcol1": 		'The big picture, humor, stories, a clever “hook,” visuals',
        "rowideatorcol2": 		"Emphasize the big picture, big idea or compelling story. Avoid getting too bogged down by less relevant facts and details.",
        "rowideatorcol3": 		"What are the possibilities?",

        "rowdeveloper": 		"Developer",
        "rowdevelopercol1": 	"Time, precision, fair-mindedness, high level of execution",
        "rowdevelopercol2": 	"Build a structured argument. Be fair-minded and pragmatic. Identify areas that need to be refined. Synthesize your presentation with summary at the end. Don’t rush a decision.",
        "rowdevelopercol3": 	"How can we take this from good to great?",

        "rowimplementer": 		"Implementer",
        "rowimplementercol1": 	"Decisiveness, direction, speed, proof, results",
        "rowimplementercol2": 	"Be brief and clear. Explain the goal. Present the essential facts. Highlight the action steps. Show how to move this idea forward.",
        "rowimplementercol3": 	"What’s the bottom line here?",

        "rowintegrator": 			"Integrator",
        "rowintegratorcol1": 		"Commitment, movement through the process, collaboration",
        "rowintegratorcol2": 		"Clearly state the purpose. Give sufficient details. Be clear on the outcomes and timelines.",
        "rowintegratorcol3": 		"Have we heard from everyone?"

    };

    teammembers = {
        "heading":     		"Our team members",
        "headingText":      "Ultimately a team is built on individual relationships. A great team requires each person to embody self-awareness, sensitivity and process accountability. Use the worksheet below, to capture specific suggestions from your teammates on how to increase your effectiveness when you collaborate.",

        "tHeading1": 		"Name",
        "tHeading2": 		"When collaborating, <br>this works for me",
        "tHeading3": 		"This doesn't work <br>so well..",
        "tHeading4": 		"Actions",
        "btnText": 			"Update"
    };

    fsprocess = {
        "heading": 		      "The FourSight process",
        "headingcatClarify":   "Clarify",
        "catClarifylisting":[
        	{
        		"label": 	"Investigate the issue from all angles. Ask the 5W’s and an H (i.e., the Who, What, When, Where, Why and How of the situation)."
        	},
        	{
        		"label": 	"Make sure you understand the history and background information. Use the key data to pull the issue into focus. Pinpoint the best opportunity or challenge to address."
        	},
        	{
        		"label": 	"Phrase the challenge as an open-ended question that invites solutions (for example, “In what ways might we…”)."
        	}
        ],
        "giveClarifytitle": 		"Give Clarifiers...",
        "giveClarifylist": [
        	{
        		"label": 	"Order"
        	},
        	{
        		"label": 	"The facts"
        	},
        	{
        		"label": 	"An understanding of history"
        	},
        	{
        		"label": 	"Access to information"
        	},
        	{
        		"label": 	"Permission to ask questions"
        	}
        ],

        "headingcatIdeate":   "Ideate",
        "catIdeatelisting":[
            {
                "label":    "Turn off the voice of “the critic.” Let the ideas flow"
            },
            {
                "label":    "Rather than go for one perfect idea, consider lots of possibilities. (Generating lots of ideas is the best way to get a few great ones.)"
            },
            {
                "label":    "List the obvious ideas first, then stretch for unusual ones."
            },
            {
                "label":    "Go for at least 20 ideas. • Build on other ideas."
            },
            {
                "label":    "Look at the issue from a new angle: what might you substitute, combine, modify, eliminate?"
            },
            {
                "label":    'Pick a random image or object and ask yourself, “When I look at this, what ideas do I get for solving my challenge?”'
            }
        ],

        "giveIdeatetitle":       "Give Ideators...",
        "giveIdeatelist": [
            {
                "label":    "Room to be playful"
            },
            {
                "label":    "Constant stimulation"
            },
            {
                "label":    "Variety and change"
            },
            {
                "label":    "The big picture"
            }
        ],


        "headingcatDevelop":   "Develop",
        "catDeveloplisting":[
            {
                "label":    	"Say what you like about the idea."
            },
            {
                "label":    	'Raise your concerns about the idea, phrased as openended questions that invite solutions (i.e., “How might...?”).'
            },
            {
                "label":    	"Develop ways to overcome the main concerns. Develop criteria for success. Rate competing solutions against them. Modify solutions, as necessary, to better meet the criteria for success. Identify key sources that will assist and resist implementation"
            },
            {
                "label":    	"With these assisters and resisters in mind, create an action plan that details who does what by when."
            }
        ],
        "giveDeveloptitle":       "Give Developers...",
        "giveDeveloplist": [
            {
                "label":    "Time to consider the options"
            },
            {
                "label":    "Time to evaluate"
            },
            {
                "label":    "Time to develop ideas"
            }
        ],

        "headingcatImplement":   "Implement",
        "catImplementlisting":[
            {
                "label":    'Get into action, realizing that you will learn as you go. Apply the Tom Peters motto: “Test fast. Fail fast. Adjust fast.”'
            },
            {
                "label":    "Ask: What’s working well? What should we change or do differently? What are we learning?"
            },
            {
                "label":    "Keep a bias for action"
            },
            {
                "label":    "Take action on something within 24 hours."
            }
        ],
        "giveImplementtitle":       "Give Implementers...",
        "giveImplementlist": [
            {
                "label":    "The sense that others are moving just as quickly"
            },
            {
                "label":    "Control"
            },
            {
                "label":    "Timely responses to their ideas"
            }
        ]
    };

    together = {
        "heading":      	"Putting it all together",
        "headingText":      "Any team that increases its level of personal and process awareness can accomplish great things. Based on insights you have gained into your own FourSight profile and your team’s, take a moment to answer these questions:",

        "subheadingI": 			"What can I do...",
        "subCatI1": 			"… to increase my own<br> effectiveness?",
        "subCatI2": 			"… to increase my team’s <br>effectiveness?",

        "subheadingWe": 		"What can we do...",
        "subCatWe1": 			"… as a team to increase our <br>effectiveness?",
        "subCatWe2": 			"… as our team’s next steps?"
    };

    faq = {
        "heading":      "FAQs",
        "question1": 	"How does our team compare to the ideal team profile?",
        "answer1": 		"There is no ideal team profile. There are only ideal teams. Those teams understand and leverage their diversity by deliberately using all four steps of the breakthrough thinking process and applying tools and techniques that enhance their results.",

        "question2": 	"Is diversity always a good thing on a team?",
        "answer2": 		"Some psychological research has shown that diversity in teams enhances performance. As a general rule, when building a team that will endure and face complex challenges, populate it with people who have diverse talents and diverse FourSight preferences. A mix of Clarifiers, Ideators, Developers, Implementers and Integrators will better manage the full spectrum of the innovation process. Diversity will ensure a balance of perspectives and energy for a variety of team activities. However, when building a team for a specific, straightforward and short-term assignment, you may be wise to match the FourSight preferences of the team to the task at hand. For example, when choosing among a pool of qualified candidates, a team leader charged with generating new product ideas might favor Ideators. A team leader charged with carrying out a specific activity might favor Implementers.",

        "question3": 	"What do our raw scores mean?",
        "answer3": 		"FourSight doesn’t really put emphasis on the absolute numbers. What’s important is where your highs and lows are relative to each other. In a sense, each person who responds to the FourSight assessment creates a benchmark that is relevant only to him or herself.",

        "question4": 	"We’re forming a new team. Should we use FourSight to organize it?",
        "answer4": 		"FourSight is a great tool, but it should not be used as a primary criterion for selection. Follow the tried and true advice: When forming a team, first create a pool of people based on their knowledge, skills, experience and need for development. Once you have that pool, you might consider their FourSight preferences for the best possible mix. As your team gets underway, consider using the FourSight breakthrought thinking process and tools, where appropriate, to ensure that all steps of the process are given appropriate time and attention.",

        "question5": 	"Do we need to balance our team’s preferences?",
		"answer5": 		"Over time, teams are best served by a balanced approach to process. You can do that by assembling teams with balanced FourSight preferences, but that may be less practical — and even less desirable — than training the current members of your team to think about their thinking, to use tools, and to get deliberate about moving through the four steps of the breakthrough thinking process",

		"question6": 	"It seems like our whole company shares a similar profile. Should we hire people with opposite preferences?",
		"answer6": 		"There may be a reason why only people with that profile are sticking around. You may have created a work climate that values those preferences. If the current culture is causing problems for your organization, you might want to consider how to make the work environment more inviting, comfortable and rewarding for people with alternate preferences.",

		"question7": 	"Where does FourSight® come from?",
		"answer7": 		'In the early 1990s, Gerard J. Puccio, Ph.D., a professor at the International Center for Studies in Creativity, began to investigate the correlation between individual behavior and creative-problem-solving preference. Over the next ten years, Dr. Puccio worked with a team of researchers, putting his theory through rigorous testing and validation studies. The result is the FourSight Profile, an innovative way to measure people’s preferences for the essential components of the innovation process. For more great tools and techniques that improve breakthrough thinking, visit our web store: <a href="http://www.foursightonline.com">www.FourSightOnline.com</a>',

		"question8": 	"We value your feedback.",
		"answer8": 		'Contact us at  <a href="mailto:info@foursightonline.com"> info@foursightonline.com</a><br>Visit our website attend <a href="http://www.foursightonline.com"> www.foursightonline.com</a>'
    };

  constructor(public lmbda_service: LambdaFunctionService) { }

}
