import { Component, Input } from '@angular/core';
import { OrgsComponent } from '../../orgs/orgs.component';

@Component({
  selector: 'app-orgs-input-popup',
  templateUrl: './orgs-input-popup.component.html',
  styleUrls: ['./orgs-input-popup.component.scss']
})
export class OrgsInputPopupComponent {
  constructor(public orgsCompo: OrgsComponent) {}

  public close() {
    $('.orgs-modal').css('display', 'none');
  }
}
