import { Component, Input } from '@angular/core';
import { VolumePricingComponent } from '../../../store/volume-pricing/volume-pricing.component';

@Component({
  selector: 'app-pricing-schedule-popup',
  templateUrl: './pricing-schedule-popup.component.html',
  styleUrls: ['./pricing-schedule-popup.component.scss']
})
export class PricingSchedulePopupComponent {

  @Input() assessmentPrice;

  constructor(public volumePriceCompo:VolumePricingComponent) {}

  public close() {
    $('.tierDetails-popup').css('display', 'none');
  }
}
