import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SlideoutContentService } from '../../../service/slideout-content.service';
import { ChallengeSummaryService } from '../../challenge-summary.service';

@Component({
  selector: 'app-cn-print-tooltip',
  templateUrl: './cn-print-tooltip.component.html',
  styleUrls: ['./cn-print-tooltip.component.scss']
})
export class CnPrintTooltipComponent implements OnInit {

  process_type: string;
	popup_type: string;
	DivergeConvergeVal:boolean= false;
	all_data: any = [];
  //slideout variable
	slideoutGoal:any;
	slideoutData:any;
	slideoutChallenge:any;
	slideoutDivergeContent:any;
	slideoutConvergeContent:any;
  slideoutIdeas:any;
  slideoutTopIdeas:any;
  slideoutSolution:any;
  slideoutIssues:any;
  slideoutOpportunities:any;
  slideoutPluses:any;
  slideoutSolutionPrototype:any;
  slideoutStrengthen:any;
  slideoutthink:any;
  slideoutAction:any;
  slideoutInNext24:any;
  showVideo:boolean = false;


  constructor(public allChallenge: ChallengeSummaryService,public slideContent:SlideoutContentService, public router:Router) { }

  ngOnInit(): void {
    // diverge,conver,goal,data,challenge content  respectively
		this.slideoutDivergeContent = this.slideContent.divergeConvergeContent().clarify.diverge;
		this.slideoutConvergeContent = this.slideContent.divergeConvergeContent().clarify.converge;
		this.slideoutGoal = this.slideContent.divergeConvergeContent().clarify.goal;
		this.slideoutData = this.slideContent.divergeConvergeContent().clarify.data;
		this.slideoutChallenge = this.slideContent.divergeConvergeContent().clarify.challenge;
    // diverge,converge,challenge,ideas,topIdeas content respectively
		this.slideoutChallenge = this.slideContent.divergeConvergeContent().ideate.challenge;
		this.slideoutIdeas = this.slideContent.divergeConvergeContent().ideate.ideas;
		this.slideoutTopIdeas = this.slideContent.divergeConvergeContent().ideate.topIdea;

    this.slideoutSolution = this.slideContent.divergeConvergeContent().develop.solution;
		this.slideoutIssues = this.slideContent.divergeConvergeContent().develop.issues;
		this.slideoutOpportunities = this.slideContent.divergeConvergeContent().develop.opportunities;
		this.slideoutPluses = this.slideContent.divergeConvergeContent().develop.pluses;
		this.slideoutSolutionPrototype = this.slideContent.divergeConvergeContent().develop.solutionPrototype;
		this.slideoutStrengthen = this.slideContent.divergeConvergeContent().develop.strengthen;
		this.slideoutthink = this.slideContent.divergeConvergeContent().develop.newthink;
		this.slideoutAction = this.slideContent.divergeConvergeContent().implement.action;
		this.slideoutInNext24 = this.slideContent.divergeConvergeContent().implement.inNext24;

    this.slideContent.process_type.subscribe((val) => {
      this.openPopup(val);;
    })
  }


  // open slideout
  public openPopup(content,showDivCon=0){
    $('.tooltippopup').addClass('tooltippopup--active');
    this.process_type = content;
    this.popup_type = 'info';
    this.DivergeConvergeVal=false;

    var that = this;
    this.allChallenge.getChallenge().then(function (challenge) {
      console.log(challenge);
      that.all_data = challenge;
      that.all_data['thinkCon'] = JSON.parse(that.all_data['thinkCon'][0]);
    });
    if(showDivCon == 1){
      setTimeout(function(){
        jQuery('.popaccordian__row:nth-child(2) .popaccordian__rowhead').trigger( "click" );
        // console.log('clicked');
      }, 10);
      this.DivergeConvergeVal = true;

    }
  }

  //slideout button like instruction summary tools
  public popupDetail(content){
    this.popup_type = content;
  }

  activeRoute(routename: string): boolean{
    return this.router.url.indexOf(routename) > -1;
  }

  // close slideout
  public closePopup(){
    $('.tooltippopup').removeClass('tooltippopup--active');
    this.process_type = '';
    this.popup_type = '';
    this.showVideo = false;
  }

  	// slider video hide and show
	public sliderVideo(){
		this.showVideo = !this.showVideo;
	}

}
