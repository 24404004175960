import { Component, OnInit } from '@angular/core';
import {CommonDataService} from './../common-data.service';
import { environment } from './../../../environments/environment';
import { Store } from '@ngrx/store';

@Component({
  selector: "app-certificate",
  templateUrl: "./certificate.component.html",
  styleUrls: ["./certificate.component.scss"],
})
export class CertificateComponent implements OnInit {
  profiles: any;
  env: any;
  storeNgrx: any;

  constructor(public service: CommonDataService,public store:Store<any>) {}

  ngOnInit() {
    this.storeNgrx  = this.store.select<any>(
      (state) => state.profile?.account.default_profile
    );
    this.env = environment;
    this.storeNgrx.subscribe((message) => {
      this.profiles = message;
    });
  }
}
