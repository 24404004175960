import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2/dist/sweetalert2.js'
// import 'sweetalert2/src/sweetalert2.scss';
import { OrgsIndividualComponent } from './../orgs-individual/orgs-individual.component';

@Component({
  selector: 'app-org-individual-sessions',
  templateUrl: './org-individual-sessions.component.html',
  styleUrls: ['./org-individual-sessions.component.scss']
})
export class OrgIndividualSessionsComponent implements OnInit {

  id:any;
	teams:any;
	org_name:any;
	currentTeam:any;
	team_name:any;
	team_members:any;
	team_id:any;
	eTeam:any;
	modal:any;
	loading:any;
	teamName:any;
    sessions:any;
    modal1:any;
    sessionName:any;
    kind:any;
    temp:any;
    temp1:any;
    temp_name:any;
    temp_kind:any;
    temp2:any;
    temp3:any;
    teamWizard:any;
    globalGroup:any;
    teamID:any;
    unassignedTeam:any;

  	constructor(public activatedRoute:ActivatedRoute, public orgs: OrgsIndividualComponent, public http:HttpClient, public modalService:NgbModal, public route: Router) { }

  	ngOnInit() {
        this.id = this.orgs.id;
        this.org_name = this.orgs.org_name;
        console.log(this.id);
        this.http.get(`${environment.profileURL}/api/v1/organizations/`+this.id+`/training_sessions.json`).subscribe(data => {
            this.sessions = data['training_sessions']; 
            console.log(data);
        },
            error => this.showError(error)
        )
  	}
  	public openTeam(record){ 
      console.log(record);         
  		if(record.open){
          record.open = false;
        }else{
          record.open = true;
        }
  	}
  	public cancelUpdate(){
      this.eTeam  = -1;
      this.temp = this.temp1;
      this.temp_name = this.temp2;
      this.temp_kind = this.temp3;
    };



    public Open(){
        this.sessionName = '';
        this.kind = 'mindset';
        $('.session-modal').css('display','block')
        // this.modal1 = this.modalService.open(content, { windowClass: 'in' });
        this.loading = false;
    }

    public addSession(){
        if(this.sessionName && this.kind){
            this.loading = 1;
            this.http.post(`${environment.profileURL}/api/v1/organizations/`+this.id+'/training_sessions.json',{ name: this.sessionName, kind:this.kind }).subscribe(result => {
                console.log(result);
                this.loading = 2;
                this.sessions.push(result['training_session']);
                // this.modal1.close();
                $('.session-modal').css('display','none')
            },
            error => {
                this.showError(error)
                this.loading = false;
                this.sessionName = '';
            }
            )
        }
    }

    public editSession(record){
        this.temp_name = record.name;
        this.temp_kind = record.kind;
        this.temp2 = record.name;
        this.temp3 = record.kind;
        this.eTeam  = record.id;
    };

    public UpdateSession(id, index){
        this.sessions[index].name = this.temp_name;
        this.sessions[index].kind = this.temp_kind;
        console.log(index);
          this.http.put(`${environment.profileURL}/api/v1/organizations/`+this.id+'/training_sessions/'+id+'.json',{name:this.temp_name, kind:this.temp_kind}).subscribe(result => {
            console.log(result);
            this.eTeam = -1;
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.success('The Session was updated successfully!', 'Nice!');
            }, 1000);
        })
    }

    public deleteSession(id,index){
        console.log(index);
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure, you want to delete this Training Session? This cannot be undone!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            cancelButtonColor: '##D0D0D0',
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result['value'] == true) {
                jQuery('.swal2-container').addClass('hide');
                jQuery('body').removeClass('swal2-shown');
                jQuery('html').removeClass('swal2-shown');
                this.http.delete(`${environment.profileURL}/api/v1/organizations/`+this.id+'/training_sessions/'+id+'.json').subscribe(result => {
                    console.log(result);
                    this.sessions.splice(index,1);
                    setTimeout(function() {
                        this.toastr.options = {
                            closeButton: true,
                            progressBar: true,
                            timeOut: 2000
                        };
                        this.toastr.success('The Training session was deleted successfully!', 'Nice!');
                    }, 1000);
                    
                },
                err =>{
                    console.log(err);
                    setTimeout(function() {
                        this.toastr.options = {
                            closeButton: true,
                            progressBar: true,
                            timeOut: 2000
                        };
                        this.toastr.error(err, 'Sorry!');
                    }, 1000);
                })
            }else{
                jQuery('.swal2-container').addClass('hide');
                jQuery('body').removeClass('swal2-shown');
                jQuery('html').removeClass('swal2-shown');
            }
        },function(dismiss){
            jQuery('.swal2-container').addClass('hide');
            jQuery('body').removeClass('swal2-shown');
            jQuery('html').removeClass('swal2-shown');
        });
    }

    public cloneSession(id) {
        this.http.get(`${environment.profileURL}/api/v1/organizations/`+this.id+`/training_sessions/`+id+`/clone.json`).subscribe(data => {
            console.log(data);
            this.sessions.push(data['training_session']);
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.success(data['training_session'].name+' Session was created successfully.', 'Nice!');
            }, 1000);
        },
            error => this.showError(error)
        );
    }

    public showError(error){
        console.log('oops', error);
        if(error.status == 500){
            setTimeout(function() {
                this.toastr.options = {
                  closeButton: true,
                  timeOut: 2000
                };
                this.toastr.error('Something went wrong!', 'Sorry!');
            }, 1000);
        }
        else if(error.status == 422){
            setTimeout(function() {
                this.toastr.options = {
                  closeButton: true,
                  timeOut: 4000
                };
                this.toastr.error(error.error.errors, 'oops!');

            }, 1000);
        }
        else{
            setTimeout(function() {
                this.toastr.options = {
                  closeButton: true,
                  timeOut: 2000
                };
                this.toastr.error('Something went wrong!', 'oops!');
            }, 1000);
        }

    }

}

