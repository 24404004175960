import { Component, Input, OnInit } from '@angular/core';
import { environment } from './../../../../environments/environment';

@Component({
  	selector: 'app-cig-clarify',
  	templateUrl: './cig-clarify.component.html',
  	styleUrls: ['./cig-clarify.component.scss']
})
export class CigClarifyComponent implements OnInit {

  	@Input() slides;
  	@Input() foursightassets;
  	@Input() author;
  	public env;
  	constructor() { }

  	ngOnInit() {
  		this.env=environment;
  	}

}
