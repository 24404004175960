import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment'; 
import { LambdaFunctionService } from '../../../service/lambda-function.service';
import { CustomPfService } from '../custom-pf.service';
import { select, Store } from '@ngrx/store';
import { CommonDataService } from '../../common-data.service';

@Component({
  selector: 'app-interpretive-guide',
  templateUrl: './interpretive-guide.component.html',
  styleUrls: ['./interpretive-guide.component.scss']
})
export class InterpretiveGuideComponent implements OnInit {

  public env; 
  guides:any;
  main_app:boolean = false;
  public modal1:any;
  long_guide:string = '';
  guide:any;
  short_guide:string = '';
  low_preference: any = 'Low Preference';
  high_preference: any = 'Peak Preference';
  contrast: any = 'Neutral';
  clarify:any = 'Clarify';
  ideate:any = 'Ideate';
  develop:any = 'Develop';
  implement:any = 'Implement';
  prefers_to:string = 'prefers to';
  letter_format:string = 'Download Profile PDF (US Letter)';
  a4_format:string = 'Download Profile PDF (International A4)';
  image_content:string = 'Download Profile PNG';
  date_of_profile: any;
  organization_title: any;
  thinking_profile_heading: any;
  thinking_profile_message: any;
  download:any;
  share_my_profile:any;
  explain_my_profile:any;
  get_report: any;
  args:any;
  public id:any;
  public profiles:any;
  public modal:any;
  sign_up_with:any;
  selectedGuide:any = '';
  @Output() contentChange = new EventEmitter();
  @Input() pdf_language;
  download_txt:string = 'Download';
  selected_lang;
  storeNgrx:any;

  constructor(public activatedRoute:ActivatedRoute,public http:HttpClient,private modalService: NgbModal,public lmda_service:LambdaFunctionService, public customPfService: CustomPfService,public router: Router,public store:Store<any>,public service:CommonDataService ) { }

  ngOnInit(): void {
    this.storeNgrx = this.store.select<any>((state) => state.profile?.account.default_profile);
    this.env = environment;

    if(this.router?.url.includes('fs-profile')) {
      this.main_app = true;
    }

    this.service.pf_selected_language.subscribe((val)=> {
      this.selected_lang = val;
      this.storeNgrx.subscribe(message => {
        this.http.get(`${environment.profileURL}/api/v1/translations.json?language=`+ this.selected_lang).subscribe(data => {
          this.download_txt = data['download'];
            console.log(data)
        })
      });  
  });
  }

//   public getGuides(){
//     this.http.get(`${environment.assessment_admin}api/v1/interpretive_guides`).subscribe(data => {
//         console.log(data);
//         // Sort by Language name
//         this.guides = data['interpretive_guides'].sort(function(a, b){
//             return a.language_name.localeCompare(b.language_name);
//         });
//         console.log(this.guides)
//         this.long_guide = this.guides[3].short_guide;
//         console.warn(this.long_guide)
//     },
//         error => console.log('oops', error)
//     )
//     console.warn(this.guides)
//     // this.long_guide = this.guides["interpretive_guides"]
// }

// get interpretive guides
public getGuides(){
  $('.guide-modal').css('display', 'block');
  var that = this;
  this.selectedGuide= '';
  this.guide = this.pdf_language;
 
  this.http.get(`${environment.assessment_admin}api/v1/interpretive_guides`).subscribe(data => {
      console.log(data);
      // Sort by Language name
      this.guides = data['interpretive_guides'].sort(function(a, b){
          return a.language_name.localeCompare(b.language_name);
      });
      that.changeGuide();
      // this.modal1 = this.modalService.open(content, { windowClass: 'in grey' });
      $('guide-modal').css('display', 'none');
  },
      error => console.log('oops', error)
  )
}

// change interpretive guides
public changeGuide(){
  console.log(this.guides);
  for(var i=0; i<this.guides.length; i++){
    if(this.guides[i].language_code == this.guide){
      this.short_guide = this.guides[i].short_guide;
      this.long_guide = this.guides[i].long_guide;
    }
  }
}

// get guide
public shortGuide() {
  let id; 
  this.activatedRoute.params.subscribe(Params => {
    id = Params.id;
  })
  if(this.selectedGuide.includes('Quick')) {
    this.customPfService.trackActivity(id,'quick_guide_download');
  }else {
    this.customPfService.trackActivity(id,'interpretive_guide_download');
  }
}

// lambda pdf fucntion
public lambdaPDF(format, title){
  $('.loader-container').css('display','block');
  if(format == 'Letter'){
      this.letter_format = 'Generating Profile PDF...';
      this.args.font = 'Open Sans';
  }
  if(format == 'A4'){
    this.a4_format = 'Generating Profile PDF...';
    this.args.font = 'Helvetica';
  }
  var that = this;
  this.args.format = format;        
  this.args.content_type = 'pdf';
  console.log(this.args);

  var res = this.lmda_service.lambda_invoke('new-new-pdf-invoice-dev-pdf',this.args);
  // res.subscribe((output) => {
  //   let newWin = window.open(
  //     output['response'].body,
  //     '_blank' // <- This is what makes it open in a new window.
  //   );
  // })
          
}

 // To open template
//  public open(content){
//   this.modal = this.modalService.open(content, { windowClass: 'in grey' });
// }


public changeContent(content){
    console.log(this.contentChange)
    this.contentChange.emit(content);
}

// update route
public updateRoute(routePath) {
  this.router.navigate([`${routePath}`]);
}
}
