import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-orgs-team-permissions',
  templateUrl: './orgs-team-permissions.component.html',
  styleUrls: ['./orgs-team-permissions.component.scss']
})
export class OrgsTeamPermissionsComponent implements OnInit {
	@Input() teams;

  	constructor(public http: HttpClient) { }

	ngOnInit() {
		// this.membersByPage = 5;
		// this.isLoading = true;
		// this.http.get(`${environment.profileURL}/api/v1/teams/`+this.team+`/memberships.json?per_page=` + this.membersByPage).subscribe(data => {
  //           console.log(data.json());
  //           this.members = data.json().memberships;
  //           this.meta = data.json().meta;
  //           this.count = this.meta.total_count;
  //           this.isLoading = false;
  //          	this.pageLen = this.meta.total_pages;
  //          	this.curPage = 0;
  //          	this.item = [];
  //          	for(var i = 1; i<=this.pageLen;i++){
		// 	    this.item.push(i);
		// 	}
		// 	if(this.meta.total_count > this.membersByPage){
		// 		this.render( this.curPage, this.item, true );
	 //            console.log(data.json());
	 //        }  
  //       })
  //       localStorage.setItem('globalGroups','');
  	}

}
