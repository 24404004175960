import { Component, OnInit, Input } from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../environments/environment';
// import { Angulartics2Segment } from 'angulartics2';
import { Location } from '@angular/common';
import {AccessGroupService} from './../../access-group.service';

@Component({
	selector: 'app-store-product-details',
  	templateUrl: './store-product-details.component.html',
  	styleUrls: ['./store-product-details.component.scss']
})
export class StoreProductDetailsComponent implements OnInit {

	public product_data: any;
	public details: any;
	public id: any;
	public prev_Prods:Array<Object>;
	public quantity:Array<Object>;
	guide:string = '';
	assessment:string = '';
	language:string = '';
	package:string = '';
	certificate:string = '';
	toolcards:string = '';
	tools_certi:string = '';
	unbound:string = '';
	facilitation:string = '';
	Quant:number = 1;
	Cart:any;
	variants:any;
	env:any;
	variant_id:any = '';
	custom_field = [];
	public rcart:any;
	related_quant: any;
	variant_nmbr: number = 0;
	store : any;

	constructor(public accessgroup:AccessGroupService, public location: Location, public http: HttpClient, private activatedRoute: ActivatedRoute, private router: Router) { }

	ngOnInit() {
        this.store = localStorage.getItem('store');
		this.rcart = [];
		this.variant_nmbr = 0;
		this.related_quant = [];
		this.env = environment;	
		this.activatedRoute.params.subscribe((params: Params) => {
			console.log(params);
			this.id = parseInt(params.id);
			this.variant_id = '';
			this.http.get(`${environment.assessment_admin}api/v1/products/${this.id}`).subscribe(data => {
	            this.details = data['product'];
	            this.variants = data['product']['variants'];
	            if(this.variants.length == 1){
	            	this.variant_id = this.variants[0].id;
	            }
	            if(this.details.variants[0].custom_fields.length > 0){
	            	for(var i=0; i< this.details.variants[0].custom_fields.length; i++){
	            		this.custom_field[i] = '';
	            	}
	            }
	            if(this.details.related_products.length > 0){
	            	for(var j=0; j<this.details.related_products.length;j++){
	            		this.rcart[j] = 0;
	            		this.related_quant[j] = 1;
		            }
	            }
	            console.log(this.custom_field);
	            console.log(typeof(this.details['description']));
	        },
	            error => console.log('oops', error)
	        );
			console.log(this.details);
		});
		this.http.get(`${environment.assessment_admin}api/v1/carts`).subscribe(data => {
        	this.Cart = data['order']['line_items'];
        	if(data['order']['is_buyer'] == true && this.id == environment.assessment_pdt_id){
                this.router.navigate(['/custom-store/pricing']);
            }
            console.log(data);
        },
            error => {
            	console.log('oops', error);
            	if(error.status == 500){
					setTimeout(function() {
						this.toastr.options = {
						  closeButton: true,
						  timeOut: 2000
						};
						this.toastr.error('Something went wrong!', 'Sorry!');
					}, 1000);
				}
				else if(error.status == 422){
					setTimeout(function() {
						this.toastr.options = {
						  closeButton: true,
						  timeOut: 4000
						};
						if(!error.error.errors.base[0]){
                            this.toastr.error(error.error.errors, 'oops!');
                        }else{
                            this.toastr.error(error.error.errors.base[0], 'oops!');
                        }
					}, 1000);
				}else{
					setTimeout(function() {
						this.toastr.options = {
						  closeButton: true,
						  timeOut: 2000
						};
						this.toastr.error('Something went wrong!', 'oops!');
					}, 1000);
				}
			}
        );
	}

	public angularSegment(eventName,variant,details) {
		if(details && variant) {
			// this.angulartics2Segment.eventTrack(eventName, 
			// 	{ 
			// 		product_id: variant[0]?.product_id,
			// 		sku: variant[0]?.sku,
			// 		category: details['categories'][0]?.name,
			// 		name: variant[0]?.name,
			// 		price: variant[0]?.price,
			// 		image_url: variant[0]?.image_url
			// 	}
			// );
		}
	}

	public cart = function(id){
		if(this.variant_id) {
			if(typeof(this.Quant) == 'number'){
			  var Data = {
				  variant_id: parseInt(id),
				  quantity: this.Quant
			  }
			  this.http.post(`${environment.assessment_admin}api/v1/carts/add_item`,Data).subscribe(data => {
				  console.log(data);
				  this.Cart = data['order']['line_items'];
				  this.angularSegment('Product Added',this.variants[0], this.details)
				  this.router.navigate(['/custom-store/cart']);
			  },
				error => {
					console.log('oops', error);
					if(error.status == 500){
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 2000
							};
							this.toastr.error('Something went wrong!', 'Sorry!');
						}, 1000);
					}
					else if(error.status == 422){
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 4000
							};
							if(!error.error.errors.base[0]){
								this.toastr.error(error.error.errors, 'oops!');
							}else{
								this.toastr.error(error.error.errors.base[0], 'oops!');
							}
						}, 1000);
					}else{
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 2000
							};
							this.toastr.error('Something went wrong!', 'oops!');
						}, 1000);
					}
				}
			)};
		}
  	}

  	public relatedPdtToCart = function(i, id){
		if(typeof(this.related_quant[i]) == 'number'){
	  		var Data = {
	  			variant_id: parseInt(id),
	  			quantity: this.related_quant[i]
	  		}
	  		this.http.post(`${this.env.assessment_admin}api/v1/carts/add_item`,Data).subscribe(data => {            
	            this.Cart = data['order']['line_items'];
	            setTimeout(function() {
		            this.toastr.options = {
		                closeButton: true,
		                timeOut: 2000
		            };
		            this.toastr.success('Product added to the Cart!', 'Nice!');
		        }, 1000);
            	this.rcart[i] = parseInt(id);
	        },
	            error => {
	            	console.log('oops', error);
	            	if(error.status == 500){
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 4000
							};
							this.toastr.error('Something went wrong!', 'Sorry!');
						}, 1000);
					}
					else if(error.status == 422){
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 4000
							};
							if(!error.error.errors.base[0]){
                                this.toastr.error(error.error.errors, 'oops!');
                            }else{
                                this.toastr.error(error.error.errors.base[0], 'oops!');
                            }
						}, 1000);
					}else{
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 2000
							};
							this.toastr.error('Something went wrong!', 'oops!');
						}, 1000);
					}
				}
	        );
	  	}

  	}

  	public Fieldscart = function(id){
	  	let allAreFilled = true;
	  	document.getElementById("myForm").querySelectorAll("[required]").forEach(function(i) {
		    if (!allAreFilled) return;
		    if (!i['value']) allAreFilled = false;
		    if (i['type'] == 'email' && !(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(i['value']))){
		     	allAreFilled = false;
	     	}
	  	})
	  	if(allAreFilled == true){
	  		if(typeof(this.Quant) == 'number'){
	  			var field_name = {}
	  			for(var i=0; i< this.details.variants[0].custom_fields.length; i++){	  				
	  				field_name[this.details.variants[0].custom_fields[i].name] = this.custom_field[i];
            	}
			  	var Data = {
		  			variant_id: parseInt(id),
		  			quantity: this.Quant,
		  			custom_fields: field_name
		  		}
		  		this.http.post(`${environment.assessment_admin}api/v1/carts/add_item`,Data).subscribe(data => {
		  			console.log(data);
		  			this.Cart = data['order']['line_items'];
					this.angularSegment('Product Added',{ 
						product_id: this.variants[0].product_id,
						sku: this.variants[0].sku,
						category: this.details['categories'][0].name,
						name: this.variants[0].name,
						price: this.variants[0].price,
						image_url: this.variants[0].image_url
					})
		  			this.router.navigate(['/custom-store/cart']);
		        },
		            error => {
		            	console.log('oops', error);
		            	if(error.status == 500){
							setTimeout(function() {
								this.toastr.options = {
								  closeButton: true,
								  timeOut: 2000
								};
								this.toastr.error('Something went wrong!', 'Sorry!');
							}, 1000);
						}
						else if(error.status == 422){
							setTimeout(function() {
								this.toastr.options = {
								  closeButton: true,
								  timeOut: 4000
								};
								if(!error.error.errors.base[0]){
		                            this.toastr.error(error.error.errors, 'oops!');
		                        }else{
		                            this.toastr.error(error.error.errors.base[0], 'oops!');
		                        }
							}, 1000);
						}else{
							setTimeout(function() {
								this.toastr.options = {
								  closeButton: true,
								  timeOut: 2000
								};
								this.toastr.error('Something went wrong!', 'oops!');
							}, 1000);
						}
					}
		        )
		  	};
	  	}		
  	}

  	public addPdt(assessment, language, guide){
  		if(this.language && this.assessment && this.guide){
  			for(var j=0; j<this.variants.length; j++){
	  			if(this.assessment == this.variants[j].mode && this.guide == this.variants[j].interpretive_guide_mode && this.language == this.variants[j].language.name){
	                var id = this.variants[j].id;
		  			var Data = {
		                variant_id: parseInt(id),
		                quantity: this.Quant
		            }
		  			this.http.post(`${environment.assessment_admin}api/v1/carts/add_item`,Data).subscribe(data => {
			            console.log(data);
						this.angularSegment('Product Added',this.variants[0], this.details)
			            this.router.navigate(['/custom-store/cart']);
			        },
			            error => {
			            	console.log('oops', error);
			            	if(error.status == 500){
								setTimeout(function() {
									this.toastr.options = {
									  closeButton: true,
									  timeOut: 2000
									};
									this.toastr.error('Something went wrong!', 'Sorry!');
								}, 1000);
							}
							else if(error.status == 422){
								setTimeout(function() {
									this.toastr.options = {
									  closeButton: true,
									  timeOut: 4000
									};
									if(!error.error.errors.base[0]){
		                                this.toastr.error(error.error.errors, 'oops!');
		                            }else{
		                                this.toastr.error(error.error.errors.base[0], 'oops!');
		                            }
								}, 1000);
							}else{
								setTimeout(function() {
									this.toastr.options = {
									  closeButton: true,
									  timeOut: 2000
									};
									this.toastr.error('Something went wrong!', 'oops!');
								}, 1000);
							}
						}
			        );
		  		}
	  		}
  		}
  	}

  	public getAssessment(){
  		if(this.assessment == 'i-will-take-it-now'){
  			window.location.href = 'https://i.foursightonline.com/assessments/d231d6162c4e94050c8aaf9e68983af8';
  		}
  		this.language = '';
  		this.guide = '';
  	}

  	public getLanguage(){
        this.guide = '';
    }

    public updateVariation(){
    	if(this.variant_id){
    		for(var i=0; i< this.details.variants.length; i++){
    			if(this.variant_id == this.details.variants[i].id){
    				this.variant_nmbr = i;
    			}
    		}
    	}
    }

}
