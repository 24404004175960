import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { environment } from './../../../../../environments/environment';

@Component({
  selector: 'app-new-pricing-card',
  templateUrl: './new-pricing-card.component.html',
  styleUrls: ['./new-pricing-card.component.scss']
})
export class NewPricingCardComponent implements OnInit {

  	public profiles : Array<Object>;
    @Input() practitioner:any;
    public card:number;
    public current_date:number;
    public env;
    public plan_yp:boolean = false;
    first:string;
    second:string;
    third:string;
    fourth:string;
    fifth:string;
    sixth:string;
    seventh:string;
    eighth:string;

  	constructor(private activatedRoute: ActivatedRoute, private router: Router ) {
  	}

  	ngOnInit() {
        this.env = environment;

  		this.router.events.subscribe(val => {
            this.getRoutes();
        });

        // this.first = this.mindset.first;
        // this.second = this.mindset.second;
        // this.third = this.mindset.third;
        // this.fourth = this.mindset.fourth;
        // this.fifth = this.mindset.fifth;
        // this.sixth = this.mindset.sixth;
        // this.seventh = this.mindset.seventh;
        // this.eighth = this.mindset.eighth;

        for (let entry of this.practitioner) {
            if(entry.lesson == 'plan-your-practice'){
              this.plan_yp = true;
            }
        }

        // Intilization
        this.getRoutes();
        this.current_date = new Date().getTime();
  	}

  	public getRoutes(){
        if(this.activatedRoute.firstChild){
            this.activatedRoute.firstChild.params.subscribe((params: Params) => {
                this.card = params['id'];
            });
        }
    }

    public checkdate(time){
        return new Date(time).getTime();
    }

    public verify_dates(bg_time, en_time){
        if(this.env.enable_date){
            return (((this.checkdate(bg_time) > this.current_date || this.checkdate(en_time) < this.current_date) && en_time != 'infinity') || (this.checkdate(bg_time) > this.current_date && en_time == 'infinity'));
        }else{
            return false;
        }
    }

}
