// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep.faq-bottom div[class*=col-] {
  padding-bottom: 50px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/team-report/team-faq/team-faq.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF","sourcesContent":["::ng-deep.faq-bottom div[class*=\"col-\"] {\n  padding-bottom: 50px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
