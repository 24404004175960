import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../../environments/environment';
// import { Angulartics2Segment } from 'angulartics2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-public-product-details',
  templateUrl: './public-product-details.component.html',
  styleUrls: ['./public-product-details.component.scss']
})
export class PublicProductDetailsComponent implements OnInit {

	public product_data: any;
	public details: any;
	public slug: any;
	public prev_Prods:Array<Object>;
	public quantity:Array<Object>;
	guide:string = '';
	assessment:string = '';
	language:string = '';
	package:string = '';
	certificate:string = '';
	toolcards:string = '';
	tools_certi:string = '';
	unbound:string = '';
	facilitation:string = '';
	Quant:number = 1;
	Cart:any;
	variants:any;
	env:any;
	cartId:any;
	variant_id:any = ''
	custom_field = [];
	public rcart:any;
	related_quant: any;
	variant_nmbr: number = 0;
	@Output() cartChange = new EventEmitter<any>();

	constructor(public location: Location, public http: HttpClient, private activatedRoute: ActivatedRoute, private router: Router) { }

	ngOnInit() {
		window.scroll(0,0);
		this.variant_nmbr = 0;
		this.rcart = [];
		this.related_quant = [];
		this.env = environment;	
		console.log(this.router.url);
		this.slug = this.router.url.replace("/store/", "");
		this.http.get(`${environment.assessment_admin}api/v1/public/products/${this.slug}`).subscribe(data => {
            this.details = data['product'];
            this.variants = data['product']['variants'];
            if(this.variants.length == 1){
            	this.variant_id = this.variants[0].id;
            }
            if(this.details.variants[0].custom_fields.length > 0){
            	for(var i=0; i< this.details.variants[0].custom_fields.length; i++){
            		this.custom_field[i] = '';
            	}
            }
            if(this.details.related_products.length > 0){
            	for(var j=0; j<this.details.related_products.length;j++){
            		this.rcart[j] = 0;
            		this.related_quant[j] = 1;
	            }
            }
            // this.angulartics2Segment.eventTrack('Product Clicked', 
            //     { 
            //         product_id: this.variants[0].product_id,
			// 		sku: this.variants[0].sku,
			// 		category: this.details['categories'][0].name,
			// 		name: this.variants[0].name,
			// 		price: this.variants[0].price,
			// 		image_url: this.variants[0].image_url
            //     }
            // );
        },
            error => {
            	console.log('oops', error);
            	if(error.status == 500){
					setTimeout(function() {
						this.toastr.options = {
						  closeButton: true,
						  timeOut: 2000
						};
						this.toastr.error('Something went wrong!', 'Sorry!');
					}, 1000);
				}
				else if(error.status == 404){
					setTimeout(function() {
						this.toastr.options = {
						  closeButton: true,
						  timeOut: 4000
						};
                        this.toastr.error(error.error.errors, 'oops!');
					}, 1000);
					this.router.navigate(['/store']);
				}
				else if(error.status == 422){
					setTimeout(function() {
						this.toastr.options = {
						  closeButton: true,
						  timeOut: 4000
						};
						if(!error.error.errors.base[0]){
                            this.toastr.error(error.error.errors, 'oops!');
                        }else{
                            this.toastr.error(error.error.errors.base[0], 'oops!');
                        }
					}, 1000);
				}else{
					setTimeout(function() {
						this.toastr.options = {
						  closeButton: true,
						  timeOut: 2000
						};
						this.toastr.error('Something went wrong!', 'oops!');
					}, 1000);
				}
			}
        );
		if(localStorage.getItem('cart_id')){
  			this.cartId = localStorage.getItem('cart_id');
  		}
	}

	public cart = function(id){
		if(this.cartId){
			if(typeof(this.Quant) == 'number'){
		  	var Data = {
	  			variant_id: parseInt(id),
	  			quantity: this.Quant
	  		}
	  		this.http.post(`${environment.assessment_admin}api/v1/public/carts/`+this.cartId+`/add_item`,Data).subscribe(data => {
	  			console.log(data);
	  			this.cartChange.emit({data :data['order']['line_items']});
	  			// this.angulartics2Segment.eventTrack('Page', 
	            //     { 
	            //         funnel: 'Store',
	            //         product_id: id,
	            //         cta_color:'developer-green',
		        //         cta_text:'Add to cart',
		        //         cta_type:'button',
	            //         url: window.location.origin + this.location.path()
	            //     }
	            // );
	            // if(id == 73){
		        //     this.angulartics2Segment.eventTrack('Page', 
		        //         { 
		        //             funnel: 'Get Student Rates',
		        //             cta_color:'developer-green',
			    //             cta_text:'Add to cart',
			    //             cta_type:'button',
			    //             cta_location:'https://app.foursightonline.com/store',
		        //             url: window.location.origin + this.location.path()
		        //         }
		        //     );
		        // }
		        // if(id == 55 || id == 84){
		        //     this.angulartics2Segment.eventTrack('Page', 
		        //         { 
		        //             funnel: 'Get Certified MSC',
		        //             cta_color:'developer-green',
			    //             cta_text:'Add to cart',
			    //             cta_type:'button',
			    //             cta_location:'https://app.foursightonline.com/store/foursight-mindset-qualification',
		        //             url: window.location.origin + this.location.path()
		        //         }
		        //     );
		        // }
		        // if(id == 86){
		        //     this.angulartics2Segment.eventTrack('Page', 
		        //         { 
		        //             funnel: 'Get Certified TSC',
		        //             cta_color:'developer-green',
			    //             cta_text:'Add to cart',
			    //             cta_type:'button',
			    //             cta_location:'https://app.foursightonline.com/store/foursight-toolset-qualification',
		        //             url: window.location.origin + this.location.path()
		        //         }
		        //     );
		        // }
		        // this.angulartics2Segment.eventTrack('Product Added', 
	            //     { 
	            //     	cart_id: this.cartId,
	            //         product_id: this.variants[0].product_id,
				// 		sku: this.variants[0].sku,
				// 		category: this.details['categories'][0].name,
				// 		name: this.variants[0].name,
				// 		price: this.variants[0].price,
				// 		image_url: this.variants[0].image_url
	            //     }
	            // );
	  			this.router.navigate(['/store/cart']);
	        },
	            error => {
	            	console.log('oops', error);
	            	if(error.status == 500){
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 2000
							};
							this.toastr.error('Something went wrong!', 'Sorry!');
						}, 1000);
					}
					else if(error.status == 422){
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 4000
							};
							if(!error.error.errors.base[0]){
	                            this.toastr.error(error.error.errors, 'oops!');
	                        }else{
	                            this.toastr.error(error.error.errors.base[0], 'oops!');
	                        }
						}, 1000);
					}else{
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 2000
							};
							this.toastr.error('Something went wrong!', 'oops!');
						}, 1000);
					}
				}
	        )};
	  	}else{
	  		this.http.post(`${this.env.assessment_admin}api/v1/public/carts.json`).subscribe(data => {
	            this.cartId = data['order']['id'];
	            localStorage.setItem('cart_id',data['order']['id']);
	            this.cart(id);
	        },
	            error => {
	            	console.log('oops', error);
	            	if(error.status == 500){
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 2000
							};
							this.toastr.error('Something went wrong!', 'Sorry!');
						}, 1000);
					}
					else if(error.status == 422){
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 4000
							};
							if(!error.error.errors.base[0]){
	                            this.toastr.error(error.error.errors, 'oops!');
	                        }else{
	                            this.toastr.error(error.error.errors.base[0], 'oops!');
	                        }
						}, 1000);
					}else{
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 2000
							};
							this.toastr.error('Something went wrong!', 'oops!');
						}, 1000);
					}
				}
	        );
	  	}
  	}

  	public Fieldscart = function(id){
	  	let allAreFilled = true;
	  	document.getElementById("myForm").querySelectorAll("[required]").forEach(function(i) {
		    if (!allAreFilled) return;
		    if (!i['value']) allAreFilled = false;
		    if (i['type'] == 'email' && !(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(i['value']))){
		     	allAreFilled = false;
	     	}
	  	})
	  	if(allAreFilled == true){
	  		if(this.cartId){
				if(typeof(this.Quant) == 'number'){
			  	var field_name = {}
	  			for(var i=0; i< this.details.variants[0].custom_fields.length; i++){	  				
	  				field_name[this.details.variants[0].custom_fields[i].name] = this.custom_field[i];
            	}
			  	var Data = {
		  			variant_id: parseInt(id),
		  			quantity: this.Quant,
		  			custom_fields: field_name
		  		}
		  		this.http.post(`${environment.assessment_admin}api/v1/public/carts/`+this.cartId+`/add_item`,Data).subscribe(data => {
		  			console.log(data);
		  			this.cartChange.emit({data :data['order']['line_items']});
		  			console.log(id);
		  			// this.angulartics2Segment.eventTrack('Page', 
		            //     { 
		            //         funnel: 'Store',
		            //         cta_color:'developer-green',
			        //         cta_text:'Add to cart',
			        //         cta_type:'button',
		            //         product_id: id,
		            //         url: window.location.origin + this.location.path()
		            //     }
		            // );
		            // if(id == 73){
			        //     this.angulartics2Segment.eventTrack('Page', 
			        //         { 
			        //             funnel: 'Get Student Rates',
			        //             cta_color:'developer-green',
				    //             cta_text:'Add to cart',
				    //             cta_type:'button',
				    //             cta_location:'https://app.foursightonline.com/store',
			        //             url: window.location.origin + this.location.path()
			        //         }
			        //     );
			        // }
			        // if(id == 55 || id == 84){
			        //     this.angulartics2Segment.eventTrack('Page', 
			        //         { 
			        //             funnel: 'Get Certified MSC',
			        //             cta_color:'developer-green',
				    //             cta_text:'Add to cart',
				    //             cta_type:'button',
				    //             cta_location:'https://app.foursightonline.com/store/foursight-mindset-qualification',
			        //             url: window.location.origin + this.location.path()
			        //         }
			        //     );
			        // }
			        // if(id == 86){
			        //     this.angulartics2Segment.eventTrack('Page', 
			        //         { 
			        //             funnel: 'Get Certified TSC',
			        //             cta_color:'developer-green',
				    //             cta_text:'Add to cart',
				    //             cta_type:'button',
				    //             cta_location:'https://app.foursightonline.com/store/foursight-toolset-qualification',
			        //             url: window.location.origin + this.location.path()
			        //         }
			        //     );
			        // }
			        // this.angulartics2Segment.eventTrack('Product Added', 
		            //     { 
		            //     	cart_id: this.cartId,
		            //         product_id: this.variants[0].product_id,
					// 		sku: this.variants[0].sku,
					// 		category: this.details['categories'][0].name,
					// 		name: this.variants[0].name,
					// 		price: this.variants[0].price,
					// 		image_url: this.variants[0].image_url
		            //     }
		            // );
		  			this.router.navigate(['/store/cart']);
		        },
		            error => {
		            	console.log('oops', error);
		            	if(error.status == 500){
							setTimeout(function() {
								this.toastr.options = {
								  closeButton: true,
								  timeOut: 2000
								};
								this.toastr.error('Something went wrong!', 'Sorry!');
							}, 1000);
						}
						else if(error.status == 422){
							setTimeout(function() {
								this.toastr.options = {
								  closeButton: true,
								  timeOut: 4000
								};
								if(!error.error.errors.base[0]){
		                            this.toastr.error(error.error.errors, 'oops!');
		                        }else{
		                            this.toastr.error(error.error.errors.base[0], 'oops!');
		                        }
							}, 1000);
						}else{
							setTimeout(function() {
								this.toastr.options = {
								  closeButton: true,
								  timeOut: 2000
								};
								this.toastr.error('Something went wrong!', 'oops!');
							}, 1000);
						}
					}
		        )};
		  	}else{
		  		this.http.post(`${this.env.assessment_admin}api/v1/public/carts.json`).subscribe(data => {
		            this.cartId = data['order']['id'];
		            localStorage.setItem('cart_id',data['order']['id']);
		            this.cart(id);
		        },
		            error => {
		            	console.log('oops', error);
		            	if(error.status == 500){
							setTimeout(function() {
								this.toastr.options = {
								  closeButton: true,
								  timeOut: 2000
								};
								this.toastr.error('Something went wrong!', 'Sorry!');
							}, 1000);
						}
						else if(error.status == 422){
							setTimeout(function() {
								this.toastr.options = {
								  closeButton: true,
								  timeOut: 4000
								};
								if(!error.error.errors.base[0]){
		                            this.toastr.error(error.error.errors, 'oops!');
		                        }else{
		                            this.toastr.error(error.error.errors.base[0], 'oops!');
		                        }
							}, 1000);
						}else{
							setTimeout(function() {
								this.toastr.options = {
								  closeButton: true,
								  timeOut: 2000
								};
								this.toastr.error('Something went wrong!', 'oops!');
							}, 1000);
						}
					}
		        );
		  	}
	  	}		
  	}

  	public addPdt(assessment, language, guide){
  		console.log(this.cartId, language,assessment,guide);
  		if(this.cartId){
	  		if(this.language && this.assessment && this.guide){
	  			for(var j=0; j<this.variants.length; j++){
		  			if(this.assessment == this.variants[j].mode && this.guide == this.variants[j].interpretive_guide_mode && this.language == this.variants[j].language.name){
		                var id = this.variants[j].id;
			  			var Data = {
			                variant_id: parseInt(id),
			                quantity: this.Quant
			            }
			  			this.http.post(`${environment.assessment_admin}api/v1/public/carts/`+this.cartId+`/add_item`,Data).subscribe(data => {
				            this.cartChange.emit({data :data['order']['line_items']});
				            // this.angulartics2Segment.eventTrack('Page', 
				            //     { 
				            //         funnel: 'Store',
				            //         cta_color:'developer-green',
					        //         cta_text:'Add to cart',
					        //         cta_type:'button',
				            //         product_id: id,
				            //         url: window.location.origin + this.location.path()
				            //     }
				            // );

				            // this.angulartics2Segment.eventTrack('Page', 
				            //     { 
				            //         funnel: 'Group Assessment',
				            //         cta_color:'developer-green',
					        //         cta_text:'Add to cart',
					        //         cta_type:'button',
				            //         product_id: id,
				            //         url: window.location.origin + this.location.path()
				            //     }
				            // );
				            // this.angulartics2Segment.eventTrack('Product Added', 
				            //     { 
				            //     	cart_id: this.cartId,
				            //         product_id: this.variants[0].product_id,
							// 		sku: this.variants[0].sku,
							// 		category: this.details['categories'][0].name,
							// 		name: this.variants[0].name,
							// 		price: this.variants[0].price,
							// 		image_url: this.variants[0].image_url
				            //     }
				            // );
				            this.router.navigate(['/store/cart']);
				        },
				            error => {
				            	console.log('oops', error);
				            	if(error.status == 500){
									setTimeout(function() {
										this.toastr.options = {
										  closeButton: true,
										  timeOut: 2000
										};
										this.toastr.error('Something went wrong!', 'Sorry!');
									}, 1000);
								}
								else if(error.status == 422){
									setTimeout(function() {
										this.toastr.options = {
										  closeButton: true,
										  timeOut: 4000
										};
										if(!error.error.errors.base[0]){
			                                this.toastr.error(error.error.errors, 'oops!');
			                            }else{
			                                this.toastr.error(error.error.errors.base[0], 'oops!');
			                            }
									}, 1000);
								}else{
									setTimeout(function() {
										this.toastr.options = {
										  closeButton: true,
										  timeOut: 2000
										};
										this.toastr.error('Something went wrong!', 'oops!');
									}, 1000);
								}
							}
				        );
			  		}
		  		}
	  		}
	  	}else{
  			this.http.post(`${this.env.assessment_admin}api/v1/public/carts.json`,{}).subscribe(data => {
	            this.cartId = data['order']['id'];
	            localStorage.setItem('cart_id',data['order']['id']);
	            this.addPdt(assessment, language, guide);
	        },
	            error => {
	            	console.log('oops', error);
	            	if(error.status == 500){
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 2000
							};
							this.toastr.error('Something went wrong!', 'Sorry!');
						}, 1000);
					}
					else if(error.status == 422){
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 4000
							};
							if(!error.error.errors.base[0]){
	                            this.toastr.error(error.error.errors, 'oops!');
	                        }else{
	                            this.toastr.error(error.error.errors.base[0], 'oops!');
	                        }
						}, 1000);
					}else{
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 2000
							};
							this.toastr.error('Something went wrong!', 'oops!');
						}, 1000);
					}
				}
	        );
  		}
  	}

  	public relatedPdtToCart = function(i, id){
		if(typeof(this.related_quant[i]) == 'number'){
			if(this.cartId){
		  		var Data = {
		  			variant_id: parseInt(id),
		  			quantity: this.related_quant[i]
		  		}
		  		this.http.post(`${this.env.assessment_admin}api/v1/public/carts/`+this.cartId+`/add_item`,Data).subscribe(data => {            
		            this.Cart = data['order']['line_items'];
		            this.cartChange.emit({data :data['order']['line_items']});
		            this.angulartics2Segment.eventTrack('Page', 
		                { 
		                    funnel: 'Store',
		                    product_id: id,
		                    cta_color:'developer-green',
			                cta_text:'Add to cart',
			                cta_type:'button',
		                    url: window.location.origin + this.location.path()
		                }
		            );
		            setTimeout(function() {
			            this.toastr.options = {
			                closeButton: true,
			                timeOut: 2000
			            };
			            this.toastr.success('Product added to the Cart!', 'Nice!');
			        }, 1000);
	            	this.rcart[i] = parseInt(id);
		        },
		            error => {
		            	console.log('oops', error);
		            	if(error.status == 500){
							setTimeout(function() {
								this.toastr.options = {
								  closeButton: true,
								  timeOut: 4000
								};
								this.toastr.error('Something went wrong!', 'Sorry!');
							}, 1000);
						}
						else if(error.status == 422){
							setTimeout(function() {
								this.toastr.options = {
								  closeButton: true,
								  timeOut: 4000
								};
								if(!error.error.errors.base[0]){
	                                this.toastr.error(error.error.errors, 'oops!');
	                            }else{
	                                this.toastr.error(error.error.errors.base[0], 'oops!');
	                            }
							}, 1000);
						}else{
							setTimeout(function() {
								this.toastr.options = {
								  closeButton: true,
								  timeOut: 2000
								};
								this.toastr.error('Something went wrong!', 'oops!');
							}, 1000);
						}
					}
		        );
		  	}else{
		  		this.http.post(`${this.env.assessment_admin}api/v1/public/carts.json`,{}).subscribe(data => {
		            this.cartId = data['order']['id'];
		            localStorage.setItem('cart_id',data['order']['id']);
		            this.relatedPdtToCart(i, id);
		        },
		            error => {
		            	console.log('oops', error);
		            	if(error.status == 500){
							setTimeout(function() {
								this.toastr.options = {
								  closeButton: true,
								  timeOut: 2000
								};
								this.toastr.error('Something went wrong!', 'Sorry!');
							}, 1000);
						}
						else if(error.status == 422){
							setTimeout(function() {
								this.toastr.options = {
								  closeButton: true,
								  timeOut: 4000
								};
								if(!error.error.errors.base[0]){
		                            this.toastr.error(error.error.errors, 'oops!');
		                        }else{
		                            this.toastr.error(error.error.errors.base[0], 'oops!');
		                        }
							}, 1000);
						}else{
							setTimeout(function() {
								this.toastr.options = {
								  closeButton: true,
								  timeOut: 2000
								};
								this.toastr.error('Something went wrong!', 'oops!');
							}, 1000);
						}
					}
		        );
		  	}
	  	}

  	}

  	public getAssessment(){
  		if(this.assessment == 'i-will-take-it-now'){
  			window.location.href = 'https://i.foursightonline.com/self-assessment';
  		}
  		this.language = '';
  		this.guide = '';
  	}

  	public getLanguage(){
        this.guide = '';
    }

    public gotoPdt(id, slug){
    	this.router.navigate(['/store/'+slug]);
    	this.slug = slug;
    	this.variant_id = '';
    	this.http.get(`${environment.assessment_admin}api/v1/public/products/${this.slug}`).subscribe(data => {
            this.details = data['product'];
            this.variants = data['product']['variants'];
            if(this.variants.length == 1){
            	this.variant_id = this.variants[0].id;
            }
            if(this.details.variants[0].custom_fields.length > 0){
            	for(var i=0; i< this.details.variants[0].custom_fields.length; i++){
            		this.custom_field[i] = '';
            	}
            }
            if(this.details.related_products.length > 0){
            	for(var j=0; j<this.details.related_products.length;j++){
            		this.rcart[j] = 0;
            		this.related_quant[j] = 1;
	            }
            }
        },
            error => console.log('oops', error)
        );
    }

    public updateVariation(){
    	if(this.variant_id){
    		for(var i=0; i< this.details.variants.length; i++){
    			if(this.variant_id == this.details.variants[i].id){
    				this.variant_nmbr = i;
    			}
    		}
    	}
    }

}

