import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import {Router, ActivatedRoute, Params, RoutesRecognized} from '@angular/router';
import {AccessGroupService} from './../../access-group.service';
import { LmNewPricingOrganizationalService} from './../lm-new-pricing-organizational.service';
import { LmNewPricingIndependentService } from './../lm-new-pricing-independent.service';
import { LmNewPricingNonProfitService } from './../lm-new-pricing-non-profit.service';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-new-pricing-model',
  templateUrl: './new-pricing-model.component.html',
  styleUrls: ['./new-pricing-model.component.scss']
})
export class NewPricingModelComponent implements OnInit {

  	training:any;
	location:string;
	lastCard:number;
	videoCard:number;
	first:string;
	second:string;
	third:string;
	fourth:string;
	fifth:string;
	sixth:string;
	seventh:string;
	eighth:string;
	ngrxAccessGroup:any;

  constructor( public accessgroup:AccessGroupService, public router: Router, public independent: LmNewPricingIndependentService, public organizational: LmNewPricingOrganizationalService, public non_profit: LmNewPricingNonProfitService,public store:Store<any> ) { }

  	ngOnInit() {
		this.ngrxAccessGroup = this.store.select<any>((state) => state.profile.accessGroupData);
  		if(this.router.url.includes('organizational')){
  			this.ngrxAccessGroup.subscribe(message => {
	            if(message && message.length > 0){
	            	// it re-directs url to the dashboard page if access to this page is not permitted.
	                if(message['main_menu'].learner_maps.new_pricing_organizational != true){
	                    this.router.navigate(['/dashboard']);
	                    return false;
	                }
	            }

	        },
	        err => {
	            // Log errors if any
	            console.log(err);
	        })
  			this.training = this.organizational.practitioner;
  			this.location = 'new price organizational';
  			this.lastCard = 4;
  			// this.videoCard = 4;
  			// this.first = 'Before the course...';
  			// this.second = 'Pre-work videos...';
  			// this.fourth = 'During the course..';
  			// this.seventh = 'After the course...';
  			// this.eighth = 'Ongoing support...';
  		}

  		if(this.router.url.includes('independent')){
  			console.log(this.router.url);
  			// this.accessgroup.data().subscribe(message => {
	        //     if(message){
	        //     	// it re-directs url to the dashboard page if access to this page is not permitted.
	        //         if(message['main_menu'].learner_maps.new_pricing_independent != true){
	        //             this.router.navigate(['/dashboard']);
	        //             return false;
	        //         }
	        //     }

	        // },
	        // err => {
	        //     // Log errors if any
	        //     console.log(err);
	        // })
  			this.training = this.independent.practitioner;
  			this.location = 'new price independent'
  			this.lastCard = 4;
  			// this.videoCard = 17;
  			// this.first = 'Before the course...';
  			// this.second = 'Pre-work videos...';
  			// this.fourth = 'During the course..';		
  			// this.seventh = 'After the course...';
  			// this.eighth = 'Ongoing support...';
  		}

  		if(this.router.url.includes('profit')){
  			console.log(this.router.url);
  			// this.accessgroup.data().subscribe(message => {
	        //     if(message){
	        //     	// it re-directs url to the dashboard page if access to this page is not permitted.
	        //         if(message['main_menu'].learner_maps.new_pricing_non_profit != true){
	        //             this.router.navigate(['/dashboard']);
	        //             return false;
	        //         }
	        //     }

	        // },
	        // err => {
	        //     // Log errors if any
	        //     console.log(err);
	        // })
  			this.training = this.non_profit.practitioner;
  			this.location = 'new price non-profit'
  			this.lastCard = 4;
  			// this.videoCard = 17;
  			// this.first = 'Before the course...';
  			// this.second = 'Pre-work videos...';
  			// this.fourth = 'During the course..';
  			// this.seventh = 'After the course...';
  			// this.eighth = 'Ongoing support...';
  		}


  	}

}

