import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  	transform(items: any[], searchText: string): any[] {
    	if(!items) return [];
    	if(!searchText) return items;
		searchText = searchText.toLowerCase();
		return items.filter( data => {
			var fullName=data.first_name+' '+data.last_name;
      		return fullName.toLowerCase().includes(searchText);
    	});
   	}

}
