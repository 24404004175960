import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LambdaFunctionService } from './../../../../../service/lambda-function.service';
import { environment } from '../../../../../../environments/environment';
import { CigSlideshowService } from '../../../../cig/cig-slideshow.service';

@Component({
  selector: 'app-slideshow-print',
  templateUrl: './slideshow-print.component.html',
  styleUrls: ['./slideshow-print.component.scss']
})
export class SlideshowPrintComponent implements OnInit {

 @Input() allSlides;
 slides:any;
 main_app_enable:boolean = false;
 author: any;
 foursightassets:any;
 env:any;
 lm_name;


  constructor(public lmda_service:LambdaFunctionService, private router: Router,public activatedRoute:ActivatedRoute, private cigService: CigSlideshowService) { }

  ngOnInit(): void {
    var that = this;
    this.env = environment;

   this.activatedRoute.params.subscribe(params => {
    this.lm_name = params.id;
   })

    if(this.router.url.includes('fs-reveal')) {
      this.main_app_enable = true;
    }
    console.log(this.allSlides)
    this.slideaData();
  }

  public slideaData() {
    this.slides = this.cigService.slides;
    this.slides[1]["from"]= "slideshow Print"
    this.slides.forEach((sl)=> {
      delete sl["audio_link"]
    })
    this.slides.push({
        id: 29, // for image but not title
        // title: this.currentlm?.title?.S,
        // imgLink: this.currentlm?.bg?.S,
        slideBackgroundImage: "",
        subtext: '',
        profileText: '',
        active: true,
    }, {
        id: 30, // for video mp4
        // title: this.currentlm?.title?.S,
        // video_link: this.currentlm?.link?.S,
        slideBackgroundImage: "",
        // poster: this.currentlm?.bg?.S,
        subtext: '',
        profileText: '',
        active: true,
    }, {
        id: 31, // for Wistia
        // title: this.currentlm?.title?.S,
        // video_link: this.currentlm?.link?.S,
        slideBackgroundImage: "",
        subtext: '',
        profileText: '',
        active: true,
    }); 
    this.author = {
      image: "author-pic.jpg",
      smallLogo: 'foursight-grey-logo-logotype-trans-w160.png',
      logoicon: 'foursight-grey-logo-icon.png',
      logotext: 'foursight-grey-logo-text.png',
      logoFoursight: "foursight-grey-logo-logotype-trans-w846.png",
      audio: '',
      lm_audio: true,
    }
    
    this.foursightassets = {
            clarifyImage: "clarify-icon-2.png",
            titleClarify: "Clarify",
            ideateImage: "ideate-icon-2.png",
            titleIdeate: "Ideate",
            developImage: "develop-icon-2.png",
            titleDevelop: "Develop",
            implementImage: "impl-icon-2.png",
            titleImplement: "Implement"
  };
  }
}