import { Component } from '@angular/core';
import { AdminLearnerMapsListComponent } from '../../admin-learner-maps-list/admin-learner-maps-list.component';

@Component({
  selector: 'app-lmlist-type-popup',
  templateUrl: './lmlist-type-popup.component.html',
  styleUrls: ['./lmlist-type-popup.component.scss']
})
export class LmlistTypePopupComponent {

  constructor(public lmCompo: AdminLearnerMapsListComponent) {}

  public close() {
    $('.listlmtype-modal').css('display', 'none');
  }
}
