import { Component, OnInit } from '@angular/core';
import{ Router, RouterModule } from '@angular/router';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-introduction-challenge',
  templateUrl: './introduction-challenge.component.html',
  styleUrls: ['./introduction-challenge.component.scss']
})
export class IntroductionChallengeComponent implements OnInit {

  public env:any;
  constructor(public router: Router) { }
  
  ngOnInit(): void {
    this.env = environment;
  }

}
