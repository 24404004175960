import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2/dist/sweetalert2.js'
// import 'sweetalert2/src/sweetalert2.scss';
import { OrgsIndividualComponent } from '../orgs-individual/orgs-individual.component';
import { OrgIndividualSessionsComponent } from '../org-individual-sessions/org-individual-sessions.component';
import { OrgIndividualTeamsComponent } from '../org-individual-teams/org-individual-teams.component';

@Component({
  selector: 'app-org-training-session-members',
  templateUrl: './org-training-session-members.component.html',
  styleUrls: ['./org-training-session-members.component.scss']
})

export class OrgTrainingSessionMembersComponent implements OnInit {
	@Input() session;
	@Input() name;
	id:any;
	params_id:any;
	members:any;
	org_name:any;
	currentTeam:any;
	team_name:any;
	team_members:any;
	team_id:any;
	eTeam:any;
	meta:any;
	checkedAll:any;
	isLoading:any;
	modal:any;
	loading:any;
	userID:any;
	checkcount:any;
	membersByPage:any;
	globalGroup:any;
	query_string:any;
	Query_String:any;
	Query_String1:any;
	pageLen:any;
	curPage:any;
	item:any;
	first_name:any;
	last_name:any;
	group:any;
	modal1:any;
	listMembers:any;
    sessionID:any;
    addtoSession:any;
    sessionMembers:any;
    modal3:any;
    modal4:any;
    pdf_type:any;
    app_type:any;
    load:any;
    email:any;

  	constructor(private router: Router, public activatedRoute:ActivatedRoute, public http:HttpClient, public modalService:NgbModal, public orgs:OrgsIndividualComponent, public orgTeams: OrgIndividualTeamsComponent, public orgSessions: OrgIndividualSessionsComponent) { }

  	ngOnInit() {
  		this.activatedRoute.params.subscribe((params: Params) => {
            this.params_id = params.id;
            console.log(this.params_id);
        })
  		this.globalGroup = [];
  		this.checkedAll = false;
  		this.checkcount = 0;
  		this.isLoading = true;
  		this.membersByPage = 20;
  		this.query_string = environment.profileURL+`/api/v1/organizations/`+this.params_id+`/training_sessions/`+this.session+`.json?`;
  		this.Query_String = this.query_string;
        this.http.get(`${environment.profileURL}/api/v1/organizations/`+this.params_id+`/training_sessions/`+this.session+`.json?per_page=`+this.membersByPage).subscribe(data => {
        	console.log(data);
           	this.members = data['training_session'].members;
   //         	this.meta = data.meta;
           	this.isLoading = false;
   //         	this.pageLen = this.meta.total_pages;
   //         	this.curPage = 0;
   //         	this.item = [];
   //         	for(var i = 1; i<=this.pageLen;i++){
			//     this.item.push(i);
			// }
			// if(this.meta.total_count > this.membersByPage){
			// 	this.render( this.curPage, this.item, true );
	  //           console.log(data);
	  //       }          	
   //         console.log(data);
        },
            error => console.log('oops', error)
        )
        this.http.post(`${environment.profileURL}/api/v1/organizations/`+this.params_id+`/training_sessions/`+this.session+`/team_profile_data_points.json`,{organization_id:this.params_id,id:this.session}).subscribe(data => {
        	console.log(data);
        },
            error => console.log('oops', error)
        )
  	}
  	public open(content){
        this.loading = false;
        this.modal = this.modalService.open(content, { windowClass: 'in grey' });
    }
    public addTeammate(){
        if(this.userID > 0){
        	this.loading = 1;
        	this.http.post(`${environment.profileURL}/api/v1/organizations/`+this.params_id+`/training_sessions/`+this.session+`/add_members.json`,{
                member_ids: [this.userID]          
            }).subscribe(result => {
        		console.log(result);
    	        this.loading = 2;
                this.members = result['training_session'].members;
    	        this.modal.close(result);
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 2000
                    };
                    this.toastr.success('New Member was added successfully!', 'Great!');
                }, 1000);
            },response => {
              	console.log(response.data.errors);
            })
        }
    }
    
    public deleteMember(){
    	this.group = [];
    	var that = this;
    	if($('.checkedbox-'+this.session+':checked').length > 0) {
            $('.checkedbox-'+this.session+':checked').each(function(){
                  that.group.push($(this).parents('tr').attr('id'));
            });            
      	}
      	console.log(this.group);
    	Swal.fire({
	        title: "Are you sure?",
	        text: "Are you sure, you want to delete this Member? This cannot be undone!",
	        type: "warning",
	        showCancelButton: true,
	        confirmButtonColor: "#DD6B55",
	        cancelButtonColor: '##D0D0D0',
	        confirmButtonText: "Yes, delete it!"
    	}).then((result) => {
	        if (result['value'] == true) {
	        	jQuery('.swal2-container').addClass('hide');
	    		jQuery('body').removeClass('swal2-shown');
	    		jQuery('html').removeClass('swal2-shown');
	    		// this.group.forEach(list =>{
	    			this.http.post(`${environment.profileURL}/api/v1/organizations/`+this.params_id+'/training_sessions/'+this.session+'/remove_members.json',{member_ids : this.group}).subscribe(result => {
			            console.log(result);
			            for(var i=0; i<this.group.length; i++){
                            for(var j=0; j<this.members.length; j++){
                                if(this.group[i] == that.members[j].id){
                                    this.members.splice(j,1);
                                }
                                
                            }
                        }
			            setTimeout(function() {
			                this.toastr.options = {
			                    closeButton: true,
                                progressBar: true,
			                    timeOut: 2000
			                };
			                this.toastr.success('The Member was deleted successfully!', 'Nice!');
			            }, 1000);
			            
			        },
			        err =>{
		                console.log(err);
		                setTimeout(function() {
			                this.toastr.options = {
			                    closeButton: true,
                                progressBar: true,
			                    timeOut: 2000
			                };
			                this.toastr.error(err.errors, 'Sorry!');
			            }, 1000);
	            	})
	    		// })
	            
	        }else{
                jQuery('.swal2-container').addClass('hide');
                jQuery('body').removeClass('swal2-shown');
                jQuery('html').removeClass('swal2-shown');
            }
    	},function(dismiss){
    		jQuery('.swal2-container').addClass('hide');
    		jQuery('body').removeClass('swal2-shown');
    		jQuery('html').removeClass('swal2-shown');
    	});
    }
    public showGroup(content){
        var counter = 0;
        var groupMembers = [];
        this.globalGroup.forEach(list =>{
            groupMembers[counter] = list.id;
            counter++;
        });
        var that = this;
        var attrib = {kind: 'group_profile', member_ids:groupMembers, organization_id:parseInt(this.params_id)};
        this.http.post(`${environment.profileURL}/api/v1/pdfs/generate.json`,attrib).subscribe(data => {
            console.log(data);
            setTimeout(function() {
                that.app_type = 'Group Profile';
                that.pdf_type = 'profile';
                // that.modal4 = that.modalService.open(content, { windowClass: 'in grey' });
                $('.pdf-modal').css('display','block');
            }, 1000);
        },
        err =>{
            console.log(err);
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.error(err.error.message, 'Sorry!');
            }, 1000);
        })
    }

    public showThinkingProfile(content = '') {
        var counter = 0;
        var groupMembers = [];
        this.globalGroup.forEach(list =>{
            groupMembers[counter] = list.id;
            counter++;
        });
        var that = this;
        var attrib = {kind: 'multiple_profiles', member_ids:groupMembers, organization_id:parseInt(this.params_id)};
        this.http.post(`${environment.profileURL}/api/v1/pdfs/generate.json`,attrib).subscribe(data => {
            console.log(data);
            setTimeout(function() {
                that.app_type = 'Thinking Profile';
                that.pdf_type = 'profile';
                $('.pdf-modal').css('display','block')
                // that.modal4 = that.modalService.open(content, { windowClass: 'in grey' });
            }, 1000);
        },
        err =>{
            console.log(err);
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.error(err.error.message, 'Sorry!');
            }, 1000);
        })
    }

    public addAdmin(records){
    	records.forEach(list => {
            if(list.checked){
		    	this.http.post(`${environment.profileURL}/api/v1/organizations/`+this.params_id+'/add_admin.json',{ role_id: 1, identity_id: list.identity_id }).subscribe(result => {

		            console.log(result);
		            this.orgTeams.teams.forEach(item =>{
              			if(item.name == 'Admin'){
                			item.memberships_count++;

                			//Sync with Admin
                			this.orgTeams.openTeam(item);
                			var that=this;
                			setTimeout(function(){
                  				that.orgTeams.openTeam(item);
                			},100);

              			}
            		});
		            setTimeout(function() {
		                this.toastr.options = {
		                    closeButton: true,
                            progressBar: true,
		                    timeOut: 2000
		                };
		                this.toastr.success('New Admin was added successfully!', 'Great!');
		            }, 1000);

		        })
	 		}

		})
    }
    public checkedbox(){
    	this.globalGroup = [];
    	this.checkcount = 0;
    	var that = this
        console.log(this.checkcount);
        jQuery(".checkedbox-"+this.session+":checkbox:checked").each(function() {
            var value = jQuery(this).val();
            console.log(value);
        	for(var i=0;i<that.members.length;i++){
                if(that.members[i].id == value){
                    that.checkcount += 1;
                    that.globalGroup.push({
                        id: that.members[i].id,
			          	name: that.members[i].first_name + ' ' + that.members[i].last_name,
			          	email: that.members[i].email,
			          	organization: that.members[i].company,
			          	picture_url: that.members[i].picture_url,
			          	default_profile: that.members[i].default_profile
                    });
            	}
            }

        })
    }
    public checkAll(){
	    var select = jQuery('#'+this.session+':checkbox:checked').length > 0;
        if(select == true){
            jQuery('.checkedbox-'+this.session).prop('checked',true);
            this.checkedbox();
        }
        else{
            jQuery('.checkedbox-'+this.session).prop('checked',false);
            this.checkedbox();
        }
    }

    public updatePerpage(){
    	this.filter();
    }

    public isPageInRange = function( curPage, index, maxPages, pageBefore, pageAfter ) {
        if (index <= 1) {
	        // first 2 pages
	        return true;
        }
        if (index >= maxPages - 2) {
	        // last 2 pages
	        return true;
        }
        if (index >= curPage - pageBefore && index <= curPage + pageAfter) {
	        return true;
        }
	}

	public render = function( curPage, item, first ) {
        var html = '', separatorAdded = false;
        for(var i in item){
            if ( this.isPageInRange( curPage, i, this.pageLen, 2, 2 ) ) {
                html += '<li data-page="' + i + '">' + item[i] + '</li>';
                // as we added a page, we reset the separator Added
                separatorAdded = false;
            } else {
                if (!separatorAdded) {
                    // only add a separator when it wasn't added before
                    html += '<li class="separator" />';
                    separatorAdded = true;
                }
            }
        }
        var holder = document.querySelector('#holder');
        if(this.meta.total_count > this.membersByPage){  
            holder.innerHTML = html;
            if(document.querySelector('#holder>li[data-page="' + curPage + '"]') != null){document.querySelector('#holder>li[data-page="' + curPage + '"]').classList.add('active');}
            if ( first ) {
                holder.addEventListener('click', e => {
                    console.log(e.target['attributes']['data-page'].value);
                    if (!e.target['attributes']['data-page'].value) {
                        // no relevant item clicked (you could however offer expand here )
                        return;
                    }

                    curPage = parseInt(e.target['attributes']['data-page'].value);
                    var page_number = curPage+1;
                    this.isLoading = true;
                    this.http.get(`${this.query_string}&page=${page_number}`).subscribe(data => {
                    	console.log(data);
                        this.members = data['training_session'].members;
                        this.meta = data['meta'];
                        this.pageLen = this.meta.total_pages;
                        this.isLoading = false;
                        this.curPage = 0;
                        this.item = [];
                        for(var i = 1; i<=this.pageLen;i++){
                            this.item.push(i);
                        }

                    })
                    this.render( curPage, item );
                });
            }
        }else{
            holder.innerHTML = null;
        }
    }

    public filter(){
        this.members = [];
    	this.query_string = this.Query_String+'&per_page=' + this.membersByPage;
        var first_name = jQuery('.first_name').val();
        var last_name = jQuery('.last_name').val();
        var profiles = jQuery('#profiles').val();
        var role = jQuery('#role').val();
        if(first_name){
            this.query_string += '&first_name=' + first_name;
        }
        if(last_name){
            this.query_string += '&last_name=' + last_name;
        }
        if (profiles) {
            this.query_string += '&profile_type_id=' + profiles;
        }
        if (role) {
            // this.query_string += '&team_id=' + teams;
        }
        this.isLoading = true;
        console.log(this.query_string);
        this.http.get(`${this.query_string}`).subscribe(data => {
            jQuery('#'+this.session).prop('checked',false);
            this.members = data['training_session'].members;
            this.pageLen = this.meta.total_pages;
            this.curPage = 0;
            this.item = [];
            console.log(this.members);
            this.isLoading = false;
            for(var i = 1; i<=this.pageLen;i++){
                this.item.push(i);
            }
            this.render( this.curPage, this.item, true );
            console.log(data);
        },
            error => console.log('oops', error)
        )
    }

    public sorting(column,order){
        // this.Query_String=environment.profileURL+`/api/v1/teams/`+this.team+`/memberships.json?per_page=`+this.membersByPage;
        // if(column=='firstName'){
        //     if(order=='asc'){ 
        //         this.Query_String += '&first_name_order=asc';
        //         this.filter();
        //         $('.fname-asc').addClass('hide');
        //         $('.fname-desc').removeClass('hide');
        //     }
        //     else{
        //         this.Query_String += '&first_name_order=desc';
        //         this.filter();
        //         $('.fname-asc').removeClass('hide');
        //         $('.fname-desc').addClass('hide');
        //     }
        // }
        // if(column=='lastName'){
        //     if(order=='asc'){
        //         this.Query_String += '&last_name_order=asc';
        //         this.filter();
        //         $('.lname-asc').addClass('hide');
        //         $('.lname-desc').removeClass('hide');
        //     }
        //     else{
        //         this.Query_String += '&last_name_order=desc';
        //         this.filter();
        //         $('.lname-asc').removeClass('hide');
        //         $('.lname-desc').addClass('hide');
        //     }
        // }
    }
    public length1(){
        this.first_name = jQuery('.first_name').val();
        this.first_name = this.first_name.length;
        if(this.first_name >= 3){
            this.filter();
        }
    }
    public length2(){
        this.last_name = jQuery('.last_name').val();
        this.last_name = this.last_name.length;
        if(this.last_name >= 3){
            this.filter();
        }
    }
    
    public addTeamMembers(modal,records,team_id){
    	this.modal1 = this.modalService.open(modal, { windowClass: 'in grey' });
    	var counter = 0;
    	this.listMembers = [];
    	records.forEach(list =>{
			if(list.checked){
				this.listMembers[counter] = { id: list.id, email: list.email, role_id: 3, name: list.first_name+' '+list.last_name };
    			counter++;
                list.checked = false;
    		}

  			
		});
		console.log(this.listMembers);

    }

    public addSessionMember(){
        if(this.sessionID > 0){
            console.log(this.addtoSession);
            this.loading = 1;
            this.http.post(`${environment.profileURL}/api/v1/organizations/`+this.params_id+`/training_sessions/`+this.sessionID+`/add_members.json`,{
              member_ids: this.addtoSession
            }).subscribe(result => {
                console.log(result);
                for(var i=0; i< this.orgSessions.sessions.length; i++){
                    if(this.sessionID == this.orgSessions.sessions[i].id){
                        this.orgSessions.sessions[i].open = false;
                    }
                }
                this.loading = 2;
                this.modal3.close(result);
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 2000
                    };
                    this.toastr.success('Members were added successfully!', 'Great!');
                }, 1000);
            },response => {
                  console.log(response.data);
            })
        }
    }

    public addSession(records){ 
        this.sessionID = '';       
        this.loading = false;
        var counter = 0;
        this.sessionMembers = [];
        this.addtoSession = [];
        this.globalGroup.forEach(list =>{
                this.sessionMembers[counter] =  {id:list.id, name:list.name, email: list.email, default_profile: list.default_profile};
                this.addtoSession[counter] = list.id;
                counter++;

        });
        console.log(this.sessionMembers);
        $('.open-toggle.dropdown').removeClass('open');
        $('.add-session-modal').css('display','block');
        // this.modal3 = this.modalService.open(content, { windowClass: 'in grey' });
        
    }

    
    public close() {
        $('.add-session-modal').css('display', 'none');
    }

    public Delete(index,id){
        this.globalGroup.splice(index,1);
        this.sessionMembers.splice(index,1);
        this.addtoSession.splice(index,1);
        jQuery("input.checkedbox-"+this.session+"[value="+ id +"]").prop('checked',false);
        this.checkcount -= 1;
    }

    public accDownload() {
        this.modal4.close();
        this.router.navigate(['/account-downloads']);
    }

    public manageMembers() {
        if($(".open-toggle.dropdown").hasClass('open')) {
            $(".open-toggle.dropdown").removeClass("open");
        } else {
            $(".open-toggle.dropdown").addClass("open");
        }
    }

}
