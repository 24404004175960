// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .title {
  font-size: 42px;
  position: relative;
}
::ng-deep .title span {
  font-size: 19px;
  position: absolute;
}

.skid__title {
  max-width: 40% !important;
}
.skid__title span {
  display: block;
  position: static;
}`, "",{"version":3,"sources":["webpack://./src/app/components/cig/cig-cover/cig-cover.component.scss"],"names":[],"mappings":"AAAA;EAEC,eAAA;EACA,kBAAA;AAAD;AAEC;EACC,eAAA;EACA,kBAAA;AAAF;;AAIA;EACC,yBAAA;AADD;AAGC;EACC,cAAA;EACA,gBAAA;AADF","sourcesContent":["::ng-deep\n.title{\n\tfont-size: 42px;\n\tposition: relative;\n\n\tspan {\n\t\tfont-size: 19px;\n\t\tposition: absolute;\n\t}\n}\n\n.skid__title {\n\tmax-width: 40% !important;\n\n\tspan{\n\t\tdisplay: block;\n\t\tposition: static;\n\t}\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
