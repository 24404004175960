import { Component, Injector, Input } from '@angular/core';
import { TeamsComponent } from '../teams.component';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-myteams-user-advice',
  templateUrl: './myteams-user-advice.component.html',
  styleUrls: ['./myteams-user-advice.component.scss']
})
export class MyteamsUserAdviceComponent {
  showMoreLessTxt = 'More';
  @Input() teamId;
  teamAdvice;
  teamCompo;

  constructor(private injector: Injector,public router: ActivatedRoute,public http:HttpClient,private route: Router) {
    this.router.params.subscribe((params)=> {
      if(!params.id) {
        this.teamCompo = this.injector.get(TeamsComponent);
      }
    })
  }

  ngOnInit() {
    console.log(this.teamId)
    this.router.params.subscribe((params)=> {
      if(this.route.url.includes('participant/'+params.id)) {
        let url:any = this.route.url;
        url = url.replace("/teams/",'')
        url = url.replace("/participant/"+params.id,'')
        url = url.replace("/myteams/",'')
        console.log("entered in if block", url)
        this.teamId = url
      }
    })
    this.http.get(`${environment.profileURL}/api/v1/team_advices/${this.teamId}.json`).subscribe((res)=> {
      this.teamAdvice = res
    })
  }

  public showMore() {
    this.showMoreLessTxt == "More" ? this.showMoreLessTxt = "Less" : this.showMoreLessTxt = "More";
  }
}
