import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { LambdaFunctionService } from "../../../service/lambda-function.service";
import { environment } from "./../../../../environments/environment";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomPfService } from "../custom-pf.service";
import { Store } from "@ngrx/store";
import { CommonDataService } from "../../common-data.service";

@Component({
  selector: "app-custom-thinking-profile",
  templateUrl: "./custom-thinking-profile.component.html",
  styleUrls: ["./custom-thinking-profile.component.scss"],
})
export class CustomThinkingProfileComponent implements OnInit {
  public allcolumns: any;
  public profiles: any;
  public id: any;
  env;
  low_preference: any = "Low Preference";
  high_preference: any = "High Preference";
  contrast: any = "Neutral";
  clarify: any = "Clarify";
  ideate: any = "Ideate";
  develop: any = "Develop";
  implement: any = "Implement";
  prefers_to: string = "prefers to";
  letter_format: string = "Download Profile PDF (US Letter)";
  a4_format: string = "Download Profile PDF (International A4)";
  image_content: string = "Download Profile PNG";
  args: any;
  allitem: any;
  date_of_profile: any = 'Profile Date';
  organization_title: any;
  thinking_profile_heading: any;
  thinking_profile_message: any;
  download: any = 'Download';
  share_my_profile: any;
  explain_my_profile: any;
  get_report: any;
  sign_up_with: any;
  modal:boolean = false;
  downloadingPdf:boolean = false;
  selected_format:string = '';
  languages = ["English","Spanish","French","Dutch","German","Italian","Portuguese","Danish Student","Danish","Arabic","Chinese","HINDI"];
  language_short =["en","es","fr","du","de","it","pr", "dk elev", "dk", "ar", "ch", "hi"];
  pdf_lang:string = 'en';
  selected_lang:string = 'en';
  pf_description:string = '';
  pf_eqn:string = '';
  pf_title = '';
  lang_fullname:string;
  pf_email_hd = 'Profile Email:';
  guidesLang:any;
  ngrxTcwaProfile: any;
  download_txt:string = 'Download';

  constructor( public http: HttpClient, private activatedRoute: ActivatedRoute, public lmda_service: LambdaFunctionService, private modalService:NgbModal,public customPfservice:CustomPfService, public store:Store<any>,public service:CommonDataService
  ) {}

  ngOnInit(): void {
    this.ngrxTcwaProfile = this.store.select<any>((state) => state.profile.tcwaProfile)
    this.env = environment;

    this.service.pf_selected_language.subscribe((val)=> {
      this.selected_lang = val;
      this.change_language(false)
  });

    this.activatedRoute.params.subscribe((params: Params) => {
      this.id = params.id;
      this.ngrxTcwaProfile.subscribe((res) => {
          if (res != null) {
            this.profiles = res["profile"];
            this.pf_description = res['profile']['profile_description'];
            this.pf_title = res['profile']['profile_title'];
            this.pf_eqn = res['profile']['profile_equation'];
              // console.log(this.profiles)
              if (this.profiles?.prefers_to != undefined) {
                this.prefers_to = this.profiles?.prefers_to;
              }
            }
            if (res == null) {
              alert(
                "Unable to get the required info. Please contact support in the chat window below."
              );
            }

            if(res['profile']?.language?.short_name != 'en') {
              console.log('deault language-', res['profile']?.language?.short_name)
              this.selected_lang = (res['profile']?.language?.short_name).toLowerCase();
              this.lang_fullname = res['profile']?.language?.name
              console.warn(this.selected_lang)
              this.organization_title = res["organization_title"];
              this.thinking_profile_heading = res["thinking_profile_heading"];
              this.thinking_profile_message = res["thinking_profile_message"];
              // this.download = res["download"];
              this.share_my_profile = res["share_my_profile"];
              this.explain_my_profile = res["explain_my_profile"];

              if (
                this.organization_title === "" ||
                this.organization_title === undefined
              ) {
                this.organization_title = "Organization";
              }
              if (
                this.thinking_profile_heading === "" ||
                this.thinking_profile_heading === undefined
              ) {
                this.thinking_profile_heading = "FourSight® Thinking Profile";
              }
              if (
                this.thinking_profile_message === "" ||
                this.thinking_profile_message === undefined
              ) {
                this.thinking_profile_message =
                  " measures where you gain and lose energy when you try to solve a complex challenge. There are no “good” or “bad” scores. Each of the 15 FourSight Profiles has its own strengths and blind spots. The goal is not to change your thinking preferences, but to understand how they affect your decision making, your perceptions, your stress levels and your interactions with others. Self awareness helps you solve challenges more effectively.";
              }
              if (this.clarify === "" || this.clarify === undefined) {
                this.clarify = "Clarify";
              }
              if (this.ideate === "" || this.ideate === undefined) {
                this.ideate = "Ideate";
              }
              if (this.develop === "" || this.develop === undefined) {
                this.develop = "Develop";
              }
              if (this.implement === "" || this.implement === undefined) {
                this.implement = "Implement";
              }
  
              this.args = {
                id: res["profile"].id,
                content_type: "pdf",
                personalized_note: `Get personalized results at <a href="${this.env.lambda_pf_footer}/thinking-coach"> ${this.env.lambda_pf_footer}/thinking-coach</a> by signing up with the email ${res["profile"]["email"]}`,
                template: "profile.html",
                date_of_profile: this.date_of_profile,
                organization_title: this.organization_title,
                first_name: res["profile"].first_name,
                last_name: res["profile"].last_name,
                low_preference: this.low_preference,
                high_preference: this.high_preference,
                contrast: this.contrast,
                clarify: this.clarify,
                ideate: this.ideate,
                develop: this.develop,
                implement: this.implement,
                prefers_to: this.prefers_to,
                get_report: this.get_report,
                sign_up_with: this.sign_up_with,
                thinking_profile_heading: this.thinking_profile_heading,
                thinking_profile_message: this.thinking_profile_message,
                organization: res["profile"].organization_name,
                clarifier_sum: res["profile"].clarifier_sum,
                developer_sum: res["profile"].developer_sum,
                ideator_sum: res["profile"].ideator_sum,
                implementer_sum: res["profile"].implementer_sum,
                low_contrast: res["profile"].low_contrast,
                high_contrast: res["profile"].high_contrast,
                generated_at: res["profile"].generated_at,
                profile_title: res["profile"]?.profile_title,
                profile_equation: res["profile"].profile_equation,
                profile_description: res["profile"].profile_description,
                email:res["profile"].email,
              };
              // this.service.pf_selected_language.next(res['profile']?.language?.short_name)
              this.change_language()
            } else {
              // console.log(res);
              this.allcolumns = res;
              // console.log(res["profile"].id);
              this.organization_title = res["organization_title"];
              this.thinking_profile_heading = res["thinking_profile_heading"];
              this.thinking_profile_message = res["thinking_profile_message"];
              // this.download = res["download"];
              this.share_my_profile = res["share_my_profile"];
              this.explain_my_profile = res["explain_my_profile"];
              if(res["low_preference"] != undefined) {
                this.low_preference = res["low_preference"];
              }
              if(res["high_preference"] != undefined) {
                this.high_preference = res["high_preference"];
              }
              if(res["contrast"] != undefined) {
                this.contrast = res["contrast"];
              }
              this.clarify = res["stage_clarify"];
              this.ideate = res["stage_ideate"];
              this.develop = res["stage_develop"];
              this.implement = res["stage_implement"];
              this.get_report = res["get_report"];
              this.sign_up_with = res["sign_up_with"];
              console.warn(this.high_preference);
  
              if (
                this.organization_title === "" ||
                this.organization_title === undefined
              ) {
                this.organization_title = "Organization";
              }
              if (
                this.thinking_profile_heading === "" ||
                this.thinking_profile_heading === undefined
              ) {
                this.thinking_profile_heading = "FourSight® Thinking Profile";
              }
              if (
                this.thinking_profile_message === "" ||
                this.thinking_profile_message === undefined
              ) {
                this.thinking_profile_message =
                  " measures where you gain and lose energy when you try to solve a complex challenge. There are no “good” or “bad” scores. Each of the 15 FourSight Profiles has its own strengths and blind spots. The goal is not to change your thinking preferences, but to understand how they affect your decision making, your perceptions, your stress levels and your interactions with others. Self awareness helps you solve challenges more effectively.";
              }
              if (this.clarify === "" || this.clarify === undefined) {
                this.clarify = "Clarify";
              }
              if (this.ideate === "" || this.ideate === undefined) {
                this.ideate = "Ideate";
              }
              if (this.develop === "" || this.develop === undefined) {
                this.develop = "Develop";
              }
              if (this.implement === "" || this.implement === undefined) {
                this.implement = "Implement";
              }
  
              this.args = {
                id: res["profile"].id,
                content_type: "pdf",
                personalized_note: `Get personalized results at <a href="${this.env.lambda_pf_footer}/thinking-coach"> ${this.env.lambda_pf_footer}/thinking-coach</a> by signing up with the email ${res["profile"]["email"]}`,
                template: "profile.html",
                date_of_profile: this.date_of_profile,
                organization_title: this.organization_title,
                first_name: res["profile"].first_name,
                last_name: res["profile"].last_name,
                low_preference: this.low_preference,
                high_preference: this.high_preference,
                contrast: this.contrast,
                clarify: this.clarify,
                ideate: this.ideate,
                develop: this.develop,
                implement: this.implement,
                prefers_to: this.prefers_to,
                get_report: this.get_report,
                sign_up_with: this.sign_up_with,
                thinking_profile_heading: this.thinking_profile_heading,
                thinking_profile_message: this.thinking_profile_message,
                organization: res["profile"].organization_name,
                clarifier_sum: res["profile"].clarifier_sum,
                developer_sum: res["profile"].developer_sum,
                ideator_sum: res["profile"].ideator_sum,
                implementer_sum: res["profile"].implementer_sum,
                low_contrast: res["profile"].low_contrast,
                high_contrast: res["profile"].high_contrast,
                generated_at: res["profile"].generated_at,
                profile_title: res["profile"]?.profile_title,
                profile_equation: res["profile"].profile_equation,
                profile_description: res["profile"].profile_description,
                email:res["profile"].email,
              };
            }
          },
          (err) => {
            // Log errors if any
            console.log(err);
            // console.log(err?.status);
            if (err.status == 422) {
              // console.log(content)
              // this.modal = this.modalService.open('expire', { windowClass: 'in grey' });
              // alert('Your 7 day link has expired.');
              //window.location.replace(`${this.env.assessment_admin}/thinking-coach`);
            } else {
              alert(
                "Unable to get the required info. Please contact support in the chat window below."
              );
            }
          }
        );
    });
    this.profileLangList()
  }

  // To open template
  public open() {
    this.modal = true;
    $('.pdf-modal').css('display','block');
    // this.modal = this.modalService.open(content, { windowClass: "in grey" });
  }

  // get profile language from guide api
  public profileLangList() {
    this.http.get(`${environment.assessment_admin}api/v1/interpretive_guides`).subscribe(data => {
        console.log(data);
        // Sort by Language name
        this.guidesLang = data['interpretive_guides'].sort(function(a, b){
            return a.language_name.localeCompare(b.language_name);
        });
    },
        error => console.log('oops', error)
    )
  }

  // lambda pdf fucntion
  // create profile pdf or png based on type
  public async lambdaPDF(format, title,type){
    var argument = this.args;
    this.downloadingPdf = true;
    let date = new Date();
    let pdfFilename = "foursight-profile-" + this.args.first_name + '-' + this.args.last_name + '-' + date.getFullYear().toString().substr(-2) + date.getMonth() + date.getDate();

    if(type == 'pdf'){
        if(format == 'A4'){
            this.a4_format = 'Generating Profile PDF...';
            this.args.format = format;
        }else {
            this.letter_format = 'Generating Profile PDF...';
            this.args.format = format;
        }
        argument = {
            "template": "multi-profile.html",
            "format": "A4",
            "organization": "Organization",
            "content_type": "pdf",
            "filename": pdfFilename,
            "members":{
                "0": this.args
            }
        }
    }else {
        this.image_content = 'Generating Profile PNG...';
        argument = {
            "template": "multi-profile.html",
            "format": "A4",
            "organization": "Organization",
            "content_type": "png",
            "filename": pdfFilename,
            "members":{
                "0": this.args
            }
        }
    }
    console.log(this.args);
    var res;
    res = await this.lmda_service.lambda_invoke('multi-profile-pdf-dev-pdf',argument);
    // res.subscribe(output => {
    //     let newwin = window.open(
    //         output['response'].body,
    //         '_blank' // <- This is what makes it open in a new window.
    //     );

    //   });
      this.letter_format = 'Download Profile PDF (US Letter)';
      this.a4_format = 'Download Profile PDF (International A4)';
      this.image_content = 'Download Profile PNG';
      argument = null;
      this.downloadingPdf = false;
            
  } 
  
  // scrolling quick guide
  public quickGuide() {
    setTimeout(function() {
        $('html,body').animate({
            scrollTop: $('.fn-profile.section-padding').offset().top},
        'slow');
    }, 100);
  }

  // downloading profile pdf based on selected radio btn
  public profile_downloader() {
    console.log(this.selected_format);
    if(this.selected_format == 'A4') {
      this.lambdaPDF('A4', 'Download Profile PDF (International A4)','pdf');
    } else if(this.selected_format == 'letter') {
      this.lambdaPDF('Letter', 'Download Profile PDF (US Letter)','pdf')
    } else {
      this.lambdaPDF('Letter', 'Download Profile PNG','png');
    }
    
    // tracking profile download
    let id;
    this.activatedRoute.params.subscribe((Params=> {
      id = Params.id;
    }))
    this.customPfservice.trackActivity(id,'profile_download')
    
  }

  public change_language(update_select:boolean = true) {
    this.pfDesAnyLang(this.selected_lang);
    console.log('change language');
    console.log(this.pdf_lang);
    console.log(this.profiles);
    if(update_select) {
      this.service.pf_selected_language.next(this.selected_lang)
    }
    this.http.get(`${environment.profileURL}/api/v1/translations.json?language=`+ this.selected_lang).subscribe(res => {
        console.log(res)
        this.download_txt = res["download"]
        this.date_of_profile = res["date_of_profile"];
        this.organization_title = res["organization_title"];
        this.thinking_profile_heading = res["thinking_profile_heading"];
        this.thinking_profile_message = res["thinking_profile_message"];
        this.download = res["download"];
        this.share_my_profile = res["share_my_profile"];
        this.explain_my_profile = res["explain_my_profile"];
        this.pf_email_hd = res["profile_email"];
        this.prefers_to = res["prefers_to"]
        if(res["low_preference"] != undefined) {
          this.low_preference = res["low_preference"];
        }
        if(res["high_preference"] != undefined) {
          this.high_preference = res["high_preference"];
        }
        if(res["contrast"] != undefined) {
          this.contrast = res["contrast"];
        }
        this.clarify = res["clarify"];
        this.ideate = res["ideate"];
        this.develop = res["develop"];
        this.implement = res["implement"];
        this.get_report = res["get_report"];
        this.sign_up_with = res["sign_up_with"];
        console.warn(this.high_preference);

        if (this.organization_title === "" || this.organization_title === undefined) {
          this.organization_title = "Organization";
        }
        if (this.thinking_profile_heading === "" || this.thinking_profile_heading === undefined) {
          this.thinking_profile_heading = "FourSight® Thinking Profile";
        }
        if (this.thinking_profile_message === "" || this.thinking_profile_message === undefined) {
          this.thinking_profile_message =
            " measures where you gain and lose energy when you try to solve a complex challenge. There are no “good” or “bad” scores. Each of the 15 FourSight Profiles has its own strengths and blind spots. The goal is not to change your thinking preferences, but to understand how they affect your decision making, your perceptions, your stress levels and your interactions with others. Self awareness helps you solve challenges more effectively.";
        }
        if (this.clarify === "" || this.clarify === undefined) {
          this.clarify = "Clarify";
        }
        if (this.ideate === "" || this.ideate === undefined) {
          this.ideate = "Ideate";
        }
        if (this.develop === "" || this.develop === undefined) {
          this.develop = "Develop";
        }
        if (this.implement === "" || this.implement === undefined) {
          this.implement = "Implement";
        }
    });
  } 

   // get profile profile description for other languages
   public pfDesAnyLang(lang_code) {
    // fetch collaboration data
    console.log(lang_code)
   this.http.get(`${environment.profileURL}/api/v1/profile_types.json`,{params:{profile:"custom",language:lang_code}}).subscribe(res => {
       for(let i = 0;i< res["profile_types"].length; i++) {
           if(this.profiles?.profile_type_id === res["profile_types"][i]["id"]) {
               this.pf_description = res["profile_types"][i]["individual_description"];
               this.pf_eqn = res["profile_types"][i]["profile_equation"];
               this.pf_title = res["profile_types"][i]["profile_title"];
               break;
           }
       }
   })
}

 // change pdf language in selected lang
 public async changePdflang() {
  console.log('running...')
   await this.http.get(`${environment.profileURL}/api/v1/translations.json?language=`+ this.pdf_lang).subscribe(res => {
     console.log(res)
     var pf_desc = '';
     var pf_eqn =  '';
     var pf_title = '';
     this.download_txt = res['download'];
     
     this.http.get(`${environment.profileURL}/api/v1/profile_types.json`,{params:{profile:"custom",language:this.pdf_lang}}).subscribe(collab_res => {
       console.warn(collab_res["profile_types"]);
      console.warn('you' + this.profiles["profile_title"]);
      for(let i = 0;i< collab_res["profile_types"].length; i++) {
          if(this.profiles["profile_title"] === collab_res["profile_types"][i]["type"]) {
              console.log(collab_res["profile_types"][i]["individual_description"]);
              pf_desc = collab_res["profile_types"][i]["individual_description"];
              pf_eqn = collab_res["profile_types"][i]["profile_equation"];
              pf_title = collab_res["profile_types"][i]["profile_title"];
            }
          }
          console.log(res["date_of_profile"])
          this.args = {
            // id: res["profile"].id,
            content_type: "pdf",
            personalized_note: `Get personalized results at <a href="${this.env.lambda_pf_footer}/thinking-coach"> ${this.env.lambda_pf_footer}/thinking-coach</a> by signing up with the email ${this.profiles["email"]}`,
            template: "profile.html",
            date_title: res['date_of_profile'],
            email_title: res["profile_email"],
            email: this.profiles.email,
            date_of_profile: res["date_of_profile"],
            organization_title: res["organization_title"],
            
            low_preference: res["low_preference"],
            high_preference: res["high_preference"],
            contrast: res["contrast"],
            clarify: res["clarify"],
            ideate: res["ideate"],
            develop: res["develop"],
            implement: res["implement"],
            prefers_to: res["prefers_to"],
            get_report: res["get_report"],
            sign_up_with: res["sign_up_with"],
            thinking_profile_heading: res["thinking_profile_heading"],
            thinking_profile_message: res["thinking_profile_message"],
            first_name: this.profiles?.first_name,
            last_name: this.profiles?.last_name,
            organization: this.profiles?.organization_name,
            clarifier_sum: this.profiles?.clarifier_sum,
            developer_sum: this.profiles?.developer_sum,
            ideator_sum: this.profiles?.ideator_sum,
            implementer_sum: this.profiles?.implementer_sum,
            low_contrast: this.profiles?.low_contrast,
            high_contrast: this.profiles?.high_contrast,
            generated_at: this.profiles?.generated_at,
            profile_title: pf_title,
            profile_equation: pf_eqn,
            profile_description: pf_desc,
            profile_email:res['profile_email']
          };
    });
    
  });
  
}

}
