// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.above-side-nav .tclogo {
  margin-bottom: 10px;
}
.above-side-nav .challenge-text {
  padding-right: 12px;
  margin-top: -5px;
}

.cpf-pfmenu {
  display: none !important;
}
.cpf-pfmenu__visible {
  display: block !important;
}

.slider-nav li {
  padding-left: 20px;
}

.fs-rvl__item {
  display: none;
}
.fs-rvl__item--vis {
  display: block;
}

.tpf-db.active .fa.arrow:before {
  content: "\\f107";
}`, "",{"version":3,"sources":["webpack://./src/app/components/custom-profile/custom-navigation/custom-navigation.component.scss"],"names":[],"mappings":"AAEI;EACI,mBAAA;AADR;AAII;EACI,mBAAA;EACA,gBAAA;AAFR;;AAMA;EACI,wBAAA;AAHJ;AAKI;EACI,yBAAA;AAHR;;AAQI;EACI,kBAAA;AALR;;AAWI;EACI,aAAA;AARR;AAUQ;EACI,cAAA;AARZ;;AAkBY;EACI,gBAAA;AAfhB","sourcesContent":[".above-side-nav {\n    \n    .tclogo {\n        margin-bottom: 10px;\n    }\n\n    .challenge-text {\n        padding-right: 12px;\n        margin-top: -5px;\n    }\n}\n\n.cpf-pfmenu {\n    display: none !important;\n\n    &__visible {\n        display: block !important;\n    }\n}\n\n.slider-nav {\n    li {\n        padding-left: 20px;\n    }\n}\n\n.fs-rvl {\n    \n    &__item {\n        display: none;\n\n        &--vis {\n            display: block;\n        }\n    }\n}\n\n.tpf-db {\n\n    &.active {\n\n        .fa.arrow {\n            &:before {\n                content: '\\f107';\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
