import { Component, OnInit } from '@angular/core';
import { environment } from './../../../../environments/environment';

@Component({
	selector: 'app-toolcards-action-steps',
  	templateUrl: './toolcards-action-steps.component.html',
  	styleUrls: ['./toolcards-action-steps.component.scss']
})
export class ToolcardsActionStepsComponent implements OnInit {
	public env;

  	constructor() { }

  	ngOnInit() {
  		this.env=environment;
  	}

}
