import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {LmTsService} from './../../lm-ts.service';

@Component({
    selector: 'app-ts-card-single',
    templateUrl: './ts-card-single.component.html',
    styleUrls: ['./ts-card-single.component.scss']
})
export class TsCardSingleComponent implements OnInit {
	public training: any;
    public instanceID:number;
    public frameheight:number;
    public currentDate:any;
    public instanceDate:any;
    public newCurrentDate:any;
    prevID:number;
    nextID:number;
    nextInstance:number;
    previousInstance:number;
    lastCard:number = 13;
	constructor(private activatedRoute: ActivatedRoute, Service: LmTsService) { 
  	this.training=Service.practitioner;
  }

  	ngOnInit() {
  		this.activatedRoute.params.subscribe((params: Params) => {
            this.instanceID = params['id'];
            this.prevID = this.instanceID;
            this.nextID = this.instanceID;
            this.prevID = --this.prevID;
            this.nextID = ++this.nextID;

            if(this.instanceID >= 1 && this.instanceID <= 14){
                this.nextInstance = this.instanceID;
                this.previousInstance = this.instanceID;
                this.nextInstance = ++this.nextInstance;
                this.previousInstance = --this.previousInstance;
            }else{
                this.nextInstance = 1;
                this.previousInstance = 1;
            }
            this.currentDate = new Date();            
            var mm = this.currentDate.getMonth()+1;
			var dd = this.currentDate.getDate();			
			var yyyy = this.currentDate.getFullYear();
			if(dd<10){
			    dd='0'+dd;
			} 
			if(mm<10){
			    mm='0'+mm;
			} 
			this.currentDate = mm+'/'+dd+'/'+yyyy;
			var date1 = this.training[this.instanceID].date;
			this.instanceDate = new Date(date1).getTime();
			this.newCurrentDate = new Date(this.currentDate).getTime();
            jQuery(document).ready(function(){
	        	$('html,body').animate({
	        	    scrollTop: $('.page-heading').offset().top},100);
	    	});
        });
        
  	}
  	public scrollDiv = function(id){
        $('html,body').animate({
            scrollTop: $("#"+id).offset().top},
    'slow');
    };

}
