import { Component, OnInit } from '@angular/core';
// import gql from 'graphql-tag';
import { APIService } from '../../../API.service';
import {AccessGroupService} from './../../access-group.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
// import { Apollo } from 'apollo-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { DatepickerOptions } from 'ng2-datepicker';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

declare var jQuery: any;

@Component({
  selector: 'app-dynamic-learner-map',
  templateUrl: './dynamic-learner-map.component.html',
  styleUrls: ['./dynamic-learner-map.component.scss']
})
export class DynamicLearnerMapComponent implements OnInit {

  public currentLM_id: string;
  public currentLM: any;
  public allcolumns:any;
  public learner_map: string;
  public child: string = '';
  email:string = localStorage.getItem('email');
  designView: boolean = false;
  lm_maptype:string = '';

  constructor(private accessgroup: AccessGroupService, private api: APIService, private sanitizer: DomSanitizer, private activatedRoute: ActivatedRoute, private modalService: NgbModal, private router: Router) { }

    ngOnInit() { 
      localStorage.setItem('appNoli','false');   
      var that = this;
        
      if(this.activatedRoute.firstChild){
        this.activatedRoute.firstChild.params.subscribe((params: Params) => {
              this.child = params['id'];
        }); 
      }
        this.activatedRoute.params.subscribe((params: Params) => {
          this.currentLM = '';
          if(this.currentLM_id != params['id']){
              this.currentLM_id = params['id'];
              var that = this;
          this.api.GetLearnerMap(this.currentLM_id).then(function (map) {
            console.warn(map);
            that.lm_maptype = map?.maptype['title'];
                      if(map?.owners?.includes(localStorage.getItem('email'))){
                          that.designView = true;
                      }
            that.learner_map = '';
            let removeHash = map['title'];
            for(let i = 0; i< removeHash.length; i++) {
              if(removeHash[i] !== '#') {
                that.learner_map += removeHash[i];
              }
              if(removeHash[i] === '#') {
                that.learner_map += ' ';
              }

            }
            that.currentLM = map;
            that.allcolumns = that.currentLM.columns.items;  
          })
        }
      });
      
    }

  
}
