import { Component, OnInit, Input } from '@angular/core';
import { TeamReportServiceService } from './../team-report-service.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-team-cover',
  templateUrl: './team-cover.component.html',
  styleUrls: ['./team-cover.component.scss']
})
export class TeamCoverComponent implements OnInit {
	cover:any;
	env:any;
	@Input() members:any;
	CurrentDate:any;

  	constructor(public service: TeamReportServiceService) { }

  	ngOnInit() {
  		this.cover = this.service.cover;
  		this.env = environment;
  		this.CurrentDate = new Date();
  	}

}
