import { Component, OnInit } from '@angular/core';
import { TeamReportServiceService } from './../team-report-service.service';

@Component({
  selector: 'app-team-footer',
  templateUrl: './team-footer.component.html',
  styleUrls: ['./team-footer.component.scss']
})
export class TeamFooterComponent implements OnInit {
	fourpreferences:any;
	CurrentDate:any;

  constructor(public service: TeamReportServiceService) { }

  ngOnInit() {
  	this.fourpreferences = this.service.fourpreferences;
  	this.CurrentDate = new Date().getFullYear();
  }

}
