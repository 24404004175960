import { Component, Input, OnInit } from '@angular/core';
import { environment } from './../../../../environments/environment';

@Component({
	selector: 'app-cig-the-universal-process-implement',
	templateUrl: './cig-the-universal-process-implement.component.html',
	styleUrls: ['./cig-the-universal-process-implement.component.scss']
})
export class CigTheUniversalProcessImplementComponent implements OnInit {
	@Input() slides;
	@Input() foursightassets;
	@Input() author;
	public env;
	constructor() { }

	ngOnInit() {
		this.env=environment;
	}

}
