import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Auth } from './auth.service';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, retryWhen, tap } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    org_header:any;

    constructor(public auth: Auth, public router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {  
        // this.org_header = localStorage.getItem('email');    
        this.org_header = localStorage.getItem('auth_id'); 


        let req_url = request.url;
        let staging;
        let production;
        let api_base = ["api/v1/hubspot","api/v1/interpretive_guides", "api/v1/profiles/", "api/v1/public","api/v1/lambda_functions/invoke.json","api/v1/profile_types.json","api/v1/translations"];
        let params = [`profile=custom`,`activity_name=app_sign_up`,`activity_name=profile_download`,`activity_name=profile_slide_map_start`,`activity_name=profile_slide_map_finish`,`activity_name=interpretive_guide_download`,`activity_name=quick_guide_download`,`activity_name=thinking_coach_visited`,`activity_name=collaboration_profile_viewed`,`activity_name=social_share`]

        // Check haystack and needle
        let staging_or_dev_env = req_url.includes("staging") || req_url.includes('localhost');
        if(staging_or_dev_env){
            if(req_url.includes("api/v1/profiles/")){
                // staging = api_base.some(i => req_url.includes("staging") && req_url.includes(i) && request.urlWithParams.includes(`profile=custom`));รท
                staging = api_base.some(i => staging_or_dev_env && req_url.includes(i) && params.some(j => request.urlWithParams.includes(j)))
            }else {
                staging = api_base.some(i => staging_or_dev_env && req_url.includes(i));
            }
        }else {
            if(req_url.includes("api/v1/profiles/")){
                // production = api_base.some(i => req_url.includes("i.foursightonline.com") && req_url.includes(i) && request.urlWithParams.includes(`profile=custom`));
                production = api_base.some(i => req_url.includes("i.foursightonline.com") && req_url.includes(i) && params.some(j => request.urlWithParams.includes(j)))
            }else {
                production = api_base.some(i => req_url.includes("i.foursightonline.com") && req_url.includes(i));
            }
        }

        // Setup Authorization headers for Env based request
        if(staging == true){
            request = request.clone({
                setHeaders: {
                    Authorization: `19b9cb5e3341406e8803e9ff16ac5c1ef7df714fc5e446f6df97ad9900b68c02796fda15efd24199c88a694010a5660733a3a9472efef1f21dabb67b64637fef`
                }
            });
        }else if(production == true) {
            request = request.clone({
                setHeaders: {
                    Authorization: `3163d9266d21a6cdcd4d46031959cd043a990541b114728c4fc202caf7c6dc29c4e2c74c4fee23473d254ada84adabcbdece7c2fdf294bf6438b4d80374c32b5`
                }
            });
        }else{
            if(localStorage.getItem('impersonation') == 'true' && !request.urlWithParams.includes(`profile=custom`)){
                request = request.clone({
                    setHeaders: {
                        Authorization: `${this.auth.getToken()}`,
                        Impersonate: 'true'
                    }
                });
            }
            if(localStorage.getItem('impersonation') == 'false' && !request.urlWithParams.includes(`profile=custom`)){
                request = request.clone({
                    setHeaders: {
                        Authorization: `${this.auth.getToken()}`
                    }
                });
            }
        }

        // Detect authorized requests 
        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                console.log(err.status)
                if (err.status === 401) {
                    this.router.navigate(['logout']);
                }
                return throwError(err);                        
            })
        );
    }
}