import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
// import { CommonDataService } from 'src/app/components/common-data.service';
import { CommonDataService } from '../../components/common-data.service';
import * as actionType from '../action/fs.action';
// import { AccessGroupService } from 'src/app/components/access-group.service';
import { AccessGroupService } from '../../components/access-group.service';
// import { CustomPfService } from 'src/app/components/custom-profile/custom-pf.service';
import { CustomPfService } from '../../components/custom-profile/custom-pf.service';

@Injectable()
export class ProfileEffects {

  // get default profile data
  getPosts$ = createEffect(() =>
    this.actions$?.pipe(
      ofType(actionType.getProfile),
      mergeMap(() => {
        return this.profileService.getProfile(true).pipe(
          map((profile:any) => actionType.getProfileSuccess(profile)),
          catchError((error) =>
            of(actionType.getProfileFailure({ error: error }))
          )
        );
      })
    )
  );

  // get default profile data
  getTcwaProfile$ = createEffect(() =>
    this.actions$?.pipe(
      ofType(actionType.getTcwaProfile),
      mergeMap(() => {
        return this.customPfService.getCustomPfData().pipe(
          map((profile) => actionType.getTcwaProfileSuccess(profile)),
          catchError((error) =>
            of(actionType.getTcwaProfileFailure({ error: error.message }))
          )
        );
      })
    )
  );

// get access group data 
  getAgd$ = createEffect(() =>
    this.actions$?.pipe(
      ofType(actionType.getAccessGroup),
      mergeMap(() => {
        return this.accessGroup.data().pipe(
          map((data) => actionType.getAccessGroupSuccess(data)),
          catchError((error) =>
            of(actionType.getAccessGroupFailure({ error: error.message }))
          )
        );
      })
    )
  );

  constructor(private actions$: Actions, private profileService: CommonDataService,public accessGroup:AccessGroupService,public customPfService: CustomPfService) {}
}