import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Auth } from '../../auth/auth.service';
import { Location } from '@angular/common'
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router, Params } from '@angular/router';
import {CommonDataService} from './../common-data.service';
import { environment } from './../../../environments/environment';
import { detectBody } from '../../app.helpers';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LmTsService } from '../learner-maps/lm-ts.service';
import { APIService } from '../../API.service';
import { Subject } from 'rxjs';
import { LambdaFunctionService } from '../../service/lambda-function.service';
import { CustomPfService } from './custom-pf.service';
import { waitForAsync } from '@angular/core/testing';
import { Store } from '@ngrx/store';
import * as fsProfileActions from '../../store/action/fs.action';

declare var jQuery: any;
// declare var hbspt: any;

@Component({
  selector: 'app-custom-profile',
  templateUrl: './custom-profile.component.html',
  styleUrls: ['./custom-profile.component.scss'],
  host: {
        '(window:resize)': 'onResize()'
    }
})
export class CustomProfileComponent implements OnInit {
	

  	public profiles:any;
  	public slides:Object[]
    public modal:any;
  	public author:Object;
  	public profileLabel:Object[];
  	public foursightassets:Object;
    public env;
    public currentSlide: number = 1;
    public textSlide: boolean = false;
    public id:any;
    public showContent: string = 'Profile Slideshow';
    public msEmail: string;
    public page_content: string = 'Profile Slideshow';
    hbspt:any;
    audio_mute:boolean = false;
    low_preference: any = 'Low Preference';
    high_preference: any = 'Peak Preference';
    contrast: any = 'Neutral';
    clarify:any = 'Clarify';
    ideate:any = 'Ideate';
    develop:any = 'Develop';
    implement:any = 'Implement';
    prefers_to:string = 'prefers to';
    letter_format:string = 'Download Profile PDF (US Letter)';
    a4_format:string = 'Download Profile PDF (International A4)';
    image_content:string = 'Download Profile PNG';

    getProfile:boolean = false;
    public profileId:number;
    date_of_profile: any;
    organization_title: any;
    thinking_profile_heading: any;
    thinking_profile_message: any;
    download:any;
    share_my_profile:any;
    explain_my_profile:any;
    get_report: any;
    args:any;
    sign_up_with:any;
    public member_id:string;
    brand_identity:any;
    public oldValue;
    public Profile;
    public allCols:any;
    public currentLM_id:any = '3f2dae98-c406-4f8e-801c-4f3196620bed';
    public currentLM:any = '';
    public allcolumns:any;
    public currentCard_id:any='db0b7870-484b-421d-a2d2-2f5b78076209';
    previousCard:string;
    nextColumn:string;
    previousColumn:string;
    nextTitle:string;
    previousTitle:string;
    nextCard:string;
    currentCard = new Subject();
    currentCard1;
    counter:number;
    public modal1:any;
    guide:any;
    guides:any;
    sorted_item:any = [];
    profile_data:any;
    allitem:any = [];
    slideshows_obj = [];
    long_guide:string;
    short_guide:string;
    selectedGuide:any = '';
    // @ViewChild('expire') ngTemplate;
    ngrxTcwaProfile: any;

  	constructor( private viewContainerRef: ViewContainerRef, public activatedRoute:ActivatedRoute, private location:Location, public http: HttpClient, public service:CommonDataService, public router: Router,private modalService:NgbModal,public lm_ts: LmTsService, private api: APIService,public lmda_service:LambdaFunctionService, public customPfService: CustomPfService,public store:Store<any> ) { }
    admin_upload:string='https://assets.foursightonline.com/admin-uploads/docs/';

  	
  	cancel() {
    	this.location.back(); // <-- go back to previous location on cancel
  	}

    ngOnDestroy() {
        localStorage.setItem('appNoli','false');
        document.title = "App | FourSight";
    }
	
	async ngOnInit() {
        this.ngrxTcwaProfile  = this.store.select<any>((state) => state.profile.tcwaProfile);
        document.title = "Thinking Coach | FourSight";
        localStorage.setItem('appNoli','true');
        $('.loader-container').css('display','flex');
        localStorage.setItem('collaboration-pf','false');
        // dispatch store action
        this.store.dispatch(fsProfileActions.getTcwaProfile())


        // old slidshow route replace with new route
        let url:any = this.router.url;
        if(url.includes('slideshow')) {
            url = url.replace('slideshow', 'thinking-coach');
            console.log(url)
            this.location.replaceState(url)
        }

        detectBody();
        this.env = environment;
        var that = this;

        this.lm_ts.an_slide_no.subscribe(val =>{
            this.counter = val;
            this.currentCard.next(this.sorted_item[this.counter])
        })

        // await this.lambda_lmdata();
    
        this.currentCard.subscribe(val => {
            this.currentCard1 = val 
            // console.log(this.currentCard1);
          });

        await this.activatedRoute.params.subscribe((params: Params) => {
              this.id = params.id;
              var data;

              // tracking thinking_coach_visited
              this.customPfService.trackActivity(this.id,'thinking_coach_visited');
            //   data = that.customPfService.getCustomPfData();
              this.ngrxTcwaProfile.subscribe((res) => {
                this.profiles = res['profile'];
                console.warn(res)


                if(res?.status == 422) {
                    // this.modal = this.modalService.open(this.ngTemplate, { windowClass: 'in grey' });
                    $('.pf-expire-modal').css('display','block');
                    setTimeout(() =>{
                        $('.modal.grey').css('pointer-events', 'none');
                        $('.modal-content').css('pointer-events', 'all');
                        $('.loader-container').css('display','none');
                    },100)
                }

                // get all slideshow items
                for(let i in this.profiles?.access_groups_json?.main_menu){
                    if(i.includes('dlm_')) {
                        this.slideshows_obj.push(this.profiles?.access_groups_json?.main_menu[i])
                    }
                }
                if(this.slideshows_obj.length > 0) {
                    this.args = {
                        "lm_id": this.slideshows_obj[0]?.value,
                        // "lm_id": '3f2dae98-c406-4f8e-801c-4f3196620bed',
                        // 243238d8-6af2-4201-914a-aa129060013c
                    }
                    this.customPfService.active_lmsd_id.next(this.slideshows_obj[0].value);
                }

                if(this.profiles?.access_groups_json?.main_menu?.profile_slideshow?.lmproperties === true) {
                    this.customPfService.active_lmsd_id.next(this.profiles?.access_groups_json?.main_menu?.profile_slideshow?.value);
                }

                // move to dashboard when slideshow not available
                if(this.profiles?.access_groups_json?.main_menu?.profile_slideshow?.lmproperties != true && this.profiles?.access_groups_json?.main_menu != undefined || this.profiles === '' || this.profiles == null) {
                    this.showProfile("Dashboard");
                    // setTimeout(() => {
                    //     $('.loader-container').css('display','none');
                    // }, 1200);
                } else {
                    this.lambda_lmdata();
                    this.showProfile("Profile Slideshow",false);
                    // setTimeout(() => {
                    //     $('.cpf-pfmenu').addClass('cpf-pfmenu__visible');
                    //     $('.loader-container').css('display','none');
                    // }, 1200);
                }
                
              }), err => {
                  // Log errors if any
	            console.log(err);
	            // console.log(err?.status);
                if(err.status == 422) {
                        // console.log(content)
                        // this.modal = this.modalService.open('expire', { windowClass: 'in grey' });
                        $('.pf-expire-modal').css('display','block');
                    // alert('Your 7 day link has expired.');
                    //window.location.replace(`${this.env.assessment_admin}/thinking-coach`);
                }else {
                    alert('Unable to get the required info. Please contact support in the chat window below.');
                }
            }
        })

		// this.sliderCall();

        this.currentCard.subscribe((res)=>{
            // console.log(res)
        });

        this.customPfService.active_lmsd_id.subscribe(val=> {
            // $('.loader-container').css('display','flex');
            if(val !== '') {
                // console.log(val);
                this.args = {
                    lm_id : val
                }
                console.log('args are:',this.args)
                this.sorted_item = [];
                let that = this; 
                // if(that.allitem.length ==0) {
                //     // alert(that.allitem.length)
                //     // this.lambda_lmdata();
                // }
            }
        })
  	}

    public printProfile(){
        $('.navbar-default').hide();
        $('body').addClass('print-body');
        window.print();
        $('.navbar-default').show();
        $('body').removeClass('print-body');
    }

    public showProfile(content,callLambda:boolean=true){
        console.warn('showProfile',content)
    	this.showContent = content;
    	this.page_content = content;
        if(content == 'Profile Slideshow' && callLambda) {
            this.lambda_lmdata();
        }
        // localStorage.setItem('appNoli','false');
    	// this.ngOnInit();
    }

    public sliderCall(){
        localStorage.setItem('appNoli','true');
        this.currentCard.subscribe(val => {
            // console.warn(val)
            this.currentCard1 = val 
            // console.log(this.currentCard1);
          });
    }

    public validateForm(content){
    	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    	if (re.test(this.msEmail.toLowerCase())) {
    		content.submit();
    	}else{
    		
    	}
    }

    public onResize(){
        detectBody();
    }


    showTranslation(slide) {
        this.textSlide = slide;
    }

    public mute(val){
    	$('audio').prop('muted', val);
    	this.audio_mute = val;
    }

    // fetch learnerMap data using lambda function
    public async lambda_lmdata(i = 0){
        console.warn(this.args)
        console.log('custom-pf------lambda function')
        this.allitem = [];
        this.sorted_item = [];
        var all_cards:any = [];
        // track slidemap start
        let id;
        this.activatedRoute.params.subscribe((Params)=> {
            id = Params.id
        })
        // this.args = {
        //     "lm_id": "243238d8-6af2-4201-914a-aa129060013c",
        //     // "lm_id": '3f2dae98-c406-4f8e-801c-4f3196620bed',
        //     // 243238d8-6af2-4201-914a-aa129060013c
        // }
        var that = this;
        console.log(that.showContent)
        try {
            await that.lmda_service.lambda_invoke('TestDynamoDBReadLM-dev-readDataFromDynamoDB',this.args);
        } catch (err) {
            console.log(err)
        }

        await this.lmda_service.lambda_res.subscribe(res => {
            console.log(res["response"])
            all_cards = res;
            try {
                    console.warn('all_cards',all_cards)
                    let allcolumns:any = [];
                    allcolumns = all_cards["response"]?.body;
                    allcolumns?.forEach((col) => {
                        col['cards'].forEach(card => {
                            this.allitem.push(card);
                        });
                    });
            } catch (e) {
                    console.error('error in try block custom-pf',e);
                    return false;
                }
            var sorting = [];
            that.allitem.forEach(element => {
                // console.warn(element.order["N"])
                sorting[parseInt(element.order["N"]) - 1] = element;
            });

            sorting.forEach(el => {
                if(el?.active?.BOOL != false) {
                    // console.log(el.order["N"]);
                    that.sorted_item.push(el);
                    // console.warn(that.sorted_item.length)
                }
            })
            that.currentCard.next(that.sorted_item[that.counter])
        });
            console.log(this.sorted_item);
            // $('.loader-container').css('display','none');
            // that.lmda_service.lambda_res.next(that.sorted_item)
        // track slidemap start
        if(i == 0) {
            this.customPfService.trackActivity(id, 'profile_slide_map_start');
        }
    }

    // get interpretive guides
    public getGuides(content){
        this.selectedGuide= '';
        this.guide = '';
        this.http.get(`${environment.assessment_admin}api/v1/interpretive_guides`).subscribe(data => {
            console.log(data);
            // Sort by Language name
            this.guides = data['interpretive_guides'].sort(function(a, b){
                return a.language_name.localeCompare(b.language_name);
            });
            // this.modal1 = this.modalService.open(content, { windowClass: 'in grey' });
            $('.guide-modal').css('display','block');
        },
            error => console.log('oops', error)
        )
    }
  
    // change interpretive guides
    public changeGuide(){
        console.log(this.guides);
        for(var i=0; i<this.guides.length; i++){
            if(this.guides[i].language_code == this.guide){
                this.short_guide = this.guides[i].short_guide;
                this.long_guide = this.guides[i].long_guide;
            }
        }
    }
    
    // get guide
    public shortGuide() {
        let id; 
        this.activatedRoute.params.subscribe(Params => {
            id = Params.id;
        })
        if(this.selectedGuide.includes('Quick')) {
            this.customPfService.trackActivity(id,'quick_guide_download');
        }else {
            this.customPfService.trackActivity(id,'interpretive_guide_download');
        }
    }

    public changeUrl() {
        window.location.replace(`${this.env.assessment_admin}/thinking-coach`)
    }
        

}