import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import DivCon from '../json/cn-slideout.json'

@Injectable({
  providedIn: 'root'
})
export class SlideoutContentService {

  public process_type = new Subject<string>();

  constructor() { }
  divergeConvergeContent(){
    return DivCon;
  }
}
