import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd, Params} from '@angular/router';
import { environment } from './../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {CommonDataService} from './../../../common-data.service';
import { APIService } from '../../../../API.service';
import { on } from 'process';
import * as AWS from 'aws-sdk';
import { LmTsService } from '../../lm-ts.service';
import { LambdaFunctionService } from '../../../../service/lambda-function.service';
import { Store } from '@ngrx/store';
import { CigSlideshowService } from '../../../cig/cig-slideshow.service';

@Component({
  selector: "app-dynamic-card",
  templateUrl: "./dynamic-card.component.html",
  styleUrls: ["./dynamic-card.component.scss"],
})
export class DynamicCardComponent implements OnInit {
  public profiles: any;
  @Input() map: any;
  @Input() active_card: string;
  public current_date: any;
  public env;
  public allcolumns: any;
  email: any = localStorage.getItem("email");
  cardQuery: String;
  @Input() markData: any;
  @Input() converseData: any;
  currentLM: string = "";
  currentLM_id: any;
  lm_maptype: string = "";
  author: any;
  slides: any;
  foursightassets: any;
  whatnext: string = "What's next?";
  appNoli: any;
  counter: number;
  allitem: any;
  sorted_item = [];
  main_app_enable: boolean = false;
  cardView:boolean = false;
  cardDate4Admin:boolean = false;

  constructor(
    private api: APIService,
    private service: CommonDataService,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    public lm_ts: LmTsService,
    private router: Router,
    public lmda_service: LambdaFunctionService,
    public store: Store<any>,
    public cigService: CigSlideshowService
  ) {}

  ngOnDestroy(): void {
    localStorage.setItem('adminDateVisible','false');
  }

  ngOnInit() {
    this.env = environment;
    this.appNoli = localStorage.getItem("appNoli");
    if (
      this.router.url.includes("fs-reveal") ||
      this.router.url.includes("profile-slideshow")
    ) {
      this.main_app_enable = true;
      // this.appNoli = 'false';
    }
    if(this.router.url.includes("fs-reveal/presenter-lm")) {
      this.appNoli = "false";
    }
    if(localStorage.getItem("adminDateVisible") == "true") {
      this.cardDate4Admin = true;
    }
    this.slides = this.cigService.slides;
    this.slides[1]["from"] = "from dynamic card";
    this.slides.forEach((sl)=> {
      delete sl["audio_link"]
    })
    this.slides.push(
      {
        id: 29, // for image but not title
        // title: this.currentlm?.title?.S,
        // imgLink: this.currentlm?.bg?.S,
        slideBackgroundImage: "",
        subtext: "",
        profileText: "",
        active: true,
      },
      {
        id: 30, // for video mp4
        // title: this.currentlm?.title?.S,
        // video_link: this.currentlm?.link?.S,
        slideBackgroundImage: "",
        // poster: this.currentlm?.bg?.S,
        subtext: "",
        profileText: "",
        active: true,
      },
      {
        id: 31, // for Wistia
        // title: this.currentlm?.title?.S,
        // video_link: this.currentlm?.link?.S,
        slideBackgroundImage: "",
        subtext: "",
        profileText: "",
        active: true,
      });

    this.author = {
      image: "author-pic.jpg",
      smallLogo: "foursight-grey-logo-logotype-trans-w160.png",
      logoicon: "foursight-grey-logo-icon.png",
      logotext: "foursight-grey-logo-text.png",
      logoFoursight: "foursight-grey-logo-logotype-trans-w846.png",
      audio: "",
      lm_audio: true,
    };

    this.foursightassets = {
      clarifyImage: "clarify-icon-2.png",
      titleClarify: "Clarify",
      ideateImage: "ideate-icon-2.png",
      titleIdeate: "Ideate",
      developImage: "develop-icon-2.png",
      titleDevelop: "Develop",
      implementImage: "impl-icon-2.png",
      titleImplement: "Implement",
    };
    $(document).on("mouseenter", ".start-work__cell", function () {
      $(".start-work__cell").removeClass("start-work__cell--active");
      $(this).addClass("start-work__cell--active");
      $(".dynamic-cards__overlay").addClass("dynamic-cards__overlay--show");
    });
    $(document).on("mouseleave", ".start-work__cell", function () {
      $(".start-work__cell").removeClass("start-work__cell--active");
      $(".dynamic-cards__overlay").removeClass("dynamic-cards__overlay--show");
    });

    if (this.main_app_enable) {
      this.lambda_lmdata();
    }
       if(this.router.url.includes('/challenge-navigator-print')){
          this.main_app_enable = true;
          this.cardView = true;
          this.appNoli = "false";
            // that.lm_maptype = map?.maptype['title'];
          this.lambda_lmdata();
              
        } else if(this.appNoli == "true"){
          console.log('dynamic card appNoli')
          this.lambda_lmdata();
          this.activatedRoute.params.subscribe((params: Params) => {
            var an_id = params.id;
            if(an_id && an_id != 'presenter-lm') {
              this.http.get(`${environment.profileURL}/api/v1/profiles/${an_id}.json`,{params:{profile:"custom",lang:"en"}}).subscribe(res => {
                this.profiles = res['profile'];
              })
            }
          })
        } else {
          console.log('dynamic card el');
          this.appNoli = "false"
          this.current_date = new Date().getTime();
            this.allcolumns = this.map.columns?.items;
            this.service.getProfile().subscribe(message => { this.profiles = message; },
            err => {
                // Log errors if any
                console.log(err);
            })
            
            var that = this;
            if(this.active_card){
                setTimeout(function(){
                    $('.product-imitation').removeClass('card-active');
                    $('.'+that.active_card).addClass('card-active');
                },1000);
            }
            this.activatedRoute.params.subscribe((params: Params) => {
                this.currentLM = '';
                if(this.currentLM_id != params['id']){
                    this.currentLM_id = params['id'];
                    var that = this;
                    this.api.GetLearnerMap(this.currentLM_id).then(function (map) {
                    that.lm_maptype = map?.maptype['title']   
                })
              }
            });

    if (this.appNoli == "true") {
      this.lambda_lmdata();
      this.activatedRoute.params.subscribe((params: Params) => {
        var an_id = params.id;
        this.http
          .get(`${environment.profileURL}/api/v1/profiles/${an_id}.json`, {
            params: { profile: "custom", lang: "en" },
          })
          .subscribe((res) => {
            this.profiles = res["profile"];
          });
      });
    } else {
      this.appNoli = "false";
      this.current_date = new Date().getTime();
      this.allcolumns = this.map.columns?.items;
      this.store
        .select<any>((state) => state.profile?.account.default_profile)
        .subscribe(
          (message) => {
            this.profiles = message;
          },
          (err) => {
            // Log errors if any
            console.log(err);
          }
        );

      var that = this;
      if (this.active_card) {
        setTimeout(function () {
          $(".product-imitation").removeClass("card-active");
          $("." + that.active_card).addClass("card-active");
        }, 1000);
      }
      this.activatedRoute.params.subscribe((params: Params) => {
        this.currentLM = "";
        if (this.currentLM_id != params["id"]) {
          this.currentLM_id = params["id"];
          var that = this;
          this.api.GetLearnerMap(this.currentLM_id).then(function (map) {
            that.lm_maptype = map?.maptype["title"];
          });
        }
      });

      // for presenterlm
      if (this.router.url.includes("/fs-reveal/presenter-lm")) {
        this.main_app_enable = false;
        this.api
          .GetLearnerMap("1bdc6259-ca98-4e63-b984-1ff5e7d4702c")
          .then(function (map) {
            that.lm_maptype = map?.maptype["title"];
            that.allcolumns = map.columns?.items;
          });
      }
    }
  }
}

  public activeCard(title = "") {
    if (this.activatedRoute.firstChild) {
      this.activatedRoute.firstChild.params.subscribe((params: Params) => {
        this.active_card = params["id"];
        $(".product-imitation").removeClass("card-active");
        $("." + this.active_card).addClass("card-active");
      });
    } else {
      this.active_card = "";
      $(".product-imitation").removeClass("card-active");
    }
    if (this.lm_maptype === "Slideshow") {
      localStorage.setItem("lm_slideshow", title);
    }
  }

  public checkdate(time) {
    return new Date(time).getTime();
  }

  public verify_dates(bg_time, en_time) {
    if (this.env.enable_date && bg_time && en_time) {
      return (
        this.checkdate(bg_time) > this.current_date ||
        this.checkdate(en_time) < this.current_date
      );
    } else {
      return false;
    }
  }

  public showError(error) {
    console.log("oops", error);
    if (error.status == 500) {
      setTimeout(function () {
        this.toastr.options = {
          closeButton: true,
          timeOut: 2000,
        };
        this.toastr.error("Something went wrong!", "Sorry!");
      }, 1000);
    } else if (error.status == 422) {
      setTimeout(function () {
        this.toastr.options = {
          closeButton: true,
          timeOut: 4000,
        };
        if (error.error.errors.number[0]) {
          this.toastr.error(error.error.errors.number[0], "oops!");
        }
        if (error.error.errors.base[0]) {
          this.toastr.error(error.error.errors.base[0], "oops!");
        }
        if (!error.error.errors.base[0] && !error.error.errors.number[0]) {
          this.toastr.error(error.error.errors, "oops!");
        }
      }, 1000);
    } else if (error.status == 404) {
      setTimeout(function () {
        this.toastr.options = {
          closeButton: true,
          timeOut: 4000,
        };
        this.toastr.error(error.error.errors, "oops!");
      }, 1000);
    } else {
      setTimeout(function () {
        this.toastr.options = {
          closeButton: true,
          timeOut: 2000,
        };
        this.toastr.error("Something went wrong!", "oops!");
      }, 1000);
    }
  }

  // fetch learnerMap data using lambda function
  public async lambda_lmdata(i = 0) {
    this.counter = i;
    let count_card = 0;
    var that = this;
    this.lmda_service.lambda_res.subscribe((res) => {
      let sorted = [];
      sorted = res["response"]?.body;
      console.log(sorted);
      let cardFormat = [];
      try {
        sorted.forEach((el) => {
          let index = parseInt(el?.order) - 1;
          console.warn(index);
          this.sorted_item[index] = el;
          cardFormat[index] = el;
        });
      } catch (e) {
        return false;
      }
      // console.warn(cardFormat)

      console.log(this.sorted_item)
      this.sorted_item.forEach((col, ind) => {
        let sort_card = [];
        col["cards"].forEach((card) => {
          if (cardFormat[ind]?.cards) {
            cardFormat[ind].cards = [];
            sort_card[parseInt(card["order"]["N"]) - (col["cards"].length + 1)] = card;
          }
        });
        // remove empty element from array
        var filtered = sort_card.filter(function (el) {
          return el != null;
        });
        if (cardFormat[ind]?.cards) {
          cardFormat[ind]["cards"] = sort_card;
        }
      });
      console.log(cardFormat);
    });
  }

  // appNoli slide based on order
  public an_cards(orderNo,url) {
    console.warn(orderNo);
    this.lm_ts.an_slide_no.next(orderNo - 1);
    if(this.router.url.includes('challenge-navigator-print-coach')) {
      this.router.navigate([`challenge-navigator-print-coach/${url}`])
    }
  }

  public cardViewId(cardId) {
    console.log(cardId)
  }

}