import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { SlideoutContentService } from '../../../service/slideout-content.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-cn-print-ideate',
  templateUrl: './cn-print-ideate.component.html',
  styleUrls: ['./cn-print-ideate.component.scss']
})
export class CnPrintIdeateComponent implements OnInit {

  public modal:any;
  env:any;
  constructor(private modalService: NgbModal, public aroute: ActivatedRoute,public slideContent:SlideoutContentService) { }

  ngOnInit(): void {
    this.env=environment;
  }

  public open(content){
    this.modal = this.modalService.open(content, { windowClass: 'in grey' });
  }

 // open side popup
  public openPopup(content) {
    console.log(content)
    this.slideContent.process_type.next(content);
  }
}
