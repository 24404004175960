import { Component, OnInit } from '@angular/core';
import { environment } from './../../../../environments/environment';

@Component({
  	selector: 'app-toolcards-brainwriting',
  	templateUrl: './toolcards-brainwriting.component.html',
  	styleUrls: ['./toolcards-brainwriting.component.scss']
})
export class ToolcardsBrainwritingComponent implements OnInit {
	public env;

  	constructor() { }

  	ngOnInit() {
  		this.env=environment;
  	}
  	public print(){  		  		  		
	  	$('.print-button').hide();
	  	$('.two').hide();
	    $('.navbar-default').hide();
	    $('body').addClass('print-body');
	    window.print();
	    $('.print-button').show();
	    $('.two').show();
	    $('.navbar-default').show();
	    $('body').removeClass('print-body');
  	}

}
