import { Component, OnInit } from '@angular/core';
import { TeamReportServiceService } from './../team-report-service.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-team-communicate',
  templateUrl: './team-communicate.component.html',
  styleUrls: ['./team-communicate.component.scss']
})
export class TeamCommunicateComponent implements OnInit {
	communicate:any;
	env:any;

  	constructor(public service: TeamReportServiceService) { }

  	ngOnInit() {
  		this.communicate = this.service.communicate;
  		this.env = environment;
  	}

}
