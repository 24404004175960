// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mt-20 {
  margin-top: 10px;
}

.thanks--fl {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.thanks__left {
  flex-basis: 50%;
  text-align: left;
  position: relative;
  z-index: 9;
}
.thanks__left .pf-detail {
  text-align: left;
  margin-block: 10px;
  line-height: 1.4;
}
.thanks__right {
  margin-inline-start: 25px;
}
.thanks__right--img img {
  height: 300px;
  transform: rotate(-10deg);
}
.thanks button {
  font-family: "museo-300";
}`, "",{"version":3,"sources":["webpack://./src/app/components/cig/cig-thankyou/cig-thankyou.component.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;AACD;;AAIC;EACC,aAAA;EACA,eAAA;EACA,uBAAA;EACA,mBAAA;AADF;AAIC;EACC,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,UAAA;AAFF;AAIE;EACC,gBAAA;EACA,kBAAA;EACA,gBAAA;AAFH;AAMC;EACC,yBAAA;AAJF;AAQG;EACC,aAAA;EACA,yBAAA;AANJ;AAWC;EACC,wBAAA;AATF","sourcesContent":[".mt-20 {\n\tmargin-top: 10px;\n}\n\n\n.thanks {\n\t&--fl {\n\t\tdisplay: flex;\n\t\tflex-wrap: wrap;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\t}\n\n\t&__left {\n\t\tflex-basis: 50%;\n\t\ttext-align: left;\n\t\tposition: relative;\n\t\tz-index: 9;\n\n\t\t.pf-detail {\n\t\t\ttext-align: left;\n\t\t\tmargin-block: 10px;\n\t\t\tline-height: 1.4;\n\t\t}\n\t}\n\n\t&__right {\n\t\tmargin-inline-start: 25px;\n\n\t\t&--img {\n\n\t\t\timg {\n\t\t\t\theight: 300px;\n\t\t\t\ttransform: rotate(-10deg);\n\t\t\t}\n\t\t}\n\t}\n\n\tbutton {\n\t\tfont-family: \"museo-300\";\n\t}\n\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
