import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Auth } from './app/auth/auth.service';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  console.log = function (a, b, c, d, e, f, g, h, i, j, k, l) { return false; } 
}

import {Amplify} from 'aws-amplify';
import awsmobile from './aws-exports';
import {Cache} from '@aws-amplify/cache';

// var temp = {
//     token: Auth.getToken()
// }

// Cache.setItem('federatedInfo', temp);
Amplify.configure(awsmobile);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));