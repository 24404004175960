// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.membersTab table thead tr th {
  border: 0;
}

#holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
}
#holder li {
  padding: 0;
  width: 20px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin: 0;
  transition: all 0.3s ease;
  border: 1px solid #6D6E71;
}
#holder li:hover {
  opacity: 0.85;
}
#holder li.active {
  color: #fff;
  background-color: #6D6E71;
}`, "",{"version":3,"sources":["webpack://./src/app/components/teams/myteams-memberstab/myteams-memberstab.component.scss"],"names":[],"mappings":"AAIgB;EACI,SAAA;AAHpB;;AAUA;EACI,aAAA;EACA,eAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AAPJ;AASI;EACI,UAAA;EACA,WAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,SAAA;EACA,yBAAA;EACA,yBAAA;AAPR;AASQ;EACI,aAAA;AAPZ;AAUQ;EACI,WAAA;EACA,yBAAA;AARZ","sourcesContent":[".membersTab {\n    table {\n        thead {\n            tr {\n                th {\n                    border: 0;\n                }\n            }\n        }\n    }\n}\n\n#holder {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    align-items: center;\n    grid-gap: 5px;\n\n    li {\n        padding: 0;\n        width: 20px;\n        aspect-ratio: 1;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        border-radius: 3px;   \n        margin: 0; \n        transition: all 0.3s ease;\n        border: 1px solid #6D6E71;\n\n        &:hover {\n            opacity: 0.85;\n        }\n\n        &.active {\n            color: #fff;\n            background-color: #6D6E71;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
