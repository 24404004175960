import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Location } from '@angular/common'
import {CommonDataService} from './../common-data.service';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-advice',
  templateUrl: './advice.component.html',
  styleUrls: ['./advice.component.scss']
})

export class AdviceComponent implements OnInit {
	id:number;
	rightUser:any;
	cUser:any;
    storeNgrx:any;

  constructor(public location:Location, public activatedRoute:ActivatedRoute, public http: HttpClient, public service:CommonDataService,public store: Store<any>) { }

    ngOnInit() {
        this.storeNgrx = this.store.select<any>((state) => state.profile.account.default_profile);
      	this.activatedRoute.params.subscribe((params: Params) => {
            this.id = params.id;
            console.log(this.id);
        })
        this.http.get(`${environment.profileURL}/api/v1/members/`+this.id+`.json`).subscribe(data => {
           this.rightUser = data['member']; 
           console.log(data);
        },
            error => console.log('oops', error))
        this.storeNgrx.subscribe(message => {
            if(message){        
                this.cUser = message;
            }

        })
    }            
    cancel() {
  	    this.location.back(); // <-- go back to previous location on cancel
    }

}
  