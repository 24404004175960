import { Component, OnInit } from '@angular/core';
import { environment } from './../../../../environments/environment';

@Component({
  	selector: 'app-toolcards-stakeholder-analysis',
  	templateUrl: './toolcards-stakeholder-analysis.component.html',
  	styleUrls: ['./toolcards-stakeholder-analysis.component.scss']
})
export class ToolcardsStakeholderAnalysisComponent implements OnInit {
	public env;

  	constructor() { }

  	ngOnInit() {
  		this.env=environment;
  	}

}
