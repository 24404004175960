import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LmNewPricingIndependentService {

	practitioner = [
        {
            type:     'pdf',
            medium:   'Video',
            title:    'Welcome',
            lesson:   'random',
            runtime:   "1:19 mins",
            link:     'qlO0fYzC2Sc',
            data:      'Welcome to your learner map. Let us lead you on a journey toward better collaboration.',
            showinfo:  false,
            parent:   'Start here',
            button:   'Play Video',
            bg:       '',
            target:   "_self"
        },
        {
            type:     'video',
            medium:   'Watch Video',
            title:    'New Pricing Model',
            lesson:   'new-pricing',
            runtime:  "2 mins",
            link:     '6nx7SkY0Nds',
            data:     'New Pricing Model',
            showinfo: false,
            parent:   '',
            button:   '',
            bg:       '',
            target:   "_self",
            BeginDateTime: '2018-12-15T24:00:00',
            EndDateTime: 'infinity',
        },
        {
            type:     'pdf',
            title:    "Earned Discounts",
            lesson:   'the-details',
            medium:   'Read',
            runtime:  '5 mins',
            link:      '',
            bg:        'dollar_bulb.png',
            parent:   '',
            button:   '',
            data:      "Earn discounts for Independent Faciltators. Here you will find the information on how you might start earning discounts with FourSight.<br/>Download the FAQs and pricing schedule now.<br/><br/><a class='btn btn-xs btn-primary' href='https://www.dropbox.com/s/cfyz2y15xyhjjpk/FAQ_IND.pdf?dl=0' target='_blank'>Download Independent earned pricing schedule now</a>",
            showinfo:  false,
            BeginDateTime: '2018-12-15T24:00:00',
            EndDateTime: 'infinity',
            target:   "_self"
        },
        {
            type:     'form',
            title:    "Signed Agreement",
            lesson:   'to-participate',
            medium:   'Read',
            runtime:  '2 mins',
            link:      '',
            bg:        '',
            parent:   '',
            button:   '',
            data:      "To start getting your discounts under the new pricing model, you must download, sign and return the new FourSight agreement.<br/><br/><a class='btn btn-xs btn-primary' href='' target='_blank'>Download PDF</a>",
            showinfo:  false,
            target:   "_self",
            BeginDateTime: '2018-12-15T24:00:00',
            EndDateTime: 'infinity'
        },
        {
            type:     'survey',
            title:    "Vote and Read Other's Feedback",
            lesson:   'tell-us',
            medium:   'Survey Online',
            runtime:  '4 mins',
            link:      '',
            bg:        'slack_foursight.png',
            parent:   '',
            button:   '',
            data:      "Please vote and share your feedback on FourSight's new pricing model.",
            showinfo:  false,
            BeginDateTime: '2018-12-15T24:00:00',
            EndDateTime: 'infinity',
            target:   "_self"
        }
    ];

  constructor() { }

}
