import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2/dist/sweetalert2.js'
// import 'sweetalert2/src/sweetalert2.scss';
import { OrgsIndividualComponent } from './../orgs-individual.component';
import { Auth } from '../../../auth/auth.service';
// Import the AuthService type from the SDK
// import { AuthService } from '@auth0/auth0-angular';

import { OrgIndividualSessionsComponent } from '../../org-individual-sessions/org-individual-sessions.component';
import { OrgIndividualTeamsComponent } from '../../org-individual-teams/org-individual-teams.component';


declare var jQuery:any;

@Component({
  selector: 'app-training-sessions',
  templateUrl: './training-sessions.component.html',
  styleUrls: ['./training-sessions.component.scss']
})
export class TrainingSessionsComponent implements OnInit {
    params_id: any;
    orgId: any;
    unassignedTeam:number;
    query_string: string;
    membersByPage: number;
    members: any;
    Query_String: string;
    session_name: any;
    groups: any;
    checkcount: number;
    modal1: any;
    groupCount: any;
    algorithms: string;
    editID: any;
    subgroups: any;
    attrib: any;
    noClone: boolean;
    item: any;
    member: any;
    update: any;
    result1: any;
    result2: any;
    editgrp: any;
    updatesbgrp: any;
    isLoading: boolean;
    checkedAll: any;
    modal2: any;
    modal3: any;
    userEmail: any;
    loading: any;
    globalGroup: any;
    group: any;
    sessionMembers: any;
    addtoSession: any;
    sessionID: any;
    groupID: any;
    eSession: number;
    modal4: any;
    newGroup: any;
    app_type: string;
    pdf_type:string;
    email: any;
    modal5: any;
    modal6: any;
    grpName:any;
    meta:any;
    count:number;
    pageLen:number;
    curPage:number;
    compact_type:boolean;
    first_name:any;
    last_name:any;
    orgName:any;
    allocated_msg:string;
    memberSearch:any;
    groups_memberSearch:any;
    grp:any;
    userID:any;
    modal7:any;
    memberProfile:any;
    modal8:any;
    group_profile:any;
    member_id:number;
    preference:any;
    getOptions:boolean;
    addMember:any;
    Downloadpdf:any;
    download_profile:boolean;
    groupWizard:any;
    sessionWizard:any;
    env:any;
    allMembers:any;
    globalMembers:any;
    low_preference: any = 'Low Preference';
    high_preference: any = 'Peak Preference';
    contrast: any = 'Neutral';
    clarify:any = 'Clarify';
    ideate:any = 'Ideate';
    develop:any = 'Develop';
    implement:any = 'Implement';
    error;
    authProfile;

    constructor( private router: Router, public activatedRoute:ActivatedRoute, public http: HttpClient, public modalService:NgbModal,public orgs: OrgsIndividualComponent, public orgTeams: OrgIndividualTeamsComponent, public orgSessions: OrgIndividualSessionsComponent,public auth: Auth) { }

    ngOnInit() {
        this.globalMembers = [];
        this.env = environment;
        this.activatedRoute.params.subscribe((params: Params) => {
            console.log(params);
            this.params_id = params.id;
        })
        this.orgId = this.orgs.id;
        this.eSession = 0;
        this.membersByPage = 20;
        this.compact_type = true;
        this.query_string = environment.profileURL+`/api/v1/organizations/`+ this.orgId + `/training_sessions/` + this.params_id + `/unassigned_members.json?per_page=` + this.membersByPage;
        this.Query_String = this.query_string;
        this.isLoading = true;

        // Fetch Session and its groups
        this.http.get(`${environment.profileURL}/api/v1/organizations/`+this.orgId+`/training_sessions/`+this.params_id+`.json?per_page=100`).subscribe(data => {
            console.info('Get Started with Single Session');
            console.log(data);
            this.session_name = data['training_session'].name;
            this.allMembers = data['training_session'].members;
            this.groups = data['training_session'].groups;
            this.preference = data['training_session'].split_algo;
            var that = this;
            setTimeout(function() {
                jQuery(document).ready(function(e){


                jQuery( ".agile-list" ).sortable({
                  connectWith: '.agile-list',
                  start: function (event, ui) {
                      that.dragMember(event.target.id);
                   },
                  receive: function (event, ui) {
                      that.dropMember(event.target.id,that.member_id);
                   },
                   change: function (event, ui) {                      
                      that.member_id = ui.item[0].id;
                   }
                }); 
                // jQuery( "ul, li" ).disableSelection(); 
                    jQuery('input[name="compact"]').on('ifChecked', function (event) {
                        that.Compact();
                    });
                    jQuery('input[name="compact"]').iCheck({
                        checkboxClass: 'icheckbox_square-green',
                        radioClass: 'iradio_square-green',
                    });
                });
            },0);
        },
            error => console.log('oops', error)
        );

        this.http.get(`${environment.profileURL}/api/v1/organizations/`+this.orgId+`.json`).subscribe(data => {
            console.log(data);
            this.orgName = data['organization'].name; 

        },
            error => console.log('oops', error)
        )

        // Unassigned Members
        this.http.get(`${environment.profileURL}/api/v1/organizations/` + this.orgId + `/training_sessions/` + this.params_id + `/unassigned_members.json?per_page=` + this.membersByPage).subscribe(data => {
            console.log(data);
            this.members = data['training_sessions'];
            var that = this;
            jQuery(document).ready(function(e){
                jQuery( ".agile-list" ).sortable({
                  connectWith: '.agile-list',
                  start: function (event, ui) {
                      that.dragMember(event.target.id);
                   },
                  receive: function (event, ui) {
                      that.dropMember(event.target.id,that.member_id);
                   },
                   change: function (event, ui) {                      
                      that.member_id = ui.item[0].id;
                   }
                }); 
            });
            this.allocated_msg = 'All members have been allocated to groups or there are no members in this session yet.';
            this.meta = data['meta'];
            this.count = data['meta'].total_count;
            this.pageLen = this.meta.total_pages;
            this.curPage = 0;
            this.isLoading = false;
            this.item = [];
            for(var i = 1; i<=this.pageLen;i++){
                this.item.push(i);
            }
            if(this.count > this.membersByPage){
                this.render( this.curPage, this.item, true );
                console.log(data);
            }
        },
            error => console.log('oops', error)
        );

        this.auth.getProfile((err, profile) => {
            this.authProfile = profile
            this.email = profile.email;
        });
        // this.auth.getUser().subscribe(user => {
        //     this.email = user.email;
        // });



        jQuery(document).click(function(event){
            if (!$(event.target).closest(".quick-card-editor-buttons,.js-open-modal").length) {
                $("body").find(".quick-card-editor-buttons").removeClass("subgroup-open");
                $("body").find(".quick-card-editor-buttons-item").removeClass("sub-subgroup-edit");

            }
        });
    }

    public isPageInRange = function( curPage, index, maxPages, pageBefore, pageAfter ) {
        if (index <= 1) {
            // first 2 pages
            return true;
        }
        if (index >= maxPages - 2) {
            // last 2 pages
            return true;
        }
        if (index >= curPage - pageBefore && index <= curPage + pageAfter) {
            return true;
        }
    }

    public render = function( curPage, item, first ) {
        var html = '', separatorAdded = false;
        for(var i in item){
            if ( this.isPageInRange( curPage, i, this.pageLen, 2, 2 ) ) {
                html += '<li data-page="' + i + '">' + item[i] + '</li>';
                // as we added a page, we reset the separator Added
                separatorAdded = false;
            } else {
                if (!separatorAdded) {
                    // only add a separator when it wasn't added before
                    html += '<li class="separator" />';
                    separatorAdded = true;
                }
            }
        }
        var holder = document.querySelector('#holder');
        if(this.count > this.membersByPage && this.eSession == this.orgId){
            holder.innerHTML = html;
            if(document.querySelector('#holder>li[data-page="' + curPage + '"]') != null){document.querySelector('#holder>li[data-page="' + curPage + '"]').classList.add('active');}
            if ( first ) {
                holder.addEventListener('click', e => {
                    console.log(e.target['attributes']['data-page'].value);
                    if (!e.target['attributes']['data-page'].value) {
                        // no relevant item clicked (you could however offer expand here )
                        return;
                    }

                    curPage = parseInt(e.target['attributes']['data-page'].value);
                    var page_number = curPage+1;
                    this.isLoading = true;
                    this.http.get(`${this.query_string}&page=${page_number}`).subscribe(data => {
                        this.members = data['training_sessions'];
                        this.meta = data['meta'];
                        this.count = data['meta'].total_count;
                        this.pageLen = this.meta.total_pages;
                        this.isLoading = false;
                        this.curPage = 0;
                        this.item = [];
                        for(var i = 1; i<=this.pageLen;i++){
                            this.item.push(i);
                        }

                    },
                        error => console.log('oops', error)
                    )
                    this.render( curPage, item );
                });
            }
        }else{
            // holder.innerHTML = null;
        }
    }

    public open() {
      this.algorithms = 'similar';
      this.groupCount = '';
      this.loading = false;
    //   this.modal1 = this.modalService.open(content, { windowClass: 'in grey' });
      $('.content-modal').css('display','block')
    }

    // Split session into groups
    public splitGroups(algorithm) {
        console.log(algorithm);
        if(this.algorithms == 'similar'){
            this.loading = 1;
            this.http.get(`${environment.profileURL}/api/v1/organizations/` + this.orgId + `/training_sessions/` + this.params_id + `/create_similar_pref_groups.json`).subscribe(result => {
                this.loading = 2;
                console.log(result);
                // this.modal1.close(result);
                $('.content-modal').css('display','none');
                var that = this;
                this.groups = result['training_session'].groups;
                this.preference = result['training_session'].split_algo;
                setTimeout(function() {
                    that.Compact();
                    that.startDragDrop();
                },0.1);
                this.http.get(`${environment.profileURL}/api/v1/organizations/` + this.orgId + `/training_sessions/` + this.params_id + `/unassigned_members.json?per_page=` + this.membersByPage).subscribe(data => {
                    this.members = data['training_sessions'];
                    setTimeout(function() {
                        that.Compact()
                    },0.1);
                    this.allocated_msg = 'All members have been allocated to groups or there are no members in this session yet.';
                    this.meta = data['meta'];
                    this.count = this.meta.total_count;
                    this.pageLen = this.meta.total_pages;
                    this.curPage = 0;
                    this.isLoading = false;
                    this.item = [];
                    for(var i = 1; i<=this.pageLen;i++){
                        this.item.push(i);
                    }
                    if(this.count > this.membersByPage){
                        this.render( this.curPage, this.item, true );
                        console.log(data);
                    }
                });
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 2000
                    };
                    this.toastr.success('Groups were created successfully!', 'Nice!');
                }, 1000);
            },
            error => console.log('oops', error)
            )
        }
        else{
            if(this.groupCount && this.algorithms == 'diverse-exact'){
                this.http.post(`${environment.profileURL}/api/v1/organizations/` + this.orgId + `/training_sessions/` + this.params_id +`/create_diverse_pref_exact_groups.json`, { number_of_groups: this.groupCount }).subscribe(data => {
                    console.log(data);
                    this.groups = data['training_session'].groups;
                    this.preference = data['training_session'].split_algo;
                    // this.modal1.close(data);
                    $('.content-modal').css('display','none');
                    var that = this;
                    setTimeout(function() {
                        that.Compact();
                        that.startDragDrop();
                    },0.1);
                    this.http.get(`${environment.profileURL}/api/v1/organizations/` + this.orgId + `/training_sessions/` + this.params_id + `/unassigned_members.json?per_page=` + this.membersByPage).subscribe(data => {
                        this.members = data['training_sessions'];
                        setTimeout(function() {
                            that.Compact()
                        },0.1);
                        this.allocated_msg = 'All members have been allocated to groups or there are no members in this session yet.';
                        this.meta = data['meta'];
                        this.count = this.meta.total_count;
                        this.pageLen = this.meta.total_pages;
                        this.curPage = 0;
                        this.isLoading = false;
                        this.item = [];
                        for(var i = 1; i<=this.pageLen;i++){
                            this.item.push(i);
                        }
                        if(this.count > this.membersByPage){
                            this.render( this.curPage, this.item, true );
                            console.log(data);
                        }
                    });
                    setTimeout(function() {
                        this.toastr.options = {
                            closeButton: true,
                            progressBar: true,
                            timeOut: 2000
                        };
                        this.toastr.success('Groups were created successfully!', 'Nice!');
                    }, 1000);

                },
                error => console.log('oops', error)
                )
            }
            else if(this.groupCount && this.algorithms == 'diverse-lottery'){
                this.http.post(`${environment.profileURL}/api/v1/organizations/` + this.orgId + `/training_sessions/` + this.params_id +`/create_diverse_pref_lottery_groups.json`, { number_of_groups: this.groupCount }).subscribe(data => {
                    console.log(data);
                    this.groups = data['training_session'].groups;
                    this.preference = data['training_session'].split_algo;
                    // this.modal1.close(data);
                    $('.content-modal').css('display','none');
                    var that = this;
                    setTimeout(function() {
                        that.Compact();
                        that.startDragDrop();
                    },0.1);
                    this.http.get(`${environment.profileURL}/api/v1/organizations/` + this.orgId + `/training_sessions/` + this.params_id + `/unassigned_members.json?per_page=` + this.membersByPage).subscribe(data => {
                        this.members = data['training_sessions'];
                        setTimeout(function() {
                            that.Compact()
                        },0.1);
                        this.allocated_msg = 'All members have been allocated to groups or there are no members in this session yet.';
                        this.meta = data['meta'];
                        this.count = this.meta.total_count;
                        this.pageLen = this.meta.total_pages;
                        this.curPage = 0;
                        this.isLoading = false;
                        this.item = [];
                        for(var i = 1; i<=this.pageLen;i++){
                            this.item.push(i);
                        }
                        if(this.count > this.membersByPage){
                            this.render( this.curPage, this.item, true );
                            console.log(data);
                        }
                    });
                    setTimeout(function() {
                        this.toastr.options = {
                            closeButton: true,
                            progressBar: true,
                            timeOut: 2000
                        };
                        this.toastr.success('Groups were created successfully!', 'Nice!');
                    }, 1000);

                },
                error => console.log('oops', error)
                )
            }
            else{
                this.createGroup(this.groupCount);
                // this.modal1.close();
                $('.content-modal').css('display','none');
            }
        }
    }

    public editGroup(index, name){
        this.editID = index;
        this.grpName = name;
    }

    public cancelEdit(item){
        this.editID = 0;
        item.name = this.grpName;

    }

    public updateGroup(id, gname){
        this.http.put(`${environment.profileURL}/api/v1/training_session_groups/` + id + '.json', {name:gname}).subscribe(result => {
            console.log(result);
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.success('Group was updated successfully!', 'Nice!');
            }, 1000);
            this.editID = 0;
        })

    }

    public removeGroup(id, index){
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure, you want to delete this Group? This cannot be undone!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            cancelButtonColor: '##D0D0D0',
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result['value'] == true) {
                jQuery('.swal2-container').addClass('hide');
                jQuery('body').removeClass('swal2-shown');
                jQuery('html').removeClass('swal2-shown');
                this.http.delete(`${environment.profileURL}/api/v1/training_session_groups/`+id+'.json').subscribe(result => {
                    console.log(result);
                    setTimeout(function() {
                        this.toastr.options = {
                            closeButton: true,
                        progressBar: true,
                            timeOut: 2000
                        };
                        this.toastr.success('The Group was deleted successfully!', 'Nice!');
                    }, 1000);

                    for(var i=0; i<this.groups.length; i++){
                        if(this.groups[i].id == id){                            
                            for(var x=0; x<this.groups[i].members.length; x++){
                                this.members.push(this.groups[i].members[x]);
                            }
                            this.groups.splice(i,1);
                        }

                    }

                },
                err =>{
                    console.log(err);
                    setTimeout(function() {
                        this.toastr.options = {
                            closeButton: true,
                        progressBar: true,
                            timeOut: 2000
                        };
                        this.toastr.error(err, 'Sorry!');
                    }, 1000);
                })
            }else{
                jQuery('.swal2-container').addClass('hide');
                jQuery('body').removeClass('swal2-shown');
                jQuery('html').removeClass('swal2-shown');
            }
        },function(dismiss){
            jQuery('.swal2-container').addClass('hide');
            jQuery('body').removeClass('swal2-shown');
            jQuery('html').removeClass('swal2-shown');
        });
    }


    public cloneGroup(group, index) {
        this.noClone = true;
        var record = {};

        record['id'] = group.parent_id;
        record['subGroupName'] = group.name + ' Clone';
        record['allMembers'] = new Array();

        group.members.forEach(member => {
            record['allMembers'].push(member.id);
        });
        console.log(record);

        this.groups.forEach(item =>  {
            // Sub Groups
            item.subgroups.forEach(record => {
                console.log(record.id);
                if(record.name == group.name + ' Clone') {
                    setTimeout(function() {
                        this.toastr.options = {
                            closeButton: true,
                            progressBar: true,
                            timeOut: 2000
                        };
                        this.toastr.error(group.name + ' Clone  Group is already exists.', 'Sorry!');
                    }, 1000);
                    this.noClone = false;
                }

            });


        });
        if(this.noClone){
            this.attrib = {name: record['subGroupName'], parent_id: record['id'], member_ids: record['allMembers']};
            this.http.post(`${environment.profileURL}/api/v1/groups.json`,this.attrib).subscribe(data => {
                console.log(data);
                this.groups[index].subgroups.unshift(data['group']);
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 2000
                    };
                    this.toastr.success(data['group'].name+' Group was added successfully.', 'Nice!');
                }, 1000);
            })
        }
    }


    public download_options(record,e){
        var name = record.id;
        console.log(record.id);
        jQuery("."+name+"").next('ul').toggle();
        e.stopPropagation();
        e.preventDefault();
    }

    public updatesubGroup(subgroups, item, member) {
        $("body").find(".quick-card-editor-buttons-item").removeClass("sub-subgroup-edit");
        this.result1 = false;
        this.result2 = false;
        let a = $('#' + item.id + member.id).val();
        console.log(a);
        if(a == 'unassigned'){
            var memberId = {};
            memberId['allMembers'] = new Array();

            memberId['allMembers'].push(member.id);

            var that = this;
            this.http.post(`${environment.profileURL}/api/v1/training_session_groups/`+item.id+'/remove_members.json',{member_ids: memberId['allMembers']}).subscribe(result => {
                console.log(result);
                this.result2 = true;
                var inner = 0;
                item.members.forEach(record => {
                    if(record.id == member.id){
                        item.members.splice(inner,1);
                    }
                    inner++;
                })
                this.members.push(member);
                setTimeout(function() {
                    that.Compact();
                },0.1);
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 2000
                    };
                    this.toastr.success('Groups were updated successfully!', 'Nice!');
                }, 1000);
            })
        }else{

            for (let i = 0; i < subgroups.length; i++) {
                if(a == subgroups[i].id){
                    var subGrp = subgroups[i];
                }
            }

            var memberId = {};
            memberId['allMembers'] = new Array();

            memberId['allMembers'].push(member.id);

            var that = this;
            this.http.post(`${environment.profileURL}/api/v1/training_session_groups/`+item.id+'/remove_members.json',{member_ids: memberId['allMembers']}).subscribe(result => {
                console.log(result);
                this.result2 = true;
                var inner = 0;
                item.members.forEach(record => {
                    if(record.id == member.id){
                        item.members.splice(inner,1);
                    }
                    inner++;
                })
                this.http.post(`${environment.profileURL}/api/v1/training_session_groups/`+subGrp.id+'/add_members.json',{member_ids: memberId['allMembers']}).subscribe(result => {
                    console.log(result);
                    subGrp.members.push(member);
                    this.result1 = true;
                    setTimeout(function() {
                        that.Compact()
                    },0.1);
                    setTimeout(function() {
                        this.toastr.options = {
                            closeButton: true,
                          progressBar: true,
                            timeOut: 2000
                        };
                        this.toastr.success('Groups were updated successfully!', 'Nice!');
                    }, 1000);

                },error =>{
                    this.result1 = false;
                    setTimeout(function() {
                        this.toastr.options = {
                            closeButton: true,
                          progressBar: true,
                            timeOut: 2000
                        };
                        this.toastr.error('Member in moving group was not updated!', 'Sorry!');
                    }, 1000);
                })
            }, error =>{
                this.http.post(`${environment.profileURL}/api/v1/training_session_groups/`+subGrp.id+'/add_members.json',{member_ids: memberId['allMembers']}).subscribe(result => {
                    console.log(result);
                    subGrp.members.push(member);
                    this.result1 = true;
                    setTimeout(function() {
                        that.Compact()
                    },0.1);
                    setTimeout(function() {
                        this.toastr.options = {
                            closeButton: true,
                          progressBar: true,
                            timeOut: 2000
                        };
                        this.toastr.error('Member from moved group was not updated!', 'Sorry!');
                    }, 1000);
                    this.Compact();
                },error =>{
                    this.result1 = false;
                    setTimeout(function() {
                        this.toastr.options = {
                            closeButton: true,
                          progressBar: true,
                            timeOut: 2000
                        };
                        this.toastr.error('Groups were not updated!', 'Sorry!');
                    }, 1000);
                })
            })
        }

    }

    public updateUnassigned(subgroups, member) {
      $("body").find(".quick-card-editor-buttons-item").removeClass("sub-subgroup-edit");
      this.result1 = false;
      this.result2 = false;
      let a = $('#unassigned' + member.id).val();

        for (let i = 0; i < subgroups.length; i++) {
        if(a == subgroups[i].id){
          var subGrp = subgroups[i];
        }
      }

    var memberId = {};
    memberId['allMembers'] = new Array();

        memberId['allMembers'].push(member.id);

        var that = this;
        this.http.post(`${environment.profileURL}/api/v1/training_session_groups/`+subGrp.id+'/add_members.json',{member_ids: memberId['allMembers']}).subscribe(result => {
            console.log(result);
            subGrp.members.push(member);
             var inner = 0;
              this.members.forEach(record => {
              if(record.id == member.id){
                this.members.splice(inner,1);
              }
              inner++;
            })
            this.result1 = true;
            setTimeout(function() {
              that.Compact()
            },0.1);
            setTimeout(function() {
                  this.toastr.options = {
                      closeButton: true,
                      progressBar: true,
                      timeOut: 2000
                  };
                  this.toastr.success('Groups were updated successfully!', 'Nice!');
              }, 1000);

        },error =>{
          this.result1 = false;
          setTimeout(function() {
                  this.toastr.options = {
                      closeButton: true,
                      progressBar: true,
                      timeOut: 2000
                  };
                  this.toastr.error('Groups were not updated!', 'Sorry!');
              }, 1000);
        })

    }

    public createGroup(nmbr) {
        this.attrib = {number_of_groups: parseInt(nmbr)};
        this.http.post(`${environment.profileURL}/api/v1/organizations/` + this.orgId + `/training_sessions/` + this.params_id +`/create_groups.json`, this.attrib).subscribe(data => {
            console.log(data);
            this.groups = data['training_session'].groups;
            this.preference = data['training_session'].split_algo;
            var that = this;
            setTimeout(function() {
                that.Compact()
            },0.1);
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.success('Group was added successfully.', 'Nice!');
            }, 1000);

        })
    }

    public createNewGroup(content:any = '') {
        if(this.newGroup){
            var counter = 0;
            var groupMembers = [];
            this.globalGroup.forEach(list =>{
                  groupMembers[counter] = list.id;
                  counter++;
              });
            this.attrib = {name: this.newGroup, member_ids:groupMembers};
                this.http.post(`${environment.profileURL}/api/v1/groups.json`,this.attrib).subscribe(data => {
                  console.log(data);
                //   this.modal4.close(data)
                $('.group-modal').css('display','block')
                  setTimeout(function() {
                      this.toastr.options = {
                          closeButton: true,
                          progressBar: true,
                          timeOut: 2000
                      };
                      this.toastr.success(data['group'].name+' Group was added successfully.', 'Nice!');
                  }, 1000);
                  this.newGroup = '';
            })
        }
    }

    public openTemp(content:any = '') {
        // this.modal1.close(content);
        // this.modal4 = this.modalService.open(content, { windowClass: 'in grey' });
        $('.content-modal').css('display','none');
        $('.group-modal').css('display','block')

    }

    public Compact(){
        if($('.super-compact-group:radio:checked').length > 0){
        this.editID = 0;
        this.compact_type = false;
            $('.superCompact').addClass('hide-content');
            $('.inner-box').removeClass('CompactView');
            $('.inner-box').addClass('superCompactView');
            $('.wrapper-content').addClass('hide-wrapper');

        }else if($('.compact-group:radio:checked').length > 0){
        this.editID = 0;
        this.compact_type = false;
            $('.superCompact').removeClass('hide-content');
            $('.Compact').addClass('hide-content');
            $('.inner-box').removeClass('superCompactView');
            $('.inner-box').addClass('CompactView');
            $('.wrapper-content').addClass('hide-wrapper');

        }else{
        this.compact_type = true;
            $('.superCompact').removeClass('hide-content');
            $('.Compact').removeClass('hide-content');
            $('.inner-box').removeClass('superCompactView');
            $('.inner-box').removeClass('CompactView');
            $('.wrapper-content').removeClass('hide-wrapper');
        }
    }

    public openMove(item,member){
        if($('.member-'+item.id+member.id).hasClass('subgroup-open')){
            $('.quick-card-editor-buttons').removeClass('subgroup-open');
        }
        else{
            $('.quick-card-editor-buttons').removeClass('subgroup-open');
            $('.member-'+item.id+member.id).addClass('subgroup-open');
            this.updatesbgrp = item.id;
        }
        $('.item-'+item.id+member.id).addClass('sub-subgroup-edit');
    }

    public close(item,member){
        $('.item-'+item.id+member.id).removeClass('sub-subgroup-edit');
    }

    public openUnassigned(member){
      if($('.member-unassigned'+member.id).hasClass('subgroup-open')){
        $('.quick-card-editor-buttons').removeClass('subgroup-open');
      }
      else{
        $('.quick-card-editor-buttons').removeClass('subgroup-open');
        $('.member-unassigned'+member.id).addClass('subgroup-open');
        this.updatesbgrp = '';
      }
      $('.item-unassigned'+member.id).addClass('sub-subgroup-edit');
    }

    public closeUnassigned(member){
      $('.item-unassigned'+member.id).removeClass('sub-subgroup-edit');
    }

    public length1(){
        this.first_name = jQuery('.first_name').val();
        if(this.first_name.length >= 3 || this.first_name.length == 0){
            this.filter();
        }
    }

    public length2(){
        this.last_name = jQuery('.last_name').val();
        if(this.last_name.length >= 3 || this.last_name.length == 0){
            this.filter();
        }
    }

    public resetFirstName() {
        jQuery('.first_name').val('');
        this.first_name = '';
        this.filter();
    }

    public resetLastName() {
        jQuery('.last_name').val('');
        this.last_name = '';
        this.filter();
    }

    public filter(){
        this.query_string = this.Query_String;
        this.allocated_msg = 'All members have been allocated to groups or there are no members in this session yet.';
        var first_name = jQuery('.first_name').val();
        var last_name = jQuery('.last_name').val();
        var profiles = jQuery('#profiles').val();
        if(first_name){
            this.query_string += '&first_name=' + first_name;
            this.allocated_msg = 'We are sorry but no members that match this filter.';
        }
        if(last_name){
            this.query_string += '&last_name=' + last_name;
            this.allocated_msg = 'We are sorry but no members that match this filter.';
        }
        if (profiles) {
            this.query_string += '&profile_type_id=' + profiles;
            this.allocated_msg = 'We are sorry but no members that match this filter.';
        }
        this.isLoading = true;
        console.log(this.query_string);
        this.http.get(`${this.query_string}`).subscribe(data => {
            jQuery('#checkAll').prop('checked',false);
            this.members = data['training_sessions'];
            this.meta = data['meta'];
            this.pageLen = this.meta.total_pages;
            this.curPage = 0;
            this.item = [];
            console.log(this.members);
            this.isLoading = false;
            for(var i = 1; i<=this.pageLen;i++){
                this.item.push(i);
            }
            this.render( this.curPage, this.item, true );
            console.log(data);
        })
    }

    public sorting(column,order){
        this.Query_String = environment.profileURL+ `/api/v1/organizations/` + this.orgId + `/training_sessions/` + this.params_id + `/unassigned_members.json?per_page=` + this.membersByPage;
        if(column == 'firstName'){
            if(order == 'asc'){
                this.Query_String += '&first_name_order=asc';
                this.filter();
                $('.fname-asc').addClass('hide');
                $('.fname-desc').removeClass('hide');
            }
            else{
                this.Query_String += '&first_name_order=desc';
                this.filter();
                $('.fname-asc').removeClass('hide');
                $('.fname-desc').addClass('hide');
            }
        }
        if(column == 'lastName'){
            if(order == 'asc'){
                this.Query_String += '&last_name_order=asc';
                this.filter();
                $('.lname-asc').addClass('hide');
                $('.lname-desc').removeClass('hide');
            }
            else{
                this.Query_String += '&last_name_order=desc';
                this.filter();
                $('.lname-asc').removeClass('hide');
                $('.lname-desc').addClass('hide');
            }
        }
        if(column == 'profile'){
            if(order == 'asc'){
                this.Query_String += '&profile_type_order=asc';
                this.filter();
                $('.profile-asc').addClass('hide');
                $('.profile-desc').removeClass('hide');
            }
            else{
                this.Query_String += '&profile_type_order=desc';
                this.filter();
                $('.profile-asc').removeClass('hide');
                $('.profile-desc').addClass('hide');
            }
        }
    }

    public deleteMember() {
        this.group = [];
        var that = this;
        if($('.checkedbox:checked').length > 0) {
            $('.checkedbox:checked').each(function(){
                  that.group.push($(this).parents('tr').attr('id'));
            });
        }
        console.log(this.group);
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure, you want to delete this Member? This cannot be undone!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            cancelButtonColor: '##D0D0D0',
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result['value'] == true) {
                jQuery('.swal2-container').addClass('hide');
                jQuery('body').removeClass('swal2-shown');
                jQuery('html').removeClass('swal2-shown');
                // this.group.forEach(list =>{
                    this.http.post(`${environment.profileURL}/api/v1/organizations/`+this.orgId+'/training_sessions/'+this.params_id+'/remove_members.json',{member_ids : this.group}).subscribe(result => {
                        console.log(result);
                        for(var i=0; i<this.group.length; i++){
                            for(var j=0; j<this.members.length; j++){
                                if(this.group[i] == that.members[j].id){
                                    this.members.splice(j,1);
                                }

                            }
                        }
                        setTimeout(function() {
                            this.toastr.options = {
                                closeButton: true,
                                progressBar: true,
                                timeOut: 2000
                            };
                            this.toastr.success('The Member was deleted successfully!', 'Nice!');
                        }, 1000);

                    },
                    err =>{
                        console.log(err);
                        setTimeout(function() {
                            this.toastr.options = {
                                closeButton: true,
                          progressBar: true,
                                timeOut: 2000
                            };
                            this.toastr.error(err.errors, 'Sorry!');
                        }, 1000);
                    })
                // })

            }else{
                jQuery('.swal2-container').addClass('hide');
                jQuery('body').removeClass('swal2-shown');
                jQuery('html').removeClass('swal2-shown');
            }
        },function(dismiss){
            jQuery('.swal2-container').addClass('hide');
            jQuery('body').removeClass('swal2-shown');
            jQuery('html').removeClass('swal2-shown');
        });
    }

    public showGroup(content) {
        this.download_profile = false;
        let counter = 0;
        let groupMembers = [];
        this.globalGroup.forEach(list => {
            groupMembers[counter] = list.id;
            counter++;
        });
      let that = this;
        let attrib = {kind: 'group_profile', member_ids:groupMembers, organization_id:parseInt(this.orgId)};
        this.http.post(`${environment.profileURL}/api/v1/pdfs/generate.json`,attrib).subscribe(data => {
            console.log(data);
            setTimeout(function() {
                that.app_type = 'Group Profile';
                that.pdf_type = 'profile';
                that.download_profile = true;
            }, 1000);
        },
        err =>{
            console.log(err);
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.error(err.error.message, 'Sorry!');
            }, 1000);
        })
    }

    public groupProfile(content) {
        this.download_profile = false;
        let that = this;
        let attrib = {kind: 'group_profile', training_session_id: parseInt(this.params_id)};
        this.http.post(`${environment.profileURL}/api/v1/pdfs/generate.json`,attrib).subscribe(data => {
            console.log(data);
            setTimeout(function() {
                that.app_type = 'Group Profile';
                that.pdf_type = 'profile';
                that.download_profile = true;
            }, 1000);
        },
        err =>{
            console.log(err);
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.error(err.error.message, 'Sorry!');
            }, 1000);
        })
    }

    public showThinkingProfile(content) {
        this.download_profile = false;
        var counter = 0;
        var groupMembers = [];
        this.globalGroup.forEach(list =>{
            groupMembers[counter] = list.id;
            counter++;
        });
      var that = this;
        var attrib = {kind: 'multiple_profiles', member_ids:groupMembers, organization_id:parseInt(this.orgId)};
        this.http.post(`${environment.profileURL}/api/v1/pdfs/generate.json`,attrib).subscribe(data => {
            console.log(data);
            setTimeout(function() {
                that.app_type = 'Thinking Profile';
                that.pdf_type = 'profile';
                that.download_profile = true;
            }, 1000);
        },
        err =>{
            console.log(err);
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.error(err.error.message, 'Sorry!');
            }, 1000);
        })
    }

    public thinkingProfile(content) {
        var that = this;
        var attrib = {kind: 'multiple_profiles', training_session_id: parseInt(this.params_id)};
        this.http.post(`${environment.profileURL}/api/v1/pdfs/generate.json`,attrib).subscribe(data => {
            console.log(data);
            setTimeout(function() {
                that.app_type = 'Thinking Profile';
                that.pdf_type = 'profile';
              that.modal5 = that.modalService.open(content, { windowClass: 'in grey' });
            }, 1000);
        },
        err =>{
            console.log(err);
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.error(err.error.message, 'Sorry!');
            }, 1000);
        })
    }

    public showGroupProfiles(id,record, content) {
        console.log(record)
        var counter = 0;
        var groupMembers = [];
        record.forEach(list =>{
            groupMembers[counter] = list.id;
            counter++;
        });
      var that = this;
        var attrib = {kind: 'group_profile', training_session_group_id: parseInt(id)};
        this.http.post(`${environment.profileURL}/api/v1/pdfs/generate.json`,attrib).subscribe(data => {
            console.log(data);
            setTimeout(function() {
                that.app_type = 'Group Profile';
                that.pdf_type = 'profile';
              that.modal5 = that.modalService.open(content, { windowClass: 'in' });
            }, 1000);
        },
        err =>{
            console.log(err);
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.error(err.error.message, 'Sorry!');
            }, 1000);
        })
    }

    public groupThinkingProfiles(id,record, content) {
        console.log(record)
        var counter = 0;
        var groupMembers = [];
        record.forEach(list =>{
            groupMembers[counter] = list.id;
            counter++;
        });
      var that = this;
        var attrib = {kind: 'multiple_profiles', training_session_group_id: parseInt(id)};
        this.http.post(`${environment.profileURL}/api/v1/pdfs/generate.json`,attrib).subscribe(data => {
            console.log(data);
            setTimeout(function() {
                that.app_type = 'Thinking Profile';
                that.pdf_type = 'profile';
                that.modal5 = that.modalService.open(content, { windowClass: 'in grey' });
            }, 1000);
        },
        err =>{
            console.log(err);
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.error(err.error.message, 'Sorry!');
            }, 1000);
        })
    }

    public Open() {
        this.loading = false;
        this.userEmail = '';
        // this.modal2 = this.modalService.open(content, { windowClass: 'in grey' });
        $('.newMember-modal').css('display','block');
    }

    public closePopup(popupName = '') {
        if(popupName =='content') {
            $('.content-modal').css('display','none');
        } else {
            $('.newMember-modal').css('display', 'none');
        }
    }

    public addSessionmate() {
        this.http.get(`${environment.profileURL}/api/v1/organizations/`+this.orgId+`/teams.json`).subscribe(data => { 
            console.log(data);
            for(var i=0; i<data['teams'].length; i++){
                if(data['teams'][i].name == 'Unassigned'){
                    this.unassignedTeam = data['teams'][i].id;
                
                    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    var email = re.test(this.userEmail);
                    if(email && this.unassignedTeam){
                        this.loading = 1;
                        this.http.post(`${environment.profileURL}/api/v1/teams/`+this.unassignedTeam+`/add_members.json`,{
                          members_attributes: {
                            "0": {
                              email: this.userEmail,
                              first_name: 'demo',
                              role_id: 3
                            }
                          }
                        }).subscribe(result => {
                            console.log(result);
                            this.userID = result['team'].memberships[0];
                            this.loading = 1;
                            this.http.post(`${environment.profileURL}/api/v1/organizations/`+this.orgId+`/training_sessions/`+this.params_id+`/add_members.json`,{
                            member_ids:

                              [this.userID.member_id]


                            }).subscribe(result => {
                            console.log(result);
                            this.loading = 2;
                            this.members.push(this.userID);
                            this.modal2.close(result);
                            setTimeout(function() {
                                this.toastr.options = {
                                    closeButton: true,
                                    progressBar: true,
                                    timeOut: 2000
                                };
                                this.toastr.success('New Member was added successfully!', 'Great!');
                            }, 1000);
                            },response => {
                              console.log(response.data.errors);
                              this.loading = false;
                            })
                        },response => {
                              console.log(response.data.errors);
                              this.loading = false;
                        })
                    }
                }
            }
        },
            error => {
                console.log('oops', error)
                this.loading = false;
            }
        )
    }

    public checkedbox() {
        this.globalGroup = [];
        this.checkcount = 0;
        var that = this
        jQuery(".checkedbox:checkbox:checked").each(function() {
            var value=jQuery(this).val();
            console.log(value);
            for(var i=0;i<that.members.length;i++){
                if(that.members[i].id == value){
                    that.checkcount += 1;
                    that.globalGroup.push({
                        id:that.members[i].id,
                        name: that.members[i].first_name + ' ' + that.members[i].last_name,
                        email: that.members[i].email,
                        organization: that.members[i].company,
                        picture_url: that.members[i].picture_url,
                        default_profile: that.members[i].default_profile
                    });
                }
            }

        })
    }
    public checkAll() {
        var select = jQuery('#checkAll:checkbox:checked').length > 0;
        if(select == true){
            jQuery('.checkedbox').prop('checked',true);
            this.checkedbox();
        }
        else{
            jQuery('.checkedbox').prop('checked',false);
            this.checkedbox();
        }
    }

    public addSession(content, records){
        this.sessionID = '';
        this.loading = false;
        var counter = 0;
        this.sessionMembers = [];
        this.addtoSession = [];
        this.globalGroup.forEach(list =>{
            this.sessionMembers[counter] =  {id:list.id, name:list.name, email: list.email, default_profile: list.default_profile};
            this.addtoSession[counter] = list.id;
            counter++;

        });
        console.log(this.sessionMembers);
        this.modal3 = this.modalService.open(content, { windowClass: 'in grey' });

    }

    public addGroup(content, records){
        this.groupID = '';
        this.loading = false;
        var counter = 0;
        this.sessionMembers = [];
        this.addtoSession = [];
        this.globalGroup.forEach(list =>{
            this.sessionMembers[counter] =  {id:list.id, name:list.name, email: list.email, default_profile: list.default_profile};
            this.addtoSession[counter] = list.id;
            counter++;

        });
        console.log(this.sessionMembers);
        this.modal6 = this.modalService.open(content, { windowClass: 'in grey' });

    }

    public Delete(index,id){
        this.globalGroup.splice(index,1);
        this.sessionMembers.splice(index,1);
        this.addtoSession.splice(index,1);
        jQuery("input.checkedbox[value="+ id +"]").prop('checked',false);
        this.checkcount -= 1;
    }

    public openTeam(record){
      console.log(record);
        if(record == this.eSession){
              this.eSession = -1;
        }else{
            this.eSession = this.orgId;
            var that = this;
            setTimeout(function() {
                if(that.count > that.membersByPage){
                    that.render( that.curPage, that.item, true );
                }
            },0.1);
            
        }
    }

    public accDownload() {
        this.modal5.close();
        this.router.navigate(['/account-downloads']);
    }

    public changeView() {
        console.log(1);
        this.Compact();
    }

    public cloneSession() {
        this.http.get(`${environment.profileURL}/api/v1/organizations/`+this.orgId+`/training_sessions/`+this.params_id+`/clone.json`).subscribe(data => {
            console.log(data);
            // this.orgSessions.sessions.push(data['training_session'])
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.success(data['training_session'].name+' Session was created successfully.', 'Nice!');
            }, 1000);
        });
    }

    public deleteSession(){
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure, you want to delete this Training Session? This cannot be undone!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            cancelButtonColor: '##D0D0D0',
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result['value'] == true) {
                jQuery('.swal2-container').addClass('hide');
                jQuery('body').removeClass('swal2-shown');
                jQuery('html').removeClass('swal2-shown');
                this.http.delete(`${environment.profileURL}/api/v1/organizations/`+this.orgId+'/training_sessions/'+this.params_id+'.json').subscribe(result => {
                    console.log(result);
                    // for(var i=0; i<this.orgSessions.sessions.length; i++){
                    //     if(this.orgSessions.sessions[i].id == this.params_id){
                    //         this.orgSessions.sessions.splice(i,1);
                             this.router.navigate(['/orgs/'+this.orgId]);
                    //     }
                    // }

                },
                err =>{
                    console.log(err);
                    setTimeout(function() {
                        this.toastr.options = {
                            closeButton: true,
                            progressBar: true,
                            timeOut: 2000
                        };
                        this.toastr.error(err, 'Sorry!');
                    }, 1000);
                })
            }else{
                jQuery('.swal2-container').addClass('hide');
                jQuery('body').removeClass('swal2-shown');
                jQuery('html').removeClass('swal2-shown');
            }
        },function(dismiss){
            jQuery('.swal2-container').addClass('hide');
            jQuery('body').removeClass('swal2-shown');
            jQuery('html').removeClass('swal2-shown');
        });
    }

    public groupList(content) {
        var that = this;
        this.http.get(`${environment.profileURL}/api/v1/organizations/`+this.orgId+`/training_sessions/`+this.params_id+`/export_csv.json?`).subscribe(data => {
            console.log(data);
            setTimeout(function() {
                that.app_type = 'Group List';
                that.pdf_type = 'group list';
              that.modal5 = that.modalService.open(content, { windowClass: 'in grey' });
            }, 1000);
        });
    }

    public clearGroups() {
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure, you want to delete all Groups? This cannot be undone!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            cancelButtonColor: '##D0D0D0',
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result['value'] == true) {
                jQuery('.swal2-container').addClass('hide');
                jQuery('body').removeClass('swal2-shown');
                jQuery('html').removeClass('swal2-shown');
                var groups = this.groups;
                var length = this.groups.length;
                var nmbr =0;
                groups.forEach(list =>{
                    var that = this;
                    this.http.delete(`${environment.profileURL}/api/v1/training_session_groups/`+ list.id +'.json').subscribe(result => {
                        console.log(result);
                        nmbr++;
                        for(var j=0; j<this.groups.length; j++){
                            if(this.groups[j].id == list.id){                                
                                for(var x=0; x<this.groups[j].members.length; x++){
                                    this.members.push(this.groups[j].members[x]);
                                }
                                this.groups.splice(j,1);
                                setTimeout(function() {
                                    that.Compact()
                                },0.1);
                            }

                        }
                        if(nmbr == length){
                            this.preference = '';
                            setTimeout(function() {
                                this.toastr.options = {
                                    closeButton: true,
                                    progressBar: true,
                                    timeOut: 2000
                                };
                                this.toastr.success('All Groups were deleted successfully!', 'Nice!');
                            }, 1000);
                        }

                    },
                    err =>{
                        console.log(err);
                        setTimeout(function() {
                            this.toastr.options = {
                                closeButton: true,
                                progressBar: true,
                                timeOut: 2000
                            };
                            this.toastr.error(err, 'Sorry!');
                        }, 1000);
                    })
                })
            }else{
                jQuery('.swal2-container').addClass('hide');
                jQuery('body').removeClass('swal2-shown');
                jQuery('html').removeClass('swal2-shown');
            }
        },function(dismiss){
            jQuery('.swal2-container').addClass('hide');
            jQuery('body').removeClass('swal2-shown');
            jQuery('html').removeClass('swal2-shown');
        });
    }

    public dropMember(item,e){
        if(this.grp != item){
            if(this.grp == 'unassigned'){
                var memberId = {};
                memberId['allMembers'] = new Array();

                memberId['allMembers'].push(e);
                var that = this;
                this.http.post(`${environment.profileURL}/api/v1/training_session_groups/`+item+'/add_members.json',{member_ids: memberId['allMembers']}).subscribe(result => {
                    console.log(result);
                    for(var i=0; i< that.groups.length; i++){
                        if(item == that.groups[i].id){
                            that.groups[i] = result['training_session_group'];
                            setTimeout(function() {
                                that.Compact()
                            },100);
                            setTimeout(function() {
                                that.startDragDrop();
                            }, 1000);
                        }
                    }
                    for(var i=0; i< that.members.length; i++){
                        if(e == that.members[i].id){
                            that.members.splice(i,1);
                        }
                    }
                    
                    setTimeout(function() {
                        this.toastr.options = {
                            closeButton: true,
                            progressBar: true,
                            timeOut: 2000
                        };
                        this.toastr.success('Groups were updated successfully!', 'Nice!');
                    }, 1000);

                },error =>{
                    this.groups = this.groups;
                    this.members = this.members;
                    setTimeout(function() {
                        that.Compact()
                    },0.1);
                    setTimeout(function() {
                        this.toastr.options = {
                            closeButton: true,
                            progressBar: true,
                            timeOut: 2000
                        };
                        this.toastr.error('Groups were not updated!', 'Sorry!');
                    }, 1000);
                })

            }else if(item == 'unassigned'){
                var memberId = {};
                memberId['allMembers'] = new Array();

                memberId['allMembers'].push(e);
                var that = this;
                this.http.post(`${environment.profileURL}/api/v1/training_session_groups/`+this.grp+'/remove_members.json',{member_ids: memberId['allMembers']}).subscribe(result => {
                    console.log(result);
                    jQuery('li#'+e).remove();
                    for(var i=0; i< that.groups.length; i++){
                        if(that.grp == that.groups[i].id){
                            for(var j=0; j< that.groups[i].members.length; j++){
                                if(e == that.groups[i].members[j].id){
                                    that.members.push(that.groups[i].members[j]);
                                }
                            }
                            that.groups[i] = result['training_session_group'];
                            setTimeout(function() {
                                that.Compact()
                            },100);
                            setTimeout(function() {
                                that.startDragDrop();
                            }, 1000);
                        }
                    }
                    
                    setTimeout(function() {
                        this.toastr.options = {
                            closeButton: true,
                            progressBar: true,
                            timeOut: 2000
                        };
                        this.toastr.success('Groups were updated successfully!', 'Nice!');
                    }, 1000);

                }, error =>{
                        this.groups = this.groups;
                        this.members = this.members;
                        setTimeout(function() {
                            that.Compact()
                        },0.1);
                        setTimeout(function() {
                            this.toastr.options = {
                                closeButton: true,
                                progressBar: true,
                                timeOut: 2000
                            };
                            this.toastr.error('Groups were not updated!', 'Sorry!');
                        }, 1000);
 
                })
            }else{
                var memberId = {};
                memberId['allMembers'] = new Array();

                memberId['allMembers'].push(e);
                var that = this;
                this.http.post(`${environment.profileURL}/api/v1/training_session_groups/`+this.grp+'/remove_members.json',{member_ids: memberId['allMembers']}).subscribe(result => {
                    console.log(result);
                    for(var i=0; i< that.groups.length; i++){
                        if(that.grp == that.groups[i].id){
                            that.groups[i] = result['training_session_group'];                           
                        }
                    }
                    setTimeout(function() {
                        that.Compact()
                    },100);
                    this.http.post(`${environment.profileURL}/api/v1/training_session_groups/`+item+'/add_members.json',{member_ids: memberId['allMembers']}).subscribe(result => {
                        console.log(result);
                        for(var i=0; i< that.groups.length; i++){
                            if(item == that.groups[i].id){
                                that.groups[i] = result['training_session_group'];
                                setTimeout(function() {
                                    that.Compact()
                                },100);
                                setTimeout(function() {
                                    that.startDragDrop();
                                }, 1000);

                            }
                        }
                        
                        setTimeout(function() {
                            this.toastr.options = {
                                closeButton: true,
                                progressBar: true,
                                timeOut: 2000
                            };
                            this.toastr.success('Groups were updated successfully!', 'Nice!');
                        }, 1000);

                    },error =>{
                        this.groups = this.groups;
                        setTimeout(function() {
                            that.Compact()
                        },0.1);
                        setTimeout(function() {
                            this.toastr.options = {
                                closeButton: true,
                                progressBar: true,
                                timeOut: 2000
                            };
                            this.toastr.error('Member in moving group was not updated!', 'Sorry!');
                        }, 1000);
                    })
                }, error =>{
                    this.groups = this.groups;
                    setTimeout(function() {
                        that.Compact()
                    },0.1);
                    setTimeout(function() {
                            this.toastr.options = {
                                closeButton: true,
                                progressBar: true,
                                timeOut: 2000
                            };
                            this.toastr.error('Groups were not updated!', 'Sorry!');
                        }, 1000);
                })
            }
        }
    
    }

    public dragMember(item){
        this.grp = item;
        console.log(item);
    }

    public showProfileModal(content,member){
        this.memberProfile = member.default_profile;
        this.modal7 = this.modalService.open(content, { windowClass: 'in grey' });
    }

    public groupProfiles(content,profile) {
        this.group_profile = profile;
        console.log(profile);
        this.modal8 = this.modalService.open(content, { windowClass: 'in grey' });
    }

    public startDragDrop(){
        var that = this;
        jQuery( ".agile-list" ).sortable({
          connectWith: '.agile-list',
          start: function (event, ui) {
              that.dragMember(event.target.id);
           },
          receive: function (event, ui) {
              that.dropMember(event.target.id,that.member_id);
           },
           change: function (event, ui) {                      
              that.member_id = ui.item[0].id;
           }
        });
    }

    public chooseOption(){
        for(var i=0;i<this.allMembers.length;i++){
            this.globalMembers.push({
                id:this.allMembers[i].id,
                  name: this.allMembers[i].first_name + ' ' + this.allMembers[i].last_name,
                  email: this.allMembers[i].email,
                  organization: this.allMembers[i].company,
                  picture_url: this.allMembers[i].picture_url,
                  default_profile: this.allMembers[i].default_profile
            });
        }
        this.getOptions = true;
        setTimeout(function() {
            jQuery(document).ready(function(e){
                jQuery('input[name="member"]').iCheck({
                    checkboxClass: 'icheckbox_square-green',
                    radioClass: 'iradio_square-green',
                });
                jQuery('input[name="member"]').on('ifChecked', function(event){
                    jQuery('div.inner-single-box').removeClass('border-green');
                    jQuery(this).closest('div.inner-single-box').addClass('border-green');
                });
            });
        },0);
    }

}
