import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AssociatedEmailsComponent } from '../associated-emails.component';

@Component({
  selector: 'app-associated-collaboration-profile',
  templateUrl: './associated-collaboration-profile.component.html',
  styleUrls: ['./associated-collaboration-profile.component.scss']
})
export class AssociatedCollaborationProfileComponent {

  collabPf;
  constructor(public http: HttpClient,public associatedCompo: AssociatedEmailsComponent) {}

  async ngOnInit() {
    // this.getCollborationDetails();
  }


  // public getCollborationDetails() {
  //   this.http.get(`${environment.profileURL}/api/v1/users/collaboration_identity.json`).subscribe((res) => {
  //       console.log("collaboration pf", res);
  //       this.collabPf = res['branding_identity']
  //       this.associatedCompo.collabPfName = res.
  //     });
  // }
}
