import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
// import { Auth } from '../../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-team-report',
  templateUrl: './team-report.component.html',
  styleUrls: ['./team-report.component.scss']
})
export class TeamReportComponent implements OnInit {
	id:any;
	members:any;
	teamName:any;
	clarifier_low:number;
	clarifier_high:number;
	ideator_low:number;
	ideator_high:number;
	developer_low:number;
	developer_high:number;
	implementor_low:number;
	implementor_high:number;
	integrator:number;
	highest_score:number;
	orgName:any;
	orgId:any;
	env:any;

	constructor(public activatedRoute:ActivatedRoute, public http: HttpClient) { }

	ngOnDestroy() {
		document.title = "App | FourSight";
	}

  	ngOnInit() {
		this.env = environment;
  		this.activatedRoute.params.subscribe((params: Params) => {
            this.id = params.id
        })

        this.http.get(`${environment.profileURL}/api/v1/teams/`+this.id+`.json`).subscribe(data => {
            console.log(data);            
            this.orgName = data['team'].organization_name;
            this.teamName = data['team'].name;
            this.orgId = data['team'].organization_id;
        },
            error => console.log('oops', error)
        )

        this.http.get(`${environment.profileURL}/api/v1/teams/`+this.id+`/memberships.json?per_page=100`).subscribe(data => {
            console.log(data);
            this.members = data['memberships'];  
            this.clarifier_low = 0;
		    this.clarifier_high = 0;
		    this.ideator_high = 0;
		    this.ideator_low = 0;
		    this.developer_high = 0;
		    this.developer_low = 0;
		    this.implementor_high = 0;
		    this.implementor_low = 0;
		    this.integrator = 0;
		    this.highest_score = 0;
			this.members.forEach(list => {
				if (!list?.default_profile) return;
			
				// Initialize local variables for scores
				let defaultProfile = list.default_profile;
				let scores = {
					clarifier: defaultProfile.clarifier_score || list.clarifier_score,
					ideator: defaultProfile.ideator_score || list.ideator_score,
					developer: defaultProfile.developer_score || list.developer_score,
					implementer: defaultProfile.implementer_score || list.implementer_score
				};
			
				// Count and update highest scores
				['clarifier', 'ideator', 'developer', 'implementer'].forEach(role => {
					let lowKey = `${role}_low`;
					let highKey = `${role}_high`;
					let score = scores[role];
			
					if (score === 'Low') {
						this[lowKey] = (this[lowKey] || 0) + 1;
						this.highest_score = Math.max(this.highest_score, this[lowKey]);
					}
			
					if (score === 'High') {
						this[highKey] = (this[highKey] || 0) + 1;
						this.highest_score = Math.max(this.highest_score, this[highKey]);
					}
				});
			
				// Check if all scores are 'Neutral'
				let allNeutral = ['clarifier', 'ideator', 'developer', 'implementer'].every(role => 
					(scores[role] === 'Neutral')
				);
			
				if (allNeutral) {
					this.integrator = (this.integrator || 0) + 1;
				}
			});
        },
            error => console.log('oops', error)
        )
  	}

  	public printProfile(){
		document.title = 'FourSight-' + this.teamName + '-Team Report';
  		$('.navbar-default').hide();
        $('body').addClass('print-body');
        window.print();
        $('.navbar-default').show();
        $('body').removeClass('print-body');
  	}


}
