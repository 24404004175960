import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-team-members',
  templateUrl: './team-members.component.html',
  styleUrls: ['./team-members.component.scss']
})
export class TeamMembersComponent implements OnInit {
	@Input() team;
	members:any;
	meta:any;
	isLoading:any;
	query_string:any;
	count:any;
	curPage:any;
	pageLen:any;
	item:any;
	membersByPage:any;
	Query_String:any;
	first_name:any;
	last_name:any;
	globalGroup:any;
	checkcount:any;

  	constructor(public http: HttpClient) { }

	ngOnInit() {
		this.membersByPage = 5;
		this.isLoading = true;
		this.query_string = environment.profileURL+`/api/v1/teams/`+this.team+`/memberships.json?per_page=` + this.membersByPage;
		this.Query_String = environment.profileURL+`/api/v1/teams/`+this.team+`/memberships.json?`;
		this.http.get(`${environment.profileURL}/api/v1/teams/`+this.team+`/memberships.json?per_page=` + this.membersByPage).subscribe(data => {
            console.log(data);
            this.members = data['memberships'];
            this.meta = data['meta'];
            this.count = this.meta.total_count;
            this.isLoading = false;
           	this.pageLen = this.meta.total_pages;
           	this.curPage = 0;
           	this.item = [];
           	for(var i = 1; i<=this.pageLen;i++){
			    this.item.push(i);
			}
			if(this.meta.total_count > this.membersByPage){
				this.render( this.curPage, this.item, true );
	            console.log(data);
	        }  
        },
            error => console.log('oops', error)
        )
        localStorage.setItem('globalGroups','');
  	}

  	public isPageInRange = function( curPage, index, maxPages, pageBefore, pageAfter ) {
        if (index <= 1) {
	        // first 2 pages
	        return true;
        }
        if (index >= maxPages - 2) {
	        // last 2 pages
	        return true;
        }
        if (index >= curPage - pageBefore && index <= curPage + pageAfter) {
	        return true;
        }
	}
  	public render = function( curPage, item, first ) {

        var html = '', separatorAdded = false;
        for(var i in item){
            if ( this.isPageInRange( curPage, i, this.pageLen, 2, 2 ) ) {
                html += '<li  data-page="' + i + '">' + item[i] + '</li>';
                // as we added a page, we reset the separator Added
                separatorAdded = false;
            } else {
                if (!separatorAdded) {
                    // only add a separator when it wasn't added before
                    html += '<li class="separator" />';
                    separatorAdded = true;
                }
            }
        }
        var holder = document.querySelector('.holder-'+this.team);  
        if(this.meta.total_count > this.membersByPage){
            holder.innerHTML = html;
            if(document.querySelector('.holder-'+this.team+'>li[data-page="' + curPage + '"]') != null){document.querySelector('.holder-'+this.team+'>li[data-page="' + curPage + '"]').classList.add('active');}
            if ( first ) {
                holder.addEventListener('click', e => {
                    console.log(e.target['attributes']['data-page'].value);
                    if (!e.target['attributes']['data-page'].value) {
                        // no relevant item clicked (you could however offer expand here )
                        return;
                    }

                    curPage = parseInt(e.target['attributes']['data-page'].value);
                    var page_number = curPage+1;
                    this.isLoading = true;
                    this.http.get(`${this.query_string}&page=${page_number}`).subscribe(data => {
                    	console.log(data);
                        this.members = data['memberships'];
                		this.meta = data['meta']; 
                		this.count = this.meta.total_count;
                        this.pageLen = this.meta.total_pages;
                        this.isLoading = false;
                        this.curPage = 0;
                        this.item = [];
                        for(var i = 1; i<=this.pageLen;i++){
                            this.item.push(i);
                        }

                    },
                    error => console.log('oops', error)
                    )
                    this.render( curPage, item );
                });
            }
        }else{
            holder.innerHTML = null;
        }
    }

    public filter(){
        this.members = [];
    	this.query_string = this.Query_String+'&per_page=' + this.membersByPage;
        var first_name = jQuery('.first_name').val();
        var last_name = jQuery('.last_name').val();
        var profiles = jQuery('#profiles').val();
        if(first_name){
            this.query_string += '&first_name=' + first_name;
        }
        if(last_name){
            this.query_string += '&last_name=' + last_name;
        }
        if (profiles) {
            this.query_string += '&profile_type_id=' + profiles;
        }        
        this.isLoading = true;
        console.log(this.query_string);
        this.http.get(`${this.query_string}`).subscribe(data => {
            this.members = data['memberships'];
            this.meta = data['meta']; 
            this.count = this.meta.total_count;
            this.pageLen = this.meta.total_pages;
            this.curPage = 0;
            this.item = [];
            this.isLoading = false;
            for(var i = 1; i<=this.pageLen;i++){
                this.item.push(i);
            }
            console.log(true);
        	this.render( this.curPage, this.item, true );
        	console.log(data);        
        },
            error => console.log('oops', error)
        )
    }

    public length1(){
        this.first_name = jQuery('.first_name').val();
        if(this.first_name.length >= 3 || this.first_name.length == 0){
            this.filter();
        }
    }
    public length2(){
        this.last_name = jQuery('.last_name').val();
        if(this.last_name.length >= 3 || this.last_name.length == 0){
            this.filter();
        }
    }

    public resetFirstName() {
        jQuery('.first_name').val('');
        this.first_name = '';
        this.filter();
    }

    public resetLastName() {
        jQuery('.last_name').val('');
        this.last_name = '';
        this.filter();
    }

    public checkedbox(){
    	this.globalGroup = [];
    	this.checkcount = 0;
    	var that = this
        jQuery(".checkedbox-"+this.team+":checkbox:checked").each(function() {
            // console.log(jQuery('.checkedbox [value=]').val());
            var value = jQuery(this).val();
            console.log(value);
            	for(var i=0;i<that.members.length;i++){
                    if(that.members[i].id == value){
                        that.checkcount += 1;
                        that.globalGroup.push({
                            id: that.members[i].member_id,
				          	name: that.members[i].first_name + ' ' + that.members[i].last_name,
				          	email: that.members[i].email,
				          	organization: that.members[i].company,
				          	picture_url: that.members[i].picture_url,
				          	default_profile: that.members[i].default_profile
                        });
                	}
                }

        })
        localStorage.setItem('globalGroups',JSON.stringify(this.globalGroup))
    }

    public checkAll(){
    	var select = jQuery('#'+this.team+':checkbox:checked').length > 0;
        if(select == true){
            jQuery('.checkedbox-'+this.team).prop('checked',true);
            this.checkedbox();
        }
        else{
            jQuery('.checkedbox-'+this.team).prop('checked',false);
            this.checkedbox();
        }
    }

}
