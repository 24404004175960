import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { Auth } from '../../../auth/auth.service';
// Import the AuthService type from the SDK
// import { AuthService } from '@auth0/auth0-angular';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { OrgsIndividualComponent } from './../orgs-individual.component';

@Component({
  selector: 'app-session-profile',
  templateUrl: './session-profile.component.html',
  styleUrls: ['./session-profile.component.scss']
})
export class SessionProfileComponent implements OnInit {
	id:any;
	members:any;
	teamName:any;
	orgName:any;
	clarifier_low:number;
	clarifier_high:number;
	ideator_low:number;
	ideator_high:number;
	developer_low:number;
	developer_high:number;
	implementor_low:number;
	implementor_high:number;
	integrator:number;
	highest_score:number;
	app_type:any;
	pdf_type:any;
	download_profile:any;
	Email:any;
	orgId:any;

  	constructor(public activatedRoute:ActivatedRoute, public router: Router, public http: HttpClient, public orgs: OrgsIndividualComponent, public auth: Auth) { }

  	ngOnInit() {
  		this.download_profile = false;
  		this.activatedRoute.params.subscribe((params: Params) => {
  			console.log(params);
            this.id = params.id
        })
  		
  		this.orgId = this.orgs.id;
        this.auth.getProfile((err, profile) => {
            this.Email = profile.email;
        })
		// this.auht.getUser().subscribe((user) => {
		// 	this.Email = user.email;
		// })

        this.http.get(`${environment.profileURL}/api/v1/organizations/`+this.orgId+`.json`).subscribe(data => {
            console.log(data);            
            this.orgName = data['organization'].name;
        },
            error => console.log('oops', error)
        )

        this.http.get(`${environment.profileURL}/api/v1/organizations/`+this.orgId+`/training_sessions/`+this.id+`.json?per_page=100`).subscribe(data => {
            console.log(data);            
            this.members = data['training_session'].members;
            this.teamName = data['training_session'].name;  
            this.clarifier_low = 0;
		    this.clarifier_high = 0;
		    this.ideator_high = 0;
		    this.ideator_low = 0;
		    this.developer_high = 0;
		    this.developer_low = 0;
		    this.implementor_high = 0;
		    this.implementor_low = 0;
		    this.integrator = 0;
		    this.highest_score = 0;
			this.members.forEach(list => {

		        if(list.default_profile.clarifier_score == 'Low'){
		          	this.clarifier_low = this.clarifier_low + 1;
		          	if( this.clarifier_low > this.highest_score ){
						this.highest_score = this.clarifier_low;
			    	}
		        }

		        if(list.default_profile.clarifier_score == 'High'){
		          	this.clarifier_high = this.clarifier_high + 1;
		          	if( this.clarifier_high > this.highest_score ){
						this.highest_score = this.clarifier_high;
			    	}
		        }

		        if(list.default_profile.ideator_score == 'Low'){
		          	this.ideator_low = this.ideator_low + 1;
		          	if( this.ideator_low > this.highest_score ){
						this.highest_score = this.ideator_low;
			    	}
		        }

		        if(list.default_profile.ideator_score == 'High'){
		          	this.ideator_high = this.ideator_high + 1;
		          	if( this.ideator_high > this.highest_score ){
						this.highest_score = this.ideator_high;
			    	}
		        }

		        if(list.default_profile.developer_score == 'Low'){
		          	this.developer_low = this.developer_low + 1;
		          	if( this.developer_low > this.highest_score ){
						this.highest_score = this.developer_low;
			    	}
		        }

		        if(list.default_profile.developer_score == 'High'){
		          	this.developer_high = this.developer_high + 1;
		          	if( this.developer_high > this.highest_score ){
						this.highest_score = this.developer_high;
			    	}
		        }

		        if(list.default_profile.implementer_score == 'Low'){
		          	this.implementor_low = this.implementor_low + 1;
		          	if( this.implementor_low > this.highest_score ){
						this.highest_score = this.implementor_low;
			    	}
		        }

		        if(list.default_profile.implementer_score == 'High'){
		          	this.implementor_high = this.implementor_high + 1;
		          	if( this.implementor_high > this.highest_score ){
						this.highest_score = this.implementor_high;
			    	}
		        }

		        if(list.default_profile.clarifier_score == 'Neutral' && list.default_profile.ideator_score == 'Neutral' && list.default_profile.developer_score == 'Neutral' && list.default_profile.implementer_score == 'Neutral'){
		          	this.integrator += 1;
		        }

			});
        },
            error => console.log('oops', error)
        )
  	}

  	public printProfile(){
  		$('.navbar-default').hide();
        $('body').addClass('print-body');
        window.print();
        $('.navbar-default').show();
        $('body').removeClass('print-body');
  	}

  	public showTeam(){
        this.download_profile = false;
        var that = this;
        var counter = 0;
        var groupMembers = [];
        this.members.forEach(list =>{
            groupMembers[counter] = list.id;
            counter++;
        });
        var attrib = {kind: 'group_profile', member_ids: groupMembers};
        this.http.post(`${environment.profileURL}/api/v1/pdfs/generate.json`,attrib).subscribe(data => {
            console.log(data);
            that.app_type = 'Session Profile';
            that.pdf_type = 'profile';
            that.download_profile = true;
        },
        err =>{
            console.log(err);
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.error(err.error.message, 'Sorry!');
            }, 1000);
        })
    }

    public accDownload() {
        this.router.navigate(['/account-downloads']);
    }

    public showGraph(){
        this.download_profile = false;
        var that = this;
        var counter = 0;
        var groupMembers = [];
        this.members.forEach(list =>{
            groupMembers[counter] = list.id;
            counter++;
        });
        var attrib = {kind: 'graph', member_ids: groupMembers};
        this.http.post(`${environment.profileURL}/api/v1/pdfs/generate.json`,attrib).subscribe(data => {
            console.log(data);
            that.app_type = 'Session Graph';
            that.pdf_type = 'graph';
            that.download_profile = true;
        },
        err =>{
            console.log(err);
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.error(err.error.message, 'Sorry!');
            }, 1000);
        })
    }

}


