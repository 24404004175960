// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-mp4 video {
  height: 460px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/cig/cig-video/cig-video.component.scss"],"names":[],"mappings":"AACI;EACI,wBAAA;AAAR","sourcesContent":[".video-mp4{\n    video {\n        height: 460px !important;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
