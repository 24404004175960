import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-cn-print',
  templateUrl: './cn-print.component.html',
  styleUrls: ['./cn-print.component.scss']
})
export class CnPrintComponent implements OnInit {

  env:any;
  constructor(public router:Router) { }

  ngOnInit(): void {
    this.env = environment;
  }

  activeRoute(routename: string): boolean{
    return this.router.url.indexOf(routename) > -1;
  }  

}
