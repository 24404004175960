import { Component, Injector } from '@angular/core';
import { OrgIndividualTeamsComponent } from '../../org-individual-teams/org-individual-teams.component';
import { OrgsComponent } from '../../orgs/orgs.component'
import { Router } from '@angular/router';;
@Component({
  selector: 'app-update-teampopup',
  templateUrl: './update-teampopup.component.html',
  styleUrls: ['./update-teampopup.component.scss']
})
export class UpdateTeampopupComponent {

  injectedCompo:any;
  constructor(private injector: Injector, private router: Router) {
    if(this.router.url.includes('/orgs') && this.router.url.includes('/teams')) {
      this.injectedCompo = this.injector.get(OrgIndividualTeamsComponent);     
    } else {
      this.injectedCompo = this.injector.get(OrgsComponent);     
    }
  }

}

