import { Component, OnInit } from '@angular/core';
import { APIService } from '../../API.service';
import {AccessGroupService} from './../access-group.service';
// import {Cache} from '@aws-amplify/cache';
// import { AuthService } from '@auth0/auth0-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { int } from 'aws-sdk/clients/datapipeline';
import { DatepickerOptions } from 'ng2-datepicker';
import { MapTypeService } from './../../map-type.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js'
// import 'sweetalert2/src/sweetalert2.scss';
import { Auth } from '../../auth/auth.service';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-admin-learner-maps-list',
  templateUrl: './admin-learner-maps-list.component.html',
  styleUrls: ['./admin-learner-maps-list.component.scss']
})
export class AdminLearnerMapsListComponent implements OnInit {

  public allmaps: any;
  private maps: any = [];
  public allmaptypes: any = [];
  private allOwners: any = [];
  private editLm: string = null;
  private editLmType: string = null;
  private editMapName: string = '';
  private editMapTypeName: string = '';
  private addMap: any;
  private addMapType: any;
  private listMapType: any;
  public new_map: string;
  public new_maptype: any;
  public new_mapType: string = '';
  public searchMap: string = '';
  public new_mapLocation: string = '';
  public new_mapImage: string = '';
  public prework_days: number;
  public assessment_deadline: number;
  public before_day_point: number;
  public days_test_opens: number;
  public days_test_close: number;
  public first_training: string = new Date().toDateString();
  public last_training: string = new Date().toDateString();
  public createdAt: string = new Date().toDateString();
  public map_owner: string;
  private edit_maptype: string;
  private edit_Owner: string;
  private edit_location: string;
  public isLoading:boolean = false;
  private feature_grp:any = [];
  private feature_grp_prod:any = [];
  private lm_titles:any = [];
  breadCrumbFor:string = '';
  ngrxAccessGroup:any;

  options: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2030,
    format: 'DD-MM-YYYY',
    formatTitle: 'DD-MM-YYYY',
    firstCalendarDay: 0,
    placeholder: 'Click to select a date', // HTML input placeholder attribute (default: '')
    inputClass: 'form-control', // 0 - Sunday, 1 - Monday
  };

  constructor(private accessgroup: AccessGroupService, private api: APIService, private modalService: NgbModal, private mapService: MapTypeService, private router: Router, private auth: Auth, public store:Store<any>) { }

  ngOnInit() {
    this.ngrxAccessGroup = this.store.select<any>((state) => state.profile.accessGroupData);
    this.ngrxAccessGroup.subscribe(message => { 
        if(message && message.length > 0){                
            if(!message['main_menu'].learner_maps_admin){
                this.router.navigate(['/dashboard']);
                return false;
            }
        }
    });

    // },
    // err => {
    //     // Log errors if any
    //     console.log(err);
    // })

    // // Update Token for OIDC
    // this.auth.getIdTokenClaims().subscribe(message => {
    //   if(message.__raw){
    //     Cache.setItem('federatedInfo', { token: message.__raw });
    //   }else{
    //     console.log('TOKEN FAILURE!');
    //   }

    // });


    this.AllLearnerMaps();
  }

    public compare(a, b) {
        // Use toUpperCase() to ignore character casing
        const titleA = a.title.toUpperCase();
        const titleB = b.title.toUpperCase();

        let comparison = 0;
        if (titleA > titleB) {
            comparison = 1;
        } else if (titleA < titleB) {
            comparison = -1;
        }
        return comparison;
    }

  private async AllLearnerMaps() {
    let email = localStorage.getItem('email')?.toLowerCase();
    this.map_owner = localStorage.getItem('email');
    let that = this;
    let filter = {owners:{contains:email}};
    let fg:any;

    console.log('Before LM Request');
    await this.api.ListLearnerMaps(filter,100).then(function (maps) {
        that.isLoading = true;
        that.allmaps = maps.items.sort(function (a, b) {
            // Use toUpperCase() to ignore character casing
            const titleA = a.title.toUpperCase();
            const titleB = b.title.toUpperCase();

            let comparison = 0;
            if (titleA > titleB) {
                comparison = 1;
            } else if (titleA < titleB) {
                comparison = -1;
            }
            return comparison;
            
        })
        that.maps = maps.items.sort(function (a, b) {
            // Use toUpperCase() to ignore character casing
            const titleA = a.title.toUpperCase();
            const titleB = b.title.toUpperCase();

            let comparison = 0;
            if (titleA > titleB) {
                comparison = 1;
            } else if (titleA < titleB) {
                comparison = -1;
            }
            return comparison;
            
        })
        // that.feature_grp = fg;
        maps.items.forEach((record, index)=> {
          let hash:number = 0;
          let feature_staging = '';
          let feature_production = '';
          let title = '';
          // remove feature between #
          for(let  i = 0; i < record.title.length; i++) {
            if(record.title[i] === '#') {
              hash += 1;
              continue;
            }

            if(hash > 0 && hash < 2) {
              feature_staging += record.title[i];
            }
              
            if(hash > 1) {
              feature_production += record.title[i];
            }

            if(hash < 1) {
              title += record.title[i];
            }
            // if(hash > 0 && hash < 3) {
            //   feature += record.title[i];
            // }
          }
          that.allmaps[index].title = title;
          that.lm_titles.push(title);
          that.feature_grp.push(feature_staging);
          that.feature_grp_prod.push(feature_production)
        })
    })

    this.allmaptypes = await this.api.ListMapTypes().then(function (data) {
        console.log(data);
      return data['items'];
    });
    this.allOwners = await this.api.ListMembers().then(function (data) {
      return data['items'];
    });
  }

    

  public async NewLearnerMap() {    
    let that = this;
    if (this.new_map && this.new_mapLocation && this.new_maptype) {
      this.createdAt = new Date(this.createdAt).toISOString();
      this.first_training = new Date(this.first_training).toISOString();
      this.last_training = new Date(this.last_training).toISOString();
      await this.api.CreateLearnerMap({ title: this.new_map, owners: this.map_owner.toLowerCase(), learnerMapMaptypeId: this.new_maptype, location: this.new_mapLocation, image: this.new_mapImage, daysOfPrework: this.prework_days, daysBeforeAssessmentDeadline: this.assessment_deadline, daysBeforePointDue: this.before_day_point, daysAfterTrainingBeforeTestOpens: this.days_test_opens, daysAfterTrainingBeforeTestCloses: this.days_test_close, createdAt: this.createdAt, trainingFirstDay: this.first_training, trainingLastDay: this.last_training }).then(async function (data) {
        that.addMap.close();
        that.AllLearnerMaps();
        if (data.maptype.title == 'Mindset') {
          that.mapService.mindset.forEach((column, index) => {
            that.api.CreateColumn({
              title: column.title,
              order: column.order,
              open: column.open,
              close: column.close,
              columnLearnermapId: data.id
            }).then(function (res) {
              if (that.mapService.mindset.length - 1 == index) {
                that.AllLearnerMaps();
              }
              column.cards.forEach(card => {
                that.api.CreateCard({
                  title: card['title'],
                  type: card['type'],
                  lesson: card['lesson'],
                  medium: card['medium'],
                  runtime: card['runtime'],
                  parent: card['parent'],
                  link: card['link'],
                  bg: card['bg'],
                  body: card['body'],
                  button: card['button'],
                  text: card['text'],
                  target: card['target'],
                  open: card['open'],
                  close: card['close'],
                  order: card['order'],
                  cardColumnId: res.id
                })
              })
            })
          })
        }
        setTimeout(function() {
            this.toastr.options = {
                closeButton: true,
                progressBar: true,
                timeOut: 2000
            };
            this.toastr.success('New learner map has been created!', 'Nice!');
        }, 1000);
      });
    }
  }

    private async DeleteLearnerMap(lmid: string) {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure, you want to delete this Learner Map? This cannot be undone!",
            type: "warning",
            showCancelButton: true,
            focusCancel: true,
            confirmButtonColor: "#dc3545",
            cancelButtonColor: '#28a745',
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result['value'] == true) {
                Swal.fire({
                    title: "Are you sure?",
                    text: "Are you really sure, you want to delete this Learner Map? This cannot be undone!",
                    type: "warning",
                    showCancelButton: true,
                    focusCancel: true,
                    confirmButtonColor: "#dc3545",
                    cancelButtonColor: '#28a745',
                    confirmButtonText: "Yes, delete it!"
                }).then((result) => {
                    if (result['value'] == true) {
                        jQuery('.swal2-container').addClass('hide');
                        jQuery('body').removeClass('swal2-shown');
                        jQuery('html').removeClass('swal2-shown');
                        this.api.DeleteLearnerMap({ id: lmid }).then(function () {
                            that.allmaps.forEach((map, index) => {
                                if (map.id == lmid) {
                                    that.allmaps.splice(index, 1);
                                }
                            });
                            setTimeout(function() {
                                this.toastr.options = {
                                    closeButton: true,
                                    progressBar: true,
                                    timeOut: 2000
                                };
                                this.toastr.success('Your learner map has been deleted!', 'Nice!');
                            }, 1000);
                        })
                    }else{
                        jQuery('.swal2-container').addClass('hide');
                        jQuery('body').removeClass('swal2-shown');
                        jQuery('html').removeClass('swal2-shown');
                    }
                })
            }else{
                jQuery('.swal2-container').addClass('hide');
                jQuery('body').removeClass('swal2-shown');
                jQuery('html').removeClass('swal2-shown');
            }
        },function(dismiss){
            jQuery('.swal2-container').addClass('hide');
            jQuery('body').removeClass('swal2-shown');
            jQuery('html').removeClass('swal2-shown');
        });
    }

    private async DeleteLearnerMapType(lmtypeid: string) {
        let that = this;
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure, you want to delete this Learner Map Type? This cannot be undone!",
            type: "warning",
            showCancelButton: true,
            focusCancel: true,
            confirmButtonColor: "#dc3545",
            cancelButtonColor: '#28a745',
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result['value'] == true) {
                jQuery('.swal2-container').addClass('hide');
                jQuery('body').removeClass('swal2-shown');
                jQuery('html').removeClass('swal2-shown');
                this.api.DeleteMapType({ id: lmtypeid }).then(function () {
                    that.allmaptypes.forEach((mapType, index) => {
                        if (mapType.id == lmtypeid) {
                            that.allmaptypes.splice(index, 1);
                        }
                    });
                    setTimeout(function() {
                        this.toastr.options = {
                            closeButton: true,
                            progressBar: true,
                            timeOut: 2000
                        };
                        this.toastr.success('Your map type has been deleted!', 'Nice!');
                    }, 1000);
                })
            }else{
                jQuery('.swal2-container').addClass('hide');
                jQuery('body').removeClass('swal2-shown');
                jQuery('html').removeClass('swal2-shown');
            }
        },function(dismiss){
            jQuery('.swal2-container').addClass('hide');
            jQuery('body').removeClass('swal2-shown');
            jQuery('html').removeClass('swal2-shown');
        });

    }

    private editMap(lm) {
        this.editLm = lm.id;
        this.editMapName = lm.title;
        this.edit_maptype = lm.maptype.id;
        this.edit_Owner = lm.owners;
        this.edit_location = lm.location;
    }

    private cancelSave() {
        this.editLm = null;
        this.editMapName = '';
    }

  private editMapType(lmtype) {
    this.editLmType = lmtype.id;
    this.editMapTypeName = lmtype.title;
  }

  private cancelSaveLMType() {
    this.editLmType = null;
    this.editMapTypeName = '';
  }

  private async cloneMap(map) {
    var that = this;
    await this.api.GetLearnerMap(map.id).then(function (lm) {
      console.log(map);
      that.api.CreateLearnerMap({
        title: lm.title + ' Clone',
        location: lm.location,
        trainingFirstDay: lm.trainingFirstDay,
        trainingLastDay: lm.trainingLastDay,
        daysOfPrework: lm.daysOfPrework,
        owners: lm.owners.toLowerCase(),
        daysBeforeAssessmentDeadline: lm.daysBeforeAssessmentDeadline,
        daysAfterTrainingBeforeTestOpens: lm.daysAfterTrainingBeforeTestOpens,
        daysAfterTrainingBeforeTestCloses: lm.daysAfterTrainingBeforeTestCloses,
        daysBeforePointDue: lm.daysBeforePointDue,
        learnerMapMaptypeId: lm.maptype.id
      }).then(function (data) {
        that.allmaps.push(data);
        lm.columns.items.forEach(column => {
          that.api.CreateColumn({
            title: column.title,
            order: column.order,
            open: column.open,
            close: column.close,
            columnLearnermapId: data.id
          }).then(function (res) {
            that.allmaps[that.allmaps.length - 1].columns.items.push(res);
            column['cards'].items.forEach(card => {
              that.api.CreateCard({
                title: card.title,
                type: card.type,
                lesson: card.lesson,
                medium: card.medium,
                runtime: card.runtime,
                parent: card.parent,
                link: card.link,
                bg: card.bg,
                body: card.body,
                button: card.button,
                text: card.text,
                target: card.target,
                open: card.open,
                close: card.close,
                order: card.order,
                cardColumnId: res.id
              })
            })
          })
        })
        setTimeout(function() {
            this.toastr.options = {
                closeButton: true,
                progressBar: true,
                timeOut: 2000
            };
            this.toastr.success('Your learner map has been created!', 'Nice!');
        }, 1000);
      });
    });
  }

  private async saveMap(map, i) {
    var that = this;
    if(this.editMapName == '' || this.edit_maptype == '' || this.edit_location == ''){
        setTimeout(function() {
            this.toastr.options = {
                closeButton: true,
                progressBar: true,
                timeOut: 4000
            };
            this.toastr.error('Invalid input!', 'Oops!');
        }, 1000);
    }else{
        await this.api.UpdateLearnerMap({
          id: map.id,
          title: that.editMapName,
          learnerMapMaptypeId: this.edit_maptype,
          owners: this.edit_Owner.toLowerCase(),
          location: this.edit_location
        }).then(function (data) {
          that.editLm = null;
          that.AllLearnerMaps();
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.success('Your learner map has been updated!', 'Nice!');
            }, 1000);
          that.allmaps[i] = data;
        })
    }
  }

  private async saveMapType(type, i) {
    var that = this;
    await this.api.UpdateMapType({
      id: type.id,
      title: that.editMapTypeName
    }).then(function (data) {
      that.editLmType = null;
        setTimeout(function() {
            this.toastr.options = {
                closeButton: true,
                progressBar: true,
                timeOut: 2000
            };
            this.toastr.success('Your map type has been updated!', 'Nice!');
        }, 1000);
      that.allmaptypes[i] = data;
    })
  }

  public open(content,lm:boolean =false) {
    if(content == 'addlm') {
      console.log('running')
      $('.addlm-modal').css('display','block');
    }
    if(lm){
      setTimeout(() => {
        $('.modal-content').addClass('modal-dialog--wd');
      })
    }
    // this.addMap = this.modalService.open(content, { size: 'lg', windowClass: 'in grey modalParent' });
    this.new_map = '';
    this.new_maptype = '';
    this.new_mapImage = '';
    this.new_mapLocation = '';
    this.new_maptype = '';
    this.prework_days = null;
    this.assessment_deadline = null;
    this.before_day_point = null;
    this.days_test_opens = null;
    this.days_test_close = null;
    this.createdAt = new Date().toDateString();
    this.first_training = new Date().toDateString();
    this.last_training = new Date().toDateString();
  }

  public openLMtype(lmtype:boolean =false) {
    console.log('running...')
    $('.addlmType-modal').css('display','block');
    if(lmtype) {
      setTimeout(() => {
        $('.modal-content').addClass('modal-dialog--swd');
      })
    }
    // this.addMapType = this.modalService.open(content, { size: 'sm', windowClass: 'in grey modalParent' });
  }

  public listLMtype(lmtype:boolean =false) {
    if(lmtype) {
      setTimeout(() => {
        $('.modal-content').addClass('modal-dialog--lwd');
      })
    }
    this.editLmType = null;
    $('.listlmtype-modal').css('display','block');
    // this.listMapType = this.modalService.open(content, { windowClass: 'in grey modalParent' });
  }

  public async addLMType() {
    var that = this;
    await this.api.CreateMapType({ title: this.new_mapType }).then(function () {
      that.addMapType.close();
      setTimeout(function() {
            this.toastr.options = {
                closeButton: true,
                progressBar: true,
                timeOut: 2000
            };
            this.toastr.success('New map has been added!', 'Nice!');
        }, 1000);
      that.new_mapType = '';
      that.AllLearnerMaps();
    })
  }

  public filterMaps() {
    if (this.searchMap.length >= 3) {
      this.allmaps = this.maps.filter(map => map.title.toLowerCase().indexOf(this.searchMap.toLowerCase()) !== -1);
    }
    if (this.searchMap.length == 0) {
        this.isLoading = false;
      this.ngOnInit();
    }
  }

  private resetName(){
    this.searchMap = '';
    this.ngOnInit();
  }

}

