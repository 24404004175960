// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	production: 			true,
	developer:              false,
	appv: 				  	'v16.1.432',
	slideVersion:           'v16.1.1002',
	adminUpload: 			'https://s3.ca-central-1.amazonaws.com/assets.foursightonline.com/',
	admin_upload: 			'https://assets.foursightonline.com/admin-uploads/docs/',
	versionCheckURL:		'https://app-staging.foursightonline.com/version.json',
	clientID: 				'5CVZit1Up1dUlzQdZHMHMrrj9vMBcjvS',
	callbackURL: 			'https://app.foursightonline.com/callback',
	redirectURL: 			'https://app.foursightonline.com/silent-prod.html',
	profileURL:				'https://i.foursightonline.com',
	storeURL:				'https://foursightonline.com/wp-json/wc/v2',
	assessment_admin:		'https://i.foursightonline.com/',
	lambda_pf_footer:		'https://aa.foursightonline.com/',
	features_admin:			'https://i.foursightonline.com/admin',
	store_url:				'https://foursightonline.com/members/?redirect_to=https://mindset/src/environments/environment.staging.tsfoursightonline.com/store/',
	store:					'https://foursightonline.com/store/',
	enable_date:			true,
	display_date:			false,
	s3_upload:      		'https://assets.foursightonline.com/app',
	assessment_pdt_id:		1,
	toolcards_pdt_id:		6,
	stripe_api_key:       	'pk_live_Ks3hTNu3dhKhmeU8L5zfPOqQ',
	accessKeyId:            '',
    secretAccessKey:        '',
	copyright_year: 		'2024'

};