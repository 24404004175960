import { Component, OnInit } from '@angular/core';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-cn-framework',
  templateUrl: './cn-framework.component.html',
  styleUrls: ['./cn-framework.component.scss']
})
export class CnFrameworkComponent implements OnInit {

  env:any;

  constructor() { }

  ngOnInit(): void {
    this.env = environment;
  }

}
