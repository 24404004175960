import { Component, Input, OnInit } from '@angular/core';
import { ChallengeSummaryService } from '../../challenge-summary.service';

@Component({
  selector: 'app-cn-delete-popup',
  templateUrl: './cn-delete-popup.component.html',
  styleUrls: ['./cn-delete-popup.component.scss']
})
export class CnDeletePopupComponent implements OnInit {

  @Input() whichpf;

  constructor(public allchallenge: ChallengeSummaryService) { }

  ngOnInit(): void {
    console.log(this.whichpf);
  }

  public delData() {
    this.allchallenge.delData.next(true)
  }

  public close() {
    $('.cn-dmodal').css('display', 'none');
  }

}
