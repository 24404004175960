import { Component, ViewChild, OnInit, Input, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { APIService } from '../../../../API.service';
import { Router, NavigationEnd, ActivatedRoute, Params  } from '@angular/router';
import { environment } from './../../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { LmTsService } from '../../lm-ts.service';
import { LambdaFunctionService } from '../../../../service/lambda-function.service';

declare var jQuery:any;
declare var hbspt:any;

@Component({
  selector: 'app-dynamic-card-single',
  templateUrl: './dynamic-card-single.component.html',
  styleUrls: ['./dynamic-card-single.component.scss']
})
export class DynamicCardSingleComponent implements OnInit {

    public currentCard_id:string;
    public cardName:string;
    public currentCard:any;
    public show_card:boolean;
    private currentDate:number;
    private BeginDateTime:number;
    private EndDateTime:number;
    public plan_qmp:boolean = false;
    public plan_cmf:boolean = false;
    public env:any;
    public url:any;
    public allCols:any;
    nextCard:string;
    guide:any;
    guides:any;
    email: string = localStorage.getItem('email');
    short_guide:string;
    long_guide:string;
    previousCard:string;
    nextColumn:string;
    previousColumn:string;
    nextTitle:string;
    previousTitle:string;
    firstname:string = '';
    lastname:string = '';
    marked: boolean;
    currentLM:any = '';
    currentLM_id:any;
    lm_maptype:string = '';
    slides_text:any;
    current_slide_value:any;
    sl_index:number;
    slides:any;
    @Input() allLmData:any;
    lm_id:string = '';
    main_app_enable:boolean = true;
    wistia_src:any;
    columns:any;
    cardView:boolean = false;

    constructor(private http: HttpClient, private viewContainerRef: ViewContainerRef, public router: Router, private api: APIService, private activatedRoute: ActivatedRoute, private sanitizer: DomSanitizer, public lm_ts:LmTsService,public lmda_service:LambdaFunctionService) { }

    // ngOnchange() {
    //     this.ngOnInit();
    // }

    async ngOnInit() {
        var that = this;
        this.env = environment;
        console.log(localStorage.getItem('fs_reveal_lm_id'))
        if(this.router.url.includes('fs-reveal')) {
            that.main_app_enable = false;
            that.lm_id = localStorage.getItem('fs_reveal_lm_id');
            console.log(that.lm_id)
            if(that.lm_id != '' && that.lm_id != undefined) {
                await this.api.GetLearnerMap(that.lm_id).then(function (map) {
                    console.log(map)
                    that.allCols = {
                        "columns": {
                            "items":map?.columns?.items
                        }
                    }
                    // that.allCols.allcolumns = map.columns.items;
                    that.lm_maptype = map.maptype['title'];     
                }).then(()=> {
                    setTimeout(() => {
                        that.getCard();
                    }, 100);
                    console.log(that.allCols);

                })
            }
            this.routerEvent();
        } else if(this.router.url.includes('challenge-navigator-print')) {
            let args = {
                "lm_id":  localStorage.getItem('fs_reveal_lm_id')
            }
            const myPromise = new Promise(async (resolve, error)=>{
                let x = await this.lambda_lmdata(0,args)
                setTimeout(() => {
                    resolve(x)
                }, 2000);
            });
            myPromise.then(()=> {
                this.getCard();
            })
            this.routerEvent();
        } else {
            that.main_app_enable = true;
            this.activatedRoute.params.subscribe((params: Params)=>{
                console.log(params);
                that.api.GetLearnerMap(params?.id).then(function (map) {
                    console.warn(map)
                    that.allCols = map;
                }).then(()=>{
                    this.getCard();
                    this.routerEvent();
                    this.activatedRoute.params.subscribe((params: Params) => {
                        this.currentLM = '';
                        if(this.currentLM_id != params['id']){
                            this.currentLM_id = params['id'];
                            var that = this;
                            this.api.GetLearnerMap(this.currentLM_id).then(function (map) {
                                console.log(map)
                                that.lm_maptype = map?.maptype['title'];     
                            })
                        }
                    })
                })
            })

            // that.main_app_enable = true;
            // console.log(this.viewContainerRef)
            // this.allCols = this.viewContainerRef[ '_data' ]?.componentView?.component?.viewContainerRef[ '_view' ]?.viewContainerParent?.component;
            // console.log(this.allCols)
            // this.getCard();
            // this.routerEvent();
            // this.activatedRoute.params.subscribe((params: Params) => {
            //     this.currentLM = '';
            //     if(this.currentLM_id != params['id']){
            //         this.currentLM_id = params['id'];
            //         var that = this;
            //         this.api.GetLearnerMap(this.currentLM_id).then(function (map) {
            //             console.log(map)
            //             that.lm_maptype = map.maptype['title'];     
            //         })
            //     }
            // })
        }


        
    }

    public routerEvent() {
        this.router.events.subscribe(e => {
            if (e instanceof NavigationEnd){
                $('html,body').animate({scrollTop: $('.page-heading').offset().top},100);
                this.getCard();
            }
        });
    }


    public scrollDiv = function(id){
        $('html,body').animate({
            scrollTop: $("#"+id)?.offset()?.top},
        'slow');
    }

    public getGuide(){
        for(var i=0; i<this.guides.length; i++){
            if(this.guides[i].language_code == this.guide){
                this.short_guide = this.guides[i].short_guide;
                this.long_guide = this.guides[i].long_guide;
            }
        }
    }

    public getCard(card_id = ''){
        this.currentCard_id = card_id;
        if(this.router.url.indexOf('card') != -1){
            // this.currentCard_id = params['id'];
            this.currentCard_id = this.router.url.substring(this.router.url.indexOf('card')+5,this.router.url.length);
        }
        console.log('get card',this.currentCard_id)

        if(this.currentCard_id !== '' && !this.router.url.includes('challenge-navigator-print')){ 
            console.log('entered..') 
            var that = this;
            var firstName = {
                email: localStorage.getItem('email'),
                property: "firstname"
            }
            var lastName = {
                email: localStorage.getItem('email'),
                property: "lastname"
            }

            this.api.GetCard(this.currentCard_id).then(function (card) {
                  that.cardName = card?.title;
                  that.currentCard = card;
                //   console.warn(card)
                  if(that.currentCard?.type == 'Var_Wistia') {
                    that.wistia_src = "//fast.wistia.net/embed/iframe/" + that.currentCard?.link  +"?videoFoam=true"
                    that.wistia_src = that.sanitizer.bypassSecurityTrustResourceUrl(that.wistia_src)
                }
                if(that.currentCard_id == '65ddf595-c1f3-46ed-9167-2fe14770657d'){
                    that.url = that.sanitizer.bypassSecurityTrustResourceUrl(card['link']);
                }else{
                    if(card['link'] != null){
                      that.url = that.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + card['link'] + '?modestbranding=1&amp;rel=0&amp;showinfo=0');
                    }
                }
                  that.currentDate = $.now();
                if(that.currentCard.open){that.BeginDateTime = new Date(that.currentCard.open).getTime();}
                if(that.currentCard.close){that.EndDateTime = new Date(that.currentCard.close).getTime();}
                if ( that.BeginDateTime >= that.currentDate  ||  that.EndDateTime <= that.currentDate) {
                  that.show_card = false;
                }
                else if(!that.currentCard.open){
                    that.show_card = true;
                }else {
                    that.show_card = true;
                }
                if(!that.env.enable_date){
                    that.show_card = true;
                }
                if(that.currentCard.title == 'Finish Qualification' || that.currentCard.id == '809572cc-4a3b-4c8b-a3eb-2a8b1d0edd4a'){                                      
                    hbspt.forms.create({
                        portalId: "4424590",
                        formId: "1d4c6c5e-09c8-463e-a355-0452f5694869",
                        target: "#hubspotFormQualification",
                        onFormReady: function($form, ctx){
                            $('#hubspotFormQualification').find('#email-1d4c6c5e-09c8-463e-a355-0452f5694869').val( that.email ).change();
                            $('#hubspotFormQualification').find('.hs-email').hide();
                            $('#hubspotFormQualification').find('.hs-submit').hide();
                            var check = setInterval(function() {
                                $("#comfortable_in_presenting_lm-1d4c6c5e-09c8-463e-a355-0452f5694869").prop("checked", false);
                            }, 0);
                            
                            $('#hubspotFormQualification .hs-form-booleancheckbox').click(function() {
                                clearInterval(check);
                                setTimeout(function() {
                                    jQuery('#hubspotFormQualification').find('.hs-submit input').click();
                                }, 500);
                                                 
                            });
                        }
                    });
                }
                if(that.currentCard.title == 'Finish Mindset Certification' || that.currentCard.id == '930f1971-f096-4163-ac08-ab604deddec0'){                                          
                    hbspt.forms.create({
                        portalId: "4424590",
                        formId: "4348742f-34e7-4f85-84d2-55a303ab471d",
                        target: "#hubspotFormFinishMindsetCertification",
                        onFormReady: function($form, ctx){
                            $('#hubspotFormFinishMindsetCertification').find('#email-4348742f-34e7-4f85-84d2-55a303ab471d').val( that.email ).change();
                            $('#hubspotFormFinishMindsetCertification').find('.hs-email').hide();
                            $('#hubspotFormFinishMindsetCertification').find('.hs-submit').hide();
                            var check = setInterval(function() {
                                $("#comfortable_in_presenting_lm-4348742f-34e7-4f85-84d2-55a303ab471d").prop("checked", false);
                            }, 0);
                            
                            $('#hubspotFormFinishMindsetCertification .hs-form-booleancheckbox').click(function() {
                                clearInterval(check);
                                setTimeout(function() {
                                    jQuery('#hubspotFormFinishMindsetCertification').find('.hs-submit input').click();
                                }, 500);
                                                 
                            });
                        }
                    });
                }
                if(that.currentCard.title == 'Learner Map Complete' || that.currentCard.id == '35a46982-33d3-4594-9fd9-af24b3f98a41'){                                          
                    hbspt.forms.create({
                        portalId: "4424590",
                        formId: "7eb0dcfc-0df6-4393-bfff-98eb154e2c1e",
                        target: "#hubspotFormCertification",
                        onFormReady: function($form, ctx){
                            $('#hubspotFormCertification').find('#email-7eb0dcfc-0df6-4393-bfff-98eb154e2c1e').val( that.email ).change();
                            $('#hubspotFormCertification').find('.hs-email').hide();
                            $('#hubspotFormCertification').find('.hs-submit').hide();
                            var check = setInterval(function() {
                                $("#online_cmf_finished-7eb0dcfc-0df6-4393-bfff-98eb154e2c1e").prop("checked", false);
                            }, 0);
                            
                            $('#hubspotFormCertification .hs-form-booleancheckbox').click(function() {
                                clearInterval(check);
                                setTimeout(function() {
                                    jQuery('#hubspotFormCertification').find('.hs-submit input').click();
                                }, 500);
                                                 
                            });
                        }
                    });
                }
                if((that.currentCard.title == 'Your Certificate' && that.currentCard.id == '8e587549-916b-4849-a29a-c8eb05d2c7a7') || (that.currentCard.title == 'Display Your Certificate' || that.currentCard.id == '74568499-d15d-471d-99da-b08c42413799')){
                    that.http.post(`${environment.profileURL}/api/v1/hubspot/details`,firstName)
                    .subscribe(
                        res => {
                            that.firstname = res['firstname'];
                        },
                        error =>{
                            if(error.status == 500){
                                setTimeout(function() {
                                    this.toastr.options = {
                                      closeButton: true,
                                      timeOut: 2000
                                    };
                                    this.toastr.error('Something went wrong!', 'Sorry!');
                                }, 1000);
                            }
                            else if(error.status == 422){
                                setTimeout(function() {
                                    this.toastr.options = {
                                      closeButton: true,
                                      timeOut: 4000
                                    };
                                    if(error.error.errors.number[0]){
                                        this.toastr.error(error.error.errors.number[0], 'oops!');
                                    }
                                    if(error.error.errors.base[0]){
                                        this.toastr.error(error.error.errors.base[0], 'oops!');
                                    }
                                    if(!error.error.errors.base[0] && !error.error.errors.number[0]){
                                        this.toastr.error(error.error.errors, 'oops!');
                                    }
                                }, 1000);
                            }
                            else{
                                setTimeout(function() {
                                    this.toastr.options = {
                                      closeButton: true,
                                      timeOut: 2000
                                    };
                                    this.toastr.error('User details were not found!', 'oops!');
                                }, 1000);
                            }
                        }
                    )
                    that.http.post(`${environment.profileURL}/api/v1/hubspot/details`,lastName)
                    .subscribe(
                        res => {
                            that.lastname = res['lastname'];
                        },
                        error =>{
                            if(error.status == 500){
                                setTimeout(function() {
                                    this.toastr.options = {
                                      closeButton: true,
                                      timeOut: 2000
                                    };
                                    this.toastr.error('Something went wrong!', 'Sorry!');
                                }, 1000);
                            }
                            else if(error.status == 422){
                                setTimeout(function() {
                                    this.toastr.options = {
                                      closeButton: true,
                                      timeOut: 4000
                                    };
                                    if(error.error.errors.number[0]){
                                        this.toastr.error(error.error.errors.number[0], 'oops!');
                                    }
                                    if(error.error.errors.base[0]){
                                        this.toastr.error(error.error.errors.base[0], 'oops!');
                                    }
                                    if(!error.error.errors.base[0] && !error.error.errors.number[0]){
                                        this.toastr.error(error.error.errors, 'oops!');
                                    }
                                }, 1000);
                            }
                            else{
                                setTimeout(function() {
                                    this.toastr.options = {
                                      closeButton: true,
                                      timeOut: 2000
                                    };
                                    this.toastr.error('User details were not found!', 'oops!');
                                }, 1000);
                            }
                        }
                    )
                }
                if(that.currentCard.title == 'Interpretive Guides'){
                    that.guide = '';
                    that.http.get(`${environment.assessment_admin}api/v1/interpretive_guides`).subscribe(data => {
                        // Sort by Language name
                        that.guides = data['interpretive_guides'].sort(function(a, b){
                            return a.language_name.localeCompare(b.language_name);
                        });
                    },
                        error => console.log('oops', error)
                    )
                }
            })
        }  
        var Data = {
            email: localStorage.getItem('email'),
            property: "certz"
        }
        if(this.currentCard_id == '8e587549-916b-4849-a29a-c8eb05d2c7a7' || this.currentCard_id == '74568499-d15d-471d-99da-b08c42413799'){
            this.http.post(`${environment.profileURL}/api/v1/hubspot/details`,Data)
              .subscribe(
                  res => { 
                    if(res['certz'] != null && res['certz'] != '') {    
                        var that = this;       
                        if(res['certz'].indexOf('qmp') > -1){
                            this.plan_qmp = true;
                        }
                        if(res['certz'].indexOf('cmf') > -1){
                            this.plan_cmf = true;
                        } 
                    }                                   
                  },
                  err =>{
                      this.showError(err);
                  }
            )
        }
        if(this.router.url.includes('challenge-navigator-print')) {
            this.cardView = true;

            // remove blank item from lambda res
            // this.columns.forEach((column,i) => {
            //     column.cards.forEach((card,j) => {
            //         if(card){
            //             console.error(card)
            //         } else {
            //             console.error('blank')
            //         }
            //     })
            // })



            this.allCols = {
                "allcolumns": this.columns
            }
            console.warn(this.allCols);
           this.show_card = true;
           this.lm_maptype = 'custom';
            this.allCols?.allcolumns?.forEach((column,i) =>{
                column.cards.forEach((card,j) =>{
                    if(card.id.S == this.currentCard_id){
                        this.currentCard_id = card?.id?.S;
                        this.cardName = card?.title?.S;
                        this.currentCard = card;
                        this.url = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + card?.link?.S + '?modestbranding=1&amp;rel=0&amp;showinfo=0');
                        console.warn(this.url)
                    }
                    if(card.id?.S == this.currentCard_id){
                        if(j > 0){
                            this.previousCard = column.cards[j-1]?.id;
                            this.previousTitle = column.cards[j-1]?.title;
                            if(!column.cards[j-1]?.id) {
                                if(i > 0) {
                                    console.log(this.allCols.allcolumns[i-1].cards[this.allCols?.allcolumns[i - 1]?.cards?.length -1])
                                    this.previousCard = this.allCols.allcolumns[i-1].cards[this.allCols?.allcolumns[i - 1]?.cards?.length -1]?.id;
                                    this.previousTitle = this.allCols.allcolumns[i-1].cards[this.allCols?.allcolumns[i - 1]?.cards?.length -1]?.title
                                }
                            }
                        }else{
                            if(i > 1){
                                this.previousTitle = this.allCols.allcolumns[i-1].cards[this.allCols.allcolumns[i-1].cards.length - 1]?.title;
                                this.previousCard = this.allCols.allcolumns[i-1].cards[this.allCols.allcolumns[i-1].cards.length - 1]?.id;
                                console.warn('else if',this.allCols.allcolumns[i-1].cards[this.allCols.allcolumns[i-1].cards.length - 1])
                                console.log(this.allCols.allcolumns[i])
                            }else{
                                this.previousCard = '';
                            }
                        }
                        if(j < column.cards.length - 1){
                            this.nextCard = column.cards[j+1]?.id;
                            this.nextTitle = column.cards[j+1]?.title;
                            console.log('next if')
                        }else{
                            if(i < this.allCols.allcolumns.length - 1){
                                this.nextTitle = this.allCols.allcolumns[i+1].cards[0]?.title;
                                this.nextCard = this.allCols.allcolumns[i+1].cards[0]?.id;
                                if(!column.cards[j + 1]?.id) {
                                    if(i < this.allCols.allcolumns.length - 1) {
                                        console.log(this.allCols.allcolumns[i+1].cards[parseInt(card.order?.N) + 1]?.title?.S)
                                        this.nextCard = this.allCols.allcolumns[i+1].cards[parseInt(card.order?.N)]?.id;
                                        this.nextTitle = this.allCols.allcolumns[i+1].cards[parseInt(card.order?.N)]?.title;
                                        console.log(this.nextTitle)
                                    }
                                }
                            }else{
                                console.log('nextcard value blank')
                                this.nextCard = '';
                            }
                        }
                    }
                    console.log(this.nextCard)
                })
            })
        } else {
            console.log(this.allCols)
            this.allCols?.columns?.items.forEach((column,i) =>{
                  column.cards.items.forEach((card,j) =>{
                      if(card.id == this.currentCard_id){
                          if(j > 0){
                              this.previousCard = column.cards.items[j-1].id;
                              this.previousTitle = column.cards.items[j-1].title;
                          }else{
                              if(i > 0){
                                  this.previousTitle = this.allCols?.columns?.items[i-1].cards.items[this.allCols?.columns?.items[i-1].cards.items.length - 1].title;
                                  this.previousCard = this.allCols?.columns?.items[i-1].cards.items[this.allCols?.columns?.items[i-1].cards.items.length - 1].id;
                              }else{
                                  this.previousCard = '';
                              }
                          }
                          if(j < column.cards.items.length - 1){
                              this.nextCard = column.cards.items[j+1].id;
                              this.nextTitle = column.cards.items[j+1].title;
                          }else{
                              if(i < this.allCols.columns.items.length - 1){
                                  this.nextTitle = this.allCols.columns.items[i+1].cards.items[0].title;
                                  this.nextCard = this.allCols.columns.items[i+1].cards.items[0].id;
                              }else{
                                  this.nextCard = '';
                              }
                          }
                      }
                  })
              })
            
        }
          
        var that = this;
        jQuery(document).ready(function(){     
            if(that.router.url.includes('/learner-maps')){
                $('html,body').animate({
                    scrollTop: $('.page-heading').offset().top},100);
            }

            setTimeout(function() {
                jQuery('input[name="radioTest"]').on('ifChecked', function (event) { 
                    that.http.post(`${environment.profileURL}/api/v1/users/update_details.json`,{nps: parseInt(this.value)}).subscribe(data => {
                        setTimeout(function() {
                            this.toastr.options = {
                                closeButton: true,
                                progressBar: true,
                                timeOut: 2000
                            };
                            this.toastr.success('Thanks for rating us!', 'Nice!');
                        }, 1000);
                    })
                    
                });
                jQuery('input[name="radioTest"]').iCheck({
                    checkboxClass: 'icheckbox_square-green',
                    radioClass: 'iradio_square-green',
                });
            }, 1500);

            // $('html body').on('click', '.openIntercom', function(){
            //     var url = window.location.href;
            //     if(url.indexOf('#hs-chat-open') != -1){
            //         url = url.replace('#hs-chat-open','')
            //     }else{
            //         url += '#hs-chat-open'
            //     }
                
            //     window.location.href = url;
            // });
        });
    }


    // public CertiPDF(){
    //     localStorage.setItem('certificate','pdf');
    //     localStorage.setItem('certificate-lmap','promoter');
    //     this.router.navigate(['/learner-maps/your-certificate']);
    // }

    public showError(error){
        console.log('oops', error);
        if(error.status == 500){
            setTimeout(function() {
                this.toastr.options = {
                  closeButton: true,
                  timeOut: 2000
                };
                this.toastr.error('Something went wrong!', 'Sorry!');
            }, 1000);
        }
        else if(error.status == 422){
            setTimeout(function() {
                this.toastr.options = {
                  closeButton: true,
                  timeOut: 4000
                };
                if(error.error.errors.number[0]){
                    this.toastr.error(error.error.errors.number[0], 'oops!');
                }
                if(error.error.errors.base[0]){
                    this.toastr.error(error.error.errors.base[0], 'oops!');
                }
                if(!error.error.errors.base[0] && !error.error.errors.number[0]){
                    this.toastr.error(error.error.errors, 'oops!');
                }
            }, 1000);
        }
        else{
            setTimeout(function() {
                this.toastr.options = {
                  closeButton: true,
                  timeOut: 2000
                };
                this.toastr.error('Something went wrong!', 'oops!');
            }, 1000);
        }

    }

    public async lambda_lmdata(i = 0,args){
        console.warn(args)
        console.warn(args?.lm_id?.length)
        console.warn((args?.lm_id?.length != 0))
        var all_cards:any = [];
    
        var that = this;
        if(args?.lm_id?.length != 0) {
        //   try {
            args["compName"] = "dynamic single";
            this.lmda_service.lambda_res.subscribe(res => {
                console.log(res)
                if(res.length == 0){
                    that.lmda_service.lambda_invoke('TestDynamoDBReadLM-dev-readDataFromDynamoDB',args);
                }
            })
        //   } catch (err) {
        //     console.log(err)
        //   }
          await this.lmda_service.lambda_res.subscribe(res => {
              this.lm_ts.an_slide_no.next(0);
              console.log(res["response"])
              all_cards = res;
              try {
                      console.warn('all_cards',all_cards)
                      let allcolumns:any = [];
                      allcolumns = all_cards["response"]?.body;
                      if(this.router.url.includes('challenge-navigator-print')) {
                        let mycol = [];
                        all_cards["response"]?.body.forEach((item,i)=> {
                            mycol[item.order - 1] = item
                        })
                        this.columns = mycol;
                      }
              } catch (e) {
                      console.error('error in try block custom-pf',e);
                      return false;
                  }
          });
        }
        setTimeout(function() {
          this.loading = false;
        },500)
    }

    activeRoute(routename: string): boolean{
        return this.router.url.indexOf(routename) > -1;
    }

}
