// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.groups {
  margin-left: 15px;
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/permissions/permissions.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,mBAAA;AACF","sourcesContent":[".groups{\n  margin-left: 15px;\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
