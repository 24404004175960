// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slidemap-text {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 300px;
}
.slidemap-text img {
  margin-inline: auto;
  max-height: 350px;
  padding-block-end: 25px;
}
@media (max-width: 767px) {
  .slidemap-text img {
    max-height: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/cig/slidemap/slidemap.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,eAAA;EACA,mBAAA;EACA,iBAAA;AACJ;AAAQ;EACI,mBAAA;EACA,iBAAA;EACA,uBAAA;AAEZ;AAAY;EALJ;IAMQ,gBAAA;EAGd;AACF","sourcesContent":[".slidemap-text {\n    display: flex;\n    flex-wrap: wrap;\n    align-items: center;\n    min-height: 300px;\n        img {\n            margin-inline: auto;\n            max-height: 350px;\n            padding-block-end: 25px;\n\n            @media (max-width: 767px) {\n                max-height: none;\n            }\n        }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
