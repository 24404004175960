import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../../../environments/environment';
// import * as AWS from 'aws-sdk';
// import { Angulartics2Segment } from 'angulartics2';
import { Location } from '@angular/common';
import { LambdaFunctionService } from '../../../../service/lambda-function.service';
import { take } from 'rxjs/operators';
declare var jQuery:any;

@Component({
    selector: 'app-order-details',
    templateUrl: './order-details.component.html',
    styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {
    public Id:number;
    public detail:any;
    public cart:Array<Object> = [];
    public subtotal:string;
    public env:any;
    public print_variable:any;
    discounted_items_price:any;
    args:any;
    print_invoice:string = 'Print Invoice';
    print_order:string = 'Print Order';
    isChrome: any;
    isSafari: any;
    download: any = [];  
    downloading: boolean = false;  
    store : any;
    loading: boolean = false;

    constructor(public location: Location, private activatedRoute: ActivatedRoute, private router: Router,private http: HttpClient,public lmda_service:LambdaFunctionService ) { }

    ngOnInit() {
        this.loading = true;
        this.store = localStorage.getItem('store');
        this.env = environment;
        this.download[0] = 'Download Creativity Unbound English PDF';
        this.download[1] = 'Download Creativity Unbound French PDF';
        this.download[2] = 'Download Facilitation PDF';
        this.download[3] = 'Download FourSight Toolcards English PDF';
        this.download[4] = 'Download FourSight Toolcards Spanish PDF';
        this.download[5] = 'Download FourSight Toolcards French PDF';
        // AWS.config.update({
        //     //Testuser
        //     accessKeyId: this.env.accessKeyId,
        //     secretAccessKey: this.env.secretAccessKey,
        //     region: "ca-central-1",
        // });
        this.isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
        this.isSafari = navigator.userAgent.toLowerCase().indexOf('safari') > -1;
        console.log(navigator.userAgent)
        this.env = environment;
        this.print_variable = 'Order';
        // this.angulartics2Segment.eventTrack('Page', 
        //     { 
        //         email: localStorage.getItem('email'),
        //         funnel: 'Store',
        //         cta_location:'Order confirmation',
        //         url: window.location.origin + this.location.path()
        //     }
        // );
        this.activatedRoute.params.subscribe((params: Params) => {

            console.log(params.id);
            this.Id = params.id;
            this.http.get(`${environment.assessment_admin}api/v1/orders/${this.Id}`)
            .subscribe(res => {
                console.log(res);
                this.detail = res['order'];
                this.cart = res['order']['line_items'];
                this.subtotal = res['order']['subtotal_amount'];
                this.discounted_items_price = res['order']['discounted_items_price'];
                // for(var i=0;i<this.cart.length;i++){
                //     if(this.cart[i]['product_slug'] == 'foursight-mindset-qualification'){
                //         this.angulartics2Segment.eventTrack('Page', 
                //             { 
                //                 email: localStorage.getItem('email'),
                //                 funnel: 'Get Student Rates',
                //                 cta_location:'Order confirmation',
                //                 url: window.location.origin + this.location.path()
                //             }
                //         );
                //     }
                //     if(this.cart[i]['product_slug'] == 'foursight-mindset-certification'){
                //         this.angulartics2Segment.eventTrack('Page', 
                //             { 
                //                 email: localStorage.getItem('email'),
                //                 funnel: 'Get Certified MSC',
                //                 cta_location:'Order confirmation',
                //                 url: window.location.origin + this.location.path()
                //             }
                //         );
                //     }
                //     if(this.cart[i]['product_slug'] == 'foursight-toolset-certification'){
                //         this.angulartics2Segment.eventTrack('Page', 
                //             { 
                //                 email: localStorage.getItem('email'),
                //                 funnel: 'Get Certified TSC',
                //                 cta_location:'Order confirmation',
                //                 url: window.location.origin + this.location.path()
                //             }
                //         );
                //     }
                //     if(this.cart[i]['product_slug'] == 'foursight-thinking-profile-assessment'){
                //         this.angulartics2Segment.eventTrack('Page', 
                //             { 
                //                 email: localStorage.getItem('email'),
                //                 funnel: 'Group Assessment',
                //                 cta_location:'Order confirmation',
                //                 url: window.location.origin + this.location.path()
                //             }
                //         );
                //     }
                // }
                console.log(JSON.parse(JSON.stringify(res['order']["line_items"])));
                if(res['order']['needs_shipping'] == true){
                    if((res['order']['payment']['payment_method'] == 'paypal') || (this.discounted_items_price == 0)){
                        this.args = {
                            "template": "invoice.html",
                            "content_type": "pdf",
                            "id": res['order']["number"],
                            "format": "A4",
                            "status": res['order']["status"],
                            "completed_at": res['order']["completed_at"],
                            "net_amount": res['order']["net_amount"],
                            "shipping_amount": res['order']["shipping_amount"],
                            "tax_amount": res['order']["tax_amount"],
                            "discounted_items_price": res['order']["discounted_items_price"],
                            "shipping_method_name": res['order']["shipping_method_name"],
                            "payment": JSON.parse(JSON.stringify(res['order']['payment'])),
                            "credit_card": "",
                            "line_items": JSON.parse(JSON.stringify(res['order']["line_items"])),
                            "billing_address": JSON.parse(JSON.stringify(res['order']["billing_address"])),
                            "needs_shipping": res['order']["needs_shipping"],
                            "shipping_address": JSON.parse(JSON.stringify(res['order']["shipping_address"]))
                        };
                    }
                    else{
                        this.args = {
                            "template": "invoice.html",
                            "content_type": "pdf",
                            "id": res['order']["number"],
                            "format": "A4",
                            "status": res['order']["status"],
                            "completed_at": res['order']["completed_at"],
                            "net_amount": res['order']["net_amount"],
                            "shipping_amount": res['order']["shipping_amount"],
                            "tax_amount": res['order']["tax_amount"],
                            "discounted_items_price": res['order']["discounted_items_price"],
                            "shipping_method_name": res['order']["shipping_method_name"],
                            "credit_card": JSON.parse(JSON.stringify(res['order']["credit_card"])),
                            "payment": JSON.parse(JSON.stringify(res['order']['payment'])),
                            "line_items": JSON.parse(JSON.stringify(res['order']["line_items"])),
                            "billing_address": JSON.parse(JSON.stringify(res['order']["billing_address"])),
                            "needs_shipping": res['order']["needs_shipping"],
                            "shipping_address": JSON.parse(JSON.stringify(res['order']["shipping_address"]))
                        };
                    }
                }else{
                    if((res['order']['payment']['payment_method'] == 'paypal') || (this.discounted_items_price == 0)){
                        this.args = {
                            "template": "invoice.html",
                            "content_type": "pdf",
                            "id": res['order']["number"],
                            "format": "A4",
                            "status": res['order']["status"],
                            "completed_at": res['order']["completed_at"],
                            "net_amount": res['order']["net_amount"],
                            "shipping_amount": "",
                            "tax_amount": res['order']["tax_amount"],
                            "discounted_items_price": res['order']["discounted_items_price"],
                            "shipping_method_name": "",
                            "payment": JSON.parse(JSON.stringify(res['order']['payment'])),
                            "credit_card": "",
                            "line_items": JSON.parse(JSON.stringify(res['order']["line_items"])),
                            "billing_address": JSON.parse(JSON.stringify(res['order']["billing_address"])),
                            "needs_shipping": res['order']["needs_shipping"],
                            "shipping_address": ""
                        };
                    }
                    else{
                        this.args = {
                            "template": "invoice.html",
                            "content_type": "pdf",
                            "id": res['order']["number"],
                            "format": "A4",
                            "status": res['order']["status"],
                            "completed_at": res['order']["completed_at"],
                            "net_amount": res['order']["net_amount"],
                            "shipping_amount": "",
                            "tax_amount": res['order']["tax_amount"],
                            "discounted_items_price": res['order']["discounted_items_price"],
                            "shipping_method_name": "",
                            "credit_card": JSON.parse(JSON.stringify(res['order']["credit_card"])),
                            "payment": JSON.parse(JSON.stringify(res['order']['payment'])),
                            "line_items": JSON.parse(JSON.stringify(res['order']["line_items"])),
                            "billing_address": JSON.parse(JSON.stringify(res['order']["billing_address"])),
                            "needs_shipping": res['order']["needs_shipping"],
                            "shipping_address": ""
                        };
                    }

                } 
                // $('.loader-container').css('display','none');  
                this.loading = false;             
            },
                error => {
                    console.log('oops', error);
                    if(error.status == 500){
                        setTimeout(function() {
                            this.toastr.options = {
                              closeButton: true,
                              timeOut: 2000
                            };
                            this.toastr.error('Something went wrong!', 'Sorry!');
                        }, 1000);
                    }
                    else if(error.status == 422){
                        setTimeout(function() {
                            this.toastr.options = {
                              closeButton: true,
                              timeOut: 4000
                            };
                            if(!error.error.errors.base[0]){
                                this.toastr.error(error.error.errors, 'oops!');
                            }else{
                                this.toastr.error(error.error.errors.base[0], 'oops!');
                            }
                        }, 1000);
                    }else{
                        setTimeout(function() {
                            this.toastr.options = {
                              closeButton: true,
                              timeOut: 2000
                            };
                            this.toastr.error('Something went wrong!', 'oops!');
                        }, 1000);
                    }
                }
            )
        });
    }

    public printPage(){
        $('.navbar-default').hide();
        $('body').addClass('print-body');
        window.print();
        $('.navbar-default').show();
        $('body').removeClass('print-body');
    }

    public printInvoice(){
        this.print_variable = 'Invoice';
        setTimeout(function() {
            $('.navbar-default').hide();
            $('body').addClass('print-body');
            window.print();        
        }, 0);
        var that = this;
        setTimeout(function() {
            $('.navbar-default').show();
            that.print_variable = 'Order';
            $('body').removeClass('print-body');        
        }, 2000);        
        
    }

    public complimentaryPdt(){
        setTimeout(function() {
            this.toastr.options = {
                closeButton: true,
                timeOut: 5000
            };
            this.toastr.success('This product is a complement to the product above it and cannot be edited independently.', 'Hey!');
        }, 500);
    }

    // Invoice and Order PDF Lambda
    public async lambda(type){
        // this.loading = true;
        $('.pdf-modal').css('display','block');
        var that = this;
        this.args['type'] = type;
        console.log(this.args);
        let res = this.lmda_service.lambda_invoke('new-pdf-invoice-dev-pdf',this.args,type);

        // let newWin = window.open(
        //     res['response'].body,
        //     '_blank' // <- This is what makes it open in a new window.
        // );
        // res.subscribe((order)=> {
        //     console.warn(order)
        //     console.warn(order["response"])
        //     // window.location.href = ('blank',JSON.stringify(order));
        //     let newWin = window.open(
        //         order['response'].body,
        //         '_blank' // <- This is what makes it open in a new window.
        //       );
        // })
        
    }

    // eBook PDF Lambda
    public downloadYourMultiPDF(type, type_name, index, quantity){
        if(this.downloading == false){
            // this.loading = true;
            $('.pdf-modal').css('display','flex');
            var footer = '';
            if(quantity == 1){
                footer = "for the exclusive use of one person. Further distribution is a violation of copyright.";
            }
            if(quantity > 1){
                footer = "for the exclusive use of one group of "+quantity+" people. Further distribution is a violation of copyright.";
            }
            this.downloading = true;
            let name = this.detail['billing_address']['first_name'] +' '+ this.detail['billing_address']['last_name'];
            
            var res = this.lmda_service.lambda_invoke('pdf-filler-stage-pdftk',{"pdf":type,"name": name, "footer": footer},'Books')
            this.lmda_service.lambda_pdf.subscribe((output) => {
                console.log(output["response"])
                if(output["response"] == null) {
                    this.download[index] = type_name;
                    this.downloading = false;
                }else {
                    this.downloading = false;
                    // let newWin = window.open(
                    //     output['response'].Location,
                    //     '_blank' // <- This is what makes it open in a new window.
                    //   );
                }
            })
            setTimeout(() => {
                this.loading = false;
            }, 1000);
        }
    }

}
