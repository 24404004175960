import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-challenge-navigator-print',
  templateUrl: './challenge-navigator-print.component.html',
  styleUrls: ['./challenge-navigator-print.component.scss']
})
export class ChallengeNavigatorPrintComponent implements OnInit {

  sideNav:boolean = true;

  constructor(public route: Router) { }

  ngOnInit(): void {
    console.log(this.route.url)
    // this.route.navigate(['/challenge-navigator-print-coach'])

    if(this.route.url.includes('/challenge-navigator-print') && !this.route.url.includes('/challenge-navigator-print-coach')) {
      this.route.navigate(['challenge-navigator-print-coach'])
    }

    if(this.route.url.includes('challenge-navigator-print/coach')) {
      window.location.replace('https://app.foursightonline.com/challenge-navigator-print-coach')
    }

  }
}
