import { Component, OnInit } from '@angular/core';
import { TeamReportServiceService } from './../team-report-service.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-team-fs-process',
  templateUrl: './team-fs-process.component.html',
  styleUrls: ['./team-fs-process.component.scss']
})
export class TeamFsProcessComponent implements OnInit {
	fsprocess:any;
	item:any;
	env:any;

  	constructor(public service: TeamReportServiceService) { }

  	ngOnInit() {
  		this.env = environment;
  		this.fsprocess = this.service.fsprocess;
  	}

}
