import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'removeStarters'
})
export class RemoveStartersPipe implements PipeTransform {

	transform(value: string): string {
  		if(value!=null && value!=undefined)
  		{	
  			return value.replace('How to...','').replace('How might...','').replace('In what ways might...','').replace('What might be all the...','');
  		}  
  	}
}
