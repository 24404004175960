import { Component, OnInit } from '@angular/core';
import * as AWS from 'aws-sdk';
import { environment } from './../../../environments/environment';
import {AccessGroupService} from './../access-group.service';
import { Router } from '@angular/router';
import { LambdaFunctionService } from '../../service/lambda-function.service';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-admin-pdf',
    templateUrl: './admin-pdf.component.html',
    styleUrls: ['./admin-pdf.component.scss']
})
export class AdminPdfComponent implements OnInit {

    public env:any;
    pdf_name: string;
    first_name: string;
    last_name: string;
    quantity: number;
    footer: string;
    download: string = 'Download PDF';
    downloading : boolean = false;
    ngrxAccessGroup:any;

    constructor(public accessgroup: AccessGroupService, private router: Router,public lmda_service:LambdaFunctionService,public store:Store<any>) { }

    ngOnInit() {
        this.ngrxAccessGroup =this.store.select<any>((state) => state.profile.accessGroupData)
        this.ngrxAccessGroup.subscribe(message => {
            if(message && message.length > 0){
                if(!message['menu_account']['admin_pdf']){
                    this.router.navigate(['/dashboard']);
                    return false;
                }
            }
        },
        err => {
            // Log errors if any
            console.log(err);
        })

        this.env = environment;
        AWS.config.update({
            //Testuser
            accessKeyId: this.env.accessKeyId,
            secretAccessKey: this.env.secretAccessKey,
            region: "ca-central-1",
        });
    }

    public setFooter(){
        if(this.quantity == 1){
            this.footer = "for the exclusive use of one person. Further distribution is a violation of copyright.";
        }
        if(this.quantity > 1){
            this.footer = "for the exclusive use of one group of "+this.quantity+" people. Further distribution is a violation of copyright.";
        }
    }

    public downloadPDF(){
        $('.apdf-loader').css('display','flex');
        if(this.downloading == false && this.first_name != '' && this.last_name != '' && this.pdf_name != '' && this.quantity > 0 && this.footer != ''){
            var that = this;
            let name = this.first_name +' '+ this.last_name;
            $('.pdf-modal').css('display','flex');
            let args = {"pdf":that.pdf_name,"name": name, "footer": that.footer}
            var res = this.lmda_service.lambda_invoke('pdf-filler-stage-pdftk',args, 'Books')
            that.lmda_service.lambda_pdf.subscribe((output) => {
                if(output == null) {
                    that.download = 'Download PDF';
                    that.downloading = false;
                }else {
                    setTimeout(() => {
                        that.first_name = '';
                        that.last_name = '';
                        that.footer = '';
                        that.pdf_name = '';
                        that.quantity = 0;
                        that.download = 'Download PDF';
                        that.downloading = false;
                    }, 500);
                    // let newwing = window.open(
                    //     output['response'].Location,
                    //     '_blank' // <- This is what makes it open in a new window.
                    //   );
                }
                setTimeout(() => {
                    $('.apdf-loader').css('display','none');
                }, 1000);
            })
        }
    }

}
