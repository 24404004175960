import { Component } from '@angular/core';
import { detectBody } from '../../../app.helpers';
// import { Auth } from '../../../auth/auth.service';

declare var jQuery:any;

@Component({
    selector: 'basic',
    templateUrl: 'basicLayout.template.html',
    host: {
        '(window:resize)': 'onResize()'
    }
})
export class BasicLayoutComponent {

    public ngOnInit():any {
        detectBody();
    }

    public onResize(){
        detectBody();
    }

    constructor(){

    }

}
