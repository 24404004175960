import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-slidemap',
  templateUrl: './slidemap.component.html',
  styleUrls: ['./slidemap.component.scss']
})
export class SlidemapComponent implements OnInit {

    @Input() profile:Array<Object>;
    @Input() slides;
    @Input() author;
    public env;
    public profiles : any;

    constructor() { }

    ngOnInit() {
        this.env=environment;
        this.profiles = this.profile;
    }

    public ngOnChanges( changes: SimpleChanges ) : void {

        if ( changes.profile && ! changes.profile.isFirstChange() ) {
        	this.profiles = changes.profile.currentValue;

        }

    }

}
