import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ManageMembersComponent} from '../manage-members/manage-members.component';

@Component({
  selector: 'app-manage-session-members',
  templateUrl: './manage-session-members.component.html',
  styleUrls: ['./manage-session-members.component.scss']
})

export class ManageSessionMembersComponent implements OnInit {
	@Input() id:number;
	sessions:any;
	meta:any;
	modal:any;
	loading:number;
	sessionName:string;
	listMembers:any;
	membersByPage:number;
	isLoading:boolean;
	pageLen:number;
	curPage:number;
	item:any;
	query_string:string;
	kind:string;

  constructor(public http: HttpClient, public modalService:NgbModal, public global:ManageMembersComponent) { }

  ngOnInit() {
  	console.log(this.id);
  	this.membersByPage = 20;
  	this.isLoading = true;
  	this.query_string = environment.profileURL+`/api/v1/organizations/`+this.id+`/training_sessions.json?`;
  	this.http.get(`${environment.profileURL}/api/v1/organizations/`+this.id+`/training_sessions.json`).subscribe(data => {
           	this.sessions = data['training_sessions'];
           	this.meta = data['meta'];
           	this.isLoading = false;
           	this.pageLen = this.meta.total_pages;
           	this.curPage = 0;
           	this.item = [];
           	for(var i = 1; i<=this.pageLen;i++){
			    this.item.push(i);
			}
			if(this.meta.total_count > this.membersByPage){
				this.render( this.curPage, this.item, true );
	            console.log(data);
	        }
        },
            error => console.log('oops', error)
        )
  	}

  	public isPageInRange = function( curPage, index, maxPages, pageBefore, pageAfter ) {
        if (index <= 1) {
	        // first 2 pages
	        return true;
        }
        if (index >= maxPages - 2) {
	        // last 2 pages
	        return true;
        }
        if (index >= curPage - pageBefore && index <= curPage + pageAfter) {
	        return true;
        }
	}
	public render = function( curPage, item, first ) {
        var html = '', separatorAdded = false;
        for(var i in item){
            if ( this.isPageInRange( curPage, i, this.pageLen, 2, 2 ) ) {
                html += '<li data-page="' + i + '">' + item[i] + '</li>';
                // as we added a page, we reset the separator Added
                separatorAdded = false;
            } else {
                if (!separatorAdded) {
                    // only add a separator when it wasn't added before
                    html += '<li class="separator" />';
                    separatorAdded = true;
                }
            }
        }
        var holder = document.querySelector('#holder');  
        if(this.meta.total_count > this.membersByPage){
            holder.innerHTML = html;
            if(document.querySelector('#holder>li[data-page="' + curPage + '"]') != null){document.querySelector('#holder>li[data-page="' + curPage + '"]').classList.add('active');}
            if ( first ) {
                holder.addEventListener('click', e => {
                    console.log(e.target['attributes']['data-page'].value);
                    if (!e.target['attributes']['data-page'].value) {

                        // no relevant item clicked (you could however offer expand here )
                        return;
                    }

                    curPage = parseInt(e.target['attributes']['data-page'].value);
                    var page_number = curPage+1;
                    this.isLoading = true;
                    this.http.get(`${this.query_string}&page=${page_number}`).subscribe(data => {
                    	console.log(data);
                        this.sessions = data['training_sessions'];
                        this.meta = data['meta'];
                        this.pageLen = this.meta.total_pages;
                        this.isLoading = false;
                        this.curPage = 0;
                        this.item = [];
                        for(var i = 1; i<=this.pageLen;i++){
                            this.item.push(i);
                        }

                    },
                        error => console.log('oops', error)
                    )
                    this.render( curPage, item );
                });
            }
        }else{
            holder.innerHTML = null;
        }
    }

    public filter(){
        this.sessions = [];
    	this.query_string = environment.profileURL+`/api/v1/organizations/`+this.id+`/training_sessions.json?`;
        var name:any = jQuery('#name').val();
        if(name.length > 0 || name.length >= 3) {
            if(name){
                this.query_string += '&name=' + name;
            }        
            this.isLoading = true;
            console.log(this.query_string);
            this.http.get(`${this.query_string}`).subscribe(data => {
                this.sessions = data['training_sessions'];
                this.meta = data['meta'];
                this.pageLen = this.meta.total_pages;
                this.curPage = 0;
                this.item = [];
                this.isLoading = false;
                for(var i = 1; i<=this.pageLen;i++){
                    this.item.push(i);
                }
                this.render( this.curPage, this.item, true );
                console.log(data);
            },
                error => console.log('oops', error)
            )
        }
    }

  	public open(){
        // this.modal = this.modalService.open(content, { windowClass: 'in grey' });
        $('.session-modal').css('display','block');
    }


    public close() {
        $('.session-modal').css('display', 'none');
    }

    public addSession(){
    	if(this.sessionName && this.kind){
	    	this.loading = 1;
	    	this.http.post(`${environment.profileURL}/api/v1/organizations/`+this.id+'/training_sessions.json',{ name: this.sessionName, kind: this.kind }).subscribe(result => {
		        console.log(result);
		        this.sessions.push(result);
		        this.loading = 2;
                this.close();
		        // this.modal.close();
		    },
            error => console.log('oops', error)
            )
	    }
    }

    public gaddSessionMembers(id){
    	var counter = 0;
    	this.listMembers = {};
    	console.log(this.global.group);
    	this.global.group.forEach(list =>{
			this.listMembers[counter] = list.id;
			counter++;
            list.checked = false;
    		  			
		});
    	this.http.post(`${environment.profileURL}/api/v1/organizations/`+this.id+'/training_sessions/'+id+'/add_members.json',{members_ids:this.listMembers}).subscribe(result => {
	        console.log(result);
	        setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.success('The Training Session was updated successfully!', 'Nice!');
            }, 1000);
	    })
    }

}
