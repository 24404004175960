import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from './../../../../../environments/environment';
import {CommonDataService} from './../../../common-data.service';
import {NewPricingModelComponent} from './../new-pricing-model.component';

declare var hbspt:any;

@Component({
  selector: 'app-new-pricing-card-single',
  templateUrl: './new-pricing-card-single.component.html',
  styleUrls: ['./new-pricing-card-single.component.scss']
})
export class NewPricingCardSingleComponent implements OnInit {

	public training: any;
    public env;
    public instanceID:number;
    public frameheight:number;
    public currentDate;
    public BeginDateTime;
    public EndDateTime;
    public show_card:boolean;
    public result:any;
    location:string;
    prevID:number;
    nextID:number;
    nextInstance:number;
    previousInstance:number;
    lastCard:number;
    videoCard:number;

  	constructor(private activatedRoute: ActivatedRoute, public sanitizer: DomSanitizer, public practitioner: NewPricingModelComponent, public commonService: CommonDataService, public route: Router) {
    }

  	ngOnInit() {
  		this.videoCard = this.practitioner.videoCard;
  		this.lastCard = this.practitioner.lastCard;
  		this.location = this.practitioner.location;
  		this.training = this.practitioner.training;
        this.env = environment;

  		this.activatedRoute.params.subscribe((params: Params) => {
        this.instanceID = params['id'];
        this.prevID = this.instanceID;
        this.nextID = this.instanceID;
        this.prevID = --this.prevID;
        this.nextID = ++this.nextID;

        if(this.instanceID >= 1 && this.instanceID <= this.lastCard) {
            this.nextInstance = this.instanceID;
            this.previousInstance = this.instanceID;
            this.nextInstance = ++this.nextInstance;
            this.previousInstance = --this.previousInstance;
        }else{
            this.nextInstance = 1;
            this.previousInstance = 1;
        }

        var currentBeginTime = this.training[this.instanceID].BeginDateTime;
        var currentEndTime = this.training[this.instanceID].EndDateTime;
        this.currentDate = $.now();
        this.BeginDateTime = new Date(currentBeginTime).getTime();
        this.EndDateTime = new Date(currentEndTime).getTime();

        if (( this.BeginDateTime >= this.currentDate  ||  this.EndDateTime <= this.currentDate ) && (this.EndDateTime !== 'infinity')) {
          this.show_card = false;
        }
        else if (this.BeginDateTime >= this.currentDate && this.EndDateTime === 'infinity') {
            this.show_card = false;
        }
        else {
            this.show_card = true;
        }

        // Enable Dates
        if(!this.env.enable_date){
            this.show_card = true;
        }

        jQuery(document).ready(function(){
	      	$('html,body').animate({
	      	    scrollTop: $('.page-heading').offset().top},100);
	  	    });
	        this.iURL();
	        hbspt.forms.create({
			    portalId: "4424+590",
			    formId: "4cffad58-bc5e-4846-9fea-7272f6154362",
			    target: '#hubspotForm'
			});

        });

        

  	}

    public iURL = function () {
        let videoID = this.training[this.instanceID].link;
        if(this.instanceID == this.videoCard){
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(videoID);
        }else{
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + videoID + '?modestbranding=1&amp;rel=0&amp;showinfo=0');
        }
    };

  	public scrollDiv = function(id){
        $('html,body').animate({
            scrollTop: $("#"+id).offset().top},
        'slow');
    };

}

