import { createAction, props } from '@ngrx/store';
import { fsProfileModel } from '../model/fs-profile.model';
export const getProfile = createAction('[Profile] Get Profile');
export const getAccessGroup = createAction('[AccessGroup] Get Access Group');
export const getTcwaProfile = createAction('[TcwaProfile] Get Tcwa Profile');

export const getProfileSuccess = createAction(
  '[Account] Get Action Success',
  props<{profile: []}>()
);

export const getProfileFailure = createAction(
  '[Account] Get Action Failure',
  props<{error: string }>()
);

export const getAccessGroupSuccess = createAction(
  '[AccessGroup] Get Access Group Success',
  props<any>()
);

export const getAccessGroupFailure = createAction(
  '[AccessGroup] Get Access Group Failure',
  props<{error: string }>()
);

export const getTcwaProfileSuccess = createAction(
  '[TcwaProfile] Get Tcwa Profile Success',
  props<any>()
);

export const getTcwaProfileFailure = createAction(
  '[TcwaProfile] Get Tcwa Profile Failure',
  props<{error: string }>()
);