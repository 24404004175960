// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password {
  padding: 10px;
}

.account-update {
  padding-top: 20px;
}
.account-update .box-shd {
  box-shadow: 0 0 10px -5px #000;
  padding-top: 15px;
  padding-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/user/user.component.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;AACD;;AACA;EACC,iBAAA;AAED;AADC;EAEC,8BAAA;EACA,iBAAA;EACA,oBAAA;AAGF","sourcesContent":[".password {\n\tpadding: 10px;\n}\n.account-update{\n\tpadding-top: 20px;\n\t.box-shd{\n\t-webkit-box-shadow: 0 0 10px -5px #000;\n\t box-shadow: 0 0 10px -5px #000;\n\t padding-top: 15px;\n\t padding-bottom: 15px;\n\t}\t\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
