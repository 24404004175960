import { Component, OnInit } from '@angular/core';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-toolcards-perspectives',
  templateUrl: './toolcards-perspectives.component.html',
  styleUrls: ['./toolcards-perspectives.component.scss']
})
export class ToolcardsPerspectivesComponent implements OnInit {

  env:any;

  constructor() { }

  ngOnInit(): void {
    this.env = environment;
  }

}
