import { Component, OnInit } from '@angular/core';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-toolcard-assumption-reversal',
  templateUrl: './toolcard-assumption-reversal.component.html',
  styleUrls: ['./toolcard-assumption-reversal.component.scss']
})
export class ToolcardAssumptionReversalComponent implements OnInit {
  env:any;
  constructor() { }

  ngOnInit(): void {
    this.env = environment;
  }

}
