import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {LmTsService} from './../../lm-ts.service';

@Component({
    selector: 'app-ts-card',
    templateUrl: './ts-card.component.html',
    styleUrls: ['./ts-card.component.scss']
})
export class TsCardComponent implements OnInit {
	public profiles : Array<Object>;
    public practitioner:any;
    public card:number;
    public startDate:number;
	constructor(private activatedRoute: ActivatedRoute, private router: Router ,Service: LmTsService) { 
        this.practitioner=Service.practitioner;
  	}

  	ngOnInit() {
  		this.router.events.subscribe(val => {
            this.getRoutes();
        
        });

        // Intilization
        this.getRoutes();
        this.startDate = new Date('02/01/2018').getTime();
  	}
  	public getRoutes(){
        if(this.activatedRoute.firstChild){
            this.activatedRoute.firstChild.params.subscribe((params: Params) => {
                this.card = params['id'];
               
            });
        }
    }
    public returnDate(record){
    	return new Date(record).getTime();
    }

}
