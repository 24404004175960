import { Component, Input } from '@angular/core';
import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AssociatedEmailsComponent } from '../../associated-emails/associated-emails.component';

@Component({
  selector: 'app-change-password-popup',
  templateUrl: './change-password-popup.component.html',
  styleUrls: ['./change-password-popup.component.scss']
})
export class ChangePasswordPopupComponent {
  @Input() email='';
  sent_mail:boolean = false;

  constructor(public associateEmailCompo: AssociatedEmailsComponent) {}

  ngOnInit(): void {
    console.log(this.email);
  }

  public close() {
    $('.password-modal').css('display', 'none');
  }

}
