// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ibox-title--bdr {
  border-bottom: 1px solid #e7eaec;
}
.ibox-title .label {
  cursor: pointer;
}
.ibox-content--fe {
  align-self: center;
  border: 0;
  width: 100%;
}
.ibox-content .profile__img {
  cursor: pointer;
  display: block;
  margin: 0 auto;
}
.ibox-content .profile__img--resz {
  max-width: 430px;
  width: 100%;
}
.ibox-content .profile__img--nocrsr {
  cursor: unset;
}`, "",{"version":3,"sources":["webpack://./src/app/components/custom-profile/custom-tp-dashboard/custom-tp-dashboard.component.scss"],"names":[],"mappings":"AAEI;EACE,gCAAA;AADN;AAII;EACE,eAAA;AAFN;AAQI;EACE,kBAAA;EACA,SAAA;EACA,WAAA;AANN;AASI;EACE,eAAA;EACA,cAAA;EACA,cAAA;AAPN;AASM;EACE,gBAAA;EACA,WAAA;AAPR;AAUM;EACE,aAAA;AARR","sourcesContent":[".ibox {\n  &-title {\n    &--bdr {\n      border-bottom: 1px solid #e7eaec;\n    }\n\n    .label {\n      cursor: pointer;\n    }\n  }\n\n  &-content {\n\n    &--fe {\n      align-self: center;\n      border: 0;\n      width: 100%;\n    }\n\n    .profile__img {\n      cursor: pointer;\n      display: block;\n      margin: 0 auto;\n    \n      &--resz {\n        max-width: 430px;\n        width: 100%;\n      }\n  \n      &--nocrsr {\n        cursor: unset;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
