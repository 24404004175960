import { Component } from '@angular/core';
import { CustomProfileComponent } from '../../custom-profile/custom-profile.component';

@Component({
  selector: 'app-custom-pf-expire-popup',
  templateUrl: './custom-pf-expire-popup.component.html',
  styleUrls: ['./custom-pf-expire-popup.component.scss']
})
export class CustomPfExpirePopupComponent {

  constructor(public customPfCompo:CustomProfileComponent) {}

  public close() {
    $('.pf-expire-modal').css('display', 'none');
  }

}
