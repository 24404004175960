import { Component, Input } from '@angular/core';

import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { Auth } from '../../../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { LambdaFunctionService } from '../../../service/lambda-function.service';
import { TeamReportServiceService } from '../../team-report/team-report-service.service';
import { select, Store } from '@ngrx/store';

@Component({
  selector: 'app-myteam-userprofile',
  templateUrl: './myteam-userprofile.component.html',
  styleUrls: ['./myteam-userprofile.component.scss']
})
export class MyteamUserprofileComponent {

  @Input() members;
  clarifier_low:number = 0;
  clarifier_high:number = 0;
  ideator_low:number = 0;
  ideator_high:number = 0;
  developer_low:number = 0;
  developer_high:number = 0;
  implementor_low:number = 0;
  implementor_high:number = 0;
  integrator:number = 0;
  highest_score:number = 0;
  total_members:number = 0;
  storeNgrx:any;
  userProfile:any;
  @Input() forTeamsCompo:number;
  @Input() profileValues:boolean = false;

  constructor(public activatedRoute:ActivatedRoute, public router: Router, public http: HttpClient,public auth:Auth,public lmda_service: LambdaFunctionService,public teamReportService: TeamReportServiceService,public store:Store<any>,public route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe((params)=> {
        if(params['id']) {
            this.members = this.teamReportService.participantTeamData.allMembers;
        }
        console.log(this.router.url.includes('participant/'+params.id))
        if(this.router.url.includes('participant/'+params.id)) {
            this.userProfile = JSON.parse(localStorage.getItem('participantData'))
            this.userProfile["clarifier_score"] = this.userProfile?.default_profile?.clarifier_score;
            this.userProfile["ideator_score"] = this.userProfile?.default_profile?.ideator_score;
            this.userProfile["developer_score"] = this.userProfile?.default_profile?.developer_score;
            this.userProfile["implementer_score"] = this.userProfile?.default_profile?.implementer_score;
        } else {
            this.storeNgrx = this.store.select<any>((state) => state.profile.account.default_profile).pipe();
            this.storeNgrx.subscribe((res)=> {
                this.userProfile = res;
                console.log(this.userProfile)
            })
        }

        this.getPfData();
    })
  }

  getPfData() {
    $('.graph-loader').css('display','block');
    this.total_members = this.members?.length;
    this.members?.forEach(list => {
        if(list?.default_profile){
            if(list?.default_profile?.clarifier_score == 'Low'){
                this.clarifier_low = this.clarifier_low + 1;
                if( this.clarifier_low > this.highest_score ){
                    this.highest_score = this.clarifier_low;
                }
            }

            if(list?.default_profile?.clarifier_score == 'High'){
                this.clarifier_high = this.clarifier_high + 1;
                if( this.clarifier_high > this.highest_score ){
                    this.highest_score = this.clarifier_high;
                }
            }

            if(list?.default_profile.ideator_score == 'Low'){
                this.ideator_low = this.ideator_low + 1;
                if( this.ideator_low > this.highest_score ){
                    this.highest_score = this.ideator_low;
                }
            }

            if(list?.default_profile?.ideator_score == 'High'){
                this.ideator_high = this.ideator_high + 1;
                if( this.ideator_high > this.highest_score ){
                    this.highest_score = this.ideator_high;
                }
            }

            if(list?.default_profile.developer_score == 'Low'){
                this.developer_low = this.developer_low + 1;
                if( this.developer_low > this.highest_score ){
                    this.highest_score = this.developer_low;
                }
            }

            if(list?.default_profile?.developer_score == 'High'){
                this.developer_high = this.developer_high + 1;
                if( this.developer_high > this.highest_score ){
                    this.highest_score = this.developer_high;
                }
            }

            if(list?.default_profile?.implementer_score == 'Low'){
                this.implementor_low = this.implementor_low + 1;
                if( this.implementor_low > this.highest_score ){
                    this.highest_score = this.implementor_low;
                }
            }

            if(list?.default_profile?.implementer_score == 'High'){
                this.implementor_high = this.implementor_high + 1;
                if( this.implementor_high > this.highest_score ){
                    this.highest_score = this.implementor_high;
                }
            }

            if(list?.default_profile?.clarifier_score == 'Neutral' && list.default_profile.ideator_score == 'Neutral' && list.default_profile.developer_score == 'Neutral' && list.default_profile.implementer_score == 'Neutral'){
                this.integrator += 1;
            }
        }

    });
    $('.graph-loader').css('display','none');
  }
}
