import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { environment } from './../../../environments/environment';
import {AccessGroupService} from './../access-group.service';

@Component({
  	selector: 'app-foursight-framework',
  	templateUrl: './foursight-framework.component.html',
  	styleUrls: ['./foursight-framework.component.scss']
})
export class FoursightFrameworkComponent implements OnInit {
	public env;
    ngrxAccessGroup:any;
	constructor(public accessgroup:AccessGroupService, public router: Router,public store:Store<any>) { }

  	ngOnInit() {
        this.ngrxAccessGroup = this.store.select<any>((state) => state.profile.accessGroupData);
  		// it re-directs url to the dashboard page if access to this page is not permitted.
  		this.ngrxAccessGroup.subscribe(message => { 
            if(message && message.length > 0){                
                if(message['main_menu'].tools.foursight_framework != true){
                    this.router.navigate(['/dashboard']);
                    return false;
                }
            }

        },
        err => {
            // Log errors if any
            console.log(err);
        })
  		this.env = environment;
  	}

}
