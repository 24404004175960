import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import {Router, ActivatedRoute, Params, RoutesRecognized} from '@angular/router';
import { Store } from '@ngrx/store';
import {AccessGroupService} from './../../access-group.service';

@Component({
	selector: 'app-toolset',
  	templateUrl: './toolset.component.html',
  	styleUrls: ['./toolset.component.scss']
})
export class ToolsetComponent implements OnInit {
    ngrxAccessGroup:any;

  	constructor(public accessgroup:AccessGroupService, public router: Router,public store: Store<any>) { }

  	ngOnInit() {
        this.ngrxAccessGroup = this.store.select<any>((state) => state.profile.accessGroupData);
  		// it re-directs url to the dashboard page if access to this page is not permitted.
  		this.ngrxAccessGroup.subscribe(message => { 
            if(message && message.length > 0){                
                if(!message['main_menu'].learner_maps.personal_toolset_certification){
                    this.router.navigate(['/dashboard']);
                    return false;
                }
            }

        },
        err => {
            // Log errors if any
            console.log(err);
        })
  	}

}
