import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'endline'
})
export class EndlinePipe implements PipeTransform {

	transform(value: string): string {
  		if(value!=null && value!=undefined)
  		{
  			return value.replace(/\\n+/g,'');
  		}  
  	}

}
