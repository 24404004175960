import { Component, ViewChild, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { environment } from './../../../../environments/environment';

@Component({
    selector: 'app-innovation-clarify',
    templateUrl: './innovation-clarify.component.html',
    styleUrls: ['./innovation-clarify.component.scss']
})
export class InnovationClarifyComponent implements OnInit {

    public modal:any;
    public env:any;
    read_fac:boolean;

    constructor(private modalService: NgbModal, public aroute: ActivatedRoute) { }
    ngOnInit() {
        this.env = environment;
        this.read_fac = false;
    }

    // To open a template
    public open(content){
        this.modal = this.modalService.open(content, { windowClass: 'in grey' });
    }

    public show_fac(){
        this.read_fac = true;
    }

}

