import { Component, OnInit } from '@angular/core';
import {AccessGroupService} from './../access-group.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

declare var crs:any;

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss']
})
export class AddressesComponent implements OnInit {
	shop:any;
	addresses:any;
	modal:any;
	city:string;
	state:string;
	country:string;
	postal_code:string;
	address_name:string;
	org_name:string;
	address_line1:string;
	address_line2:string;
	update_city:string;
	address_first_name:string;
	address_last_name:string;
	update_state:string;
	update_email:string;
	address_nmbr;
	update_org:string;
	update_country:string;
	update_postal_code:string;
	update_address_name:string;
	update_address_first_name:string;
	update_address_last_name:string;
	update_address_email:string;
	update_address_line1:string;
	update_address_nmbr;
	update_address_line2:string;
	address_id:number;
	modal1:any;

  	constructor(public accessgroup: AccessGroupService, public router: Router, public http: HttpClient, private modalService: NgbModal) { }

  	ngOnInit() {
        this.http.get(`${environment.assessment_admin}api/v1/addresses`).subscribe(data => {
            console.log(data);
            if(data['errors']){
        		setTimeout(function() {
					this.toastr.options = {
					  closeButton: true,
					  timeOut: 10000
					};
					this.toastr.error(data['errors'], 'oops!');
				}, 1000);
        	}else{
        		this.addresses = data['addresses'];
            	console.log(this.addresses[0])
        	}
            
        },
            error => console.log('oops', error)
        )
  	}

  	public open(){
		this.modal = true;
		setTimeout(() => {
			$('.address-modal').css('display','block');
			crs.init();
		}, 1000);
  		// this.modal = this.modalService.open(content, { windowClass: 'in grey' });
		setTimeout(() => {
			crs.init();
		}, 100);
  	}

  	public update(content,i,id){
  		console.log(i,this.addresses[i])
  		this.address_id = id;
		this.modal = false;
  		// this.modal1 = this.modalService.open(content, { windowClass: 'in grey' });
		  $('.address-modal').css('display','block');
  		this.update_address_name = this.addresses[i].name;
  		this.update_org = this.addresses[i].organization_name;
  		this.update_address_first_name = this.addresses[i].first_name;
  		this.update_address_last_name = this.addresses[i].last_name;
  		this.update_address_email = this.addresses[i].email;
		this.update_address_line1 = this.addresses[i].address_line1;
		this.update_address_line2 = this.addresses[i].address_line2;
		this.update_address_nmbr = this.addresses[i].phone;
		this.update_city = this.addresses[i].city;
		this.update_state = this.addresses[i].state; 
		this.update_country = this.addresses[i].country;
		this.update_postal_code = this.addresses[i].postal_code;
  		setTimeout(function() {
            crs.init();
        }, 0);
		var that = this;
  		setTimeout(function() {
            $("#update_country").val(that.update_country).trigger('change');
            $("#update_state").val(that.update_state);
        }, 1000);
  	}

  	public addAddress(){
  		var Data = {
  			name: this.address_name,
  			organization_name: this.org_name,
  			first_name: this.address_first_name,
  			last_name: this.address_last_name,
  			email: this.update_email,
  			address_line1: this.address_line1,
  			address_line2: this.address_line2,
  			phone: this.address_nmbr,
  			city: this.city, 
  			state: this.state, 
  			country: this.country,
  			postal_code:this.postal_code
  		}
  		console.log(Data);
		let checkValue = ()=> {
			return (this.address_name && this.address_line1 && this.address_nmbr.length >= 10 && this.address_first_name && this.address_last_name && this.update_email && this.city && this.country && this.postal_code && this.state);
		}
		console.log(checkValue());
  		if(checkValue()){
	  		this.http.post(`${environment.assessment_admin}api/v1/addresses`,Data)
	    	.subscribe(
	    		res => {	    		
		    		console.log(res);
		  			setTimeout(function() {
			            this.toastr.options = {
			                closeButton: true,
	                    	progressBar: true,
			                timeOut: 2000
			            };
			            this.toastr.success('Your new address has been added!', 'Nice!');
		        	}, 1000);
		        	// this.modal.close();
					$('.address-modal').css('display','none');
		        	this.ngOnInit();
				},
				err =>{
					console.log(err);
		  			setTimeout(function() {
			            this.toastr.options = {
			                closeButton: true,
	                    progressBar: true,
			                timeOut: 2000
			            };
			            this.toastr.error(err.error.errors, 'oops!');
		        	}, 1000);
				}
			)   
		}             
  	}

  	public updateAddress(){
  		var Data = {
  			name: this.update_address_name,
  			organization_name: this.update_org,
  			first_name: this.update_address_first_name,
  			last_name: this.update_address_last_name,
  			email: this.update_address_email,
  			address_line1: this.update_address_line1,
  			address_line2: this.update_address_line2,
  			phone: this.update_address_nmbr,
  			city: this.update_city, 
  			state: this.update_state, 
  			country: this.update_country,
  			postal_code:this.update_postal_code
  		}
  		//console.log(Data);
		let checkValue = ()=> {
			return this.update_address_line1 && this.update_address_first_name && this.update_address_last_name && this.update_address_email && this.update_address_nmbr.length >= 10 && this.update_address_name && this.update_city && this.update_country && this.update_state && this.update_postal_code
		}
  		if(checkValue()){
	  		this.http.put(`${environment.assessment_admin}api/v1/addresses/${this.address_id}`, Data)
	    	.subscribe(
	    		res => {	    		
		    		//console.log(res);
		  			setTimeout(function() {
			            this.toastr.options = {
			                closeButton: true,
	                    progressBar: true,
			                timeOut: 2000
			            };
			            this.toastr.success('Your address has been updated!', 'Nice!');
		        	}, 1000);
		        	// this.modal1.close();
					$('.address-modal').css('display','none');
		        	this.ngOnInit();
				},
				err =>{
					//console.log(err);
		  			setTimeout(function() {
			            this.toastr.options = {
			                closeButton: true,
	                    progressBar: true,
			                timeOut: 2000
			            };
			            this.toastr.error(err.error.errors, 'oops!');
		        	}, 1000);
				}
			)   
		}             
  	}

  	public delete(index,id){        
    	this.http.delete(`${environment.assessment_admin}api/v1/addresses/${id}`)
    	.subscribe(
    		res => {	    		
	    		console.log(res);
	  			setTimeout(function() {
		            this.toastr.options = {
		                closeButton: true,
                    	progressBar: true,
		                timeOut: 2000
		            };
		            this.toastr.success('Your address has been deleted!', 'Nice!');
	        	}, 1000);
	        	this.addresses.splice(index,1);
			},
			err =>{
				console.log(err);
	  			setTimeout(function() {
		            this.toastr.options = {
		                closeButton: true,
                    	progressBar: true,
		                timeOut: 2000
		            };
		            this.toastr.error(err.error.errors, 'oops!');
	        	}, 1000);
			}
		)   
    }

    public notNumber(evt){
		console.log(evt)
	    if ((evt.which < 48 || evt.which > 57) && (evt.which != 43)){
	        evt.preventDefault();
	    }
	}

}
