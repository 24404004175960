import { Component, Injector, Input } from '@angular/core';
import { OrgsComponent } from '../orgs.component';

@Component({
  selector: 'app-roster-hierarchical-view',
  templateUrl: './roster-hierarchical-view.component.html',
  styleUrls: ['./roster-hierarchical-view.component.scss']
})
export class RosterHierarchicalViewComponent {
  @Input() record;
  @Input() index;
  injectedCompo;

  constructor(private injector: Injector) {
    this.injectedCompo = this.injector.get(OrgsComponent);
  }
}
