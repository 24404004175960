import { createReducer, on } from '@ngrx/store';

// import { addProfile, retrievedProfileList } from '../action/fs.action';
import { fsProfileModel } from '../model/fs-profile.model';
import * as actionType from '../action/fs.action'

export const initialState: fsProfileModel = {
  isLoading: false,
  account: [],
  tcwaProfile: [],
  accessGroupData: {},
  error: null,
}

export const reducers = createReducer(
  initialState, 
  on(actionType.getProfile, (state) => ({...state, isLoading: true})),
  on(actionType.getTcwaProfile, (state) => ({...state, isLoading: true})),
  on(actionType.getProfileSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    account: action, 
  })),
  on(actionType.getProfileFailure, (state,action) => ({
    ...state,
    isLoading: true,
    error: action.error,
  })),
  on(actionType.getAccessGroupSuccess, (state,action) => ({
    ...state,
    isLoading: false,
    accessGroupData: action, 
  })),
  on(actionType.getTcwaProfileSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    tcwaProfile: action, 
  })),
  on(actionType.getTcwaProfileFailure, (state,action) => ({
    ...state,
    isLoading: true,
    error: action.error,
  })),
);
