import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import { CommonDataService } from '../../common-data.service';
import { CustomPfService } from '../custom-pf.service';

@Component({
  selector: "app-custom-collaboration-profile",
  templateUrl: "./custom-collaboration-profile.component.html",
  styleUrls: ["./custom-collaboration-profile.component.scss"],
})
export class CustomCollaborationProfileComponent implements OnInit {
  env: any;
  id: string;
  profiles: any;
  profile_type_data: any;
  collab_pf: string;
  collab_pf_icon: string;
  benefit_blk: boolean = false;
  app_collaboration: string;
  loading: boolean = true;
  mailto: string = "";
  storeNgrx: any;
  ngrxTcwaProfile: any;

  constructor(
    public http: HttpClient,
    public activatedRoute: ActivatedRoute,
    public customPfService: CustomPfService,
    private service: CommonDataService,
    public store: Store<any>
  ) {}

  async ngOnInit() {
    this.ngrxTcwaProfile = this.store.select<any>((state) => state.profile.tcwaProfile);
    this.storeNgrx = this.store.select<any>((state) => state.profile?.account.default_profile);
    this.env = environment;
    var that = this;
    this.app_collaboration = localStorage.getItem("collaboration-pf");

    // add class on click
    $(document).on("click", ".icon-img li", function () {
      $(".icon-img li").removeClass("active--focus");
      $(".icon-img li").addClass("inactive--focus");
      $(this).addClass("active--focus");
    });

    // add actvie class in ul
    $(document).on("mouseenter", ".icon-img", function () {
      $(this).addClass("active");
    });
    $(document).on("mouseleave", ".icon-img", function () {
      $(this).removeClass("active");
    });

    // add active class in li
    $(document).on("mouseenter", ".icon-img li", function () {
      $(".icon-img li").addClass("inactive");
      $(this).addClass("active");
      $(this).removeClass("inactive");
    });
    $(document).on("mouseleave", ".icon-img li", function () {
      $(this).removeClass("active");
      $(".icon-img li").removeClass("inactive");
    });

    if (this.app_collaboration == "false") {
      await this.activatedRoute.params.subscribe((params: Params) => {
        this.id = params.id;

        this.ngrxTcwaProfile.subscribe((pf) => {
          this.profiles = pf["profile"];
          this.collab_pf = this.profiles?.profile_title;
          this.collab_pf_icon = this.profiles["profile_title"];
          this.collab_pf_icon = this.removeSpace(this.profiles["profile_title"]);
          this.mailto = `mailto:?subject=${this.profiles?.profile_title}%20|%20FourSight®%20Thinking%20Profiles&body=My FourSight Thinking Profile is%0D%0A%0D%0A${this.profiles?.profile_title}%0D%0A%0D%0A`;
          console.log(this.mailto);
        });
      });

      // tracking collaboration_profile_viewed
      this.activatedRoute.params.subscribe((params: Params) => {
        this.id = params.id;
        this.customPfService.trackActivity(
          this.id,
          "collaboration_profile_viewed"
        );
      });
    } else {
      // fetch profile data for login app
      await this.storeNgrx.subscribe((message) => {
        // for (var i = 0; i < message.length; i++) {
          this.profiles = message;
        // }
        console.log(this.profiles["profile_title"]);
        this.collab_pf = this.profiles["profile_title"];
        this.collab_pf_icon = this.profiles["profile_title"];
        this.mailto = `mailto:?subject=${this.profiles?.profile_title}%20|%20FourSight®%20Thinking%20Profiles&body=My FourSight Thinking Profile is%0D%0A%0D%0A${this.profiles?.profile_title}%0D%0A%0D%0A`;
        console.log(this.mailto);
      });
    }

    // fetch collaboration data
    this.http
      .get(`${environment.profileURL}/api/v1/profile_types.json`, {
        params: { profile: "custom", lang: "en" },
      })
      .subscribe((res) => {
        console.warn(res);
        that.profile_type_data = res["profile_types"];
        console.warn(that.profile_type_data);
        setTimeout(() => {
          this.loading = false;
        }, 700);
      });
  }

  // change profile
  public change_pf(val) {
    this.collab_pf = val;
    this.benefit_blk = true;
    if (val == "Early Bird") {
      this.collab_pf_icon = "early-bird";
    } else if (val == "Idea Broker") {
      this.collab_pf_icon = "idea-broker";
    } else {
      this.collab_pf_icon = val;
    }
  }

  // remove space from profile title 
  removeSpace(val) {
    return val.replace(" ","-");
  }

  public social_network(val) {
    let pf_title = this.removeSpace(this.profiles?.profile_title);
    console.log(pf_title);
    if (val == "twit") {
      window.open(
        `https://twitter.com/share?url=https://www.foursightonline.com/${pf_title}`,
        "Twitter Share",
        "width=600,height=600"
      );
    } else if (val == "fb") {
      window.open(
        `http://www.facebook.com/sharer/sharer.php?u=https://www.foursightonline.com/${pf_title}`,
        "Facebook Share",
        "width=600,height=600"
      );
    } else if (val == "linked") {
      window.open(
        `http://www.linkedin.com/shareArticle?mini=true&url=https://www.foursightonline.com/${pf_title}`,
        "LinkedIn Share",
        "width=600,height=600"
      );
      console.log(
        `http://www.linkedin.com/shareArticle?mini=true&url=https://www.foursightonline.com/${pf_title}`
      );
    }
  }

  // copy text to clipboard
  public copy_text() {
    let copyText = "https://www.foursightonline.com/" + this.profiles?.profile_title;
    navigator.clipboard.writeText(copyText);
    let icon = document.querySelector(".cpy");
    icon.classList.add("tooltip-cpy");
    // ev.target.addClass('tooltip-cpy');

    setTimeout(function () {
      icon.classList.remove("tooltip-cpy");
    }, 1500);
  }
}
