import { Component, OnInit } from '@angular/core';
import {AccessGroupService} from './../access-group.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss']
})
export class StoreComponent implements OnInit {

  constructor(public accessgroup: AccessGroupService, public router: Router) { }

  	ngOnInit() {
	}

}
