import { Component } from '@angular/core';
import { VolumePricingComponent } from '../../../store/volume-pricing/volume-pricing.component';

@Component({
  selector: 'app-calculated-price-popup',
  templateUrl: './calculated-price-popup.component.html',
  styleUrls: ['./calculated-price-popup.component.scss']
})
export class CalculatedPricePopupComponent {
  constructor(public volumePriceCompo:VolumePricingComponent) {}

  public close() {
    $('.cal-price-popup').css('display', 'none');
  }
}
