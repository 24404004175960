import { Component, Input } from '@angular/core';
import { TeamReportServiceService } from './../../team-report/team-report-service.service'
import { OrgIndividualTeamsComponent } from '../../org-individual-teams/org-individual-teams.component';

@Component({
  selector: 'app-roster-add-member-popup',
  templateUrl: './roster-add-member-popup.component.html',
  styleUrls: ['./roster-add-member-popup.component.scss']
})
export class RosterAddMemberPopupComponent {

  bodyText:string;
  delBodyText:string;
  email;
  fromOrgs = 0;
  constructor(public teamReportService: TeamReportServiceService, public orgIndivTeams: OrgIndividualTeamsComponent){}
  
  ngOnInit() {
    console.warn('====>',this.orgIndivTeams?.removeRosterMemDetails?.default_identity?.email)
    this.email = localStorage.getItem('email')
    this.teamReportService.rosterName.subscribe((name)=> {
      this.bodyText = `FourSight Team,&#10;Please add the following members to my roster.&#10;&#10;Roster name: ${name}&#10;Account Email: ${this.email}&#10;&#10;New Members&#10;&#10;Name 1, Email 1&#10;Name 2, Email2&#10;•••`;
      this.delBodyText = `FourSight Team,&#10;Please delete the following member of my roster.&#10;&#10;Roster name: ${name}&#10;Account Email: ${this.email}&#10;&#10;Email of member&#10;${this.orgIndivTeams?.removeRosterMemDetails?.default_identity?.email}`;
    })
  }

  public copyText(id,i) {
    let el = document.querySelectorAll('.cpy')[i];
    el?.classList.add('cpy--cpd');
    setTimeout(() => {
      el?.classList.remove('cpy--cpd');
      el?.classList.remove('cpy--txtar');
    }, 1000);
    let copyText;
    if(id == 'body-content') {
      el?.classList.add('cpy--txtar');
      copyText = document.getElementById(id);
      copyText?.select();
      copyText?.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value);
    } else {
      copyText = id;
      navigator.clipboard.writeText(copyText);
    }
  }

  public close() {
    $('.roster_popup').css('display','none');
    $('.roster_addmem_popup').css('display','none');
  }
}
