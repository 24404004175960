
import { environment } from './../../environments/environment'
interface AuthConfig {
  clientID: string;
  domain: string;
  callbackURL: string;
  apiUrl: string;
}
export const AUTH_CONFIG: AuthConfig = {
  clientID: environment.clientID,    //staging
  domain: 'foursight.eu.auth0.com',
  callbackURL: environment.callbackURL,
  apiUrl: 'https://foursight.eu.auth0.com/api/v2/'
};