import { Component, Input, Output, EventEmitter } from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute,Params} from '@angular/router';
// import { Auth } from '../../../auth/auth.service';
import { environment } from '../../../../environments/environment';
import { detectBody, smoothlyMenu } from '../../../app.helpers';
import { CustomPfService } from '../custom-pf.service';
import { LmTsService } from '../../learner-maps/lm-ts.service';
import { Store } from '@ngrx/store';

declare var jQuery:any;

@Component({
  selector: 'app-custom-navigation',
  templateUrl: './custom-navigation.component.html',
  styleUrls: ['./custom-navigation.component.scss']
})

export class CustomNavigationComponent {

    // app:any;
    public env;
    menu_links;
    public profile:any;
    public profiles:any;
    sideNav:boolean = true;
    access_group:any;
    store_url:string = environment.store_url;
    store:string = environment.store;
    assessment_admin:string = environment.assessment_admin;
    app_v:string = environment.slideVersion;
    @Output() contentChange = new EventEmitter();
    @Input() activePage;
    active_lmss_id = '';
    slideshows_obj = [];
    @Input() challenge_print_nav:any;
    ngrxTcwaProfile: any;

    constructor(private router: Router,public customPfService: CustomPfService, public activatedRoute:ActivatedRoute, public lm_ts: LmTsService,public ngrxStore: Store<any>) {}

    ngOnInit() {
        this.ngrxTcwaProfile = this.ngrxStore.select<any>((state) => state.profile.tcwaProfile)
        this.env = environment;

        // jQuery("#side-menu .mCustomScrollbar").mCustomScrollbar();
        var that=this;
        if(this.challenge_print_nav) {
            console.log("Challenge navigator nav");
        } else {
            // profile data 
            this.ngrxTcwaProfile.subscribe(res => {
                this.profiles = res['profile'];
                for(let i in this.profiles?.access_groups_json?.main_menu){
                    if(i.includes('dlm_')) {
                        this.slideshows_obj.push(this.profiles?.access_groups_json?.main_menu[i])
                    }
                }
                if(this.profiles?.access_groups_json?.main_menu?.profile_slideshow?.lmproperties != true && this.profiles?.access_groups_json?.main_menu != undefined) {
                    this.activePage = "Dashboard";
                }else {
                    this.activePage = "Profile Slideshow";
                }
            })
    
            this.customPfService.active_lmsd_id.subscribe(val => {
                this.active_lmss_id = val;
            })
        }


    }

    ngAfterViewInit() {
        setTimeout(() => {
            jQuery('.scroll-bar').metisMenu();
        },2000)

        // if (jQuery("body").hasClass('fixed-sidebar')) {
        //     jQuery('.sidebar-collapse').slimscroll({
        //         height: '100%'
        //     })
        // }
    }

    activeRoute(routename: string): boolean{
        return this.router.url.indexOf(routename) > -1;
    }

    // Smaller Navbar
    public toggleNavigation(){
        $('body').toggleClass('mini-navbar');
        if (!$('body').hasClass('mini-navbar') || $('body').hasClass('body-small')) {
            // Hide menu in order to smoothly turn on when maximize menu
            $('#side-menu').hide();
            // For smoothly turn on menu
                setTimeout(function () {
                    $('#side-menu').fadeIn(500);
                }, 100);
        } else {
            // Remove all inline style from jquery fadeIn function to reset menu state
            $('#side-menu').removeAttr('style');
        }

        if($('.owl-carousel').length){
            setTimeout(function () {
                var owl = $(".owl-carousel").data('owlCarousel');
                owl.updateVars();
            }, 500);
        }
    }

    // update content view value
    public changeContent(content,lm_id = ''){
    	this.contentChange.emit(content);
        if(content == 'Thinking Profile Dashboard' || content == 'Interpretive Guide' || content == 'Profile Slideshow' || content == 'Collaboration Profile') {
            $('.cpf-pfmenu').addClass('cpf-pfmenu__visible');
            $('.fs-reveal.collapse').removeClass('in');
            $('.fs-rvl__item').removeClass('fs-rvl__item--vis');
        }
        if(content == 'Dashboard') {
            $('.cpf-pfmenu').removeClass('cpf-pfmenu__visible');
            $('.fs-reveal.collapse').removeClass('in');
            $('.fs-rvl__item').removeClass('fs-rvl__item--vis');
        }
        if(content.includes('FourSight Reveal')) {
            $('.cpf-pfmenu').removeClass('cpf-pfmenu__visible');
            $('.fs-reveal.collapse').addClass('in');
            $('.fs-rvl__item').addClass('fs-rvl__item--vis');
        }
        if(lm_id !== '') {
            this.customPfService?.active_lmsd_id.next(lm_id);
        }
        if(content == 'Profile Slideshow') {
            this.customPfService?.active_lmsd_id.next(lm_id);
            this.lm_ts?.an_slide_no.next(0);
        }
    }
  
}