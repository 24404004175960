import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from './../../../../environments/environment';
import { CustomPfService } from '../custom-pf.service';
import {AccessGroupService} from './../../access-group.service';
import { CommonDataService } from '../../common-data.service';
import { Store } from '@ngrx/store';

@Component({
  selector: "app-custom-tp-dashboard",
  templateUrl: "./custom-tp-dashboard.component.html",
  styleUrls: ["./custom-tp-dashboard.component.scss"],
})
export class CustomTpDashboardComponent implements OnInit {
  env;
  @Output() contentChange = new EventEmitter();
  main_app_enable: boolean = false;
  profiles: any;
  menu_links;
  cUser: any;
  storeNgrx: any;
  ngrxAccessGroup;
  ngrxTcwaProfile;

  constructor(
    public service: CommonDataService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public customPfService: CustomPfService,
    public accessgroup: AccessGroupService,
    public store: Store<any>
  ) {}

  ngOnInit(): void {
    this.storeNgrx = this.store.select<any>((state) => state.profile?.account.default_profile);
    this.ngrxAccessGroup = this.store.select<any>((state) => state.profile.accessGroupData);
    this.ngrxTcwaProfile = this.store.select<any>((state) => state.profile.tcwaProfile)
    this.env = environment;
    console.log("router", this.router?.url);

    if (this.router?.url.includes("fs-profile")) {
      this.main_app_enable = true;
      this.storeNgrx.subscribe((message) => {
          this.profiles = message;
        console.log(this.profiles);
      });
      this.ngrxAccessGroup.subscribe(
        (message) => {
          if (message) {
            this.menu_links = message;
          }
        },
        (err) => {
          // Log errors if any
          console.log(err);
        }
      );
    } else {
      // profile data
      this.ngrxTcwaProfile.subscribe((res) => {
        this.profiles = res["profile"];
      });
    }
  }

  // update content view value
  public changeContent(content) {
    console.log(content);
    this.contentChange.emit(content);
  }

  // update route
  public updateRoute(routePath) {
    this.router.navigate([`${routePath}`]);
  }
}
