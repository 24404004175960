import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-login-callback',
	templateUrl: './login-callback.component.html',
	styleUrls: ['./login-callback.component.scss']
})
export class LoginCallbackComponent implements OnInit {

	constructor() { }

	ngOnInit() {}

}
