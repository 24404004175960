import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-accept-paypal',
  templateUrl: './accept-paypal.component.html',
  styleUrls: ['./accept-paypal.component.scss']
})
export class AcceptPaypalComponent implements OnInit {
    id:any;
    loading:boolean = true;

    constructor(public location: Location, public http:HttpClient, public router: Router, private route: ActivatedRoute) { }

    ngOnInit() {
        var param1 = this.route.snapshot.queryParamMap.get('token');
        var param2 = this.route.snapshot.queryParamMap.get('id');
        console.log(param1, param2);
	  	this.http.get(`${environment.assessment_admin}api/v1/carts/`+param2+`/paypal_purchase?token=`+param1).subscribe(data => {
	            console.log(data);
	            this.loading = false;
	            this.id = data['order']['id'];
	            var cart = data['order']['line_items'];
                var products = [];
                for(var i=0;i<cart.length;i++){
                    products.push({
                        product_id: cart[i].variant.product_id,
                        currency: 'USD',
                        sku: cart[i].variant.sku,
                        name: cart[i].variant.name,
                        price: parseFloat(cart[i].variant.price),
                        quantity: cart[i].quantity,
                        image_url: cart[i].variant.image_url
                    })
                }
                // this.angulartics2Segment.eventTrack('purchase', 
                //     { 
                //         ecommerce:{
                //             purchase:{
                //                 actionField:{
                //                     id: data['order']['id'],
                //                     coupon: data['order']['applied_discount_code'],
                //                     currency: 'USD',
                //                     affiliation: '',
                //                     revenue: parseFloat(data['order']['net_amount']),
                //                     tax: parseFloat(data['order']['tax_amount']),
                //                     shipping: parseFloat(data['order']['shipping_amount']),
                //                     value: parseFloat(data['order']['net_amount']),                            
                //                     discount: parseFloat(data['order']['total_discount'])
                //                 },                        
                //                 products: products
                //             }
                //         }
                //     }
                // );
	            // this.angulartics2Segment.eventTrack('Order Completed', 
                //     { 
                //         order_id: data['order']['id'],
                //         total: data['order']['net_amount'],
                //         shipping: data['order']['shipping_amount'],
                //         tax: data['order']['tax_amount'],
                //         discount: data['order']['total_discount'],
                //         coupon: data['order']['applied_discount_code'],
                //         currency: 'USD',
                //         products: products
                //     }
                // );
	            this.router.navigate([`/custom-store/order-details/${this.id}`]);
	        },
            error => {
                console.log(error)
                this.loading = false;
                this.router.navigate(['/custom-store/cart']);
                if(error.status == 422){
                    setTimeout(function() {
                        this.toastr.options = {
                          closeButton: true,
                          timeOut: 4000
                        };
                        this.toastr.error(error.error.errors, 'oops!');
                    }, 1000);
                }else{
                    setTimeout(function() {
                        this.toastr.options = {
                          closeButton: true,
                          timeOut: 2000
                        };
                        this.toastr.error('Something went wrong!', 'oops!');
                    }, 1000);
                }
            }
        )
    }

}
