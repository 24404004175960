import { Component } from '@angular/core';

@Component({
  selector: 'app-change-account-email',
  templateUrl: './change-account-email.component.html',
  styleUrls: ['./change-account-email.component.scss']
})
export class ChangeAccountEmailComponent {

  ngOnInit(): void {
    console.log('popup change account email');
  }

  public close() {
    $('.email-modal').css('display', 'none');
  }

  public changeAccountEmail() {
    $('.link-email-modal').css('display', 'block')
    $('.email-modal').css('display', 'none')
  }
}
