import { Component, Input, OnInit } from '@angular/core';
import { environment } from './../../../../environments/environment';

@Component({
	selector: 'app-cig-ideate',
	templateUrl: './cig-ideate.component.html',
	styleUrls: ['./cig-ideate.component.scss']
})
export class CigIdeateComponent implements OnInit {

	@Input() foursightassets;
	@Input() author;
	@Input() slides;
	public env;

	constructor() { }

	ngOnInit() {
		this.env=environment;
	}

}
