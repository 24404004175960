import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2/dist/sweetalert2.js'
// import 'sweetalert2/src/sweetalert2.scss';
import { OrgsIndividualComponent } from '../orgs-individual/orgs-individual.component';
import { OrgIndividualTeamsComponent } from '../org-individual-teams/org-individual-teams.component';
import { Auth } from '../../auth/auth.service';
import { TeamReportServiceService } from '../team-report/team-report-service.service';
// Import the AuthService type from the SDK
// import { AuthService } from '@auth0/auth0-angular';

declare var jQuery:any;

@Component({
  selector: 'app-orgteam-members',
  templateUrl: './orgteam-members.component.html',
  styleUrls: ['./orgteam-members.component.scss']
})

export class OrgteamMembersComponent implements OnInit {
	@Input() team;
	@Input() name;
	id:any;
	params_id:any;
	members:any;
	org_name:any;
	currentTeam:any;
	team_name:any;
	team_members:any;
	team_id:any;
	eTeam:any;
	meta:any;
	checkedAll:boolean;
	isLoading:boolean;
	modal:any;
	loading:any;
	userEmail:any;
	checkcount:number;
	membersByPage:number;
	globalGroup:any;
	query_string:string;
	Query_String:string;
	Query_String1:string;
	pageLen:number;
	curPage:number;
	item:any;
	first_name:string;
	last_name:string;
	group:any;
	modal1:any;
	listMembers:any;
    modal2:any;
    sessionID:any;
    sessionMembers:any;
    addtoSession:any;
    app_type:string;
    email:any;
    pdf_type:string;
    modal3:any;
    teamID:any;
    getOptions:boolean;
    addMember:boolean;
    Downloadpdf:boolean;
    download_profile:boolean;
    adminteamWizard:boolean;
    newteamWizard:boolean;
    sessionWizard:boolean;
    allTeamMembers:Array<number>;
    error;
    teamMembers:any =[];

  	constructor( private router: Router, public activatedRoute:ActivatedRoute, public http:HttpClient, public modalService:NgbModal, public orgs:OrgIndividualTeamsComponent, public org_individual: OrgsIndividualComponent,public auth:Auth,public teamReportService: TeamReportServiceService) { }

  	ngOnInit() {
        console.warn('orgteam')
  		this.activatedRoute.params.subscribe((params: Params) => {
            this.params_id = params.id;
            console.log(this.params_id);
        });

        console.warn('rec---->',this.team)
        console.log(this.teamReportService.team_members)
        var that = this;
        that.teamReportService.team_members.subscribe((res)=> {
            console.warn('---->',res)
            if(res.length > 0) {
                this.teamMembers = res;
            }
        })
  		this.globalGroup = [];
  		this.checkedAll = false;
  		this.checkcount = 0;
  		this.isLoading = true;
  		this.membersByPage = 20;
  		this.query_string = environment.profileURL+`/api/v1/teams/`+this.team+`/memberships.json?`;
  		this.Query_String = this.query_string;
        this.http.get(`${environment.profileURL}/api/v1/teams/`+this.team+`/memberships.json?per_page=`+this.membersByPage).subscribe(data => {
            // this.teamReportService.current_team_members.next(data['meta'].total_count);
           	this.members = data['memberships'];
           	this.meta = data['meta'];
           	this.isLoading = false;
           	this.pageLen = this.meta.total_pages;
           	this.curPage = 0;
           	this.item = [];
           	for(var i = 1; i<=this.pageLen;i++){
			    this.item.push(i);
			}
			if(this.meta.total_count > this.membersByPage){
				this.render( this.curPage, this.item, true );
	            console.log(data);
	        }
            console.log(data);
        },
            error => console.log('oops', error)
        )

        this.auth.getProfile((err, profile) => {
            this.email = profile.email;
        });
        // this.auth.getUser().subscribe(user => {
        //     this.email = user.email;
        // })

  	}
  	public open(){
        // this.modal = this.modalService.open(content, { windowClass: 'in grey' });
        $('.content-modal').css('display','block');
    }

    public close() {
        $('.content-modal').css('display','none');
    }

    public addTeammate(){
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var email = re.test(this.userEmail);
        if(email){
        	this.loading = 1;
        	this.http.post(`${environment.profileURL}/api/v1/teams/`+this.team+`/add_members.json`,{
              members_attributes: {
                "0": {
                  email: this.userEmail,
                  role_id: 3
                }
              }
            }).subscribe(result => {
        		console.log(result);
                this.members.push(result['team']);
    	        this.loading = 2;
                this.userEmail = '';
                this.meta.total_count = this.members.length;
                this.loading = false;
                console.log(this.meta)
    	        // this.modal.close(result);
                $('.content-modal').css('display','none');
                let teamIndex:number;
                this.teamReportService.team_members.subscribe(res =>{
                  res.forEach((team, index) => {
                    if(team["id"] == this.team){
                        teamIndex = index;
                    }
                })  
                })
                this.teamReportService.team_members[teamIndex] = {id:result?.["team"].id,memberships_count: result?.["team"].memberships_count};
                // setTimeout(() => {
                //     let teamIcon = document.querySelectorAll('.fa.fa-chevron-right')[teamIndex -1] as HTMLElement;
                //     teamIcon.click();
                // },500);
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 2000
                    };
                    this.toastr.success('New Member was added successfully!', 'Nice!');
                }, 1000);
                this.ngOnInit();
            },response => {
              	console.log(response.data.errors);
            })
        }
    }

    public deleteMember(){
    	this.group = [];
    	var that = this;
    	if($('.checkedbox-'+this.team+':checked').length > 0) {
            $('.checkedbox-'+this.team+':checked').each(function(){
                for(var i=0; i<that.members.length; i++){
                    if($(this).parents('tr').attr('id') == that.members[i].member_id)
                    that.group.push(that.members[i]);
                }
            });
      	}
      	// console.log(this.group);
    	Swal.fire({
	        title: "Are you sure?",
	        text: "Are you sure, you want to delete this Member? This cannot be undone!",
	        type: "warning",
	        showCancelButton: true,
	        confirmButtonColor: "#DD6B55",
	        cancelButtonColor: '##D0D0D0',
	        confirmButtonText: "Yes, delete it!"
    	}).then((result) => {
	        if (result['value'] == true) {
	        	jQuery('.swal2-container').addClass('hide');
	    		jQuery('body').removeClass('swal2-shown');
	    		jQuery('html').removeClass('swal2-shown');
	    		this.group.forEach(list =>{
	    			this.http.delete(`${environment.profileURL}/api/v1/identities/`+list.identity_id+'/memberships/'+list.id+'.json').subscribe(result => {
			            console.log(result);
			            console.log(list.identity_id,list.id,list.team_id);
                        for(var i=0; i<this.members.length; i++){
                            if(list.member_id == that.members[i].member_id)
                            this.members.splice(i,1);
                            this.checkcount -= 1;
                        }
                        // update counter
                        let teamIndex;
                        this.teamReportService.team_members.subscribe(res=> {
                            res.forEach((team, index) => {
                                if(team["id"] == this.team){
                                    teamIndex = index;
                                }
                            })

                        })
                        // console.log(teamIndex,this.teamReportService.team_members[teamIndex])
                        this.teamReportService.team_members.subscribe(res=> {
                            res[teamIndex] = {id:list.team_id,memberships_count: this.teamReportService.team_members[teamIndex]?.memberships_count - 1};
                        })
                        // this.teamReportService.team_members[teamIndex] = {id:result?.["team"].id,memberships_count: result?.["team"].memberships_count};
                        // setTimeout(()=> {
                        //     this.teamReportService.team_members = [];
                        //     this.ngOnInit();
                        // },1000)
			            // this.members.splice(index,1);
			            setTimeout(function() {
			                this.toastr.options = {
                                closeButton: true,
                                progressBar: true,
			                    timeOut: 2000
			                };
			                this.toastr.success('The Member was deleted successfully!', 'Nice!');
			            }, 1000);

			        },
			        err =>{
		                console.log(err);
		                setTimeout(function() {
			                this.toastr.options = {
			                    closeButton: true,
                                progressBar: true,
			                    timeOut: 2000
			                };
			                this.toastr.error(err.errors, 'Sorry!');
			            }, 1000);
	            	})
	    		})

	        }else{
                jQuery('.swal2-container').addClass('hide');
                jQuery('body').removeClass('swal2-shown');
                jQuery('html').removeClass('swal2-shown');
            }
    	},function(dismiss){
    		jQuery('.swal2-container').addClass('hide');
    		jQuery('body').removeClass('swal2-shown');
    		jQuery('html').removeClass('swal2-shown');
    	});
    }

    public makeLead(){
        // var that = this;
        // console.info(that.members);
        let index = $('.checkedbox-'+this.team+':checked').parents('tr').attr('data-index');
        // console.info("INDEX -- ", index);
        console.log(this.members[index]);
        let attr = {membership_role_id: 4};
        if(index){
            let membership_id = this.members[index].id;
            let identity_id = this.members[index].identity_id;
            // Hit API for Making Lead
            this.http.put(`${environment.profileURL}/api/v1/identities/${identity_id}/memberships/${membership_id}.json`, attr).subscribe(result => {
                console.log(result);
                
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 2000
                    };
                    this.toastr.success("Member's role was updated successfully!", 'Nice!');
                }, 500);

                // Update the local array
                this.members[index] = result['membership'];
            },
            err =>{
                console.log(err);
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 2000
                    };
                    this.toastr.error(err.errors, 'Sorry!');
                }, 1000);
            })
        }
    }

    public addSession(content,records){
        this.loading = false;
        var counter = 0;
        this.sessionMembers = [];
        this.addtoSession = [];
        this.globalGroup.forEach(list =>{
                this.sessionMembers[counter] =  {id:list.id, name:list.name, email: list.email, default_profile: list.default_profile};
                this.addtoSession[counter] = list.member_id;
                counter++;

        });
        console.log(this.sessionMembers);
        this.modal2 = this.modalService.open(content, { windowClass: 'in grey' });

    }

    public showGroup(content){
        this.download_profile = false;
        var that = this;
        var counter = 0;
        var groupMembers = [];
        this.globalGroup.forEach(list =>{
            groupMembers[counter] = list.member_id;
            counter++;
        });
        var attrib = {kind: 'group_profile', member_ids: groupMembers, organization_id:parseInt(this.params_id)};
        this.http.post(`${environment.profileURL}/api/v1/pdfs/generate.json`,attrib).subscribe(data => {
            console.log(data);
            setTimeout(function() {
                that.app_type = 'Group Profile';
                that.pdf_type = 'profile';
                that.download_profile = true;
            }, 1000);
        },
        err =>{
            console.log(err);
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.error(err.error.message, 'Sorry!');
            }, 1000);
        })
    }

    public showTeam(content){
        this.download_profile = false;
        var that = this;
        var counter = 0;
        var groupMembers = [];
        this.globalGroup.forEach(list =>{
            groupMembers[counter] = list.member_id;
            counter++;
        });
        var attrib = {kind: 'team_profile', member_ids: groupMembers, organization_id:parseInt(this.params_id)};
        this.http.post(`${environment.profileURL}/api/v1/pdfs/generate.json`,attrib).subscribe(data => {
            console.log(data);
            setTimeout(function() {
                that.app_type = 'Team Profile';
                that.pdf_type = 'profile';
                that.download_profile = true;
            }, 1000);
        },
        err =>{
            console.log(err);
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.error(err.error.message, 'Sorry!');
            }, 1000);
        })
    }

    public showThinkingProfile(content) {
        this.download_profile = false;
        var that = this;
        var counter = 0;
        var groupMembers = [];
        this.globalGroup.forEach(list =>{
            groupMembers[counter] = list.member_id;
            counter++;
        });
        var attrib = {kind: 'multiple_profiles', member_ids: groupMembers, organization_id:parseInt(this.params_id)};
        this.http.post(`${environment.profileURL}/api/v1/pdfs/generate.json`,attrib).subscribe(data => {
            console.log(data);
            setTimeout(function() {
                that.app_type = 'Thinking Profile';
                that.pdf_type = 'profile';
                that.download_profile = true;
            }, 1000);
        },
        err =>{
            console.log(err);
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.error(err.error.message, 'Sorry!');
            }, 1000);
        })
    }

    public addAdmin(records){
    	records.forEach(list => {
            if(list.checked){
		    	this.http.post(`${environment.profileURL}/api/v1/organizations/`+this.params_id+'/add_admin.json',{ role_id: 1, identity_id: list.identity_id }).subscribe(result => {
		            console.log(result);
		            this.orgs.teams.forEach(item =>{
              			if(item.name == 'Admin'){
                			item.memberships_count++;

                			//Sync with Admin
                			this.orgs.openTeam(item);
                			var that=this;
                			setTimeout(function(){
                  				that.orgs.openTeam(item);
                			},100);

              			}
            		});
		            setTimeout(function() {
		                this.toastr.options = {
		                    closeButton: true,
                            progressBar: true,
		                    timeOut: 2000
		                };
		                this.toastr.success('New Admin was added successfully!', 'Great!');
		            }, 1000);

		        })
	 		}

		})
    }
    public checkedbox(){
        console.log('callin checkbox function', this.members)
    	this.globalGroup = [];
        this.teamReportService.teamSelectedArr = []
    	this.checkcount = 0;
        console.log('1');
    	var that = this
        jQuery(".checkedbox-"+this.team+":checkbox:checked").each(function() {
            var value = jQuery(this).val();
            	for(var i=0;i<that.members.length;i++){
                    if(that.members[i].id == value){
                        that.checkcount += 1;
                        that.globalGroup.push({
                            id:that.members[i].id,
                            member_id: that.members[i].member_id,
				          	name: that.members[i].first_name + ' ' + that.members[i].last_name,
				          	email: that.members[i].email,
				          	organization: that.members[i].company,
				          	picture_url: that.members[i].picture_url,
				          	default_profile: that.members[i].default_profile
                        });
                            that.teamReportService.teamSelectedArr.push(that.members[i].default_profile)
                	}
                }
                
            })
    }
    public checkAll(){
	    var select = jQuery('#'+this.team+':checkbox:checked').length > 0;
        if(select == true){
            jQuery('.checkedbox-'+this.team).prop('checked',true);
            this.checkedbox();
        }
        else{
            jQuery('.checkedbox-'+this.team).prop('checked',false);
            this.checkedbox();
        }
    }
    public updatePerpage(){
    	this.filter();
    }
    public isPageInRange = function( curPage, index, maxPages, pageBefore, pageAfter ) {
        if (index <= 1) {
	        // first 2 pages
	        return true;
        }
        if (index >= maxPages - 2) {
	        // last 2 pages
	        return true;
        }
        if (index >= curPage - pageBefore && index <= curPage + pageAfter) {
	        return true;
        }
	}
    
	public render = function( curPage, item, first ) {
        var html = '', separatorAdded = false;
        for(var i in item){
            if ( this.isPageInRange( curPage, i, this.pageLen, 2, 2 ) ) {
                html += '<li data-page="' + i + '">' + item[i] + '</li>';
                // as we added a page, we reset the separator Added
                separatorAdded = false;
            } else {
                if (!separatorAdded) {
                    // only add a separator when it wasn't added before
                    html += '<li class="separator" />';
                    separatorAdded = true;
                }
            }
        }
        var holder = document.querySelector('.holder-'+this.team);
        if(this.meta.total_count > this.membersByPage){
            holder.innerHTML = html;
            if(document.querySelector('.holder-'+this.team+'>li[data-page="' + curPage + '"]') != null){document.querySelector('.holder-'+this.team+'>li[data-page="' + curPage + '"]').classList.add('active');}
            if ( first ) {
                holder.addEventListener('click', e => {
                    console.log(e.target['attributes']['data-page'].value);
                    if (!e.target['attributes']['data-page'].value) {
                        // no relevant item clicked (you could however offer expand here )
                        return;
                    }

                    curPage = parseInt(e.target['attributes']['data-page'].value);
                    var page_number = curPage+1;
                    this.isLoading = true;
                    this.http.get(`${this.query_string}&page=${page_number}`).subscribe(data => {
                        this.members = data['memberships'];
                        this.meta = data['meta'];
                        this.pageLen = this.meta.total_pages;
                        this.isLoading = false;
                        this.curPage = 0;
                        this.item = [];
                        for(var i = 1; i<=this.pageLen;i++){
                            this.item.push(i);
                        }

                    },
                    error => console.log('oops', error)
                    )
                    this.render( curPage, item );
                });
            }
        }else{
            holder.innerHTML = null;
        }
    }

    public filter(){
        this.members = [];
    	this.query_string = this.Query_String;
        var first_name = jQuery('.first_name').val();
        var last_name = jQuery('.last_name').val();
        var profiles = jQuery('#profiles').val();
        var role = jQuery('#role').val();
        if(first_name){
            this.query_string += '&first_name=' + first_name;
        }
        if(last_name){
            this.query_string += '&last_name=' + last_name;
        }
        if (profiles) {
            this.query_string += '&profile_type_id=' + profiles;
        }
        if (role) {
            // this.query_string += '&team_id=' + teams;
        }
        this.isLoading = true;
        console.log(this.query_string);
        this.http.get(`${this.query_string}`).subscribe(data => {
            jQuery('#'+this.team).prop('checked',false);
            this.members = data['memberships'];
            this.meta = data['meta'];
            this.pageLen = this.meta.total_pages;
            this.curPage = 0;
            this.item = [];
            console.log(this.members);
            this.isLoading = false;
            for(var i = 1; i<=this.pageLen;i++){
                this.item.push(i);
            }
            this.render( this.curPage, this.item, true );
            console.log(data);
        },
            error => console.log('oops', error)
        )
    }
    public sorting(column,order){
        this.Query_String = environment.profileURL+`/api/v1/teams/`+this.team+`/memberships.json?per_page=`+this.membersByPage;
        if(column == 'firstName'){
            if(order == 'asc'){
                this.Query_String += '&first_name_order=asc';
                this.filter();
                $('.fname-asc').addClass('hide');
                $('.fname-desc').removeClass('hide');
            }
            else{
                this.Query_String += '&first_name_order=desc';
                this.filter();
                $('.fname-asc').removeClass('hide');
                $('.fname-desc').addClass('hide');
            }
        }
        if(column == 'lastName'){
            if(order == 'asc'){
                this.Query_String += '&last_name_order=asc';
                this.filter();
                $('.lname-asc').addClass('hide');
                $('.lname-desc').removeClass('hide');
            }
            else{
                this.Query_String += '&last_name_order=desc';
                this.filter();
                $('.lname-asc').removeClass('hide');
                $('.lname-desc').addClass('hide');
            }
        }
        if(column == 'page'){
            this.filter();
        }
    }
    public length1(){
        this.first_name = jQuery('.first_name').val();
        if(this.first_name.length >= 3 || this.first_name.length == 0){
            this.filter();
        }
    }
    public length2(){
        this.last_name = jQuery('.last_name').val();
        if(this.last_name.length >= 3 || this.last_name.length == 0){
            this.filter();
        }
    }

    public resetFirstName() {
        jQuery('.first_name').val('');
        this.first_name = '';
        this.filter();
    }

    public resetLastName() {
        jQuery('.last_name').val('');
        this.last_name = '';
        this.filter();
    }

    public addTeamMembers(modal, records, team_id){
        this.orgs.teamID = team_id;
        this.orgs.globalGroup = this.globalGroup;
        this.org_individual.teamWizard = true;
        this.orgs.teamWizard = true;
  //       this.loading = false;
  //       this.teamID = '';
  //   	var counter = 0;
  //   	this.listMembers = [];
  //       // console.log(records);

  //   	this.globalGroup.forEach(list =>{
  //   		this.listMembers[counter] = { id: list.id, email: list.email, role_id: 3, name: list.name, default_profile: list.default_profile };
  //   		counter++;

		// });
  //       this.modal1 = this.modalService.open(modal, { windowClass: 'in' });

		// console.log(this.listMembers);

    }

    public addSessionMember(){
        if(this.sessionID > 0){
            console.log(this.addtoSession);
            this.loading = 1;
            this.http.post(`${environment.profileURL}/api/v1/organizations/`+this.params_id+`/training_sessions/`+this.sessionID+`/add_members.json`,{
              member_ids: this.addtoSession
            }).subscribe(result => {
                console.log(result);
                for(var i=0; i< this.orgs.sessions.length; i++){
                    if(this.sessionID == this.orgs.sessions[i].id){
                        this.orgs.sessions[i].open = false;
                    }
                }
                this.loading = 2;
                this.modal2.close(result);
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 2000
                    };
                    this.toastr.success('Members were added successfully!', 'Great!');
                }, 1000);
            },response => {
                  console.log(response.data);
            })
        }
    }

    public delete(index,id){
        this.globalGroup.splice(index,1);
        this.listMembers.splice(index,1);
        jQuery("input.checkedbox-"+this.team+"[value="+ id +"]").prop('checked',false);
        this.checkcount -= 1;

    }

    public Delete(index,id){
        this.globalGroup.splice(index,1);
        this.sessionMembers.splice(index,1);
        this.addtoSession.splice(index,1);
        jQuery("input.checkedbox-"+this.team+"[value="+ id +"]").prop('checked',false);
        this.checkcount -= 1;
    }

    public teamReport(content){
        this.download_profile = false;
        var that = this;
        var attrib = {kind: 'team_report', team_id:parseInt(this.team), organization_id:parseInt(this.params_id)};
        this.http.post(`${environment.profileURL}/api/v1/pdfs/generate.json`,attrib).subscribe(data => {
            console.log(data);
            setTimeout(function() {
                that.app_type = 'Team Report';
                that.pdf_type = 'report';
                that.download_profile = true;
            }, 1000);
        },
        err =>{
            console.log(err);
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.error(err.error.message, 'Sorry!');
            }, 1000);
        })
    }

    public accDownload() {
        this.modal3.close();
        this.router.navigate(['/account-downloads']);
    }

    public addTeamMember() {
        if(this.teamID > 0){
            this.loading = 1;
            var counter = 0;
            this.listMembers={};
            console.log(this.globalGroup);
            this.globalGroup.forEach(list =>{
                this.listMembers[counter] = { 'email': list.email, 'role_id': 3 };
                counter++;
                list.checked = false;

            });

            this.http.post(`${environment.profileURL}/api/v1/teams/`+this.teamID+'/add_members.json',{members_attributes:this.listMembers}).subscribe(result => {
                console.log(result);
                for(var i=0; i< this.orgs.teams.length; i++){
                    if(this.teamID == this.orgs.teams[i].id){
                        this.orgs.teams[i].open = false;
                    }
                }
                var that = this;
                this.loading = 2;
                this.modal1.close(result);
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 2000
                    };
                    this.toastr.success('Members were added successfully!', 'Great!');
                }, 1000);

            })
        }
    }

    public chooseOption(){
        if(this.checkcount > 0){
            this.getOptions = true;
            setTimeout(function() {
                jQuery(document).ready(function(e){
                    jQuery('input[name="member"]').iCheck({
                        checkboxClass: 'icheckbox_square-green',
                        radioClass: 'iradio_square-green',
                    });
                });
            },0);
        }
    }

    public cancelMemmbers(){
        this.getOptions = false;
    }

    public openWizard(){ 
    setTimeout(function() {
        jQuery(document).ready(function(e){
            jQuery('input[name="download-pdf"]').iCheck({
                checkboxClass: 'icheckbox_square-green',
                radioClass: 'iradio_square-green',
            });
            jQuery('input[name="add-member"]').iCheck({
                checkboxClass: 'icheckbox_square-green',
                radioClass: 'iradio_square-green',
            });
        });
    },0);       
        if(jQuery("input[value='add-members").prop("checked") == true){
            this.addMember = true;
            this.getOptions = false; 
        }
        if(jQuery("input[value='download-options").prop("checked") == true){
            this.Downloadpdf = true;
            this.getOptions = false; 
            this.download_profile = false;
        }
    }

    public canceldownloadPdf(){
        this.Downloadpdf = false;
        this.getOptions = true;
        setTimeout(function() {
            jQuery(document).ready(function(e){
                jQuery('input[name="member"]').iCheck({
                    checkboxClass: 'icheckbox_square-green',
                    radioClass: 'iradio_square-green',
                });
            });
        },0);
    }

    public downloadPdf(content){
        if(jQuery("input[value='group-profile").prop("checked") == true){
            this.showGroup(content);
        }
        if(jQuery("input[value='thinking-profile").prop("checked") == true){
            this.showThinkingProfile(content);
        }
        if(jQuery("input[value='team-profile").prop("checked") == true){
            this.showTeam(content);
        }
        if(jQuery("input[value='team-report").prop("checked") == true){
            this.teamReport(content);
        }
    }

    public cancelAddmemmbers(){
        this.addMember = false;
        this.getOptions = true;
        setTimeout(function() {
            jQuery(document).ready(function(e){
                jQuery('input[name="member"]').iCheck({
                    checkboxClass: 'icheckbox_square-green',
                    radioClass: 'iradio_square-green',
                });
            });
        },0);
    }

    public openAddmembers(){
        if(jQuery("input[value='orgs").prop("checked") == true){
            this.newteamWizard = true;
        }
        if(jQuery("input[value='teams").prop("checked") == true){
            this.adminteamWizard = true;
        }
        if(jQuery("input[value='sessions").prop("checked") == true){
            this.sessionWizard = true;
        }
    }

    public manageMembers() {
        if($(".open-toggle.dropdown").hasClass('open')) {
            $(".open-toggle.dropdown").removeClass("open");
        } else {
            $(".open-toggle.dropdown").addClass("open");
        }
    }

}
