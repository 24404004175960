import { Component, OnInit, Input } from '@angular/core';
// import { Auth } from '../../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import {CommonDataService} from './../common-data.service';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-fs-graph',
	templateUrl: './fs-graph.component.html',
	styleUrls: ['./fs-graph.component.scss']
})
export class FsGraphComponent implements OnInit {
	@Input() profiles:any;
	public neutral: number;
	public height:number;
	@Input() small;
	@Input() activated;
	@Input() bottom;
	@Input() low_preference: any;
	@Input() high_preference: any;
	@Input() contrast: any;
	@Input() clarify:any;
	@Input() ideate:any;
	@Input() develop:any;
	@Input() implement:any;
  	constructor( public http: HttpClient,public service:CommonDataService ) { }
  	
	ngOnInit() {
		console.warn(this.profiles)
    	// Connectors
    	jQuery(window).on('load resize orientationchange', function ($) {


	    	function connectorLine(from, to, line){
				var fT = from.offsetTop  + from.offsetHeight/2;
				var tT = to.offsetTop    + to.offsetHeight/2;
				var fL = from.offsetLeft + from.offsetWidth/2;
				var tL = to.offsetLeft   + to.offsetWidth/2;

				var CA   = Math.abs(tT - fT);
				var CO   = Math.abs(tL - fL);
				var H    = Math.sqrt(CA*CA + CO*CO);
				var ANG  = 180 / Math.PI * Math.acos( CA/H );


				if(tT > fT){
				var top  = (tT-fT)/2 + fT;
				}else{
				var top  = (fT-tT)/2 + tT;
				}
				if(tL > fL){
				var left = (tL-fL)/2 + fL;
				}else{
				var left = (fL-tL)/2 + tL;
				}

				let checkValue = ()=> {
					return (( fT < tT && fL < tL) || ( tT < fT && tL < fL) || (fT > tT && fL > tL) || (tT > fT && tL > fL))
				}

				if(checkValue()){
					ANG *= -1;
				}
				top-= H/2;

				line.style.webkitTransform = 'rotate('+ ANG +'deg)';
				line.style.msTransform = 'rotate('+ ANG +'deg)';
				line.style.mozTransform = 'rotate('+ ANG +'deg)';
				line.style["transform"] = 'rotate('+ ANG +'deg)';
				line.style.top    = top+'px';
				line.style.left   = left+'px';
				line.style.height = H + 'px';
    		}

	    	setInterval(function() {

	      		jQuery('.chart-section')?.each(function(index, el) {
	        		connectorLine(
	          			jQuery(this).find('.connector_clarify_pointer').get(0),
	          			jQuery(this).find('.connector_ideate_pointer').get(0),
	          			jQuery(this).find('.connector_line_1 ').get(0)
	        		);

	        		connectorLine(
	          			jQuery(this).find('.connector_develop_pointer').get(0),
	          			jQuery(this).find('.connector_implement_pointer').get(0),
	          			jQuery(this).find('.connector_line_2').get(0)
	        		);

	        		connectorLine(
	          			jQuery(this).find('.mid_connector_1').get(0),
	          			jQuery(this).find('.mid_connector_2').get(0),
	          			jQuery(this).find('.connector_line_3').get(0)
	        		);

	      		});

	    	}, 0);


	  	});

	  	jQuery(window).trigger('load');

	  	(function() {
	    	function connectorLine(from, to, line){
				var fT = from.offsetTop  + from.offsetHeight/2;
				var tT = to.offsetTop    + to.offsetHeight/2;
				var fL = from.offsetLeft + from.offsetWidth/2;
				var tL = to.offsetLeft   + to.offsetWidth/2;

				var CA   = Math.abs(tT - fT);
				var CO   = Math.abs(tL - fL);
				var H    = Math.sqrt(CA*CA + CO*CO);
				var ANG  = 180 / Math.PI * Math.acos( CA/H );


				if(tT > fT){
				var top  = (tT-fT)/2 + fT;
				}else{
				var top  = (fT-tT)/2 + tT;
				}
				if(tL > fL){
				var left = (tL-fL)/2 + fL;
				}else{
				var left = (fL-tL)/2 + tL;
				}

				let checkValue = ()=> {
					return (( fT < tT && fL < tL) || ( tT < fT && tL < fL) || (fT > tT && fL > tL) || (tT > fT && tL > fL));
				} 

				if(checkValue()){
					ANG *= -1;
				}
				top-= H/2;

				line.style.webkitTransform = 'rotate('+ ANG +'deg)';
				line.style.msTransform = 'rotate('+ ANG +'deg)';
				line.style.mozTransform = 'rotate('+ ANG +'deg)';
				line.style["transform"] = 'rotate('+ ANG +'deg)';
				line.style.top    = top+'px';
				line.style.left   = left+'px';
				line.style.height = H + 'px';
	    	}

	    	let beforePrint = function() {
	      		jQuery('.chart-section').each(function(index, el) {
	        		connectorLine(
						jQuery(this).find('.connector_clarify_pointer').get(0),
						jQuery(this).find('.connector_ideate_pointer').get(0),
						jQuery(this).find('.connector_line_1 ').get(0)
	        		);

	        		connectorLine(
	          			jQuery(this).find('.connector_develop_pointer').get(0),
          				jQuery(this).find('.connector_implement_pointer').get(0),
	          			jQuery(this).find('.connector_line_2').get(0)
        			);

	        		connectorLine(
          				jQuery(this).find('.mid_connector_1').get(0),
	          			jQuery(this).find('.mid_connector_2').get(0),
	          			jQuery(this).find('.connector_line_3').get(0)
	        		);

	      		});
	    	};

	    	if (window.matchMedia) {
	      		var mediaQueryList = window.matchMedia('print');
	      		mediaQueryList.addListener(function(mql) {
		        	if (mql.matches) {
		          		beforePrint();
		        	} else {
		          	//afterPrint();
		        	}
	      		});
	    	}

		    window.onbeforeprint = beforePrint;

		}());

	}

}