import { Component, OnInit } from '@angular/core';
// import { Auth } from '../../auth/auth.service';
import { Location } from '@angular/common'
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {CommonDataService} from './../common-data.service';
import { environment } from './../../../environments/environment';
import { throwError } from "rxjs";
import {AccessGroupService} from './../access-group.service';
import { Store } from '@ngrx/store';


declare var jQuery:any;

@Component({
  selector: "app-collaboration-profile",
  templateUrl: "./collaboration-profile.component.html",
  styleUrls: ["./collaboration-profile.component.scss"],
})
export class CollaborationProfileComponent implements OnInit {
  public profile: any;
  public editState = 0;
  public fieldEdit = 0;
  public oldValue: any;
  public picture_url: string;
  public collaboration_profile;
  first_name: string = "";
  current_profile;
  profile_title: string = "";
  env;
  collab_desc: string;
  collab_ind_des: string;
  collab_pf;
  email_validation: boolean = true;
  phone_validation: boolean = true;
  storeNgrx:any;
  ngrxAccessGroup:any;
  previousValue;

  constructor(
    public accessgroup: AccessGroupService,
    private location: Location,
    public http: HttpClient,
    public service: CommonDataService,
    public router: Router,
    public store: Store<any>
  ) {}

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  ngOnDestroy() {
    localStorage.setItem("collaboration-pf", "false");
  }

  async ngOnInit() {
    this.storeNgrx = this.store.select<any>((state) => state.profile?.account.default_profile);
    this.ngrxAccessGroup  = this.store.select<any>((state) => state.profile.accessGroupData);
    this.env = environment;
    localStorage.setItem("collaboration-pf", "true");
    // it re-directs url to the dashboard page if access to this page is not permitted.
    this.ngrxAccessGroup.subscribe(
      (message) => {
        console.log(message);
        if (message) {
          this.collaboration_profile = message["main_menu"]?.collaboration_profile;
          if (message["main_menu"]?.collaboration_profile != true && message.length > 0) {
            this.router.navigate(["/dashboard"]);
            return false;
          }
        }
      },
      (err) => {
        // Log errors if any
        console.log(err);
      }
    );

    // this.collaboration_profile=this.accessgroup.accessdata().main_menu.profile.collaboration_profile;
    this.getCollaborationData();

    // fetch profile data for login app
    await this.storeNgrx.subscribe((message) => {
      console.log(message)
      // for (var i = 0; i < message.length; i++) {
        this.current_profile = message;
      // }
      this.first_name = this.current_profile?.first_name;
      this.profile_title = this.current_profile?.profile_title;
      this.http
        .get(`${environment.profileURL}/api/v1/profile_types.json`, {
          params: { profile: "custom", lang: "en" },
        })
        .subscribe((res) => {
          console.warn(res);
          console.log(this.profile_title);
          res["profile_types"].forEach((element) => {
            console.log(element["type"]);
            if (this.profile_title == element["type"]) {
              this.collab_ind_des = element["individual_description"];
              this.collab_desc = element["collaboration_description"];
              console.log(this.collab_desc);
            }
          });
        });
    });
  }

  public cancelSave = function () {
    this.cancelField(this.fieldEdit)
    this.editState = 0;
    this.fieldEdit = 0;
  };

  public editUser(index) {
    this.editState = index;
    this.store
      .select<any>((state) => state.profile?.account.default_profile)
      .subscribe(
        (message) => {
          if (message) {
            this.oldValue = message;
          }
        },
        (err) => {
          // Log errors if any
          console.log(err);
        }
      );
  }

  public getProfile;

  private handleError(error: Response) {
    return throwError(error.statusText);
  }

  public editField(index, previousValue = '') {
    this.fieldEdit = index;
    this.previousValue = previousValue
  }

  public removeImage() {
    this.profile.identity.picture_url = localStorage.getItem("picture_url");
    this.saveUser();
  }

  public cancelField(index) {
    switch (index) {
      case 1:
        let name = this.previousValue.split("/")
        this.collab_pf.first_name = name[0];
        this.collab_pf.last_name = name[1];
        break;
      case 2:
        this.collab_pf.skype = this.previousValue;
        break;
      case 3:
        this.collab_pf.phone = this.previousValue;
        break;
      case 4:
        this.collab_pf.timezone = this.previousValue;
        break;
      case 5:
        this.collab_pf.about = this.previousValue;
        break;
      case 6:
        this.collab_pf.works_for_me = this.previousValue;
        break;
      case 7:
        // this.profile.identity.collaboration_not_works = this.oldValue?.identity?.collaboration_not_works;
        this.collab_pf.does_not_works_for_me = this.previousValue
        break;
      case 9:
        this.collab_pf.organization = this.previousValue;
        break;
      case 11:
        this.collab_pf.website = this.previousValue;
        break;
      case 12:
        this.collab_pf.email = this.previousValue;
        break;
      default:
    }
    this.fieldEdit = 0;
  }
  public saveUser() {
    var that = this;
    if (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        this.collab_pf?.email
      ) &&
      /^[+]\d{1}?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
        this.collab_pf?.phone
      )
    ) {
      this.http
        .post(
          `${environment.profileURL}/api/v1/users/update_collaboration_identity.json`,
          {
            first_name: this.collab_pf?.first_name,
            last_name: this.collab_pf?.last_name,
            email: this.collab_pf.email,
            phone: this.collab_pf?.phone,
            website: this.collab_pf?.website,
            organization: this.collab_pf?.organization,
            works_for_me: this.collab_pf?.works_for_me,
            does_not_works_for_me: this.collab_pf?.does_not_works_for_me,
          }
        )
        .subscribe((result) => {
          console.log(result);
          this.fieldEdit = 0;
          setTimeout(function () {
            this.toastr.options = {
              closeButton: true,
              progressBar: true,
              timeOut: 2000,
            };
            this.toastr.success("Profile updated successfully!", "Nice!");
          }, 1000);
        });
    } else {
      console.log("else blk");
      this.validatePhone();
      this.validateEmail();
    }
  }
  public printprofile() {
    $(".navbar-default").hide();
    $(".user-profile-wrapper").addClass("print-body");
    window.print();
    $(".navbar-default").show();
    $(".user-profile-wrapper").removeClass("print-body");
  }

  public Profile() {
    $(".navbar-default").hide();
    $("body").addClass("profile-print-body");
    // we change title because file name showing based on title
    document.title =
      "FourSight-collaboration-profile-" +
      localStorage.getItem("first_name") +
      "-" +
      localStorage.getItem("last_name");
    window.print();
    setTimeout(() => {
      document.title = "App | FourSight";
    }, 100);
    $(".navbar-default").show();
    $("body").removeClass("profile-print-body");
  }

  // get branding data
  public getCollaborationData() {
    this.http
      .get(`${environment.profileURL}/api/v1/users/collaboration_identity.json`)
      .subscribe((res) => {
        console.log("collaboration pf", res);
        this.collab_pf = res['branding_identity'];
        let fromSetting = localStorage.getItem("settingToCollab");
        if (fromSetting == "true") {
          this.editUser(1);
          this.editField(12);
          setTimeout(function () {
            $("html,body").animate(
              {
                scrollTop: $(".collaboration-email").offset().top,
              },
              "slow"
            );
          }, 50);
          localStorage.removeItem("settingToCollab");
        }
      });
  }

  public validateEmail() {
    if (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        this.collab_pf?.email
      )
    ) {
      this.email_validation = true;
    } else {
      this.email_validation = false;
    }
  }

  public validatePhone() {
    if (
      /^[+]\d{1}?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
        this.collab_pf?.phone
      )
    ) {
      this.phone_validation = true;
    } else {
      this.phone_validation = false;
    }
  }
}