import { Component, OnInit, Input } from '@angular/core';
import { TeamReportServiceService } from './../team-report-service.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-team-ideator',
  templateUrl: './team-ideator.component.html',
  styleUrls: ['./team-ideator.component.scss']
})
export class TeamIdeatorComponent implements OnInit {
	ideatorsTeam:any;
	item:any;
	clarifierTeam:any;
	@Input() members:any;
	record:any;
	env;any;

  	constructor(public service: TeamReportServiceService) { }

  	ngOnInit() {
  		this.env = environment;
  		this.ideatorsTeam = this.service.ideatorsTeam;
  		this.clarifierTeam = this.service.clarifierTeam;
  	}

}
