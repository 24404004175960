import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LambdaFunctionService {

  lambda_res = new BehaviorSubject<any>([]);
  lambda_pdf:any = new BehaviorSubject<any>([]);
  // lambda_pdf_book = new Subject;
  pdf_popup = new BehaviorSubject<boolean>(false);
  pdfName = new Subject;

  constructor(private http:HttpClient) { }

  // Lambda function call (fn_name,args stands for function name and arguments respectively)
  public async lambda_invoke(fn_name,args,name='') {
    if(fn_name != "multi-profile-pdf-dev-pdf") {
      this.pdf_popup.next(true);
      this.pdfName.next(name);
    }
    console.warn('lmbda-func',args);
    var that = this;
    await this.http.post(`${environment.profileURL}/api/v1/lambda_functions/invoke.json`,{name:fn_name,options:args}).subscribe((result)=> {
      if(fn_name == 'TestDynamoDBReadLM-dev-readDataFromDynamoDB'){
        that.lambda_res.next(result);
      }else{
        this.lambda_res.next([]);
        if(fn_name != 'pdf-filler-stage-pdftk'){
          if(fn_name == 'multi-profile-pdf-dev-pdf') {
            that.lambda_res.next(result['response'].body);
            if(result['response'].body) {
              that.lambda_res.next(result['response'].body);
            }
          } else {
            this.lambda_pdf.next(result['response'].body);
          }
       } else {
          // that.lambda_pdf_book.next(result);
          that.lambda_pdf.next(result['response'].Location);
       }
      }
    }, err => {
      console.log(err);
      console.log(err?.error?.response?.errorMessage)
      if(err?.error?.response?.errorMessage.includes('Task timed out after')) {
        console.log('hitting again because of timeout')
        this.lambda_invoke(fn_name,args);
      } else {
        this.pdf_popup.next(false)
        setTimeout(function() {
          this.toastr.options = {
              closeButton: true,
              progressBar: true,
              timeOut: 2000
          };
          this.toastr.error("Something went wrong!", "Sorry!");
        }, 1000);
      }
    });
    
    if(fn_name == 'TestDynamoDBReadLM-dev-readDataFromDynamoDB'){
      return that.lambda_res;
    }
  }

}