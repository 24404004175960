import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {AccessGroupService} from './../access-group.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss']
})
export class ToolsComponent implements OnInit {

  menu_links;
  ngrxAccessGroup:any;
  env:any;

  constructor(public accessgroup:AccessGroupService, private router: Router,public store:Store<any>) { }

	ngOnInit() {
    this.ngrxAccessGroup = this.store.select<any>((state) => state.profile.accessGroupData);
    this.env = environment;
		// it re-directs url to the dashboard page if access to this page is not permitted.
	  	this.ngrxAccessGroup.subscribe(message => { 
            if(message && message.length > 0){                
                if(!message['main_menu']?.tools){
                    this.router.navigate(['/dashboard']);
                    return false;
                }
            }

        },
        err => {
            // Log errors if any
            console.log(err);
        })

        this.ngrxAccessGroup.subscribe(message => { 
          if(message){           
            this.menu_links = message;
          }
        },
        err => {
            // Log errors if any
            console.log(err);
        })
  	}

}
