import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ManageMembersComponent} from '../manage-members/manage-members.component';

@Component({
  selector: 'app-manage-team-members',
  templateUrl: './manage-team-members.component.html',
  styleUrls: ['./manage-team-members.component.scss']
})

export class ManageTeamMembersComponent implements OnInit {
	@Input() id;
	teams:any;
	meta:any;
	modal:any;
	loading:number;
	teamName:string;
	listMembers:any;
	membersByPage:number;
	isLoading:boolean;
	pageLen:number;
	curPage:number;
	item:any;
	query_string:string;
    show:number;
    name:any;

  constructor(public http: HttpClient, public modalService:NgbModal, public global:ManageMembersComponent) { }

    ngOnInit() {
      	console.log(this.id);
      	this.membersByPage = 20;
      	this.isLoading = true;
      	this.query_string = environment.profileURL+`/api/v1/organizations/`+this.id+`/teams.json?`;
      	this.http.get(`${environment.profileURL}/api/v1/organizations/`+this.id+`/teams.json`).subscribe(data => {
           	this.teams = data['teams'];
           	this.meta = data['meta'];
           	this.isLoading = false;
           	this.pageLen = this.meta.total_pages;
           	this.curPage = 0;
           	this.item = [];
           	for(var i = 1; i<=this.pageLen;i++){
			    this.item.push(i);
			}
			if(this.meta.total_count > this.membersByPage){
				this.render( this.curPage, this.item, true );
	            console.log(data);
	        }
        },
            error => console.log('oops', error)
        )
  	}

  	public isPageInRange = function( curPage, index, maxPages, pageBefore, pageAfter ) {
        if (index <= 1) {
	        // first 2 pages
	        return true;
        }
        if (index >= maxPages - 2) {
	        // last 2 pages
	        return true;
        }
        if (index >= curPage - pageBefore && index <= curPage + pageAfter) {
	        return true;
        }
	}
	public render = function( curPage, item, first ) {
        var html = '', separatorAdded = false;
        for(var i in item){
            if ( this.isPageInRange( curPage, i, this.pageLen, 2, 2 ) ) {
                html += '<li data-page="' + i + '">' + item[i] + '</li>';
                // as we added a page, we reset the separator Added
                separatorAdded = false;
            } else {
                if (!separatorAdded) {
                    // only add a separator when it wasn't added before
                    html += '<li class="separator" />';
                    separatorAdded = true;
                }
            }
        }
        var holder = document.querySelector('#holder'); 
        if(this.meta.total_count > this.membersByPage){ 
            holder.innerHTML = html;
            if(document.querySelector('#holder>li[data-page="' + curPage + '"]') != null){document.querySelector('#holder>li[data-page="' + curPage + '"]').classList.add('active');}
            if ( first ) {
                holder.addEventListener('click', e => {
                    console.log(e.target['attributes']['data-page'].value);
                    if (!e.target['attributes']['data-page'].value) {
                        // no relevant item clicked (you could however offer expand here )
                        return;
                    }

                    curPage = parseInt(e.target['attributes']['data-page'].value);
                    var page_number = curPage+1;
                    this.isLoading = true;
                    this.http.get(`${this.query_string}&page=${page_number}`).subscribe(data => {
                    	console.log(data);
                        this.teams = data['teams'];
                        this.meta = data['meta'];
                        this.pageLen = this.meta.total_pages;
                        this.isLoading = false;
                        this.curPage = 0;
                        this.item = [];
                        for(var i = 1; i<=this.pageLen;i++){
                            this.item.push(i);
                        }

                    },
                    error => console.log('oops', error)
                    )
                    this.render( curPage, item );
                });
            }
        }else{
            holder.innerHTML = null;
        }
    }

    public filter(){
        this.teams = [];
    	this.query_string = environment.profileURL+`/api/v1/organizations/`+this.id+`/teams.json?`;
        this.name = jQuery('#name').val();
        if(this.name.length == 0 || this.name.length >=3 ){
            if(this.name){
                this.query_string += '&name=' + this.name;
            }        
            this.isLoading = true;
            console.log(this.query_string);
            this.http.get(`${this.query_string}`).subscribe(data => {
                this.teams = data['teams'];
                this.meta = data['meta'];
                this.pageLen = this.meta.total_pages;
                this.curPage = 0;
                this.item = [];
                this.isLoading = false;
                for(var i = 1; i<=this.pageLen;i++){
                    this.item.push(i);
                }
                this.render( this.curPage, this.item, true );
                console.log(data);
            },
                error => console.log('oops', error)
            )
        }
    }

    public resetTeamName() {
        jQuery('#name').val('');
        this.filter();
    }

  	public open(){
        // this.modal = this.modalService.open(content, { windowClass: 'in grey' });
        $('.team-modal').css('display','block');
    }


    public close() {
        $('.orgs-modal').css('display', 'none');
    }


    public addTeam(){
        if(this.teamName){
        	this.loading = 1;
        	this.http.post(`${environment.profileURL}/api/v1/organizations/`+this.id+'/teams.json',{ name: this.teamName, organization_id: this.id }).subscribe(result => {
    	        console.log(result);
    	        this.teams.push(result);
    	        this.loading = 2;
    	        // this.modal.close();
                this.close();
                $('.team-modal').css('display','none');
    	    },
            error => console.log('oops', error)
            )
        }
    }

    public gaddTeamMembers(id){
        this.show = id;
    	var counter = 0;
    	this.listMembers = {};
    	console.log(this.global.group);
    	this.global?.group?.forEach(list =>{
			this.listMembers[counter] = { 'email': list.email, 'role_id': 3 };
			counter++;
            list.checked = false;
    		  			
		});

    	this.http.post(`${environment.profileURL}/api/v1/teams/`+id+'/add_members.json',{members_attributes:this.listMembers}).subscribe(result => {
	        console.log(result);
            this.show = id * 10;
            var that = this;
	        setTimeout(function() {
                that.tempId(id);
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.success('The Team was updated successfully!', 'Nice!');
            }, 1000);

	    })
    }

    public tempId(id){
        if (this.show == id * 10){
            this.show = 0;
        }
    }

}
