import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { CustomPfService } from '../custom-pf.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment'; 

@Component({
  selector: 'app-guide-flipbook',
  templateUrl: './guide-flipbook.component.html',
  styleUrls: ['./guide-flipbook.component.scss']
})
export class GuideFlipbookComponent implements OnInit {

  main_app:boolean = false;

  constructor(public activatedRoute:ActivatedRoute,public http:HttpClient,private modalService: NgbModal, public customPfService: CustomPfService,public router: Router) { }

  ngOnInit(): void {

    if(this.router?.url.includes('interpretative-guide')) {
      this.main_app = true;
    }

  }

  public getGuides() {
      $('.guide-modal').css('display', 'block');
  }

}
