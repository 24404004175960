import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapTypeService {
  mindset = [
    {
      title: "Welcome",
      order: 1,
      open: "2018-04-16T00:00Z",
      close: "2019-04-16T00:00Z",
      cards: [
        {
          type: "video",
          medium: "Video",
          title: "Welcome",
          order: 1,
          lesson: "random",
          runtime: "1:19 mins",
          link: "qlO0fYzC2Sc",
          body: "Welcome to your learner map. Let us lead you on a journey toward better collaboration.",
          parent: "Start here",
          button: "Play Video",
          target: "_self",
          open: "2018-04-15T18:30:00.000Z",
          close: "2018-04-15T18:30:00.000Z"
        },
      ]
    },
  ]

  constructor() { }
}
