import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { environment } from './../../../../environments/environment';

@Component({
    selector: 'app-cig-what-you-should-know',
    templateUrl: './cig-what-you-should-know.component.html',
    styleUrls: ['./cig-what-you-should-know.component.scss']
})
export class CigWhatYouShouldKnowComponent implements OnInit {

    @Input() profile:Array<Object>;
    @Input() slides;
    @Input() author;
    public env;
    public profiles : any;

    constructor() { }

    ngOnInit() {
        this.env=environment;
        this.profiles = this.profile;
    }

    public ngOnChanges( changes: SimpleChanges ) : void {

        if ( changes.profile && ! changes.profile.isFirstChange() ) {
        	this.profiles = changes.profile.currentValue;

        }

    }

}
