import { Component, OnInit, Input } from '@angular/core';
import { ManageProfilesComponent } from './../manage-profiles/manage-profiles.component';
import { TrainingSessionsComponent } from './../orgs-individual/training-sessions/training-sessions.component';
import { OrgIndividualTeamsComponent } from '../org-individual-teams/org-individual-teams.component';
import { OrgIndividualMembersComponent } from '../org-individual-members/org-individual-members.component';
import { OrgsIndividualComponent } from '../orgs-individual/orgs-individual.component';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Auth } from '../../auth/auth.service';
import { LambdaFunctionService } from '../../service/lambda-function.service';
import { CommonDataService } from '../common-data.service';
import { TeamReportServiceService } from '../team-report/team-report-service.service';
// Import the AuthService type from the SDK
// import { AuthService } from '@auth0/auth0-angular';

declare var jQuery:any;

@Component({
  selector: 'app-manage-wizards',
  templateUrl: './manage-wizards.component.html',
  styleUrls: ['./manage-wizards.component.scss']
})
export class ManageWizardsComponent implements OnInit {
    public oldValue;
    public profiles;
    public profileId:number;
    public member_id:string;
    getProfile:boolean = false;
    brand_identity:any;
	organizations:any;
	pageSession1:boolean;
	pageSession2:boolean;
	orgName:string;
	add_orgsSession:boolean;
	addOrg:boolean;
	sessionName:string;
	kind:string;
	loadingSession:any;
	loading:any;
	sessionID:any;
	session_sessionName:any;
	sessions:any;
	add_Session:boolean;
	session_orgName:any;
	OrgsTeams:any;
	unassignedId:number;
	addtoSession:any;
	@Input() globalGroup;
	@Input() mode;
	orgs_memberCount:number;
	orgid:any;
	teamID:any;
	pageTeam1:boolean;
	pageTeam2:boolean;
	add_orgsTeam:boolean;
	add_Team:boolean;
	team_orgName:any;
	team_teamName:any;
	loadingTeam:boolean;
	teamName:string;
	orgWizard:boolean;
	add_orgs:boolean;
	teamWizard:boolean;
	sessionWizard:boolean;
	addteam:boolean;
	addsession:boolean;
	addgetSession:boolean;
	orgToastr:boolean;
	teamToastr:boolean;
	sessionToastr:boolean;
	orgDone:boolean;
	orgteamDone:boolean;
	teamDone:boolean;
	open_acc:boolean;
	orgsessionDone:boolean;
	sessionDone:boolean;
	groupToastr:boolean;
	groupDone:boolean;
	groups:any;
	orgs:any;
	groupWizard:boolean;
	orgId:number;
	groupid:any;
	sessionMembers:any;
	groupName:string;
	getOptions:boolean;
	addMember:boolean;
	Downloadpdf:boolean;
	download_profile:boolean;
	app_type:string;
	pdf_type:string;
	teams:any;
	env:any;
	pageTitle:string;
    Email:any;
	@Input() team_id:any;
    tempOrg:number;
    tempOrgName:string;
    tempSession:number;
    tempSessionname:string;
    tempTeam:number;
    tempTeamName:string;
    showGroupProfile:boolean;
    previousPage:string;
    date_of_profile: any;
    organization_title: any;
    thinking_profile_heading: any;
    thinking_profile_message: any;
    download:any;
    share_my_profile:any;
    explain_my_profile:any;
    low_preference: any;
    high_preference: any;
    contrast: any;
    clarify:any;
    ideate:any;
    develop:any;
    implement:any;
    prefers_to:any;
    get_report: any;
    sign_up_with:any;
    pf_description:string = '';
    pf_eqn:string = '';
    pf_title = '';
    pf_email_hd = 'Profile Email:';
    args:any;
    arguements:any;
    profile_type:string;

  	constructor( public router: Router, public http: HttpClient, public manage: ManageProfilesComponent, public manage_session: TrainingSessionsComponent, public org_individual: OrgIndividualTeamsComponent, public orgs_main: OrgsIndividualComponent, public org_members: OrgIndividualMembersComponent,public auth: Auth, public lmda_service: LambdaFunctionService,public service:CommonDataService, public teamReportService: TeamReportServiceService) { }	

  	ngOnInit() {
  		this.env = environment;
  		this.addMember = false;
  		this.getOptions = true;
  		this.Downloadpdf = false;
  		this.groupToastr = false;
  		this.groupDone = false;
  		this.groups = this.manage_session.groups;
  		this.orgs = this.manage_session.orgs;
  		this.groupWizard = this.manage_session.groupWizard;
  		this.orgId = this.orgs.id;  		
  		this.addGroup();
  		this.open_acc = false;
  		this.organizations = this.manage.organizations;
  		this.sessionID = '';
        this.pageSession1 = false;
        this.pageSession2 = false;
        this.add_orgsSession = false;
        this.add_Session = this.manage.add_Session;
        this.addtoSession = [];        
        this.sessions = this.manage.sessions;
        this.loading = false;
        this.orgid = this.manage.orgid;
  		this.teamID = '';
        this.pageTeam1 = false;
        this.pageTeam2 = false;
        this.add_orgsTeam = false;
        this.add_Team = false;
        this.OrgsTeams = this.manage.OrgsTeams;
        this.orgWizard = this.manage.orgWizard;
        this.teamWizard = this.manage.teamWizard;
        this.sessionWizard = this.manage.sessionWizard;
        this.addOrg = true;
        this.orgDone = false;
        this.pageTitle = 'Manage Members Wizard';
        this.showGroupProfile = false;
        this.previousPage = 'Manage Members';
        if(this.manage.orgid){
        	this.getOrgTeams();
        	this.getOrgSessions();
        }
        if(this.mode == 'Orgmembers'){
            this.previousPage = this.orgs_main.org_name;
            this.http.get(`${environment.profileURL}/api/v1/organizations.json?per_page=100&name_order=asc`).subscribe(data => {
                console.log(data);
                this.organizations = data['organizations'];
            },
            error => console.log('oops', error)
            )
        }
        if(this.mode == 'session'){
            this.previousPage = 'Manage Session';
            this.getOptions = false;
            this.Downloadpdf = true;
        	this.pageTitle = 'Download PDF';
	  		this.sessionToastr = false;
	  		this.sessionDone = false;
	  		this.orgs = this.manage_session.orgs;
	  		this.sessionWizard = this.manage_session.sessionWizard;
	  		this.orgId = this.orgs.id;  		
	  		this.addGroup();
            setTimeout(function() {
            jQuery(document).ready(function(e){
                jQuery('input[name="download-pdf"]').iCheck({
                    checkboxClass: 'icheckbox_square-green',
                    radioClass: 'iradio_square-green',
                });

                jQuery('input[name="download-pdf"]').on('ifChecked', function(event){
                    jQuery('div.inner-single-box').removeClass('border-green');
                    jQuery(this).closest('div.inner-single-box').addClass('border-green');
                });
            });
        },0);
        }
        if(this.mode == 'team'){
            this.previousPage = 'Manage Team';
        	this.pageTitle = 'Team Members Wizard';
        	this.orgId = this.org_individual.id;
            this.http.get(`${environment.profileURL}/api/v1/organizations/`+this.orgId+`/training_sessions.json`).subscribe(data => {
                this.sessions = data['training_sessions']; 
                console.log(data);
            },
            error => console.log('oops', error)
            )
	  		this.teams = this.org_individual.teams;
	  		this.addGroup();
        }
        this.auth.getProfile((err, profile) => {
            this.Email = profile.email;
        })
        // this.auth.getUser().subscribe(user => {
        //     this.Email = user.email;
        // });
        setTimeout(function() {
            jQuery(document).ready(function(e){
                jQuery('input[name="member"]').iCheck({
                    checkboxClass: 'icheckbox_square-green',
                    radioClass: 'iradio_square-green',
                });

                jQuery('input[name="member"]').on('ifChecked', function(event){
                    jQuery('div.inner-single-box').removeClass('border-green');
                    jQuery(this).closest('div.inner-single-box').addClass('border-green');
                });
            });
        },0);

        $('#team-page').addClass('f-p-inline-0');
  	}

    ngOnDestroy() {
        $('#team-page').removeClass('f-p-inline-0');
    }

  	public getOrgSessions(){
    	for(var i=0; i<this.organizations?.length;i++){
    		if(this.orgid == this.organizations[i].id){
    			this.orgName = this.organizations[i].name;
    		}
    	}
        this.orgs_memberCount = 0;
        this.sessionID = '';
        this.addOrg = false;
        this.addsession = false;
        this.addgetSession = false;
        if(this.mode == 'members'){
            this.addOrg = true;
            for(var i=0; i<this.globalGroup.length; i++){
                for(var j=0; j<this.globalGroup[i].organizations.length; j++){
                    if(this.orgid == this.globalGroup[i].organizations[j].id){
                        this.orgs_memberCount = this.orgs_memberCount + 1;
                        if(this.orgs_memberCount == this.globalGroup.length){
                            console.log(this.orgs_memberCount);
                            this.addOrg = false;
                        }else{
                        	this.addsession = true;
                        }
                    }
                }
            }
        }
        this.sessions = [];
        this.http.get(`${environment.profileURL}/api/v1/organizations/`+this.orgid+`/training_sessions.json`).subscribe(data => {
            this.sessions = data['training_sessions']; 
            this.addgetSession = true;
            if(this.sessions.length < 1){
            	this.add_Session = true;
            }else{
            	this.add_Session = false;
            }
            console.log(data);
        },
            error => console.log('oops', error)
        )
        this.OrgsTeams = [];
        this.http.get(`${environment.profileURL}/api/v1/organizations/`+this.orgid+`/teams.json`).subscribe(data => {
            this.OrgsTeams = data['teams'];
                
           console.log(data);
        },
            error => console.log('oops', error)
        )
    }

    public newsession(){
        this.tempSession = this.sessionID;
        this.tempSessionname = this.sessionName;
        this.loadingSession = false;
        this.add_Session = true;
        this.sessionID = '';
        this.sessionName = '';
    }

    public cancelSession(){
    	if(this.sessions.length > 0){
            this.sessionID = this.tempSession;
            this.sessionName = this.tempSessionname;
        	this.add_Session = false;
        }else{
        	setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 1000
                };
                this.toastr.error('No sessions in this Organization!', 'Sorry!');
            }, 0);
        }
    }

    public addSession(){
        if(this.sessionName){
        	this.kind = 'mindset';
            this.http.post(`${environment.profileURL}/api/v1/organizations/`+this.orgid+'/training_sessions.json',{ name: this.sessionName, kind: this.kind }).subscribe(result => {
                console.log(result);
                this.add_Session = false;
                this.sessionID = result['training_session'].id;
                this.http.get(`${environment.profileURL}/api/v1/organizations/`+this.orgid+`/training_sessions.json`).subscribe(data => {
                    this.sessions = data['training_sessions']; 
                    console.log(data);
                })
            },
            error => console.log('oops', error)
            )
        }
    }

    public addSessionMember(){
        if(this.sessionID > 0){
            var counter = 0;
            this.addtoSession = [];
            this.globalGroup.forEach(list =>{
                this.addtoSession[counter] = list.id;
                counter++;
            });
            this.loading = 1;
            this.http.post(`${environment.profileURL}/api/v1/organizations/`+this.orgid+`/training_sessions/`+this.sessionID+`/add_members.json`,{
                member_ids: this.addtoSession
            }).subscribe(result => {
                console.log(result);
                this.manage.Userprofiles = [];
                this.loading = 2;
                this.sessionToastr = true;
                // this.manage.ngOnInit();
                var that = this;
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 3000
                    };
                    this.toastr.success('Members were added successfully!', 'Great!');
                }, 1000);
            },response => {
                  console.log(response.data);
            })
        }
    }

    public previousOrgs(){        
	    if(this.orgid){
	    	this.orgid = '';
	    	this.orgDone = false;
            this.orgToastr = false;
	    }else{
	    	this.orgWizard = false;
	    	this.addMember = true;
	    	setTimeout(function() {
		    	jQuery('input[name="add-member"]').prop('checked',false);
                jQuery('input[value="orgs"]').prop('checked',true);
                jQuery('input[name="add-member"]').iCheck({
                    checkboxClass: 'icheckbox_square-green',
                    radioClass: 'iradio_square-green',
                });      
                jQuery('div.inner-single-box').removeClass('border-green');  
                jQuery('input[value="orgs"]').closest('div.inner-single-box').addClass('border-green');
                jQuery('input[name="add-member"]').on('ifChecked', function(event){    
                    jQuery('div.inner-single-box').removeClass('border-green');  
                    jQuery(this).closest('div.inner-single-box').addClass('border-green');
                });
		    }, 1);
	    }
    }

    public previousTeam(){        
	    if(this.orgid && !this.teamID){
	    	this.orgid = '';
	    	this.addteam = false;
	    	this.orgteamDone = false;
	    	this.teamID = '';
	    }else if(this.teamID) {
	    	this.teamID = '';
	    	this.teamDone = false;
            this.teamToastr = false;
	    }else{
	    	this.addMember = true;
	    	this.teamWizard = false;
	    	setTimeout(function() {
		    	jQuery('input[name="add-member"]').prop('checked',false);
                jQuery('input[value="teams"]').prop('checked',true);
                jQuery('input[name="add-member"]').iCheck({
                    checkboxClass: 'icheckbox_square-green',
                    radioClass: 'iradio_square-green',
                });      
                jQuery('div.inner-single-box').removeClass('border-green');  
                jQuery('input[value="teams"]').closest('div.inner-single-box').addClass('border-green');
                jQuery('input[name="add-member"]').on('ifChecked', function(event){    
                    jQuery('div.inner-single-box').removeClass('border-green');  
                    jQuery(this).closest('div.inner-single-box').addClass('border-green');
                });
		    }, 1);
	    }
    }

    public previousSession(){        
	    if(this.orgid && !this.sessionID){
	    	this.orgid = '';
	    	this.addgetSession = false;
	    	this.sessionID = '';
	    	this.orgsessionDone = false;
	    }else if(this.sessionID) {
	    	this.sessionID = '';
	    	this.sessionDone = false;
            this.sessionToastr = false;
	    }else{
	    	this.sessionWizard = false;
	    	this.addMember = true;
	    	setTimeout(function() {
                jQuery('input[name="add-member"]').prop('checked',false);
                jQuery('input[value="sessions"]').prop('checked',true);
                jQuery('input[name="add-member"]').iCheck({
                    checkboxClass: 'icheckbox_square-green',
                    radioClass: 'iradio_square-green',
                });      
                jQuery('div.inner-single-box').removeClass('border-green');  
                jQuery('input[value="sessions"]').closest('div.inner-single-box').addClass('border-green');
                jQuery('input[name="add-member"]').on('ifChecked', function(event){    
                    jQuery('div.inner-single-box').removeClass('border-green');  
                    jQuery(this).closest('div.inner-single-box').addClass('border-green');
                });
		    }, 1);
	    }
    }

    public previousGroups(){
    	if(this.groupid){
	    	this.groupid = '';
	    	this.groupDone = false;
            this.groupToastr = false;
	    }else{
	    	this.groupWizard = false;
	    	this.addMember = true;
	    	setTimeout(function() {
		    	jQuery('input[name="add-member"]').prop('checked',false);
                jQuery('input[value="groups"]').prop('checked',true);
                jQuery('input[name="add-member"]').iCheck({
                    checkboxClass: 'icheckbox_square-green',
                    radioClass: 'iradio_square-green',
                });      
                jQuery('div.inner-single-box').removeClass('border-green');  
                jQuery('input[value="groups"]').closest('div.inner-single-box').addClass('border-green');
                jQuery('input[name="add-member"]').on('ifChecked', function(event){    
                    jQuery('div.inner-single-box').removeClass('border-green');  
                    jQuery(this).closest('div.inner-single-box').addClass('border-green');
                });
		    }, 1);
	    }
    }

    public previousSessions(){
    	if(this.sessionID){
	    	this.sessionID = '';
	    	this.sessionDone = false;
            this.sessionToastr = false;
	    }else{
	    	this.manage_session.sessionWizard = false;
	    	this.manage_session.addMember = true;
	    	this.sessionWizard = false;
	    	this.addMember = true;
	    	setTimeout(function() {
		    	jQuery('input[name="add-member"]').prop('checked',false);
                jQuery('input[value="sessions"]').prop('checked',true);
                jQuery('input[name="add-member"]').iCheck({
                    checkboxClass: 'icheckbox_square-green',
                    radioClass: 'iradio_square-green',
                });      
                jQuery('div.inner-single-box').removeClass('border-green');  
                jQuery('input[value="sessions"]').closest('div.inner-single-box').addClass('border-green');
                jQuery('input[name="add-member"]').on('ifChecked', function(event){    
                    jQuery('div.inner-single-box').removeClass('border-green');  
                    jQuery(this).closest('div.inner-single-box').addClass('border-green');
                });
		    }, 1);
	    }
    }

    public finalsession(){
    	this.sessionDone = true;
    }

    public checkMember(orgs){
        if(orgs){
            for(var i=0; i<orgs.length; i++){
                if(this.orgid == orgs[i].id){
                    return true;
                }
            }
        }
    }

    public getOrgTeams(){
    	for(var i=0; i<this.organizations?.length;i++){
    		if(this.orgid == this.organizations[i].id){
    			this.orgName = this.organizations[i].name;
    		}
    	}
    	this.addteam = false;
    	this.teamID = '';
        this.OrgsTeams = [];
        this.http.get(`${environment.profileURL}/api/v1/organizations/`+this.orgid+`/teams.json`).subscribe(data => {
            this.OrgsTeams = data['teams'];
        	this.addteam = true;
        	if(this.OrgsTeams.length - 2 > 0){
        		this.add_Team = false;
        	}else{
        		this.add_Team = true;
        	}
           console.log(data);
        },
            error => console.log('oops', error)
        )
    }

    public newteam(){
        this.tempTeam = this.teamID;
        this.tempTeamName = this.teamName;
        this.loadingTeam = false;
        this.add_Team = true;
        this.teamID = '';
        this.teamName = '';
    }

    public cancelTeam(){
    	if(this.OrgsTeams.length - 2 > 0){
            this.teamID = this.tempTeam;
            this.teamName = this.tempTeamName;
        	this.add_Team = false;
        }else{
        	setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 1000
                };
                this.toastr.error('No teams in this Organization!', 'Sorry!');
            }, 0);
        }
    }

    public addTeam(){
        if(this.teamName){
            this.http.post(`${environment.profileURL}/api/v1/organizations/`+this.orgid+'/teams.json',{ name: this.teamName, organization_id: this.orgid }).subscribe(result => {
                console.log(result);
                this.teamID = result['team'].id;
                this.add_Team = false;
                this.http.get(`${environment.profileURL}/api/v1/organizations/`+this.orgid+`/teams.json`).subscribe(data => {
                    this.OrgsTeams = data['teams'];                        
                   console.log(data);
                })
            },
            error => console.log('oops', error)
            )
        }
    }

    public addTeamMember(){
        if(this.teamID > 0){
            this.addtoSession = {};
            var counter = 0;
            this.globalGroup.forEach(list =>{
                this.addtoSession[counter] = { 'email': list.email, 'role_id': 3 };
                counter++;                          
            });
            this.loading = 1;
            this.http.post(`${environment.profileURL}/api/v1/teams/`+this.teamID+'/add_members.json',{members_attributes:this.addtoSession}).subscribe(result => {
                console.log(result);
                this.manage.Userprofiles = [];
                this.loading = 2;
                // this.manage.ngOnInit();
                this.teamToastr = true;
                var that = this;
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 3000
                    };
                    this.toastr.success('Members were added successfully!', 'Nice!');
                }, 1000);

            },
            error => console.log('oops', error)
            )
        }
    }

    public crossClick(){
    	if(this.mode == 'members'){
    		this.manage.getOptions = false;
	    	this.sessionWizard = false;
	    	this.orgWizard = false;
	    	this.teamWizard = false;
	    	this.manage.Userprofiles = [];
	    	this.manage.ngOnInit();
    	}
        if(this.mode == 'Orgmembers'){
            this.org_members.getOptions = false;
            this.orgs_main.getOptions = false;
            this.sessionWizard = false;
            this.orgWizard = false;
            this.teamWizard = false;
            this.org_members.Userprofiles = [];
            this.org_members.ngOnInit();
        }
    	if(this.mode == 'session'){
    		this.manage_session.getOptions = false;
            var that = this;
    		jQuery(document).ready(function(e){
                that.manage_session.startDragDrop();
	            jQuery('input[name="compact"]').iCheck({
	                checkboxClass: 'icheckbox_square-green',
	                radioClass: 'iradio_square-green',
	            });
	        });
    		this.manage_session.ngOnInit();
    	}
    	if(this.mode == 'team'){
    		this.org_individual.teamWizard = false;
            this.orgs_main.teamWizard = false;
    		this.getOptions = false;
    		this.Downloadpdf = false;
    		this.addMember = false;
    	}
    	// var that = this;
    	// setTimeout(function() {
	    // 	for(var i=0; i<that.manage.deleteIds.length; i++){
	    // 		jQuery("input[value="+ that.manage.deleteIds[i] +"]").prop('checked',false);
	    // 	}
	    // }, 10);
    }

    public delete(index,id){        
        if(this.mode == 'session'){
        	this.addtoSession.splice(index,1);
        	this.sessionMembers.splice(index,1);
        }else{
        	this.globalGroup.splice(index,1);
	        this.manage?.deleteIds?.push(id);
	        console.log(this.globalGroup);
	        console.log(this.manage.deleteIds);
	        this.manage.checkcount -= 1;
	        this.getOrgSessions();
        }
    }

    public addOrganization(){
        this.tempOrg = this.orgid;
        this.tempOrgName = this.orgName;
        this.orgName = '';
        this.loading = false;
        this.add_orgs = true;
        this.orgid = '';
        this.addteam = false;
        this.addgetSession = false;
    }

    public cancelOrgs(){
        this.orgid = this.tempOrg;
        this.orgName = this.tempOrgName;
        this.getOrgTeams();
        this.getOrgSessions();
        this.add_orgs = false;
    }

    public newOrgs(){
        if(this.orgName){
            var data = {
                name:this.orgName
            };
            this.http.post(`${environment.profileURL}/api/v1/organizations.json`,data).subscribe(result => {
                console.log(result);
                this.organizations?.push(result['organization']);
                this.orgid = result['organization'].id;
                this.add_orgs = false;
                this.getOrgTeams();
            },
            error => {
                console.log('oops', error)
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 3000
                    };
                    this.toastr.error(error?.error?.errors, 'Oops!');
                }, 1000);
            })
        }
    }

    public addOrgMember(){
        if(this.orgid > 0 && this.OrgsTeams.length > 0){
            this.addtoSession = {};
            var counter = 0;
            this.globalGroup.forEach(list =>{
                this.addtoSession[counter] = { 'email': list.email, 'role_id': 3 };
                counter++;                          
            });
            for(var j=0;j<this.OrgsTeams.length;j++){
                if(this.OrgsTeams[j].name == 'Unassigned'){
                    this.unassignedId = this.OrgsTeams[j].id;
                }
            }
            this.loading = 1;
            this.http.post(`${environment.profileURL}/api/v1/teams/`+ this.unassignedId +'/add_members.json',{members_attributes:this.addtoSession}).subscribe(result => {
                console.log(result);
                this.manage.Userprofiles = [];
                this.loading = 2;
                var that = this;
                // this.manage.ngOnInit();
                this.orgToastr = true;
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 3000
                    };
                    this.toastr.success('Members were added successfully!', 'Nice!',
                    );
                }, 1000);

            },
            error => console.log('oops', error)
            )
        }
    }


    public cancelAddOrg(){
        this.addOrg = false;
        this.addsession = true;
    }

    public addMemberstoOrg(){
        if(this.orgid > 0 && this.OrgsTeams.length > 0){
            this.addtoSession = {};
            var counter = 0;
            this.globalGroup.forEach(list =>{
                this.addtoSession[counter] = { 'email': list.email, 'role_id': 3 };
                counter++;                          
            });
            for(var j=0;j<this.OrgsTeams.length;j++){
                if(this.OrgsTeams[j].name == 'Unassigned'){
                    this.unassignedId = this.OrgsTeams[j].id;
                }
            }
            this.loadingSession = 1;
            this.http.post(`${environment.profileURL}/api/v1/teams/`+ this.unassignedId +'/add_members.json',{members_attributes:this.addtoSession}).subscribe(result => {
                console.log(result);
                this.loadingSession = false;
                this.addOrg = false;
                this.addsession = true;
                var that = this;
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 3000
                    };
                    this.toastr.success('Members were added successfully to Organization!', 'Nice!');
                }, 1000);

            },
            error => console.log('oops', error)
            )
        }
    }



    public GetSessionName(){
    	if(this.mode == 'session'){
	    	for(var i=0; i<this.orgs.sessions.length;i++){
	    		if(this.sessionID == this.orgs.sessions[i].id){
	    			this.sessionName = this.orgs.sessions[i].name;
	    		}
	    	}
	    }
	    if(this.mode == 'team'){
	    	for(var i=0; i<this.sessions.length;i++){
	    		if(this.sessionID == this.sessions[i].id){
	    			this.sessionName = this.sessions[i].name;
	    		}
	    	}
	    }
    }

    public AddSessionMember(){
    	if(this.sessionID > 0){
	        console.log(this.addtoSession);
	        this.loading = 1;
	        this.http.post(`${environment.profileURL}/api/v1/organizations/` + this.orgId + `/training_sessions/` + this.sessionID + `/add_members.json`, {
	          member_ids: this.addtoSession
	        }).subscribe(result => {
	            console.log(result);
	            this.loading = 2;
	            this.sessionToastr = true;
	            setTimeout(function() {
	                this.toastr.options = {
	                    closeButton: true,
	                    progressBar: true,
	                    timeOut: 2000
	                };
	                this.toastr.success('Members were added successfully!', 'Great!');
	            }, 1000);
	        }, response => {
	              console.log(response.data);
	        })
		}
    }

    public addGroup(){
        this.loading = false;
        var counter = 0;
        this.addtoSession = [];
        this.sessionMembers = [];
        this.globalGroup.forEach(list =>{
        	this.sessionMembers[counter] =  {id:list.id, name:list.name, email: list.email, default_profile: list.default_profile};
            this.addtoSession[counter] = list.id;
            counter++;
        });
    }

    public addGroupMember() {
        if(this.groupid > 0){
            console.log(this.addtoSession);
            this.loading = 1;
            this.http.post(`${environment.profileURL}/api/v1/training_session_groups/` + this.groupid + `/add_members.json`, {
              member_ids: this.addtoSession
            }).subscribe(result => {
                console.log(result['training_session_group']);
                this.loading = 2;
                this.groupToastr = true;
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 2000
                    };
                    this.toastr.success('Members were added successfully!', 'Great!');
                }, 1000);
            }, response => {
                console.log(response.data);
            });
        }
    }

    public getOrgFirst(){
    	this.orgid = '';
    	this.orgDone = false;
    }

    public getTeamFirst(){
    	this.orgid = '';
    	this.addteam = false;
    	this.teamID = '';
    	this.orgteamDone = false;

    }

    public getTeamSecond(){
    	this.teamID = '';
    	this.teamDone = false;
    }

    public getSessionFirst(){
    	this.orgid = '';
    	this.addgetSession = false;
    	this.sessionID = '';
    	this.orgsessionDone = false;
    }

    public getSessionSecond(){
    	this.sessionID = '';
    	this.sessionDone = false;
    }

    public getGroupFirst(){
    	this.groupid = '';
    	this.groupDone = false;
    }

    public getTeamName(){
    	for(var i=0; i<this.OrgsTeams.length;i++){
    		if(this.teamID == this.OrgsTeams[i].id){
    			this.teamName = this.OrgsTeams[i].name;
    		}
    	}
    }

    public GetTeamName(){
    	for(var i=0; i<this.teams.length;i++){
    		if(this.teamID == this.teams[i].id){
    			this.teamName = this.teams[i].name;
    		}
    	}
    }

    public getSessionName(){
    	for(var i=0; i<this.sessions.length;i++){
    		if(this.sessionID == this.sessions[i].id){
    			this.sessionName = this.sessions[i].name;
    		}
    	}
    }

    public getGroupName(){
    	for(var i=0; i<this.groups.length;i++){
    		if(this.groupid == this.groups[i].id){
    			this.groupName = this.groups[i].name;
    		}
    	}
    }

    public finalgroup(){
    	if(this.groupid){
    		this.groupDone = true;
    	}
    }

    public finalorg(){
    	if(this.orgid){
    		this.orgDone = true;
    	}
    }

    public finalorgTeam(){
    	if(this.orgid){
    		this.orgteamDone = true;
    	}
    }

    public finalTeam(){
    	if(this.teamID){
    		this.teamDone = true;
    	}
    }

    public finalorgSession(){
    	if(this.orgid){
    		this.orgsessionDone = true;
    	}
    }

    public finalSession(){
    	if(this.sessionID){
    		this.sessionDone = true;
    	}
    }

    public openAccordian(){
    	if(this.open_acc){
          	this.open_acc = false;
        }else{
          	this.open_acc = true;;
        }
    }

    public cancelMembers(){
        this.getOptions = false;
        this.org_individual.teamWizard = false;
        this.orgs_main.teamWizard = false;
        this.manage.getOptions = false;
        this.org_members.getOptions = false;
        this.orgs_main.getOptions = false;
        var that = this;
        if(this.mode == 'session'){
        	this.manage_session.getOptions = false;            
        	jQuery(document).ready(function(e){
                that.manage_session.startDragDrop();
	            jQuery('input[name="compact"]').iCheck({
	                checkboxClass: 'icheckbox_square-green',
	                radioClass: 'iradio_square-green',
	            });
	        });
        }
    }

    public openWizard(){ 
        setTimeout(function() {
            jQuery(document).ready(function(e){
                jQuery('input[name="download-pdf"]').iCheck({
                    checkboxClass: 'icheckbox_square-green',
                    radioClass: 'iradio_square-green',
                });
                jQuery('input[name="add-member"]').iCheck({
                    checkboxClass: 'icheckbox_square-green',
                    radioClass: 'iradio_square-green',
                });
                jQuery('input[name="add-member"]').on('ifChecked', function(event){
                    jQuery('div.inner-single-box').removeClass('border-green');
                    jQuery(this).closest('div.inner-single-box').addClass('border-green');
                });
                jQuery('input[name="download-pdf"]').on('ifChecked', function(event){
                    jQuery('div.inner-single-box').removeClass('border-green');
                    jQuery(this).closest('div.inner-single-box').addClass('border-green');
                });
            });
        },0);   

        if(jQuery("input[value='add-members").prop("checked") == true){
            this.addMember = true;
            this.getOptions = false;
            this.pageTitle = 'Add Members';
        }

        if(jQuery("input[value='download-options").prop("checked") == true){
            this.Downloadpdf = true;
            this.getOptions = false; 
            this.download_profile = false;
            this.pageTitle = 'Download PDF';
        }
    }

    public canceldownloadPdf(){
    	if(this.mode == ('members' || 'Orgmembers')){
    		this.pageTitle = 'Manage Members Wizard';
    	}
    	if(this.mode == 'team'){
    		this.pageTitle = 'Team Members Wizard';
    	}
    	if(this.mode == 'session'){
    		this.pageTitle = 'Training Session Members Wizard';
            this.manage_session.getOptions = false; 
            var that = this;           
            jQuery(document).ready(function(e){
                that.manage_session.startDragDrop();
                jQuery('input[name="compact"]').iCheck({
                    checkboxClass: 'icheckbox_square-green',
                    radioClass: 'iradio_square-green',
                });
            });
    	}
        this.Downloadpdf = false;
        this.getOptions = true;
        setTimeout(function() {
            jQuery(document).ready(function(e){
                jQuery('input[name="member"]').prop('checked',false);
                jQuery('input[value="download-options"]').prop('checked',true);
                jQuery('input[name="member"]').iCheck({
                    checkboxClass: 'icheckbox_square-green',
                    radioClass: 'iradio_square-green',
                });      
                jQuery('div.inner-single-box').removeClass('border-green');  
                jQuery('input[value="download-options"]').closest('div.inner-single-box').addClass('border-green');
                jQuery('input[name="member"]').on('ifChecked', function(event){    
                    jQuery('div.inner-single-box').removeClass('border-green');  
                    jQuery(this).closest('div.inner-single-box').addClass('border-green');
                });
            });
        },0);
    }

    public downloadPdf(content){
        this.showGroupProfile = false;
        if(jQuery("input[value='group-profile").prop("checked") == true){
            console.log("group-profile");
            this.showGroup(content);
        }
        if(jQuery("input[value='thinking-profile").prop("checked") == true){
            console.log("thinking-profile");
            this.showThinkingProfile(content)
            this.teamReportService.wizardThinkingProfilePopup = true;
        }
        if(jQuery("input[value='team-profile").prop("checked") == true){
            console.log("show team");
            this.showTeam(content);
        }
        if(jQuery("input[value='team-report").prop("checked") == true){
            console.log("team-report");
            this.teamReport(content);
        }
        if(jQuery("input[value='group-list").prop("checked") == true){
            console.log("group list");
            this.groupList(content);
        }
    }

    public groupList(content) {
    	this.download_profile = false;
        var that = this;
        this.http.get(`${environment.profileURL}/api/v1/organizations/`+this.orgId+`/training_sessions/`+this.manage_session.params_id+`/export_csv.json?`).subscribe(data => {
            console.log(data);
            setTimeout(function() {
                that.app_type = 'Group List';
                that.pdf_type = 'group list';
                that.download_profile = true;
            }, 1000);
        },
            error => console.log('oops', error)
        );
    }

    public showGroup(content){
        if(this.mode == 'session'){
            this.router.navigate(['/orgs/'+this.orgId+'/session-profile/'+this.manage_session.params_id]);

        }if(this.mode == ('members' || 'Orgmembers')){
            this.showGroupProfile = true;
            this.pageTitle = 'Group Profile';

        }else{
            var that = this;
            this.download_profile = false;
            var counter = 0;
            var groupMembers = [];
            this.globalGroup.forEach(list =>{
                groupMembers[counter] = list.id;
                counter++;
            });
            this.profile_type = 'group profile'
            var attrib = {kind: 'group_profile', member_ids: groupMembers};
            console.log(attrib);
            console.log(this.teamReportService.teamSelectedArr)
            var len= this.teamReportService.teamSelectedArr.length;
            var clLow = 0;
            var clHigh = 0;
            var idLow = 0;
            var idHigh = 0;
            var deLow = 0;
            var deHigh = 0;
            var imLow = 0;
            var imHigh = 0;
            var integrator = 0;
            var date  = new Date();
            var highContast = this.teamReportService.teamSelectedArr[0]?.high_contrast;
            for(let i = 0 ; i < len; i++) {
                let pf = this.teamReportService.teamSelectedArr[i]?.profile_title
                console.log(pf);
                if(highContast < this.teamReportService.teamSelectedArr[i]?.high_contrast) {
                    highContast = this.teamReportService.teamSelectedArr[i]?.high_contrast;
                }
                if(this.teamReportService.teamSelectedArr[i]?.profile_title == "Clarifier") {
                    if(this.teamReportService.teamSelectedArr[i]?.clarifier_score == 'High') {
                        clHigh += 1;
                    } else {
                        clLow += 1
                    }
                } else  if(this.teamReportService.teamSelectedArr[i]?.profile_title == "Ideator") {
                    if(this.teamReportService.teamSelectedArr[i]?.ideator_score == 'High') {
                        idHigh += 1;
                    } else {
                        idLow += 1
                    }
                } else  if(this.teamReportService.teamSelectedArr[i]?.profile_title == 'Developer') {
                    if(this.teamReportService.teamSelectedArr[i]?.developer_score == 'High') {
                        deHigh += 1;
                    } else {
                        deLow += 1
                    }
                } else if(this.teamReportService.teamSelectedArr[i]?.profile_title == 'Implementer') {
                    if(this.teamReportService.teamSelectedArr[i]?.implementer_score == 'High') {
                        imHigh += 1;
                    } else {
                        imLow += 1
                    }
                }  else if(this.teamReportService.teamSelectedArr[i]?.profile_title == "Integrator") {
                    integrator += 1;
                }
            }
            this.args = {
                "id": this.teamReportService.teamSelectedArr[0]?.team_id,
                "template": "group_pf.html",
                "filename": "group_pf_org",
                "memberships_count": len,
                "name": this.teamReportService.teamSelectedArr[0]?.team_name,
                "organization_title": "Organization",
                "organization_id": this.teamReportService.teamSelectedArr[0]?.organization_id,
                "organization_name": this.teamReportService.teamSelectedArr[0]?.organization_name,
                "clarifier_low": clLow,
                "clarifier_high": clHigh,
                "ideator_low": idLow,
                "ideator_high": idHigh,
                "developer_low": deLow,
                "developer_high": deHigh,
                "implementor_low": imLow,
                "implementor_high": imHigh,
                "integrator": integrator,
                "highest_score": highContast,
                "app_type": "Group Profile",
                "pdf_type": "letter",
                "email": "testownerfoursightonline.com",
                "today_date": date,
                "content_type": "pdf",
                "profile_type": "Ask",
                "description": "Look at your group graph. What do you notice? What, if anything, is surprising? Where does this group need to paydeliberate attention so they can succeed? Think of a project orchallenge this group has recently been engaged in. Describehow the group approached the project using the group profileas a guide.",
                "footer_imptext": "FourSightÂ® Group Profile",
                "footer_text": " The chart above shows the high and low preference expressed byyour group. Note that the numbers in each bar of the chart will generally not add up to the totalnumber of people on your group. The chart only reflects group members with high or low preferencesin each area.",
                "version": "v17.3",
                }
                console.warn(this.args)
                // this.lmda_service.lambda_invoke('group-profile-dev-pdf',this.args);
            // this.http.post(`${environment.profileURL}/api/v1/pdfs/generate.json`,attrib).subscribe(data => {
            //     console.log(data);
            //     that.app_type = 'Group Profile';
            //     that.pdf_type = 'profile';
            //     that.download_profile = true;
            // },
            // err =>{
            //     console.log(err);
            //     setTimeout(function() {
            //         this.toastr.options = {
            //             closeButton: true,
            //             progressBar: true,
            //             timeOut: 2000
            //         };
            //         this.toastr.error(err.error.message, 'Sorry!');
            //     }, 1000);
            // })
        }
    }

    public showTeam(content){
        // if(this.mode == 'team'){
        //     this.router.navigate(['/team-profile/'+this.team_id]);
        // }else{
            this.download_profile = false;
            var that = this;
            var counter = 0;
            var groupMembers = [];
            this.globalGroup.forEach(list =>{
                groupMembers[counter] = list.id;
                counter++;
            });
            this.profile_type = 'team profile';
            var attrib = {kind: 'team_profile', member_ids: groupMembers};
            console.log(attrib);
            var len= this.teamReportService.teamSelectedArr?.length;
            var clNeu = [];
            var clLow = [];
            var clHigh = [];
            var idNeu = [];
            var idLow = [];
            var idHigh = [];
            var deNeu = [];
            var deLow = [];
            var deHigh = [];
            var imNeu = [];
            var imLow = [];
            var imHigh = [];
            var integrator = [];
            var date  = new Date();
            var highContast = this.teamReportService.teamSelectedArr[0]?.high_contrast;
            console.warn(this.teamReportService.teamSelectedArr)
            for(let i = 0 ; i < len; i++) {
                let pf = this.teamReportService.teamSelectedArr[i]?.profile_title
                console.log(pf);
                if(highContast < this.teamReportService.teamSelectedArr[i]?.high_contrast) {
                    highContast = this.teamReportService.teamSelectedArr[i]?.high_contrast;
                }
                if(this.teamReportService.teamSelectedArr[i]?.profile_title == "Clarifier") {
                    if(this.teamReportService.teamSelectedArr[i]?.clarifier_score == 'High') {
                        clHigh.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                    } else if(this.teamReportService.teamSelectedArr[i]?.clarifier_score == 'Neutral') {
                        clNeu.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                    } else {
                        clLow.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                    }
                } else  if(this.teamReportService.teamSelectedArr[i]?.profile_title == "Ideator") {
                    if(this.teamReportService.teamSelectedArr[i]?.ideator_score == 'High') {
                        idHigh.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                    } else if(this.teamReportService.teamSelectedArr[i]?.ideator_score == 'Neutral') {
                        idNeu.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                    } else {
                        idLow.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                    }
                } else  if(this.teamReportService.teamSelectedArr[i]?.profile_title == 'Developer') {
                    if(this.teamReportService.teamSelectedArr[i]?.developer_score == 'High') {
                        deHigh.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                    } else if (this.teamReportService.teamSelectedArr[i]?.developer_score == 'Neutral') {
                        deNeu.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                    } else {
                        deLow.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                    }
                } else if(this.teamReportService.teamSelectedArr[i]?.profile_title == 'Implementer') {
                    if(this.teamReportService.teamSelectedArr[i]?.implementer_score == 'High') {
                        imHigh.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                    } else if(this.teamReportService.teamSelectedArr[i]?.implementer_score == 'Neutral') {
                        imNeu.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                    } else {
                        imLow.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                    }
                }  else if(this.teamReportService.teamSelectedArr[i]?.profile_title == "Integrator") {
                    integrator.push(this.teamReportService.teamSelectedArr[i]?.first_name);
                }
            }
            this.args = {
                "function_name": "team-profile-dev-pdf",
                "invocation_type": "RequestResponse",
                "log_type": "None",
                "id": this.teamReportService.teamSelectedArr[0]?.team_id,
                "template": "team_pf.html",
                "filename": "team-pdf",
                "memberships_count": len,
                "name": "05 Feb 2023",
                "organization_title": "Organization",
                "organization_id": this.teamReportService.teamSelectedArr[0]?.organization_id,
                "organization_name": this.teamReportService.teamSelectedArr[0]?.organization_name,
                "clarifier_low": clLow.length,
                "clarifier_high": clHigh.length,
                "ideator_low": idLow.length,
                "ideator_high": idHigh.length,
                "developer_low": deLow.length,
                "developer_high": deHigh.length,
                "implementor_low": imLow.length,
                "implementor_high": imHigh.length,
                "integrator": integrator.length,
                "highest_score": highContast,
                "app_type": "Group Profile Plus",
                "pdf_type": "letter",
                "email": "sarah@blairmiller.com",
                "today_date": date,
                "content_type": "pdf",
                "profile_type": "Ask",
                "description": "Look at your graph. What do you notice? What, if anything, is surprising? Where does this group need to pay deliberate attention so they can succeed? Think of a project or a challenge this group has recently been engaged in. Describe how the group approached the project using the group profile as a guide.",
                "footer_imptext": "FourSightÂ® Group Profile",
                "footer_text": " The chart above shows the high and low thinking preferences of your group. Because neutral preferences are hidden, the numbers in each vertical bar may not add up to the group total.",
                "remember": "Preference <span>=</span> Energy",
                "team_des": "FourSight is a measure of thinking preference, not ability. A preference is a measure of energy, not talent. People with a high preference tend to gravitate to that stage of the problem-solving process. People with a low preference tend to move quickly through it. People with a neutral preference tend to do the work and move on. All three approaches can lead to problem-solving success. Teams are more likely to succeed when people are both self-aware and process aware.",
                "footer_des": null,
                "team_profiles": {
                    "clarifier_high": clHigh,
                    "clarifier_neutral": clNeu,
                    "clarifier_low": clLow,
                    "ideator_high": idHigh,
                    "ideator_neutral": idNeu,
                    "ideator_low": idLow,
                    "developer_high": deHigh,
                    "developer_neutral": deNeu,
                    "developer_low": deLow,
                    "implementer_high": imHigh,
                    "implementer_neutral": imNeu,
                    "implementer_low": imLow
                }
            }
                console.warn(this.args)
                // this.lmda_service.lambda_invoke('team-profile-dev-pdf',this.args);
           
            // this.http.post(`${environment.profileURL}/api/v1/pdfs/generate.json`,attrib).subscribe(data => {
            //     console.log(data);
            //     that.app_type = 'Team Profile';
            //     that.pdf_type = 'profile';
            //     that.download_profile = true;
            // },
            // err =>{
            //     console.log(err);
            //     setTimeout(function() {
            //         this.toastr.options = {
            //             closeButton: true,
            //             progressBar: true,
            //             timeOut: 2000
            //         };
            //         this.toastr.error(err.error.message, 'Sorry!');
            //     }, 1000);
            // })
        // }
    }

    // get profile using profile id
    async getPfUsingId(profile,counter:number = 0,i) {
        var that = this;
        await this.http.get(`${environment.profileURL}/api/v1/translations.json?language=`+profile?.language?.short_name).subscribe(data => {
            console.log(data);
            that.date_of_profile = data['date_of_profile'];
            that.organization_title = data['organization_title'];
            that.thinking_profile_heading = data['thinking_profile_heading'];
            that.thinking_profile_message = data['thinking_profile_message'];
            that.download = data['download'];
            that.share_my_profile = data['share_my_profile'];
            that.explain_my_profile = data['explain_my_profile'];
            that.low_preference = data['low_preference'];
            that.high_preference = data['high_preference'];
            that.contrast = data['contrast'];
            that.clarify = data['stage_clarify'];
            that.ideate = data['stage_ideate'];
            that.develop = data['stage_develop'];
            that.implement = data['stage_implement'];
            that.prefers_to = data['prefers_to'];
            that.get_report = data['get_report'];
            that.sign_up_with = data['sign_up_with'];
            that.oldValue = profile;
            // that.arguments(profile);
            that.args = {
                "date_title": data["date_of_profile"],
                "email_title" : data["profile_email"],
                "id": profile?.id,
                "content_type": "pdf",
                "personalized_note": `Get personalized results at <a href="${that.env.lambda_pf_footer}/thinking-coach"> ${that.env.lambda_pf_footer}/thinking-coach</a> by signing up with the email ${profile?.email}`,
                "template": "profile.html",
                "email": profile?.email,
                "date_of_profile": that.date_of_profile,
                "organization_title": data['organization_title'],
                "first_name": profile?.first_name,
                "last_name": profile?.last_name,
                "low_preference": that.low_preference,
                "high_preference": that.high_preference,
                "contrast": that.contrast,
                "clarify": that.clarify,
                "ideate": that.ideate,
                "develop": that.develop,
                "implement": that.implement,
                "prefers_to": that.prefers_to,
                "get_report": that.get_report,
                "sign_up_with": that.sign_up_with,
                "thinking_profile_heading": data['thinking_profile_heading'],
                "thinking_profile_message": data['thinking_profile_message'],
                "organization": profile?.organization_name,
                "clarifier_sum": profile?.clarifier_sum,
                "developer_sum": profile?.developer_sum,
                "ideator_sum": profile?.ideator_sum,
                "implementer_sum": profile?.implementer_sum,
                "low_contrast": profile?.low_contrast,
                "high_contrast": profile?.high_contrast,
                "generated_at": profile?.generated_at,
                "profile_title": profile?.profile_title,
                "profile_equation": profile?.profile_equation,
                "profile_description": profile?.profile_description,
            };
            let date = new Date();
            let pdfFilename = "foursight-profile-" + '-' + date.getFullYear().toString().substr(-2) + date.getMonth() + date.getDate();
            that.arguements["members"][i + ''] = that.args


            if(counter > 0) {
                this.lmda_service.lambda_res.next([])
                console.log(this.arguements)
                this.teamReportService.wizardArgs =this.arguements;
                // $('.team-loader').css('display', 'block');
                // let myPromise = new Promise((resolve, reject)=> {
                //     resolve(this.lmda_service.lambda_invoke('multi-profile-pdf-dev-pdf',this.arguements));
                // })
                // myPromise.then((res)=> {
                //     this.lmda_service.lambda_res.subscribe((pdfLink)=> {
                //         console.warn(pdfLink.length)
                //         if(pdfLink.length) {
                //             window.open(pdfLink, '_blank')
                //             this.lmda_service.lambda_res.unsubscribe();
                //             setTimeout(() =>{
                //                 $('.team-loader').css('display', 'none');
                //             },500)
                //         }
                //     })
                // })
            }
        },
            error => 
            {
                console.log('oops', error)
                this.date_of_profile = 'Profile Date';
                this.organization_title = 'Organization';
                this.thinking_profile_heading = 'FourSight<sup>®</sup> Thinking Profile</span>';
                this.thinking_profile_message = 'measures where you gain energy in the innovation process. There are no "good" or "bad" scores. Each of these four preferences has its own strengths and potential weaknesses. The goal is not to change your preferences, but to understand how your preferences are affecting your decision making, your perceptions, your stress levels and your interactions with others.';
                this.download = 'Download';
                this.share_my_profile = 'Share my profile';
                this.explain_my_profile = 'Explain my profile';
                this.low_preference = 'Low Preference';
                this.high_preference = 'High Preference';
                this.contrast = 'Neutral';
                this.clarify = 'Clarify';
                this.ideate = 'Ideate';
                this.develop = 'Develop';
                this.implement = 'Implement';
                this.prefers_to = 'prefers to';
                this.get_report = 'Get your full report at';
                this.sign_up_with = 'Sign up with the email';
                // this.arguments(profile);
        })
        console.log(this.arguements)
        // if(counter > 0) {
        //     console.log(this.arguements)
        //     $('.team-loader').css('display', 'block');
        //     this.lmda_service.lambda_invoke('multi-profile-pdf-dev-pdf',this.arguements);
        //     setTimeout(() =>{
        //         $('.team-loader').css('display', 'none');
        //     },2000)
        // }
    }

    // public download_mutli_profile() {
    //     $('.team-loader').css('display', 'block');
    //     this.lmda_service.lambda_invoke('multi-profile-pdf-dev-pdf',this.arguements);
    //         setTimeout(() =>{
    //             // this.lmda_service.lambda_invoke('multi-profile-pdf-dev-pdf',this.arguements);
    //             $('.team-loader').css('display', 'none');
    //         },1000)
    // }

    // public async arguments(message) {
    //     console.log(message)
    //     this.args = {
    //         "date_title": message["date_of_profile"],
    //         "email_title" : message["profile_email"],
    //         "id": message["id"],
    //         "content_type": "pdf",
    //         "personalized_note": `Get personalized results at <a href="${this.env.lambda_pf_footer}/thinking-coach"> ${this.env.lambda_pf_footer}/thinking-coach</a> by signing up with the email ${message["email"]}`,
    //         "template": "profile.html",
    //         "email": message["email"],
    //         "date_of_profile": this.date_of_profile,
    //         "organization_title": this.organization_title,
    //         "first_name": message["first_name"],
    //         "last_name": message["last_name"],
    //         "low_preference": this.low_preference,
    //         "high_preference": this.high_preference,
    //         "contrast": this.contrast,
    //         "clarify": this.clarify,
    //         "ideate": this.ideate,
    //         "develop": this.develop,
    //         "implement": this.implement,
    //         "prefers_to": this.prefers_to,
    //         "get_report": this.get_report,
    //         "sign_up_with": this.sign_up_with,
    //         "thinking_profile_heading": this.thinking_profile_heading,
    //         "thinking_profile_message": this.thinking_profile_message,
    //         "organization": message["organization_name"],
    //         "clarifier_sum": message["clarifier_sum"],
    //         "developer_sum": message["developer_sum"],
    //         "ideator_sum": message["ideator_sum"],
    //         "implementer_sum": message["implementer_sum"],
    //         "low_contrast": message["low_contrast"],
    //         "high_contrast": message["high_contrast"],
    //         "generated_at": message["generated_at"],
    //         "profile_title": message?.profile_title,
    //         "profile_equation": message["profile_equation"],
    //         "profile_description": message["profile_description"],
    //     };
    //     // await this.getPfUsingId(message)
    //     console.warn(this.args)
    // }

    public async showThinkingProfile(content) {
        console.log(content)
        var that = this;
        this.download_profile = false;
        var counter = 0;
        var groupMembers = [];
        this.globalGroup.forEach(list =>{
            groupMembers[counter] = list.id;
            counter++;
        });
        this.profile_type = 'multiple profiles'
        var attrib = {kind: 'multiple_profiles', member_ids: groupMembers};
        console.log(attrib);
        let grpMebLen:number = groupMembers.length;
        let args:any;
        let date = new Date();
        let pdfFilename = "foursight-profile-" + '-' + date.getFullYear().toString().substr(-2) + date.getMonth() + date.getDate();
        this.arguements = {
            "template": "multi-profile.html",
            "format": "A4",
            "organization": "Organization",
            "content_type": "pdf",
            "filename": pdfFilename,
            "members": {}
        }
        console.log(that.args,grpMebLen)
        for(let i =0 ; i < grpMebLen; i++) {
            // this.arguments(this.teamReportService.teamSelectedArr[i])
            var len = 0;
            if(i == grpMebLen -1) {
                len = i;
            }
            console.log(that.teamReportService.teamSelectedArr)
            await this.getPfUsingId(this.teamReportService.teamSelectedArr[i]?.default_profile,len,i);
            console.log(that.args);
            this.arguements["members"][i + ''] = this.args;
        }
        // console.log(this.arguements.members.length)
        //     console.log(this.arguements)
        //     $('.team-loader').css('display', 'block');
        //     setTimeout(() =>{
        //         this.lmda_service.lambda_invoke('multi-profile-pdf-dev-pdf',this.arguements);
        //         $('.team-loader').css('display', 'none');
        //     },2000)
        // this.http.post(`${environment.profileURL}/api/v1/pdfs/generate.json`,attrib).subscribe(data => {
        //     console.log(data);
        //     that.app_type = 'Thinking Profile';
        //     that.pdf_type = 'profile';
        //     that.download_profile = true;
        // },
        // err =>{
        //     console.log(err);
        //     setTimeout(function() {
        //         this.toastr.options = {
        //             closeButton: true,
        //             progressBar: true,
        //             timeOut: 2000
        //         };
        //         this.toastr.error(err.error.message, 'Sorry!');
        //     }, 1000);
        // })
    }

    public teamReport(content){
        var that = this;
        this.download_profile = false;
        var counter = 0;
        var groupMembers = [];
        this.globalGroup.forEach(list =>{
            groupMembers[counter] = list.id;
            counter++;
        });
        var attrib = {kind: 'team_report', member_ids: groupMembers};
        console.log(attrib);
        // this.http.post(`${environment.profileURL}/api/v1/pdfs/generate.json`,attrib).subscribe(data => {
        //     console.log(data);
        //     that.app_type = 'Team Report';
        //     that.pdf_type = 'report';
        //     that.download_profile = true;
        // },
        // err =>{
        //     console.log(err);
        //     setTimeout(function() {
        //         this.toastr.options = {
        //             closeButton: true,
        //             progressBar: true,
        //             timeOut: 2000
        //         };
        //         this.toastr.error(err.error.message, 'Sorry!');
        //     }, 1000);
        // })
    }

    public cancelAddmembers(){
    	if(this.mode == ('members' || 'Orgmembers')){
    		this.pageTitle = 'Manage Members Wizard';
    	}
    	if(this.mode == 'team'){
    		this.pageTitle = 'Team Members Wizard';
    	}
    	if(this.mode == 'session'){
    		this.pageTitle = 'Training Session Members Wizard';
    	}
    	
        this.addMember = false;
        this.getOptions = true;
        setTimeout(function() {
            jQuery(document).ready(function(e){
                jQuery('input[name="member"]').iCheck({
                    checkboxClass: 'icheckbox_square-green',
                    radioClass: 'iradio_square-green',
                });
                jQuery('input[name="member"]').on('ifChecked', function(event){
                    jQuery('div.inner-single-box').removeClass('border-green');
                    jQuery(this).closest('div.inner-single-box').addClass('border-green');
                });
            });
        },0);
    }

    public openAddmembers(){
    	if(this.mode == ('members' || 'Orgmembers')){
    		if(!this.organizations){
	            this.add_orgs = true;
	        }
	        this.addMember = false;
	        var orgs = [];
	        for(var i=0; i<this.globalGroup.length;i++){
	            for(var j=0;j<this.globalGroup[i].organizations.length;j++){
	                orgs.push(parseInt(this.globalGroup[i].organizations[j].id));
	            }
	        }
	        orgs.sort(function(a,b) { return a - b; });
	        var count = 0;
	        var org_count = 0;
	        var org_id = '';
	        for(var k=0; k<orgs.length; k++){
	            if(orgs[k] == orgs[k-1]){
	                count++;
	                if(count == this.globalGroup.length-1){
	                    org_count++;
	                    org_id = orgs[k];
	                }
	            }
	            else{
	                count = 0;
	            }
	        }
	        console.log(org_count);
	        if(org_count == 1){
	            this.orgid = org_id;
	            // this.orgs_id = this.orgid;
	            this.getOrgTeams();
	            this.getOrgSessions();
	            console.log(this.orgid);
	        }
	        else{
	            this.orgid = '';
	        }
	        this.getOptions = false;
	        this.loading = false;
	        this.add_orgs = false;
	        this.OrgsTeams = [];
    	}
        this.addMember = false;
        this.loading = false;
        this.download_profile = false;
        if(jQuery("input[value='group").prop("checked") == true){
            this.groupWizard = true;
        }
        if(jQuery("input[value='orgs").prop("checked") == true){
            this.orgWizard = true;
            this.orgid = '';
        }
        if(jQuery("input[value='teams").prop("checked") == true){
            this.teamWizard = true;
        }
        if(jQuery("input[value='sessions").prop("checked") == true){
            this.sessionWizard = true;
        }
    }

    public exitDownload(){
    	if(this.mode == 'members'){
	        this.getOptions = false;
	        this.Downloadpdf = false;
	        this.manage.getOptions = false;
	        this.addMember = false;
	    }
        if(this.mode == 'Orgmembers'){
            this.getOptions = false;
            this.Downloadpdf = false;
            this.org_members.getOptions = false;
            this.orgs_main.getOptions = false;
            this.addMember = false;
        }
	    if(this.mode == 'team'){
	        this.org_individual.teamWizard = false;
            this.orgs_main.teamWizard = false;
	        this.Downloadpdf = false;
	        this.getOptions = false;
	        this.addMember = false;
	    }
	    if(this.mode == 'session'){
	        this.manage_session.getOptions = false;
	        this.Downloadpdf = false;
	        this.getOptions = false;
	        this.addMember = false;
            var that = this;
	        jQuery(document).ready(function(e){
                that.manage_session.startDragDrop();
	            jQuery('input[name="compact"]').iCheck({
	                checkboxClass: 'icheckbox_square-green',
	                radioClass: 'iradio_square-green',
	            });
	        });
	    }

    }

    public accDownload() {
        this.router.navigate(['/account-downloads']);
    }

    public previous(){
        console.warn(this.mode)
        if(this.mode == 'members'){
            this.manage.getOptions = false;
            this.sessionWizard = false;
            this.orgWizard = false;
            this.teamWizard = false;
            this.manage.Userprofiles = [];
            this.manage.ngOnInit();
        }

        if(this.mode == 'Orgmembers'){
            this.org_members.getOptions = false;
            this.orgs_main.getOptions = false;
            this.sessionWizard = false;
            this.orgWizard = false;
            this.teamWizard = false;
            this.org_members.Userprofiles = [];
            this.org_members.ngOnInit();
        }

        if(this.mode == 'session'){
            this.manage_session.getOptions = false;
            var that = this;
            jQuery(document).ready(function(e){
                that.manage_session.startDragDrop();
                jQuery('input[name="compact"]').iCheck({
                    checkboxClass: 'icheckbox_square-green',
                    radioClass: 'iradio_square-green',
                });
            });
            this.manage_session.ngOnInit();
        }
        if(this.mode == 'team'){
            this.org_individual.teamWizard = false;
            this.orgs_main.teamWizard = false;
            this.getOptions = false;
            this.Downloadpdf = false;
            this.addMember = false;
            this.org_individual.ngOnInit();
        }
    }

}
