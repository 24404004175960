import { NgModule } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import{ Router, RouterModule } from '@angular/router';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { APIService } from '../../../API.service';
import {AccessGroupService} from './../../access-group.service';
import {ChallengeSummaryService} from './../../challenge-summary.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { SlideoutContentService } from '../../../service/slideout-content.service';
import { exit } from 'process';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { CnEditmodeService } from '../challenges_service/cn-editmode.service';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-develop-challenge',
  templateUrl: './develop-challenge.component.html',
  styleUrls: ['./develop-challenge.component.scss']
})

export class DevelopChallengeComponent implements OnInit {

	strengthen_content:any = '';
	menu_links;
	access_group:any;
	prev_strengthen_content:any = '';
	doing_content:any = '';
	doing_dropdown:any = '';
	prev_doing_content:any = '';
	edit_strengthen:boolean = false;
	edit_doing:boolean = false;
	PtEvaluation_content:any = [];
	plus_content:any = [];
	plus:any = [0];
	thinking:any = [0];
	thinking_content = [];
	issue_content:any = [];
	issue_text:any = [];
	opp_content:any = [];
	opp_text:any = [];
	edit_plus:boolean = true;
	edit_opportunity:boolean = false;
	edit_issues:boolean = false;
	edit_thinking:boolean = false;
	edit_PtEvaluation:boolean = true;
	strengthen:any = [0];
	doing:any = [0];
	opportunities:any = [0];
	issues:any = [0];
	showPoint: boolean = false;
	showStrength: boolean = false;
	showDoing: boolean = false;
	Stupdate:boolean = false;
	Doingupdate:boolean = false;
	StId:string = '';
	DoingId:string = '';
	Ptupdate:boolean = false;
	PtId:string = '';
	selected_strength:string;
	selected_opp:any = [];
	selected_thinking:string;
	selected_issue:any = [];
	private thinkingIds: any = [];
	col_update: string;
	proposed_content:any = [];
	loading_doing: boolean = false;
	loading_strengthen: boolean = false;
	compare_solution: boolean = false;
	showPlus:boolean = false;
	showOpp:boolean = false;
	showIssue:boolean = false;
	showThink:boolean = false;
	count_plus: number = 0;
	count_opp: number = 0;
	count_issue: number = 0;
	count_think: number = 0;
	process_type: string;
	popup_type: string;
	DivergeConvergeVal:boolean=false;
	all_data: any = [];
	pin_challenge: boolean = false;
	sol_pin_challenge:boolean = false;
	deleteDatavalue:number;
	modal:any;
	ThinkingStartbtn:boolean = true;
	TotalConvergeIssues:number = 0;
	issueIndex;
	issue_div_content:any = [];
	plus_div_content:any = [];
	opp_div_content:any = [];
	think_div_content:any = [];
	//slideout variable
	slideoutSolution:any;
	slideoutIssues:any;
	slideoutOpportunities:any;
	slideoutPluses:any;
	slideoutSolutionPrototype:any;
	slideoutStrengthen:any;
	slideoutDivergeContent:any;
	slideoutConvergeContent:any;
	slideoutthink:any;
	showVideo:boolean= false;
	reset_confirm: string = '';
	convIssueThinkData = '';
	currentIssueElement:any;
	// edit mode variables
	hideIssueDiv:boolean= true;
	hideDivLabel:string = 'See all diverged options';
	issueEyeIcon:boolean = true;
	editmode:boolean = false;
	hideOneditMode:boolean = true;
	editmodeicon:boolean = false;
	TopIdeasInput:boolean = false;
	plusInput:boolean = false;
	issueInput:boolean = false;
	opportunityInput:boolean = false;
	editplusmode:boolean = false;
	editissuemode:boolean = false;
	editopportunitymode:boolean = false;
	editthinkmode:boolean = false;
	thinkmodeInput:boolean = false;
	listGridView:string = 'listView';
	reduceSolHeight:boolean = false;
	solutionProEditmode:boolean = false;
	solutionProInput:boolean = false;
	solutionEditmode:boolean = false;
	Tracking:boolean = false;
	backTrackingBtn:boolean = false;
	PreviousTracker:string = '';
	currentTracker:string = '';
	gridViewClass:string = '';
	comefromimplement:boolean = false;
	ngrxAccessGroup:any;

  	constructor(private api: APIService, public router: Router, public allChallenge: ChallengeSummaryService, public accessgroup:AccessGroupService,private modalService: NgbModal,public slideContent:SlideoutContentService,public editmodeService:CnEditmodeService,public store: Store<any>) { 	}
	
	ngOnDestroy(){
		this.ngrxAccessGroup  = this.store.select<any>((state) => state.profile.accessGroupData);
		// sidebar
		let togglemenu = document.querySelector('.trigger-mini-bar') as HTMLElement;
		if(togglemenu.classList.contains('squeeze')){
			togglemenu.click();
			togglemenu.classList.remove('squeeze');
		}
		this.Startedtimode(false,true,'block','#fff');
		// this.allChallenge.delData.unsubscribe();
	}

  	async ngOnInit() {
		$('.develop-loader').css('display','flex');
		if(localStorage.getItem('implementTodevelop') == 'true'){
			//// console.log(1234);
			// that.showStrength = false;
			// that.edit_strengthen = true;
			localStorage.setItem('implementTodevelop','false');
			// $('.develop-loader').css('display','flex');
			await setTimeout(function(){
				that.StDiverge(1,1);
			},1000);
			// setTimeout(function() {
			// 	if($('.from-implement').offset()){
			// 		$('html,body').animate({
			//             scrollTop: $('.from-implement').offset().top},
			//         'slow');
			// 	}
			// }, 1000);
		}

		// come from ideate top ideas
		if(localStorage.getItem('gotodevelop') == 'true'){
			localStorage.setItem('gotodevelop','false');
			// $('.develop-loader').css('display','flex');
			// await setTimeout(() => {
			// 	if(this.doing_content?.length > 0){
			// 		this.DoingDiverge()
			// 	}else {
			// 		this.DoingStart();
			// 	}
			// }, 1500);
		}

		// sidebar
		let togglemenu = document.querySelector('.trigger-mini-bar') as HTMLElement;
		if(!togglemenu.classList.contains('squeeze')){
			togglemenu.click();
			togglemenu.classList.add('squeeze');
		}

		if(this.editmodeService.editmode.value){
			let page = document.getElementById('page-wrapper');
			page.style.background = '#fff';
			let footer = document.querySelector('.footer') as HTMLElement;
			footer.style.backgroundColor = '#fff';
			footer.style.border = 'none';
			// let togglemenu = document.querySelector('.trigger-mini-bar') as HTMLElement;
			// togglemenu.click();
			this.editmodeService.editmode.next(false);
			// let main_nav = document.querySelector('.row.wrapper') as HTMLElement;
			// main_nav.style.display ='block';
		}
		// diverge,converge,solution,issues,opportunities,pluses content respectively
		this.slideoutDivergeContent = this.slideContent.divergeConvergeContent().develop.diverge;
		this.slideoutConvergeContent = this.slideContent.divergeConvergeContent().develop.converge;
		this.slideoutSolution = this.slideContent.divergeConvergeContent().develop.solution;
		this.slideoutIssues = this.slideContent.divergeConvergeContent().develop.issues;
		this.slideoutOpportunities = this.slideContent.divergeConvergeContent().develop.opportunities;
		this.slideoutPluses = this.slideContent.divergeConvergeContent().develop.pluses;
		this.slideoutSolutionPrototype = this.slideContent.divergeConvergeContent().develop.solutionPrototype;
		this.slideoutStrengthen = this.slideContent.divergeConvergeContent().develop.strengthen;
		this.slideoutthink = this.slideContent.divergeConvergeContent().develop.newthink;

		//// console.log(localStorage.getItem('email'));
	  	
	  	// // console.log(this.plus_content);
	  	var that = this;

		let DevelopPromise = new Promise((resolve,error)=>{
			let PtFilter = {email:{eq:localStorage.getItem('email')}, processTypeId:{eq:"9d455c40-4e27-4db2-ac3f-d5743d88887e"}, type:{eq:"process-stage"}, title:{eq:"point"}};
		  	this.api.ListFrameworks(PtFilter,1000).then(function (data){
			  // console.log(data)
			});
			this.api.ListFrameworks(PtFilter,10000).then(function (data) {
				if(data.items.length == 0){
					that.edit_plus = false;
					that.edit_PtEvaluation = false;
					that.thinking_content = [];
					that.thinking_content[0] = '';
					that.opp_content = [];
					that.opp_text = [];
					that.issue_content = [];
					that.issue_text = [];
					that.plus_content = [];
					that.plus = [];
					if(that.plus_content.length < 5){
						for(var i=that.plus.length;i<5;i++){
							that.plus[i] = ''
							that.plus_content[i] = '';
						}  			
					}
					that.PtStart();
				}else{
					that.edit_plus = false;
					that.edit_PtEvaluation = false;
					// console.log(data.items[0].content);
					// console.log(JSON.parse(data.items[0].content));
					that.PtId = data.items[0].id;
					that.Ptupdate = true;
					that.PtEvaluation_content = JSON.parse(data.items[0].content);
					// console.log(JSON.parse(that.PtEvaluation_content.point.pluses))
					that.plus_content = JSON.parse(that.PtEvaluation_content.point.pluses);
					that.plus = JSON.parse(that.PtEvaluation_content.point.pluses);	   
					that.count_plus = that.plus_content.length; 
					if(that.count_plus > 1){
						that.showPlus = true;
					}    	
					var think_content = JSON.parse(that.PtEvaluation_content.point.new_thinking);
					// console.log(think_content);
					for(var l=0; l<think_content.length; l++){
						that.thinking_content[l] = JSON.parse(think_content[l]);
						that.thinking[l] = JSON.parse(think_content[l]);
						that.think_div_content[l] = JSON.parse(think_content[l])
						if(l>1){
							for(var ct=0; ct<JSON.parse(think_content[l]).length; ct++){
								that.count_think = that.count_think + 1;
							}
							
						}
					}			  	
					// console.log(that.thinking_content);
					for(var y=0; y<that.thinking_content[0].length; y++){
						that.thinking_content[0][y] = that.thinking_content[0][y].replace("\n",'');
					}
					// console.log(that.thinking_content);
					that.selected_thinking = that.thinking_content[0];
					that.opportunities = JSON.parse(that.PtEvaluation_content.point.opportunities);
					that.opp_content[0] = '';
					that.opp_text[0] = ''; 
					for(var opp=1;opp<JSON.parse(that.PtEvaluation_content.point.opportunities).length;opp++){
						let data_str3 = (JSON.parse(that.PtEvaluation_content.point.opportunities)[opp]).split("\n");
						// console.log(data_str3)
						that.opp_content[opp] = data_str3[0];
						that.opp_text[opp] = data_str3[1];
						that.opp_div_content[opp] = data_str3[1];
					}
					that.selected_opp = JSON.parse(that.PtEvaluation_content.point.opportunities)[0];
			//    	for(var sel_opp=0; sel_opp<that.selected_opp.length; sel_opp++){
					// 	that.selected_opp[sel_opp] = that.selected_opp[sel_opp].replace("\n",'');
					// }
					// console.log(that.opp_text)
					that.count_opp = that.opp_text.length;
					if(that.count_opp > 1){
						that.showOpp = true;
					}
					that.issues = JSON.parse(that.PtEvaluation_content.point.issues);
					that.issue_content[0] = '';
					that.issue_text[0] = ''; 
					for(var x=1;x<JSON.parse(that.PtEvaluation_content.point.issues).length;x++){
						let data_str2 = (JSON.parse(that.PtEvaluation_content.point.issues)[x]).split("\n");
						// console.log(data_str2)
						that.issue_content[x] = data_str2[0];
						that.issue_text[x] = data_str2[1];
					}
					that.selected_issue = JSON.parse(that.PtEvaluation_content.point.issues)[0];
					that.TotalConvergeIssues = that.selected_issue.length;
					if(that.selected_issue.length > 0){
						that.showThink = true;
					}
					that.count_issue = that.issue_text.length;
					if(that.count_issue > 1){
						that.showIssue = true;
					}
					// console.log(typeof(that.selected_issue));
					that.edit_plus = false;
					that.edit_PtEvaluation = false;
					that.edit_thinking = false;
					that.edit_issues = false;
					that.edit_opportunity = false;
					for(var c=0; c< that.thinking_content.length-2;c++){
						that.thinkingIds[c] = JSON.stringify(c+2);
					}
				}
			})

			let DoingFilter = {email:{eq:localStorage.getItem('email')}, processTypeId:{eq:"9d455c40-4e27-4db2-ac3f-d5743d88887e"}, type:{eq:"process-stage"}, title:{eq:"myself-doing"}};
			this.api.ListFrameworks(DoingFilter,10000).then(function (data) {
				if(data.items.length == 0){
					that.edit_doing = false;
					that.doing_content = '';
					that.doing_dropdown = 'What I see myself doing is...';
				}else{
					// console.log(data.items[0].content);
					that.DoingId = data.items[0].id;
					that.Doingupdate = true;
					that.doing_content = data.items[0].content.substr(data.items[0].content.indexOf("\n")+1);
					that.doing_dropdown = data.items[0].content.substr(0,data.items[0].content.indexOf("\n"));
					that.prev_doing_content = data.items[0].content;
					that.edit_doing = false;
					that.showDoing = true;
				}
			})

			let StFilter = {email:{eq:localStorage.getItem('email')}, processTypeId:{eq:"9d455c40-4e27-4db2-ac3f-d5743d88887e"}, type:{eq:"process-stage"}, title:{eq:"strengthen-solution"}};
			this.api.ListFrameworks(StFilter,10000).then(function (data) {
				if(data.items.length == 0){
					that.edit_strengthen = false;
					that.strengthen_content = '';
				}else{
					that.StId = data.items[0].id;
					that.Stupdate = true;
					that.strengthen_content = data.items[0].content;
					that.prev_strengthen_content = data.items[0].content;
					if(localStorage.getItem('implementTodevelop') == 'true'){
						//// console.log(1234);
						// that.showStrength = false;
						// that.edit_strengthen = true;
						// localStorage.setItem('implementTodevelop','false');
						// $('.develop-loader').css('display','flex');
						// setTimeout(function(){
						// 	that.StDiverge(1,1);
						// },1000);
						// setTimeout(function() {
						// 	if($('.from-implement').offset()){
						// 		$('html,body').animate({
						//             scrollTop: $('.from-implement').offset().top},
						//         'slow');
						// 	}
						// }, 1000);
					}else{
						that.edit_strengthen = false;
						that.showStrength = true;
					}
					
				}
			})

			let IpFilter = {email:{eq:localStorage.getItem('email')}, processTypeId:{eq:"790b8a8e-9fdd-475d-9ab1-d320bfe69086"}, type:{eq:"process-stage"}, title:{eq:"ideas-proposed"}};
			this.api.ListFrameworks(IpFilter,10000).then(function (data) {
				if(data.items.length == 0){

				}else{
					for(var i=0; i<JSON.parse(data.items[0].content).length; i++){
						// console.log(JSON.parse(data.items[0].content)[i]);
						if(JSON.parse(data.items[0].content)[i]?.length != 0){
							that.proposed_content[i] = JSON.parse(JSON.parse(data.items[0].content)[i]);
						}
					}
					// console.log(that.proposed_content)
					// that.array_proposed_content = Array(that.proposed_content);
				}
			})
			setTimeout(() => {
				resolve([PtFilter,DoingFilter,StFilter,IpFilter]);
			}, 1000);
		});

		DevelopPromise.then(()=> {
			$('.develop-loader').css('display','none');
		})

		// come from implement
		// if(localStorage.getItem('implementTodevelop') == 'true'){
		// 	localStorage.setItem('implementTodevelop','false');
		// 	// that.backtoideate = true;
		// 	$('.develop-loader').css('display','flex');
		// 	setTimeout(function(){
		// 		that.StDiverge(1,1);
		// 	},1000);
		// }

		// Catching the Click Event
	    jQuery(document).click(function(event){
	    	// console.log(event);
			$("body").click(function() {
				$(".dotactions__dropdown").hide();
			});	

			$(".dotactions__top, .dotactions__dropdown").click(function(e) {
				e.stopPropagation();
			});
		});

		jQuery(document).on("click", ".popaccordian__rowhead", function(e) {
	    	e.stopPropagation();
			var thisPar = jQuery(this).parents(".popaccordian__row");
	

	  		if( !jQuery(thisPar).hasClass("popaccordian__row--active") ) {
	  			thisPar.find(".popaccordian__rowbody").slideDown();
	  			thisPar.siblings().find(".popaccordian__rowbody").slideUp();
	  			thisPar.addClass("popaccordian__row--active");
	  			thisPar.siblings().removeClass("popaccordian__row--active");
	  		} else {
	  			thisPar.siblings().find(".popaccordian__rowbody").slideUp();
	  		}
	    })
		
		jQuery('#SWALthink').click(function(){
			// console.log('hello');
		})
  	}

	// solution start btn
  	public StStart(){
		this.StId = '0';
		this.edit_strengthen = true;
		if(this.DoingId && this.DoingId != '0'){
    		this.edit_strengthen = true;
    		this.StId = '0';
    		this.showStrength = false;
    		var str1 = '\n' + 'Strengths' + '\n';
    		// if(this.plus_content[0].length > 0){
    		// 	for(var i=0; i<this.plus_content[0].length; i++){
        	// 		str1 = str1 + this.plus_content[0][i] + '\n';
        	// 	}
        	// }
        	if(this.selected_opp.length > 0){
        		for(var j=0; j<this.selected_opp.length; j++){
        			str1 = str1 + this.selected_opp[j] + '\n';
        		}
        	}
    		str1 = str1 + 'Improvements' + '\n';
    		if(this.thinking_content[0].length > 0){
        		for(var k=0; k<this.thinking_content[0].length; k++){
        			var str = this.thinking_content[0][k].replace('How to...','').replace('How might...','').replace('In what ways might...','').replace('What might be all the...','');
        			str1 = str1 + 'In order to ' + str + '\n';
        			if(this.thinking_content[k+2].length > 0){
	        			for(var tc=0; tc<this.thinking_content[k+2].length; tc++){
	        				str1 = str1 + 'I will ' + this.thinking_content[k+2][tc] + '\n';
	        			}
	        		}
        		}
        	}
    		this.strengthen_content = this.doing_dropdown + this.doing_content;
			// console.warn('----'+this.doing_dropdown)
    	}
		this.StDiverge();
	}

	// solution diverge
  	public StDiverge(clicknav = 0, trackit = 0){
		if(clicknav){
			this.Startedtimode(true,false,'none','#EBEBEB',false);
		}else{
			this.Startedtimode(true,false);
		}
		setTimeout(function(){
			$(document).scrollTop(0);
		}, 10);
		this.TopIdeasInput = false;
		this.listGridView = 'listView';
		this.solutionProEditmode = true;
		this.editthinkmode = false;
		this.editplusmode = false;
		this.editopportunitymode = false;
		this.editissuemode = false;
		this.solutionProEditmode = false;
		this.solutionEditmode = true;
		this.plusInput = true;
		this.opportunityInput = true;
		this.issueInput = true;
		this.solutionProInput = true;
		this.thinkmodeInput  = true;
		this.editmodeicon = true;
		this.Tracking = false;
		this.backTrackingBtn = false;
		this.PreviousTracker = 'solution';
		this.listGridView = 'listView';
		

		this.showStrength = false;
		this.edit_strengthen = true;
  		var strength = this.strengthen_content;
  		this.prev_strengthen_content = '';
  		this.prev_strengthen_content = strength;
  		this.cancelDoing();
  		this.cancelPlus();
  		this.cancelOpportunity();
  		this.cancelIssues();
  		this.cancelThinking();
		if(trackit == 1) {
			this.comefromimplement = true;
			this.PreviousTracker = 'implement';
			this.currentTracker = 'solution';
			this.Tracking = true;
			$('.develop-loader').css('display','none');
		}
		var that = this;
		// setTimeout(function(){
		// 	that.stickChallenge();
		// },300)
	}

	// save solution
  	public async submitStrengthen(trackit = 0, gotonext =''){
  		var that = this;	
  		if(this.strengthen_content && this.strengthen_content != ''){
  			this.loading_strengthen = true;
	  		if(!this.Stupdate){
	  		await	this.api.CreateFramework({email:localStorage.getItem('email'), title:"strengthen-solution", processTypeId:"9d455c40-4e27-4db2-ac3f-d5743d88887e",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:this.strengthen_content}).then(function (data){
						that.edit_strengthen = false;
						that.StId = data.id;
						that.Stupdate = true;
						that.loading_strengthen = false;
						that.strengthen_content = data.content;
						that.prev_strengthen_content = data.content;
						that.showStrength = true;
						setTimeout(function() {
							this.toastr.options = {
								closeButton: true,
								progressBar: true,
								timeOut: 2000
							};
							this.toastr.success('Your thought has been updated!', 'Nice!');
						}, 1000);
					}) 
	  		}else{
	  		await	this.api.UpdateFramework({id:this.StId,email:localStorage.getItem('email'), title:"strengthen-solution", processTypeId:"9d455c40-4e27-4db2-ac3f-d5743d88887e",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:this.strengthen_content}).then(function (data){
						that.edit_strengthen = false;
						that.loading_strengthen = false;
						that.strengthen_content = data.content;
						that.prev_strengthen_content = data.content;
						that.showStrength = true;
						setTimeout(function() {
							this.toastr.options = {
								closeButton: true,
								progressBar: true,
								timeOut: 2000
							};
							this.toastr.success('Your thoughts have been updated!', 'Nice!');
						}, 1000);
					}) 
	  		}
	  	}else{
	  		if(this.StId && this.StId != '0'){
	  			this.delStrengthen();
	  		}
	  		this.StId = '';
	  		this.edit_strengthen = false;
	  	}
		this.Startedtimode(false,true,'block','#fff');
		this.comefromimplement = false;
		if(trackit == 1) {
			this.router.navigate(['/challenges/implementer']);
		}
		if(gotonext === "implement"){
			localStorage.setItem('gotoimplement','true');
			this.router.navigate(['/challenges/implementer'])
		}
  	}

	// solution prototype start btn
  	public DoingStart(){
		this.DoingId = '0';
		this.edit_doing = true;
		this.DoingDiverge();
	}

	// solution prototype diverge
  	public DoingDiverge(prevTrack = 0){
		if(prevTrack == 1){
			this.Startedtimode(true,false,'none','#EBEBEB',false);
			this.Tracking = true;
			this.backTrackingBtn = true;
			this.issueEyeIcon = true;
		}else{
			this.Startedtimode(true,false);
			this.Tracking = false;
			this.backTrackingBtn = false;
			this.issueEyeIcon = false;
			this.PreviousTracker = 'Solution Prototype';
		}
		setTimeout(function(){
			$(document).scrollTop(0);
		}, 10);
		this.TopIdeasInput = true;
		this.solutionProEditmode = true;
		this.editthinkmode = false;
		this.editplusmode = false;
		this.editopportunitymode = false;
		this.editissuemode = false;
		this.solutionEditmode = false;
		this.plusInput = false;
		this.opportunityInput = false;
		this.issueInput = false;
		this.solutionProInput = false;
		this.thinkmodeInput = false;
		this.editmodeicon = false;
		this.currentTracker = 'Solution Prototype';

		this.showDoing = false;
		this.edit_doing = true;
  		var doing = this.prev_doing_content;
  		this.doing_content = this.prev_doing_content.substr(this.prev_doing_content.indexOf("\n")+1);
    	this.doing_dropdown = this.prev_doing_content.substr(0,this.prev_doing_content.indexOf("\n"));
		// console.warn('---'+this.doing_dropdown )
		if(this.doing_dropdown == ''){
			this.doing_dropdown = 'What I see myself doing is...';
		}
		// console.warn('---'+this.doing_dropdown )
  		this.cancelPlus();
  		this.cancelOpportunity();
  		this.cancelIssues();
  		this.cancelThinking();
  		this.cancelSt();
		// var that = this;
		// setTimeout(function(){
		// 	that.stickChallenge();
		// },300)
		$('.develop-loader').css('display','none');
	}

	//save solution prototype
  	public  submitDoing(prevTrack = 0){
	  	if(this.doing_content && this.doing_content != ''){
	  		var that = this;
	  		this.loading_doing = true;
	  		var content1 = this.doing_dropdown.concat("\n"+this.doing_content);
	  		if(!this.Doingupdate){
	  			this.api.CreateFramework({email:localStorage.getItem('email'), title:"myself-doing", processTypeId:"9d455c40-4e27-4db2-ac3f-d5743d88887e",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:content1}).then(function (data){
		  			that.edit_doing = false;
		  			that.DoingId = data.id;
		  			that.Doingupdate = true;
		  			that.loading_doing = false;
		        	that.doing_content = data.content.substr(data.content.indexOf("\n")+1);
    				that.doing_dropdown = data.content.substr(0,data.content.indexOf("\n"));
		        	that.prev_doing_content = data.content;
		        	that.showDoing = true;
			  		setTimeout(function() {
			            this.toastr.options = {
			                closeButton: true,
			                progressBar: true,
			                timeOut: 2000
			            };
			            this.toastr.success('Your thought has been updated!', 'Nice!');
			        }, 1000);
		  		}) 
	  		}else{
	  			this.api.UpdateFramework({id:this.DoingId,email:localStorage.getItem('email'), title:"myself-doing", processTypeId:"9d455c40-4e27-4db2-ac3f-d5743d88887e",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:content1}).then(function (data){
		  			that.edit_doing = false;
		  			that.loading_doing = false;
		        	that.doing_content = data.content.substr(data.content.indexOf("\n")+1);
    				that.doing_dropdown = data.content.substr(0,data.content.indexOf("\n"));
		        	that.prev_doing_content = data.content;
	        		that.showDoing = true;
			  		setTimeout(function() {
			            this.toastr.options = {
			                closeButton: true,
			                progressBar: true,
			                timeOut: 2000
			            };
			            this.toastr.success('Your thoughts have been updated!', 'Nice!');
			        }, 1000);
		  		}) 
	  		}
	  	}else{
	  		if(this.DoingId && this.DoingId != '0'){
	  			this.delDoing();
	  		}
	  		this.DoingId = '';
	  		this.edit_doing = false;
	  	}
		  
		// calling plus diverge when click on go to next step
		if(prevTrack === 2) {
			setTimeout(() => {
				this.PlusDiverge();
			}, 1000);
		}

		if(prevTrack == 1){
			// console.log('if blk')
			this.Tracking = false;
			this.backTrackingBtn = false;
			this.callto_point();
		}else{
			setTimeout(function() {
				that.Startedtimode(false,true,'block','#fff');
			},500);
		}
  	}

	public callto_point() {
		switch(this.PreviousTracker){
			case 'New Thinking':
				this.ThinkDiverge(1);
				break;
			case 'Opportunities':
				this.OppDiverge(1);
				break;
			case 'Issues':
				this.IssueDiverge(1);
				break;
			case 'Pluses':
				this.PlusDiverge(1);
				break;
			case 'solution':
				this.StDiverge(1);
				break;
			default:
				this.Startedtimode(false,true,'block','#fff');
				break;
		}
		
	}

	// delete solution content
  	public delStrengthen(){
	  	var that = this;
		try {
			this.api.DeleteFramework({id:this.StId}).then(function(){
				that.Stupdate = false;
				that.strengthen_content = '';
				that.prev_strengthen_content = '';
				that.edit_strengthen = false;
				that.StId = '';
				setTimeout(function() {
					this.toastr.options = {
						closeButton: true,
						progressBar: true,
						timeOut: 2000
					};
					this.toastr.success('Your thoughts has been deleted!', 'Nice!');
				}, 1000);
			})
		} catch (err) {
			console.log(err)
		}
  	}

	// delete solution prototype content
  	public delDoing(){
	  	var that = this;
		try {
			this.api.DeleteFramework({id:this.DoingId}).then(function(){
				that.Doingupdate = false;
				that.doing_content = '';
				that.doing_dropdown = 'What I see myself doing is...';
				that.prev_doing_content = '';
				that.edit_doing = false;
				that.DoingId = '';
				setTimeout(function() {
					this.toastr.options = {
						closeButton: true,
						progressBar: true,
						timeOut: 2000
					};
					this.toastr.success('Your thoughts has been deleted!', 'Nice!');
				}, 1000);
			})
		} catch (err) {
			console.log(err)
		}
  	}

	// do not save changes solution prototype
  	public cancelDoing(editmode = 0, trackit = 0){
		this.pin_challenge = false;
		if(editmode == 1){
			this.Startedtimode(false,true,'block','#fff');
			this.Tracking = false;
		}
		if(trackit == 1){
			this.Tracking = false;
			this.backTrackingBtn = false;
			this.callto_point();
		}
  		this.edit_doing = false;
  		var doing = this.prev_doing_content;
  		this.doing_content = this.prev_doing_content.substr(this.prev_doing_content.indexOf("\n")+1);
    	this.doing_dropdown = this.prev_doing_content.substr(0,this.prev_doing_content.indexOf("\n"));
  		if(this.DoingId == '0'){
    		this.edit_doing = false;
    		this.DoingId = '';
    		this.doing_content = '';
    		this.doing_dropdown = 'What I see myself doing is...';
        	this.prev_doing_content = '';
    	}
    	if(this.DoingId != '' && this.DoingId != '0'){
    		this.showDoing = true;
    	}
  	}

  	public addStLine(){
  		this.strengthen.push(this.strengthen.length);
  	}

  	public subStLine(i){
	  	this.strengthen_content.splice(i,1);
	  	this.strengthen.splice(this.strengthen.length-1,1);
  	}

	// plus start btn
  	public PlusStart(){
  		this.edit_plus = true;
  		this.PlusDiverge();
		// this.stickChallengeSolpin(1);
		// setTimeout(function(){
		// 	var SolutionPrototypeHeight = $('.card.card-default:nth-child(2)').outerHeight(true);
		// 	var TopIdeasHeight = $('.card.card-default:nth-child(1)').outerHeight();
		// 	$('.stick-challenge-solpropin').animate({
		// 		scrollTop : SolutionPrototypeHeight + TopIdeasHeight
		// 	}, 300);
		// },1000);
  	}
	
	//Opportunities start btn
  	public OppStart(){
  		this.edit_opportunity = true;
  		this.OppDiverge();
		// this.stickChallengeSolpin(1);
		// setTimeout(function(){
		// 	var SolutionPrototypeHeight = $('.card.card-default:nth-child(2)').outerHeight(true);
		// 	var TopIdeasHeight = $('.card.card-default:nth-child(1)').outerHeight();
		// 	var plusesHeight = $('.col-sm-12.pluses').outerHeight();
		// 	$('.stick-challenge-solpropin').animate({
		// 		scrollTop : SolutionPrototypeHeight + TopIdeasHeight + plusesHeight -10
		// 	}, 300);
		// },1000);
  	}

	// issues start btn
  	public IssueStart(){
  		this.edit_issues = true;
  		this.IssueDiverge();
		// this.stickChallengeSolpin(1);
		// setTimeout(function(){
		// 	var SolutionPrototypeHeight = $('.card.card-default:nth-child(2)').outerHeight(true);
		// 	var TopIdeasHeight = $('.card.card-default:nth-child(1)').outerHeight();
		// 	var plusesHeight = $('.col-sm-12.pluses').outerHeight();
		// 	var opportunityHeight = $('.col-sm-12.opportunities').outerHeight();
		// 	$('.stick-challenge-solpropin').animate({
		// 		scrollTop : SolutionPrototypeHeight + TopIdeasHeight + plusesHeight + opportunityHeight -10
		// 	}, 300);
		// },1000);
  	}

	// Thinking Start button
	public ThinkingStart(){
		// console.log('thinking call');
		this.ThinkingStartbtn = false;
		this.ngOnInit();
		setTimeout(() => {
			this.ThinkDiverge();
		}, 1005);
		// this.stickChallengeSolpin(1);
		// setTimeout(function(){
		// 	var SolutionPrototypeHeight = $('.card.card-default:nth-child(2)').outerHeight(true);
		// 	var TopIdeasHeight = $('.card.card-default:nth-child(1)').outerHeight();
		// 	var plusesHeight = $('.col-sm-12.pluses').outerHeight();
		// 	var opportunityHeight = $('.col-sm-12.opportunities').outerHeight();
		// 	var issuesHeight = $('.col-sm-12.issues').outerHeight();
		// 	$('.stick-challenge-solpropin').animate({
		// 		scrollTop : (SolutionPrototypeHeight + TopIdeasHeight + plusesHeight + opportunityHeight + issuesHeight) -20
		// 	}, 300);
		// },1000);
	}

  	public checkPoint(){
  		this.thinking_content[1] = [];
		var that = this;
  		jQuery(".checkedbox-thinking:checkbox").each(function() {
	        var value = jQuery(this).val();

	        if (jQuery(this).is(':checked')) {
	        	that.thinking_content[1].push(value);
			} 
	  	});

		// var biggestNum = 0;
		// jQuery(".list-columns--tc").each(function() {
		// 	var thisHeight = jQuery(this).outerHeight();
			
		// 	if( thisHeight >  biggestNum ) {
		// 		biggestNum = thisHeight;
		// 	}

		// 	jQuery(".list-columns--tc").outerHeight(biggestNum);

		// });
		
	  	this.submitPtEvaluation('think');
  	}

	// plus Diverge 
  	public PlusDiverge(clicknav = 0, currentTrack =''){
		// console.log(this.plus_div_content)
		if(clicknav == 1 || this.Tracking){
			this.Startedtimode(true,false,'none','#EBEBEB',false);
			this.Tracking = true;
			this.backTrackingBtn = true;
			this.issueEyeIcon = true;
			this.currentTracker = currentTrack;
		}else{
			this.Startedtimode(true,false);
			this.PreviousTracker = 'Pluses';
			this.Tracking = false;
			this.backTrackingBtn = false;
			this.issueEyeIcon = false;
		}
		if(currentTrack == ''){
			this.Tracking = false;
			this.backTrackingBtn = false;
		}
		setTimeout(function(){
			$(document).scrollTop(0);
		}, 10);
		this.solutionProInput= true;
		this.editthinkmode = false;
		this.editplusmode = true;
		this.editopportunitymode = false;
		this.editissuemode = false;
		this.plusInput = false;
		this.opportunityInput = false;
		this.issueInput = false;
		this.solutionProEditmode =false;
		this.solutionEditmode = false;
		this.thinkmodeInput = false;
		this.TopIdeasInput = false;
		this.TopIdeasInput = false;
		this.editmodeicon = false;
		this.editmodeicon = false;
		
  		this.showPlus = false;
  		this.edit_plus = true;
  		this.cancelDoing();
  		this.cancelOpportunity();
  		this.cancelIssues();
  		this.cancelThinking();
  		this.cancelSt();
  		if(this.PtId == '0'){
	  		if(this.plus_content.length < 4){
	  			for(var j=this.plus_content.length;j<4;j++){
	  				this.plus[j] = '';
		  			this.plus_content[j] = '';
	  			}  			
	  		}  	
  		}else{
        	this.plus_content = JSON.parse(this.PtEvaluation_content.point.pluses);
        	this.plus_div_content = JSON.parse(this.PtEvaluation_content.point.pluses);
        	this.plus = JSON.parse(this.PtEvaluation_content.point.pluses);
        	if(this.plus_content.length < 4){
	  			for(var j=this.plus_content.length;j<4;j++){
	  				this.plus[j] = '';
		  			this.plus_content[j] = '';
	  			}  			
	  		}
  		}
		// console.warn(this.plus_div_content)
		var that = this;
		// setTimeout(function(){
		// 	that.stickChallenge();
		// },300)
  	}

	// opportunities diverge
  	public OppDiverge(clicknav = 0, currentTrack=''){
		  // console.log(this.opp_div_content)
		if(clicknav == 1 || this.Tracking){
			this.Startedtimode(true,false,'none','#EBEBEB',false);
			this.Tracking = true;
			this.backTrackingBtn = true;
			this.issueEyeIcon = true;
			this.currentTracker = currentTrack;
		}else{
			this.Startedtimode(true,false);
			this.PreviousTracker = 'Opportunities';
			this.Tracking = false;
			this.backTrackingBtn = false;
			this.issueEyeIcon = false;
		}
		if(currentTrack == ''){
			this.Tracking = false;
			this.backTrackingBtn = false;
		}
		setTimeout(function(){
			$(document).scrollTop(0);
		}, 10);
		this.solutionProInput= true;
		this.listGridView = 'listView';
		this.plusInput = true;
		this.issueInput = false;
		this.opportunityInput = false;
		this.editopportunitymode  = true;
		this.editplusmode = false;
		this.editissuemode= false;
		this.editthinkmode = false;
		this.editmodeicon = true;
		this.solutionProEditmode =false;
		this.solutionEditmode = false;
		this.thinkmodeInput  = false;
		this.TopIdeasInput = false;

  		this.showOpp = false;
  		this.edit_opportunity = true;
  		this.cancelDoing();
  		this.cancelPlus();
  		this.cancelIssues();
  		this.cancelThinking();
  		this.cancelSt();
  		if(this.PtId == '0'){
  			for(var j=this.opportunities.length;j<4;j++){
  				this.opp_text[j] = '';
	  			this.opp_content[j] = 'It might...';
	  			this.opportunities[j] = '';	  			
  			}
  		}else{
	  		this.opportunities = JSON.parse(this.PtEvaluation_content.point.opportunities);
	  		this.opp_content[0] = '';
			this.opp_text[0] = '';
			for(var i=1;i<JSON.parse(this.PtEvaluation_content.point.opportunities).length;i++){
	    		let data_str2 = (JSON.parse(this.PtEvaluation_content.point.opportunities)[i]).split("\n");
	    		this.opp_content[i] = data_str2[0];
	    		this.opp_text[i] = data_str2[1];
				this.opp_div_content[i] = data_str2[1];
	    	}
	    	this.selected_opp = JSON.parse(this.PtEvaluation_content.point.opportunities)[0];
	    	for(var sel_opp=0; sel_opp<this.selected_opp.length; sel_opp++){
		  		this.selected_opp[sel_opp] = this.selected_opp[sel_opp].replace("\n",'');
		  	}
		  	
		  	if(this.opportunities.length < 4){
		  		for(var j=this.opportunities.length;j<4;j++){
	  				this.opp_text[j] = '';
		  			this.opp_content[j] = 'It might...';
		  			this.opportunities[j] = '';	  			
	  			}
	  		}
	  		if(this.opportunities.length == 1){
		  		this.opportunities[1] = ''
		  		this.opp_content[1] = 'It might...';
	  		}
	  	}
		// var that = this;
		// setTimeout(function(){
		// 	that.stickChallenge();
		// },300)	  	
  	}

	// issue Diverge
  	public IssueDiverge(clicknav = 0, currentTrack = ''){
		// console.log(this.issue_text);
		// console.log(this.issue_div_content);
		if(clicknav == 1 || this.Tracking){
			this.Startedtimode(true,false,'none','#EBEBEB',false);
			this.Tracking = true;
			this.backTrackingBtn = true;
			this.issueEyeIcon = true;
			this.currentTracker = currentTrack;
		}else{
			this.Startedtimode(true,false);
			this.PreviousTracker = 'Issues';
			this.Tracking = false;
			this.backTrackingBtn = false;
			this.issueEyeIcon = false;
		}
		if(currentTrack == ''){
			this.Tracking = false;
			this.backTrackingBtn = false;
		}
		setTimeout(function(){
			$(document).scrollTop(0);
		}, 10);
		this.solutionProInput= true;
		this.listGridView = 'listView';
		this.plusInput = true;
		this.opportunityInput = true;
		this.issueInput = false;
		this.editplusmode = false;
		this.editopportunitymode = false;
		this.editissuemode = true;
		this.editthinkmode = false;
		this.editmodeicon = true;
		this.solutionProEditmode =false;
		this.solutionEditmode = false;
		this.thinkmodeInput  = false;
		this.TopIdeasInput = false;

  		this.showIssue = false;
  		this.edit_issues = true;
  		this.cancelDoing();
  		this.cancelPlus();
  		this.cancelOpportunity();
  		this.cancelThinking();
  		this.cancelSt();
  		if(this.PtId == '0'){
  			for(var j=this.issues.length;j<4;j++){
  				this.issue_text[j] = '';
	  			this.issue_content[j] = 'How might...';
	  			this.issues[j] = '';	  			
  			}
  		}else{
	  		this.issues = JSON.parse(this.PtEvaluation_content.point.issues);
	    	this.issue_content[0] = '';
			this.issue_text[0] = ''; 
	    	for(var i=1;i<JSON.parse(this.PtEvaluation_content.point.issues).length;i++){
	    		let data_str2 = (JSON.parse(this.PtEvaluation_content.point.issues)[i]).split("\n");
	    		// console.log(data_str2)
	    		this.issue_content[i] = data_str2[0];
	    		this.issue_text[i] = data_str2[1];
				this.issue_div_content[i] = data_str2[1];
	    	}
	    	this.selected_issue = JSON.parse(this.PtEvaluation_content.point.issues)[0];		  	
		  	// console.log(this.issue_content)
	  		if(this.issues.length < 4){
		  		for(var j=this.issues.length;j<4;j++){
	  				this.issue_text[j] = '';
		  			this.issue_content[j] = 'How might...';
		  			this.issues[j] = '';
	  			}

	  		}
	  	}
		// var that = this;
		// setTimeout(function(){
		// 	that.stickChallenge();
		// },300)
		// console.log(this.issue_div_content);	  	
  	}
	
	// diverge thinking
  	public ThinkDiverge(clicknav = 0, currentTrack=''){
		if(clicknav == 1 || this.Tracking){
			this.Startedtimode(true,false,'none','#EBEBEB',false);
			this.Tracking = true;
			this.backTrackingBtn = true;
			this.issueEyeIcon = true;
			this.currentTracker = currentTrack;
		}else{
			this.Startedtimode(true,false);
			this.PreviousTracker = 'New Thinking';
			this.Tracking = false;
			this.backTrackingBtn = false;
			this.issueEyeIcon = false;
		}
		if(currentTrack == ''){
			this.Tracking = false;
			this.backTrackingBtn = false;
		}
		setTimeout(function(){
			$(document).scrollTop(0);
		}, 10);
		this.solutionProInput= true;
		this.listGridView = 'listView';
		this.plusInput = true;
		this.issueInput = true;
		this.opportunityInput = true;
		this.editopportunitymode = false;
		this.editissuemode = false;
		this.editthinkmode = true;
		this.editmodeicon = true;
		this.solutionProEditmode =false;
		this.solutionEditmode = false;
		this.thinkmodeInput = false;
		this.TopIdeasInput = false;

  		this.showThink = false;
  		this.edit_thinking = true;
		this.editplusmode = false;
  		var think_content = JSON.parse(this.PtEvaluation_content.point.new_thinking);
	  	for(var l=0; l<think_content.length; l++){
	  		this.thinking_content[l] = JSON.parse(think_content[l]);
	  		this.thinking[l] = JSON.parse(think_content[l]);
	  	}
	  	for(var tc=0; tc<this.thinking_content[0].length; tc++){
	  		this.thinking_content[0][tc] = this.thinking_content[0][tc].replace("\n",'');
	  	}
    	this.cancelDoing();
  		this.cancelPlus();
  		this.cancelOpportunity();
  		this.cancelIssues();
  		this.cancelSt();
    	for(var x=0; x<this.thinking_content[0].length; x++){
    		if(this.thinking_content[x+2]){
		  		if(this.thinking_content[x+2].length < 2){
		  			for(var j=this.thinking_content[x+2].length;j<2;j++){
		  				// this.thinking[j] = '';
			  			this.thinking_content[x+2][j] = '';
			  			this.thinking[x+2][j] = '';
						this.think_div_content[x+2][j] = '';
		  			}
		  		}
		  	}else{
		  		this.thinking_content[x+2] = [];
				this.think_div_content[x+2] = [];
		  		x--;
		  	}
	  	}
		var think_content = JSON.parse(this.PtEvaluation_content.point.new_thinking);
		for(var l=0; l<think_content.length; l++){;
			this.think_div_content[l] = JSON.parse(think_content[l])
		}
		var that = this;
		// setTimeout(function(){
		// 	that.stickChallenge('think');
		// },300)
		// console.warn(this.thinking_content[0]);  	
		// console.warn(this.thinking_content);
		// console.warn(this.thinking);
  	}

	//plus submit
	public duplicateElementPlus(goback = 0){
		// console.log(this.plus_div_content);
		var arr = [];

		// // item already in diverge
		if(this.plus_content[0].length > 0){
			for(let i=1;i<this.plus_div_content.length;i++){
				if(this.plus_content[i] != this.plus_div_content[i]){
					// console.log(this.plus_content[0].indexOf(this.plus_content[i]))
					let index = this.plus_content[0].indexOf(this.plus_content[i]);
					// this.plus_content[0].splice(index,1,(this.plus_content[i]))
					this.plus_content[0][index] = this.plus_div_content[i];
					// console.log(this.plus_content[i]+'::'+ this.plus_div_content[i])
				}
			}
		}
		
		for(let i=1;i<this.plus_div_content.length;i++){
			if(!this.plus_content.includes(this.plus_div_content[i])){
				// console.warn(this.plus_div_content[i])
				arr.push(this.plus_div_content[i])
				// this.selected_issue.push(this.issue_content[i] + this.issue_div_content[i]);
				if(this.plus_content[0].length > 0){
					var index_of_item = this.plus_content[0].indexOf(this.plus_content[i]);
					if(index_of_item > -1){
						// console.warn(this.plus_content[i]);		
						this.plus_content[0].splice(index_of_item,1,(this.plus_div_content[i]))
					}
				}
			}
		}
		

		
		// console.warn(this.plus_content)
		console.warn(this.plus_content[0])

		this.addPlusLine();
		for(let i = 0; i < arr.length; i++){
			if(this.plus_content.indexOf('',1) > -1){
			  let blankSpaceIndex = this.plus_content.indexOf('',1);
			  this.plus_content[blankSpaceIndex] = arr[i];
			}
		}

		// converge all item by default
		if(goback === 2) {
			for(let i = 1; i<this.plus_div_content.length; i++) {
				this.addPlusLine()
				this.plus_content[0]= this.plus_div_content
			}
		}

		if(goback == 1){
			this.submitPtEvaluation('plus',0,0,'',1);
		}else{
			this.submitPtEvaluation('plus');
		}

		setTimeout(() => {
			this.gotonextstep('Opportunities');
		}, 1005);
	}

	//opp submit
	public async duplicateElementOpp(goback = 0){
		// console.log(this.opp_div_content)
		var arr = [];
		// item already in diverge
		if(this.selected_opp.length > 0){
			for(let i=1;i<this.opp_div_content.length;i++){
				if(this.opp_text[i] != this.opp_div_content[i]){
					// console.log(this.selected_opp.indexOf(this.opp_content[i] + this.opp_text[i]))
					let index = this.selected_opp.indexOf(this.opp_content[i] + this.opp_text[i]);
					this.selected_opp[index] = this.opp_content[i] + this.opp_div_content[i];
					// console.log(this.opp_text[i]+'::'+ this.opp_div_content[i])
				}
			}
		}

		for(let i=1;i<this.opp_div_content.length;i++){
			// console.warn(this.opp_text[(this.opp_text.indexOf(this.opp_div_content[i]))])
			if(!this.opp_text.includes(this.opp_div_content[i])){
				// console.warn(this.opp_div_content[i])
				arr.push(this.opp_div_content[i])
				// this.selected_issue.push(this.issue_content[i] + this.issue_div_content[i]);
				var index_of_item = this.selected_opp.indexOf(this.opp_content[i] + this.opp_text[i]);
				if(index_of_item > -1){
					// console.warn(this.opp_content[i] + '::' +this.opp_text[i]);		
					this.selected_opp.splice(index_of_item,1,(this.opp_content[i]+ this.opp_div_content[i]))
				} 
			}
		}
		// console.warn(this.opportunities);
		// console.warn(this.selected_opp);
		// console.warn(arr)

		for(let i = 0; i < arr.length; i++){
				this.addOppLine();
				if(this.opp_text.indexOf('',1) > -1){
				  let blankSpaceIndex = this.opp_text.indexOf('',1);
				  this.opp_text[blankSpaceIndex] = arr[i];
				}
		}

		// converge all item by default
		if(goback == 2){
			for(let i = 1; i<this.opp_div_content.length; i++) {
				if(this.selected_opp.includes(this.opp_content[i] + this.opp_div_content[i])){
	
				}else {
					this.selected_opp[i] = this.opp_content[i] + this.opp_div_content[i]
				};
			}
		}

		// console.warn(this.selected_opp);
		if(goback == 1){
			this.submitPtEvaluation('opp',0,0,'',1);
		}else{
			this.submitPtEvaluation('opp');
		}
		setTimeout(() => {
			this.gotonextstep('Issues');
		}, 1005);
	}

	//issue submit
	public duplicateElementIssue(goback = 0){
		console.log('converge issue calling',this.issue_div_content,this.issue_text)
		// console.log(this.issue_div_content);
		var arr = [];
		// item already in diverge
		if(this.selected_issue.length > 0){
			for(let i=1;i<this.issue_div_content.length;i++){
				if(this.issue_text[i] != this.issue_div_content[i]){
					// console.log(this.selected_issue.indexOf(this.issue_content[i] + this.issue_text[i]))
					let index = this.selected_issue.indexOf(this.issue_content[i] + this.issue_text[i]);
					this.selected_issue[index] = this.issue_content[i] + this.issue_div_content[i];
					// console.log(this.issue_text[i]+'::'+ this.issue_div_content[i])
				}
			}
		}

		for(let i=1;i<this.issue_div_content.length;i++){
			// console.warn(this.issue_text[(this.issue_text.indexOf(this.issue_div_content[i]))])
			if(!this.issue_text.includes(this.issue_div_content[i])){
				// console.warn(this.issue_div_content[i])
				arr.push(this.issue_div_content[i])
				// this.selected_issue.push(this.issue_content[i] + this.issue_div_content[i]);
				var index_of_item = this.selected_issue.indexOf(this.issue_content[i] + this.issue_text[i]);
				if(index_of_item > -1){
					// console.warn(this.issue_content[i] + '::' +this.issue_text[i]);		
					this.selected_issue.splice(index_of_item,1,(this.issue_content[i]+ this.issue_div_content[i]))
				} 
			}
		}
		console.log(arr)
		// console.warn(this.issues);
		// console.warn(this.selected_issue);
		// console.warn(arr)

		// removing '' values from array
		// this.issue_text = this.issue_text.filter(function( element ) {
		// 	return element !== '';
		//  });
		for(let i = 0; i < arr.length; i++){
				this.addIssueLine();
				if(this.issue_text.indexOf('',1) > -1){
				  let blankSpaceIndex = this.issue_text.indexOf('',1);
				  this.issue_text[blankSpaceIndex] = arr[i];
				}
		}
		// console.warn(this.selected_issue);
		if(goback == 1){
			this.submitPtEvaluation('issue',0,0,'',1);
		}else{
			this.submitPtEvaluation('issue');
		}
	}

	//Thinking submit
	public duplicateElementThinking(goback=0){
		// console.log(this.think_div_content);
		var arr = [[],[]];
		// item not in diverge  (thinking_content[1] having converge items and thinking_content[i+2] having total items)
		if(this.thinking_content[1].length > 0 && this.Tracking){
			for(let i=0;i<this.think_div_content[0].length;i++){
				for(let j=0;j<this.think_div_content[i+2].length;j++){
					if(this.think_div_content[i+2][j] != this.thinking_content[i+2][j] && this.think_div_content[i+2][j] != ''){
						// console.warn('this.think_div_content[i+2]--' + i + '--' + this.think_div_content[i+2])
						this.addThinkingSolution(i);
						this.thinking_content[1].push(this.think_div_content[i+2][j]);
						this.thinking_content[i+2].push(this.think_div_content[i+2][j])
					}
				}
			}
		}else{
			this.thinking_content = this.think_div_content;
		}
		// console.log(this.thinking_content)
		// console.warn(this.think_div_content);

		// for(let i=1;i<this.issue_div_content.length;i++){
		// 	// console.warn(this.issue_text[(this.issue_text.indexOf(this.issue_div_content[i]))])
		// 	if(!this.issue_text.includes(this.issue_div_content[i])){
		// 		// console.warn(this.issue_div_content[i])
		// 		arr.push(this.issue_div_content[i])
		// 		var index_of_item = this.selected_issue.indexOf(this.issue_content[i] + this.issue_text[i]);
		// 		if(index_of_item > -1){
		// 			// console.warn(this.issue_content[i] + '::' +this.issue_text[i]);		
		// 			this.selected_issue.splice(index_of_item,1,(this.issue_content[i]+ this.issue_div_content[i]))
		// 		} 
		// 	}
		// }

		// item already not in think diverge
		// for(let i=0;i<this.think_div_content[0].length;i++){
		// 	for(let j=0;j<this.think_div_content[1].length;i++){
		// 		// // console.warn(this.thinking_content[i+2][(this.thinking_content[i+2].indexOf(this.think_div_content[i+2][j]))])
		// 		if(!(this.thinking_content[i+2]).includes(this.think_div_content[i+2][j])){
		// 			// console.warn(this.think_div_content[i+2][j])
		// 			arr[i].push(this.think_div_content[i+2][j])
		// 			var index_of_item = this.thinking_content[1].indexOf(this.thinking_content[i+2][j])
		// 			if(index_of_item > -1){
		// 				// console.warn(this.thinking_content[i+2][j])
		// 				this.thinking_content[1].splice(index_of_item,1,(this.think_div_content[i+2][j]))
		// 			}
		// 		}

		// 	}
		// }

		// for(let i = 0; i < arr.length; i++){
		// 	this.addIssueLine();
		// 	if(this.issue_text.indexOf('',1) > -1){
		// 	  let blankSpaceIndex = this.issue_text.indexOf('',1);
		// 	  this.issue_text[blankSpaceIndex] = arr[i];
		// 	}
		// }
		// // console.warn(this.selected_issue);
		// if(goback == 1){
		// 	this.submitPtEvaluation('issue',0,0,'',1);
		// }else{
		// 	this.submitPtEvaluation('issue');
		// }
		for(let i = 0;i<arr.length;i++){
			for(let j = 0; j< arr[i].length;j++){
				if(this.thinking_content[i+2].index('',) > -1){
					let blankSpaceIndex = this.thinking_content[i+2].indexOf('',1);
					this.thinking_content[blankSpaceIndex] = arr[i][j];
				}

			}
		}
		if(goback == 1){
			this.submitPtEvaluation('think',0,0,'',1);
		}else{
			this.submitPtEvaluation('think');
		}

		// for(let i=0;i<this.thinking_content[0].length;i++){
		// 	// console.warn(this.thinking_content[0][i])
		// 	for(let j=0;j<this.thinking_content[i+2].length;j++){
		// 		// console.warn(this.thinking_content[i+2][j])
		// 		// console.warn('--' + this.thinking_content[1][j]);
		// 	}
		// }

	}
	
	// submit or converge type(plus,opportunities,thinking,issues)
  	async submitPtEvaluation(type,editmode = 0,prevTrack = 0,currentTrack='',trackit = 0){
		console.log('see you')
		if(currentTrack.length !=0){
			this.currentTracker = currentTrack;
			this.Tracking = true;
			this.backTrackingBtn = true;
			this.issueEyeIcon = true;
		}
		if(this.Tracking){
			this.backTrackingBtn = true;
		}
		if(type == 'plus' && editmode == 1){
			if(prevTrack == 0){
				this.Startedtimode(true,false);
			}else{
				this.Startedtimode(true,false,'none','#EBEBEB',false);
			}
			// this.solutionProInput= true;
			this.solutionProInput= true;
			this.listGridView = 'listView';
			this.editthinkmode = false;
			this.editplusmode = true;
			this.editopportunitymode = false;
			this.editissuemode = false;
			this.plusInput = false;
			this.opportunityInput = false;
			this.issueInput = false;
			this.solutionProEditmode =false;
			this.solutionEditmode = false;
			this.thinkmodeInput = false;
			this.TopIdeasInput = false;
		}
		if(type == 'opp' && editmode == 1){
			if(prevTrack == 0){
				this.Startedtimode(true,false);
			}else{
				this.Startedtimode(true,false,'none','#EBEBEB',false);
			}
			this.plusInput = true;
			this.issueInput = false;
			this.opportunityInput = false;
			this.editopportunitymode  = true;
			this.editplusmode = false;
			this.editissuemode= false;
			this.editthinkmode = false;
			this.editmodeicon = true;
			this.solutionProEditmode =false;
			this.solutionEditmode = false;
			this.thinkmodeInput  = false;
		}
		
		if(type == 'issue' && editmode == 1){
			console.log('enter submit issue')
			if(prevTrack == 0){
				this.Startedtimode(true,false);
			}else{
				this.Startedtimode(true,false,'none','#EBEBEB',false);
			}
			this.plusInput = true;
			this.opportunityInput = true;
			this.issueInput = false;
			this.editplusmode = false;
			this.editopportunitymode = false;
			this.editissuemode = true;
			this.editthinkmode = false;
			this.editmodeicon = true;
			this.solutionProEditmode =false;
			this.solutionEditmode = false;
			this.thinkmodeInput  = false;
		}
		if(type == 'think' && editmode == 1){
			if(prevTrack == 0){
				this.Startedtimode(true,false);
			}else{
				this.Startedtimode(true,false,'none','#EBEBEB',false);
			}
			
			this.plusInput = true;
			this.issueInput = true;
			this.opportunityInput = true;
			this.editopportunitymode = false;
			this.editissuemode = false;
			this.editthinkmode = true;
			this.editmodeicon = true;
			this.solutionProEditmode =false;
			this.solutionEditmode = false;
			this.thinkmodeInput = false;
		}
		this.solutionProInput= true;

		var that = this;
	  	console.log(this.PtEvaluation_content);
	  	if(this.PtEvaluation_content?.length != 0 || this.issues.length !==0){
			  let plus = this.plus_content;
	  		for(var i=plus.length-1;i>=1;i--){
				  if(plus[i] == null || plus[i] == ''){
					  // console.log(i)
	  				this.plus_content.splice(i,1);
	  				this.plus.splice(this.plus.length-1,1);
				}
			}
			if(this.plus_content[0] && this.plus_content[0].length > 0){
		  		for(var j=0; j<this.plus_content[0].length; j++){
		  			for(var k=1; k<this.plus_content.length; k++){
		  				if(this.plus_content[0][j] == this.plus_content[k]){
		  					break;
		  				}
		  				if(k == this.plus_content.length - 1 && this.plus_content[0][j] != this.plus_content[k]){
		  					this.plus_content[0].splice(j,1);
		  				}
		  			}
		  		}
		  	}
	  		for(var tc=2; tc<this.thinking_content.length; tc++){
		  		for(var tj=this.thinking_content[tc].length;tj>=0;tj--){
			  		if(this.thinking_content[tc][tj] == ''){
			  			this.thinking_content[tc].splice(tj,1);
			  			this.thinking[tc].splice(this.thinking[tc].length-1,1);
			  		}
			  	}
		  	}
	  		var issue = this.issue_text;
	  		for(var k=issue.length-1; k>=1;k--){
				if(issue[k] == null || issue[k] == ''){
					console.log('my issue text')
					this.issue_text.splice(k,1);
					this.issue_content.splice(k,1);
					this.issues.splice(this.issues.length-1,1);
				}
			}			
			var opp = this.opp_text;
			for(var x=opp.length-1; x>=1;x--){
				if(opp[x] == null || opp[x] == ''){
					this.opp_text.splice(x,1);
					this.opp_content.splice(x,1);
					this.opportunities.splice(this.opportunities.length-1,1);
				}
			}
			for(var j1=0; j1<this.selected_opp.length; j1++){
	  			for(var k1=1; k1<this.opportunities.length; k1++){
	  				if(this.selected_opp[j1] == this.opp_content[k1]+this.opp_text[k1]){
	  					break;
	  				}
	  				if(k1 == this.opportunities.length - 1 && this.selected_opp[j1] != this.opp_content[k1]+this.opp_text[k1]){
	  					this.selected_opp.splice(j1,1);
	  				}
	  			}
	  		}
	  		// console.log(this.selected_issue);
			var think_array = [];
			for(var y=0; y<this.selected_issue.length; y++){
				for(var is=1; is<this.issues.length; is++){
					if(this.selected_issue[y] == this.issue_content[is]+this.issue_text[is]){
						think_array.push(this.selected_issue[y]);
						break;
					}
					if(is == this.issues.length - 1 && this.selected_issue[y] != this.issue_content[is]+this.issue_text[is]){
	  					this.selected_issue.splice(y,1);
	  				}
				}
			}
			// console.log(this.selected_issue);
			this.thinking_content[0] = think_array;
			for(var a1=this.thinking_content.length; a1>=this.selected_issue.length+2; a1--){
				if(this.thinking_content[a1]){
					this.thinking_content.splice(a1,1);
				}
			}
			var content4 = [];
			for(var z=0; z<=this.thinking_content[0].length + 1; z++){
				if(this.thinking_content[z]){
			  		content4[z] = JSON.stringify(this.thinking_content[z]);
			  	}else{
			  		this.thinking_content[z] = [];
			  		z--;
			  	}
			}
			
			
	  		var content2 = [];
	  		var content3 = [];
	  		content2[0] = this.selected_opp;
	  		for(var op=1; op<this.opp_text.length;op++){
	  			content2[op] = this.opp_content[op].concat("\n"+this.opp_text[op]);
	  		}
	  		// console.log(this.issue_content, this.issue_text)
	  		content3[0] = this.selected_issue;
	  		for(var i=1; i<this.issue_text.length;i++){
	  			content3[i] = this.issue_content[i].concat("\n"+this.issue_text[i]);
	  		}
	  		// console.log(content3);
	  		var content = {
	  						point:{
	  							pluses:JSON.stringify(this.plus_content),
	  							opportunities:JSON.stringify(content2),
	  							issues:JSON.stringify(content3),
	  							new_thinking:JSON.stringify(content4)
	  						}
	  					}
			console.warn(this.Ptupdate);
	  		if(!this.Ptupdate){
				try {
					await this.api.CreateFramework({email:localStorage.getItem('email'), title:"point", processTypeId:"9d455c40-4e27-4db2-ac3f-d5743d88887e",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:JSON.stringify(content)}).then(function (data){
							that.edit_PtEvaluation = false;
							that.edit_plus = false;
							that.edit_thinking = false;
							that.edit_opportunity = false;
							that.edit_issues = false;
							that.PtId = data.id;
							that.Ptupdate = true;
							// console.log(JSON.parse(data.content))
						  that.PtEvaluation_content = JSON.parse(data.content);
						  that.plus_content = JSON.parse(that.PtEvaluation_content.point.pluses);
						  that.plus = JSON.parse(that.PtEvaluation_content.point.pluses);
						  that.count_plus = that.plus_content.length;
						  if(that.count_plus > 1 && type != 'plus'){
								that.showPlus = true;
							}else{
								that.showPlus = false;
							}
							var think_content = JSON.parse(that.PtEvaluation_content.point.new_thinking);
							that.count_think = 0;
							for(var l=0; l<think_content.length; l++){
								that.thinking_content[l] = JSON.parse(think_content[l]);
								that.thinking[l] = JSON.parse(think_content[l]);
								if(l>1){
									for(var ct=0; ct<JSON.parse(think_content[l]).length; ct++){
										that.count_think = that.count_think + 1;
									}
									
								}
							}
							for(var tc=0; tc<that.thinking_content[0].length; tc++){
								that.thinking_content[0][tc] = that.thinking_content[0][tc].replace("\n",'');
							}
							// console.log(that.thinking_content);
	  
							that.opportunities = JSON.parse(that.PtEvaluation_content.point.opportunities);
						   that.opp_content[0] = '';
						  that.opp_text[0] = ''; 
						  for(var opp=1;opp<JSON.parse(that.PtEvaluation_content.point.opportunities).length;opp++){
							  let data_str3 = (JSON.parse(that.PtEvaluation_content.point.opportunities)[opp]).split("\n");
							  // console.log(data_str3)
							  that.opp_content[opp] = data_str3[0];
							  that.opp_text[opp] = data_str3[1];
						  }
						  that.selected_opp = JSON.parse(that.PtEvaluation_content.point.opportunities)[0];
				   //    	for(var sel_opp=0; sel_opp<that.selected_opp.length; sel_opp++){
							// 	that.selected_opp[sel_opp] = that.selected_opp[sel_opp].replace("\n",'');
							// }
						  that.count_opp = that.opp_text.length;
						  if(that.count_opp > 1 && type != 'opp'){
								that.showOpp = true;
							}else{
								that.showOpp = false;
							}
						  console.log(that.issues)
							that.issues = JSON.parse(that.PtEvaluation_content.point.issues);
						   that.issue_content[0] = '';
						  that.issue_text[0] = ''; 
						  for(var i=1;i<JSON.parse(that.PtEvaluation_content.point.issues).length;i++){
							  let data_str2 = (JSON.parse(that.PtEvaluation_content.point.issues)[i]).split("\n");
							  // console.log(data_str2)
							  that.issue_content[i] = data_str2[0];
							  that.issue_text[i] = data_str2[1];
						  }
						  that.selected_issue = JSON.parse(that.PtEvaluation_content.point.issues)[0];
						  if(that.selected_issue.length > 0 && type != 'think'){
								that.showThink = true;
							}else{
								that.showThink = false;
							}
						  that.count_issue = that.issue_text.length;
						  if(that.count_issue > 1 && type != 'issue'){
								that.showIssue = true;
							}else{
								that.showIssue = false;
							}
							setTimeout(function() {
							  this.toastr.options = {
								  closeButton: true,
								  progressBar: true,
								  timeOut: 2000
							  };
							  this.toastr.success('Your thought has been updated!', 'Nice!');
						  }, 1000);
							that.showStrength = true;
							for(var ac=0; ac< that.thinking_content.length-2;ac++){
								that.thinkingIds[ac] = JSON.stringify(ac+2);
							}
						}) 
				} catch (err) {
					console.log(err)
				}
	  		}else{
				try {
					await this.api.UpdateFramework({id:this.PtId,email:localStorage.getItem('email'), title:"point", processTypeId:"9d455c40-4e27-4db2-ac3f-d5743d88887e",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:JSON.stringify(content)}).then(function (data){
							that.edit_PtEvaluation = false;
							that.edit_plus = false;
							that.edit_thinking = false;
							that.edit_opportunity = false;
							that.edit_issues = false;
							// console.log(JSON.parse(data.content))
						  that.PtEvaluation_content = JSON.parse(data.content);
						  that.plus_content = JSON.parse(that.PtEvaluation_content.point.pluses);
						  that.plus = JSON.parse(that.PtEvaluation_content.point.pluses);
						  that.count_plus = that.plus_content.length;
						  if(that.count_plus > 1 && type != 'plus'){
								that.showPlus = true;
							}else{
								that.showPlus = false;
							}
							var think_content = JSON.parse(that.PtEvaluation_content.point.new_thinking);
							that.count_think = 0;
							for(var l=0; l<think_content.length; l++){
								that.thinking_content[l] = JSON.parse(think_content[l]);
								that.thinking[l] = JSON.parse(think_content[l]);
								if(l>1){
									for(var ct=0; ct<JSON.parse(think_content[l]).length; ct++){
										that.count_think = that.count_think + 1;
									}
									
								}
							}
							for(var tc=0; tc<that.thinking_content[0].length; tc++){
								that.thinking_content[0][tc] = that.thinking_content[0][tc].replace("\n",'');
							}
							that.opportunities = JSON.parse(that.PtEvaluation_content.point.opportunities);
						   that.opp_content[0] = '';
						  that.opp_text[0] = ''; 
						  for(var opp=1;opp<JSON.parse(that.PtEvaluation_content.point.opportunities).length;opp++){
							  let data_str3 = (JSON.parse(that.PtEvaluation_content.point.opportunities)[opp]).split("\n");
							  // console.log(data_str3)
							  that.opp_content[opp] = data_str3[0];
							  that.opp_text[opp] = data_str3[1];
						  }
						  that.selected_opp = JSON.parse(that.PtEvaluation_content.point.opportunities)[0];
				   //    	for(var sel_opp=0; sel_opp<that.selected_opp.length; sel_opp++){
							// 	that.selected_opp[sel_opp] = that.selected_opp[sel_opp].replace("\n",'');
							// }
						  that.count_opp = that.opp_text.length;
						  if(that.count_opp > 1 && type != 'opp'){
								that.showOpp = true;
							}else{
								that.showOpp = false;
							}
							that.issues = JSON.parse(that.PtEvaluation_content.point.issues);
						   that.issue_content[0] = '';
						  that.issue_text[0] = ''; 
						  for(var i=1;i<JSON.parse(that.PtEvaluation_content.point.issues).length;i++){
							  let data_str2 = (JSON.parse(that.PtEvaluation_content.point.issues)[i]).split("\n");
							  // console.log(data_str2)
							  that.issue_content[i] = data_str2[0];
							  that.issue_text[i] = data_str2[1];
						  }
						  that.selected_issue = JSON.parse(that.PtEvaluation_content.point.issues)[0];
						  if(that.selected_issue.length > 0 && type != 'think'){
								that.showThink = true;
							}else{
								that.showThink = false;
							}
						  that.count_issue = that.issue_text.length;
						  if(that.count_issue > 1 && type != 'issue'){
								that.showIssue = true;
							}else{
								that.showIssue = false;
							}
							setTimeout(function() {
							  this.toastr.options = {
								  closeButton: true,
								  progressBar: true,
								  timeOut: 2000
							  };
							  this.toastr.success('Your thoughts have been updated!', 'Nice!');
						  }, 1000);
							that.showStrength = true;
							for(var ac=0; ac< that.thinking_content.length-2;ac++){
								that.thinkingIds[ac] = JSON.stringify(ac+2);
							}
						}) 
				} catch (err) {
					console.log(err)
				}
	  		}
	  	}
		this.TotalConvergeIssues = this.selected_issue.length; 
		var that = this;
		if(trackit == 1){
			// console.log('submit issue with tracking value');
			that.Tracking = false;
			that.backTrackingBtn = false;
			switch(this.PreviousTracker){
				case 'Pluses':
						// console.warn('new pluses calling submit pt eval');
						that.closePlus(0,1);
						that.closeOpp(0,1);
						that.closeIssue(0,1);
						that.closeThink(0,1);
						that.PlusDiverge(1);
					break;
				case 'Opportunities':
						// console.warn('new opp calling submit pt eval');
						that.closePlus(0,1);
						that.closeOpp(0,1);
						that.closeIssue(0,1);
						that.closeThink(0,1);
						that.OppDiverge(1);
					break;
				case 'Issues':
						that.closePlus(0,1);
						that.closeOpp(0,1);
						that.closeIssue(0,1);
						that.closeThink(0,1);
						// console.warn('new issue calling submit pt eval');
						that.IssueDiverge(1);
					break;
					case 'New Thinking':
							// console.warn('new think calling submit pt eval');
							that.closePlus(0,1);
							that.closeOpp(0,1);
							that.closeIssue(0,1);
							that.closeThink(0,1);
							that.ThinkDiverge(1);
						break;
				case 'solution':
					that.closePlus(0,1);
					that.closeOpp(0,1);
					that.closeIssue(0,1);
					that.closeThink(0,1);
					this.StDiverge(1);
					break;
				default:
					this.Startedtimode(false,true,'block','#fff');
					break;
			}
		}
		// console.warn(this.Tracking);
		// console.warn(this.backTrackingBtn);
  	}

	//Strengthen with POINt start btn
  	public PtStart(){
  		this.PtId = '0';
		this.edit_PtEvaluation = true;
		this.edit_plus = false;
		this.plus_content = [];
	  	this.plus = [];
	  	this.thinking_content = [];
	  	this.thinking_content[0] = '';
	  	this.opp_content = [];
	  	this.opp_text = [];
	  	this.issue_content = [];
	  	this.issue_text = [];
	  	this.issues = [];
	  	this.opportunities = [];
  	}

  	public open_PtEvaluation(){
  		this.edit_PtEvaluation = true;
  	}

	// add new plus content line
  	public addPlusLine(trackit = 0){	
		if(trackit == 1){
			this.backTrackingBtn = false;
		}
		setTimeout(function(){
			$('.card-body__editcol.hideblk:last-child').css('display','block');
		},50);  	
	  	if(document.activeElement.id){
	  		if(this.plus.length <= parseInt(document.activeElement.id.replace("plus","")) + 1 ){
	  			this.plus.push(this.plus.length);
			  	this.plus_content.push(this.plus_content[this.plus_content.length]);
			  	this.plus_content[this.plus_content.length - 1] = '';
	  		}else{
	  			for(var i=parseInt(document.activeElement.id.replace("plus",""))+1; i<=this.plus.length-1; i++){
	  				if(this.plus_content[i] == '' || this.plus_content[i] == undefined){
	  					document.getElementById("plus"+ (i)).focus();
	  					break;
	  				}
	  				if(i == this.plus.length - 1 && this.plus_content[i] != '' && this.plus_content[i] != undefined){
	  					this.plus.push(this.plus.length);
					  	this.plus_content.push(this.plus_content[this.plus_content.length]);
					  	this.plus_content[this.plus_content.length - 1] = '';
	  				}
	  			}
	  		}
	  	}else{
	  		this.plus.push(this.plus.length);
		  	this.plus_content.push(this.plus_content[this.plus_content.length]);
		  	this.plus_content[this.plus_content.length - 1] = '';
	  	}
		// hide plus line delete icon
		  setTimeout(function(){
			let allBtDelIcon = document.querySelectorAll('.card-body__minus');
			console.log(allBtDelIcon)
			if(allBtDelIcon.length > 1) {
				allBtDelIcon[0].classList.remove('card-body__minus--hide');
			}
		}, 10);
  	}

  	public subPlusLine(i){
	  	this.plus.splice(this.plus.length-1,1);
	  	this.plus_content.splice(i,1);
		this.plus_div_content.splice(i,1);
		setTimeout(function(){
			let allBtDelIcon = document.querySelectorAll('.card-body__minus');
			console.log(allBtDelIcon)
			if(allBtDelIcon.length == 1) {
				allBtDelIcon[0].classList.add('card-body__minus--hide');
			}
		}, 10);
  	}

  	public async PlusOrderChange(event) {
	  	// console.log(event);
	  	moveItemInArray(this.plus_content, event.previousIndex, event.currentIndex);
	  	this.submitPtEvaluation('none');
  	}

  	public async NtOrderChange(event) {
	  	// console.log(event);
	  	transferArrayItem(this.thinking_content[event.previousContainer.id], this.thinking_content[event.container.id], event.previousIndex, event.currentIndex);
	  	// transferArrayItem(this.thinking[event.previousContainer.id], this.thinking[event.container.id], event.previousIndex, event.currentIndex);
	  	// moveItemInArray(this.thinking_content, event.previousIndex, event.currentIndex);
	  	this.submitPtEvaluation('none');
  	}

	// add new opportunity content line
  	public addOppLine(trackit=0){
		if(trackit == 1){
			this.backTrackingBtn = false;
		}
		setTimeout(function(){
			$('.card-body__editcol.hideblk:last-child').css('display','block');
		},50);
	  	if(document.activeElement.id){
	  		if(this.opportunities.length <= parseInt(document.activeElement.id.replace("opp","")) + 1 ){
	  			this.opportunities.push(this.opportunities.length);
			  	this.opp_content.push(this.opp_content[this.opp_content.length]);
			  	this.opp_content[this.opp_content.length - 1] = 'It might...';
			  	this.opp_text.push(this.opp_text[this.opp_text.length]);
			  	this.opp_text[this.opp_text.length - 1] = '';
	  		}else{
	  			for(var i=parseInt(document.activeElement.id.replace("opp",""))+1; i<=this.opportunities.length-1; i++){
	  				if(this.opp_text[i] == '' || this.opp_text[i] == undefined){
	  					document.getElementById("opp"+ (i)).focus();
	  					break;
	  				}
	  				if(i == this.opportunities.length - 1 && this.opp_text[i] != '' && this.opp_text[i] != undefined){
	  					this.opportunities.push(this.opportunities.length);
					  	this.opp_content.push(this.opp_content[this.opp_content.length]);
					  	this.opp_content[this.opp_content.length - 1] = 'It might...';
					  	this.opp_text.push(this.opp_text[this.opp_text.length]);
					  	this.opp_text[this.opp_text.length - 1] = '';
	  				}
	  			}
	  		}
	  	}else{
	  		this.opportunities.push(this.opportunities.length);
		  	this.opp_content.push(this.opp_content[this.opp_content.length]);
		  	this.opp_content[this.opp_content.length - 1] = 'It might...';
		  	this.opp_text.push(this.opp_text[this.opp_text.length]);
		  	this.opp_text[this.opp_text.length - 1] = '';
	  	}
		// show delete icon
		  setTimeout(function(){
			let allBtDelIcon = document.querySelectorAll('.card-body__minus');
			console.log(allBtDelIcon)
			if(allBtDelIcon.length > 1) {
				allBtDelIcon[0].classList.remove('card-body__minus--hide');
			}
		}, 10);
  	}

  	public subOppLine(i){
	  	this.opportunities.splice(this.opportunities.length-1,1);
	  	this.opp_content.splice(i,1);
	  	this.opp_text.splice(i,1);
		this.opp_div_content.splice(i,1)
		// hide opp line delete icon
		setTimeout(function(){
			let allBtDelIcon = document.querySelectorAll('.card-body__minus');
			console.log(allBtDelIcon)
			if(allBtDelIcon.length == 1) {
				allBtDelIcon[0].classList.add('card-body__minus--hide');
			}
		}, 10);
		// let index = this.opportunities.indexOf(this.opportunities[i])
		// // console.log(index)
		// if (index > -1) {
		// 	this.opportunities.splice(index, 1);
		//   }
		// // console.log(this.opportunities[i])
		// // console.log(this.opportunities)
  	}

  	public async OppOrderChange(event) {
	  	// console.log(event);
	  	moveItemInArray(this.opp_content, event.previousIndex, event.currentIndex);
	  	moveItemInArray(this.opp_text, event.previousIndex, event.currentIndex);
	  	this.submitPtEvaluation('none');
  	}

	// add new Issue content line
  	public addIssueLine(trackit = 0){
		if(trackit == 1){
			this.backTrackingBtn = false;
		}
		setTimeout(function(){
			$('.card-body__editcol.hideblk:last-child').css('display','block');
		},50);
	  	if(document.activeElement.id){
	  		if(this.issues.length <= parseInt(document.activeElement.id.replace("issue","")) + 1 ){
	  			this.issues.push(this.issues.length);
			  	this.issue_content.push(this.issue_content[this.issue_content.length]);
			  	this.issue_content[this.issue_content.length - 1] = 'How might...';
			  	this.issue_text.push(this.issue_text[this.issue_text.length]);
			  	this.issue_text[this.issue_text.length - 1] = '';
	  		}else{
	  			for(var i=parseInt(document.activeElement.id.replace("issue",""))+1; i<=this.issues.length-1; i++){
	  				if(this.issue_text[i] == '' || this.issue_text[i] == undefined){
	  					break;
	  				}
	  				if(i == this.issues.length - 1 && this.issue_text[i] != '' && this.issue_text[i] != undefined){
	  					this.issues.push(this.issues.length);
					  	this.issue_content.push(this.issue_content[this.issue_content.length]);
					  	this.issue_content[this.issue_content.length - 1] = 'How might...';
					  	this.issue_text.push(this.issue_text[this.issue_text.length]);
					  	this.issue_text[this.issue_text.length - 1] = '';
	  				}
	  			}
	  		}
	  	}else{
	  		this.issues.push(this.issues.length);
		  	this.issue_content.push(this.issue_content[this.issue_content.length]);
		  	this.issue_content[this.issue_content.length - 1] = 'How might...';
		  	this.issue_text.push(this.issue_text[this.issue_text.length]);
		  	this.issue_text[this.issue_text.length - 1] = '';
	  	}
		// show issue delete icon
		  setTimeout(function(){
			let allBtDelIcon = document.querySelectorAll('.card-body__minus');
			console.log(allBtDelIcon)
			if(allBtDelIcon.length > 1) {
				allBtDelIcon[0].classList.remove('card-body__minus--hide');
			}
		}, 10);
		// if(trackvalue){
		// 	this.Tracking = false;
		// 	$('.card-body__editcol.hideblk').css('display','none');
		// 	// console.log('HIDE');
		// }
  	}

  	public subIssueLine(j, event){
		// console.log(this.thinking_content[0][1]);
		// console.warn(this.issue_div_content.indexOf(this.issue_text[j]));
		// console.warn(this.issue_div_content);
		var itemIndex = this.issue_div_content.indexOf(this.issue_text[j]);
		this.issue_div_content.splice(itemIndex,1)
		// console.warn(this.issue_div_content);

  		for(var i=0; i<this.selected_issue.length; i++){
			if(this.selected_issue[i] == this.issue_content[j] + this.issue_text[j]){
				// console.log(i)
				if(this.thinking_content[i+2].length > 0){
					var remove = confirm("Are you sure, you want to delete this? This cannot be undone!");
					if(remove == true){
					  	this.thinking_content.splice(i+2,1);
	  					this.selected_issue.splice(i,1);
	  					// console.log(this.thinking_content);
	  					this.issues.splice(this.issues.length-1,1);
					  	this.issue_content.splice(j,1);
					  	this.issue_text.splice(j,1);
	  					this.checkPoint();
					}else{
						event.preventDefault();
						break;
					}
				}else{
				  	this.thinking_content.splice(i+2,1);
  					this.selected_issue.splice(i,1);
  					this.issues.splice(this.issues.length-1,1);
				  	this.issue_content.splice(j,1);
				  	this.issue_text.splice(j,1);
  					// console.log(this.thinking_content);
  					// this.submitPtEvaluation('none');
				}  					
			}
			if(i == this.selected_issue.length - 1 && this.selected_issue[i] != this.issue_content[j] + this.issue_text[j]){
				this.issues.splice(this.issues.length-1,1);
				this.issue_content.splice(j,1);
				this.issue_text.splice(j,1);
				// this.submitPtEvaluation('none');
			}
  		}
		// hide issue delete icon
		  setTimeout(function(){
			let allBtDelIcon = document.querySelectorAll('.card-body__minus');
			console.log(allBtDelIcon)
			if(allBtDelIcon.length == 1) {
				allBtDelIcon[0].classList.add('card-body__minus--hide');
			}
		}, 10);
	  	
  	}

  	public async IssueOrderChange(event) {
	  	// console.log(event);
	  	moveItemInArray(this.issue_content, event.previousIndex+1, event.currentIndex+1);
	  	moveItemInArray(this.issue_text, event.previousIndex+1, event.currentIndex+1);
	  	// moveItemInArray(this.thinking_content, event.previousIndex + 2, event.currentIndex + 2);
	  	// moveItemInArray(this.thinking, event.previousIndex + 2, event.currentIndex + 2);
	  	this.submitPtEvaluation('none');
  	}

  	public editPlus(){
  		this.edit_plus = true;
  		// console.log(this.plus_content)
  		this.cancelThinking();
  		this.cancelOpportunity();
  		this.cancelIssues();
  		if(this.plus_content.length < 6){
  			for(var j=this.plus_content.length;j<6;j++){
  				this.plus[j] = '';
	  			this.plus_content[j] = '';
  			}  			
  		}
  	}

	// cancel plus edit
  	public cancelPlus(editmode = 0,trackit = 0){
		this.pin_challenge = false;
		if(editmode == 1){
			this.Tracking = false;
			this.backTrackingBtn = false;
			this.Startedtimode(false,true,'block','#fff');
		}
  		this.edit_plus = false;
  		if(this.PtId == '0'){
  			this.showPlus = false;
  		}else{
	  		this.plus_content = JSON.parse(this.PtEvaluation_content.point.pluses);
	    	this.plus = JSON.parse(this.PtEvaluation_content.point.pluses);
	    	if(this.count_plus > 1){
	    		this.showPlus = true;
	    	}
	    }
		this.pin_challenge = false;
		if(trackit == 1){
			this.Tracking = false;
			this.backTrackingBtn = false;
			this.callto_point();
		}
  	}

	//   close pluses converge view
  	public closePlus(editmode = 0,trackit = 0){
		this.pin_challenge = false;
		// console.log(this.PreviousTracker);
  		this.showPlus = true;
		  if(editmode == 1){
			this.Startedtimode(false,true,'block','#fff');
		}
		if(trackit == 1){
			this.Tracking = false;
			this.backTrackingBtn = false;
			this.callto_point();
		}
	}


  	public removePlus(){
		this.plus = [];
		this.plus_content = [];
		this.plus[0] = ''
		this.plus_content[0] = '';
		this.edit_plus = true;
		this.submitPtEvaluation('none');

  	}

	//   goto next step
	public async gotonextstep(next_step_val) {
		var that = this
		switch(next_step_val){
			case 'New Thinking':
				this.ngOnInit();
				console.log(this.thinking_content)
				setTimeout(() => {
					this.ThinkDiverge(1);
				}, 1000);
				break;
			case 'Opportunities':
				this.OppDiverge(1);
				break;
			case 'Issues':
				this.IssueDiverge(1);
				break;
			case 'Pluses':
				this.PlusDiverge(1);
				break;
			case 'solution':
				this.StDiverge(1);
				break;
			default:
				break;
		}
		
	} 

  	public editOpportunity(){
  		this.edit_opportunity = true;
  		this.opp_content = JSON.parse(this.PtEvaluation_content.point.opportunities);
  		this.opp_content[0] = '';
		this.opp_text[0] = ''; 
    	for(var i=1;i<JSON.parse(this.PtEvaluation_content.point.opportunities).length;i++){
    		let data_str2 = (JSON.parse(this.PtEvaluation_content.point.opportunities)[i]).split("\n");
    		// console.log(data_str2)
    		this.opp_content[i] = data_str2[0];
    		this.opp_text[i] = data_str2[1];
    	}
    	this.selected_opp = JSON.parse(this.PtEvaluation_content.point.opportunities)[0];
	  	this.cancelThinking();
	  	this.cancelIssues();
	  	this.cancelPlus();
	  	if(this.opportunities.length < 6){
	  		for(var j=this.opportunities.length;j<6;j++){
  				this.opp_text[j] = '';
	  			this.opp_content[j] = 'It might...';
	  			this.opportunities[j] = '';
  			}
  		}
  		if(this.opportunities.length == 1){
	  		this.opportunities[1] = ''
	  		this.opp_text[1] = '';
	  		this.opp_content[1] = 'It might...';
  		}
  	}

  	public cancelOpportunity(editmode = 0, trackit = 0){
		this.pin_challenge = false;
		if(editmode == 1){
			this.Tracking = false;
			this.backTrackingBtn = false;
			this.Startedtimode(false,true,'block','#fff');
		}
  		this.edit_opportunity = false;
  		if(this.PtId == '0'){
  			this.showOpp = false;
  		}else{
	  		this.opportunities = JSON.parse(this.PtEvaluation_content.point.opportunities);
			this.opp_content[0] = '';
			this.opp_text[0] = ''; 
	    	for(var opp=1;opp<JSON.parse(this.PtEvaluation_content.point.opportunities).length;opp++){
	    		let data_str3 = (JSON.parse(this.PtEvaluation_content.point.opportunities)[opp]).split("\n");
	    		// console.log(data_str3)
	    		this.opp_content[opp] = data_str3[0];
	    		this.opp_text[opp] = data_str3[1];
	    	}
	    	this.selected_opp = JSON.parse(this.PtEvaluation_content.point.opportunities)[0];
	    	if(this.count_opp > 1){
		  		this.showOpp = true;
		  	}
		}
		this.pin_challenge = false;
		if(trackit == 1){
			this.Tracking = false;
			this.backTrackingBtn = false;
		}
  	}

  	public closeOpp(editmode=0,trackit = 0){
		this.pin_challenge = false;
  		this.showOpp = true;
		this.Tracking = false;
		this.backTrackingBtn = false;
		if(editmode == 1){
			this.Startedtimode(false,true,'block','#fff');
		}
		if(trackit == 1){
			this.Tracking = false;
			this.backTrackingBtn = false;
			this.callto_point();
		}
  	}

  	public removeOpportunity(){
		this.opp_div_content = [];
		this.opportunities = [];
		this.selected_opp = [];
		this.opp_text = [];
		this.opp_content = [];
		this.opp_text[0] = '';
		this.opp_content[0] = '';
		this.edit_opportunity = true;
		this.submitPtEvaluation('none');

  	}

  	public editIssues(){
  		this.edit_issues = true;
    	this.issues = JSON.parse(this.PtEvaluation_content.point.issues);
    	this.issue_content[0] = '';
		this.issue_text[0] = ''; 
    	for(var i=1;i<JSON.parse(this.PtEvaluation_content.point.issues).length;i++){
    		let data_str2 = (JSON.parse(this.PtEvaluation_content.point.issues)[i]).split("\n");
    		// console.log(data_str2)
    		this.issue_content[i] = data_str2[0];
    		this.issue_text[i] = data_str2[1];
    	}
    	this.selected_issue = JSON.parse(this.PtEvaluation_content.point.issues)[0];
	  	this.cancelPlus();
	  	this.cancelThinking();
	  	this.cancelOpportunity();
	  	// console.log(this.issue_content)
  		if(this.issues.length < 6){
	  		for(var j=this.issues.length;j<6;j++){
  				this.issue_text[j] = '';
	  			this.issue_content[j] = 'How might...';
	  			this.issues[j] = '';
  			}

  		}
  		// console.log(this.issues, this.issue_text, this.issue_content)
  	}

  	public cancelIssues(editmode = 0,trackit = 0){
		this.pin_challenge = false;
		if(editmode == 1){
			this.Tracking = false;
			this.backTrackingBtn = false;
			this.Startedtimode(false,true,'block','#fff');
		}
  		this.edit_issues = false; 
  		if(this.PtId == '0'){
  			this.showIssue = false;
  		}else{		
		  	this.issues = JSON.parse(this.PtEvaluation_content.point.issues);	
		  	this.issue_content[0] = '';
			this.issue_text[0] = '';  	
	    	for(var i=1;i<JSON.parse(this.PtEvaluation_content.point.issues).length;i++){
	    		let data_str2 = (JSON.parse(this.PtEvaluation_content.point.issues)[i]).split("\n");
	    		// console.log(data_str2)
	    		this.issue_content[i] = data_str2[0];
	    		this.issue_text[i] = data_str2[1];
	    	}
	    	this.selected_issue = JSON.parse(this.PtEvaluation_content.point.issues)[0];
	    	var think_content = JSON.parse(this.PtEvaluation_content.point.new_thinking);
	  		this.thinking_content = [];
		  	for(var l=0; l<think_content.length; l++){
		  		this.thinking_content[l] = JSON.parse(think_content[l]);
		  		this.thinking[l] = JSON.parse(think_content[l]);
		  	}
		  	for(var tc=0; tc<this.thinking_content[0].length; tc++){
		  		this.thinking_content[0][tc] = this.thinking_content[0][tc].replace("\n",'');
		  	}
		  	for(var i=1; i<this.thinking_content.length; i++){
		  		for(var j=this.thinking_content[i].length;j>=0;j--){
			  		if(this.thinking_content[i][j] == ''){
			  			this.thinking_content[i].splice(j,1);
			  			this.thinking[i].splice(j,1);
			  		}
			  	}
		  	}
		  	if(this.count_issue > 1){
		  		this.showIssue = true;
		  	}
		}
		this.pin_challenge = false;
		if(trackit == 1){
			this.Tracking = false;
			this.backTrackingBtn = false;
			this.callto_point();
		}
  	}

  	public closeIssue(editmode = 0,trackit = 0){
		  // console.warn('close issues')
		this.pin_challenge = false;
  		this.showIssue = true;
		this.Tracking = false;
		this.backTrackingBtn = false;
		if(editmode == 1){
			this.Startedtimode(false,true,'block','#fff');
		}
		if(trackit == 1){
			this.Tracking = false;
			this.backTrackingBtn = false;
			this.callto_point();
		}
  	}

  	public removeIssues(){
		this.issue_div_content = [];
		this.issue_text = [];
		this.issue_content = [];
		this.issues = [];
		this.issue_text[0] = '';
		this.issue_content[0] = '';
		this.edit_issues = true;
		this.selected_issue = [];
		this.thinking_content = [];
		this.ThinkingStartbtn = false;
		this.TotalConvergeIssues = 0;
		this.submitPtEvaluation('none');
  	}

  	public editThinking(){
  		this.edit_thinking = true;
  		// this.edit_issues = false;
  		// this.edit_plus = false;
  		// this.edit_opportunity = false;
  		var think_content = JSON.parse(this.PtEvaluation_content.point.new_thinking);
	  	for(var l=0; l<think_content.length; l++){
	  		this.thinking_content[l] = JSON.parse(think_content[l]);
	  		this.thinking[l] = JSON.parse(think_content[l]);
	  	}
	  	for(var tc=0; tc<this.thinking_content[0].length; tc++){
	  		this.thinking_content[0][tc] = this.thinking_content[0][tc].replace("\n",'');
	  	}
    	this.cancelPlus();
    	this.cancelOpportunity();
    	this.cancelIssues();
    	for(var x=0; x<this.thinking_content[0].length; x++){
    		if(this.thinking_content[x+2]){
		  		if(this.thinking_content[x+2].length < 5){
		  			for(var j=this.thinking_content[x+2].length;j<5;j++){
		  				// this.thinking[j] = '';
			  			this.thinking_content[x+2][j] = '';
			  			this.thinking[x+2][j] = '';
		  			}
		  		}
		  	}else{
		  		this.thinking_content[x+2] = [];
		  		x--;
		  	}
	  	}
  	}

  	public cancelThinking(editmode=0,trackit = 0){
		this.pin_challenge = false;
		if(editmode == 1){
			this.Tracking = false;
			this.backTrackingBtn = false;
			this.Startedtimode(false,true,'block','#fff');
		}
  		this.edit_thinking = false;
  		if(this.PtId == '0'){
  			this.showThink = false;
  		}else{
	  		var think_content = JSON.parse(this.PtEvaluation_content.point.new_thinking);
	  		this.thinking_content = [];
		  	for(var l=0; l<think_content.length; l++){
		  		this.thinking_content[l] = JSON.parse(think_content[l]);
		  		this.thinking[l] = JSON.parse(think_content[l]);
		  	}
		  	for(var tc=0; tc<this.thinking_content[0].length; tc++){
		  		this.thinking_content[0][tc] = this.thinking_content[0][tc].replace("\n",'');
		  	}
		  	for(var i=1; i<this.thinking_content.length; i++){
		  		for(var j=this.thinking_content[i].length;j>=0;j--){
			  		if(this.thinking_content[i][j] == ''){
			  			this.thinking_content[i].splice(j,1);
			  			this.thinking[i].splice(j,1);
			  		}
			  	}
		  	}
		  	if(this.count_think > 0){
		  		this.showThink = true;
		  	}
		}
		this.pin_challenge = false;
		if(trackit == 1){
			this.Tracking = false;
			this.backTrackingBtn = false;
			this.callto_point();
		}
  	}

  	public closeThink(editmode =0,trackit = 0){
		this.pin_challenge = false;
  		this.showThink = true;
		this.Tracking = false;
		if(editmode == 1){
			this.Startedtimode(false,true,'block','#fff');
		}
		if(trackit == 1){
			this.Tracking = false;
			this.backTrackingBtn = false;
			this.callto_point();
		}
  	}


  	public ideateBT(){
  		localStorage.setItem('developToideate','true');
  		this.router.navigate(['/challenges/ideate']);
  	}

	// cancel strengthen
  	public cancelSt(editmode = 0){
		this.comefromimplement = false
		this.pin_challenge = false;
		if(editmode== 1){
			this.Startedtimode(false,true,'block','#fff');
		}
  		this.edit_strengthen = false;
  		var strengthen = this.prev_strengthen_content;
  		this.strengthen_content = '';
  		this.strengthen_content = strengthen;
  		if(this.StId == '0'){
    		this.edit_strengthen = false;
    		this.StId = '';
    		this.strengthen_content = '';
        	this.prev_strengthen_content = '';
    	}
    	if(this.StId != '' && this.StId != '0'){
    		this.showStrength = true;
    	}
  	}

	// check plus items
	public checkPlus(){
		var that = this;
		this.plus_content[0] = [];
	  	// console.log("check plus");
		jQuery(".checkedbox-plus:checkbox").each(function() {
		  var value = jQuery(this).val();
		  if(jQuery(this).prop('checked') == true){
				that.plus_content[0].push(value);
			}
		})
		// console.log(this.plus_content[0])
		this.submitPtEvaluation('plus');
	}

  	// public checkPlus(index){
	// 	for(let i = 0; i <this.plus_content[0].length; i++){
	// 		$('.plusSelectTr' + i).css('display','none');
	// 	}
  	// 	var that = this;
  	// 	this.plus_content[0] = [];
  	// 	jQuery(".checkedbox-plus:checkbox").each(function() {
	//         var value = jQuery(this).val();
	//         if(jQuery(this).prop('checked') == true){
	//   			that.plus_content[0].push(value);
	//   		}
	//   	})
	//   	// console.log(this.plus_content[0])
  	// 	this.submitPtEvaluation('plus');
  	// }

  	public checkOpp(){
  		var that = this;
  		this.selected_opp = [];
		// console.log('test');
  		jQuery(".checkedbox-opp:checkbox").each(function() {
	        var value = jQuery(this).val();
	        // console.log(value);
	        if(jQuery(this).prop('checked') == true){
	  			that.selected_opp.push(value);
	  		}
	  	})
	  	// console.log(this.opp_content[0])
  		this.submitPtEvaluation('opp');
		
		// console.log(this.selected_opp);
  	}

  	public checkThink(){
  		var that = this;
  		this.thinking_content[0] = [];
		// console.log('test');
  		jQuery(".checkedbox-thinking:checkbox").each(function() {
	        var value = jQuery(this).val();
	        if(jQuery(this).prop('checked') == true){
	  			that.thinking_content[0].push(value);
				// console.log(that.thinking_content);
	  		}
	  	})
	  	// console.log(this.thinking_content[0])
  		this.submitPtEvaluation('none');
  	}

	// Converge Issues  
  	public checkIssue(val, content1, content2 ,index , e){
  		// console.log(val);
		var path = e.path || (e.composedPath && e.composedPath());		//(firefox not support e.path direct)
		// console.log(path);
		// console.log("Total Checked" + this.TotalConvergeIssues);
		this.currentIssueElement = path[2];
  		if(val == true){ 
			  for(var i=0; i<this.selected_issue.length; i++){
				  if(this.selected_issue[i] == content1+content2){		  			
  					// console.log(i)
  					if(this.thinking_content[i+2].length > 0){
						  e.preventDefault();
						  this.convIssueThinkData  = this.thinking_content[i+2];
						  // console.log(this.convIssueThinkData);
						  this.issueIndex = e;
						  // console.warn(this.issueIndex)
						  this.checkboxalert();

  						// var remove = confirm("Are you sure, you want to uncheck this? This cannot be undone!");
  						// if(remove == true){
						//   	this.thinking_content.splice(i+2,1);
		  				// 	this.selected_issue.splice(i,1);
		  				// 	// console.log(this.thinking_content);
		  				// 	this.checkPoint();
						// }else{
						// 	e.preventDefault();
						// }
  					}else{
						  if(this.TotalConvergeIssues > 0){
							this.TotalConvergeIssues = this.TotalConvergeIssues - 1;
						}
					  	this.thinking_content.splice(i+2,1);
	  					this.selected_issue.splice(i,1);
	  					//// console.log(this.thinking_content);
	  					this.submitPtEvaluation('issue');
  					}  					
  				}
	  		}
		}else{
			if(this.TotalConvergeIssues < 2){
				// console.log('increase');
				// console.log(this.TotalConvergeIssues)
				this.TotalConvergeIssues = this.TotalConvergeIssues + 1;
				this.selected_issue.push(content1+content2);
				this.submitPtEvaluation('issue');
			}else{
				e.preventDefault();
				Swal.fire({
					title: "Warning",
					text: "You are limited to 2 converged issues. To choose a new one, unselect one of your current choices.",
					type: "warning",
					showCancelButton: false,
					confirmButtonColor: "#DD6B55",
					cancelButtonColor: '##D0D0D0',
					confirmButtonText: "Ok"
				}).then((result) => {
					// if (result['value'] == true) {
					// 	jQuery('.swal2-container').addClass('hide');
					// 	jQuery('body').removeClass('swal2-shown');
					// 	jQuery('html').removeClass('swal2-shown');
					// }else{
						jQuery('.swal2-container').addClass('hide');
						jQuery('body').removeClass('swal2-shown');
						jQuery('html').removeClass('swal2-shown');
					// }
				},function(dismiss){
					jQuery('.swal2-container').addClass('hide');
					jQuery('body').removeClass('swal2-shown');
					jQuery('html').removeClass('swal2-shown');
				});
			}
		}
  	}

  	public copySolution(){
  		this.edit_strengthen = true;
  		this.strengthen_content = this.doing_dropdown + this.doing_content;
  	}

	// add new thinking content
	public addThinkingSolution(i,trackit=0){
		// console.log('i::::' + i);
		if(trackit == 1){
			this.backTrackingBtn = false;
		}
		setTimeout(function(){
			$('.card-body__editcol.hideblk:last-child').css('display','block');
		},50);
		// console.log(document.activeElement.closest('.card-body__editcol'));
		let elem = document.activeElement.closest('.card-body__editcol');
		// console.log(elem);
		if(elem != null){
			if(elem.nextElementSibling){
				let inputElement=document.activeElement.closest('.card-body__editcol').nextElementSibling.getElementsByTagName("input");
				inputElement[0].focus();
			}else{
				this.thinking_content[i].push('');
				this.thinking[i].push('');
			}
		}else{
		this.thinking_content[i].push('');
		this.thinking[i].push('');
		}
	  }

  	public removeThinkingSolution(i,j){
  		this.thinking_content[i].splice(j,1);
  		this.submitPtEvaluation('none');
  	}

	// thinking data delete from this step
  	public removeThinking(){
	  	for(var i=1; i< this.thinking_content.length; i++){
	  		this.thinking_content[i] = [];
	  	}
		this.submitPtEvaluation('none');
		this.ThinkingStartbtn = false
  	}

  	public openDataAction(i,j,e){
  		e.stopPropagation();
  		jQuery('.dotactions__dropdown').css("display", "none");
  		jQuery('.data_action'+i+j).css("display", "block");
  	}

  	public updateCol(i,j,val){
  		jQuery('.move'+i+j).css("display", "none");
		for(var x=0; x<this.thinking_content[0].length; x++){
			if(this.col_update == this.thinking_content[0][x]){
				this.thinking_content[x+2].push(val);
				this.thinking_content[i].splice(j,1);
				this.submitPtEvaluation('none');
			}
		}
  			
  	}

  	public hideDropdown(){
  		jQuery('.dotactions__dropdown').css("display", "none");
  		this.col_update = '';
  	}

  	public openAllCol(i,j,val){
  		jQuery('.dotactions__dropdowninner').css("display", "none");
  		jQuery('.move'+i+j).css("display", "block");
  		this.col_update = val;
  	}

  	public checkThinking(){
  		var that = this;
  		this.thinking_content[1] = [];
  		jQuery(".checkedbox-thinking:checkbox").each(function(i) {
	        var value = jQuery(this).val();
	        if(jQuery(this).prop('checked') == true){
	        	// console.log(value);
	  			that.thinking_content[1].push(i);
	  		}
	  	})
  	}

  	public EditIdeas(){
  		this.router.navigate(['/challenges/ideate']);
  		localStorage.setItem('developToideate','true');
  	}

  	public openPopup(content,showDivCon=0){
		  $('.tooltippopup').addClass('tooltippopup--active');
		  this.process_type = content;
		  this.popup_type = 'info';
		  this.DivergeConvergeVal=false;
		  var that = this;
		  this.allChallenge.getChallenge().then(function (challenge) {
			  // console.log(challenge);
			  that.all_data = challenge;
			  that.all_data['thinkCon'] = JSON.parse(that.all_data['thinkCon'][0]);
			});
		  if(showDivCon == 1){
			  this.DivergeConvergeVal = true;
			  setTimeout(function(){
				  jQuery('.popaccordian__row:nth-child(2) .popaccordian__rowhead').trigger( "click" );
				//   // console.log('clicked');
			  }, 10);
  
		  }
	}


  	public closePopup(){
  		$('.tooltippopup').removeClass('tooltippopup--active');
  		this.process_type = '';
  		this.popup_type = '';
		this.showVideo = false;
  	}

  	public popupDetail(content){
		//  Redirect if user has tools feature 
		// if(content == 'tools'){
		// 	if(this.menu_links.main_menu.tools.toolcards){
		// 		this.router.navigate(['/tools/toolcards/index']);
		// 	}
		// }  
  		this.popup_type = content;
  	}

  	public gotoSection(section){
		  // console.log(section);
  		this.closePopup();
  		$('html,body').animate({
            scrollTop: $('.card-default:nth-child('+section+')').offset().top},
        'slow');
  	}
	  
	// solution pin challenge
	// public stickChallengeSolpin(){
		// this.sol_pin_challenge =!this.sol_pin_challenge;
		// if(pinValue == 1){
		// 	this.sol_pin_challenge = 1;
		// }
		// else{
		// 	this.sol_pin_challenge = 0;
		// }
	// }
	
	//delete data from this step modal
	public DataModel(value){
		// console.log(content)
		this.deleteDatavalue = value;
		$('.cn-dmodal').css('display', 'block');
		this.allChallenge.delData.subscribe((data)=> {
			console.log(data);
			if(data) {
				this.deletedata();
				this.allChallenge.delData.next(false)
			}
		})
		// this.modal = this.modalService.open(content, { windowClass: 'in grey' });
	}

	// delete confirm issues having thinking
	public deleteConfirm() {
		// console.log(this.convIssueThinkData)
		if(this.reset_confirm == 'DELETE'){
			// this.modal.close();
			$('.issue-uncheck-modal').css('display','none')
			// console.log('Delete is running');
			// console.log(this.thinking_content[1]);
			for(var i=1; i< this.thinking_content.length; i++){
				if(this.thinking_content[i] == this.convIssueThinkData){
					this.thinking_content[i] = [];
					for(let j = 0; j<this.convIssueThinkData.length; j++){
						// console.log(this.thinking_content[1].includes(this.convIssueThinkData[j]));
						if(this.thinking_content[1].includes(this.convIssueThinkData[j])){
							let convthinkindex = this.thinking_content[1].indexOf(this.convIssueThinkData[j])
							this.thinking_content[1].splice(convthinkindex,1);
						}
					}
					$(this.currentIssueElement.getElementsByTagName("input")).click();
					// this.submitPtEvaluation('none');
					this.submitPtEvaluation('issue',1,1);
				}
			}
	  }
	  // console.warn(this.thinking_content[1]);
	}

	// delete data from develop based on specific condition
	public deletedata(){
		// this.modal.close();
		$('.cn-dmodal').css('display', 'none');
		var that = this;
		this.Tracking = false;
		this.backTrackingBtn = false;
		switch(this.deleteDatavalue){
			case 1:
				this.delDoing();
				this.Startedtimode(false,true,'block','#fff');
				break;
			case 2:
				this.removePlus();
				this.Startedtimode(false,true,'block','#fff');
				break;
			case 3:
				this.removeOpportunity();
				this.Startedtimode(false,true,'block','#fff');
				break;
			case 4:
				this.removeIssues();
				this.Startedtimode(false,true,'block','#fff');
				break;
			case 5:
				this.removeThinking();
				this.Startedtimode(false,true,'block','#fff');
				break;
			case 6:
				try {
					this.api.DeleteFramework({id:this.PtId}).then(function(){
						that.Ptupdate = false;
						that.PtEvaluation_content = [];
						that.PtId = '';
						that.edit_plus = false;
						that.edit_PtEvaluation = false;
						that.thinking_content = [];
						that.thinking_content[0] = '';
						that.opp_content = [];
						that.opp_text = [];
						that.issue_content = [];
						that.issue_text = [];
						that.plus_content = [];
						that.plus = [];
						that.count_plus = 0;
						that.count_opp = 0;
						that.count_issue = 0;
						that.count_think = 0;
						that.selected_opp = [];
						that.selected_issue = [];
						that.selected_thinking = '';
						if(that.plus_content.length < 5){
							for(var i=that.plus.length;i<5;i++){
								that.plus[i] = ''
								that.plus_content[i] = '';
							}  			
						}
						that.PtStart();
						setTimeout(function() {
						  this.toastr.options = {
							  closeButton: true,
							  progressBar: true,
							  timeOut: 2000
						  };
						  this.toastr.success('Your thoughts has been deleted!', 'Nice!');
					  }, 1000);
					});
				} catch (err) {
					console.log(err)
				}
				this.Startedtimode(false,true,'block','#fff');
				break;
			case 7:
				this.delStrengthen();
				this.Startedtimode(false,true,'block','#fff');
				break;
			default:
				// console.log("error occured");
				break;
		}
	}

	// hide diverge item 
	public eyesHideDiverge(st_point = ''){
		if(this.hideIssueDiv){
			$('.card-body__editcol.hideblk').css('display','block');
			$('.card-body__subtitle span').css('opacity','1');
			this.hideIssueDiv = false;
			this.hideDivLabel = 'Hide unconverged options';
		}else{
			$('.card-body__editcol.hideblk').css('display','none');
			$('.card-body__subtitle span').css('opacity','0.7');
			this.hideIssueDiv = true;
			this.hideDivLabel = 'See all diverged options';
		}
		$('.card-body__editcol.hideblk.hideblk--foreye').css('display','none');
		var all_div_data = document.querySelectorAll('.card-body__editcol');
		for(let item = 1; item<this.plus_content.length;item++){
			if(this.plus_content[item] === ''){
				$(`.card-body__editcol.hideblk:nth-child(${item})`).css('display','none');
				// console.log(all_div_data[item])
			}
		}		
	}

	// slider video hide and show
	public sliderVideo(){
		this.showVideo = !this.showVideo;
	}

	// use when issue converge deselect and it is containing new thinking
	public async checkboxalert() {
		// console.log(content)
        // this.modal = this.modalService.open(content, { windowClass: 'in grey' });
		$('.issue-uncheck-modal').css('display','block');
  	}

	// for starting edit mode
	public Startedtimode(editmode,hideEl,displaynone='none',bgcolor='#EBEBEB',clicked=true){
		// console.log('edtimode active');
		this.editmode = editmode;
		// console.log(this.editmodeService.editmode);
		this.editmodeService.editmode.next(this.editmode);
		// console.log(this.editmodeService.editmode);
		this.hideOneditMode = hideEl;
		// let togglemenu = document.querySelector('.trigger-mini-bar') as HTMLElement;
		// if(clicked){
		// 	togglemenu.click();
		// }	
		// let mainnav = document.querySelector('.row.wrapper') as HTMLElement;
		// let bottomnav = document.querySelector('.mainnav.mainnav--bottom') as HTMLElement;
		let prevnext = document.querySelector('.prevnext') as HTMLElement;
		if(displaynone == 'none'){
			// mainnav.style.display = displaynone;
			// bottomnav.style.display = displaynone;	
			prevnext.style.display = displaynone;
		}else{
			// mainnav.style.display = 'block';
			// bottomnav.style.display = 'flex';	
			prevnext.style.display = 'block';
		}
		let page = document.getElementById('page-wrapper');
		page.style.backgroundColor = bgcolor;
		let footer = document.querySelector('.footer') as HTMLElement;
		footer.style.backgroundColor = bgcolor;
		footer.style.border = '1px solid #CFDBE2'
	}

	// active icon of list view (we are also used this variable for adding class in view)
	public activeIcon(viewtype){
		this.listGridView = viewtype;
		if(this.listGridView == 'gridView'){
			let items = document.querySelectorAll('.EditmodeInputs');
			this.gridViewClass = 'card-body__petopcol--' + items.length;
			// console.log(items.length);
		}
	}

	// pin challenge
	public stickChallenge(thinkval = ''){
		this.pin_challenge = !this.pin_challenge;	
		let headDivHight = $('.info.card__headactive').outerHeight();
		let inputDivTop = $('.info.card__headactive').offset()?.top;
		let winHeight = jQuery(window).outerHeight();
		// console.log(inputDivTop);
		if(this.pin_challenge){
			$('.card-body__backouter-pincontent').height(winHeight - headDivHight- inputDivTop - 180 );
			if(thinkval != 'think') {
				$('.card-body__backouter').height(winHeight - inputDivTop - 250 );	
			}
		}else{
			$('.card-body__backouter-pincontent').height('auto');
			$('.card-body__backouter').height('auto');
		}
	}

	// solution prototype arrow for reducing/increasing height
	public reduceSolutionPro(){
		// console.log('reduce height call')
		let divheight = $('.card-body__finalstate').height();
		divheight = Math.floor(divheight);
		// console.warn(divheight)
		let div = document.querySelector('.card-body__finalstate') as HTMLElement;
		if(!this.reduceSolHeight){
			div.style.height = divheight/2 +'px';
		}else{
			div.style.height = 85 + '%';
		}
		this.reduceSolHeight = !this.reduceSolHeight;
	}

	// top ideas cluster list arrow show and hide list 
	public ShowHideClusterList(i){
		let item = document.getElementById('proposed' + i);
		if(item.classList.contains('hide')){
			item.classList.remove('hide');
			let span = document.querySelectorAll('.TopIdeaList__arrow svg')[i] as HTMLElement;
			span.style.transform = 'rotate(180deg)';
		}else{
			item.classList.add('hide');
			let span = document.querySelectorAll('.TopIdeaList__arrow svg')[i] as HTMLElement;
			span.style.transform = 'rotate(0deg)';
		}
	}

	// develop stage view 
	public stageView(){
		if(this.editmode){
			this.Startedtimode(false,true,'block','#fff');
			this.cancelPlus();
			this.closePlus();
			this.cancelOpportunity();
			this.closeOpp();
			this.cancelIssues();
			this.closeIssue();
			this.cancelThinking();
			this.closeThink();
			this.cancelDoing();
			this.cancelSt();
		}else{
			// console.log("already on stage mode")
		}
	}

	public notingTodo(){
		// console.log("dont have any diverge data")
	}
}