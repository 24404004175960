import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-toolset-guide',
  templateUrl: './toolset-guide.component.html',
  styleUrls: ['./toolset-guide.component.scss']
})
export class ToolsetGuideComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
