import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { environment } from './../../../../environments/environment';

@Component({
    selector: 'app-cig-your-implementer-score',
    templateUrl: './cig-your-implementer-score.component.html',
    styleUrls: ['./cig-your-implementer-score.component.scss']
})
export class CigYourImplementerScoreComponent implements OnInit {

    @Input() profile:Array<Object>;
    @Input() slides;
    @Input() author;
    public env;
    public profiles : any;
    low_preference: any = 'Low Preference';
    high_preference: any = 'High Preference';
    contrast: any = 'Neutral';
    clarify:any = 'Clarify';
    ideate:any = 'Ideate';
    develop:any = 'Develop';
    implement:any = 'Implement';

    constructor() { }

    ngOnInit() {
        this.env=environment;
        this.profiles = this.profile;
    }

    public ngOnChanges( changes: SimpleChanges ) : void {

        if ( changes.profile && ! changes.profile.isFirstChange() ) {
            this.profiles = changes.profile.currentValue;

        }

    }

}
