import { Component, OnInit, Input } from '@angular/core';
import { TeamReportServiceService } from './../team-report-service.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-team-integrator',
  templateUrl: './team-integrator.component.html',
  styleUrls: ['./team-integrator.component.scss']
})
export class TeamIntegratorComponent implements OnInit {
	integratorsTeam:any;
	record:any;
	@Input() members:any;
	clarifierTeam:any;
	env:any;

  	constructor(public service: TeamReportServiceService) { }

  	ngOnInit() {
  		this.env = environment;
  		this.integratorsTeam = this.service.integratorsTeam;
  		this.clarifierTeam = this.service.clarifierTeam;
  	}

}
