import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { TeamReportServiceService } from '../team-report/team-report-service.service';

@Component({
  selector: 'app-orgs-individual',
  templateUrl: './orgs-individual.component.html',
  styleUrls: ['./orgs-individual.component.scss']
})
export class OrgsIndividualComponent implements OnInit {
	id:number;
    teamWizard:boolean;
    org_name:string;
    getOptions:boolean;
    teams:any;
    sessions:any;
    orgData:any;

  	constructor(public activatedRoute:ActivatedRoute, public http: HttpClient, public modalService:NgbModal, public route: Router, private teamService: TeamReportServiceService) { }

  	ngOnInit() {
  		this.activatedRoute.params.subscribe(async (params: Params) => {
        this.id = params.id;
        console.log(this.id);
        this.teamWizard = false;
        this.getOptions = false;
        this.org_name = localStorage.getItem("currentOrgName");
        // this.http.get(`${environment.profileURL}/api/v1/organizations/`+this.id+`.json`).subscribe((data) => {
        //     console.log(data);
        //     this.orgData = data['organization'];
        //     this.org_name = data['organization'].name; 
        //     this.teams = data['organization'].teams;
        //     this.teamService.totalOrgMembers.next(data['organization']?.members_count)

        // },
        // error => console.log('oops', error)
        // )
        // this.http.get(`${environment.profileURL}/api/v1/organizations/`+this.id+`/training_sessions.json`).subscribe(data => {
        //     this.sessions = data['training_sessions']; 
        // },
        // error => console.log('oops', error)
        // )
      })
  	}

    public back() {
      window.history.back();
    }

}
