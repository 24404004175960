import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LmTsService {
	practitioner = [
        {
            type:     'pdf',
            medium:   'Video',
            title:    'Welcome',
            lesson:   'random',
            runtime:   "1:19 mins",
            link:     'qlO0fYzC2Sc',
            data:      'Welcome to your learner map. Let us lead you on a journey toward better collaboration.',
            showinfo:  false,
            parent:   'Start here',
            button:   'Play Video',
            bg:       '',
            target:   "_self"
        },
        {
            type:     '',
            medium:   '',
            title:    'Welcome to Toolset Certification',
            lesson:   'before-course',
            runtime:   "",
            link:     '',
            data:      '',
            showinfo:  false,
            parent:   '',
            button:   '',
            bg:       '',
            target:   "_self",
            date:	  "12/14/2017"
        },
        {
            type:     '',
            medium:   '',
            title:    'Course Fact Sheet',
            lesson:   'before-course',
            runtime:   "",
            link:     '',
            parent:   '',
            score:     true,
            data:      "",
            showinfo:  false,
            button:   '',
            bg:       '',
            target:   "_self",
            date:	  "12/14/2017"
        },
        {
            type:     '',
            title:    'Prework Articles',
            medium:   '',
            runtime:  '',
            lesson:   'before-course',
            link:     '',
            parent:   '',
            score:     true,
            data:      "",
            showinfo:  false,
            button:   '',
            bg:       '',
            target:   "_self",
            date:	  "12/14/2017"
        },
        {
            type:     '',
            title:    "Prework Trainer Self-Evaluation",
            lesson:   'before-course',
            medium:   '',
            runtime:   '',
            link:      '',
            bg:       '',
            parent:   '',
            button:   '',
            data:      "",
            showinfo:  false,
            target:   "_self",
            date:	  "12/14/2017"
        },
        {
            type:     '',
            title:    "Course Agenda",
            lesson:   'during-course',
            medium:   '',
            runtime:  '',
            link:      '',
            bg:        '',
            parent:   '',
            button:   '',
            data:      "",
            showinfo:  false,
            target:   "_self",
            date:	  "02/02/2018"
        },
        {
            type:     '',
            title:    "Teachback Assignment",
            lesson:   'during-course',
            medium:   '',
            runtime:  '',
            link:      '',
            bg:        '',
            parent:   '',
            button:   '',
            data:      "",
            showinfo:  false,
            target:   "_self",
            date:	  "02/02/2018"
        },
        {
            type:     '',
            title:    "Teachback Evaluation",
            lesson:   'during-course',
            medium:   '',
            runtime:  '',
            link:      '',
            bg:        '',
            parent:   '',
            button:   '',
            data:      "",
            showinfo:  false,
            target:   "_self",
            date:	  "02/02/2018"
        },
        {
            type:     '',
            title:    "Conduct Safe Practice",
            lesson:   'after-course',
            medium:   '',
            runtime:  '',
            link:      '',
            bg:        '',
            parent:   '',
            button:   '',
            data:      "",
            showinfo:  false,
            target:   "_self",
            date:	  "02/02/2018"
        },
        {
            type:     '',
            title:    "Give Evaluation Form to Participants",
            lesson:   'after-course',
            medium:   '',
            runtime:  '',
            link:      '',
            bg:        '',
            parent:   '',
            button:   '',
            data:      "",
            showinfo:  false,
            target:   "_self",
            date:	  "02/02/2018"
        },
        {
            type:     '',
            title:    "Upload Safe Practice POINt Evaluation",
            lesson:   'after-course',
            medium:   '',
            runtime:  '',
            link:      '',
            bg:        '',
            parent:   '',
            button:   '',
            data:      "",
            showinfo:  false,
            target:   "_self",
            date:	  "02/02/2018"
        },
        {
            type:     '',
            title:    "Attend Coaching Call",
            lesson:   'ongoing-support',
            medium:   '',
            runtime:  '',
            link:      '',
            bg:        '',
            parent:   '',
            button:   '',
            data:      "",
            showinfo:  false,
            target:   "_self",
            date:	  "02/02/2018"	
        },
        {
            type:     '',
            title:    "Toolset Agendas and Slides",
            lesson:   'ongoing-support',
            medium:   '',
            runtime:  '',
            link:      '',
            bg:        '',
            parent:   '',
            button:   '',
            data:      "",
            showinfo:  false,
            target:   "_self",
            date:	  "02/02/2018"
        },
        {
            type:     '',
            title:    "FourSight Trainer Resources",
            lesson:   'ongoing-support',
            medium:   '',
            runtime:  '',
            link:      '',
            bg:        '',
            parent:   '',
            button:   '',
            data:      "",
            showinfo:  false,
            target:   "_self",
            date:	  "02/02/2018"
        }
    ];


  constructor() { }

  an_slide_no = new BehaviorSubject<number>(0);

}
