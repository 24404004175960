import { Component } from '@angular/core';
import { AccountEmailsComponent } from '../../account-emails/account-emails.component';

@Component({
  selector: 'app-new-profile-popup',
  templateUrl: './new-profile-popup.component.html',
  styleUrls: ['./new-profile-popup.component.scss']
})
export class NewProfilePopupComponent {

  constructor(public accountEmail: AccountEmailsComponent) {}

  public close() {
    $('.new-profile-modal').css('display', 'none');
  } 
}
