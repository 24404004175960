import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cn-card-details-popup',
  templateUrl: './cn-card-details-popup.component.html',
  styleUrls: ['./cn-card-details-popup.component.scss']
})
export class CnCardDetailsPopupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
