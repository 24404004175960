import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { environment } from '../../../environments/environment';
import {CommonDataService} from './../common-data.service';
import { HttpClient } from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AccessGroupService} from './../access-group.service';
import { Auth } from '../../auth/auth.service';
import { Store } from '@ngrx/store';
import { TeamReportServiceService } from '../team-report/team-report-service.service';
// Import the AuthService type from the SDK
// import { AuthService } from '@auth0/auth0-angular';

declare var jQuery:any;

@Component({
  selector: 'app-manage-profiles',
  templateUrl: './manage-profiles.component.html',
  styleUrls: ['./manage-profiles.component.scss']
})

export class ManageProfilesComponent implements OnInit {
  Userprofiles:any;
  totalRecords:number;
  pageLen:number;
  item:any;
  curPage:number;
  isLoading:boolean;
  query_string:string;
  Query_String:string;
  first_name:string;
  last_name:string;
  email:string;
  organizations:any;
  teams:any;
  org:string;
  checkcount:number;
  globalGroup:any;
  showOrgs:boolean;
  showTeams:boolean;
  showSource:boolean;
  showOwner:boolean;
  showEmail:boolean;
  parentOrg:boolean;
  childOrg:boolean;
  groups:any;
  modal3:any;
  ty:string;
  showAll:any;
  lastWeek:any;
  lastDay:any;
  lastMonth:any;
  fromdate:any;
  toDate:any;
  profiles:any;
  all_teams:string;
  source_team:string;
  resetAll:boolean;
  Email:any;
  itemsByPage:number;
  getOptions:boolean;
  env:any;
  pageTitle:string;
  default_opt:boolean;
  add_Session:any;
  sessions:any;
  orgid:any;
  OrgsTeams:any;
  orgWizard:any;
  teamWizard:any;
  sessionWizard:any;
  deleteIds:any;
  ngrxAccessGroup:any;

  constructor(public accessgroup:AccessGroupService, public service: CommonDataService, public http: HttpClient, public modalService:NgbModal, private router: Router,public auth: Auth,public store: Store<any>, public teamReportService: TeamReportServiceService) {

    }
    ngOnInit() {
        this.ngrxAccessGroup = this.store.select<any>((state) => state.profile.accessGroupData);
        this.pageTitle = 'Members';
        this.env = environment;
          // it re-directs url to the dashboard page if access to this page is not permitted.
        this.ngrxAccessGroup.subscribe(message => {
            if(message && message.length > 0){
                if(!message['main_menu'].manage_profiles){
                    this.router.navigate(['/dashboard']);
                    return false;
                }
            }

        },
        err => {
            // Log errors if any
            console.log(err);
        })
        this.resetAll = false;
        this.first_name = '';
        this.last_name = '';
        this.email = '';
        this.toDate = '';
        this.fromdate = '';
        this.profiles = '';
        this.all_teams = '';
        this.org = '';
        this.source_team = '';
        var today = new Date();
        this.lastWeek = this.formatDate(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7));
        this.lastDay = this.formatDate(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1));
        var lastmonth = today;
        lastmonth.setMonth(today.getMonth() - 1);
        this.lastMonth = this.formatDate(lastmonth);

        this.ngrxAccessGroup.subscribe(message => {
            if(message && message.length > 0){
                if(!message['main_menu'].manage_profiles){
                    this.router.navigate(['/dashboard']);
                    return false;
                }
            }
        },
        err => {
            // Log errors if any
            console.log(err);
        })
        this.itemsByPage = 20;
        this.parentOrg = true;
        this.childOrg = true;
        this.globalGroup = [];
        this.checkcount = 0;
        this.showOrgs = true;
        this.showTeams = true;
        this.showSource = true;
        this.showOwner = true;
        this.showEmail = true;
        this.query_string = environment.profileURL+`/api/v1/users/members.json?per_page=`+this.itemsByPage;
        this.Query_String = this.query_string;
        this.isLoading = true;
      	this.http.get(`${this.query_string}`).subscribe(data => {
            console.log(data);
            this.Userprofiles = data['members'];
            this.totalRecords = data['meta'].total_count;
            this.pageLen = data['meta'].total_pages;
            console.log(this.pageLen);
            this.curPage = 0;
            this.item = [];
            this.isLoading = false;
            for(var i = 1; i<=this.pageLen; i++){
        	    this.item.push(i);
        	}
            if(this.totalRecords > this.itemsByPage){
                this.render( this.curPage, this.item, true );
                console.log(data);
            }
        },
            error => console.log('oops', error)
        )
        this.http.get(`${environment.profileURL}/api/v1/organizations.json?per_page=100&name_order=asc`).subscribe(data => {
          console.log(data);
          this.organizations = data['organizations'];
        },
            error => console.log('oops', error)
        )
        this.http.get(`${environment.profileURL}/api/v1/groups.json`).subscribe(data => {
          this.groups = data['groups'];
        },
            error => console.log('oops', error)
        )
        this.auth.getProfile((err, profile) => {
            this.Email = profile.email;
        })
        
        // this.auth.getUser().subscribe(user => {
        //     this.Email = user.email;
        // });

        localStorage.setItem('globalGroups','');

    }

    public isPageInRange = function( curPage, index, maxPages, pageBefore, pageAfter ) {
        if (index <= 1) {
	        // first 2 pages
	        return true;
        }
        if (index >= maxPages - 2) {
	        // last 2 pages
	        return true;
        }
        if (index >= curPage - pageBefore && index <= curPage + pageAfter) {
	        return true;
        }
	   }

	public render = function( curPage, item, first ) {
        var html = '', separatorAdded = false;
        for(var i in item){
            if ( this.isPageInRange( curPage, i, this.pageLen, 2, 2 ) ) {
                html += '<li data-page="' + i + '">' + item[i] + '</li>';
                // as we added a page, we reset the separator Added
                separatorAdded = false;
            } else {
                if (!separatorAdded) {
                    // only add a separator when it wasn't added before
                    html += '<li class="separator" />';
                    separatorAdded = true;
                }
            }
        }

        var holder = document.querySelector('#holder');
        if(this.totalRecords > this.itemsByPage){
            holder.innerHTML = html;
            if(document.querySelector('#holder>li[data-page="' + curPage + '"]') != null){document.querySelector('#holder>li[data-page="' + curPage + '"]').classList.add('active');}
            if ( first ) {
                holder.addEventListener('click', e => {
                    console.log(e.target['attributes']['data-page'].value);
                    if (!e.target['attributes']['data-page'].value) {
                        // no relevant item clicked (you could however offer expand here )
                        return;
                    }

                    curPage = parseInt(e.target['attributes']['data-page'].value);
                    var page_number = curPage+1;
                    this.isLoading = true;
                    this.http.get(`${this.query_string}&page=${page_number}`).subscribe(data => {
                        jQuery('#select_all').prop('checked',false);
                        this.Userprofiles = data['members'];
                        this.totalRecords = data['meta'].total_count;
                        this.pageLen = data['meta'].total_pages;
                        this.curPage = 0;
                        this.item = [];
                        this.isLoading = false;
                        for(var i = 1; i<=this.pageLen;i++){
                            this.item.push(i);
                        }

                    },
                        error => console.log('oops', error)
                    )
                    this.render( curPage, item );
                });
            }
        }else{
            holder.innerHTML = null;
        }
    }

    public filter(){
        // this.globalGroup = [];
        this.Userprofiles = [];
        this.query_string = this.Query_String;

        let checkValue = ()=> {
            return ((!this.first_name) && (!this.last_name) && (!this.email) && (!this.profiles) && (!this.org) && (!this.all_teams) && (!this.source_team) && (!this.fromdate) && (!this.toDate));
        }

        if(checkValue()){
            this.resetAll = false;
        }else{
            this.resetAll = true;
        }

        if(this.first_name){
            this.query_string += '&first_name=' + this.first_name;
        }
        if(this.last_name){
            this.query_string += '&last_name=' + this.last_name;
        }
        if(this.email){
            this.query_string += '&email=' + this.email;
        }
        if (this.profiles) {
            this.query_string += '&profile_type_id=' + this.profiles;
        }
        if (this.org) {
            this.fetchTeams(this.org);
            this.query_string += '&organization_id=' + this.org;
        }
        if (this.all_teams) {
            this.query_string += '&team_id=' + this.all_teams;
        }
        if(this.source_team){
            this.query_string += '&source_team_structure=' + this.source_team;
        }
        if(this.fromdate){
            this.query_string += '&from_date=' + this.fromdate;
        }

        if(this.toDate){
            this.query_string += '&to_date=' + this.toDate;
        }

        this.isLoading = true;
        console.log(this.query_string);
        this.http.get(`${this.query_string}`).subscribe(data => {
            jQuery('#select_all').prop('checked', false);
            // this.checkcount = 0;
            this.Userprofiles = data['members'];
            this.totalRecords = data['meta'].total_count;
            this.pageLen = data['meta'].total_pages;
            this.curPage = 0;
            this.item = [];
            console.log(this.Userprofiles);
            this.isLoading = false;
            for(var i = 1; i <= this.pageLen; i++){
                this.item.push(i);
            }
            this.render( this.curPage, this.item, true );
            console.log(data);
        },
            error => console.log('oops', error)
        )
    }

    public length1(){
        if(this.first_name.length >= 3 || this.first_name.length == 0){
            this.filter();
        }
    }

    public length2(){
        if(this.last_name.length >= 3 || this.last_name.length == 0){
            this.filter();
        }
    }

    public length3(){
        if(this.email.length >= 3 || this.email.length == 0){
            this.filter();
        }
    }

    public length4(){
        if(this.source_team.length >= 3 || this.source_team.length == 0){
            this.filter();
        }
    }

    public resetFirstName() {
        this.first_name = '';
        this.filter();
    }

    public resetLastName() {
        this.last_name = '';
        this.filter();
    }

    public resetEmail() {
        this.email = '';
        this.filter();
    }

    public resetSourceTeam() {
        this.source_team = '';
        this.filter();
    }

    public fetchTeams(id){
        this.http.get(`${environment.profileURL}/api/v1/organizations/` + id + '/teams.json').subscribe(data => {
            console.log(data);
            this.teams = data['teams'];
        },
            error => console.log('oops', error)
        )

    }

    public checkedbox(){
        var that = this
        jQuery(".checkedbox:checkbox").each(function() {
            var value = jQuery(this).val();
            if(jQuery("input[value="+ value +"]").prop("checked") == true){
                var present = 0;
                for(var j=0;j<that.globalGroup.length;j++){
                    if(that.globalGroup[j].id == value){
                        present = 1;
                    }
                }
                if(present == 0){
                    for(var i=0;i<that.Userprofiles.length;i++){
                        if(that.Userprofiles[i].id == value){

                            that.checkcount += 1;
                            that.globalGroup.push({
                                id: that.Userprofiles[i].id,
                                name: that.Userprofiles[i].identities.first_name + ' ' + that.Userprofiles[i].identities.last_name,
                                email: that.Userprofiles[i].identities.email,
                                organizations: that.Userprofiles[i].organizations,
                                default_profile: that.Userprofiles[i].default_profile
                            });
                            that.teamReportService.teamSelectedArr.push({
                                id: that.Userprofiles[i].id,
                                name: that.Userprofiles[i].identities.first_name + ' ' + that.Userprofiles[i].identities.last_name,
                                email: that.Userprofiles[i].identities.email,
                                organizations: that.Userprofiles[i].organizations,
                                default_profile: that.Userprofiles[i].default_profile
                            });
                        }
                    }
                }
               console.log(that.globalGroup);
            }else{
                for(var j=0;j<that.globalGroup.length;j++){
                    if(that.globalGroup[j].id == value){
                        that.globalGroup.splice(j,1);
                        that.checkcount -= 1;
                    }
                }
            }

        })
    }

    public checkAll(){
        var select = jQuery('#select_all:checkbox:checked').length > 0;
        if(select == true){
            jQuery('.checkedbox').prop('checked',true);
            this.checkedbox();
        }
        else{
            jQuery('.checkedbox').prop('checked',false);
            this.checkedbox();
        }
    }

    public fromdateFormat(){
        var fromDate = jQuery('#fromDate').val();
        if(fromDate){
            this.filter();
        }
    }

    public todateFormat(){
        var toDate = jQuery('#toDate').val();
        if(toDate){
            this.filter();
        }
    }

    public sorting(column, order){
        this.Query_String=environment.profileURL+`/api/v1/users/members.json?per_page=`+this.itemsByPage;
        if(column == 'firstName'){
            if(order == 'asc'){
                this.Query_String += '&first_name_order=asc';
                this.filter();
                $('.fname-asc').addClass('hide');
                $('.fname-desc').removeClass('hide');
            }
            else{
                this.Query_String += '&first_name_order=desc';
                this.filter();
                $('.fname-asc').removeClass('hide');
                $('.fname-desc').addClass('hide');
            }
        }
        if(column == 'lastName'){
            if(order == 'asc'){
                this.Query_String += '&last_name_order=asc';
                this.filter();
                $('.lname-asc').addClass('hide');
                $('.lname-desc').removeClass('hide');
            }
            else{
                this.Query_String += '&last_name_order=desc';
                this.filter();
                $('.lname-asc').removeClass('hide');
                $('.lname-desc').addClass('hide');
            }
        }
        if(column == 'organization'){
            if(order == 'asc'){
                this.Query_String += '&organization_id_order=asc';
                this.filter();
                $('.org-asc').addClass('hide');
                $('.org-desc').removeClass('hide');
            }
            else{
                this.Query_String += '&organization_id_order=desc';
                this.filter();
                $('.org-asc').removeClass('hide');
                $('.org-desc').addClass('hide');
            }
        }
        if(column == 'teams'){
            if(order == 'asc'){
                this.Query_String += '&team_id_order=asc';
                this.filter();
                $('.team-asc').addClass('hide');
                $('.team-desc').removeClass('hide');
            }
            else{
                this.Query_String += '&team_id_order=desc';
                this.filter();
                $('.team-asc').removeClass('hide');
                $('.team-desc').addClass('hide');
            }
        }
        if(column == 'email'){
            if(order == 'asc'){
                this.Query_String += '&email_order=asc';
                this.filter();
                $('.email-asc').addClass('hide');
                $('.email-desc').removeClass('hide');
            }
            else{
                this.Query_String += '&email_order=desc';
                this.filter();
                $('.email-asc').removeClass('hide');
                $('.email-desc').addClass('hide');
            }
        }
        if(column == 'profile'){
            if(order == 'asc'){
                this.Query_String += '&profile_type_order=asc';
                this.filter();
                $('.profile-asc').addClass('hide');
                $('.profile-desc').removeClass('hide');
            }
            else{
                this.Query_String += '&profile_type_order=desc';
                this.filter();
                $('.profile-asc').removeClass('hide');
                $('.profile-desc').addClass('hide');
            }
        }
        if(column == 'page'){
            this.filter();
        }
    }

    public chooseOption(){
        if(this.checkcount > 0){
            this.pageTitle = 'Members Wizard';
            this.getOptions = true;
            this.default_opt = true;
            var that = this;

            setTimeout(function() {
                jQuery(document).ready(function(e){
                    jQuery('input[name="member"]').iCheck({
                        checkboxClass: 'icheckbox_square-green',
                        radioClass: 'iradio_square-green',
                    });

                    jQuery('input[name="member"]').on('ifChecked', function(event){
                        jQuery('div.inner-single-box').removeClass('border-green');
                        jQuery(this).closest('div.inner-single-box').addClass('border-green');
                    });
                });
            },0);
        }
    }

    public moreTeams(record){
        this.ty = 'team';
        this.showAll = record;
        // this.modal3 = this.modalService.open(content, { windowClass: 'in grey' });
        $('.org-team-modal').css('display','block');
    }


    public close() {
        $('.org-team-modal').css('display', 'none');
    }

    public moreOrgs(record){
        this.ty = 'org';
        this.showAll = record;
        console.log(this.showAll);
        $('.org-team-modal').css('display','block');
    }

    public getLastDay(){
        this.fromdate = this.lastDay;
        console.log(this.fromdate);
        this.filter();
    }

    public getLastWeek(){
        this.fromdate = this.lastWeek;
        this.filter();
    }

    public getLastMonth(){
        this.fromdate = this.lastMonth;
        this.filter();
    }

    public formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    public reset() {
        this.Userprofiles = [];
        this.ngOnInit();
    }

    public advanceOptions(){
        localStorage.setItem('globalGroups',JSON.stringify(this.globalGroup));
        this.router.navigate(['/manage-members']);
    }

}
