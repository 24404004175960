import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Location } from '@angular/common'

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss']
})

export class MemberComponent implements OnInit {
	id:number;
	rightUser:any;

  	constructor(public activatedRoute:ActivatedRoute, public http: HttpClient, public location:Location) { }

  	ngOnInit() {
  		this.activatedRoute.params.subscribe((params: Params) => {
            this.id = params.id;
            console.log(this.id);
        })
        this.http.get(`${environment.profileURL}/api/v1/members/`+this.id+`.json`).subscribe(data => {
           this.rightUser = data['member']; 
           console.log(data);
        },
            error => console.log('oops', error)
        )
  	}
  	cancel() {
  	this.location.back(); // <-- go back to previous location on cancel
	}

}
