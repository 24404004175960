import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LmNewPricingOrganizationalService {

	practitioner = [
        {
            type:     'pdf',
            medium:   'Video',
            title:    'Welcome',
            lesson:   'random',
            runtime:   "1:19 mins",
            link:     'qlO0fYzC2Sc',
            data:      'Welcome to your learner map. Let us lead you on a journey toward better collaboration.',
            showinfo:  false,
            parent:   'Start here',
            button:   'Play Video',
            bg:       '',
            target:   "_self"
        },
        {
            type:     'video',
            medium:   'Watch Video',
            title:    'New Pricing Model',
            lesson:   'new-pricing',
            runtime:  "2 mins",
            link:     '0R6xwxjzQrI',
            data:     'New Pricing Model<br/>Why the new pricing model? And what does it mean to me? FourSight Managing Partner Sarah Thurber offers a quick introduction to the new FourSight Pricing Model and explains the elements of this learner map.',
            showinfo: false,
            parent:   '',
            button:   '',
            bg:       '',
            target:   "_self",
            BeginDateTime: '2018-12-15T24:00:00',
            EndDateTime: 'infinity',
        },
        {
            type:     'pdf',
            title:    "Earned Discounts",
            lesson:   'the-details',
            medium:   'Read',
            runtime:  '5 mins',
            link:      '',
            bg:        'dollar_bulb.png',
            parent:   '',
            button:   '',
            data:      "Earn discounts for Organizational Faciltators. Here you will find the information on how you might start earning discounts with FourSight.<br/>Download the FAQs and pricing schedule now.<br/><br/><a class='btn btn-xs btn-primary' href='https://www.dropbox.com/s/7kjtys8tegurj1l/FAQ_ORG.pdf?dl=0' target='_blank'>Download Organizational earned pricing schedule now</a>",
            showinfo:  false,
            BeginDateTime: '2018-12-15T24:00:00',
            EndDateTime: 'infinity',
            target:   "_self"
        },
        {
            type:     'form',
            title:    "Signed Agreement",
            lesson:   'to-participate',
            medium:   'Read',
            runtime:  '2 mins',
            link:      '',
            bg:        '',
            parent:   '',
            button:   '',
            data:      "To start getting your discounts under the new pricing model, you must download, sign and return the new FourSight agreement.<br/><br/><a class='btn btn-xs btn-primary' href='' target='_blank'>Download PDF</a>",
            showinfo:  true,
            target:   "_self",
            BeginDateTime: '2018-12-15T24:00:00',
            EndDateTime: 'infinity'
        },
        {
            type:     'survey',
            title:    "Vote and Read Other's Feedback",
            lesson:   'tell-us',
            medium:   'Survey Online',
            runtime:  '4 mins',
            link:      '',
            bg:        '',
            parent:   '',
            button:   '',
            data:      "Please vote and share your feedback on FourSight's new pricing model.",
            showinfo:  false,
            BeginDateTime: '2018-12-15T24:00:00',
            EndDateTime: 'infinity',
            target:   "_self"
        }
    ];

  constructor() { }

} 
