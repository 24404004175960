import { Injectable } from '@angular/core';
import { throwError } from "rxjs";
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccessGroupService {
	constructor(public http: HttpClient) { }
   
    data()  {
        return this.http.get(`${environment.profileURL}/api/v1/users/access_groups `).pipe(map(data => {
            console.log('getching data',data);
            if(data['main_menu']){
                if(data['main_menu'].shop){
                    localStorage.setItem('store','Custom Store');
                }else{
                    localStorage.setItem('store','Store');
                }
                return (data);
            }else{
                localStorage.setItem('store','Store');
            }
        }))
    }
    private handleError(error: Response) {
        return throwError(error.statusText);
    }

}
