// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper-content {
  padding: 20px 10px 40px;
}
.wrapper-content.dashboard-column .fade-columns .f-col-6 .ibox {
  position: relative;
  opacity: 0.8;
}
.wrapper-content.dashboard-column .fade-columns .f-col-6 .ibox:before {
  background: #f3f3f3;
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0.5;
  display: block;
}

.ibox-title .label-primary,
.ibox-content .image-youtube {
  cursor: pointer;
}

.lm-flexbox {
  display: flex;
  display: -moz-flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
}

.lrnr-mps h2 {
  font-size: 22px;
  margin-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/learner-maps-listing/learner-maps-listing.component.scss"],"names":[],"mappings":"AAAA;EACC,uBAAA;AACD;AAGI;EACI,kBAAA;EACA,YAAA;AADR;AAEQ;EACC,mBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;EACA,cAAA;AAAT;;AAQA;;EAEE,eAAA;AALF;;AAQA;EACE,aAAA;EAEA,kBAAA;EACA,oBAAA;EACA,eAAA;EACA,uBAAA;EACA,oBAAA;AALF;;AAUE;EACE,eAAA;EACA,aAAA;AAPJ","sourcesContent":[".wrapper-content {\n\tpadding:20px 10px 40px;\n\t&.dashboard-column{\n\t\t.fade-columns{\n\t\t\t.f-col-6{\n\t\t\t\t.ibox {\n\t\t\t\t    position: relative;\n\t\t\t\t    opacity: 0.8;\n\t\t\t\t    &:before {\n\t\t\t\t\t    background: #f3f3f3;\n\t\t\t\t\t    content: \"\";\n\t\t\t\t\t    height: 100%;\n\t\t\t\t\t    width: 100%;\n\t\t\t\t\t    position: absolute;\n\t\t\t\t\t    opacity: 0.5;\n\t\t\t\t\t    display: block;\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n\n.ibox-title .label-primary,\n.ibox-content .image-youtube {\n  cursor: pointer;\n}\n\n.lm-flexbox {\n  display: flex;\n  display: -webkit-flex;\n  display: -moz-flex;\n  display: -ms-flexbox;\n  flex-wrap: wrap;\n  -webkit-flex-wrap: wrap;\n  -moz-flex-wrap: wrap;\n}\n\n.lrnr-mps {\n\n  h2 {\n    font-size: 22px;\n    margin-top: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
