import { Component, Input } from '@angular/core';
import { AssociatedEmailsComponent } from '../../associated-emails/associated-emails.component';

@Component({
  selector: 'app-link-new-email-popup',
  templateUrl: './link-new-email-popup.component.html',
  styleUrls: ['./link-new-email-popup.component.scss']
})
export class LinkNewEmailPopupComponent {

  @Input() profilemail;

  constructor(public associateEmailCompo: AssociatedEmailsComponent) {}

  ngOnInit(): void {
    console.log('link new email');
  }

  public close() {
    $('.link-email-modal').css('display', 'none');
  }


}
