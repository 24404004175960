import { Component, OnInit, Input } from '@angular/core';
import { TeamReportServiceService } from './../team-report-service.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-team-clarifier',
  templateUrl: './team-clarifier.component.html',
  styleUrls: ['./team-clarifier.component.scss']
})
export class TeamClarifierComponent implements OnInit {
	clarifierTeam:any;
	item:any;
	@Input() members:any;
	env:any;

  	constructor(public service: TeamReportServiceService) { }

  	ngOnInit() {
  		this.clarifierTeam = this.service.clarifierTeam;
  		this.env = environment;
  	}

}
