import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-hierarchical-view',
  templateUrl: './hierarchical-view.component.html',
  styleUrls: ['./hierarchical-view.component.scss']
})
export class HierarchicalViewComponent {
  clarifier_low:number = 0;
  clarifier_high:number = 0;
  ideator_low:number = 0;
  ideator_high:number = 0;
  developer_low:number = 0;
  developer_high:number = 0;
  implementor_low:number = 0;
  implementor_high:number = 0;
  integrator:number = 0;
  highest_score:number = 0;
  total_members:number = 0;
  @Input() members;
  profileValues:boolean = false;

  constructor(public activatedRoute:ActivatedRoute,public router: Router) {}


  async ngOnInit() {
    $('.graph-loader').css('display','block');
    console.log('====members====>',this.members, this.router.url)
    this.total_members = this.members?.length;
      // this.showTeam();
    this.members?.forEach(list => {
          if(list?.default_profile){
              if(list?.default_profile?.clarifier_score == 'Low'){
                  this.clarifier_low = this.clarifier_low + 1;
                  if( this.clarifier_low > this.highest_score ){
                      this.highest_score = this.clarifier_low;
                  }
              }

              if(list?.default_profile?.clarifier_score == 'High'){
                  this.clarifier_high = this.clarifier_high + 1;
                  if( this.clarifier_high > this.highest_score ){
                      this.highest_score = this.clarifier_high;
                  }
              }

              if(list?.default_profile.ideator_score == 'Low'){
                  this.ideator_low = this.ideator_low + 1;
                  if( this.ideator_low > this.highest_score ){
                      this.highest_score = this.ideator_low;
                  }
              }

              if(list?.default_profile?.ideator_score == 'High'){
                  this.ideator_high = this.ideator_high + 1;
                  if( this.ideator_high > this.highest_score ){
                      this.highest_score = this.ideator_high;
                  }
              }

              if(list?.default_profile.developer_score == 'Low'){
                  this.developer_low = this.developer_low + 1;
                  if( this.developer_low > this.highest_score ){
                      this.highest_score = this.developer_low;
                  }
              }

              if(list?.default_profile?.developer_score == 'High'){
                  this.developer_high = this.developer_high + 1;
                  if( this.developer_high > this.highest_score ){
                      this.highest_score = this.developer_high;
                  }
              }

              if(list?.default_profile?.implementer_score == 'Low'){
                  this.implementor_low = this.implementor_low + 1;
                  if( this.implementor_low > this.highest_score ){
                      this.highest_score = this.implementor_low;
                  }
              }

              if(list?.default_profile?.implementer_score == 'High'){
                  this.implementor_high = this.implementor_high + 1;
                  if( this.implementor_high > this.highest_score ){
                      this.highest_score = this.implementor_high;
                  }
              }

              if(list?.default_profile?.clarifier_score == 'Neutral' && list.default_profile.ideator_score == 'Neutral' && list.default_profile.developer_score == 'Neutral' && list.default_profile.implementer_score == 'Neutral'){
                  this.integrator += 1;
              }
          }

      });
      $('.graph-loader').css('display','none');
    }
}
