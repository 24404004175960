import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { APIService } from '../../../API.service';
import { CnEditmodeService } from '../challenges_service/cn-editmode.service';
import { ChallengeSummaryService } from '../../challenge-summary.service';

declare var jQuery: any;

@Component({
	selector: 'app-warm-up',
	templateUrl: './warm-up.component.html',
	styleUrls: ['./warm-up.component.scss']
})

export class WarmUpComponent implements OnInit {

	brainstorm: any = [0];
	brainstorm_content: any = [];
	prev_brainstorm_content: any = [];
	edit_brainstorm: boolean = false;
	checked_brainstorm: any = [];
	showBrainstorm: boolean = true;
	private trackIds: any = [];
	proposed_content: any = [];
	array_proposed_content: any = [];
	column_name: String = '';
	col_update: String;
	edit_column: number = 0;
	old_column: String;
	loading_brainstorm: boolean = false;
	open_question: String = '';
	new_question: String = '';
	save_question: boolean = false;
	edit_question: boolean = false;
	process_type: string;
	popup_type: string;
	modal: any;
	hideOnEditMode: boolean = false;
	edit_topic_view: boolean = true;
	edit_option_view: boolean = true;
	edit_top_opiton_view: boolean = true;
	pin_challenge: boolean = false;
	cluster_btn: boolean = true;
	question_dropdown: string = '';
	PreviousTracker: string = '';
	currentTracker: string = '';
	trackLabel: boolean = false;
	hideDivLabel:string = 'See all diverged options';
	hideDiv:boolean= true;

	constructor(private api: APIService, public router: Router, private allChallenge: ChallengeSummaryService, public editmodeService: CnEditmodeService) { }

	ngOnDestroy() {
		// sidebar
		let togglemenu = document.querySelector('.trigger-mini-bar') as HTMLElement;
		if (togglemenu.classList.contains('squeeze')) {
			togglemenu.click();
			togglemenu.classList.remove('squeeze');
		}
		this.openEditmode(true, true, true, true, 'block', '#fff');
	}

	ngOnInit() {
		// sidebar
		let togglemenu = document.querySelector('.trigger-mini-bar') as HTMLElement;
		if(!togglemenu.classList.contains('squeeze')){
			togglemenu.click();
			togglemenu.classList.add('squeeze');
		}

		console.log(this.brainstorm_content.length)
		// this.edit_brainstorm = true;
		this.brainstorm_content[0] = [];
		this.brainstorm[0] = '';
		for (var i = 1; i < 16; i++) {
			this.brainstorm_content[i] = '';
			this.brainstorm[i] = '';
		}

		this.proposed_content[0] = [];
		this.proposed_content[1] = [];
		this.unassignedIdeas();

		var brainstorm = this.brainstorm_content;
		this.prev_brainstorm_content = [];
		// for(var j=0;j<brainstorm.length;j++){
		// 		let data_str = brainstorm[j];
		// 		this.prev_brainstorm_content[j] = data_str;
		// 	}

		jQuery(document).click(function (event) {
			console.log(event);
			$("body").click(function () {
				$(".dotactions__dropdown").hide();
			});

			$(".dotactions__top, .dotactions__dropdown").click(function (e) {
				e.stopPropagation();
			});

		});
		jQuery(document).on("click", ".popaccordian__rowhead", function (e) {
			e.stopPropagation();
			var thisPar = jQuery(this).parents(".popaccordian__row");


			if (!jQuery(thisPar).hasClass("popaccordian__row--active")) {
				thisPar.find(".popaccordian__rowbody").slideDown();
				thisPar.siblings().find(".popaccordian__rowbody").slideUp();
				thisPar.addClass("popaccordian__row--active");
				thisPar.siblings().removeClass("popaccordian__row--active");
			} else {
				thisPar.siblings().find(".popaccordian__rowbody").slideUp();
			}
		})

		jQuery(document).click(function (event) {
			//console.log(event);
			$("body").click(function () {
				$(".dotactions__dropdown").hide();
			});

			$(".dotactions__top, .dotactions__dropdown").click(function (e) {
				e.stopPropagation();
			});

		});
		jQuery(document).on("click", ".popaccordian__rowhead", function (e) {
			e.stopPropagation();
			var thisPar = jQuery(this).parents(".popaccordian__row");


			if (!jQuery(thisPar).hasClass("popaccordian__row--active")) {
				thisPar.find(".popaccordian__rowbody").slideDown();
				thisPar.siblings().find(".popaccordian__rowbody").slideUp();
				thisPar.addClass("popaccordian__row--active");
				thisPar.siblings().removeClass("popaccordian__row--active");
			} else {
				thisPar.siblings().find(".popaccordian__rowbody").slideUp();
			}
		})
	}

	public checkCluster(i, e, val) {
		if (val == 'Cluster ' + i) {
			var check = confirm("Please restate your cluster name.");
			if (check == true) {
				this.editColumn(i + 2, val);
			} else {
				e.preventDefault();
			}
		} else {
			this.submitIdeasProposed();
		}
	}



	public submitBrainstorm() {
		for (var i = this.brainstorm_content.length; i >= 1; i--) {
			if (this.brainstorm_content[i] == null || this.brainstorm_content[i] == '') {
				this.brainstorm_content.splice(i, 1);
				this.brainstorm.splice(i, 1);
			}
		}
		if (this.brainstorm_content.length > 1) {
			console.log(this.brainstorm)
			this.loading_brainstorm = true;
			this.edit_brainstorm = false;
			this.loading_brainstorm = false;
			this.showBrainstorm = false;
			for (var j = 0; j < this.brainstorm_content.length; j++) {
				this.prev_brainstorm_content[j] = this.brainstorm_content[j];
			}
			setTimeout(function () {
				this.toastr.options = {
					closeButton: true,
					progressBar: true,
					timeOut: 2000
				};
				this.toastr.success('Your thoughts has been saved!', 'Nice!');
			}, 1000);
		}
	}

	public DataModel(content) {
		console.log(content)
		$('.cn-dmodal').css('display', 'block');
		this.allChallenge.delData.subscribe((data)=> {
			console.log(data)
			if(data) {
				if(content == 'question') {
					this.deleteQuestion();
				} else if(content == 'brainstorm') {
					this.delBrainstorm();
				}
			}
		})
		// this.modal = this.modalService.open(content, { windowClass: 'in grey' });
	}

	public delBrainstorm() {
		var that = this;
		this.cancelBs();
		this.submit_converge_brainstorm();
		// var remove = confirm("Are you sure, you want to delete this? This cannot be undone!");
		// if(remove == true){
		// this.modal.close();
		$('.cn-dmodal').css('display', 'none');
		this.brainstorm_content = [];
		this.brainstorm_content[0] = [];
		this.checked_brainstorm = [];
		this.prev_brainstorm_content = [];
		this.brainstorm = [0];
		this.edit_brainstorm = true;
		this.unassignedIdeas();
		for (var i = 1; i < 16; i++) {
			this.brainstorm_content[i] = '';
			this.brainstorm[i] = '';
		}
		setTimeout(function () {
			this.toastr.options = {
				closeButton: true,
				progressBar: true,
				timeOut: 2000
			};
			this.toastr.success('Your thoughts has been deleted!', 'Nice!');
		}, 1000);
		this.allChallenge.delData.next(false);
		// }
	}

	public BsConverge() {
		this.cancelBs();
		this.trackLabel = false;
		this.showBrainstorm = false;
		this.edit_brainstorm = false;
		// this.stickChallenge()
	}

	public BsDiverge(currentTrack = '') {
		this.showBrainstorm = false;
		this.edit_brainstorm = true;
		this.edit_top_opiton_view = false;
		if (this.brainstorm.length < 16) {
			for (var i = this.brainstorm_content.length; i < 16; i++) {
				this.brainstorm_content[i] = '';
				this.brainstorm[i] = '';
			}
		}
		if (currentTrack.length > 0) {
			this.trackLabel = true;
			this.currentTracker = currentTrack;
		} else {
			this.PreviousTracker = 'Options';
		}
		if (this.brainstorm[1].length == 0) {
			setTimeout(() => {
				let frst_elem = document.querySelectorAll('.note-1');
				$(frst_elem[0]).focus();
			}, 100);
		}
		this.openEditmode(false, true, false);
		// this.stickChallenge();
	}

	// cancel option
	public submit_converge_brainstorm(gotonext = 0) {
		this.trackLabel = false;
		this.edit_brainstorm = false;
		this.showBrainstorm = true;
		this.openEditmode(true, true, true, true, 'block', '#fff');
		if (gotonext == 1) {
			setTimeout(() => {
				this.topOptionDiverge();
			}, 500);
		}
	}

	public addBsLine() {
		let elem = document.activeElement.closest('.card-body__editcol');
		console.warn(elem)
		if (elem != null) {
			if (elem.nextElementSibling) {
				let inputElement = document.activeElement.closest('.card-body__editcol').nextElementSibling.getElementsByTagName("input");
				inputElement[0].focus();
			} else {
				this.brainstorm.push(this.brainstorm.length);
			}
		} else {
			this.brainstorm.push(this.brainstorm.length);
		}
	}

	public subBsLine(i) {
		this.brainstorm_content.splice(i, 1);
		this.brainstorm.splice(this.brainstorm.length - 1, 1);
	}

	public submitIdeasProposed() {
		var that = this;
		this.proposed_content[1] = [];
		var loc = this;
		jQuery(".checkedbox-proposed:checkbox").each(function () {
			var value = jQuery(this).val();

			if (jQuery(this).is(':checked')) {
				loc.proposed_content[1].push(value);
			}
		})
		for (var x = 0; x < this.proposed_content.length - 2; x++) {
			this.trackIds[x] = JSON.stringify(x + 2);
		}
		var content = [];
		for (var z = 0; z <= this.proposed_content[0].length + 1; z++) {
			if (this.proposed_content[z]) {
				content[z] = JSON.stringify(this.proposed_content[z]);
			} else {
				this.proposed_content[z] = [];
				z--;
			}
		}
		setTimeout(function () {
			this.toastr.options = {
				closeButton: true,
				progressBar: true,
				timeOut: 2000
			};
			this.toastr.success('Your thoughts has been saved!', 'Nice!');
		}, 1000);
	}


	public async IpOrderChange(event) {
		console.log(event);
		var that = this;
		transferArrayItem(this.proposed_content[event.previousContainer.id], this.proposed_content[event.container.id], event.previousIndex, event.currentIndex);
		console.log(this.proposed_content);
		this.submitIdeasProposed();
	}

	public showSecond() {
		this.showBrainstorm = !this.showBrainstorm;
	}


	public checkBrainstorm(val, index) {
		console.log(val);
		var that = this;
		if (val == true) {
			for (var i = 1; i < this.proposed_content.length; i++) {
				for (var j = 0; j < this.proposed_content[i].length; j++) {
					if (this.proposed_content[i][j] == this.brainstorm_content[index]) {
						this.proposed_content[i].splice(j, 1);
						this.submitIdeasProposed();
					}
				}
			}
			this.brainstorm_content[0] = [];
			jQuery(".checkedbox:checkbox").each(function () {
				var value = jQuery(this).val();
				if (jQuery(this).prop('checked') == true && value != '') {
					that.brainstorm_content[0].push(value);
				}
			})
			this.submitBrainstorm();
		} else {
			this.proposed_content[2].push(this.brainstorm_content[index]);
			this.submitIdeasProposed();
			this.brainstorm_content[0] = [];
			jQuery(".checkedbox:checkbox").each(function () {
				var value = jQuery(this).val();
				if (jQuery(this).prop('checked') == true && value != '') {
					that.brainstorm_content[0].push(value);
				}
			})
			this.submitBrainstorm();

		}
	}

	public unassignedIdeas() {
		this.proposed_content = [];
		this.proposed_content[0] = [];
		this.proposed_content[0] = ['Converged options'];
		this.proposed_content[1] = [];
		this.proposed_content[2] = this.brainstorm_content[0];
	}

	public deleteIdea(i, j) {
		for (var index = 0; index < this.brainstorm_content[0].length; index++) {
			if (this.proposed_content[i][j] == this.brainstorm_content[0][index]) {
				this.proposed_content[i].splice(j, 1);
				this.brainstorm_content[0].splice(index, 1);
				this.submitIdeasProposed();
				this.submitBrainstorm();
			}
		}
	}

	public newColumn() {
		this.cluster_btn = false;
		this.column_name = 'Cluster ' + this.proposed_content[0].length;

		for (var i = 0; i < this.proposed_content[0].length; i++) {
			if (this.proposed_content[0].indexOf(this.column_name) > -1) {
				let count = 2;
				this.column_name = 'Cluster ' + (this.proposed_content[0].length + count);
				console.warn('new name of column is------->' + this.column_name)
				count++;
			} else {
				break;
			}
		}

		for (var i = 0; i < this.proposed_content[0].length; i++) {
			if (this.proposed_content[0][i] == this.column_name) {
				break;
			}
			if (this.proposed_content[0][i] != this.column_name && i == this.proposed_content[0].length - 1 && this.column_name != '') {
				this.proposed_content[0].push(this.column_name);
				this.proposed_content[this.proposed_content.length] = [];
				this.column_name = '';
				console.log(this.proposed_content);
				this.submitIdeasProposed();
			}
		}
		console.log(i - 1 + ' yes');
		console.warn(this.proposed_content[i])
		this.editColumn(i + 1, 'Cluster ' + this.proposed_content[0].length)
	}

	public deleteColumn(param) {
		if (param == 'Converged options') {

		} else {
			for (var i = 0; i < this.proposed_content[0].length; i++) {
				if (this.proposed_content[0][i] == param) {
					this.proposed_content[0].splice(i, 1);
					this.proposed_content.splice(i + 2, 1);
				}
			}
		}
		console.log(this.proposed_content)
		this.submitIdeasProposed();
	}

	public topOptionDiverge() {
		if (this.brainstorm.length == 0) {
			console.log('bsdiverge')
			setTimeout(() => {
				this.BsDiverge();
			}, 200);
		} else {
			console.log('else blk')
			this.openEditmode(false, false, true, true);
			this.PreviousTracker = 'Top Options'
		}
		setTimeout(function () {
			$(document).scrollTop(0);
		}, 10);
	}


	public cancelBs() {
		this.trackLabel = false;
		this.edit_brainstorm = false;
		var brainstorm = this.prev_brainstorm_content;
		this.brainstorm_content = [];
		this.brainstorm = [];
		for (var i = 0; i < brainstorm?.length; i++) {
			let data_str = brainstorm[i];
			this.brainstorm_content[i] = data_str;
			this.brainstorm[i] = i;
		}
		this.openEditmode(true, true, true, true, 'block', '#fff');
		if (this.pin_challenge) {
			this.stickChallenge();
		}
		this.submit_converge_brainstorm();
	}

	public openColAction(i) {
		jQuery('.dotactions__dropdown').css("display", "none");
		jQuery('.col_action' + i).css("display", "block");
	}

	public openDataAction(i, j) {
		jQuery('.dotactions__dropdown').css("display", "none");
		jQuery('.data_action' + i + j).css("display", "block");
	}

	public hideDropdown() {
		jQuery('.dotactions__dropdown').css("display", "none");
		this.col_update = '';
	}

	public openAllCol(i, j, val) {
		jQuery('.dotactions__dropdowninner').css("display", "none");
		jQuery('.move' + i + j).css("display", "block");
		this.col_update = val;
	}

	public updateCol(i, j, val) {
		jQuery('.move' + i + j).css("display", "none");
		for (var x = 0; x < this.proposed_content[0].length; x++) {
			if (this.col_update == this.proposed_content[0][x]) {
				this.proposed_content[x + 2].push(val);
				this.proposed_content[i].splice(j, 1);
				// this.col_update = '';
				this.submitIdeasProposed();
			}
		}

	}

	public editColumn(i, val) {
		this.edit_column = i;
		console.log(this.edit_column);
		this.old_column = val;
		console.log(this.old_column, val)
		jQuery('.dotactions__dropdown').css("display", "none");
	}

	public saveColumn(i) {
		this.cluster_btn = true;
		this.edit_column = 0;
		this.proposed_content[0][i] = this.old_column;
		let clusterindex = i;
		setTimeout(function () {
			$('input[ng-reflect-name="col_head' + clusterindex + '"]').prop('checked', true);
		}, 100)
		this.submitIdeasProposed();
	}

	public cancelColumn(i) {
		this.cluster_btn = true;
		this.edit_column = 0;
		let clusterindex = i;
		setTimeout(function () {
			$('input[ng-reflect-name="col_head' + clusterindex + '"]').prop('checked', true);
		}, 100)
	}

	public submitQuestion() {
		this.trackLabel = false;
		this.save_question = true;
		let content = this.new_question;
		this.open_question = content;
		setTimeout(function () {
			this.toastr.options = {
				closeButton: true,
				progressBar: true,
				timeOut: 2000
			};
			this.toastr.success('Your thoughts has been saved!', 'Nice!');
		}, 1000);
		this.openEditmode(true, true, true, true, 'block', '#fff');
		this.BsDiverge();
	}

	public deleteQuestion() {
		this.save_question = true;
		this.new_question = '';
		this.open_question = '';
		// this.modal.close();
		$('.cn-dmodal').css('display', 'none');
		this.edit_question = false;
		setTimeout(function () {
			this.toastr.options = {
				closeButton: true,
				progressBar: true,
				timeOut: 2000
			};
			this.toastr.success('Your thoughts has been deleted!', 'Nice!');
		}, 1000);
		this.allChallenge.delData.next(false);
		this.openEditmode(true, true, true, true, 'block', '#fff');
	}

	public cancelQuestion() {
		this.save_question = true;
		this.edit_question = false;
		this.trackLabel = false;
		this.submit_converge_brainstorm();
		if (this.new_question.length < 0) {
			this.question_dropdown = '';
		}
		this.openEditmode(true, true, true, true, 'block', '#fff');
	}

	public editQuestion(currentTrack = '') {
		this.question_dropdown = 'How might...';
		this.edit_question = true;
		this.save_question = false;
		let content = this.open_question;
		this.new_question = content;
		if (currentTrack.length > 0) {
			this.currentTracker = currentTrack;
			this.trackLabel = true;
		} else {
			this.PreviousTracker = 'Topic for warm up'
		}
		this.openEditmode(true, false, false);
	}

	public openPopup(content) {
		$('.tooltippopup').addClass('tooltippopup--active');
		this.process_type = content;
		this.popup_type = 'info';
	}

	public closePopup() {
		$('.tooltippopup').removeClass('tooltippopup--active');
		this.process_type = '';
		this.popup_type = '';
	}

	public popupDetail(content) {
		this.popup_type = content;
	}

	public gotoSection(section) {
		this.closePopup();
		$('html,body').animate({
			scrollTop: $('.card-default:nth-child(' + section + ')').offset().top
		},
			'slow');
	}

	// openedit mode 
	public openEditmode(edittopic, editoption, edittopoption, sidenav = false, displaynone = 'none', bgcolor = "#EBEBEB") {
		this.edit_topic_view = edittopic
		this.edit_option_view = editoption;
		this.edit_top_opiton_view = edittopoption;
		// let togglemenu = document.querySelector('.trigger-mini-bar') as HTMLElement;
		// let mainnav = document.querySelector('.row.wrapper') as HTMLElement;
		let page = document.getElementById('page-wrapper');
		page.style.backgroundColor = bgcolor;
		let footer = document.querySelector('.footer') as HTMLElement;
		footer.style.backgroundColor = bgcolor;
		footer.style.border = '1px solid #CFDBE2'
		// if(sidenav){
		// 	togglemenu.click();
		// }
		if (editoption || edittopoption || edittopic) {
			// mainnav.style.display = displaynone;
			this.editmodeService.editmode.next(true);
		} else {
			// mainnav.style.display = displaynone;
			this.editmodeService.editmode.next(false);
		}
	}

	// pin challenge
	public stickChallenge(val = '') {
		console.warn(this.pin_challenge)
		this.pin_challenge = !this.pin_challenge;
		let headDivHight = $('.info.card__headactive').outerHeight();
		let inputDivTop = $('.info.card__headactive').offset()?.top;
		let winHeight = jQuery(window).outerHeight();
		console.log(winHeight - inputDivTop - headDivHight - inputDivTop);
		if (val == 'ideas') {
			if (this.pin_challenge) {
				$('.card-body__edit').height(winHeight - headDivHight - inputDivTop - 240);
				$('.card-body__inner').height('auto');
				// $('.card-body__edit').height(winHeight - inputDivTop - 150 );
			} else {
				$('.card-body__edit').height('auto');
			}
		} else {
			if (this.pin_challenge) {
				console.warn("ifblk")
				$('.card-body__inner').height(winHeight - headDivHight - inputDivTop - 165);
				$('.card-body__edit').height('auto');
			} else {
				console.warn("elseblk")
				$('.card-body__inner').height('auto');
			}
		}
	}

	public eyesHideDiverge() {
		if(this.hideDiv){
			$('.card-body__editcol.hideblk').css('display','block');
			$('.card-body__subtitle span').css('opacity','1');
			this.hideDiv = false;
			this.hideDivLabel = 'Hide unconverged options';
		}else{
			$('.card-body__editcol.hideblk').css('display','none');
			$('.card-body__subtitle span').css('opacity','0.7');
			this.hideDiv = true;
			this.hideDivLabel = 'See all diverged options';
		}
		let all_div_data = document.querySelectorAll('.card-body__editcol');
		for(let item = 0; item<this.brainstorm_content.length;item++){
			if(this.brainstorm_content[item] == ''){
				$(`.card-body__editcol.hideblk:nth-child(${item})`).css('display','none');
				console.log(all_div_data[item])
			}
		}
	}


}

