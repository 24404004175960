import { Component, OnInit, Input } from '@angular/core';
import { Auth } from "./../../auth/auth.service";
declare var jQuery:any;

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
	@Input() profile;
	@Input() edit;
  authProfile;


  constructor(public auth: Auth) { }

  ngOnInit() {
      jQuery(document).ready(function($) {
          $('html body').on('click', '.btn-example', function() {
              $(this).parents('.col-md-6').children('.work-modal_popup').addClass("active");
          });

          $('.clx-example').click(function() {
              $('.work-modal_popup').removeClass('active');
          });
      });
      
      this.auth.getProfile((err, profile)=> {
        this.authProfile = profile
        console.log(profile)
      })
  }

  // public getMembershipsRole(index){
  //     if(this.profile.memberships) {
  //       var memberships = this.profile.memberships;
  //       memberships.reverse();
  //       return memberships[index].role_title;
  //     }
  //   }

}
