import { Component, HostListener, Inject, OnInit } from "@angular/core";
import { isDevMode } from '@angular/core';
// Import the AuthService type from the SDK
// import { AuthService } from '@auth0/auth0-angular';
// import Cache from '@aws-amplify/cache';

import { Router, NavigationEnd } from "@angular/router";
import { Subscription } from "rxjs";
import { AccessGroupService } from "./components/access-group.service";
import { Location, DOCUMENT } from "@angular/common";
import { CommonDataService } from "./components/common-data.service";
import { Injectable } from "@angular/core";
import { map, filter, catchError, mergeMap } from "rxjs/operators";
import { VersionCheckService } from "./version-check.service";
import { environment } from "../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Auth } from "./auth/auth.service";

// store
import { select, Store } from "@ngrx/store";
import * as fsProfileActions from "./store/action/fs.action";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  public menu_items: any;
  public email: any;
  public referrer: any;
  public profile: any;

  timer: number = 60000; //2700000 for 45 min  // 1 min 60000
  userActivity;
  userActive;
  active: boolean = false;
  counter: number = 0;
  storeNgrx: any;

  // Check for Authentication
  isAuthenticated: boolean;

  shouldDisplayOverlay: boolean = true;

  routerSubscription: Subscription;

  constructor(
    public versionCheckService: VersionCheckService,
    private authService: Auth,
    private router: Router,
    public service: CommonDataService,
    public location: Location,
    public http: HttpClient,
    @Inject(DOCUMENT) private document: Document,
    public store: Store<any>
  ) {
    // console.log("appMode:-",isDevMode());
    // Update Token for OIDC
    // this.authService.getIdTokenClaims().subscribe(message => {
    //     if(message.__raw){
    //     Cache.setItem('federatedInfo', { token: message.__raw });
    //     }else{
    //     console.log('TOKEN FAILURE!');
    //     }

    // });

    // this.startCounter();

    // hash-based routing
    // auth.handleRedirectCallback();
    authService.handleAuthentication();

    // Attempt single sign-on authentication
    // if not authenticated
    // if (auth.isAuthenticated()) {
    //   this.shouldDisplayOverlay = false;
    // } else {
    //   auth.renewToken();
    // }
    authService.scheduleRenewal();
    this.isAuthenticated = false;
  }

  ngOnInit() {
    this.storeNgrx = this.store.select<any>((state) => state.profile.accessGroupData)
    // this.authService.isAuthenticated$.subscribe((success: boolean) => {
    //     this.isAuthenticated = success;
    // });
    var that = this;

    this.versionCheckService.initVersionCheck(environment.versionCheckURL);
    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        document.body.scrollTop = 0;
      });

    // this.storeActions();
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  onActivate(e, scrollContainer) {
    scrollContainer.scrollTop = 0;
  }

  // SignOut
  public signOut(): void {
    this.authService.logout();
  }
}
