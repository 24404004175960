import { Component, Input, OnInit } from '@angular/core';
import { environment } from './../../../../environments/environment';

@Component({
	selector: 'app-cig-foursight-thinking-preferences',
	templateUrl: './cig-foursight-thinking-preferences.component.html',
	styleUrls: ['./cig-foursight-thinking-preferences.component.scss']
})
export class CigFoursightThinkingPreferencesComponent implements OnInit {

	@Input() author;
	@Input() slides;
	public env;

  	constructor() { }

  	ngOnInit() {
  		this.env=environment;
  	}

}
