import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
// import gql from 'graphql-tag';
import { APIService } from '../../API.service';
import {AccessGroupService} from './../access-group.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
// import { Apollo } from 'apollo-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { DatepickerOptions } from 'ng2-datepicker';
import locale from 'date-fns/locale/en-US';
import { getYear } from 'date-fns';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import swal from 'sweetalert2/dist/sweetalert2.js';
import { Store } from '@ngrx/store';
// import 'sweetalert2/src/sweetalert2.scss';
// import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo } from 'ckeditor5';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

declare var jQuery: any;
import {
	ClassicEditor,
	AccessibilityHelp,
	Alignment,
	Autoformat,
	AutoImage,
	AutoLink,
	Autosave,
	BlockQuote,
	Bold,
	CloudServices,
	CodeBlock,
	Essentials,
	FindAndReplace,
	GeneralHtmlSupport,
	Heading,
	HorizontalLine,
	ImageBlock,
	ImageInsertViaUrl,
	ImageToolbar,
	ImageUpload,
	Indent,
	IndentBlock,
	Italic,
	Link,
	Paragraph,
	PasteFromOffice,
	SelectAll,
	SourceEditing,
	Style,
	TextTransformation,
	Undo,
	type EditorConfig
} from 'ckeditor5';


@Component({
  selector: 'app-admin-single-learner-map',
  templateUrl: './admin-single-learner-map.component.html',
  styleUrls: ['./admin-single-learner-map.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminSingleLearnerMapComponent implements OnInit {
    editor;
    editor2;
    public isLayoutReady = false;
    public config: EditorConfig = {}; // CKEditor needs the DOM tree before calculating the configuration.
    toolbar = [
      // default value
      ["bold", "italic"],
      ["underline", "strike"],
      ["code", "blockquote"],
      ["ordered_list", "bullet_list"],
      [{ heading: ["h1", "h2", "h3", "h4", "h5", "h6"] }],
      ["link", "image"],
      ["text_color", "background_color"],
      ["align_left", "align_center", "align_right", "align_justify"],
    ]
    currentLM_id: string;
    private currentLM: any = [];
    public allcolumns: any = [];
    private columns: any = [];
    private allmaptypes: any = [];
    private allOwners: any = [];
    public column_name: string = '';
    private column_order: number;
    private newCardId: string;
    private card_name: string = '';
    private card_text: string = '';
    private card_column: string = '';
    private card_order: number;
    private GET_LEARNERMAP: string;
    private open_card: any;
    private old_card: any;
    public cardModal: boolean = false;
    public cloneModal: boolean;
    private cloneCard_name: string;
    private learner_maps: any = [];
    private cloneCard_lm: string;
    private cloneCard_column: string;
    private editcard: any = [];
    private CardId: string;
    private OpenCol: string = '';
    learner_map: string = '';
    public editMap: boolean = false;
    private cloneCol: string = '';
    private url: any;
    private cloneColumn: string = '';
    private editColId: string = '';
    private editColName: string = '';
    private col_nmbr: number;
    private card_nmbr: number;
    private dropPos: number;
    private dragPos: number;
    private dragList: any;
    private trackIds: any = [];
    public isLoading:boolean = false;
    private edit_map: string;
    private edit_maptype: any;
    private edit_mapLocation: string = '';
    private edit_mapImage: string = '';
    private prework_days: number;
    private assessment_deadline: number;
    private before_day_point: number;
    private days_test_opens: number;
    private days_test_close: number;
    private first_training: any;
    private last_training: any;
    private open_date: any;
    private close_date: any;
    private map_owner: any;
    toaster_time = 1000;
    order_counter = 0;
    hashTitleStaging = '';
    hashTitleProduction = '';
    ngrxAccessGroup:any;
    wistia_src:any;
    loading:boolean =false;

    options: DatepickerOptions = {
      minYear: getYear(new Date()) - 50, // minimum available and selectable year
      maxYear: getYear(new Date()) + 50, // maximum available and selectable year
      placeholder: '', // placeholder in case date model is null | undefined, example: 'Please pick a date'
      format: "MM/dd/yyyy", // date format to display in input
      formatTitle: 'LLLL YYYYY',
      formatDays: 'EEEEE',
      firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
      locale: locale, // date-fns locale
      position: 'bottom',
      inputClass: '', // custom input CSS class to be applied
      calendarClass: 'datepicker-default', // custom datepicker calendar CSS class to be applied
      scrollBarColor: '#dfe3e9', // in case you customize you theme, here you define scroll bar color
    };

    // options: DatepickerOptions = {
    //     minYear: 1970,
    //     maxYear: 2030,
    //     format: 'DD-MM-YYYY',
    //     formatTitle: 'DD-MM-YYYY',
    //     firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    //     placeholder: 'Click to select a date', // HTML input placeholder attribute (default: '')
    //     inputClass: 'form-control',
    // };

  constructor(private changeDetector: ChangeDetectorRef,private accessgroup: AccessGroupService, private api: APIService, private sanitizer: DomSanitizer, private activatedRoute: ActivatedRoute, private modalService: NgbModal, private router: Router, public store:Store<any>) { }

  ngOnDestroy() {
    // for udpating order
    this.getLM();
  }

  ngOnInit() {
    this.editor = ClassicEditor;
    this.editor2 = ClassicEditor;
    this.ngrxAccessGroup =this.store.select<any>((state) => state.profile.accessGroupData);
  	this.ngrxAccessGroup.subscribe(message => { 
        if(message && message.length > 0){                
            if(!message['main_menu']?.learner_maps_admin){
                this.router.navigate(['/dashboard']);
                return false;
            }
        }

    },
    err => {
        // Log errors if any
        console.log(err);
    })
    localStorage.setItem('adminDateVisible', 'true');
    this.newCardId = '';
    this.activatedRoute.params.subscribe((params: Params) => {
      this.currentLM_id = params['id'];
      this.getLM();
    });
    var that = this;
    jQuery(document).click(function (event) {
      console.log(event.target)
      if (event.target.id != 'open-column') {
        that.OpenCol = '';
      }
    });

    $(document).on("focus","#editor-subtitle",function() {
      $('.editor-script--spc').addClass("editor-script--visible");
    });
    // $(document).on("mouseleave",".editor-script--visible",function() {
    //   $(this).removeClass("editor-script--visible");
    // });

  }

  public ngAfterViewInit(): void {
		this.config = {
			toolbar: {
				items: [
					'undo',
					'redo',
					'|',
					'sourceEditing',
					'findAndReplace',
					'selectAll',
					'|',
					'heading',
					'style',
					'|',
					'bold',
					'italic',
					'|',
					'horizontalLine',
					'link',
					'blockQuote',
					'codeBlock',
					'|',
					'alignment',
					'|',
					'outdent',
					'indent',
					'|',
					'accessibilityHelp'
				],
				shouldNotGroupWhenFull: false
			},
			plugins: [
				AccessibilityHelp,
				Alignment,
				Autoformat,
				AutoImage,
				AutoLink,
				Autosave,
				BlockQuote,
				Bold,
				CloudServices,
				CodeBlock,
				Essentials,
				FindAndReplace,
				GeneralHtmlSupport,
				Heading,
				HorizontalLine,
				ImageBlock,
				ImageInsertViaUrl,
				ImageToolbar,
				ImageUpload,
				Indent,
				IndentBlock,
				Italic,
				Link,
				Paragraph,
				PasteFromOffice,
				SelectAll,
				SourceEditing,
				Style,
				TextTransformation,
				Undo
			],
			heading: {
				options: [
					{
						model: 'paragraph',
						title: 'Paragraph',
						class: 'ck-heading_paragraph'
					},
					{
						model: 'heading1',
						view: 'h1',
						title: 'Heading 1',
						class: 'ck-heading_heading1'
					},
					{
						model: 'heading2',
						view: 'h2',
						title: 'Heading 2',
						class: 'ck-heading_heading2'
					},
					{
						model: 'heading3',
						view: 'h3',
						title: 'Heading 3',
						class: 'ck-heading_heading3'
					},
					{
						model: 'heading4',
						view: 'h4',
						title: 'Heading 4',
						class: 'ck-heading_heading4'
					},
					{
						model: 'heading5',
						view: 'h5',
						title: 'Heading 5',
						class: 'ck-heading_heading5'
					},
					{
						model: 'heading6',
						view: 'h6',
						title: 'Heading 6',
						class: 'ck-heading_heading6'
					}
				]
			},
			htmlSupport: {
				allow: [
					{
						name: /^.*$/,
						styles: true,
						attributes: true,
						classes: true
					}
				]
			},
			image: {
				toolbar: ['imageTextAlternative']
			},
			initialData:'',
			link: {
				addTargetToExternalLinks: true,
				defaultProtocol: 'https://',
				decorators: {
					toggleDownloadable: {
						mode: 'manual',
						label: 'Downloadable',
						attributes: {
							download: 'file'
						}
					}
				}
			},
			placeholder: 'Type or paste your content here!',
			style: {
				definitions: [
					{
						name: 'Article category',
						element: 'h3',
						classes: ['category']
					},
					{
						name: 'Title',
						element: 'h2',
						classes: ['document-title']
					},
					{
						name: 'Subtitle',
						element: 'h3',
						classes: ['document-subtitle']
					},
					{
						name: 'Info box',
						element: 'p',
						classes: ['info-box']
					},
					{
						name: 'Side quote',
						element: 'blockquote',
						classes: ['side-quote']
					},
					{
						name: 'Marker',
						element: 'span',
						classes: ['marker']
					},
					{
						name: 'Spoiler',
						element: 'span',
						classes: ['spoiler']
					},
					{
						name: 'Code (dark)',
						element: 'pre',
						classes: ['fancy-code', 'fancy-code-dark']
					},
					{
						name: 'Code (bright)',
						element: 'pre',
						classes: ['fancy-code', 'fancy-code-bright']
					}
				]
			}
		};

		this.isLayoutReady = true;
		this.changeDetector.detectChanges();
	}
  public hideScript() {
    $('.editor-script--spc').removeClass("editor-script--visible");
  }

  public async NewColumn() {
    let that = this;
    console.log(this.api.GetLearnerMap(this.currentLM_id))
    await this.api.GetLearnerMap(this.currentLM_id).then(function (res) {
      that.api.CreateColumn({ title: that.column_name, columnLearnermapId: that.currentLM_id, order: res.columns.items.length + 1 }).then(function (data) {
      	that.column_name = '';
      	setTimeout(function() {
            this.toastr.options = {
                closeButton: true,
                progressBar: true,
                timeOut: that.toaster_time
            };
            this.toastr.success('New column has been created!', 'Nice!');
        }, 1000);
        that.getLM();
      });
    })
  }

  private async NewCard(col) {
    let that = this;
    await this.api.GetColumn(col.id).then(function (data) {
      console.log(data);
      that.api.CreateCard({ title: that.card_name, cardColumnId: data.id, order: data.cards.items.length + 1 }).then(function (result) {
        that.card_name = '';
        that.newCardId = '';
        setTimeout(function() {
            this.toastr.options = {
                closeButton: true,
                progressBar: true,
                timeOut: that.toaster_time
            };
            this.toastr.success('New card has been created!', 'Nice!');
        }, 1000);
        that.getLM();
      })
    })
  }

  private async getLM() {
    console.log('calling learner map...!')
    var that = this;
    let email = localStorage.getItem('email').toLowerCase();
    await this.api.GetLearnerMap(this.currentLM_id).then(function (map) {
      console.log(map);
      if(map.owners.indexOf(email) == -1){
        that.router.navigate(['/learner-maps-admin']);
      }
      that.isLoading = true;
      that.learner_map = '';
      let hash:number = 0;
      // remove feature between #
      for(let  i = 0; i < map.title.length; i++) {
        if(map.title[i] === '#') {
          hash += 1;
        }
        if(hash < 1) {
          that.learner_map += map.title[i];
        }
      }

      that.currentLM = map;
      that.allcolumns = that.currentLM.columns.items;
      console.log(that.allcolumns)
      console.log(that.allcolumns[0]?.cards?.items)

      // update order 
      that.order_counter = 0;
      that.allcolumns.forEach((col, index) => {
        that.trackIds[index] = col.id;
        col?.cards?.items?.forEach((item,ind) => {
          that.order_counter += 1;
          that.api.UpdateCard({
            id: item.id,
            title: item.title,
            active: item.active,
            type: item.type,
            lesson:item.lesson,
            medium: item.medium,
            runtime: item.runtime,
            parent: item.parent,
            link: item.link,
            body: item.body,
            bg: item.bg,
            button: item.button,
            text: item.text,
            target: item.target,
            open: item.open,
            close: item.close,
            order: that.order_counter,
          })
        })
      })
    })
    this.allmaptypes = await this.api.ListMapTypes().then(function (data) {
        console.log(data);
      return data['items'];
    });
    this.allOwners = await this.api.ListMembers().then(function (data) {
      return data['items'];
    });
  }

  private addCardlist(id) {
    this.newCardId = id;
  }

  private cancelAddCard() {
    this.newCardId = '';
  }

  private async onTaskDrop(event) {
    this.loading = true;
    var that = this;
    this.CardId = event.item.element.nativeElement.id;
    console.log(event);
    console.log(this.CardId);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        var url;
        await that.api.GetColumn(event.container.id).then(async function (data) {
          console.warn('get coloumn data',data);
          let moved_item:any;
          if(event.currentIndex < event.previousIndex) {
            // bottom to top
            moved_item = data.cards.items[event.previousIndex];
          } else {
            // top to bottom
            moved_item = data.cards.items[event.currentIndex];
          }
          console.log(moved_item)
          console.log(event.currentIndex,event.previousIndex)

          data.cards.items.forEach(async (card, index) => {
            console.warn(index,card.title)
            if(event.currentIndex < event.previousIndex) {
              if(index >= event.currentIndex && index < event.previousIndex && card.id != that.CardId) {
                that.api.UpdateCard({
                  id: card.id,
                  order: data.cards.items[event.previousIndex].order + 1
                })
              }
            } else if(event.currentIndex > event.previousIndex) {
              if(index <= event.currentIndex && index > event.previousIndex && card.id != that.CardId) {
                  that.api.UpdateCard({
                  id: card.id,
                  order: index - 1
                })
              }
            }
          })
          
          data.cards.items[event.currentIndex] = moved_item;
          url = that.router.url;
          that.router.navigate(['/learner-maps-admin']);
        }).then(()=> {
          that.router.navigate([`${url}`])
          // that.getLM();
          setTimeout(() => {
            that.loading = false;
          }, 300);
          setTimeout(function() {
            this.toastr.options = {
                closeButton: true,
                progressBar: true,
                timeOut: that.toaster_time
            };
            this.toastr.success('Card order has been updated!', 'Nice!');
	        }, 1000);
        })
      // })
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      this.api.UpdateCard({
        id: this.CardId,
        cardColumnId: event.container.id,
        order: event.currentIndex + 1
      }).then(async function (data) {
        console.log(data);
        that.api.GetColumn(event.container.id).then(async function (data) {
          console.log(data);
        })
        that.api.GetColumn(event.previousContainer.id).then(function (data) {
          data.cards.items.forEach((card, index) => {
            if (card.order > event.previousIndex + 1 && card.id != that.CardId) {
              that.api.UpdateCard({
                id: card.id,
                order: card.order - 1
              })
            }
          })
        })
        await that.api.GetColumn(event.container.id).then(async function (data) {
          data.cards.items.forEach((card, index) => {
            if (card.order >= event.currentIndex + 1 && card.id != that.CardId) {
              that.api.UpdateCard({
                id: card.id,
                order: card.order + 1
              })
            }
          })
          	await that.getLM();
          	setTimeout(function() {
	            this.toastr.options = {
	                closeButton: true,
	                progressBar: true,
	                timeOut: that.toaster_time
	            };
	            this.toastr.success('Card order has been updated!', 'Nice!');
	        }, 1000);
        })
      })
    }
  }

  public async onColumnDrop(event) {
    console.log(event);
    var that = this;
    moveItemInArray(this.allcolumns, event.previousIndex, event.currentIndex);
    console.log(this.allcolumns)
    await this.api.UpdateColumn({ id: event.item.element.nativeElement.id, order: event.currentIndex + 1 }).then(function (data) {
      that.api.GetLearnerMap(that.currentLM_id).then(async function (map) {
        console.log(map);
        that.allcolumns.forEach((col,index)=> {
          that.api.UpdateColumn({
            id: col.id,
            order: index + 1
          })
        })
        // map.columns.items.forEach((column, index) => {
        //   if (column.order >= event.currentIndex + 1 && column.id != event.item.element.nativeElement.id) {
        //     that.api.UpdateColumn({
        //       id: column.id,
        //       order: column.order + 1
        //     })
        //   }
        //   if (column.order > event.previousIndex + 1 && column.id != event.item.element.nativeElement.id) {
        //     that.api.UpdateColumn({
        //       id: column.id,
        //       order: column.order - 1
        //     })
        //   }
        // })
        await that.getLM();
        setTimeout(function() {
            this.toastr.options = {
                closeButton: true,
                progressBar: true,
                timeOut: that.toaster_time
            };
            this.toastr.success('Column order has been updated!', 'Nice!');
        }, 1000);
      })
    })
  }

  public close(popupName) {
    // $('.card-modal').css('display', 'none');
    if(popupName == 'editmap') {
      this.editMap = false;
    }else if(popupName == 'clone') {
      this.cloneModal = false;
    } else {
      this.cardModal = false;
    }
}

  private async openCard( card, column, col_index, card_index) {
    this.editcard = [];
    this.card_nmbr = card_index + 1;
    this.col_nmbr = col_index + 1;

    // this.cardModal = this.modalService.open(content, { windowClass: 'modal-module in' });
    // $('.card-modal').css('display','block');
    this.cardModal = true;
    this.old_card = card;
    this.open_card = card;
    if(this.open_card?.type == 'Var_Wistia') {
      this.wistia_src = "//fast.wistia.net/embed/iframe/" + this.open_card?.link  +"?videoFoam=true"
      this.wistia_src = this.sanitizer.bypassSecurityTrustResourceUrl(this.wistia_src)
      console.log(this.wistia_src)
    }
    this.cloneCard_column = column.id;
    this.columns = this.allcolumns;
    var that = this;
    await this.api.ListLearnerMaps().then(function (data) {
      that.learner_maps = data.items;
    });
    console.log(this.open_card);
    if(this.open_card.id == '65ddf595-c1f3-46ed-9167-2fe14770657d'){
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.open_card.link);
    }else{
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.open_card.link + '?modestbranding=1&amp;rel=0&amp;showinfo=0');
    }
  }

  private cloneCard() {
    // this.cloneModal = this.modalService.open(content, { size: 'sm', windowClass: 'in' });
    this.cloneModal = true;
    this.cloneCard_name = this.open_card.title;
    this.cloneCard_lm = this.currentLM_id;
  }

  private async getColumns() {
    this.cloneCard_column = '';
    var that = this
    await this.api.GetLearnerMap(this.cloneCard_lm).then(function (data) {
    	console.log(data);
      that.columns = data.columns.items;
    })
  }

  private async createCard() {
    var that = this;
    await this.api.GetColumn(this.cloneCard_column).then(function (data) {
      console.log(data);
      that.api.CreateCard({
        title: that.cloneCard_name,
        active: that.open_card.active,
        cardColumnId: that.cloneCard_column,
        order: data.cards.items.length + 1,
        type: that.open_card.type,
        lesson: that.open_card.lesson,
        medium: that.open_card.medium,
        runtime: that.open_card.runtime,
        parent: that.open_card.parent,
        body: that.open_card.body,
        link: that.open_card.link,
        bg: that.open_card.bg,
        button: that.open_card.button,
        text: that.open_card.text,
        target: that.open_card.target,
        open: that.open_card.open,
        close: that.open_card.close,
      }).then(function (data) {
        console.log(data);
        setTimeout(function() {
            this.toastr.options = {
                closeButton: true,
                progressBar: true,
                timeOut: that.toaster_time
            };
            this.toastr.success('New card has been created!', 'Nice!');
        }, 1000);
        that.getLM();
        // that.cloneModal.close();
        that.cloneModal = false;
        // that.cardModal.close();
        that.cardModal = false;
        // $('.card-modal').css('display','none');
      })
    })

  }

  private editCard(card) {
    this.editcard = card;
    if(card.open) {
      this.open_date = new Date(card.open)
    } else {
      this.open_date = null;
    }
    if(card.close) {
      this.close_date = new Date(card.close)
    } else {
      this.close_date = null;
    }
    this.getLM();
  }

  private async updateCard() {
    if(this.editcard.body == ""){
      this.editcard.body = null;
    }
    if(this.editcard.bg == ""){
      this.editcard.bg = null;
    }
    if(this.editcard.link == ""){
      this.editcard.link = null;
    }
    if(this.editcard.text == ""){
      this.editcard.text = null;
    }
    if(this.editcard.type == ""){
      this.editcard.type = null;
      this.editcard.bg = null;
      this.editcard.text = null;
      this.editcard.link = null;
    }
    if(this.editcard.target == "") {
      this.editcard.target = null;
    }
    var that = this;
    await this.api.UpdateCard({
      id: this.editcard.id,
      title: this.editcard.title,
      active: this.editcard.active,
      type: this.editcard.type,
      lesson: this.editcard.lesson,
      medium: this.editcard.medium,
      runtime: this.editcard.runtime,
      parent: this.editcard.parent,
      link: this.editcard.link,
      body: this.editcard.body,
      bg: this.editcard.bg,
      button: this.editcard.button,
      text: this.editcard.text,
      target: this.editcard.target,
      open: this.open_date,
      close: this.close_date,
      order: this.editcard.order
    }).then(function (data) {
      	that.editcard = [];
      	setTimeout(function() {
            this.toastr.options = {
                closeButton: true,
                progressBar: true,
                timeOut: that.toaster_time,
            };
            this.toastr.success('New card has been updated!', 'Nice!');
        }, 1000);
        that.getLM();
      	that.open_card = data;
        that.getLM();
    })
  }

  private cancelCard() {
  	this.open_card = this.allcolumns[this.col_nmbr-1]['cards'].items[this.card_nmbr - 1];
    this.editcard = [];
  }

  private openColumn(col) {
    this.cloneColumn = '';
    if (this.OpenCol == '') {
      this.OpenCol = col.id
    } else if (this.OpenCol == col.id) {
      this.OpenCol = '';
    }
    else {
      this.OpenCol = col.id;
    }
  }

  private closeCol() {
    this.OpenCol = '';
  }

  	private async deleteCard() {
	    var that = this;
	    Swal.fire({
	        title: "Are you sure?",
	        text: "Are you sure, you want to delete this Card? This cannot be undone!",
	        type: "warning",
	        showCancelButton: true,
	        focusCancel: true,
	        confirmButtonColor: "#dc3545",
	        cancelButtonColor: '#28a745',
	        confirmButtonText: "Yes, delete it!"
		}).then((result) => {
	        if (result['value'] == true) {
	        	jQuery('.swal2-container').addClass('hide');
	    		jQuery('body').removeClass('swal2-shown');
	    		jQuery('html').removeClass('swal2-shown');
	          this.api.DeleteCard({ id: this.open_card.id }).then(function (res) {
		      	console.log(res.column.id);
		      	setTimeout(function() {
	            this.toastr.options = {
	                closeButton: true,
	                progressBar: true,
	                timeOut: that.toaster_time
	            };
	            this.toastr.success('Your card has been deleted!', 'Nice!');
	        }, 1000);
		      that.getLM();
		      // that.cardModal.close();
          that.cardModal = false;
          // $('.card-modal').css('display','none');
		      console.log('deleted');
		    })
	        }else{
	            jQuery('.swal2-container').addClass('hide');
	            jQuery('body').removeClass('swal2-shown');
	            jQuery('html').removeClass('swal2-shown');
	        }
		},function(dismiss){
			jQuery('.swal2-container').addClass('hide');
			jQuery('body').removeClass('swal2-shown');
			jQuery('html').removeClass('swal2-shown');
		});
    jQuery('.swal2-container').css('z-index','99999') 
	}

  private columnModal(col) {
  	this.cloneCol = '';
    this.cloneColumn = col.id;
  }
  private closeColumn() {
    this.cloneColumn = '';
  }

  private async copyColumn(col) {
    var that = this;
    await this.api.GetLearnerMap(this.currentLM_id).then(function (map) {
      that.api.CreateColumn({
        title: that.cloneCol,
        order: map.columns.items.length + 1,
        open: col.open,
        close: col.close,
        columnLearnermapId: col.learnermap.id
      }).then(function (data) {
        that.api.GetColumn(col.id).then(async function (column) {
          column.cards.items.forEach((card) => {
            that.api.CreateCard({
              title: card.title,
              active: card.active,
              type: card.type,
              lesson: card.lesson,
              medium: card.medium,
              runtime: card.runtime,
              parent: card.parent,
              body: card.body,
              link: card.link,
              bg: card.bg,
              button: card.button,
              text: card.text,
              target: card.target,
              open: card.open,
              close: card.close,
              order: card.order,
              cardColumnId: data.id
            })
          })
          setTimeout(function() {
            this.toastr.options = {
                closeButton: true,
                progressBar: true,
                timeOut: that.toaster_time
            };
            this.toastr.success('Your column has been created!', 'Nice!');
        }, 1000);
          await that.getLM();
        })
        that.cloneColumn = '';
      });
    })
  }

  	private async deleteCol(col) {
	    var that = this;
	    Swal.fire({
	        title: "Are you sure?",
	        text: "Are you sure, you want to delete this Card? This cannot be undone!",
	        type: "warning",
	        showCancelButton: true,
	        focusCancel: true,
	        confirmButtonColor: "#dc3545",
	        cancelButtonColor: '#28a745',
	        confirmButtonText: "Yes, delete it!"
		}).then((result) => {
	        if (result['value'] == true) {
	        	jQuery('.swal2-container').addClass('hide');
	    		jQuery('body').removeClass('swal2-shown');
	    		jQuery('html').removeClass('swal2-shown');
	            this.api.DeleteColumn({ id: col.id }).then(function (res) {
	      			that.api.GetLearnerMap(that.currentLM_id).then(async function (data) {
	        			data.columns.items.forEach(async (column, index) => {
	          				if (column.order > res.order) {
					            that.api.UpdateColumn({
					              id: column.id,
					              order: column.order - 1
					            })
	          				}
	        			})
				        setTimeout(function() {
			            this.toastr.options = {
			                closeButton: true,
			                progressBar: true,
			                timeOut: that.toaster_time
			            };
			            this.toastr.success('Your column has been deleted!', 'Nice!');
        			}, 1000);
	        		await that.getLM();
	      			})
	    		})
	        }else{
	            jQuery('.swal2-container').addClass('hide');
	            jQuery('body').removeClass('swal2-shown');
	            jQuery('html').removeClass('swal2-shown');
	        }
		},function(dismiss){
			jQuery('.swal2-container').addClass('hide');
			jQuery('body').removeClass('swal2-shown');
			jQuery('html').removeClass('swal2-shown');
		});
  	}

  private editCol(col) {
    this.editColId = col.id;
    this.editColName = col.title;
  }

  private async saveCol() {
    var that = this;
    await this.api.UpdateColumn({
      id: this.editColId,
      title: this.editColName
    }).then(function (data) {
      that.allcolumns.forEach((col, index) => {
        if (col.id == that.editColId) {
        	that.editColId = '';
          	that.allcolumns[index] = data;
        }
      })
      setTimeout(function() {
        this.toastr.options = {
            closeButton: true,
            progressBar: true,
            timeOut: that.toaster_time
        };
        this.toastr.success('Your column has been updated!', 'Nice!');
    }, 1000);
    })
  }

  private cancelCol() {
    this.editColId = '';
  }

  private updateCardOrder(item, index) {
    if (item.order != index + 2 && item.id != this.CardId) {
      this.api.UpdateCard({
        id: item.id,
        order: index + 2
      })
    } else {
      return true;
    }
  }

  public open(){
    // $('.editlm-modal').css('display','block');
    this.editMap = true;
    // this.editMap = this.modalService.open(content, { size: 'lg', windowClass: 'in grey modalParent' });
    // this.edit_map = this.currentLM.title;
    let editLmTitle = '';
    this.hashTitleStaging = '';
    this.hashTitleProduction = '';
    let hash = 0;
    // feature between #
    for(let  i = 0; i < this.currentLM.title.length; i++) {
      if(this.currentLM.title[i] === '#') {
        hash += 1;
        continue;
      }
      if(hash > 0 && hash < 2) {
        this.hashTitleStaging += this.currentLM.title[i];
      }
      if(hash > 1) {
        this.hashTitleProduction += this.currentLM.title[i];
      }
      if(hash < 1){
        editLmTitle += this.currentLM.title[i]; 
        console.log(editLmTitle) 
      }
    }
    this.edit_map = editLmTitle;
    this.map_owner = this.currentLM.owners;
    this.edit_maptype = this.currentLM.maptype.id;
    this.edit_mapImage = this.currentLM.image;
    this.edit_mapLocation = this.currentLM.location;
    this.prework_days = this.currentLM.daysOfPrework;
    this.assessment_deadline = this.currentLM.daysBeforeAssessmentDeadline;
    this.before_day_point = this.currentLM.daysBeforePointDue;
    this.days_test_opens = this.currentLM.daysAfterTrainingBeforeTestOpens;
    this.days_test_close = this.currentLM.daysAfterTrainingBeforeTestCloses;
    this.first_training = new Date(this.currentLM.trainingFirstDay);
    this.last_training = new Date(this.currentLM.trainingLastDay);
  }

  private async UpdateLearnerMap() {
    let that = this;
    let features_grp_staging = $('#feature-grp-staging').val();
    let features_grp_production = $('#feature-grp-production').val();
    this.edit_map += '#' +  features_grp_staging + '#' + features_grp_production + '#';
    if (this.edit_map && this.edit_mapLocation && this.edit_maptype) {
      this.first_training = new Date(this.first_training);
      this.last_training = new Date(this.last_training);
      await this.api.UpdateLearnerMap({ id: this.currentLM_id, title: this.edit_map, owners: this.map_owner.toLowerCase(), learnerMapMaptypeId: this.edit_maptype, location: this.edit_mapLocation, image: this.edit_mapImage, daysOfPrework: this.prework_days, daysBeforeAssessmentDeadline: this.assessment_deadline, daysBeforePointDue: this.before_day_point, daysAfterTrainingBeforeTestOpens: this.days_test_opens, daysAfterTrainingBeforeTestCloses: this.days_test_close, trainingFirstDay: this.first_training, trainingLastDay: this.last_training }).then(async function (data) {
        // that.editMap.close();
        that.close('editMap')
        that.getLM();
        setTimeout(function() {
            this.toastr.options = {
                closeButton: true,
                progressBar: true,
                timeOut: that.toaster_time
            };
            this.toastr.success('Learner map has been updated!', 'Nice!');
        }, 1000);
      });
    }
  }

  public increaseZindex() {
    setTimeout(() => {
      $('bs-datepicker-container').css('z-index','999999')
    }, 100);
  }
}
