import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { environment } from './../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-cig-wistia',
  templateUrl: './cig-wistia.component.html',
  styleUrls: ['./cig-wistia.component.scss']
})
export class CigWistiaComponent implements OnInit {

  @Input() profile:any;
  @Input() slides;
  @Input() author;
  @Input() videoLink;
  @Input() thumbnail_img;
  public env;
  public profiles : any;
  src;
  poster:string = '';

  constructor( private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    console.log(this.slides)
    this.env=environment;
    this.profiles = this.profile;
    this.src = "https://fast.wistia.com/embed/iframe/" +this.slides[31]?.video_link + "?videoFoam=true";
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
    console.log(this.src)
    let thumbnailLink = "https://fast.wistia.com/embed/iframe/" + this.videoLink + "?videoFoam=true";
    this.videoLink = this.sanitizer.bypassSecurityTrustResourceUrl(thumbnailLink);
  }

}
