import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomPfService {

  profile_data = new BehaviorSubject<any>('');
  active_lmsd_id = new BehaviorSubject<string>('');

  default_TCWA = {
    profiles:{
      access_groups_json:{
        "main_menu": {
            "collaboration_profile": true,
            "profile_slideshow": true,
            "claim_account": false,
            "presenter_details": true,
            "thinking_profile": true,
            "interpretative_guide": false
        }
      }
    }
  }

  constructor(public http:HttpClient,public router: Router) { }

  public getCustomPfData() {
    let id = this.router.url.replace('/thinking-coach/','');
    console.log(id)
    this.http.get(`${environment.profileURL}/api/v1/profiles/${id}.json`,{params:{profile:"custom",lang:"en"}}).subscribe((data) => {
      this.profile_data.next(data);
    } , err => {
      console.log(err)
     this.profile_data.next(err)
  })
    return this.profile_data;
  }


  // track activity 
  public trackActivity(id,activity) {
    let item = '';
    this.http.patch(`${environment.profileURL}/api/v1/profiles/${id}/activity.json`,item,{params:{activity_name:activity}}).subscribe((red)=>{
      // console.log(red)
    }, err => {
      console.log(err);
    })
  }
}