// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-wrapper {
  padding: 20px 10px 40px;
}

.ibox-content .image-youtube {
  cursor: pointer;
  display: block;
  margin: 0 auto;
}
.ibox-content .image-youtube--resz {
  max-width: 260px;
  width: 100%;
}
.ibox-content .image-youtube--re {
  max-width: 350px;
  width: 100%;
}

.ibox {
  height: 100%;
}
.ibox-title--bdr {
  border-bottom: 1px solid #e7eaec;
  align-self: self-start;
  width: 100%;
}
.ibox-content--fe {
  align-self: center;
  border: 0;
  width: 100%;
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 991px) {
  .d-flex div[class*=col-] {
    width: 100%;
  }
}

.video-container {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}
.video-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain;
  max-width: none !important;
  width: 100% !important;
  height: 80% !important;
}

.rvl-dash .ibox-title h5 {
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/teams-dashboard/teams-dashboard.component.scss"],"names":[],"mappings":"AAAA;EACC,uBAAA;AACD;;AAEA;EACC,eAAA;EACA,cAAA;EACA,cAAA;AACD;AACC;EACC,gBAAA;EACA,WAAA;AACF;AAEC;EACC,gBAAA;EACA,WAAA;AAAF;;AAIA;EACC,YAAA;AADD;AAIE;EACC,gCAAA;EACA,sBAAA;EACA,WAAA;AAFH;AAQE;EACC,kBAAA;EACA,SAAA;EACA,WAAA;AANH;;AAWA;EACC,aAAA;EACA,eAAA;AARD;AAUC;EACC;IACC,WAAA;EARD;AACF;;AAYA;EACI,WAAA;EACA,oBAAA;EACA,kBAAA;AATJ;AAWC;EACC,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,mBAAA;EACA,0BAAA;EACA,sBAAA;EACA,sBAAA;AATF;;AAiBE;EACC,gBAAA;AAdH","sourcesContent":[".section-wrapper{\n\tpadding:20px 10px 40px;\n}\n\n.ibox-content .image-youtube{\n\tcursor: pointer;\n\tdisplay: block;\n\tmargin: 0 auto;\n\t\n\t&--resz {\n\t\tmax-width: 260px;\n\t\twidth: 100%;\n\t}\n\n\t&--re {\n\t\tmax-width: 350px;\n\t\twidth: 100%;\n\t}\n}\n\n.ibox {\n\theight: 100%;\n\t&-title {\n\n\t\t&--bdr {\n\t\t\tborder-bottom: 1px solid #e7eaec;\n\t\t\talign-self: self-start;\n\t\t\twidth: 100%;\n\t\t}\n\t}\n\n\t&-content {\n\n\t\t&--fe {\n\t\t\talign-self: center;\n\t\t\tborder: 0;\n\t\t\twidth: 100%;\n\t\t}\n\t}\n}\n\n.d-flex {\n\tdisplay: flex;\n\tflex-wrap: wrap;\n\t\n\t@media screen and (max-width:991px) {\n\t\tdiv[class*=\"col-\"] {\n\t\t\twidth: 100%;\n\t\t}\n\t}\n}\n\n.video-container {\n    width: 100%;\n    padding-bottom: 100%;\n    position: relative;\n\n\timg {\n\t\tposition: absolute;\n\t\ttop: 50%;\n\t\tleft: 50%;\n\t\ttransform: translate(-50% , -50%);\n\t\tobject-fit: contain;\n\t\tmax-width: none !important;\n\t\twidth: 100% !important;\n\t\theight: 80% !important;\n\t}\n}\n\n.rvl-dash {\n\n\t.ibox-title {\n\n\t\th5 {\n\t\t\tmargin-bottom: 0;\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
