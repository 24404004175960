import { NgModule } from '@angular/core';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import{ Router, RouterModule } from '@angular/router';
import { APIService } from '../../../API.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {ChallengeSummaryService} from './../../challenge-summary.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { SlideoutContentService } from '../../../service/slideout-content.service';
import { CnEditmodeService } from '../challenges_service/cn-editmode.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

declare var jQuery:any;

@Component({
  selector: 'app-clarify-challenge',
  templateUrl: './clarify-challenge.component.html',
  styleUrls: ['./clarify-challenge.component.scss']
})

export class ClarifyChallengeComponent implements OnInit {

	breakthrough_content:any = [];
	breakthrough_dropdown:any = [];
	edit_breakthrough:boolean = false;
	datadump_content:any = [];
	dupli_datadump_content:any = [];
	edit_datadump:boolean = false;
	question_content:any = [];
	dupli_question_content:any = [];
	question_dropdown:any = [];
	edit_question:boolean = false;
	breakthrough:any = [];
	data_dump:any = [0];
	question:any = [0];
	showBreakthrough: boolean = false;
	showDatadump: boolean = false;
	showQuestion: boolean = false;
	Btupdate:boolean = false;
	BtId:string = '';
	Ddupdate:boolean = false;
	DdId:string = '';
	Qtupdate:boolean = false;
	QtId:string = '';
	selected_breakthrough:string;
	selected_datadump:string;
	selected_question:string;
	prev_breakthrough_content: any = [];
	prev_datadump_content: any = [];
	prev_question_content: any = [];
	loading_breakthrough: boolean = false;
	loading_datadump: boolean = false;
	loading_question: boolean = false;
	process_type: string;
	popup_type: string;
	DivergeConvergeVal:boolean= false;
	all_data: any = [];
	deleteDatavalue:number;
	modal:any;
	//slideout variable
	slideoutGoal:any;
	slideoutData:any;
	slideoutChallenge:any;
	slideoutDivergeContent:any;
	slideoutConvergeContent:any;
	// edit mode 
	pin_challenge: boolean = false;
	listGridView:string = 'listView';
	hideOnEditMode:boolean = true;
	EditModeIcon:boolean = false;
	editGoalView:boolean = true;
	editDataView:boolean = true;
	editthinkmode:boolean = false;
	editChallengeView:boolean = true;
	goalInput:boolean = false;
	dataInput:boolean = false;
	trackLabel:boolean = false;
	trackbtn:boolean = false;
	gridViewClass:string = '';
	currentTracker:string = '';
	PreviousTracker:string = '';
	dupli_breakthrough_content:any = [];
	backtoideate:boolean = false;
	hideDivLabel:string = 'See all diverged options';
	hideclarify:boolean = false;
	clarifyEyeIcon:boolean = false;
	personae:string = 'Select option';
	statementStarter:string = 'Select option';
	temprature:any = 0;
	openAiData:any = [];
	selectAiData:any = [];
	counter:number = 0;
	showVideo:boolean= false;


	// @ViewChild('content', {static: false}) content: ElementRef;

  	constructor(private api: APIService, public router: Router, public allChallenge: ChallengeSummaryService,private modalService: NgbModal,public slideContent:SlideoutContentService,public editmodeService:CnEditmodeService,public http: HttpClient) {	}

	ngOnDestroy(){
		// sidebar
		let togglemenu = document.querySelector('.trigger-mini-bar') as HTMLElement;
		if(togglemenu.classList.contains('squeeze')){
			togglemenu.click();
			togglemenu.classList.remove('squeeze');
		}
		this.openEditmode(true,true,true,true,'block','#fff');
		// this.allChallenge.delData.unsubscribe();
	}

  	async ngOnInit() {
		// if(localStorage.getItem('ideateToclarify') == 'true'){
		// 	$('.loader-container').css('display','flex');
		// }
		$('.loader-container').css('display','flex');
		// sidebar
		let togglemenu = document.querySelector('.trigger-mini-bar') as HTMLElement;
		if(!togglemenu.classList.contains('squeeze')){
			togglemenu.click();
			togglemenu.classList.add('squeeze');
		}

		// // console.log(this.listGridView)
		if(this.editmodeService.editmode.value){
			let page = document.getElementById('page-wrapper');
			page.style.background = '#fff';
			let footer = document.querySelector('.footer') as HTMLElement;
			footer.style.backgroundColor = '#fff';
			footer.style.border = 'none';
			// let togglemenu = document.querySelector('.trigger-mini-bar') as HTMLElement;
			// togglemenu.click();
			this.editmodeService.editmode.next(false);
			// let main_nav = document.querySelector('.row.wrapper') as HTMLElement;
			// main_nav.style.display ='block';
		}
		// diverge,conver,goal,data,challenge content  respectively
		this.slideoutDivergeContent = this.slideContent.divergeConvergeContent().clarify.diverge;
		this.slideoutConvergeContent = this.slideContent.divergeConvergeContent().clarify.converge;
		this.slideoutGoal = this.slideContent.divergeConvergeContent().clarify.goal;
		this.slideoutData = this.slideContent.divergeConvergeContent().clarify.data;
		this.slideoutChallenge = this.slideContent.divergeConvergeContent().clarify.challenge;

	  	var that = this;
	  	// console.log(localStorage.getItem('ideateToclarify'))
	  	
	  	// // console.log(this.showQuestion)

		let clarifyPromise = new Promise((resolve,error)=>{
			let BtFilter = {email:{eq:localStorage.getItem('email')}, processTypeId:{eq:"c1c17711-b4a3-4dcc-98b5-fe73ee0791d2"}, type:{eq:"process-stage"}, title:{eq:"vision"}};
			this.api.ListFrameworks(BtFilter,10000).then(function (data) {
				if(data.items.length == 0){
					that.edit_breakthrough = false;
					that.breakthrough_content[0] = '';
					that.breakthrough[0] = '';
					that.breakthrough_dropdown[0] = '';
					that.breakthrough_content[1] = '';
					that.breakthrough_dropdown[1] = 'It would be great if...';
					that.breakthrough[1] = '';
				}else{
					// // console.log(data.items[0].content);
					that.BtId = data.items[0].id;
					that.Btupdate = true;
					that.breakthrough = JSON.parse(data.items[0].content);
					that.prev_breakthrough_content = JSON.parse(data.items[0].content);
					var breakthrough = JSON.parse(data.items[0].content);
					for(var i=0;i<breakthrough.length;i++){
						let data_str = (breakthrough[i])?.split("\n");
						// console.log(data_str)
						that.breakthrough_dropdown[i] = data_str[0];
						that.breakthrough_content[i] = data_str[1];
						that.dupli_breakthrough_content[i] = data_str[1];
					}
					that.selected_breakthrough = that.breakthrough_dropdown[0]+that.breakthrough_content[0];
					that.edit_breakthrough = false;
					that.showBreakthrough = true;
				}
				// console.log('breakthrough',this.breakthrough)
			})
			// data
			let DdFilter = {email:{eq:localStorage.getItem('email')}, processTypeId:{eq:"c1c17711-b4a3-4dcc-98b5-fe73ee0791d2"}, type:{eq:"process-stage"}, title:{eq:"data-gather"}};
			this.api.ListFrameworks(DdFilter,10000).then(function (data) {
				if(data.items.length == 0){
					that.edit_datadump = false;
					that.datadump_content[0] = '';
					that.data_dump[0] = '';
					that.datadump_content[1] = '';
					that.data_dump[1] = '';
				}else{
					// // console.log(data.items[0].content);
					that.DdId = data.items[0].id;
					that.Ddupdate = true;
					that.data_dump = JSON.parse(data.items[0].content);
					that.datadump_content = JSON.parse(data.items[0].content);
					that.dupli_datadump_content = JSON.parse(data.items[0].content);
					that.prev_datadump_content = JSON.parse(data.items[0].content);
					that.selected_datadump = that.datadump_content[0];
					that.edit_datadump = false;
					that.showDatadump = true;
				}
			})
			// challenges
			let QtFilter = {email:{eq:localStorage.getItem('email')}, processTypeId:{eq:"c1c17711-b4a3-4dcc-98b5-fe73ee0791d2"}, type:{eq:"process-stage"}, title:{eq:"breakthrough-question"}};
			this.api.ListFrameworks(QtFilter,10000).then(function (data) {
				if(data.items.length == 0){
					that.edit_question = false;
					that.question_content[0] = '';
					that.question_dropdown[0] = '';
					that.question[0] = '';
					that.question_content[1] = '';
					that.question_dropdown[1] = 'How might...';
					that.question[1] = '';
				}else{
					// console.log(data.items[0].content);
					that.QtId = data.items[0].id;
					that.Qtupdate = true;
					that.question = JSON.parse(data.items[0].content);
					that.prev_question_content = JSON.parse(data.items[0].content);
					that.dupli_question_content = JSON.parse(data.items[0].content)
					var ques = JSON.parse(data.items[0].content);
					for(var i=0;i<ques.length;i++){
						let data_str = (ques[i]).split("\n");
						// console.log(data_str)
						that.question_dropdown[i] = data_str[0];
						that.question_content[i] = data_str[1];
						that.dupli_question_content[i] = data_str[1];
					}
					that.selected_question = that.question_dropdown[0]+that.question_content[0];
					that.edit_question = false;
					that.showQuestion = true;
				}
			})
			setTimeout(() => {
				resolve([BtFilter,DdFilter,QtFilter]);
			}, 1000);
		})

		clarifyPromise.then(()=> {
			$('.loader-container').css('display','none');
		})
	    jQuery(document).on("click", ".popaccordian__rowhead", function(e) {
	    	e.stopPropagation();
	  		var thisPar = jQuery(this).parents(".popaccordian__row");
	

	  		if( !jQuery(thisPar).hasClass("popaccordian__row--active") ) {
	  			thisPar.find(".popaccordian__rowbody").slideDown();
	  			thisPar.siblings().find(".popaccordian__rowbody").slideUp();
	  			thisPar.addClass("popaccordian__row--active");
	  			thisPar.siblings().removeClass("popaccordian__row--active");
	  		} else {
	  			thisPar.siblings().find(".popaccordian__rowbody").slideUp();
	  		}
	    })

		// from ideate
		if(localStorage.getItem('ideateToclarify') == 'true'){
			localStorage.setItem('ideateToclarify','false');
			that.backtoideate = true;
			console.log(that.question_content)
			console.log(that.question_content[0])
			// if(that.question_content[0] == '' || that.question_content[0] == undefined){
				await setTimeout(function(){
					console.log('--->qtstart')
					// that.QtDiverge(1);
					that.QtStart(1);
				},1000);
			// }else {
			// 	await setTimeout(function(){
			// 		console.log('--->qtdiverge')
			// 		that.QtDiverge();
			// 	},1000);
			// }
		}
		
	    // console.log(this.BtId)
		// console.warn(this.breakthrough_content);
		// console.warn(this.breakthrough);
	}

	// Goal start btn
	public BtStart(){
		this.BtId = '0';
		this.edit_breakthrough = true;
		this.breakthrough_content[0] = '';
    	this.breakthrough[0] = '';
    	this.breakthrough_dropdown[0] = '';
    	this.breakthrough_content[1] = '';
    	this.breakthrough_dropdown[1] = 'It would be great if...';
    	this.breakthrough[1] = '';
		this.BtDiverge();
	}

	public BtConverge(){
		this.cancelBt();
		this.showBreakthrough = false;
		this.edit_breakthrough = false;
	}

	// slider video hide and show
	public sliderVideo(){
		this.showVideo = !this.showVideo;
	}

	// goal diverge btn
	public BtDiverge(trackit = 0, currentTrack =''){
		this.showBreakthrough = false;
		this.edit_breakthrough = true;
		this.cancelDd();
		this.cancelQt();
		if(trackit == 1){
			this.trackLabel = true;
			this.trackbtn = true;
			this.currentTracker = currentTrack;
			this.openEditmode(true,false,false);
			this.clarifyEyeIcon = true;
		}else{
			this.PreviousTracker = 'Goal';
			this.openEditmode(true,false,false,true);
			this.clarifyEyeIcon = false;
		}
		var that = this;
		// setTimeout(function(){
		// 	that.stickChallenge();
		// },500)
		setTimeout(function(){
			$(document).scrollTop(0);
			// goal delete icon hide or show
			let allBtDelIcon = document.querySelectorAll('.card-body__minus');
			console.log(allBtDelIcon)
			  if(allBtDelIcon.length == 1) {
				allBtDelIcon[0].classList.add('card-body__minus--hide');
			}
		}, 10);

	}

	// duplicate goal item
	public dupli_submitBreakthrough(backto = false) {
		// console.log(this.breakthrough_content)
		// console.log(this.selected_breakthrough)
		// console.log(this.dupli_breakthrough_content)
		if(this.trackLabel){
			for(let i =1; i < this.breakthrough.length; i++){
				if(this.dupli_breakthrough_content[i] != this.breakthrough_content[i] && this.dupli_breakthrough_content[i] != undefined ){
					// console.warn(this.dupli_breakthrough_content[i]);
					this.addBtLine();
					this.breakthrough_content.push(this.dupli_breakthrough_content[i]);
					this.breakthrough_content[0] =  this.dupli_breakthrough_content[i]; 
					this.selected_breakthrough = this.breakthrough_dropdown[i] + this.dupli_breakthrough_content[i];
				}
			}
		}else {
			this.breakthrough_content = this.dupli_breakthrough_content;
		}
		// console.log(this.breakthrough_content);
		// console.log(this.dupli_breakthrough_content);
		if(backto){
		this.submitBreakthrough(false,true);
		}else{
			this.submitBreakthrough(false);
		}
	}

	//goal converge
	public async submitBreakthrough(click = true,callback=false){
		this.openEditmode(true,false,false,click);

  		var change_selected = 0;
  		// // console.log(this.breakthrough_content)
  		for(var i=1; i<this.breakthrough_content.length;i++){
  			if(this.breakthrough_content[i] == this.breakthrough_content[0] && this.breakthrough_dropdown[i] == this.breakthrough_dropdown[0]){
  				change_selected = 1;
  			}
			if(this.breakthrough_content[i] == null || this.breakthrough_content[i] == ''){
				this.breakthrough_content.splice(i,1);
				this.breakthrough_dropdown.splice(i,1);
			}
			if(i == this.breakthrough_content.length - 1 && change_selected == 0){
  				this.breakthrough_content[0] = '';
  				this.breakthrough_dropdown[0] = '';
  			}
		}
	  	if(this.breakthrough_content.length > 1){
	  		this.loading_breakthrough = true;
	  		var that = this;
	  		var content = [];
	  		
	  		for(var i=0; i<this.breakthrough_content.length;i++){
	  			content[i] = this.breakthrough_dropdown[i].concat("\n"+this.breakthrough_content[i]);
	  		}
	  		// // console.log(content);
	  		if(!this.Btupdate){
	  		await	this.api.CreateFramework({email:localStorage.getItem('email'), title:"vision", processTypeId:"c1c17711-b4a3-4dcc-98b5-fe73ee0791d2",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:JSON.stringify(content)}).then(function (data){
						that.edit_breakthrough = false;
						that.BtId = data.id;
						that.Btupdate = true;
						that.loading_breakthrough = false;
						that.breakthrough = JSON.parse(data.content);
						that.prev_breakthrough_content = JSON.parse(data.content);
						var breakthrough = JSON.parse(data.content);
						that.showBreakthrough = false;
						for(var i=0;i<breakthrough.length;i++){
							let data_str = (breakthrough[i]).split("\n");
							// console.log(data_str)
							that.breakthrough_dropdown[i] = data_str[0];
							that.breakthrough_content[i] = data_str[1];
						}
						that.selected_breakthrough = that.breakthrough_dropdown[0]+that.breakthrough_content[0];
						setTimeout(function() {
							this.toastr.options = {
								closeButton: true,
								progressBar: true,
								timeOut: 2000
							};
							this.toastr.success('Your thought has been updated!', 'Nice!');
						}, 1000);
					}) 
	  		}else{
	  		await this.api.UpdateFramework({id:this.BtId,email:localStorage.getItem('email'), title:"vision", processTypeId:"c1c17711-b4a3-4dcc-98b5-fe73ee0791d2",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:JSON.stringify(content)}).then(function (data){
						that.edit_breakthrough = false;
						that.loading_breakthrough = false;
						that.breakthrough = JSON.parse(data.content);
						that.prev_breakthrough_content = JSON.parse(data.content);
						that.showBreakthrough = false;
						var breakthrough = JSON.parse(data.content);
						for(var i=0;i<breakthrough.length;i++){
							let data_str = (breakthrough[i]).split("\n");
							// // console.log(data_str);
							that.breakthrough_dropdown[i] = data_str[0];
							that.breakthrough_content[i] = data_str[1];
						}
						that.selected_breakthrough = that.breakthrough_dropdown[0]+that.breakthrough_content[0];
						setTimeout(function() {
							this.toastr.options = {
								closeButton: true,
								progressBar: true,
								timeOut: 2000
							};
							this.toastr.success('Your thoughts have been updated!', 'Nice!');
						}, 1000);
					}) 
	  		}
  		
  		}else{
  			if(this.BtId && this.BtId != '0'){
	  			this.delBreakthrough();
	  		}
  			this.BtId = '';
	  		this.edit_breakthrough = false;
  		}
		if(callback){
			switch(this.PreviousTracker){
				case 'Data':
					this.DdDiverge();
					break;
				case 'Challenge':
					this.QtDiverge();
					break;
				default:
					this.openEditmode(true,true,true)
			}
		}
  	}

	// delete goal content
  	public delBreakthrough(){
	  	var that = this;
		  this.dupli_breakthrough_content = [];
		  try {
		  	this.api.DeleteFramework({id:this.BtId}).then(function(){
		  		that.breakthrough_content = [];
		  		that.prev_breakthrough_content = [];
		  		that.breakthrough = [];
		  		that.Btupdate = false;
		  		that.BtId = '';
		  		that.edit_breakthrough = false;
		    	that.breakthrough_content[0] = '';
		    	that.breakthrough_dropdown[0] = '';
		    	that.breakthrough[0] = '';
		    	that.breakthrough_content[1] = '';
		    	that.breakthrough_dropdown[0] = 'It would be great if...';
		    	that.breakthrough[1] = '';
		  		setTimeout(function() {
		            this.toastr.options = {
		                closeButton: true,
		                progressBar: true,
		                timeOut: 2000
		            };
		            this.toastr.success('Your thoughts has been deleted!', 'Nice!');
		        }, 1000);
		  	})
		  } catch (err) {
			console.log(err)
		  }
	}

  	public submit_converge_breakthrough(){
		if(this.pin_challenge){
			this.stickChallenge();
		}
  		this.edit_breakthrough = false;
  		this.showBreakthrough = true;
		this.trackLabel = false;
		this.trackbtn = false;
		this.openEditmode(true,true,true,true,'block','#fff');
  	}

	// go to next step
	public async gotonextstep(next_step_val){
		switch (next_step_val) {
			case 'data':
				this.submit_converge_breakthrough();
				if(!this.DdId && !this.edit_datadump){
					this.DdStart()
				}else{
					this.DdDiverge();
				}
				break;
			case 'challenge':
				this.submit_converge_datadump();
				if(!this.QtId && !this.edit_question){
					this.QtStart();
				}else{
					this.QtDiverge();
				}
				break;
			case 'ideate':
				this.submit_converge_question();
				localStorage.setItem('gotoideate','true');
				this.router.navigate(['/challenges/ideate']);
				break;
			default:
				break;
		}
	}

	// add new in goal diverge
  	public addBtLine(){
		if(this.trackbtn){
			this.trackbtn = false;
		}
		setTimeout(function(){
			$('.card-body__editcol.hideblk:last-child').css('display','block');
		},50);
  		if(document.activeElement.id){
	  		if(this.breakthrough.length <= parseInt(document.activeElement.id.replace("Bt","")) + 1 ){
	  			this.breakthrough.push(this.breakthrough.length);
	  			this.breakthrough_dropdown[this.breakthrough.length - 1] = 'It would be great if...';
	  		}else{
	  			for(var i=parseInt(document.activeElement.id.replace("Bt",""))+1; i<=this.breakthrough.length-1; i++){
	  				if(this.breakthrough_content[i] == '' || this.breakthrough_content[i] == undefined){
	  					document.getElementById("Bt"+ (i)).focus();
	  					break;
	  				}
	  				if(i == this.breakthrough.length - 1 && this.breakthrough_content[i] != '' && this.breakthrough_content[i] != undefined){
	  					this.breakthrough.push(this.breakthrough.length);
	  					this.breakthrough_dropdown[this.breakthrough.length - 1] = 'It would be great if...';
	  				}
	  			}
	  		}
	  	}else{
	  		this.breakthrough.push(this.breakthrough.length);
			this.breakthrough_dropdown[this.breakthrough.length - 1] = 'It would be great if...';
	  	}

		// delete icon hide or show
		setTimeout(() => {
			let allBtDelIcon = document.querySelectorAll('.card-body__minus');
			console.log(allBtDelIcon)
			  if(allBtDelIcon.length > 1) {
				allBtDelIcon[0].classList.remove('card-body__minus--hide');
			}
		},10)
  	}

	//goal card edit 
  	public subBtLine(i){
  		if(this.selected_breakthrough == this.breakthrough_dropdown[i]+this.breakthrough_content[i]){
  			this.selected_breakthrough = '';
  			this.breakthrough_dropdown[0] = '';
  			this.breakthrough_content[0] = '';
  		}
	  	this.breakthrough_content.splice(i,1);
		this.dupli_breakthrough_content.splice(i,1);
	  	this.breakthrough_dropdown.splice(i,1);
	  	this.breakthrough.splice(this.breakthrough.length-1,1);

		// delete icon hide or show
		setTimeout(() => {
			let allBtDelIcon = document.querySelectorAll('.card-body__minus');
			console.log(allBtDelIcon)
			if(allBtDelIcon.length == 1) {
				allBtDelIcon[0].classList.add('card-body__minus--hide');
			}
		},10)
  	}

  	public async BtOrderChange(event) {
		var that = this;
	  	var content = [];	
	  	moveItemInArray(this.breakthrough_content, event.previousIndex, event.currentIndex);
	  	moveItemInArray(this.breakthrough_dropdown, event.previousIndex, event.currentIndex);  	
	  	for(var i=0; i<this.breakthrough_content.length;i++){
			content[i] = this.breakthrough_dropdown[i].concat("\n"+this.breakthrough_content[i]);
		}
	  	this.api.UpdateFramework({id:this.BtId,email:localStorage.getItem('email'), title:"vision", processTypeId:"c1c17711-b4a3-4dcc-98b5-fe73ee0791d2",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:JSON.stringify(content)}).then(function (data){
			that.edit_breakthrough = false;
			that.breakthrough = JSON.parse(data.content);
			that.prev_breakthrough_content = JSON.parse(data.content);
	    	var breakthrough = JSON.parse(data.content);
	    	for(var i=0;i<breakthrough.length;i++){
	    		let data_str = (breakthrough[i]).split("\n");
	    		// console.log(data_str)
	    		that.breakthrough_dropdown[i] = data_str[0];
	    		that.breakthrough_content[i] = data_str[1];
	    	}
	    	that.selected_breakthrough = that.breakthrough_dropdown[0]+that.breakthrough_content[0];
	  		setTimeout(function() {
	            this.toastr.options = {
	                closeButton: true,
	                progressBar: true,
	                timeOut: 2000
	            };
	            this.toastr.success('Your thoughts has been updated!', 'Nice!');
	        }, 1000);
		})
  	}

	// data start btn
  	public DdStart(){
		this.DdId = '0';
		this.edit_datadump = true;
		this.datadump_content[0] = '';
    	this.data_dump[0] = '';
    	this.datadump_content[1] = '';
    	this.data_dump[1] = '';
		this.DdDiverge();
	}

  	public DdConverge(){
  		this.cancelDd();
		this.showDatadump = false;
		this.edit_datadump = false;
	}

	// data diverge btn
	public DdDiverge(trackit=0,currentTrack=''){
		// console.warn(this.breakthrough_dropdown);
		this.showDatadump = false;
		this.edit_datadump = true;
		this.cancelQt();
		this.cancelBt();

		if(trackit == 1){
			this.openEditmode(false,true,false);
			this.trackLabel = true;
			this.trackbtn = true;
			this.currentTracker = currentTrack;
			this.clarifyEyeIcon = true;
		}else{
			this.PreviousTracker = 'Data';
			this.openEditmode(false,true,false,true);
			this.clarifyEyeIcon = false;
		}
		var that = this;
		// setTimeout(function(){
		// 	that.stickChallenge();
		// },500)
		setTimeout(function(){
			$(document).scrollTop(0);
			// delete icon hide or show
			let allBtDelIcon = document.querySelectorAll('.card-body__minus');
			console.log(allBtDelIcon)
			if(allBtDelIcon.length == 1) {
				allBtDelIcon[0].classList.add('card-body__minus--hide');
			}
		}, 10);
		
	}

	public dupli_submitDatadump(backto = 0){
		// console.log(this.datadump_content[0]);
		// console.log(this.dupli_datadump_content)
		for(let i =1; i < this.data_dump.length; i++){
			if(this.dupli_datadump_content[i] != this.datadump_content[i]){
				// console.warn(this.dupli_datadump_content[i]);
				this.datadump_content.push(this.dupli_datadump_content[i]);
				if(this.datadump_content[0].length > 0 && this.trackbtn){
					this.datadump_content[0].push(this.dupli_datadump_content[i]);
				}
			}
		}
		// console.log(this.breakthrough_content);
		// console.log(this.dupli_breakthrough_content);
		if(backto){
			this.submitDatadump(false,true);
		}else{
			this.submitDatadump(false);
		}
	}

	// submit data converge
  	public async submitDatadump(click = true,callback=false){
		this.openEditmode(false,true,false,click);

  		// console.log(this.datadump_content);
  		for(var i=1;i<this.datadump_content.length;i++){
  			if(this.datadump_content[i] == null || this.datadump_content[i] == ''){
  				this.datadump_content.splice(i,1);
  				this.data_dump.splice(this.data_dump.length-1,1);
  			}
  		}
	  	if(this.datadump_content.length > 1){
	  		this.loading_datadump = true;
	  		var that = this;
	  		
	  		for(var j=0; j<this.datadump_content[0].length; j++){
	  			for(var k=1; k<this.datadump_content.length; k++){
	  				if(this.datadump_content[0][j] == this.datadump_content[k]){
	  					break;
	  				}
	  				if(k == this.datadump_content.length - 1 && this.datadump_content[0][j] != this.datadump_content[k]){
	  					this.datadump_content[0].splice(j,1);
	  				}
	  			}
	  		}

	  		if(!this.Ddupdate){
	  			await	this.api.CreateFramework({email:localStorage.getItem('email'), title:"data-gather", processTypeId:"c1c17711-b4a3-4dcc-98b5-fe73ee0791d2",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:JSON.stringify(this.datadump_content)}).then(function (data){
							that.edit_datadump = false;
							that.DdId = data.id;
							that.Ddupdate = true;
							that.data_dump = JSON.parse(data.content);
							that.loading_datadump = false;
							that.datadump_content = JSON.parse(data.content);
							that.prev_datadump_content = JSON.parse(data.content);
							that.showDatadump = false;
							setTimeout(function() {
								this.toastr.options = {
									closeButton: true,
									progressBar: true,
									timeOut: 2000
								};
								this.toastr.success('Your thought has been updated!', 'Nice!');
							}, 1000);
						}) 
	  		}else{
	  			await	this.api.UpdateFramework({id:this.DdId,email:localStorage.getItem('email'), title:"data-gather", processTypeId:"c1c17711-b4a3-4dcc-98b5-fe73ee0791d2",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:JSON.stringify(this.datadump_content)}).then(function (data){
							that.edit_datadump = false;
							that.loading_datadump = false;
							that.data_dump = JSON.parse(data.content);
							that.datadump_content = JSON.parse(data.content);
							// console.log(that.datadump_content[0])
							// console.log(typeof(that.datadump_content[0]));
							that.prev_datadump_content = JSON.parse(data.content);
							that.showDatadump = false;
							setTimeout(function() {
								this.toastr.options = {
									closeButton: true,
									progressBar: true,
									timeOut: 2000
								};
								this.toastr.success('Your thoughts have been updated!', 'Nice!');
							}, 1000);
						}) 
	  		}  		
  		}else{
  			if(this.DdId && this.DdId != '0'){
	  			this.delDatadump();
	  		}
  			this.DdId = '';
	  		this.edit_datadump = false;
  		}
		if(callback){
			switch(this.PreviousTracker){
				case 'Data':
					this.DdDiverge();
				case 'Challenge':
					this.QtDiverge();
			}
		}
  	}

	// delete data content
  	public delDatadump(){
	  	var that = this;
		this.trackLabel = false;
		this.dupli_datadump_content = [];
		try {
			this.api.DeleteFramework({id:this.DdId}).then(function(){
				that.datadump_content = [];
				that.prev_datadump_content = [];
				that.data_dump = [];
				that.Ddupdate = false;
				that.edit_datadump = false;
				that.DdId = '';
				that.datadump_content[0] = '';
				that.data_dump[0] = '';
				that.datadump_content[1] = '';
				that.data_dump[1] = '';
				setTimeout(function() {
					this.toastr.options = {
						closeButton: true,
						progressBar: true,
						timeOut: 2000
					};
					this.toastr.success('Your thoughts has been deleted!', 'Nice!');
				}, 1000);
			})
		} catch (err) {
			console.log(err)
		}
  	}
  	public submit_converge_datadump(){
		if(this.pin_challenge){
			this.stickChallenge();
		}
  		this.edit_datadump = false;
  		this.showDatadump = true;
		this.trackLabel = false;
		this.trackbtn = false;
		this.openEditmode(true,true,true,true,'block','#fff');
  	}

	// add new in data
  	public addDdLine(){
		if(this.trackbtn){
			this.trackbtn = false;
		}
		setTimeout(function(){
			$('.card-body__editcol.hideblk:last-child').css('display','block');
		},50);
  		if(document.activeElement.id){
		  	if(this.data_dump.length <= parseInt(document.activeElement.id.replace("Dd","")) + 1 ){
	  			this.data_dump.push(this.data_dump.length);
	  		}else{
	  			for(var i=parseInt(document.activeElement.id.replace("Dd",""))+1; i<=this.data_dump.length-1; i++){
	  				if(this.datadump_content[i] == '' || this.datadump_content[i] == undefined){
	  					document.getElementById("Dd"+ (i)).focus();
	  					break;
	  				}
	  				if(i == this.data_dump.length - 1 && this.datadump_content[i] != '' && this.datadump_content[i] != undefined){
	  					this.data_dump.push(this.data_dump.length);
	  				}
	  			}
	  		}
	  	}else{
	  		this.data_dump.push(this.data_dump.length);
	  	}

		// delete icon hide or show
		setTimeout(() => {
			let allBtDelIcon = document.querySelectorAll('.card-body__minus');
			console.log(allBtDelIcon)
			if(allBtDelIcon.length > 1) {
				allBtDelIcon[0].classList.remove('card-body__minus--hide');
			}
		},10)
  	}

  	public subDdLine(i){
	  	this.datadump_content.splice(i,1);
	  	this.dupli_datadump_content.splice(i,1);
	  	this.data_dump.splice(this.data_dump.length-1,1);
		// delete icon hide or show
		setTimeout(() => {
			let allBtDelIcon = document.querySelectorAll('.card-body__minus');
			console.log(allBtDelIcon)
			if(allBtDelIcon.length == 1) {
				allBtDelIcon[0].classList.add('card-body__minus--hide');
			}
		},10)
  	}

  	public async DdOrderChange(event) {
	  	// console.log(event);
	  	var that = this;
	  	moveItemInArray(this.datadump_content[0], event.previousIndex, event.currentIndex);
	  	this.api.UpdateFramework({id:this.DdId,email:localStorage.getItem('email'), title:"data-gather", processTypeId:"c1c17711-b4a3-4dcc-98b5-fe73ee0791d2",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:JSON.stringify(this.datadump_content)}).then(function (data){
			that.edit_datadump = false;
			that.data_dump = JSON.parse(data.content);
	    	that.datadump_content = JSON.parse(data.content);
	    	that.prev_datadump_content = JSON.parse(data.content);
	    	that.selected_datadump = that.datadump_content[0];
	  		setTimeout(function() {
	            this.toastr.options = {
	                closeButton: true,
	                progressBar: true,
	                timeOut: 2000
	            };
	            this.toastr.success('Your thoughts has been updated!', 'Nice!');
	        }, 1000);
		})
  	}
	// challenge start btn
  	public QtStart(trackit = 0){
		console.warn('start question')
		if(trackit == 1) {
			if(this.question_content[0] == '' || this.question_content[0] == undefined){
				this.QtId = '0';
			this.edit_question = true;
			this.question_content[0] = '';
			this.question_dropdown[0] = '';
			this.question[0] = '';
			this.question_content[1] = '';
			this.question_dropdown[1] = 'How might...';
			this.question[1] = '';
			// $('.fa-pencil').click();
			this.QtDiverge();
			}else{
				this.QtDiverge(1);
			}
			// $('button.card__h3button').click();
		}else {
			this.QtId = '0';
			this.edit_question = true;
			this.question_content[0] = '';
			this.question_dropdown[0] = '';
			this.question[0] = '';
			this.question_content[1] = '';
			this.question_dropdown[1] = 'How might...';
			this.question[1] = '';
			// $('.fa-pencil').click();
			this.QtDiverge();
		}
		$('.loader-container').css('display','none');
	}

  	public QtConverge(){
  		this.cancelQt();
		this.showQuestion = false;
		this.edit_question = false;
	}

	// challenge diverge
	public QtDiverge(trackit = 0){
		this.showQuestion = false;
		this.edit_question = true;
		// console.warn(trackit)
		if(trackit == 1){
			// console.warn('qtdiverge if')
			this.openEditmode(false,false,true,true);
			this.trackbtn =true;
			this.trackLabel = true;
			this.PreviousTracker = 'Ideate';
			this.currentTracker = 'Challenge';
			$('.loader-container').css('display','none');
			this.clarifyEyeIcon = true;
		}else{
			this.cancelDd();
			this.cancelBt();
			// console.warn('qtdiverge else')
			this.openEditmode(false,false,true,true);
			this.PreviousTracker = 'Challenge';
			this.clarifyEyeIcon = false;
		}
		var that = this;
		// setTimeout(function(){
		// 	that.stickChallenge();
		// },500)
		setTimeout(function(){
			$(document).scrollTop(0);
			let allBtDelIcon = document.querySelectorAll('.card-body__minus');
			console.log(allBtDelIcon)
			if(allBtDelIcon.length == 1) {
				allBtDelIcon[0].classList.add('card-body__minus--hide');
			}
		}, 10);
		console.log(this.showQuestion)
		console.log(this.edit_question)
	}

	// submit question duplicate item
	public dupli_submitQuestion(backto = false) {
		// console.log(this.question_content);
		// console.log(this.prev_question_content);
		// console.log(this.dupli_question_content);
		if(this.question_content[0].length > 0 && this.trackLabel){
			for(let i =1; i < this.dupli_question_content.length; i++){
				if(this.dupli_question_content[i] != this.question_content[i] && this.question_content != ''){
					// console.warn(i)
					this.addQtLine();
					// console.warn(this.question_content)
					// console.warn(this.dupli_question_content[i])
					this.question_content.push(this.dupli_question_content[i]);
					this.question_content[0] = this.dupli_question_content[i];
				}
			}
		}else {
			this.question_content = this.dupli_question_content;
		}
		// console.log(this.question);
		// console.warn(this.question_content[0]);
		// console.log(this.selected_question);
		if(backto){
			this.submitQuestion(false,true);
		}else{
			this.submitQuestion(false);
		}
	}

	// submit challenge 
  	public submitQuestion(click = true,trackit = false){
		this.openEditmode(false,false,true,click);

  		var change_selected = 0;
  		// // console.log(this.question_content)
  		for(var i=1; i<this.question_content.length;i++){
  			if(this.question_content[i] == this.question_content[0] && this.question_dropdown[i] == this.question_dropdown[0]){
  				change_selected = 1;
  			}
			if(this.question_content[i] == null || this.question_content[i] == ''){
				this.question_content.splice(i,1);
				this.question_dropdown.splice(i,1);
			}
			if(i == this.question_content.length - 1 && change_selected == 0){
  				this.question_content[0] = '';
  				this.question_dropdown[0] = '';
  			}
		}
	  	if(this.question_content.length > 1){
	  		this.loading_question = true;
	  		var that = this;
	  		var content = [];
	  		
	  		for(var i=0; i<this.question_content.length;i++){
	  			content[i] = this.question_dropdown[i].concat("\n"+this.question_content[i]);
	  		}
	  		// console.log(content);
	  		if(!this.Qtupdate){
	  			this.api.CreateFramework({email:localStorage.getItem('email'), title:"breakthrough-question", processTypeId:"c1c17711-b4a3-4dcc-98b5-fe73ee0791d2",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:JSON.stringify(content)}).then(function (data){
		  			that.edit_question = false;
		  			that.QtId = data.id;
		  			that.Qtupdate = true;
		  			that.loading_question = false;
		  			that.question = JSON.parse(data.content);
		  			that.prev_question_content = JSON.parse(data.content);
		        	var ques = JSON.parse(data.content);
		        	that.showQuestion = false;
		        	for(var i=0;i<ques.length;i++){
		        		let data_str = (ques[i]).split("\n");
		        		// console.log(data_str)
		        		that.question_dropdown[i] = data_str[0];
		        		that.question_content[i] = data_str[1];
		        	}
		        	that.selected_question = that.question_dropdown[0]+that.question_content[0];
			  		setTimeout(function() {
			            this.toastr.options = {
			                closeButton: true,
			                progressBar: true,
			                timeOut: 2000
			            };
			            this.toastr.success('Your thought has been updated!', 'Nice!');
			        }, 1000);
		  		}) 
	  		}else{
	  			this.api.UpdateFramework({id:this.QtId,email:localStorage.getItem('email'), title:"breakthrough-question", processTypeId:"c1c17711-b4a3-4dcc-98b5-fe73ee0791d2",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:JSON.stringify(content)}).then(function (data){
		  			that.edit_question = false;
		  			that.loading_question = false;
		  			that.question = JSON.parse(data.content);
		  			that.prev_question_content = JSON.parse(data.content);
		  			that.showQuestion = false;
		        	var ques = JSON.parse(data.content);
		        	for(var i=0;i<ques.length;i++){
		        		let data_str = (ques[i]).split("\n");
		        		// console.log(data_str)
		        		that.question_dropdown[i] = data_str[0];
		        		that.question_content[i] = data_str[1];
		        	}
		  			that.selected_question = that.question_dropdown[0]+that.question_content[0];
			  		setTimeout(function() {
			            this.toastr.options = {
			                closeButton: true,
			                progressBar: true,
			                timeOut: 2000
			            };
			            this.toastr.success('Your thoughts have been updated!', 'Nice!');
			        }, 1000);
		  		}) 
	  		}
  		
  		}else{
  			if(this.QtId && this.QtId != '0'){
	  			this.delQuestion();
	  		}
  			this.QtId = '';
	  		this.edit_question = false;
  		}
		// if(this.backtoideate){
		// 	this.backtoideate = false;
		// 	this.router.navigate(['/challenges/ideate']);
		// }
		if(trackit){
			switch(this.PreviousTracker){
				case 'Data':
					this.DdDiverge();
					break;
				case 'Challenge':
					this.QtDiverge();
					break;
				case 'Ideate':
					this.router.navigate(['/challenges/ideate'])
					break;
			}
		}
  	}

  	public submit_converge_question(){
		if(this.pin_challenge){
			this.stickChallenge();
		}
  		this.edit_question = false;
  		this.showQuestion = true;
		this.trackLabel = false;
		this.trackbtn = false;
		this.openEditmode(true,true,true,true,'block','#fff');
  	}

	// delete challenge content
  	public delQuestion(){  	
	  	var that = this;
		this.dupli_question_content = [];
		try {
			this.api.DeleteFramework({id:this.QtId}).then(function(){
				that.question_content = [];
				that.question_dropdown = [];
				that.question = [];
				that.prev_question_content = [];
				that.Qtupdate = false;
				that.QtId = '';
				that.edit_question = false;
				that.question_content[0] = '';
				that.question_dropdown[0] = '';
				that.question[0] = '';
				that.question_content[1] = '';
				that.question_dropdown[1] = 'How might...';
				that.question[1] = '';
				setTimeout(function() {
					this.toastr.options = {
						closeButton: true,
						progressBar: true,
						timeOut: 2000
					};
					this.toastr.success('Your thoughts has been deleted!', 'Nice!');
				}, 1000);
			})
		} catch (err) {
			console.log(err)
		}
  	}

	// add new in challenge
  	public addQtLine(){
		if(this.trackbtn){
			this.trackbtn = false;
		}
		setTimeout(function(){
			$('.card-body__editcol.hideblk:last-child').css('display','block');
		},50);
  		if(document.activeElement.id){
	  		if(this.question.length <= parseInt(document.activeElement.id.replace("Qt","")) + 1 ){
	  			this.question.push(this.question.length);
	  			this.question_dropdown[this.question.length - 1] = 'How might...';
	  		}else{
	  			for(var i=parseInt(document.activeElement.id.replace("Qt",""))+1; i<=this.question.length-1; i++){
	  				if(this.question_content[i] == '' || this.question_content[i] == undefined){
	  					document.getElementById("Qt"+ (i)).focus();
	  					break;
	  				}
	  				if(i == this.question.length - 1 && this.question_content[i] != '' && this.question_content[i] != undefined){
	  					this.question.push(this.question.length);
	  					this.question_dropdown[this.question.length - 1] = 'How might...';
	  				}
	  			}
	  		}
	  	}else{
	  		this.question.push(this.question.length);
			this.question_dropdown[this.question.length - 1] = 'How might...';
	  	}
		// delete icon hide or show
		setTimeout(() => {
			let allBtDelIcon = document.querySelectorAll('.card-body__minus');
			console.log(allBtDelIcon)
			  if(allBtDelIcon.length > 1) {
				allBtDelIcon[0].classList.remove('card-body__minus--hide');
			}
		},10)
		// console.warn(this.question_content)
  	}
	// challenge list of questions
  	public subQtLine(i){
  		if(this.selected_question == this.question_dropdown[i]+this.question_content[i]){
  			this.selected_question = '';
  			this.question_dropdown[0] = '';
  			this.question_content[0] = '';
  		}
	  	this.question_content.splice(i,1);
	  	this.question_dropdown.splice(i,1);
	  	this.question.splice(this.question.length-1,1);
		// delete icon hide or show
		setTimeout(() => {
			let allBtDelIcon = document.querySelectorAll('.card-body__minus');
			console.log(allBtDelIcon)
			  if(allBtDelIcon.length == 1) {
				allBtDelIcon[0].classList.add('card-body__minus--hide');
			}
		},10)
  	}

  	public async QtOrderChange(event) {
	  	// console.log(event);
	  	var that = this;
	  	var content = [];	
	  	moveItemInArray(this.question_content, event.previousIndex, event.currentIndex);
	  	moveItemInArray(this.question_dropdown, event.previousIndex, event.currentIndex);  	
	  	for(var i=0; i<this.question_content.length;i++){
			content[i] = this.question_dropdown[i].concat("\n"+this.question_content[i]);
		}
	  	this.api.UpdateFramework({id:this.QtId,email:localStorage.getItem('email'), title:"breakthrough-question", processTypeId:"c1c17711-b4a3-4dcc-98b5-fe73ee0791d2",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:JSON.stringify(content)}).then(function (data){
			that.edit_question = false;
			that.question = JSON.parse(data.content);
			that.prev_question_content = JSON.parse(data.content);
	    	var ques = JSON.parse(data.content);
	    	for(var i=0;i<ques.length;i++){
	    		let data_str = (ques[i]).split("\n");
	    		// console.log(data_str)
	    		that.question_dropdown[i] = data_str[0];
	    		that.question_content[i] = data_str[1];
	    	}
	    	that.selected_question = that.question_dropdown[0]+that.question_content[0];
	  		setTimeout(function() {
	            this.toastr.options = {
	                closeButton: true,
	                progressBar: true,
	                timeOut: 2000
	            };
	            this.toastr.success('Your thoughts has been updated!', 'Nice!');
	        }, 1000);
		})
  	}

  	public checkBreakthrough(content1, content2){
	  	this.selected_breakthrough = content1+content2;
	  	this.breakthrough_dropdown[0] = content1;
	  	this.breakthrough_content[0] = content2;
  		this.submitBreakthrough(false);
  	}

  	public checkDataDump(){
  		var that = this;
  		this.datadump_content[0] = [];
  		jQuery(".checkedbox-dumpData:checkbox").each(function(i) {
	        var value = jQuery(this).val();
	        // console.log(value);
	        if(jQuery(this).prop('checked') == true){
	  			that.datadump_content[0].push(value);
	  		}
			})
  		this.submitDatadump(false);
  	}

  	public checkQuestion(content1, content2, i){
  		if(content2.indexOf('?') > -1){
		  	this.selected_question = content1+content2;
		  	this.question_dropdown[0] = content1;
		  	this.question_content[0] = content2;
	  		this.submitQuestion();
	  	}else{
			Swal.fire({
				  title: "Do you want to add a question mark to the end of this challenge?",
				  type: "question",
					showCancelButton: true,
				  confirmButtonText: "Yes",
				  cancelButtonText: "No"
			  }).then((result) => {
				  if (result['value'] == true) {
					content2 = content2 + '?';
					this.selected_question = content1+content2;
					this.question_dropdown[0] = content1;
					this.question_content[0] = content2;
					this.question_content[i] = this.question_content[i] + '?';
					this.submitQuestion();
					jQuery('.swal2-container').addClass('hide');
					jQuery('body').removeClass('swal2-shown');
					jQuery('html').removeClass('swal2-shown');
				  }else{
					this.selected_question = content1+content2;
					this.question_dropdown[0] = content1;
					this.question_content[0] = content2;
					this.submitQuestion();
					jQuery('.swal2-container').addClass('hide');
					jQuery('body').removeClass('swal2-shown');
					jQuery('html').removeClass('swal2-shown');
				  }
			  },function(dismiss){
				  jQuery('.swal2-container').addClass('hide');
				  jQuery('body').removeClass('swal2-shown');
				  jQuery('html').removeClass('swal2-shown');
			  });
			//   let sw_confirmbtn = document.querySelector('.swal2-confirm') as HTMLElement;
			//   sw_confirmbtn.classList.add('gbg');
			//   let cancelbtn = document.querySelector('.swal2-show button.swal2-cancel.swal2-styled') as HTMLElement;
			//   let icon = document.querySelector('.swal2-icon.swal2-question.swal2-animate-question-icon') as HTMLElement;
			//   $('span.swal2-icon-text').addClass('question');
			//   setTimeout(function(){
			// 		$('.swal2-title').css('font-size','20px');
			// 	  icon.style.fontSize = '70px';
			// 	  icon.style.color = '#F8BB86';
			// 	  icon.style.justifyContent = 'center';
			// 	  cancelbtn.classList.add('wbg');
			//   },50)
	  	}
  	}

	//cancel goal diverge
  	public cancelBt(){
		if(this.pin_challenge){
			this.stickChallenge();
		}
		this.openEditmode(true,true,true,true,'block','#fff');
		this.trackLabel = false;
		this.trackbtn = false;

    	this.edit_breakthrough = false;
  		var breakthrough = this.prev_breakthrough_content;
  		this.breakthrough_dropdown = [];
  		this.breakthrough_content = [];
  		this.breakthrough = [];
    	for(var i=0;i<breakthrough.length;i++){
    		let data_str = (breakthrough[i]).split("\n");
    		// console.log(data_str)
    		this.breakthrough_dropdown[i] = data_str[0];
    		this.breakthrough_content[i] = data_str[1];
    		this.breakthrough[i] = i;
    	}
    	this.selected_breakthrough = this.breakthrough_dropdown[0]+this.breakthrough_content[0];
    	if(this.BtId == '0'){
    		this.edit_breakthrough = false;
    		this.BtId = '';
    		this.breakthrough_content[0] = '';
        	this.breakthrough_dropdown[0] = '';
        	this.breakthrough[0] = '';
        	this.breakthrough_content[1] = '';
        	this.breakthrough_dropdown[1] = 'It would be great if...';
        	this.breakthrough[1] = '';
    	}

    	if(this.BtId != '' && this.BtId != '0'){
    		this.showBreakthrough = true;
    	}
  	}

	// cancel data diverge
  	public cancelDd(){
		if(this.pin_challenge){
			this.stickChallenge();
		}
		this.openEditmode(true,true,true,true,'block','#fff');
		this.trackLabel = false;
		this.trackbtn = false;

  		this.edit_datadump = false;
  		var data_dump = this.prev_datadump_content;
  		this.datadump_content = [];
  		this.data_dump = [];
  		for(var i=0;i<data_dump.length;i++){
    		let data_str = data_dump[i];
    		this.datadump_content[i] = data_str;
    		this.data_dump[i] = i;
    	}
    	if(this.DdId == '0'){
    		this.edit_datadump = false;
    		this.DdId = '';
    		this.datadump_content[0] = '';
        	this.data_dump[0] = '';
        	this.datadump_content[1] = '';
        	this.data_dump[1] = '';
    	}
    	if(this.DdId != '' && this.DdId != '0'){
    		this.showDatadump = true;
    	}
  	}

	// cancel challenge diverge
  	public cancelQt(){
		if(this.pin_challenge){
			this.stickChallenge();
		}
		this.openEditmode(true,true,true,true,'block','#fff');
		this.trackLabel = false;
		this.trackbtn = false;

  		this.edit_question = false;
  		var ques = this.prev_question_content;
  		this.question_content = [];
  		this.question_dropdown = [];
  		this.question = [];
    	for(var i=0;i<ques.length;i++){
    		let data_str = (ques[i]).split("\n");
    		// console.log(data_str)
    		this.question_dropdown[i] = data_str[0];
    		this.question_content[i] = data_str[1];
    		this.question[i] = i;
    	}
    	this.selected_question = this.question_dropdown[0]+this.question_content[0];
    	if(this.QtId == '0'){
    		this.edit_question = false;
    		this.QtId = '';
    		this.question_content[0] = '';
        	this.question_dropdown[0] = '';
        	this.question[0] = '';
        	this.question_content[1] = '';
        	this.question_dropdown[1] = 'How might...';
        	this.question[1] = '';
    	}

    	if(this.QtId != '' && this.QtId != '0'){
    		this.showQuestion = true;
    	}
  	}

	// open slideout
  	public openPopup(content,showDivCon=0){
		  $('.tooltippopup').addClass('tooltippopup--active');
		  this.process_type = content;
		  this.popup_type = 'info';
		  this.DivergeConvergeVal=false;
		  var that = this;
		  this.allChallenge.getChallenge().then(function (challenge) {
			  // console.log(challenge);
			  that.all_data = challenge;
			  that.all_data['thinkCon'] = JSON.parse(that.all_data['thinkCon'][0]);
			});
			if(showDivCon == 1){
				setTimeout(function(){
					jQuery('.popaccordian__row:nth-child(2) .popaccordian__rowhead').trigger( "click" );
					// console.log('clicked');
				}, 10);
				this.DivergeConvergeVal = true;
	
			}
  	}
	// close slideout
  	public closePopup(){
  		$('.tooltippopup').removeClass('tooltippopup--active');
  		this.process_type = '';
  		this.popup_type = '';
		this.showVideo = false;
  	}

	//slideout button like instruction summary tools
  	public popupDetail(content){
  		this.popup_type = content;
  	}

  	// public gotoSection(section){
  	// 	this.closePopup();
  	// 	$('html,body').animate({
    //         scrollTop: $('.card-default:nth-child('+section+')').offset().top},
    //     'slow');
  	// }

	//delete data from this step modal
	public async DataModel(value){
		$('.cn-dmodal').css('display', 'block');
		this.deleteDatavalue = value;
		await this.allChallenge.delData.subscribe((data)=> {
			console.log(data);
			if(data) {
				this.deletedata();
				this.allChallenge.delData.next(false)
			}
		})
		// this.modal = this.modalService.open(content, { windowClass: 'in grey' });
	  }

	// delete goal,data and challenge content
	public deletedata(){
		// this.modal.close();
		$('.cn-dmodal').css('display', 'none');
		this.trackLabel =false;
		this.openEditmode(true,true,true,true,'block','#fff')
		console.log(this.deleteDatavalue)
		switch(this.deleteDatavalue){
			case 1:
				this.delBreakthrough();
				break;
			case 2:
				this.delDatadump();
				break;
			case 3:
				this.delQuestion();
				break;
			default:
				// console.log('error occured');
				break;
		}
	}

	//open edit view for goal,data and challenges
	public openEditmode(editgoal,editdata,editchall,sidenav=false,displaynone='none',bgcolor="#EBEBEB"){
		this.editGoalView = editgoal;
		this.editDataView = editdata;
		this.editChallengeView = editchall;
		// let togglemenu = document.querySelector('.trigger-mini-bar') as HTMLElement;
		// let mainnav = document.querySelector('.row.wrapper') as HTMLElement;
		let page = document.getElementById('page-wrapper');
		page.style.backgroundColor = bgcolor;
		let footer = document.querySelector('.footer') as HTMLElement;
		footer.style.backgroundColor = bgcolor;
		footer.style.border = '1px solid #CFDBE2'
		// if(sidenav){
		// 	togglemenu.click();
		// }
		if(editgoal && !editdata && !editchall){
			this.goalInput = false;
			this.dataInput = false;
			this.EditModeIcon = true;
			// mainnav.style.display = displaynone;
			this.editmodeService.editmode.next(true);
			this.hideOnEditMode = false;
		}else if(editdata  && !editgoal && !editchall){
			this.goalInput = true;
			this.dataInput = false;
			this.EditModeIcon = true;
			// mainnav.style.display = displaynone;
			this.editmodeService.editmode.next(true);
			this.hideOnEditMode = false;
		}else if(editchall && !editgoal && !editdata){
			this.goalInput = true;
			this.dataInput = true;
			this.EditModeIcon = true;
			// mainnav.style.display = displaynone;
			this.editmodeService.editmode.next(true)
			this.hideOnEditMode = false;
		}else{
		// console.warn("else block ");
		this.hideOnEditMode = true;
		this.pin_challenge = false;
		this.listGridView = 'listView';
		this.hideOnEditMode = true;
		this.EditModeIcon = false;
		this.editGoalView = true;
		this.editDataView = true;
		this.editChallengeView = true;
		this.goalInput = false;
		this.dataInput = false;
		// mainnav.style.display = displaynone;
		this.editmodeService.editmode.next(false);
		}
	}
	// active icon of list view (we are also used this variable for adding class in view)
	public activeIcon(viewtype){
		this.listGridView = viewtype;
		// console.log(this.listGridView)
		if(this.listGridView == 'gridView'){
			let items = document.querySelectorAll('.editmode__listcol');
			this.gridViewClass = 'editmode__listrow--grid' + items.length;
			// console.log(items.length);
		}
	}

	// hide diverge item 
	public eyesHideDiverge(){
		if(this.hideclarify){
			$('.card-body__editcol.hideblk').css('display','block');
			$('.card-body__subtitle span').css('opacity','1');
			this.hideclarify = false;
			this.hideDivLabel = 'Hide unconverged options';
		}else{
			$('.card-body__editcol.hideblk').css('display','none');
			$('.card-body__subtitle span').css('opacity','0.7');
			this.hideclarify = true;
			this.hideDivLabel = 'See all diverged options';
		}
	}

	// pin content
	public stickChallenge(){
		// console.warn(this.pin_challenge)
		this.pin_challenge = !this.pin_challenge;	
		let headDivHight = $('.info.card__headactive').outerHeight();
		let inputDivTop = $('.info.card__headactive').offset()?.top;
		let winHeight = jQuery(window).outerHeight();
		// console.log(inputDivTop);
		if(this.pin_challenge){
			$('.card-body__inner').height(winHeight - headDivHight- inputDivTop - 250 );
			$('.card-body__inner').height(winHeight - inputDivTop - 250 );
		}else{
			$('.card-body__inner').height('auto');
			$('.card-body__inner').height('auto');
		}
	}

	public notingTodo(){
		// console.log('you dont have any diverge data');
	}

	public openAi() {
		this.openAiData = [];
		this.selectAiData = [];
		var that = this;
		var all_data:any = ['Identify the root cause of the problem','Develop a range of potential solutions','Select the most effective solution','Implement the chosen solution','Monitor and evaluate the results','Lack of clear plan','Lack of accountability','Setting unrealistic goals','Lack of motivation','Distractions','Lack of focus','Lack of persistence','Lack of clarity','Fear of failure or success','Self-doubt or negative self-talk'];
		$('.popaccordian__gen__aidata').css('display', 'block');
		this.temprature = +this.temprature;
		let data = {
			prompt:`using ${this.statementStarter} Generate 5 goals for creative problem solving as ${this.personae}`,
			max_tokens:128,
			temperature: this.temprature,
		}
		this.http.post(`${environment.assessment_admin}api/v1/open_ai/completions.json`,data).subscribe(res => {
			console.warn(res['choices'][0])
			for (let i = 0; i < res['choices'][0]?.text.length; i++) {
				this.openAiData = res['choices'][0]?.text.split('\n')
				console.log(this.openAiData)
			}
		}), err=> {
			console.log(err);
			setTimeout(function() {
				this.toastr.options = {
					closeButton: true,
					progressBar: true,
					timeOut: 2000
				};
				this.toastr.error("Something went wrong!", "Sorry!");
			}, 1000);
		}
		// setTimeout(() => {
		// 	that.counter++;
		// 	if(that.counter > 2) {
		// 		that.counter = 0;
		// 	}
		// 	for(let i = that.counter * 5; i < (that.counter+1) * 5 ; i++) {
		// 			that.openAiData.push(all_data[i]);
		// 	}
		// 	console.log(that.openAiData);
		// },1000)
		console.log('open ai',this.openAiData)
		console.log(this.personae,this.statementStarter);
	}

	public selectedData() {
		console.warn(this.selectAiData);
		console.warn(this.breakthrough_dropdown);
		console.warn(this.breakthrough_content);
		for (let i = 0; i < this.openAiData.length; i++) {
			if(this.selectAiData[i]) {
				this.addBtLine();
				this.selectAiData[i] = false;
				this.dupli_breakthrough_content.push(this.openAiData[i]);
			}
		}
	}

}