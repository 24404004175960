import { Component,Injector } from '@angular/core';
import { OrgsComponent } from '../orgs.component';

@Component({
  selector: 'app-roster-table-view',
  templateUrl: './roster-table-view.component.html',
  styleUrls: ['./roster-table-view.component.scss']
})
export class RosterTableViewComponent {

  injectedCompo;

  constructor(private injector: Injector) {
    this.injectedCompo = this.injector.get(OrgsComponent);
  }
}
