import { Component, OnDestroy, OnInit, ElementRef } from "@angular/core";
import { Auth } from "../../auth/auth.service";
// import { AuthService } from '@auth0/auth0-angular';

import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { CommonDataService } from "./../common-data.service";
import { AccessGroupService } from "./../access-group.service";
import { environment } from "../../../environments/environment";
import { of } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { APIService } from "../../API.service";
import { Location } from "@angular/common";
import { Store } from "@ngrx/store";

declare var jQuery: any;

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  public nav: any;
  public menu_items: any;
  public profile: any = null;
  public a_profile: any;
  public picture_url: string;
  public p_url: any;
  public env;
  public dashboard;
  public lm;
  public Video: number;
  public modal: any;
  main_menu: any;
  imgSrc: string;
  certification: string;
  // Navigational Items
  store_url: string = environment.store_url;
  assessment_admin: string = environment.assessment_admin;
  features_admin: string = environment.features_admin;
  brand_identity: any;
  public member_id: string;
  current_lm: any;
  allmaps: any;
  current_mapExist: boolean = false;
  email_verified: string;
  reset_pswrd: string;
  mail_sent: boolean = false;
  pswrd_sent: boolean = false;
  mail_resent: boolean = false;
  pswrd_resent: boolean = false;
  verify: boolean = false;
  email: string;
  user: any;
  assessment_img:string;
  profileNgrx$;
  ngrxAccessGroup:any;
  ngrxError$:any;
  error;
  havepf: boolean = true;
  image:string= "";

  public constructor(
    public location: Location,
    public api: APIService,
    public http: HttpClient,
    private router: Router,
    public service: CommonDataService,
    public accessgroup: AccessGroupService,
    private modalService: NgbModal,
    private authService: Auth,
    public store: Store<any>
  ) {
    this.nav = document.querySelector("nav.navbar");
    this.user = {};
  }

  public ngOnInit() {
    this.ngrxAccessGroup = this.store.select<any>((state) => state.profile.accessGroupData);
    this.ngrxError$ = this.store.select<any>((state) => state.profile.error);
    // this.profile = "";
    this.Video = 0;
    this.env = environment;
    this.ngrxError$.subscribe(err => {
      if(err?.status == 503) {
        this.error = err;
      }
      console.log(err);
      if (err?.status == 412) {
        this.email = localStorage.getItem("email");
        if (err.error.errors == "Reset password required") {
          this.reset_pswrd = "false";
        }
        if (err.error.errors == "Email verification required") {
          this.email_verified = "false";
        }
      }
    })

    // this.authService.user$.subscribe((success: any) => {
    //     this.user = success;
    // });
    this.user = this.authService.getProfile;

    this.http.get(`${this.env.assessment_admin}api/v1/public/products.json`).subscribe(data => {
      console.log(data['products'][0]?.image_url)
      this.assessment_img = data['products'][0]?.image_url
    })

    $(".profile-error").hide();
    this.nav.className += " white-bg";
    this.email = localStorage.getItem("email");
    if (localStorage.getItem("FS login") == "true") {
      localStorage.setItem("FS login", "false");
    }

    if (localStorage.getItem("cart_id")) {
      var cartId = localStorage.getItem("cart_id");
      this.http
        .get(
          `${environment.assessment_admin}api/v1/carts/` +
            cartId +
            `/attach_to_customer`
        )
        .subscribe(
          (data) => {
            console.log(data);
            this.router.navigate(["/custom-store/cart"]);
            localStorage.removeItem("cart_id");
          },
          (error) => {
            console.log("oops", error);
            if (error.status == 500) {
              setTimeout(function () {
                this.toastr.options = {
                  closeButton: true,
                  timeOut: 2000,
                };
                this.toastr.error("Something went wrong!", "Sorry!");
              }, 1000);
            } else if (error.status == 422) {
              setTimeout(function () {
                this.toastr.options = {
                  closeButton: true,
                  timeOut: 4000,
                };
                if (!error.error.errors.base[0]) {
                  this.toastr.error(error.error.errors, "oops!");
                } else {
                  this.toastr.error(error.error.errors.base[0], "oops!");
                }
              }, 1000);
            } else {
              setTimeout(function () {
                this.toastr.options = {
                  closeButton: true,
                  timeOut: 2000,
                };
                this.toastr.error("Something went wrong!", "oops!");
              }, 1000);
            }
          }
        );
    }

    this.ngrxAccessGroup.subscribe((message) => {
        if (message) {
          this.email_verified = "true";
          this.reset_pswrd = "true";
          this.allmaps = [];
          if (message["main_menu"]?.learner_maps != null) {
            for (var i in message["main_menu"]?.learner_maps) {
              if (message["main_menu"]?.learner_maps[i]?.title) {
                this.allmaps.push([i, message["main_menu"].learner_maps[i]]);
              }
            }
            this.allmaps.sort(function (a, b) {
              return parseInt(a[1].priority) - parseInt(b[1].priority);
            });
            console.log(this.allmaps);
          }
          this.dashboard = message["main_menu"]?.dashboard;
          this.main_menu = message["main_menu"];
          this.lm = message["main_menu"]?.learner_maps;
          this.a_profile = message["main_menu"]?.collaboration_profile;
          console.log(this.allmaps);
          if(this.allmaps.length > 0) {
            this.api.GetLearnerMap(this.allmaps[this.allmaps?.length -1][1]?.value).then((res)=> {
              this.image = res?.image
            })
          }
        }
      },
      (err) => {
        // Log errors if any
        if (err.status == 412) {
          this.email = localStorage.getItem("email");
          if (err.error.errors == "Reset password required") {
            this.reset_pswrd = "false";
          }
          if (err.error.errors == "Email verification required") {
            this.email_verified = "false";
          }
        }
      }
    );
    this.getPfData();
  }

  // get profile data from store
  public getPfData() {
    this.profileNgrx$ = this.store.select<any>((state) => state.profile?.account.default_profile);
    this.profileNgrx$.subscribe(
        (message) => {
          console.warn(message)
          this.brand_identity = message?.source_owner?.collaboration_identity;
          console.log(this.brand_identity);
          if (message && message?.profile_title) {
            this.havepf = true;
            this.email_verified = "true";
            this.reset_pswrd = "true";
            setTimeout(() => {
              this.profile = message;
            }, 5000);
            console.log(this.profile)
            if (localStorage.getItem("impersonation") == "true") {
              localStorage.setItem("email", this.profile.email);
            }
            if (this.profile?.identity?.first_name != "" && this.profile?.identity?.first_name != null) {
              $("body").addClass("pace-done");
            }
            $('.dashloader').css("display", "none");
          } else {
            this.email_verified = "true";
            this.reset_pswrd = "true";
            $("#dash2").hide();
            $("#owner").hide();
            $(".profile-error").show();
            this.store.select<any>((state) => state.profile.isLoading).subscribe((rslt)=> {
              if(rslt == false){
                setTimeout(() => {
                  $('.dashloader').css("display", "none");
                    this.havepf = false;
                }, 100);
              } else {
                $('.dashloader').css("display", "none");
              }

            })
          }
        },
        (err) => {
          // Log errors if any
          if (err.status == 412) {
            console.warn(err)
            this.email = localStorage.getItem("email");
            if (err.error.errors == "Reset password required") {
              this.reset_pswrd = "false";
            }
            if (err.error.errors == "Email verification required") {
              this.email_verified = "false";
            }
            $('.dashloader').css("display", "none");
          }
        }
      );
  }
  
  public ngOnDestroy(): any {
    this.nav.classList.remove("white-bg");
  }
  public closebox = function () {
    var ibox = $(".ibox-tools").closest("div.ibox");
    ibox.remove();
  };
  public showhide = function () {
    var ibox = $(".ibox-tools").closest("div.ibox");
    var icon = $(".ibox-tools").find("i:first");
    var content = ibox.find("div.ibox-content");
    content.slideToggle(200);
    // Toggle icon from up to down
    icon.toggleClass("fa-chevron-up").toggleClass("fa-chevron-down");
    ibox.toggleClass("").toggleClass("border-bottom");
    setTimeout(function () {
      ibox.resize();
      ibox.find("[id^=map-]").resize();
    }, 50);
  };

  public video() {
    this.Video = 1;
  }

  closeDialogR() {
    $("button.close").trigger("click");
    this.router.navigate(["/branding"]);
  }

    public getIdentity() {
        this.member_id = localStorage.getItem("member_id");
        this.http
            .get(
                `${environment.profileURL}/api/v1/members/` + this.member_id + `.json`
            )
            .subscribe(
                (result) => {
                    console.log(result);
                    this.brand_identity = result["member"]["brand_identity"];
                },
                (error) => console.log("oops", error)
            );
    }

  public sendMail() {
    this.verify = true;
    this.http
      .get(
        `${environment.profileURL}/api/v1/users/send_verification_email.json`
      )
      .subscribe(
        (result) => {
          console.log(result);
          this.mail_sent = true;
          this.verify = false;
        },
        (error) => {
          console.log("oops", error);
          this.verify = false;
        }
      );
  }

  public resendMail() {
    this.verify = true;
    this.http
      .get(
        `${environment.profileURL}/api/v1/users/send_verification_email.json`
      )
      .subscribe(
        (result) => {
          console.log(result);
          this.mail_resent = true;
          this.verify = false;
        },
        (error) => {
          console.log("oops", error);
          this.verify = false;
        }
      );
  }

  public sendPswrdReset() {
    this.verify = true;
    this.http
      .get(
        `${environment.profileURL}/api/v1/users/send_reset_password_email.json`
      )
      .subscribe(
        (result) => {
          console.log(result);
          this.pswrd_sent = true;
          this.verify = false;
        },
        (error) => {
          console.log("oops", error);
          this.verify = false;
        }
      );
  }

  public resendPswrd() {
    this.verify = true;
    this.http
      .get(
        `${environment.profileURL}/api/v1/users/send_reset_password_email.json`
      )
      .subscribe(
        (result) => {
          console.log(result);
          this.pswrd_resent = true;
          this.verify = false;
        },
        (error) => {
          console.log("oops", error);
          this.verify = false;
        }
      );
  }
}
