import { Component, OnInit } from '@angular/core';
import { TeamReportServiceService } from './../team-report-service.service';

@Component({
  selector: 'app-team-faq',
  templateUrl: './team-faq.component.html',
  styleUrls: ['./team-faq.component.scss']
})
export class TeamFaqComponent implements OnInit {
	faq:any;

  	constructor(public service: TeamReportServiceService) { }

  	ngOnInit() {
  		this.faq = this.service.faq;
  	}

}
