import { Component, Input, OnInit } from '@angular/core';
import { LambdaFunctionService } from '../../service/lambda-function.service';
import { Subject } from 'rxjs';
import {AccessGroupService} from './../access-group.service';
import { LmTsService } from '../learner-maps/lm-ts.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { APIService } from '../../API.service';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-fs-slideshow',
  templateUrl: './fs-slideshow.component.html',
  styleUrls: ['./fs-slideshow.component.scss']
})
export class FsSlideshowComponent implements OnInit {

  sorted_item:any = [];
  allitem:any = [];
  public profiles:any;
  args:any;
  currentCard = new Subject();
  currentCard1;
  counter:number;
  nextCard:string;
  previousCard:string;
  public Profile;
  public allCols:any;
  public allcolumns:any;
  menu_links;
  active_route:string = '';
  profile_slideshow:boolean = false;
  userName:string = '';
  lm_card_view:boolean = false;
  learner_map:any;
  loading:boolean = false;
  ngrxAccessGroup:any;
  @Input() learnerMapId:string;
  columns:any;

  constructor(public lmda_service:LambdaFunctionService,public accessgroup:AccessGroupService,public lm_ts: LmTsService,public router: Router,public activatedRoute:ActivatedRoute,private api: APIService,public store:Store<any>) { }

  async ngOnInit() {
    this.ngrxAccessGroup = this.store.select<any>((state) => state.profile.accessGroupData);
    $('.loader-container').css('display','flex');
    this.userName = localStorage.getItem('first_name');

    var that = this;
    this.loading = true;
    if(this.router.url.includes('profile-slideshow')) {
      this.profile_slideshow = true;
      this.args = {
        "lm_id": '3f2dae98-c406-4f8e-801c-4f3196620bed',
      }
      this.lambda_lmdata(0,this.args);
    } else if(this.learnerMapId && !this.router.url.includes('fs-reveal')) {
        console.log(this.learnerMapId);
        this.lm_card_view = false;
        this.args = {
          "lm_id": this.learnerMapId,
        }
        // this.lambda_lmdata(0,this.args);
        that.lm_card_view = true;
        this.loading = false;
        localStorage.setItem('fs_reveal_lm_id',this.learnerMapId)
    } else {
      await this.ngrxAccessGroup.subscribe(message => { 
        if(message){           
          this.menu_links = message;
          this.activatedRoute.params.subscribe((params: Params) => {
            this.active_route = params.id;
            if((this.menu_links["main_menu"]?.dynamic_lms?.presentation?.title)?.toLowerCase() === params?.id) {
              this.lm_card_view = false;
              this.args = {
                "lm_id": this.menu_links["main_menu"]?.dynamic_lms?.presentation?.value,
              }
              this.lambda_lmdata(0,this.args);
            } 
            if('presenter-lm' === params?.id) {
              // this.args = {
              //   "lm_id": this.menu_links["main_menu"]?.dynamic_lms?.presenter_lm?.value,
              // } 
              // this.lambda_lmdata(0,this.args);
              this.ngrxAccessGroup.subscribe(message => {
                console.log(message["main_menu"]?.dynamic_lms?.presenter_lm?.value)
                if(message["main_menu"]?.dynamic_lms?.presenter_lm?.value) {
                  this.api.GetLearnerMap(message["main_menu"]?.dynamic_lms?.presenter_lm?.value).then(function (map) {
                    that.sorted_item = map;
                    console.log(map);
                    localStorage.setItem('fs_reveal_lm_id', map.id);
                  });
                  that.lm_card_view = true;
                  this.loading = false;
                }
              })
            }
      
          })
        }
      },
      err => {
          // Log errors if any
          console.log(err);
      })

    }


    if(!this.learnerMapId) {
      this.lm_ts.an_slide_no.subscribe(val =>{
        this.counter = val;
        this.currentCard.next(this.sorted_item[this.counter])
      })
  
      this.currentCard.subscribe(val => {
        this.currentCard1 = val 
        // console.log(this.currentCard1);
      });
    }

  }


  activeRoute(routename: string): boolean{
    return this.router.url.indexOf(routename) > -1;
  }

  // get LM using lambda functionda
  public async lambda_lmdata(i = 0,args){
    console.warn(args)
    console.log('custom-pf------lambda function')
    this.allitem = [];
    this.sorted_item = [];
    var all_cards:any = [];

    var that = this;
    if(args?.lm_id?.length != 0) {
      try {
        this.args["compName"] = "fs-slideshow";
        await that.lmda_service.lambda_invoke('TestDynamoDBReadLM-dev-readDataFromDynamoDB',args);
      } catch (err) {
        console.log(err)
      }
      await this.lmda_service.lambda_res.subscribe(res => {
          this.lm_ts.an_slide_no.next(0);
          console.log(res["response"])
          all_cards = res;
          try {
                  console.warn('all_cards',all_cards)
                  let allcolumns:any = [];
                  allcolumns = all_cards["response"]?.body;
                  if(this.router.url.includes('challenge-navigator-print')) {
                    this.columns = all_cards["response"]?.body;
                  }
                  allcolumns?.forEach((col) => {
                      col['cards']?.forEach(card => {
                          this.allitem.push(card);
                      });
                  });
          } catch (e) {
                  console.error('error in try block custom-pf',e);
                  return false;
              }
          var sorting = [];
          that.allitem?.forEach(element => {
              // console.warn(element.order["N"])
              sorting[parseInt(element.order["N"]) - 1] = element;
          });
  
          sorting?.forEach(el => {
              if(el?.active?.BOOL != false) {
                  // console.log(el.order["N"]);
                  that.sorted_item.push(el);
                  // console.warn(that.sorted_item.length)
              }
          })
          that.currentCard.next(that.sorted_item[that.counter])
      });
    }
    console.log(this.sorted_item);
    setTimeout(function() {
      this.loading = false;
    },500)
  }

  // print Allslides 
  public printSlides() {
    this.activatedRoute.params.subscribe((params)=> {
      document.title = params.id
    });
    window.print();
    document.title = 'App | FourSight';
  }

}