// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-wrapper {
  padding: 20px 10px 40px;
}

.ibox-content .image-youtube {
  cursor: pointer;
  display: block;
  margin: 0 auto;
}
.ibox-content .image-youtube--resz {
  max-width: 205px;
  width: 100%;
}

.ibox {
  height: 100%;
}
.ibox-title--bdr {
  border-bottom: 1px solid #e7eaec;
  align-self: self-start;
  width: 100%;
}
.ibox-content--fe {
  align-self: center;
  border: 0;
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 1199px) {
  .d-flex div[class*=col-] {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/tools/tools.component.scss"],"names":[],"mappings":"AAAA;EACC,uBAAA;AACD;;AAEA;EACC,eAAA;EACA,cAAA;EACA,cAAA;AACD;AACC;EACC,gBAAA;EACA,WAAA;AACF;;AAGA;EACC,YAAA;AAAD;AAIE;EACC,gCAAA;EACA,sBAAA;EACA,WAAA;AAFH;AAQE;EACC,kBAAA;EACA,SAAA;AANH;;AAWA;EACC,aAAA;EACA,eAAA;AARD;AAUC;EACC;IACC,WAAA;EARD;AACF","sourcesContent":[".section-wrapper{\n\tpadding:20px 10px 40px;\n}\n\n.ibox-content .image-youtube{\n\tcursor: pointer;\n\tdisplay: block;\n\tmargin: 0 auto;\n\t\n\t&--resz {\n\t\tmax-width: 205px;\n\t\twidth: 100%;\n\t}\n}\n\n.ibox {\n\theight: 100%;\n\n\t&-title {\n\n\t\t&--bdr {\n\t\t\tborder-bottom: 1px solid #e7eaec;\n\t\t\talign-self: self-start;\n\t\t\twidth: 100%;\n\t\t}\n\t}\n\n\t&-content {\n\n\t\t&--fe {\n\t\t\talign-self: center;\n\t\t\tborder: 0;\n\t\t}\n\t}\n}\n\n.d-flex {\n\tdisplay: flex;\n\tflex-wrap: wrap;\n\t\n\t@media screen and (max-width:1199px) {\n\t\tdiv[class*=\"col-\"] {\n\t\t\twidth: 100%;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
