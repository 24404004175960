import { Component } from '@angular/core';
import { VolumePricingComponent } from '../../store/volume-pricing/volume-pricing.component';

@Component({
  selector: 'app-volume-pricing-popup',
  templateUrl: './volume-pricing-popup.component.html',
  styleUrls: ['./volume-pricing-popup.component.scss']
})
export class VolumePricingPopupComponent {

  constructor(public volumePriceCompo: VolumePricingComponent) {}

  public close() {
    $('.vol-price-modal').css('display', 'none');
  }
}
