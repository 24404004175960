import { Component } from '@angular/core';
import { OrgsComponent } from '../../orgs/orgs.component';
@Component({
  selector: 'app-roster-del-popup',
  templateUrl: './roster-del-popup.component.html',
  styleUrls: ['./roster-del-popup.component.scss']
})
export class RosterDelPopupComponent {

  constructor(public orgsCompo: OrgsComponent) {}

  public delRoster() {
    this.orgsCompo.delRoster = true;
    this.orgsCompo.delOrg(this.orgsCompo.deleteOrg,this.orgsCompo.deleteIndex);
  }

  public close() {
    this.orgsCompo.delRoster = false;
    $('.orgs-modal-del').css('display', 'none');
  }
}
