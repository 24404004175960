import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../../environments/environment';
// import { Angulartics2Segment } from 'angulartics2';
import { Location } from '@angular/common';

declare var Stripe:any;

@Component({
  selector: 'app-order-verify',
  templateUrl: './order-verify.component.html',
  styleUrls: ['./order-verify.component.scss']
})
export class OrderVerifyComponent implements OnInit {
	cart:any;
	detail:any;
	subtotal:string;
	env:any;
	discounted_items_price:string;
	payment_id:string;
	stripe:any;
	payment_status:any;
	verify:boolean = false;
	store : any;
	cvc_req:boolean = false;
	last4:string = '';
	sl_card_id:string = '';
	credit_card:any;

  	constructor(public location: Location, private activatedRoute: ActivatedRoute, private router: Router,private http:HttpClient) { }

  	ngOnInit() {
		var that = this;
  		this.store = localStorage.getItem('store');
		this.last4 = localStorage.getItem('last4');
		this.sl_card_id = localStorage.getItem('sl_card_id');
  		this.env = environment;
  		this.stripe = Stripe(this.env.stripe_api_key);
		this.http.get(`${environment.assessment_admin}api/v1/carts`)
		.subscribe(res => {
			console.log(res);
			this.detail = res['order'];
			this.cart = res['order']['line_items'];
			this.subtotal = res['order']['subtotal_amount'];
			this.discounted_items_price = res['order']['discounted_items_price'];
			this.payment_id = res['order']['stripe_payment_method_id'];
			this.payment_status = res['order']['status'];

			// if(this.payment_status == "payment_required") {
			// 	this.http.post(`${environment.assessment_admin}api/v1/carts/complete`,{card:true}).subscribe(data => {
			// 		console.log(data)
			// 	})

			// }
		},
        	error => console.log('oops', error)
    	)
		if(this.last4 != '') {
			this.http.get(`${environment.assessment_admin}api/v1/credit_cards`).subscribe(data => {
			console.log(data);
			data["credit_cards"].forEach((cc)=> {
				if(cc["last4"] == this.last4) {
					console.log(cc)
					this.credit_card = cc;
				}
			})
			}, err => {
				console.log(err)
			});
			console.log('selected card functions')
			that.http.post(`${environment.assessment_admin}api/v1/carts/save_credit_card`,{credit_card_id: this.sl_card_id}).subscribe(res => {
				console.log(res);
				if(res['success']){
					this.http.post(`${environment.assessment_admin}api/v1/carts/complete`,{card:true}).subscribe(data => {
						console.log(data)
					}, error => {
						console.log(error);
						if(error['error']['action'] ==='requires_confirmation') {
							this.forCVC(error['error'])
						}
					});
				}
			}, err => {
				console.log(err);
			});
		}
		
  	}

	public forCVC(pymnt_intnt) {
		var that = this;
		this.cvc_req = true;
		
		var stripe = Stripe(this.env.stripe_api_key);

		var elements = stripe.elements();
		var cvcElement = elements.create('cardCvc');
		
		setTimeout(() => {
			cvcElement.mount('#cvc-element');
		}, 500);

		setTimeout(() => {
		var cardButton = document.getElementById('cvc_btn');
		
			cardButton.addEventListener('click', function(ev) {
				stripe.confirmCardPayment(pymnt_intnt['payment_intent_secret'],{
					payment_method: pymnt_intnt['payment_method_id'],
					payment_method_options: {
						card: {
							cvc: cvcElement,
						}
					},
				}).then((res)=> {
					console.log(res);
					console.log(res['error']);
					if(res['error'] != '' && res['error'] != undefined) {
						let error = document.getElementById('stripe_error');
						error.innerHTML = res['error']['message'];
					} else {
						if((res['paymentIntent']?.status == "succeeded")){
							that.cvc_req = false;
							that.verify = false;
							that.checkoutCard("");
						}
						//  that.router.navigate([`/custom-store/order-details/${that.detail.id}`]);
					}
				})
			});
		}, 500);
	}

  	public checkoutCard(e){
  		if(this.verify){
  			e.preventDefault();
  		}else{
			$('#stripe_error').hide();
			this.verify = true;
	  		var that = this;
	      	// completes the order if payment confirmed
			$('.loader-container').css('display','flex');
	      	this.http.post(`${environment.assessment_admin}api/v1/carts/complete`,{card:true}).subscribe(data => {
	        	console.log(data);
	        	localStorage.removeItem('intent_secret');
	        	var products = [];
	        	for(var i=0;i<this.cart.length;i++){
                    products.push({
                        product_id: this.cart[i].variant.product_id,
                        currency: 'USD',
                        sku: this.cart[i].variant.sku,
                        name: this.cart[i].variant.name,
                        price: parseFloat(this.cart[i].variant.price),
                        quantity: this.cart[i].quantity,
                        image_url: this.cart[i].variant.image_url
                    })
                }
                // this.angulartics2Segment.eventTrack('purchase', 
                //     { 
                //         ecommerce:{
                //         	purchase:{
	            //             	actionField:{
		        //                     id: this.detail.id,
		        //                     coupon: this.detail.applied_discount_code,
		        //                     currency: 'USD',
		        //                     affiliation: '',
		        //                     revenue: parseFloat(this.detail.net_amount),
		        //                     tax: parseFloat(this.detail.tax_amount),
		        //                     shipping: parseFloat(this.detail.shipping_amount),  
		        //                     value: parseFloat(this.detail.net_amount),                          
		        //                     discount: parseFloat(this.detail.total_discount)
		        //                 },                        
		        //                 products: products
		        //             }
		        //         }
                //     }
                // );
	            // this.angulartics2Segment.eventTrack('Order Completed', 
                //     { 
                //         order_id: this.detail.id,
                //         total: this.detail.net_amount,
                //         shipping: this.detail.shipping_amount,
                //         tax: this.detail.tax_amount,
                //         discount: this.detail.total_discount,
                //         coupon: this.detail.applied_discount_code,
                //         currency: 'USD',
                //         products: products
                //     }
                // );
	        	this.router.navigate([`/custom-store/order-details/${that.detail.id}`]);
	        	this.verify = false;
	        	// this.angulartics2Segment.eventTrack('Page', 
	            //     { 
	            //         email: localStorage.getItem('email'),
	            //         funnel: 'Store',
		        //         cta_color:'developer-green',
		        //         cta_text:'Place Order',
		        //         cta_type:'button',
		        //         cta_location:'Checkout',
	            //         url: window.location.origin + this.location.path()
	            //     }
	            // );
	            // for(var i=0;i<this.cart.length;i++){
		        //     if(this.cart[i].product_slug == 'foursight-mindset-qualification'){
			    //         this.angulartics2Segment.eventTrack('Page', 
			    //             { 
			    //             	email: localStorage.getItem('email'),
			    //                 funnel: 'Get Student Rates',
			    //                 cta_color:'developer-green',
				//                 cta_text:'Place Order',
				//                 cta_type:'button',
				//                 cta_location:'Checkout',
			    //                 url: window.location.origin + this.location.path()
			    //             }
			    //         );
			    //     }
			    //     if(this.cart[i].product_slug == 'foursight-mindset-certification'){
			    //         this.angulartics2Segment.eventTrack('Page', 
			    //             { 
			    //             	email: localStorage.getItem('email'),
			    //                 funnel: 'Get Certified MSC',
			    //                 cta_color:'developer-green',
				//                 cta_text:'Place Order',
				//                 cta_type:'button',
				//                 cta_location:'Checkout',
			    //                 url: window.location.origin + this.location.path()
			    //             }
			    //         );
			    //     }
			    //     if(this.cart[i].product_slug == 'foursight-toolset-certification'){
			    //         this.angulartics2Segment.eventTrack('Page', 
			    //             { 
			    //             	email: localStorage.getItem('email'),
			    //                 funnel: 'Get Certified TSC',
			    //                 cta_color:'developer-green',
				//                 cta_text:'Place Order',
				//                 cta_type:'button',
				//                 cta_location:'Checkout',
			    //                 url: window.location.origin + this.location.path()
			    //             }
			    //         );
			    //     }
			    //     if(this.cart[i].product_slug == 'foursight-thinking-profile-assessment'){
			    //         this.angulartics2Segment.eventTrack('Page', 
			    //             { 
			    //             	email: localStorage.getItem('email'),
			    //                 funnel: 'Group Assessment',
			    //                 cta_color:'developer-green',
				//                 cta_text:'Place Order',
				//                 cta_type:'button',
				//                 cta_location:'Checkout',
			    //                 url: window.location.origin + this.location.path()
			    //             }
			    //         );
			    //     }
			    // }
			},
	        error => {
	        	console.log('oops', error['error']);
	        	// payment requires more authentication
	        	if((error['error']['status'] == "requires_action") || (error['error']['status'] == "requires_payment_method") || (error['error']['status'] == "requires_source_action") || (error['error']['status'] == "requires_confirmation")){
	        		// confirming payment
	        		that.stripe.confirmCardPayment(
			        	error['error']['payment_intent_secret'],
			        	{
			          		payment_method: error['error']['payment_method_id'],
			        	}
			      	).then(function(result) {
			      		if (result.error) {
				        	that.verify = false;
				          	// Display error.message in your UI.
				          	console.log(result)
				          	if(result.error.message){
				          		$('#stripe_error').html(result.error.message);
				          		$('#stripe_error').show();
				          		setTimeout(function() {
									this.toastr.options = {
									  closeButton: true,
									  timeOut: 10000
									};
									this.toastr.error(result.error.message, 'oops!');
								}, 1000);
				          	}else{
				          		$('#stripe_error').html('Please try a different card or contact support in the chat window below.');
				          		$('#stripe_error').show();
				          		setTimeout(function() {
									this.toastr.options = {
									  closeButton: true,
									  timeOut: 10000
									};
									this.toastr.error('Please try a different card or contact support in the chat window below.','Something went wrong!');
								}, 1000);
				          	}
				        }else{
				        	// completes the order if payment confirmed
				        	that.http.post(`${environment.assessment_admin}api/v1/carts/complete`,{card:true}).subscribe(data => {
				            	console.log(data);
				            	localStorage.removeItem('intent_secret');
				            	var products = [];
				            	for(var i=0;i<that.cart.length;i++){
				                    products.push({
				                        product_id: that.cart[i].variant.product_id,
				                        currency: 'USD',
				                        sku: that.cart[i].variant.sku,
				                        name: that.cart[i].variant.name,
				                        price: parseFloat(that.cart[i].variant.price),
				                        quantity: that.cart[i].quantity,
				                        image_url: that.cart[i].variant.image_url
				                    })
				                }
				                // that.angulartics2Segment.eventTrack('purchase', 
				                //     { 
				                //         ecommerce:{
				                //         	purchase:{
				                //         		actionField:{
						        //                     id: that.detail.id,
						        //                     coupon: that.detail.applied_discount_code,
						        //                     currency: 'USD',
						        //                     affiliation: '',
						        //                     revenue: parseFloat(that.detail.net_amount),
						        //                     tax: parseFloat(that.detail.tax_amount),
						        //                     shipping: parseFloat(that.detail.shipping_amount), 
						        //                     value: parseFloat(that.detail.net_amount),                           
						        //                     discount: parseFloat(that.detail.total_discount)
						        //                 },                        
						        //                 products: products
						        //             }
						        //         }
				                //     }
				                // );
					            // that.angulartics2Segment.eventTrack('Order Completed', 
				                //     { 
				                //         order_id: that.detail.id,
				                //         total: that.detail.net_amount,
				                //         shipping: that.detail.shipping_amount,
				                //         tax: that.detail.tax_amount,
				                //         discount: that.detail.total_discount,
				                //         coupon: that.detail.applied_discount_code,
				                //         currency: 'USD',
				                //         products: products
				                //     }
				                // );
				            	that.router.navigate([`/custom-store/order-details/${that.detail.id}`]);
				            	that.verify = false;
				            	// that.angulartics2Segment.eventTrack('Page', 
					            //     { 
					            //         email: localStorage.getItem('email'),
					            //         funnel: 'Store',
						        //         cta_color:'developer-green',
						        //         cta_text:'Place Order',
						        //         cta_type:'button',
						        //         cta_location:'Checkout',
					            //         url: window.location.origin + that.location.path()
					            //     }
					            // );
					            // for(var i=0;i<that.cart.length;i++){
						        //     if(that.cart[i].product_slug == 'foursight-mindset-qualification'){
							    //         that.angulartics2Segment.eventTrack('Page', 
							    //             { 
							    //             	email: localStorage.getItem('email'),
							    //                 funnel: 'Get Student Rates',
							    //                 cta_color:'developer-green',
								//                 cta_text:'Place Order',
								//                 cta_type:'button',
								//                 cta_location:'Checkout',
							    //                 url: window.location.origin + that.location.path()
							    //             }
							    //         );
							    //     }
							    //     if(that.cart[i].product_slug == 'foursight-mindset-certification'){
							    //         that.angulartics2Segment.eventTrack('Page', 
							    //             { 
							    //             	email: localStorage.getItem('email'),
							    //                 funnel: 'Get Certified MSC',
							    //                 cta_color:'developer-green',
								//                 cta_text:'Place Order',
								//                 cta_type:'button',
								//                 cta_location:'Checkout',
							    //                 url: window.location.origin + that.location.path()
							    //             }
							    //         );
							    //     }
							    //     if(that.cart[i].product_slug == 'foursight-toolset-certification'){
							    //         that.angulartics2Segment.eventTrack('Page', 
							    //             { 
							    //             	email: localStorage.getItem('email'),
							    //                 funnel: 'Get Certified TSC',
							    //                 cta_color:'developer-green',
								//                 cta_text:'Place Order',
								//                 cta_type:'button',
								//                 cta_location:'Checkout',
							    //                 url: window.location.origin + that.location.path()
							    //             }
							    //         );
							    //     }
							    //     if(that.cart[i].product_slug == 'foursight-thinking-profile-assessment'){
							    //         that.angulartics2Segment.eventTrack('Page', 
							    //             { 
							    //             	email: localStorage.getItem('email'),
							    //                 funnel: 'Group Assessment',
							    //                 cta_color:'developer-green',
								//                 cta_text:'Place Order',
								//                 cta_type:'button',
								//                 cta_location:'Checkout',
							    //                 url: window.location.origin + that.location.path()
							    //             }
							    //         );
							    //     }
							    // }
			        		},
				            error => {
				            	that.verify = false;
				            	console.log('oops', error);
				            	if(error.status == 500){
				            		$('#stripe_error').html('Please try a different card or contact support in the chat window below.');
				            		$('#stripe_error').show();
									setTimeout(function() {
										this.toastr.options = {
										  closeButton: true,
										  timeOut: 10000
										};
										this.toastr.error('Something went wrong!', 'Sorry!');
									}, 1000);
								}
								else if(error.status == 422){
									setTimeout(function() {
										this.toastr.options = {
										  closeButton: true,
										  timeOut: 10000
										};
										if(!error.error.errors.base[0]){
											$('#stripe_error').html(error.error.errors);
											$('#stripe_error').show();
											this.toastr.error(error.error.errors, 'oops!');
										}else{
											$('#stripe_error').html(error.error.errors.base[0]);
											$('#stripe_error').show();
											this.toastr.error(error.error.errors.base[0], 'oops!');
										}
									}, 1000);
								}else{
									$('#stripe_error').html('Please try a different card or contact support in the chat window below.');
									$('#stripe_error').show();
									setTimeout(function() {
										this.toastr.options = {
										  closeButton: true,
										  timeOut: 10000
										};
										this.toastr.error('Something went wrong!', 'oops!');
									}, 1000);
								}
							})
				        }
			      	})
	        	}else{
	        		that.verify = false;
	            	if(error.status == 500){
	            		$('#stripe_error').html('Please try a different card or contact support in the chat window below.');
	            		$('#stripe_error').show();
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 10000
							};
							this.toastr.error('Something went wrong!', 'Sorry!');
						}, 1000);
					}
					else if(error.status == 422){
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 10000
							};
							if(!error.error.errors.base[0]){
								$('#stripe_error').html(error.error.errors);
								$('#stripe_error').show();
								this.toastr.error(error.error.errors, 'oops!');
							}else{
								$('#stripe_error').html(error.error.errors.base[0]);
								$('#stripe_error').show();
								this.toastr.error(error.error.errors.base[0], 'oops!');
							}
						}, 1000);
					}else{
						$('#stripe_error').html('Please try a different card or contact support in the chat window below.');
						$('#stripe_error').show();
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 10000
							};
							this.toastr.error('Something went wrong!', 'oops!');
						}, 1000);
					}
				}
			})
			$('.loader-container').css('display', 'none');
		}	
	}

	public complimentaryPdt(){
  		setTimeout(function() {
	  		this.toastr.options = {
				closeButton: true,
				timeOut: 5000
			};
			this.toastr.success('This product is a complement to the product above it and cannot be edited independently.', 'Hey!');
		}, 500);
  	}

}