import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {CommonDataService} from './../common-data.service';
// import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AccessGroupService} from './../access-group.service';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Location } from '@angular/common'
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
	public password:any;
	public userID:any;
	public email:any;
	public loading:any;
	public newEmail:any;
	public confirmPassword:any;
    public isLoading:any;
    ngrxAccessGroup:any;

  	constructor(public location: Location, public http: HttpClient, public router: Router, public service: CommonDataService, public accessgroup: AccessGroupService,public store:Store<any>) { }

  	ngOnInit() {
        this.ngrxAccessGroup = this.store.select<any>((state) => state.profile.accessGroupData);
  		this.ngrxAccessGroup.subscribe(message => {
            if(message && message.length > 0){
                if(message['menu_account'].manage_account_update != true ){
                    this.router.navigate(['/dashboard']);
                    return false;
                }
            }

        },
        err => {
            // Log errors if any
            console.log(err);
        })

        // this.auth.getProfile((err, profile) => {
        //     this.userID = profile.sub;
        //     this.email = profile.email;
        // });
        
  	}

    public cancel() {
        this.location.back(); // <-- go back to previous location on cancel
    }

  	public updatePassword() {
  		if(this.password && this.confirmPassword && this.password == this.confirmPassword) {
	  		this.loading = 1;
	  		var attrib = {password:this.password};
              var that = this;
	        this.http.post(`${environment.profileURL}/api/v1/users/update_account`,attrib).subscribe(data => {
	            console.log(data);
	            this.loading = 2;
	            setTimeout(function() {
	                this.toastr.options = {
	                    closeButton: true,
                      progressBar: true,
	                    timeOut: 2000
	                };
	                this.toastr.success('Password has been updated!', 'Great!');
	            }, 1000);
                setTimeout(function() {
                    that.router.navigate(['/logout']);
                }, 3000);
	        }, response => {
                this.loading = false;
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 2000
                    };
                    this.toastr.error(response.errors, 'Sorry!');
                }, 1000);
            })
	    }
	    else {
	    	setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.error('Passwords not matched!', 'Sorry!');
            }, 1000);
	    }
  	}

  	public changeEmail() {
  		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var email = re.test(this.newEmail);
  		if(email) {
  			this.isLoading = 1;
  			var attrib = {email: this.newEmail};
              var that = this;
	        this.http.post(`${environment.profileURL}/api/v1/users/update_account`,attrib).subscribe(data => {
	            console.log(data);
	            this.isLoading = 2;
	            setTimeout(function() {
	                this.toastr.options = {
	                    closeButton: true,
                      progressBar: true,
	                    timeOut: 2000
	                };
	                this.toastr.success('Email has been updated!', 'Great!');
	            }, 1000);
	            setTimeout(function() {
                    that.router.navigate(['/logout']);
                }, 3000);
	        },response => {
                this.isLoading = false;
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 2000
                    };
                    this.toastr.error(response.errors, 'Sorry!');
                }, 1000);
            })
	    }
  	}

}
