// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.f-weight-normal {
  font-weight: 400 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/teams/myteams-gpp/myteams-gpp.component.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;AACJ","sourcesContent":[".f-weight-normal {\n    font-weight: 400 !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
