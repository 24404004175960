import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CigSlideshowService {
  admin_upload = 'https://assets.foursightonline.com/admin-uploads/docs/';
  
  constructor() { }
    slides_text ={
        1:{
            title:'',
            below_text:["You know the expression “Great minds think alike?” Actually, they don’t.","I’m Sarah Thurber, managing partner at FourSight, where we study cognitive diversity in problem solving. In our database of hundreds of thousands of thinking profiles, not many people have a profile quite like yours."],
        },
        2:{
            title:'How do you approach a challenge?',
            below_text:["The FourSight assessment is a scientific measure of thinking preference. It reveals how you naturally approach any kind of challenge that needs new thinking. It helps you reflect on your own problem-solving style and understand why others approach the same problem so differently. In the next 10 minutes, my goal is to give you information about your thinking profile that can make you an even more effective problem solver and collaborator."],
        },
        3:{
            title:'The Universal Process ',
            below_text:["How exactly do people solve complex challenges? There are lots of useful models out there. But they all rely on bringing together four distinct types of thinking. Put them together, and you get a simple, universal, problem-solving process. We call it the FourSight framework."],
        },
        4:{
            title:'The universal process',
            below_text:["First, you clarify to understand the challenge."],
        },
        5:{
            title:'The universal process',
            below_text:["Then, you ideate to imagine new ideas to solve the challenge."],
        },
        6:{
            title:'The universal process',
            below_text:["Next, you develop workable solutions."],
        },
        7:{
            title:'The universal process',
            below_text:["And you implement them.  Success requires all four types of thinking. So you’d think we would use them all equally.","But we don’t."],
        },
        8:{
            title:'FourSight thinking preferences',
            below_text:["Through 20 years of research and analyzing more than six million data points on cognitive diversity, Professor Gerard Puccio from the State university of New York College at Buffalo discovered that more than 80% of us prefer some types of thinking over others. He called those thinking preferences. Thinking preferences are hard to see, but they can cause a lot of stress in relationships. They’re often the underlying cause of misunderstanding, frustration and miscommunication. Ever noticed how some people seem to ask too many questions? Or have too many ideas? Or make things too complicated? Or rush to finish things too quickly? Those are thinking preferences in action. The ones you don’t like are probably the ones you don’t share. We’re used to our own thinking preferences, but other people’s can be a little hard to take, especially when we don’t understand where they’re coming from."],
        },
        9:{
            title:'Preference does not equal ability',
            below_text:["Now, when we’re talking about thinking preferences, there’s one thing you should know: Thinking preferences are NOT the same as thinking abilities. You have the ability to do all four types of thinking, and you do them all the time. But if you’re like most people, your brain plays favorites. Some styles of thinking may come more naturally. Some energize you more. Those are your thinking preferences, which remain pretty constant over a life time. Your thinking abilities, however, can change and improve with training and practice."],
        },
        10:{
            title:'There are no "good" or "bad" scores',
            below_text:[" Given that FourSight measures your preferences, it follows that you can’t possibly get a “bad” score on this measure. Because it’s simply a measure of how much you like something. FourSight has 15 different thinking profiles. So the best profile really is your profile. Because it shows where you naturally light up in the <b>problem-solving</b> process. The goal is not to change your profile, but to understand it. So remember, a high score isn’t a good score any more than a low score is a bad score. It’s just an indication of where you like to spend time and energy."],
        },
        11:{
            title:'Congratulations!',
            "Clarifier":{
                below_text:"You have a high preference for clarifying.",
                Clarifier:'That’s fantastic news. Clarifiers are known for understanding what problem to solve.',
            },
            "Ideater":{
                below_text:'You have a high preference for ideating.',
                Ideator:'That’s fantastic news. Ideators are known for generating original ideas.',
            },
            "Developer":{
                below_text:'You have a high preference for developing.',
                Developer:'That’s fantastic news. Developers are known for polishing and perfecting plans.',
            },
            "Implementer":{
                below_text:'You have a high preference for implementing.',
                Implementer:'That’s fantastic news. Implementers are known for their persistence to make things happen.',
            },
            "Early Bird":{
                below_text:'You have a high preference for clarifying and ideating.',
                Early_Bird:'That’s fantastic news. Early Birds are known for their strategic insights.',
            },
            "Analyst":{
                below_text:'You have a high preference for clarifying and developing.',
                Analyst:'That’s fantastic news. Analyst are known for their ability to think things all the way through.',
            },
            "Accelerator":{
                below_text:'You have a high preference for clarifying and implementing.',
                Accelerator:'That’s fantastic news. Accelerators are known for identifying problems and taking care of them.',
            },
            "Theorist":{
                below_text:'You have a high preference for ideating and developing.',
                Theorist:'That’s fantastic news. Theorists are known for creating elegant, original solutions.',
            },
            "Finisher":{
                below_text:'You have a high preference for ideating and implementing.',
                Finisher:'That’s fantastic news. Drivers are known for their ability to turn ideas into reality.',
            },
            "Driver":{
                below_text:'You have a high preference for developing and implementing.',
                Driver:'That’s fantastic news. Finishers are known for executing complex solutions.',
            },
            "Hare":{
                below_text:'You have a high preference for clarifying, ideating and developing.',
                Hare:'That’s fantastic news. Hares are known for facilitating complex challenges.',
            },
            "Idea Broker":{
                below_text:'You have a high preference for clarifying, ideating and implementing.',
                Idea_Broker:'That’s fantastic news. Idea Brokers are known for seizing strategic opportunities.',
            },
            "Optimist":{
                below_text:'You have a high preference for ideating, developing and implementing.',
                Optimist:'That’s fantastic news. Optimists are known for delivering original solutions.',
            },
            "Integrator":{
                below_text: ["Your FourSight profile is Integrator.","You have nearly equal preference for all the steps in the creative process","That’s fantastic news.","Integrators are known for their even- handed approach."]
            },
            "Realist":{
                below_text:'You have a high preference for clarifying, developing and implementing.',
                Realist:'That’s fantastic news. Realists are known for delivering targeted results.',
            },
        },
        12:{
            title:'Your FourSight Thinking Profile graph',
            "not equal to integrator":{
                below_text:['Here’s your graph. It has four scores, one for clarify, ideate, develop and implement.','Notice the gray band that runs horizontally across the graph. That represents your neutral zone.','Any point that falls above it is a high preference for you. Any point that falls below it is a low preference. Points that within it areneutral preferences. The neutral zone falls in a different place on the graph for every person,because we calculate it based on your highest and lowest scores.','<strong>The most important part of the graph are not the scores themselves, but the shape of the line that connects them. Think of that as an energy wave, showing how your energy rises and falls</strong>','over the course of the problem-solving process. The more extreme the peaks and valleys of your graph, the stronger your preferences.']
            },
            "equal to integrator":{
                // title:'Your FourSight Thinking Profile graph',
                below_text:['Here’s your graph. It has four scores, one for clarify, ideate, develop and implement.','Notice the gray band that runs horizontally across the graph. That represents your neutral zone.','Any point that falls above it is a high preference for you. Any point that falls below it is a low preference. Points that within it areneutral preferences. The neutral zone falls in a different place on the graph for every person,because we calculate it based on your highest and lowest scores.','The most important part of the graph are not the scores themselves, but the shape of the line that connects them. Think of that as an energy wave, showing how your energy rises and falls','over the course of the <b>problem-solving</b> process. The more extreme the peaks and valleys of your graph, the stronger your preferences.']
            }
        },
        13:{
            title:"The 4 stages",
            below_text:["I’ll walk you through your scores one at a time. First we’ll look at each stage of the universal process. Then we’ll look at your preference for it."]
        },
        14:{
            title:"Clarify",
            below_text:[" We start with clarifying. This is the stage where you do the reality check. You figure out what is going on and ask all the who, what, when, where, why and how questions. The goal of clarifying is to understand the situation well enough that you can truly identify what challenge you should be trying to address."]
        },
        15:{
            "Your preference for clarifying = High":{
                title:'Your preference for clarifying = High',
                below_text:["It looks like you have a high preference for clarifying. Our research shows that high Clarifiers instinctively focus on understanding the challenges they take on. You pursue information, get background data, and familiarize yourself with rules, directions and instructions to crystalize your understanding. While some people go rushing into ideas and solutions, you move forward cautiously, thinking about the right way to approach the challenge and asking questions to make sure you are on target. People with a low preference for clarifying may sometimes see you as being overly cautious or stuck in “analysis paralysis,” but you would rather spend time thinking things through up front to make sure you're solving the right problem."]
            },
            "neutral and not equal to integrator":{
                title:'Your preference for clarifying = Neutral',
                below_text:["It looks like you have a neutral preference for clarifying. When faced with an open-ended challenge, you naturally gather key information, get familiar with the rules, restrictions and expectations and move forward. While people with a high preference for clarifying may keep gathering more and more information, you are comfortable moving forward, even I the face of some ambiguity. Your neutral position makes it possible for you to work with both high and low Clarifiers with relative ease."]
            },
            "Your preference for clarifying = Low":{
                title:'Your preference for clarifying = Neutral',
                below_text:[" It looks like you have a low preference for clarifying. That means you tend to move quickly through the clarifying stage. You need to gather only the essential pieces of information before you are ready to move to other parts of the process. You don’t need all the details now, because you are willing to backfill the information later, when it’s clear what information is really necessary. In your rush to move on, you may miss key pieces of information and find your subsequent efforts unfocused or off target. Then again, your willingness to move ahead in the face of ambiguity allows you to discover information down the line that no amount of thinking or research could produce up front."]
            },
            "neutral and equal to integrator":{
                title:'INTEGRATOR -- You score “neutral” for clarify',
                below_text:[" As an integrator, you have a neutral preference for clarifying. When faced with an open-ended challenge, you naturally gather key information, get familiar with the rules, restrictions and expectations and move forward. While people with a high preference for clarifying may keep gathering more and more information, you are comfortable moving forward, even I the face of some ambiguity. Your neutral position makes it possible for you to work with both high and low Clarifiers with relative ease."]
            }
        },
        16:{
            title:'ideate',
            below_text:['The next stage in the universal <b>creative</b> process is the Ideate stage. Here’s where you generate ideas, drum up possibilities, explore options and make new connections. Let your brain float above the facts “as given” and see the big picture. This is your chance to use your imagination to challenge rules, reverse assumptions, make intuitive leaps…all in hopes of striking on ideas that are novel and valuable. <b> This is the stage that we associate with thinking outside the box.</b>']
        },
        17:{
            "Your preference for ideating = High":{
                title:'Your preference for clarifying = High',
                below_text:["When it comes to ideating, it looks like you have a high preference. Our research shows that high Ideators love to generate ideas. In fact, sometimes, you can’t STOP generating ideas. Your thinking is naturally fluent, flexible and prolific. You see things in global terms and need to know “why something is important” before you are willing to engage. You can use your imagination to envision futures different than what’s before you. Your appetite for novelty lets you enjoy variety, surprises and new experiences. Conversely, you are likely to avoid monotonous, repetitive, predictable activities. You don’t mind defying convention and sometimes enjoy the attention that brings. Your attention is drawn to to so many different things, to others your thinking can sometimes seems fractured or distracted. Then you’ll surprise them by making a connection that everyone else missed."]
            },
            "neutral and not equal to integrator":{
                title:'Your preference for ideating = Neutral',
                below_text:[" When it comes to ideating, it looks like you have a neutral preference. So when it’s time to generate ideas, you may think up some options and choose the best one. The choice doesn’t have to be ground-breaking, earth-shattering or even new, as long as you think it will work. High Ideators will keep for pushing novelty and low Ideators will avoid novelty. But your neutral position makes it relatively easy to work with people at both ends of the Ideator spectrum."]
            },
            "Your preference for ideating = Low":{
                title:'Your preference for ideating = Low',
                below_text:["When it comes to ideating, it looks like you have a low preference. Low Ideators can move quickly through the ideation stage, because you don’t feel compelled to challenge the status quo or produce a dozen dazzling new ideas. You just need one workable idea. A functional, reliable, sure-fire idea so you can get back to the more “serious” parts of the process. The hazard of shortchanging the ideation stage is that you are likely to end up with a solution that is rather conventional. Still, there are a lot of challenges where your simple, pragmatic approach to ideation is enough to get the job done."]
            },
            "neutral and equal to integrator":{
                title:'INTEGRATOR -- You score “neutral” for ideate',
                below_text:[" When it’s time to generate ideas, your neutral preference for ideating helps you steadily think up options and choose the best one. The choice doesn’t have to be ground-breaking, earth-shattering or even new, as long as you think it will work. High Ideators will keep for pushing novelty and low Ideators will avoid novelty. But your neutral position makes it relatively easy to work with people at both ends of the Ideator spectrum."]
            }
        },
        18:{
                title:"Develop",
                below_text:["Now we come to the Develop stage. This is where ideas and concepts get worked out and improved, where they are moved from rough ideas into refined solutions. Developing means weighing the pros and cons of competing options, analyzing choices, optimizing solutions, and refining plans. Think of this as the stage where you create a blueprint of the perfect solution."]
        },
        19:{
            "Your preference for developing = High":{
                title:'Your preference for clarifying = High',
                below_text:[" It looks like you have a high preference for developing. High Developers seem to enjoy the patient work of refining and polishing solutions. You know that perfecting things takes time. You are reflective, cautious and pragmatic, and you appreciate having time and space to sort out your options and elaborate on your plans. High developers enjoy crafting complex multi-layered solutions. You may have a perfectionist streak that can make it hard to meet the standards that you set. But when you succeed, everyone appreciates the result."]
            },
            "neutral and not equal to integrator":{
                title:'Your preference for ideating = Neutral',
                below_text:[" When it comes to developing, it looks like you have a neutral preference. A neutral score for developing suggests that when it’s time to develop solutions, you pick the best ideas and refine them until you have a practical plan to implement. That approach may seem obvious to you, but people with high and low preferences for developing are quite different. Those with a high preferences get drawn into every detail of the plan. Those with a low preference may resist planning all together and prefer to rush right into implementation. Your neutral position makes a good bridge between high and low Developers."]
            },
            "Your preference for developing = Low":{
                title:'Your preference for developing = Low',
                below_text: ["It looks like you have a low preference for developing. Low Developers can move quickly through the develop stage. You may prefer a simple, working plan that will allow maximum flexibility responsiveness. itineraries and minute-to-minute agendas can bog you down. Frankly, the less time spent fussing over the perfect solution the better. Your impatience with developing may cause bumps in the road when you implement, but you'd rather risk a journey of discovery than suffer through crafting the perfect solution!"]
            },
            "Neutral and equal to integrator":{
                title:"INTEGRATOR -- You score “neutral” for develop ",
                below_text:[" Your neutral score here suggests that when it’s time to develop solutions, you pick the best ideas and refine them until you have a practical plan to implement. That approach may seem obvious to you, but people with high and low preferences for developing are quite different. Those with a high preferences get drawn into every detail of the plan. Those with a low preference may resist planning all together and prefer to rush right into implementation. Your neutral position makes a good bridge between high and low Developers."]
            }
        },
        20:{
            title:'implement',
            below_text:["And finally, we get to the implement stage. This is where you make things happen. As the Nike motto says: Just do it. You take your solution and put it into action. You build it, test it, sell it or make it happen.  The implementation stage is about results. Everything else was a pre-amble. Now it’s time to get the job done."]
        },
        21:{
            "High":{
                title:"Your preference for implementing = High",
                below_text:[" When it comes to implementing, it looks like you have a high preference for this part of the process. High Implementers have a strong bias for action. Ready or not, you push toward your goal, and everything else falls away. You are confident selling your ideas to others. You tend to be persistent, decisive, determined and assertive. And others better move just as fast as you do, or you’ll just do the work yourself. In your haste to act, be careful not to fall victim to a “ready, fire, aim” syndrome, where you haven’t thought things through. No need to rush. Be confident that as a high Implementer, if there’s a way to deliver results, you’ll do it!"]
            },
            "neutral and not equal to integrator":{
                title:"Your preference for implementing = Neutral",
                below_text:["When it comes to implementing, it looks like you have a neutral preference, suggesting that you move into action when the time comes. While people with high preferences may leap into action before thinking things through, and people with a low preference may avoid and delay action altogether, your neutral position makes it easy for you to get the job done and to work with people on both the high and low ends of the Implementer spectrum."]
            },
            "Low":{
            title:'Your preference for implementing = Low',
            below_text:" When it comes to implementing, it looks like you have a low preference for this part of the process. Low Implementers don’t seek out busy work or activity for its own sake. Nor are they in a rush to take action. Of course, you still get things done, but sometimes, it may help you to have a deadline or some external support to do it. Your low preference for implementing can help high implementers avoid rushing to action too quickly."
            },
            "Neutral and equal to integrator":{
                title:"INTEGRATOR -- You score “neutral” for implement",
                below_text:["When it comes to implementing, once again, your neutral preference, suggesting that you pace yourself and move into action when the time comes. While people with high preference for implementing may leap to action before thinking things through, and people with a low preference may avoid and delay action, your neutral position makes it easy for you to get the job done and to work with people on both the high and low ends of the Implementer spectrum."]
            }
        },
        22:{
            title:"What you should know",
            below_text:["Now that we’ve gone through each one of your scores, here are some things to keep in mind about your overall profile."]
        },
        23:{
            title:"What you should know",
            below_text:["need 14 other profiles"]
        },
        24:{
            title:"What's next?",
            below_text:["So what’s next? Now that you know about your thinking preferences, you know that sometimes they work for you, and sometimes, they work against you.","Start by paying attention to thinking preferences in action. Do they drive you to make better decisions or worse ones? Instead of letting your thinking preferences drive your path through the </b>problem-solving process</b>, learn tools and techniques so you can do each stage better, and put your skills not your preferences in the driver seat."]
        },
        25:{
            title:"Work well with others",
            below_text:["When you work with others, remember that it’s usually easy to work with people like you. People with similar profiles tend to think in the same patterns and get energized by the same things. But over time, they can overlook the same steps and get hung up in the same places. <b>Cognitive diversity </b> actually promotes innovation."," So there’s real value in learning to work with people who have different thinking profiles. While unrecognized differences can cause stress and conflict, understanding differences can actually improve your performance, especially on complex, long term projects. Use FourSight to help you appreciate the energy and insights other people bring to different parts of the process."]
        },
        26:{
            title:"Create your collaboration profile",
            below_text:["n our experience, most people are really proud of their FourSight profile, and they want to share it with others. So, being innovators, we’ve developed a way for you to do that. It’s called a collaboration profile. You’ll find it on your FourSight learner map."," The collaboration profile shows your FourSight profile and gives you a place to add tips for what does and doesn’t work well for you when you collaborate with others.","Share it with people you problem solve with and invite them to take FourSight. Building this awareness in the groups you work with will help you strengthen both your working relationships and your performance results."]
        },
        27:{
            title:"Keep developing your thinking skills",
            below_text:["Thank you for taking the FourSight Thinking Profile. It’s our privilege to share this research with you. And we hope you share it with others. We wish you great success as you <b>solve whatever challenges lie ahead.</b>"]
        }
    }

    slides = [
        {
            id: 0,
            title: "FourSight<sup>&reg;</sup><br>Thinking<br>Profile<br>",
            slideBackgroundImage: "feed-banner.png",
            active: true,
            audio_link: this.admin_upload + '135/original.mp3?1650368969',
        },{
            id: 1,
            title: "How do you approach a challenge?",
            slideBackgroundImage: "slide2.jpg",
            audio: 16,
            active: true,
            audio_link: this.admin_upload + '136/original.mp3?1650369297',
        },{
            id: 2,
            title: "The universal process",   // clarify
            active: true,
            audio_link: this.admin_upload + '138/original.mp3?1650369510',
        },{
            id: 3,
            title: "The universal process",  // ideate
            active: true,
            audio_link: this.admin_upload + '139/original.mp3?1650369811',
        },{
            id: 4,
            title: "The universal process", // develop
            active: true,
            audio_link: this.admin_upload + '140/original.mp3?1650369871',
        },{
            id: 5,
            title: "The universal process", // implement
            active: true,
            audio_link: this.admin_upload + '141/original.mp3?1650369926',
        },{
            id: 6,
            title: "The universal process",
            active: true,
            audio_link: this.admin_upload + '137/original.mp3?1650369393',
        },{
            id: 7,
            title: "FourSight thinking preferences",
            slideBackgroundImage: "think-pref.png",
            active: true,
            audio_link: this.admin_upload + '142/original.mp3?1650370011',
        },{
            id: 8,
            title: "Preference does<br>not equal<br>ability",
            slideBackgroundImage: "feed-bulb.png",
            active: true,
            audio_link: this.admin_upload + '143/original.mp3?1650370074',
        },{
            id: 9,
            title: "There are no “good” or<br>“bad” scores",
            slideBackgroundImage: "feed-bulb.png",
            active: true,
            audio_link: this.admin_upload + '144/original.mp3?1650370289',
        },{
            id: 10,
            title: "Congratulations, ",
            profileSubText: "Your FourSight profile is",
            active: true,
            audio_link: [
                '',   // blank value because any profile do not having 0 id 
                this.admin_upload + '145/original.mp3?1650370401',
                this.admin_upload + '146/original.mp3?1650370457',
                this.admin_upload + '147/original.mp3?1650370524',
                this.admin_upload + '148/original.mp3?1650370579',
                this.admin_upload + '149/original.mp3?1650370793',
                this.admin_upload + '150/original.mp3?1650370850',
                this.admin_upload + '151/original.mp3?1650370916',
                this.admin_upload + '152/original.mp3?1650371120',
                this.admin_upload + '153/original.mp3?1650371187',
                this.admin_upload + '154/original.mp3?1650371243',
                this.admin_upload + '155/original.mp3?1650371311',
                this.admin_upload + '156/original.mp3?1650371368',
                this.admin_upload + '157/original.mp3?1650371418',
                this.admin_upload + '158/original.mp3?1650371513',
                this.admin_upload + '159/original.mp3?1650371566',
            ]
        },{
            id: 11,
            title: "Your FourSight Thinking Profile graph",
            active: true,
            audio_link: [
                this.admin_upload + '160/original.mp3?1650371716',
                this.admin_upload + '161/original.mp3?1650371938' 
            ] 
        },{
            id: 12,
            title: "The 4 stages",
            active: true,
            audio_link: this.admin_upload + '202/original.mp3?1650438184'
        },{
            id: 13,
            title: "Clarify",
            active: true,
            subtext: "This is the stage when you:",
            list: [
                {
                    listItem: "understand the situation"
                },
                {
                    listItem: "get the facts, background, details"
                },
                {
                    listItem: "frame the challenge"
                }
            ],
            audio_link: this.admin_upload + '162/original.mp3?1650372155',
        },{
            id: 14,
            title: "Your preference for clarifying = ",
            active: true,
            highList: [
                {
                    listItem: "seek information"
                },
                {
                    listItem: "ask questions for clarity"
                },
                {
                    listItem: "focus on the right problem to solve"
                },
                {
                    listItem: "may over analyze"
                }
            ],
            neutralList: [
                {
                    listItem: "gather key information"
                },
                {
                    listItem: "understand rules, restrictions"
                },
                {
                    listItem: "move forward"
                }
            ],
            lowList: [
                {
                    listItem: "move quickly through clarifying"
                },
                {
                    listItem: "just get the essential data"
                },
                {
                    listItem: "gather more data later"
                },
                {
                    listItem: "may miss important information"
                }
            ],
            audio_link: [
                this.admin_upload + '163/original.mp3?1650372208', //high
                this.admin_upload + '164/original.mp3?1650372275', //neutral
                this.admin_upload + '165/original.mp3?1650372330', //low
                this.admin_upload + '166/original.mp3?1650372386', // integ
            ]
        },{
            id: 15,
            title: "Ideate",
            active: true,
            subtext: "This is the stage when you:",
            list: [
                {
                    listItem: "play with ideas and possibilities"
                },
                {
                    listItem: "imagine"
                },
                {
                    listItem: "look at the big picture"
                },
                {
                    listItem: "make intuitive leaps"
                }
            ],
            audio_link: this.admin_upload + '167/original.mp3?1650372668'
        },{
            id: 16,
            title: "Your preference for ideating = ",
            active: true,
            subtext: "preference:",
            highList: [
                {
                    listItem: "seek novelty"
                },
                {
                    listItem: "see the big picture"
                },
                {
                    listItem: "generate lots of ideas"
                },
                {
                    listItem: "make connections"
                },
                {
                    listItem: "may seem distracted"
                }
            ],
            neutralList: [
                {
                    listItem: "generate some ideas"
                },
                {
                    listItem: "choose a reasonable option"
                },
                {
                    listItem: "move to solutions"
                }
            ],
            lowList: [
                {
                    listItem: "move quickly through this stage"
                },
                {
                    listItem: "settle on a workable idea"
                },
                {
                    listItem: "move to solutions"
                },
                {
                    listItem: "may avoid novelty"
                }
            ],
            audio_link:[
                this.admin_upload + '168/original.mp3?1650372735', //high
                this.admin_upload + '169/original.mp3?1650372776', //neutral
                this.admin_upload + '170/original.mp3?1650372826', //low
                this.admin_upload + '171/original.mp3?1650372907', //integ
            ]
        },{
            id: 17,
            title: "Develop",
            active: true,
            subtext: "This is the stage when you:",
            list: [
                {
                    listItem: "create workable solutions"
                },
                {
                    listItem: "examine pros and cons"
                },
                {
                    listItem: "optimize solutions"
                },
                {
                    listItem: "make plans"
                }
            ],
            audio_link: this.admin_upload + '172/original.mp3?1650428820'
        },{
            id: 18,
            title: "Your preference for developing = ",
            active: true,
            subtext: "preference:",
            highList: [
                {
                    listItem: "refine and polish solutions"
                },
                {
                    listItem: "take time to improve"
                },
                {
                    listItem: "craft complex solutions"
                },
                {
                    listItem: "can get stuck perfecting solutions"
                }
            ],
            neutralList: [
                {
                    listItem: "keep plans simple"
                },
                {
                    listItem: "don’t get fixed on perfection"
                },
                {
                    listItem: "move into action"
                }
            ],
            lowList: [
                {
                    listItem: "keep plans simple"
                },
                {
                    listItem: "don’t get fixed on perfection"
                },
                {
                    listItem: "move into action"
                }
            ],
            audio_link : [
                this.admin_upload + '173/original.mp3?1650428974', // high
                this.admin_upload + '174/original.mp3?1650429102', // neutral 
                this.admin_upload + '175/original.mp3?1650429164', // low
                this.admin_upload + '176/original.mp3?1650429254', // integ
            ]
        },{
            id: 19,
            title: "Implement",
            active: true,
            subtext: "This is the stage when you:",
            list: [
                {
                    listItem: "make things happen"
                },
                {
                    listItem: "Just do it!"
                },
                {
                    listItem: "learn as you go"
                },
                {
                    listItem: "focus on results"
                }
            ],
            audio_link: this.admin_upload + '177/original.mp3?1650429371'
        },{
            id: 20,
            title: "Your preference for implementing = ",
            active: true,
            subtext: "preference:",
            highList: [
                {
                    listItem: "push toward action"
                },
                {
                    listItem: "learn as you go"
                },
                {
                    listItem: "persist"
                },
                {
                    listItem: "may take action too soon"
                }
            ],
            neutralList: [
                {
                    listItem: "move to action when it’s time"
                },
                {
                    listItem: "think before acting"
                },
                {
                    listItem: "get things done over time"
                }
            ],
            lowList: [
                {
                    listItem: "act when necessary"
                },
                {
                    listItem: "not rushed"
                },
                {
                    listItem: "think first"
                },
                {
                    listItem: "may resist taking action"
                }
            ],
            audio_link: [
                this.admin_upload + '178/original.mp3?1650429450', // high
                this.admin_upload + '179/original.mp3?1650429560', // neutral
                this.admin_upload + '180/original.mp3?1650429608', // low
                this.admin_upload + '181/original.mp3?1650429681', // integ
            ]
        },{
            id: 21,
            title: "What you should know",
            active: true,
            audio_link: this.admin_upload + '182/original.mp3?1650429825',
        },{
            id: 22,
            active: true,
            audio_link: [
                '',
                this.admin_upload + '183/original.mp3?1650429956',
                this.admin_upload + '184/original.mp3?1650430059',
                this.admin_upload + '185/original.mp3?1650430181',
                this.admin_upload + '186/original.mp3?1650430291',
                this.admin_upload + '187/original.mp3?1650430370',
                this.admin_upload + '188/original.mp3?1650430513',
                this.admin_upload + '189/original.mp3?1650430567',
                this.admin_upload + '190/original.mp3?1650430674',
                this.admin_upload + '191/original.mp3?1650430747',
                this.admin_upload + '192/original.mp3?1650430834',
                this.admin_upload + '193/original.mp3?1650430945',
                this.admin_upload + '194/original.mp3?1650431024',
                this.admin_upload + '195/original.mp3?1650431134',
                this.admin_upload + '196/original.mp3?1650431245',
                this.admin_upload + '197/original.mp3?1650431359',
            ]
        },{
            id: 23,
            title: "What's <br>next?",
            slideBackgroundImage: "f-now.png",
            active: true,
            audio_link: this.admin_upload + '198/original.mp3?1650431479'
        },{
            id: 24,
            title: "Work well with others",
            active: true,
            audio_link: this.admin_upload + '199/original.mp3?1650431546',
        },{
            id: 25,
            title: "Create your collaboration profile",
            slideImage: 'learner-maps/collab-profile.jpg',
            active: true,
            audio_link: this.admin_upload + '200/original.mp3?1650431856'
        },{
            id: 26,
            title: "Thank you!<br/>Share the insight.",
            slideBackgroundImage: "f-end.png",
            subtext: 'Congratulations',
            profileText: 'Your FourSight profile is',
            active: true,
            audio_link: this.admin_upload + '201/original.mp3?1650431963'
        }
    ];

    profileLabel = [
      {    "label": "" },
      {
            "label": "Clarifiers are known for understanding what problem to solve.",
            "img": "2gqA28D"
      },
      {
            "label": "Ideators are known for generating original ideas.",
            "img": "2gcw9kE"
      },
      {
            "label": "Developers are known for polishing and perfecting plans.",
            "img": "2fPWCni"
      },
      {
            "label": "Implementers are known for making things happen.",
            "img": "2fWkicT"
      },
      {
            "label": "Early Birds are known for their strategic insights.",
            "img": "2gcAnck"
      },
      {
            "label": "Analyst are known for thinking things all the way through.",
            "img": "2gqs27A"
      },
      {
            "label": "Accelerators are no-nonsense problem solvers.",
            "img": "2fJQvFT"
      },
      {
            "label": "Theorists are known for creating elegant, original solutions.",
            "img": "2fWdPPi"
      },
      {
            "label": "Drivers are known for their ability to turn ideas into reality.",
            "img": "2gDzvxO"
      },
      {
            "label": "Finishers are known for executing complex solutions.",
            "img": "2gqAdRg"
      },
      {
            "label": "Hares are known for facilitating complex challenges.",
            "img": "2gKNWDq"
      },
      {
            "label": "Idea Brokers are known for capitalizing on opportunities.",
            "img": "2fWl2Pt"
      },
      {
            "label": "Optimists are known for delivering original solutions.",
            "img": "2gKMaSw"
      },
      {
            "label": "Integrators are known for their even-handed approach.",
            "img": "2fPWUdS"
      },
      {
            "label": "Realists are known for delivering targeted results.",
            "img": "2gctSGa"
      }
    ];

		author = {
			image: "author-pic.jpg",
      smallLogo: 'foursight-grey-logo-logotype-trans-w160.png',
      logoicon: 'foursight-grey-logo-icon.png',
			logotext: 'foursight-grey-logo-text.png',
			logoFoursight: "foursight-grey-logo-logotype-trans-w846.png"
		};

		foursightassets = {
			clarifyImage: "clarify-icon-2.png",
			titleClarify: "Clarify",
			ideateImage: "ideate-icon-2.png",
			titleIdeate: "Ideate",
			developImage: "develop-icon-2.png",
			titleDevelop: "Develop",
			implementImage: "impl-icon-2.png",
			titleImplement: "Implement"
		};

}
