import { Component } from '@angular/core';
import { StoreCartComponent } from '../../store/store-cart/store-cart.component';

@Component({
  selector: 'app-stripe-add-card',
  templateUrl: './stripe-add-card.component.html',
  styleUrls: ['./stripe-add-card.component.scss']
})
export class StripeAddCardComponent {

  constructor(public storeCartCompo: StoreCartComponent){}

  public close() {
    $('.add-card-modal').css('display', 'none');
  }

}
