import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from './../../../../environments/environment';
declare var jQuery:any;

@Component({
    selector: 'app-cig-cover',
    templateUrl: './cig-cover.component.html',
    styleUrls: ['./cig-cover.component.scss']
})
export class CigCoverComponent implements OnInit {

    @Input() profile:any;
    @Input() slides;
    @Input() author;
    public env;
    public profiles : any;
  
    constructor() { }

    ngOnInit() {
        this.env=environment;
        this.profiles = this.profile;
    }

    AfterContentInit() {
        jQuery(document).ready(function($){
            $('.cig-cover audio').css("width","70%").trigger("play");
        });
    }

    
    public ngOnChanges( changes: SimpleChanges ) : void {

        if ( changes.profile && ! changes.profile.isFirstChange() ) {
        	this.profiles = changes.profile.currentValue;

            jQuery(document).ready(function($){
            	$('.cig-cover audio').css("width","70%").trigger("play");
            });

        }

    }

}
