import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-learner-maps',
	templateUrl: './learner-maps.component.html',
	styleUrls: ['./learner-maps.component.scss']
})
export class LearnerMapsComponent implements OnInit {

	constructor() { }

	ngOnInit() {}
}
