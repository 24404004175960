import { Component } from '@angular/core';
import { AdminLearnerMapsListComponent } from '../../admin-learner-maps-list/admin-learner-maps-list.component';
import { DatepickerOptions } from 'ng2-datepicker';

@Component({
  selector: 'app-add-learnermap-popup',
  templateUrl: './add-learnermap-popup.component.html',
  styleUrls: ['./add-learnermap-popup.component.scss']
})
export class AddLearnermapPopupComponent {

  options: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2030,
    format: 'DD-MM-YYYY',
    formatTitle: 'DD-MM-YYYY',
    firstCalendarDay: 0,
    placeholder: 'Click to select a date', // HTML input placeholder attribute (default: '')
    inputClass: 'form-control', // 0 - Sunday, 1 - Monday
  };

  constructor(public lmCompo: AdminLearnerMapsListComponent) {}

  public close() {
    $('.addlm-modal').css('display', 'none');
  }
}
