import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-cn-print-summary',
  templateUrl: './cn-print-summary.component.html',
  styleUrls: ['./cn-print-summary.component.scss']
})
export class CnPrintSummaryComponent implements OnInit {

  env:any;
  constructor() { }

  ngOnInit(): void {
    this.env = environment;
  }

}
