// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-sm {
  font-size: 24px;
}

.lmslider__notes > h3 {
  text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/app/components/cig/cig-here-your-foursight-graph/cig-here-your-foursight-graph.component.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;AACD;;AAEA;EACI,0BAAA;AACJ","sourcesContent":[".title-sm{\n\tfont-size: 24px;\n}\n\n.lmslider__notes > h3 {\n    text-transform: capitalize;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
