import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-guide-popup',
  templateUrl: './guide-popup.component.html',
  styleUrls: ['./guide-popup.component.scss']
})
export class GuidePopupComponent implements OnInit {

  guides:any;
  guide:any  = 'Choose a language';
  long_guide:string = '';
  short_guide:string = '';
  selectedGuide:any = '';

  constructor(public http:HttpClient, public router: Router) { }

  ngOnInit(): void {
    this.getGuides();
  }

  // get interpretive guides
  public getGuides(){
    var that = this;
    this.selectedGuide= '';
    // this.guide = this.pdf_language;
    if(this.router.url != '/teams') {
      this.http.get(`${environment.assessment_admin}api/v1/interpretive_guides`).subscribe(data => {
          console.log(data);
          // Sort by Language name
          this.guides = data['interpretive_guides'].sort(function(a, b){
              return a.language_name.localeCompare(b.language_name);
          });
          that.changeGuide();
      },
          error => console.log('oops', error)
      )
    }
  }

  // change interpretive guides
  public changeGuide(){
    this.selectedGuide = '';
    console.log(this.guides);
    for(var i=0; i<this.guides.length; i++){
      if(this.guides[i].language_code == this.guide){
        this.short_guide = this.guides[i].short_guide;
        this.long_guide = this.guides[i].long_guide;
      }
    }
  }

  public closePopup() {
    $('.guide-modal').css('display', 'none');
  }

}
