import { Component, OnInit } from '@angular/core';
import { SlideoutContentService } from '../../../service/slideout-content.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-cn-print-develop',
  templateUrl: './cn-print-develop.component.html',
  styleUrls: ['./cn-print-develop.component.scss']
})
export class CnPrintDevelopComponent implements OnInit {

  public modal:any;
  env:any;
  constructor(public slideContent:SlideoutContentService) { }

  ngOnInit(): void {
    this.env = environment;
  }
  
  // open side popup
  public openPopup(content) {
    console.log(content)
    this.slideContent.process_type.next(content);
  }

}
