import { Component, Input, OnInit } from '@angular/core';
import { LambdaFunctionService } from './../../../service/lambda-function.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-pdf-download-popup',
  templateUrl: './pdf-download-popup.component.html',
  styleUrls: ['./pdf-download-popup.component.scss']
})
export class PdfDownloadPopupComponent implements OnInit {

  counter:number = 1;
  pdfLink:string = '';
  pdfName:any= '';
  timer: any;
  text_arr = ["","Generating PDFs","Generating PDFs","Creating PDF Link"];
  text:string = '';

  constructor(public lmbdaService: LambdaFunctionService) { }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  ngOnInit(): void {
    // console.log(this.funcName);
    // console.log(this.args);
    // console.log(this.timer);
    // this.startTimer();
    this.lmbdaService.pdfName.subscribe((name)=> {
      this.pdfName = name;
      this.text = `Fetching ${this.pdfName} Information`
    });
    this.lmbdaService.pdf_popup.subscribe((output)=> {
      if(output) {
        this.pdfLink = '';
        this.text = this.text_arr[0]
        this.startTimer();
      }  else {
        this.pdfLink = '';
        this.text = this.text_arr[0];
      }
    })
    this.lmbdaService.lambda_pdf.subscribe((res)=> {
        this.pdfLink = res;
    })
  }

  public startTimer() {
    console.log('startTimer')
    let dots = '';
    let count = 0;
    this.timer = setInterval(() =>{
      dots = dots + '.';
      this.text = this.text + dots;
      console.log(dots, dots.length)
      if(dots.length >= 3) {
        dots = '';
        count += 1;
        if(count < 3) {
          this.text = this.text_arr[count];
        }
      }
      if(this.pdfLink != '' && this.pdfLink != undefined) {
        clearInterval(this.timer)
      }
      // if(this.counter > 1 && this.pdfLink.length == 0) {
      //   this.counter = this.counter - 1;
      //   console.log(this.counter)
      // } else {
      //   console.log('clearInterval')
      //   clearInterval(this.timer)
      // }
    },1000)
  }

  public close() {
    $('.pdf-modal').css('display', 'none');
    this.pdfLink = '';
    this.text = this.text_arr[0];
    this.text = this.text.replace('Profile',this.pdfName);
    clearInterval(this.timer);
  }

}
