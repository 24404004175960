import { Component, Input, OnInit } from '@angular/core';
// import { Auth } from '../../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import {CommonDataService} from './../common-data.service';

@Component({
	selector: 'app-fs-icon',
	templateUrl: './fs-icon.component.html',
	styleUrls: ['./fs-icon.component.scss']
})
export class FsIconComponent implements OnInit {

	@Input() profile;

  constructor(public http: HttpClient, public service:CommonDataService ) { }

	ngOnInit() {}

}
