import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'spaceless'
})
export class SpacelessPipe implements PipeTransform {

	transform(value: string): string {
  		if(value!=null && value!=undefined)
  		{
  			return value.replace(/\s+/g,'-');
  		}  
  	}
}
