import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {environment} from '../../../../environments/environment';
@Component({
	selector: 'footer',
	templateUrl: 'footer.template.html'
})
export class FooterComponent {

	env:any;

	constructor(public router: Router) { }

	ngOnInit() {
		this.env = environment;
	}

	activeRoute(routename: string): boolean{
        return this.router.url.indexOf(routename) > -1;
    }

}
