import { NgModule } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import{ Router, RouterModule } from '@angular/router';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { APIService } from '../../../API.service';
import {ChallengeSummaryService} from './../../challenge-summary.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { SlideoutContentService } from '../../../service/slideout-content.service';
import { CnEditmodeService } from '../challenges_service/cn-editmode.service';
// import swal from 'sweetalert2/dist/sweetalert2.js';
import { AccessGroupService } from '../../access-group.service';
import { Store } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

declare var jQuery:any;

@Component({
  selector: 'app-ideate-challenge',
  templateUrl: './ideate-challenge.component.html',
  styleUrls: ['./ideate-challenge.component.scss']
})

export class IdeateChallengeComponent implements OnInit {

	breakthrough_content:any = '';
	breakthrough_dropdown:any = '';
	edit_breakthrough:boolean = true;
	brainstorm:any = [0];
	breakthrough:any = [];
	brainstorm_content:any = [];
	dupli_brainstorm_content:any = [];
	prev_brainstorm_content:any = [];
	prev_breakthrough_content: string = '';
	edit_brainstorm:boolean = true;
	checked_brainstorm:any = [];
	showBreakthrough: boolean = false;
	showBrainstorm: boolean = false;
	user:any;
	Btupdate:boolean = false;
	BtId:string = '';
	Bsupdate:boolean = false;
	BsId:string = '';
	IpId:string;
	private trackIds: any = [];
	Ipupdate:boolean = false;
	proposed_content:any = [];
	array_proposed_content:any = [];
	column_name: string = '';
	col_update: string;
	edit_column: number = 0;
	old_column: string;
	loading_breakthrough: boolean = false;
	loading_brainstorm: boolean = false;
	process_type: string;
	popup_type: string;
	all_data: any = [];
	checkState: boolean = true;
	deleteDatavalue:number;
	modal:any;
	// slideout variable
	slideoutChallenge:any;
	slideoutIdeas:any;
	slideoutTopIdeas:any;
	slideoutDivergeContent:any;
	slideoutConvergeContent:any;
	// slider video variable
	showVideo:boolean= false;
	// edit mode variables
	listGridView:string = 'listView';
	pin_challenge: boolean = false;
	challengeInput:boolean = false;
	ideasInput:boolean = false;
	editChallengeView:boolean = true;
	editIdeasView:boolean = true;
	editTopIdeasView:boolean = true;
	hideOnEditMode:boolean = true;
	EditModeIcon:boolean = false;
	gridViewClass:string = '';
	currentTracker:string = '';
	PreviousTracker:string = '';
	trackLabel:boolean = false;
	trackbtn:boolean = false;
	eyeIcon:boolean = false;
	hideDivLabel:string = 'See all diverged options';
	hideDiv:boolean= true;
	comefromdevelop:boolean = false;
	menu_links;
	cluster_btn:boolean = true;
	ngrxAccessGroup:any;
	personae:string = 'Select option';
	temprature:any = 0;
	openAiData:any = [];
	selectAiData:any = [];
	counter:number = 0;

  	constructor(private api: APIService, public router: Router, public allChallenge: ChallengeSummaryService,private modalService: NgbModal,public slideContent:SlideoutContentService,public editmodeService:CnEditmodeService,public accessgroup:AccessGroupService,public store: Store<any>,public http: HttpClient) {	}

	ngOnDestroy(){
		// sidebar
		let togglemenu = document.querySelector('.trigger-mini-bar') as HTMLElement;
		if(togglemenu.classList.contains('squeeze')){
			togglemenu.click();
			togglemenu.classList.remove('squeeze');
		}
		this.openEditmode(true,true,true,true,'block','#fff');
		// this.allChallenge.delData.unsubscribe();
	}

  	async ngOnInit() {
		this.ngrxAccessGroup = this.store.select<any>((state) => state.profile.accessGroupData);
		$('.loader-container').css('display','flex');
		// if(localStorage.getItem('developToideate') == 'true'){
		// 	$('.loader-container').css('display','flex');
		// }

		// sidebar
		let togglemenu = document.querySelector('.trigger-mini-bar') as HTMLElement;
		if(!togglemenu.classList.contains('squeeze')){
			togglemenu.click();
			togglemenu.classList.add('squeeze');
		}

		//change editmode background
		if(this.editmodeService.editmode.value){
			let page = document.getElementById('page-wrapper');
			page.style.background = '#fff';
			let footer = document.querySelector('.footer') as HTMLElement;
			footer.style.backgroundColor = '#fff';
			footer.style.border = 'none';
			// let togglemenu = document.querySelector('.trigger-mini-bar') as HTMLElement;
			// let main_nav = document.querySelector('.row.wrapper') as HTMLElement;
			// main_nav.style.display ='block';
			// togglemenu.click();
			this.editmodeService.editmode.next(false);
		}
		// diverge,converge,challenge,ideas,topIdeas content respectively 
		this.slideoutDivergeContent = this.slideContent.divergeConvergeContent().ideate.diverge;
		this.slideoutConvergeContent = this.slideContent.divergeConvergeContent().ideate.converge;
		this.slideoutChallenge = this.slideContent.divergeConvergeContent().ideate.challenge;
		this.slideoutIdeas = this.slideContent.divergeConvergeContent().ideate.ideas;
		this.slideoutTopIdeas = this.slideContent.divergeConvergeContent().ideate.topIdea;
		
		var that = this;
		let ideatePromise = new Promise((resolve,error)=>{
			let BtFilter = {email:{eq:localStorage.getItem('email')}, processTypeId:{eq:"c1c17711-b4a3-4dcc-98b5-fe73ee0791d2"}, type:{eq:"process-stage"}, title:{eq:"breakthrough-question"}};
			this.api.ListFrameworks(BtFilter,10000).then(function (data) {
				if(data.items.length == 0){
					that.edit_breakthrough = false;
					that.breakthrough_content = '';
					that.prev_breakthrough_content = '';
				}else{
					that.breakthrough = JSON.parse(data.items[0].content);
					that.BtId = data.items[0].id;
					that.Btupdate = true;
					let data_str = (that.breakthrough[0]).split("\n");
					console.log(data_str)
					that.breakthrough_dropdown = data_str[0];
					that.breakthrough_content = data_str[1];
					// that.prev_breakthrough_content = that.breakthrough[0].replace(/\n/g,'');
					// that.breakthrough_content = that.breakthrough[0].replace(/\n/g,'');
					that.edit_breakthrough = false;
					that.showBreakthrough = true;
				}
			})
	
			let BsFilter = {email:{eq:localStorage.getItem('email')}, processTypeId:{eq:"790b8a8e-9fdd-475d-9ab1-d320bfe69086"}, type:{eq:"process-stage"}, title:{eq:"brainstorm"}};
			this.api.ListFrameworks(BsFilter,10000).then(function (data) {
				if(data.items.length == 0){
					that.edit_brainstorm = false;
					for(var i=0; i<16;i++){
						that.brainstorm_content[i] = '';
						that.brainstorm[i] = '';
					}
				}else{
					console.log(data.items[0].content);
					that.BsId = data.items[0].id;
					that.Bsupdate = true;
					that.brainstorm = JSON.parse(data.items[0].content);
					that.brainstorm_content = JSON.parse(data.items[0].content);
					that.dupli_brainstorm_content = JSON.parse(data.items[0].content);
					that.prev_brainstorm_content = JSON.parse(data.items[0].content);
					that.edit_brainstorm = false;
					that.showBrainstorm = true;
				}
			})
	
	
			let IpFilter = {email:{eq:localStorage.getItem('email')}, processTypeId:{eq:"790b8a8e-9fdd-475d-9ab1-d320bfe69086"}, type:{eq:"process-stage"}, title:{eq:"ideas-proposed"}};
			 this.api.ListFrameworks(IpFilter,10000).then(function (data) {			
				if(data.items.length == 0){
					that.unassignedIdeas();
					if(localStorage.getItem('developToideate') == 'true'){
							console.log('loader true1');
								localStorage.setItem('developToideate','false');
								$('.loader-container').css('display','flex');
								that.comefromdevelop = true;
								that.topideasDiverge(1);
					}
				}else{
					if(localStorage.getItem('developToideate') == 'true'){
								console.log('loader true');
								localStorage.setItem('developToideate','false');
								$('.loader-container').css('display','flex');
								that.comefromdevelop = true;
								that.topideasDiverge(1);
					}
					console.log(data.items[0].content);
					that.IpId = data.items[0].id;
					that.Ipupdate = true;
					// that.proposed_content = JSON.parse(data.items[0].content);
					for(var i=0; i<JSON.parse(data.items[0].content).length; i++){
						console.log(JSON.parse(data.items[0].content)[i]);
						if(JSON.parse(data.items[0].content)[i]?.length != 0){
							that.proposed_content[i] = JSON.parse(JSON.parse(data.items[0].content)[i]);
						}
					}
					// that.array_proposed_content = Array(that.proposed_content);
					console.log(that.proposed_content);
					for(var i=0; i< that.proposed_content.length-2;i++){
						  that.trackIds[i] = JSON.stringify(i+2);
					  }
					  console.log(that.trackIds)
				}
			})
			setTimeout(() => {
				resolve([BtFilter,BsFilter,IpFilter]);
			}, 1000);
		});

		ideatePromise.then(()=> {
			$('.loader-container').css('display','none');
		})

	    jQuery(document).click(function(event){
	    	console.log(event);
			$("body").click(function() {
				$(".dotactions__dropdown").hide();
			});	

			$(".dotactions__top, .dotactions__dropdown").click(function(e) {
				e.stopPropagation();
			});

		});
		jQuery(document).on("click", ".popaccordian__rowhead", function(e) {
	    	e.stopPropagation();
	  		var thisPar = jQuery(this).parents(".popaccordian__row");
	

	  		if( !jQuery(thisPar).hasClass("popaccordian__row--active") ) {
	  			thisPar.find(".popaccordian__rowbody").slideDown();
	  			thisPar.siblings().find(".popaccordian__rowbody").slideUp();
	  			thisPar.addClass("popaccordian__row--active");
	  			thisPar.siblings().removeClass("popaccordian__row--active");
	  		} else {
	  			thisPar.siblings().find(".popaccordian__rowbody").slideUp();
	  		}
	    })
		//Retrive Features
		this.ngrxAccessGroup.subscribe(message => { 
            if(message){  
                this.menu_links = message;
				// console.log('Menu Links');
				// console.log(message);
            }

        },
        err => {
            // Log errors if any
            // console.log(err);
        })

		console.log(this.prev_brainstorm_content.length)
		// come from ideate
		if(localStorage.getItem('gotoideate') == 'true'){
			localStorage.setItem('gotoideate','false');
			// $('.loader-container').css('display','flex');
			// if(this.prev_brainstorm_content?.length > 1){
			// 	console.log('calling diverge')
			// 	await setTimeout(() => {
			// 		this.BsDiverge()
			// 	}, 1500);
			// }else {
			// 	console.log('calling start')
			// 	await setTimeout(() => {
			// 		this.BsStart();
			// 	}, 1500);
			// }
		}
  	}

  	public BtStart(){
		this.BtId = '0';
		this.edit_breakthrough = true;
		this.breakthrough_dropdown = 'How might...';
  		this.breakthrough_content = '';
	}

  	public BtDiverge(trackit = 0, currentTrack = ''){
		this.router.navigate(['/challenges/clarify']);
		localStorage.setItem('ideateToclarify','true');
	}

	public submitBreakthrough(trackit = 0){
		if(this.breakthrough_content && this.breakthrough_content != ''){
			if(this.breakthrough_content.indexOf('?') > -1){
				if(trackit == 1){
					this.submitChallenge(1)
				}else{
					this.submitChallenge();
				}
			}else{
				var mark = confirm("Do you want to add question mark to the end of this challenge?");
			  	if(mark == true){
			  		this.breakthrough_content = this.breakthrough_content + '?';
			  		if(trackit == 1){
						this.submitChallenge(1)
					}else{
						this.submitChallenge();
					}
				}else{
					if(trackit == 1){
						this.submitChallenge(1)
					}else{
						this.submitChallenge();
					}
				}
			}
		}
	}

  	public async submitChallenge(trackit = 0){
  		var that = this;
  		for(var i=0; i<this.breakthrough.length; i++){
  			if(this.breakthrough[i] == this.breakthrough_dropdown.concat("\n"+this.breakthrough_content)){
  				break;
  			}
  			if(i== this.breakthrough.length - 1 && this.breakthrough[i] != this.breakthrough_dropdown.concat("\n"+this.breakthrough_content)){
  				this.breakthrough[0] = this.breakthrough_dropdown.concat("\n"+this.breakthrough_content);
  				this.breakthrough.push(this.breakthrough_dropdown.concat("\n"+this.breakthrough_content));
  			}
  		}
  		if(this.breakthrough.length == 0){
  			this.breakthrough[0] = this.breakthrough_dropdown.concat("\n"+this.breakthrough_content);
			this.breakthrough.push(this.breakthrough_dropdown.concat("\n"+this.breakthrough_content));
  		}
  		this.loading_breakthrough = true;
	  	if(!this.Btupdate){
  		await	this.api.CreateFramework({email:localStorage.getItem('email'), title:"breakthrough-question", processTypeId:"c1c17711-b4a3-4dcc-98b5-fe73ee0791d2",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:JSON.stringify(this.breakthrough)}).then(function (data){
					that.edit_breakthrough = false;
					that.breakthrough = JSON.parse(data.content);
					that.BtId = data.id;
					that.Btupdate = true;
					that.loading_breakthrough = false;
					let data_str = JSON.parse(data.content)[0].split("\n");
					that.breakthrough_dropdown = data_str[0];
					that.breakthrough_content = data_str[1];
					setTimeout(function() {
						this.toastr.options = {
							closeButton: true,
							progressBar: true,
							timeOut: 2000
						};
						this.toastr.success('Your thought has been updated!', 'Nice!');
					}, 1000);
		        	that.showBreakthrough = true;
	  		}) 
  		}else{
  		await	this.api.UpdateFramework({id:this.BtId,email:localStorage.getItem('email'), title:"breakthrough-question", processTypeId:"c1c17711-b4a3-4dcc-98b5-fe73ee0791d2",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:JSON.stringify(this.breakthrough)}).then(function (data){
					that.edit_breakthrough = false;
					that.breakthrough = JSON.parse(data.content);
					that.loading_breakthrough = false;
					let data_str = JSON.parse(data.content)[0].split("\n");
					that.breakthrough_dropdown = data_str[0];
					that.breakthrough_content = data_str[1];
					setTimeout(function() {
						this.toastr.options = {
							closeButton: true,
							progressBar: true,
							timeOut: 2000
						};
						this.toastr.success('Your thought has been updated!', 'Nice!');
					}, 1000);
					that.Btupdate = true;
					that.edit_breakthrough = false;
					that.showBreakthrough = true;
	  		}) 
  		}
		if(trackit == 1){
			this.trackLabel = false;
			this.trackbtn = false;
			this.callto_idea_ti();
		}else{
			this.openEditmode(true,true,true,true,'block','#fff');
			this.trackLabel = false;
			this.trackbtn = false;
		}
  	}

	// callback function for ideas and top ideas
	public callto_idea_ti(){
		switch(this.PreviousTracker){
			case 'Ideas':
				this.BsDiverge();
				break;
			case 'Top ideas':
				this.topideasDiverge();
				break;
			default :
				this.openEditmode(true,true,true,true,'block','#fff');
				break;
		}
	}

  	public BsStart(){
		this.BsId = '0';
		this.edit_brainstorm = true;
		if(this.brainstorm.length < 16){
    		for(var i=this.brainstorm_content.length; i< 16; i++){
    			this.brainstorm_content[i] = '';
    			this.brainstorm[i] = '';
    		}
    	}
		this.BsDiverge()
	}

  	public BsConverge(){
  		this.cancelBs();
		this.showBrainstorm = false;
		this.edit_brainstorm = false;
	}

	public BsDiverge(trackit = 0,currentTrack = ''){
		this.showBrainstorm = false;
		this.edit_brainstorm = true;
		this.cancelBreakthrough();
		if(this.brainstorm.length < 16){
    		for(var i=this.brainstorm_content.length; i< 16; i++){
    			this.brainstorm_content[i] = '';
    			this.brainstorm[i] = '';
    		}
    	}

		if(trackit == 1){
			this.openEditmode(false,true,false);
			this.trackLabel = true;
			this.trackbtn = true;
			this.currentTracker = currentTrack;
			this.eyeIcon = true;
		}else{
			this.PreviousTracker = 'Ideas';
			this.openEditmode(false,true,false,true);
		}
		var that = this;
		// setTimeout(function(){
		// 	that.stickChallenge('ideas');
		// },300)
		setTimeout(function(){
			$(document).scrollTop(0);
			setTimeout(function(){
				$(document).scrollTop(0);
				let allBtDelIcon = document.querySelectorAll('.card-body__minus');
				console.log(allBtDelIcon)
				if(allBtDelIcon.length == 1) {
					allBtDelIcon[0].classList.add('card-body__minus--hide');
				}
			}, 10);
		}, 10);
		$('.loader-container').css('display','none');
	}

	// close ideas converge
	public submit_converge_brainstorm(trackit = 0){
		this.edit_brainstorm = false;
  		this.showBrainstorm = true;
		if(trackit == 1){
			this.callto_idea_ti();
		}else{
			this.openEditmode(true,true,true,true,'block','#fff');
		}
		this.trackbtn = false;
		this.trackLabel = false;
	}

	// go to next step like ideas to top ideas
	public gotonextstep(next_step_val){
		switch (next_step_val) {
			case 'Top ideas':
				this.submit_converge_brainstorm();
				this.topideasDiverge();
				break;

			case 'develop':
				this.closeTopIdeas();
				localStorage.setItem('gotodevelop','true');
				this.router.navigate(['/challenges/develop']);
				break;
			default:
				break;
		}
	}

	// submit ideas
  	public submitBrainstorm(trackit = 0,currentTrack = ''){
		if(trackit == 1 ) {
			this.trackbtn = true;
			this.trackLabel = true;
		}else{
			this.trackbtn = false;
			this.trackLabel = false;
		}
		if(currentTrack != ''){
			this.callto_idea_ti()
		}
  		for(var i=this.brainstorm_content.length;i>=1;i--){
  			if(this.brainstorm_content[i] == null || this.brainstorm_content[i] == ''){
  				this.brainstorm_content.splice(i,1);
  				this.brainstorm.splice(this.brainstorm.length-1,1);
  			}
  		}
	  	if(this.brainstorm_content.length > 1){
	  		var that = this;
	  		this.loading_brainstorm = true;
	  		if(!this.Bsupdate){
	  			this.api.CreateFramework({email:localStorage.getItem('email'), title:"brainstorm", processTypeId:"790b8a8e-9fdd-475d-9ab1-d320bfe69086",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:JSON.stringify(this.brainstorm_content)}).then(function (data){
		  			that.edit_brainstorm = false;
		  			that.BsId = data.id;
		  			that.loading_brainstorm = false;
		  			that.brainstorm = JSON.parse(data.content);
		        	that.brainstorm_content = JSON.parse(data.content);
		        	that.prev_brainstorm_content = JSON.parse(data.content);
		        	that.Bsupdate = true;
		        	that.showBrainstorm = false;
		        	that.submitIdeasProposed();
			  		setTimeout(function() {
			            this.toastr.options = {
			                closeButton: true,
			                progressBar: true,
			                timeOut: 2000
			            };
			            this.toastr.success('Your thought has been updated!', 'Nice!');
			        }, 1000);
		  		}) 
	  		}else{
	  			this.api.UpdateFramework({id:this.BsId,email:localStorage.getItem('email'), title:"brainstorm", processTypeId:"790b8a8e-9fdd-475d-9ab1-d320bfe69086",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:JSON.stringify(this.brainstorm_content)}).then(function (data){
		  			that.edit_brainstorm = false;
		  			that.loading_brainstorm = false;
		  			that.brainstorm = JSON.parse(data.content);
	        		that.brainstorm_content = JSON.parse(data.content);
	        		that.prev_brainstorm_content = JSON.parse(data.content);
	        		that.showBrainstorm = false;
	        		that.checkState = true;
	        		that.submitIdeasProposed();
			  		setTimeout(function() {
			            this.toastr.options = {
			                closeButton: true,
			                progressBar: true,
			                timeOut: 2000
			            };
			            this.toastr.success('Your thoughts have been updated!', 'Nice!');
			        }, 1000);
		  		}) 
	  		}
	  	}else{
	  		if(this.BsId && this.BsId != '0'){
	  			this.delBrainstorm();
	  		}
	  		this.BsId = '';
	  		this.edit_brainstorm = false;
	  	}
  	}

  	public delBrainstorm(){
	  	var that = this;
	  	var remove = confirm("Are you sure, you want to delete this? This cannot be undone!");
	  	if(remove == true){
			try {
				this.api.DeleteFramework({id:this.BsId}).then(function(){
					that.Bsupdate = false;
					that.brainstorm_content = [];
					that.checked_brainstorm = [];
					that.prev_brainstorm_content = [];
					that.brainstorm = [0];
					that.BsId = '';
					that.edit_brainstorm = false;
					that.showBrainstorm = false;
					that.delIdeaProposed();
					for(var i=0; i<16;i++){
					  that.brainstorm_content[i] = '';
					  that.brainstorm[i] = '';
				  }
					setTimeout(function() {
					  this.toastr.options = {
						  closeButton: true,
						  progressBar: true,
						  timeOut: 2000
					  };
					  this.toastr.success('Your thoughts has been deleted!', 'Nice!');
				  }, 1000);
				})
			} catch (err) {
				console.log(err)
			}
		}
  	}

  	public addBsLine(){
		  console.warn(" calling addBsLine")
		$('.card-body__editcol.hideblk:last-child').css('display','block');
		$('.card-body__subtitle span').css('opacity','1');
		this.trackbtn = false;
  		if(document.activeElement.id){
		  	if(this.brainstorm.length <= parseInt(document.activeElement.id.replace("Bs","")) + 1 ){
	  			this.brainstorm.push(this.brainstorm.length);
	  		}else{
	  			for(var i=parseInt(document.activeElement.id.replace("Bs",""))+1; i<=this.brainstorm.length-1; i++){
	  				if(this.brainstorm_content[i] == '' || this.brainstorm_content[i] == undefined){
	  					document.getElementById("Bs"+ (i)).focus();
	  					break;
	  				}
	  				if(i == this.brainstorm.length - 1 && this.brainstorm_content[i] != '' && this.brainstorm_content[i] != undefined){
	  					this.brainstorm.push(this.brainstorm.length);
	  				}
	  			}
	  		}
	  	}else{
	  		this.brainstorm.push(this.brainstorm.length);
	  	}
		// hide and show idea lines
		  setTimeout(function(){
			let allBtDelIcon = document.querySelectorAll('.card-body__minus');
			console.log(allBtDelIcon)
			if(allBtDelIcon.length > 1) {
				allBtDelIcon[0].classList.remove('card-body__minus--hide');
			}
		}, 10);
  	}

  	public subBsLine(i){
	  	this.brainstorm_content.splice(i,1);
	  	this.brainstorm.splice(this.brainstorm.length-1,1);
		// show hide ideas lines
		  setTimeout(function(){
			let allBtDelIcon = document.querySelectorAll('.card-body__minus');
			console.log(allBtDelIcon)
			if(allBtDelIcon.length == 1) {
				allBtDelIcon[0].classList.add('card-body__minus--hide');
			}
		}, 100);
  	}

	// change cluster name
  	public checkCluster(i, e, val){
		  if(val == 'Cluster '+i){
  		}else{
  			this.submitIdeasProposed();
  		}
  	}

  	public submitIdeasProposed(){
  		var that = this;
  		this.proposed_content[1] = [];
		var loc = this;
  		jQuery(".checkedbox-proposed:checkbox").each(function() {
	        var value = jQuery(this).val();

	        if (jQuery(this).is(':checked')) {
	        	loc.proposed_content[1].push(value);
			} 
	  	})
  		for(var x=0; x< this.proposed_content.length-2;x++){
  			this.trackIds[x] = JSON.stringify(x+2);
  		}
  		var content = [];
	  	for(var z=0; z<=this.proposed_content[0].length + 1; z++){
			if(this.proposed_content[z]){
		  		content[z] = JSON.stringify(this.proposed_content[z]);
		  	}else{
		  		this.proposed_content[z] = [];
		  		z--;
		  	}
		}
  		if(!this.Ipupdate){
  			this.api.CreateFramework({email:localStorage.getItem('email'), title:"ideas-proposed", processTypeId:"790b8a8e-9fdd-475d-9ab1-d320bfe69086",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:JSON.stringify(content)}).then(function (data){
	  			that.IpId = data.id;
	  			for(var i=0; i<JSON.parse(data.content).length; i++){
	        		if(JSON.parse(data.content)[i]?.length != 0){
	        			that.proposed_content[i] = JSON.parse(JSON.parse(data.content)[i]);
	        		}
	        	}
	  			that.Ipupdate = true;
		  		setTimeout(function() {
		            this.toastr.options = {
		                closeButton: true,
		                progressBar: true,
		                timeOut: 2000
		            };
		            this.toastr.success('Your thought has been updated!', 'Nice!');
		        }, 1000);
	  		}) 
  		}else{
  			this.api.UpdateFramework({id:this.IpId,email:localStorage.getItem('email'), title:"ideas-proposed", processTypeId:"790b8a8e-9fdd-475d-9ab1-d320bfe69086",type:"process-stage", toolId:"3a8e48d7-110d-4a1e-9936-7438a518dd08", content:JSON.stringify(content)}).then(function (data){
        		for(var i=0; i<JSON.parse(data.content).length; i++){
	        		console.log(JSON.parse(data.content)[i]);
	        		if(JSON.parse(data.content)[i]?.length != 0){
	        			that.proposed_content[i] = JSON.parse(JSON.parse(data.content)[i]);
	        		}
	        	}
		  		setTimeout(function() {
		            this.toastr.options = {
		                closeButton: true,
		                progressBar: true,
		                timeOut: 2000
		            };
		            this.toastr.success('Your thoughts have been updated!', 'Nice!');
		        }, 1000);
	  		}) 
  		}
	}


  	public async IpOrderChange(event) {
	  	console.log(event);
	  	var that = this;
	  	transferArrayItem(this.proposed_content[event.previousContainer.id], this.proposed_content[event.container.id], event.previousIndex, event.currentIndex);
	  	console.log(this.proposed_content);
	  	this.submitIdeasProposed();
  	}


  	public checkBrainstorm(val, index, e){
  		if(this.checkState){
	  		console.log(val);
	  		this.checkState = false;
		  	var that = this;
		  	if(val == true){
		  		for(var i=1; i<this.proposed_content.length; i++){
		  			for(var j=0; j<this.proposed_content[i].length; j++){
		  				if(this.proposed_content[i][j] == this.brainstorm_content[0][index]){
		  					this.proposed_content[i].splice(j,1);
		  					this.submitIdeasProposed();
		  				}
		  			}
		  		}
		  		var brainstorm_content = [];
		  		var content = this.brainstorm_content;
			  	jQuery(".checkedbox:checkbox").each(function(ind) {
			        var value = jQuery(this).val();
			        if(jQuery(this).prop('checked') == true && value != ''){
			  			brainstorm_content.push(value);
			  		}
			  	})
			  	this.brainstorm_content[0] = brainstorm_content;
			  	this.brainstorm_content = content;
			  	this.submitBrainstorm();
			}else{
				this.proposed_content[2].push(this.brainstorm_content[index]);
				this.submitIdeasProposed();
				var brainstorm_content = [];
				var content = this.brainstorm_content;
			  	jQuery(".checkedbox:checkbox").each(function(ind) {
			        var value = jQuery(this).val();
			        // console.log(jQuery(this).prop('checked'));
			        if(jQuery(this).prop('checked') == true && value != ''){
			  			brainstorm_content.push(value);
			  		}
			  	})
			  	this.brainstorm_content[0] = brainstorm_content;
			  	this.brainstorm_content = content;
			  	this.submitBrainstorm();

			}
		}else{
			e.preventDefault();
		}
  	}

  	public unassignedIdeas(){
  		this.proposed_content = [];
  		this.proposed_content[0] = [];
		this.proposed_content[0] = ['Converged Ideas'];
		this.proposed_content[1] = [];
		this.proposed_content[2] = this.brainstorm_content[0];
  		this.submitIdeasProposed();
  	}

  	public deleteIdea(i,j){
  		for(var index=0; index<this.brainstorm_content[0].length; index++){
			if(this.proposed_content[i][j] == this.brainstorm_content[0][index]){
				this.proposed_content[i].splice(j,1);
				this.brainstorm_content[0].splice(index,1);
				this.submitIdeasProposed();
				this.submitBrainstorm();
			}
		}
  	}

  	public newColumn(){
		this.cluster_btn = false;
		console.warn(this.proposed_content);
  		this.column_name = 'Cluster '+(this.proposed_content[0].length +1);

		// console.warn('cluster index----->' + this.proposed_content[0].indexOf(this.column_name));
		// console.warn('cluster index value----->' + this.proposed_content[0][(this.proposed_content[0].indexOf(this.column_name))]);

		for(var i=0; i< this.proposed_content[0].length; i++){
			if(this.proposed_content[0].indexOf(this.column_name) > -1){
				let count = 2;
				this.column_name = 'Cluster ' + (this.proposed_content[0].length + count);
				console.warn('new name of column is------->'+this.column_name)
				count++;
			}else{
				break;
			}
		}
		console.warn(this.column_name)

		for(var i=0; i< this.proposed_content[0].length; i++){

  			if(this.proposed_content[0][i] == this.column_name){
  				break;
  			}
  			// if(this.proposed_content[0][i] != this.column_name && i == this.proposed_content[0].length - 1 && this.column_name != ''){
  			if(this.proposed_content[0][i] != this.column_name && this.column_name != ''){
			  	this.proposed_content[0].push(this.column_name);
				this.proposed_content[this.proposed_content.length] = [];
				this.column_name = '';
				console.log(this.proposed_content);
				this.submitIdeasProposed();
  			}
  		}
		console.log(i-1+' yes');
		console.warn(this.proposed_content[i])
		this.editColumn(i+1,'Cluster ' + this.proposed_content[0].length)
		// console.log($('.card-body__ipcol:nth-last-child(2) input'))
		// $('.card-body__ipcol:nth-last-child(2) input').click();
  	}

  	public deleteColumn(param){
  		if(param == 'Converged Ideas'){

  		}else{
  			for(var i =0; i< this.proposed_content[0].length; i++){
  				if(this.proposed_content[0][i] == param){
  					this.proposed_content[2] = this.proposed_content[2].concat(this.proposed_content[i+2]);
  					this.proposed_content[0].splice(i,1);
  					this.proposed_content.splice(i+2,1);
  					this.submitIdeasProposed();
  				}
  			}
  		}  		
  	}


  	public clarifyBT(){
  		localStorage.setItem('ideateToclarify','true');
  		this.router.navigate(['/challenges/clarify']);
  	}

  	public cancelBs(closeedit = false){
		if(closeedit == true){
			this.openEditmode(true,true,true,true,'block','#fff');
			this.trackLabel = false;
			this.trackbtn = false;
		}

  		this.edit_brainstorm = false;
  		var brainstorm = this.prev_brainstorm_content;
  		this.brainstorm_content = [];
  		this.brainstorm = [];
  		for(var i=0;i<brainstorm.length;i++){
    		let data_str = brainstorm[i];
    		this.brainstorm_content[i] = data_str;
    		this.brainstorm[i] = i;
    	}
    	if(this.BsId == '0'){
    		this.edit_brainstorm = false;
    		this.BsId = '';
    		for(var i=0; i<16;i++){
        		this.brainstorm_content[i] = '';
        		this.brainstorm[i] = '';
        	}
    	}
    	if(this.BsId != '' && this.BsId != '0'){
    		this.showBrainstorm = true;
    	}
  	}

  	public cancelBreakthrough(closeedit= false){
		if(closeedit == true){
			this.openEditmode(true,true,true,true,'block','#fff');
			this.trackLabel = false;
			this.trackbtn = false;
		}

  		this.edit_breakthrough = false;
    	if(this.BtId == '0'){
    		this.edit_breakthrough = false;
    		this.BtId = '';
    		this.breakthrough_content = '';
        	this.prev_breakthrough_content = '';
    	}
    	if(this.BtId != '' && this.BtId != '0'){
    		this.showBreakthrough = true;
    		let data_str = (this.breakthrough[0]).split("\n");
    		this.breakthrough_dropdown = data_str[0];
    		this.breakthrough_content = data_str[1];
    	}
  	}

  	public openColAction(i, e){
  		e.stopPropagation();
  		jQuery('.dotactions__dropdown').css("display", "none");
  		jQuery('.col_action'+i).css("display", "block");
  	}

  	public openDataAction(i,j, e){
  		e.stopPropagation();
  		jQuery('.dotactions__dropdown').css("display", "none");
  		jQuery('.data_action'+i+j).css("display", "block");
  	}

  	public hideDropdown(){
  		jQuery('.dotactions__dropdown').css("display", "none");
  		this.col_update = '';
  	}

  	public openAllCol(i,j,val){
  		jQuery('.dotactions__dropdowninner').css("display", "none");
  		jQuery('.move'+i+j).css("display", "block");
  		this.col_update = val;
  	}

  	public updateCol(i,j,val){
  		jQuery('.move'+i+j).css("display", "none");
		for(var x=0; x<this.proposed_content[0].length; x++){
			if(this.col_update == this.proposed_content[0][x]){
				this.proposed_content[x+2].push(val);
				this.proposed_content[i].splice(j,1);
				// this.col_update = '';
				this.submitIdeasProposed();
			}
		}
  			
  	}

  	public delIdeaProposed(){
  		var that = this;
	  	// var remove = confirm("Are you sure, you want to delete this? This cannot be undone!");
	  	// if(remove == true){	
			try {
				this.api.DeleteFramework({id:this.IpId}).then(function(){  	
					that.brainstorm_content[0] = [];
					that.proposed_content = [];
				  that.proposed_content[0] = [];
				  that.proposed_content[0] = ['Converged Ideas'];
				  that.proposed_content[1] = [];
				  that.proposed_content[2] = [];
				  that.submitBrainstorm();
				  // setTimeout(function() {
			//           this.toastr.options = {
			//               closeButton: true,
			//               progressBar: true,
			//               timeOut: 2000
			//           };
			//           this.toastr.success('Your thoughts has been deleted!', 'Nice!');
			//       }, 1000);
			  })
			} catch (err) {
				console.log(err)
			}
		// }
  	}

  	public editColumn(i, val){
		console.log(i + '--->' + val)
  		this.edit_column = i;
  		console.log(this.edit_column);
  		this.old_column = val;
  		console.log(this.old_column, val)
  		jQuery('.dotactions__dropdown').css("display", "none");
  	}

  	public saveColumn(i){
		this.cluster_btn = true
  		this.edit_column = 0;
  		this.proposed_content[0][i] = this.old_column;
		let clusterindex = i ;
		setTimeout(function () {
			$('input[ng-reflect-name="col_head'+clusterindex+'"]').prop('checked', true);
		},100)
  		this.submitIdeasProposed();
  	}

  	public cancelColumn(i){
		this.cluster_btn = true
  		this.edit_column = 0;
		let clusterindex = i ;
		// setTimeout(function () {
		// 	$('input[ng-reflect-name="col_head'+clusterindex+'"]').prop('checked', true);
		// },100)
  		// this.proposed_content[0][i] = this.old_column;
  	}

	public openPopup(content){
	  if(content == 'diverge'){
		  $('.tooltippopup').addClass('tooltippopup--active');
		  setTimeout(function(){
			  jQuery('.popaccordian__row:nth-child(2) .popaccordian__rowhead').trigger( "click" );
			  // console.log('clicked');
		  }, 10);
		  this.process_type = content;
		  this.popup_type = 'info';
		  var that = this;
		  this.allChallenge.getChallenge().then(function (challenge) {
			  // console.log(challenge);
			  that.all_data = challenge;
			  that.all_data['thinkCon'] = JSON.parse(that.all_data['thinkCon'][0]);
		  });
	  }
	  else{
		  $('.tooltippopup').addClass('tooltippopup--active');
		  this.process_type = content;
		  this.popup_type = 'info';
		  var that = this;
		  this.allChallenge.getChallenge().then(function (challenge) {
			  // console.log(challenge);
			  that.all_data = challenge;
		  });
	  }
	}

  	public closePopup(){
  		$('.tooltippopup').removeClass('tooltippopup--active');
  		this.process_type = '';
  		this.popup_type = '';
		this.showVideo = false;
  	}

  	public popupDetail(content){ 
  		this.popup_type = content;
  	}

	public SliderToolsNavigator(nav){
		//  Redirect if user has tools feature 
		if(nav == 'forcedConnection'){
			if(this.menu_links.main_menu.tools.toolcards){
				this.router.navigate(['/tools/toolcards/forced-connections']);
				$('html,body').animate({
					scrollTop: $('.all-toolcards').offset().top},
				'slow');
			  }
		} 
	}

  	public gotoSection(section){
  		this.closePopup();
  		$('html,body').animate({
            scrollTop: $('.card-default:nth-child('+section+')').offset().top},
        'slow');
  	}
	
	//delete data from this step modal
	public DataModel(value){
		this.deleteDatavalue = value;
		$('.cn-dmodal').css('display', 'block');
		this.allChallenge.delData.subscribe((data)=> {
			console.log(data);
			if(data) {
				this.deletedata();
				this.allChallenge.delData.next(false)
			}
		})
		// this.modal = this.modalService.open(content, { windowClass: 'in grey' });
	}

	public deletedata(){
		// this.modal.close();
		$('.cn-dmodal').css('display', 'none');
		var that = this;
		this.openEditmode(true,true,true,true,'block','#fff');
		if(this.deleteDatavalue==1){
			try {
				this.api.DeleteFramework({id:this.BtId}).then(function(){
					that.Btupdate = false;
					that.BtId = '';
					that.breakthrough_content = '';
					that.breakthrough_dropdown = 'How might...';
					that.prev_breakthrough_content = '';
					that.edit_breakthrough = false;
					setTimeout(function() {
					  this.toastr.options = {
						  closeButton: true,
						  progressBar: true,
						  timeOut: 2000
					  };
					  this.toastr.success('Your thoughts has been deleted!', 'Nice!');
				  }, 1000);
				})
			} catch (err) {
				console.log(err)
			}
		}
		if(this.deleteDatavalue == 2){
			try {
				this.api.DeleteFramework({id:this.BsId}).then(function(){
					that.Bsupdate = false;
					that.brainstorm_content = [];
					that.checked_brainstorm = [];
					that.prev_brainstorm_content = [];
					that.brainstorm = [0];
					that.BsId = '';
					that.edit_brainstorm = false;
					that.showBrainstorm = false;
					that.delIdeaProposed();
					for(var i=0; i<16;i++){
					  that.brainstorm_content[i] = '';
					  that.brainstorm[i] = '';
				  }
					setTimeout(function() {
					  this.toastr.options = {
						  closeButton: true,
						  progressBar: true,
						  timeOut: 2000
					  };
					  this.toastr.success('Your thoughts has been deleted!', 'Nice!');
				  }, 1000);
				})
			} catch (err) {
				console.log(err)
			}
		}
		if(this.deleteDatavalue == 3){
			try {
				this.api.DeleteFramework({id:this.IpId}).then(function(){  	
					that.brainstorm_content[0] = [];
					that.proposed_content = [];
				  that.proposed_content[0] = [];
				  that.proposed_content[0] = ['Converged Ideas'];
				  that.proposed_content[1] = [];
				  that.proposed_content[2] = [];
				  that.submitBrainstorm();
				  // setTimeout(function() {
			//           this.toastr.options = {
			//               closeButton: true,
			//               progressBar: true,
			//               timeOut: 2000
			//           };
			//           this.toastr.success('Your thoughts has been deleted!', 'Nice!');
			//       }, 1000);
			  })
			} catch (err) {
				console.log(err)
			}
		}
	}  
	public sliderVideo(){
		this.showVideo = !this.showVideo;
	}

	// top ideas diverge
	public topideasDiverge(trackit = 0){
		console.log('topideas diverge')
		this.openEditmode(false,false,true,true);
		if(trackit == 1){
			this.trackLabel= true;
			this.trackbtn = true;
			this.PreviousTracker = 'Develop';
			this.currentTracker = 'Top ideas';
			$('.loader-container').css('display','none');
			if(this.brainstorm_content[0]?.length == 0){
				this.trackLabel= true;
				this.trackbtn = true;
				this.PreviousTracker = 'Develop';
				this.currentTracker = 'Ideas';
				this.BsStart();
				setTimeout(function(){
					that.PreviousTracker = 'Develop';
					that.stickChallenge('ideas');
				},450)
			}
		}else{
			this.PreviousTracker = 'Top ideas';
		}
		var that = this;
		// setTimeout(function(){
		// 	that.stickChallenge();
		// },300)
		setTimeout(function(){
			$(document).scrollTop(0);
		}, 10);
	}

	//close top ideas 
	public closeTopIdeas(trackit = 0) {
		this.trackLabel = false;
		if(trackit == 1){
			this.comefromdevelop = false;
			this.router.navigate(['/challenges/develop']);
		}else{
			this.cancelBreakthrough();
			this.cancelBs();
			this.openEditmode(true,true,true,true,'block','#fff');
		}
		this.pin_challenge= true;
		this.stickChallenge();
	}

	// edit mode functions 
	public eyesHideDiverge() {
		if(this.hideDiv){
			$('.card-body__editcol.hideblk').css('display','block');
			$('.card-body__subtitle span').css('opacity','1');
			this.hideDiv = false;
			this.hideDivLabel = 'Hide unconverged options';
		}else{
			$('.card-body__editcol.hideblk').css('display','none');
			$('.card-body__subtitle span').css('opacity','0.7');
			this.hideDiv = true;
			this.hideDivLabel = 'See all diverged options';
		}
		let all_div_data = document.querySelectorAll('.card-body__editcol');
		for(let item = 0; item<this.brainstorm_content.length;item++){
			if(this.brainstorm_content[item] == ''){
				$(`.card-body__editcol.hideblk:nth-child(${item})`).css('display','none');
				console.log(all_div_data[item])
			}
		}
	}
	//open edit view for goal,data and challenges
	public openEditmode(editchall,editideas,edtitopidea,sidenav=false,displaynone='none',bgcolor="#EBEBEB"){
		this.editChallengeView = editchall;
		this.editIdeasView = editideas;
		this.editTopIdeasView = edtitopidea;
		// let togglemenu = document.querySelector('.trigger-mini-bar') as HTMLElement;
		// let mainnav = document.querySelector('.row.wrapper') as HTMLElement;
		let page = document.getElementById('page-wrapper');
		page.style.backgroundColor = bgcolor;
		let footer = document.querySelector('.footer') as HTMLElement;
		footer.style.backgroundColor = bgcolor;
		footer.style.border = '1px solid #CFDBE2'
		// if(sidenav){
		// 	togglemenu.click();
		// }
		if(editchall && !editideas && !edtitopidea){
			this.challengeInput = false;
			this.ideasInput = false;
			this.EditModeIcon = true;
			// mainnav.style.display = displaynone;
			this.editmodeService.editmode.next(true);
			this.hideOnEditMode = false;
		}else if(editideas  && !editchall && !edtitopidea){
			this.challengeInput = true;
			this.ideasInput = false;
			this.EditModeIcon = true;
			// mainnav.style.display = displaynone;
			this.editmodeService.editmode.next(true);
			this.hideOnEditMode = false;
		}else if(!editideas  && !editchall && edtitopidea){
			this.challengeInput = true;
			this.ideasInput = true;
			this.EditModeIcon = true;
			// mainnav.style.display = displaynone;
			this.editmodeService.editmode.next(true);
			this.hideOnEditMode = false;
		}else{
		console.warn("else block ");
		this.hideOnEditMode = true;
		this.pin_challenge = false;
		this.listGridView = 'listView';
		this.hideOnEditMode = true;
		this.EditModeIcon = false;
		this.editChallengeView = true;
		this.editIdeasView = true;
		this.editChallengeView = true;
		this.challengeInput = false;
		this.ideasInput = false;
		this.eyeIcon = false;
		// mainnav.style.display = displaynone;
		this.editmodeService.editmode.next(false);
		}
	}
	// active icon of list view (we are also used this variable for adding class in view)
	public activeIcon(viewtype){
		this.listGridView = viewtype;
		console.log(this.listGridView)
		if(this.listGridView == 'gridView'){
			let items = document.querySelectorAll('.editmode__listcol');
			this.gridViewClass = 'editmode__listrow--grid' + items.length;
			console.log(items.length);
		}
	}

	// pin challenge
	public stickChallenge(val = ''){
		console.warn(this.pin_challenge)
		this.pin_challenge = !this.pin_challenge;	
		let headDivHight = $('.info.card__headactive').outerHeight();
		let inputDivTop = $('.info.card__headactive').offset()?.top;
		let winHeight = jQuery(window).outerHeight();
		console.log(winHeight - inputDivTop - headDivHight - inputDivTop);
		if(val == 'ideas'){
			if(this.pin_challenge){
				$('.card-body__edit').height(winHeight - headDivHight- inputDivTop - 240 );
				$('.card-body__inner').height('auto');
				// $('.card-body__edit').height(winHeight - inputDivTop - 150 );
			}else{
				$('.card-body__edit').height('auto');
			}
		}else {
			if(this.pin_challenge){
				console.warn("ifblk")
				$('.card-body__inner').height(winHeight - headDivHight- inputDivTop - 165 );
				$('.card-body__edit').height('auto');
			}else{
				console.warn("elseblk")
				$('.card-body__inner').height('auto');
			}
		}
	}

	public notingTodo(){
		console.log('dont have any diverge data');
	}

	public openAi() {
		this.openAiData = [];
		this.selectAiData = [];
		var that = this;
		var all_data:any = ['Identify the root cause of the problem','Develop a range of potential solutions','Select the most effective solution','Implement the chosen solution','Monitor and evaluate the results','Lack of clear plan','Lack of accountability','Setting unrealistic goals','Lack of motivation','Distractions','Lack of focus','Lack of persistence','Lack of clarity','Fear of failure or success','Self-doubt or negative self-talk'];
		$('.popaccordian__gen__aidata').css('display', 'block');
		console.log(typeof(+this.temprature))
		this.temprature = +this.temprature;
		let data = {
			// prompt:`Generate 5 ideas for creative problem solving as ${this.personae}`,
			prompt:`Generate 5 ideas for ${this.breakthrough_dropdown}${this.breakthrough_content} as ${this.personae}`,
			max_tokens:128,
			temperature: this.temprature,
		}
		this.http.post(`${environment.assessment_admin}api/v1/open_ai/completions.json`,data).subscribe(res => {
			console.warn(res['choices'][0])
			for (let i = 0; i < res['choices'][0]?.text.length; i++) {
				this.openAiData = res['choices'][0]?.text.split('\n')
			}
			console.log(this.openAiData)
		}), err=> {
			console.log(err);
			setTimeout(function() {
				this.toastr.options = {
					closeButton: true,
					progressBar: true,
					timeOut: 2000
				};
				this.toastr.error("Something went wrong!", "Sorry!");
			}, 1000);
		}
		// setTimeout(() => {
		// 	that.counter++;
		// 	if(that.counter > 2) {
		// 		that.counter = 0;
		// 	}
		// 	for(let i = that.counter * 5; i < (that.counter+1) * 5 ; i++) {
		// 			that.openAiData.push(all_data[i]);
		// 	}
		// 	console.log(that.openAiData);
		// },1000)
		console.log('open ai',this.openAiData)
	}

	public selectedData() {
		console.warn(this.selectAiData);
		console.warn(this.brainstorm_content);
		let len = this.brainstorm_content.length;
		let aiDataLen = this.openAiData.length;
		// for (let i = 0; i < this.openAiData.length; i++) {
		// 	if(this.selectAiData[i]) {
		// 		this.addBsLine();
		// 		this.selectAiData[i] = false;
		// 		this.brainstorm_content.push(this.openAiData[i]);
		// 	}
		// }
			if(len < 15) {
				for (let j = 0; j < aiDataLen; j++) {
						console.log('if block')
						if(this.selectAiData[j]) { 
							this.addBsLine();
							this.brainstorm_content.push(this.openAiData[j]);
						}
					} 
			} else {
				console.log('else block')
				let ind = this.brainstorm_content.indexOf('');
				for (let j = 0; j < aiDataLen; j++) {
					if(this.selectAiData[j]) {
						this.brainstorm_content[ind + 1] = this.openAiData[j];
						ind++;
					}
				} 
			}
	}
}
