import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { Auth } from '../../auth/auth.service';
// Import the AuthService type from the SDK
// import { AuthService } from '@auth0/auth0-angular';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LambdaFunctionService } from '../../service/lambda-function.service';
import { TeamReportServiceService } from '../team-report/team-report-service.service';

@Component({
  selector: 'app-team-profile',
  templateUrl: './team-profile.component.html',
  styleUrls: ['./team-profile.component.scss']
})
export class TeamProfileComponent implements OnInit {
    id:any;
    members:any;
    teamName:any;
    orgName:any;
    clarifier_low:number;
    clarifier_neutral:number = 0;
    clarifier_high:number;
    ideator_low:number;
    ideator_neutral:number = 0;
    ideator_high:number;
    developer_low:number;
    developer_neutral:number = 0;
    developer_high:number;
    implementor_low:number;
    implementor_neutral:number = 0;
    implementor_high:number;
    integrator:number;
    highest_score:number = 0;
    app_type:any;
    pdf_type:any;
    download_profile:any;
    Email:any;
    today_date:any;
    orgId:any;
    orgData:boolean= false;
    args:any;
    total_members:number;

    constructor(public activatedRoute:ActivatedRoute, public router: Router, public http: HttpClient,public auth:Auth,public lmda_service: LambdaFunctionService,private teamService: TeamReportServiceService) { }

    ngOnInit() {
        this.today_date = new Date();
        this.download_profile = false;

        // get id from url
        this.activatedRoute.params.subscribe((params: Params) => {
            this.id = params.id
        })

        this.auth.getProfile((err, profile) => {
            this.Email = profile.email;
        });

        // this.auth.getUser().subscribe((profile) => {
        //     this.Email = profile.email;
        // })
        console.log(this.router.url.includes('team-profile'))

        if(this.router.url.includes('team-profile')) {
            console.warn("enter in if block")
            // team profile graph
            this.http.get(`${environment.profileURL}/api/v1/teams/`+this.id+`.json`).subscribe(data => {
                console.log(data);
                this.teamName = data['team'].name;
                this.orgName = data['team'].organization_name;
                this.orgId = data['team'].organization_id;
            })
    
            this.http.get(`${environment.profileURL}/api/v1/teams/`+this.id+`/memberships.json?per_page=100`).subscribe(data => {
                console.warn(data);            
                this.members = data['memberships'];  
                this.clarifier_low = 0;
                this.clarifier_high = 0;
                this.ideator_high = 0;
                this.ideator_low = 0;
                this.developer_high = 0;
                this.developer_low = 0;
                this.implementor_high = 0;
                this.implementor_low = 0;
                this.integrator = 0;
                this.highest_score = 0;
                this.showTeam();
            },
                error => console.log('oops', error)
            )
        } else {
            // org profile graph
            $('.graph-loader').css('display', 'block');
            this.orgData = true;
            let totalMem:any = localStorage.getItem('orgGraph');
            totalMem = JSON.parse(totalMem)
            this.total_members = totalMem?.members_count;
            console.warn("enter in else block",this.total_members > 0)
            this.orgName = totalMem.orgName;
            if(this.total_members > 0) {
                this.http.get(`${environment.profileURL}/api/v1/organizations/${this.id}/members.json?per_page=${this.total_members}&last_name_order=asc`).subscribe((data)=> {
                    console.log(data)
                    this.members = data["members"];
                    // this.orgName = data['organization']['name'];
                    this.orgId = this.id;
                    this.clarifier_low = 0;
                    this.clarifier_high = 0;
                    this.ideator_high = 0;
                    this.ideator_low = 0;
                    this.developer_high = 0;
                    this.developer_low = 0;
                    this.implementor_high = 0;
                    this.implementor_low = 0;
                    this.integrator = 0;
                    this.highest_score = 0;
                    this.showTeam();
                });
            } else {
                this.args = {}
                $('.graph-loader').css('display', 'none');
            }
        }

    }

    public printProfile(){
        $('.navbar-default').hide();
        $('body').addClass('print-body');
        window.print();
        $('.navbar-default').show();
        $('body').removeClass('print-body');
    }

    public showTeam(){
        // this.download_profile = false;
        // var that = this;
        // var counter = 0;
        // var groupMembers = [];
        // this.members.forEach(list =>{
        //     groupMembers[counter] = list.member_id;
        //     counter++;
        // });
        // var attrib = {kind: 'team_profile', member_ids: groupMembers};
        // this.http.post(`${environment.profileURL}/api/v1/pdfs/generate.json`,attrib).subscribe(data => {
        //     console.log(data);
        //     that.app_type = 'Team Profile';
        //     that.pdf_type = 'profile';
        //     that.download_profile = true;
        // },
        // err =>{
        //     console.log(err);
        //     setTimeout(function() {
        //         this.toastr.options = {
        //             closeButton: true,
        //             progressBar: true,
        //             timeOut: 2000
        //         };
        //         this.toastr.error(err.error.message, 'Sorry!');
        //     }, 1000);
        // })
        let allProfiles = ['','Clarifier','Ideator','Developer','Implementer','Early-bird','Analyst','Accelerator','Theorist','Driver','Finisher','Hare','Idea-broker','Optimist','Integrator','Realist'];
        var len= this.members?.length;
            var clNeu = [];
            var clLow = [];
            var clHigh = [];
            var idNeu = [];
            var idLow = [];
            var idHigh = [];
            var deNeu = [];
            var deLow = [];
            var deHigh = [];
            var imNeu = [];
            var imLow = [];
            var imHigh = [];
            var integrator = [];
            var date  = new Date();
            var highContast = 0;
            for(let i = 0 ; i < len; i++) {
                // console.warn(this.members[i]);
                if(this.members[i]?.default_profile) {
                    let pf = allProfiles[this.members[i]?.default_profile?.profile_type_id]
                    // console.log(pf)
                    // console.warn(highContast < this.members[i]?.default_profile?.high_contrast)
                    // if(highContast < this.members[i]?.default_profile?.high_contrast) {
                    //     highContast = this.members[i]?.default_profile?.high_contrast;
                    // }
                    
                    if(this.members[i]?.default_profile.clarifier_score == 'High') {
                        clHigh.push(this.members[i]?.default_profile.first_name);
                        if(highContast < clHigh.length) {
                            highContast = clHigh.length;
                        }
                    } else if(this.members[i]?.default_profile.clarifier_score == 'Neutral') {
                        clNeu.push(this.members[i]?.default_profile.first_name);
                        if(highContast < clNeu.length) {
                            highContast = clNeu.length;
                        }
                    } else if(this.members[i]?.default_profile.clarifier_score == 'Low'){
                        clLow.push(this.members[i]?.default_profile.first_name);
                        if(highContast < clLow.length) {
                            highContast = clLow.length;
                        }
                    }

                    if(this.members[i]?.default_profile.ideator_score == 'High') {
                        idHigh.push(this.members[i]?.default_profile.first_name);
                        if(highContast < idHigh.length) {
                            highContast = idHigh.length;
                        }
                    } else if(this.members[i]?.default_profile.ideator_score == 'Neutral') {
                        idNeu.push(this.members[i]?.default_profile.first_name);
                        if(highContast < idNeu.length) {
                            highContast = idNeu.length;
                        }
                    } else if(this.members[i]?.default_profile.ideator_score == 'Low'){
                        idLow.push(this.members[i]?.default_profile.first_name);
                        if(highContast < idLow.length) {
                            highContast = idLow.length;
                        }
                    }
                    
                    if(this.members[i]?.default_profile.developer_score == 'High') {
                        deHigh.push(this.members[i]?.default_profile.first_name);
                        if(highContast < deHigh.length) {
                            highContast = deHigh.length;
                        }
                    } else if (this.members[i]?.default_profile.developer_score == 'Neutral') {
                        deNeu.push(this.members[i]?.default_profile.first_name);
                        if(highContast < deNeu.length) {
                            highContast = deNeu.length;
                        }
                    } else if (this.members[i]?.default_profile.developer_score == 'Low') {
                        deLow.push(this.members[i]?.default_profile.first_name);
                        if(highContast < deLow.length) {
                            highContast = deLow.length;
                        }
                    }
    
                    if(this.members[i]?.default_profile.implementer_score == 'High') {
                        imHigh.push(this.members[i]?.default_profile.first_name);
                        if(highContast < imHigh.length) {
                            highContast = imHigh.length;
                        }
                    } else if(this.members[i]?.default_profile?.implementer_score == 'Neutral') {
                        imNeu.push(this.members[i]?.default_profile.first_name);
                        if(highContast < imNeu.length) {
                            highContast = imNeu.length;
                        }
                    } else if(this.members[i]?.default_profile?.implementer_score == 'Low') {
                        imLow.push(this.members[i]?.default_profile.first_name);
                        if(highContast < imLow.length) {
                            highContast = imLow.length;
                        }
                    }

                    if(this.members[i]?.default_profile.clarifier_score == 'Neutral') {
                        this.clarifier_neutral += 1;
                    }
                    if(this.members[i]?.default_profile.ideator_score == 'Neutral') {
                        this.ideator_neutral += 1;
                    }
                    if(this.members[i]?.default_profile.developer_score == 'Neutral') {
                        this.developer_neutral += 1;
                    }
                    if(this.members[i]?.default_profile.implementer_score == 'Neutral') {
                        this.implementor_neutral += 1;
                    }
                    
                    if(this.members[i]?.default_profile.clarifier_score == 'Neutral' && this.members[i]?.default_profile.ideator_score == 'Neutral' && this.members[i]?.default_profile.developer_score == 'Neutral' && this.members[i]?.default_profile?.implementer_score == 'Neutral') {
                        integrator.push(this.members[i]?.default_profile.first_name);
                        this.integrator += 1
                    }
                }
            }

            // console.log(clLow, idLow, deLow, imLow)
            // console.log(clHigh, idHigh, deHigh, imHigh)
            // console.warn(this.members)
            this.id = this.members[0]?.default_profile?.team_id;
            // this.members = len;
            // if(this.orgData) {
                // this.teamName = this.members[0]?.default_profile?.team_name;
                // this.orgName = this.members[0]?.default_profile?.organization_name;
            // }
            this.clarifier_low= clLow.length;
            this.clarifier_high = clHigh.length;
            this.ideator_low = idLow.length;
            this.ideator_high = idHigh.length;
            this.developer_low = deLow.length;
            this.developer_high = deHigh.length;
            this.implementor_low = imLow.length;
            this.implementor_high = imHigh.length;
            this.integrator = integrator.length;
            this.highest_score = highContast;
            console.warn(this.highest_score)
            this.args = {
                "function_name": "group-profile-dev-pdf",
                "invocation_type": "RequestResponse",
                "log_type": "None",
                "id": this.members[0]?.default_profile?.team_id,
                "template": "group_pf.html",
                "filename": "team-pdf",
                "memberships_count": len,
                "name": this.orgName,
                "organization_title": "Organization",
                "organization_id": this.id,
                "organization_name": this.orgName,
                "clarifier_low": clLow.length,
                "clarifier_neutral": clNeu.length,
                "clarifier_high": clHigh.length,
                "ideator_low": idLow.length,
                "ideator_neutral": idNeu.length,
                "ideator_high": idHigh.length,
                "developer_low": deLow.length,
                "developer_neutral": deNeu.length,
                "developer_high": deHigh.length,
                "implementor_low": imLow.length,
                "implementor_neutral": imNeu.length,
                "implementor_high": imHigh.length,
                "integrator": integrator.length,
                "highest_score": highContast == 0 ? 20 : highContast,
                "app_type": "Group Profile Plus",
                "pdf_type": "letter",
                "email": "sarah@blairmiller.com",
                "today_date": date,
                "content_type": 'pdf',
                "profile_type": "Ask",
                "description": "Look at your graph. What do you notice? What, if anything, is surprising? Where does this group need to pay deliberate attention so they can succeed? Think of a project or a challenge this group has recently been engaged in. Describe how the group approached the project using the group profile as a guide.",
                "footer_imptext": "FourSight® Group Profile",
                "footer_text": " The chart above shows the high and low thinking preferences of your group. Because neutral preferences are hidden, the numbers in each vertical bar may not add up to the group total.",
                "remember": "Preference <span>=</span> Energy",
                "team_des": "FourSight is a measure of thinking preference, not ability. A preference is a measure of energy, not talent. People with a high preference tend to gravitate to that stage of the problem-solving process. People with a low preference tend to move quickly through it. People with a neutral preference tend to do the work and move on. All three approaches can lead to problem-solving success. Teams are more likely to succeed when people are both self-aware and process aware.",
                "footer_des": null,
                "team_profiles": {
                    "clarifier_high": clHigh,
                    "clarifier_neutral": clNeu,
                    "clarifier_low": clLow,
                    "ideator_high": idHigh,
                    "ideator_neutral": idNeu,
                    "ideator_low": idLow,
                    "developer_high": deHigh,
                    "developer_neutral": deNeu,
                    "developer_low": deLow,
                    "implementer_high": imHigh,
                    "implementer_neutral": imNeu,
                    "implementer_low": imLow
                }
            }
            console.warn(this.args)
            $('.graph-loader').css('display', 'none');
    }

    public download_pdf(type= 'pdf') {
        $('.pdf-modal').css('display','block')
        console.log(this.args)
        if(type == 'png') {
            this.args["content_type"] = 'png';
        } else {
            this.args["content_type"] = 'pdf';
        }
        this.lmda_service.lambda_invoke('group-profile-dev-pdf',this.args);
    }

    public accDownload() {
        this.router.navigate(['/account-downloads']);
    }

    public showGraph(){
        this.download_profile = false;
        var that = this;
        var counter = 0;
        var groupMembers = [];
        this.members.forEach(list =>{
            groupMembers[counter] = list.member_id;
            counter++;
        });
        var attrib = {kind: 'graph', member_ids: groupMembers};
        this.http.post(`${environment.profileURL}/api/v1/pdfs/generate.json`,attrib).subscribe(data => {
            console.log(data);
            that.app_type = 'Team Graph';
            that.pdf_type = 'graph';
            that.download_profile = true;
        },
        err =>{
            console.log(err);
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.error(err.error.message, 'Sorry!');
            }, 1000);
        })
    }

    back() {
        window.history.back()
    }

}