import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {CommonDataService} from './../../common-data.service';
// import { Auth } from '../../../auth/auth.service';
import { DatepickerOptions } from 'ng2-datepicker';
import locale from 'date-fns/locale/en-US';
import { getYear } from 'date-fns';
import { environment } from './../../../../environments/environment';

@Component({
	selector: 'app-store-orders',
  	templateUrl: './store-orders.component.html',
  	styleUrls: ['./store-orders.component.scss']
})
export class StoreOrdersComponent implements OnInit {
	public order:any;
	public profile: any;
	public mail:string;
	public data:any;
	public date=null;
	loading:boolean = true;
	// options: DatepickerOptions = {
	// 	minYear: 1970,
	// 	maxYear: 2030,
	// 	formatTitle: 'mm/dd/yyyy',
	// 	format: 'mm/dd/yyyy', // date format to display in input
	// 	firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
	// 	locale: locale, // date-fns locale
  	// };
	options: DatepickerOptions = {
		minYear: getYear(new Date()) - 50, // minimum available and selectable year
		maxYear: getYear(new Date()) + 50, // maximum available and selectable year
		placeholder: '', // placeholder in case date model is null | undefined, example: 'Please pick a date'
		format: "MM/dd/yyyy", // date format to display in input
		formatTitle: 'LLLL YYYYY',
		formatDays: 'EEEEE',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: locale, // date-fns locale
		position: 'bottom',
		inputClass: '', // custom input CSS class to be applied
		calendarClass: 'datepicker-default', // custom datepicker calendar CSS class to be applied
		scrollBarColor: '#dfe3e9', // in case you customize you theme, here you define scroll bar color
	};
	store: any;

  constructor( private http:HttpClient, public service:CommonDataService) { }

  ngOnChanges() {
	console.log('change is occurred')
  }

	ngOnInit() {
		this.date= new Date();
		this.store = localStorage.getItem('store');
    	this.mail = localStorage.getItem('email');
			this.http.get(`${environment.assessment_admin}api/v1/orders?id_order=desc`)
			.subscribe(res => {
				this.loading = false;
				this.order = res['orders'];;
				this.data = this.order;
			},
	      	error => {
	      		this.loading = false;
		      	console.log('oops', error);
		      	if(error.status == 500){
					setTimeout(function() {
						this.toastr.options = {
						  closeButton: true,
						  timeOut: 2000
						};
						this.toastr.error('Something went wrong!', 'Sorry!');
					}, 1000);
				}
				else if(error.status == 422){
					setTimeout(function() {
						this.toastr.options = {
						  closeButton: true,
						  timeOut: 4000
						};
						if(!error.error.errors.base[0]){
							this.toastr.error(error.error.errors, 'oops!');
						}else{
							this.toastr.error(error.error.errors.base[0], 'oops!');
						}
					}, 1000);
				}else{
					setTimeout(function() {
						this.toastr.options = {
						  closeButton: true,
						  timeOut: 2000
						};
						this.toastr.error('Something went wrong!', 'oops!');
					}, 1000);
				}
	      	}
      	)

	}

	public updateFilter(orderId= false, orderStatus = false, orderDate= false)  {
		setTimeout(() => {
			document.querySelector('.bs-datepicker-body')?.addEventListener('click', ()=> {
				console.log('call myfilter')
				setTimeout(() => {
					myfilter();
				}, 100);
			})
			document.querySelector('.bs-datepicker-head')?.addEventListener('click', ()=> {
				document.querySelector('.bs-datepicker-body')?.addEventListener('click', ()=> {
					console.log('call myfilter')
					setTimeout(() => {
						myfilter();
					}, 100);
				})
			})
		}, 500);
		console.warn('update date calling.....',this.date)
		this.order = this.data;
		// if($('.datepicker-container.datepicker-default input').val() == 'Invalid Date'){
		// 	this.date = new Date();
		// }else{
		// 	this.date=$('.datepicker-container.datepicker-default input').val();
		// }
		let myfilter = ()=> {
			console.log('my filter')
			let orderIdValue = $('#order_id').val();
			let orderStatusValue = $('#status').val();
			let datePickerValue = $('#dateValue').val();
			if(orderIdValue && orderId) {
				orderStatusValue = '';
				datePickerValue = '';
			} else if(orderStatusValue && orderStatus) {
				orderIdValue = '';
				datePickerValue = '';
			} else if(datePickerValue && orderDate) {
				orderIdValue = '';
				orderStatusValue = '';
			}
			console.log(orderIdValue, orderStatusValue ,datePickerValue);
			const temp = this.order.filter(function(data) {
				let match_id = data['id'].toString();
				let match_status = data['status'].toString();
				let match_date = data['completed_at'];
				match_date = new Date(match_date);
				var mm = match_date.getMonth()+1;
				var dd = match_date.getDate();
				var yyyy = match_date.getFullYear();
				if(dd<10){
					dd='0'+dd;
				}
				if(mm<10){
					mm='0'+mm;
				}
				match_date = mm+'/'+dd+'/'+yyyy;
				return (match_id.indexOf(orderIdValue) !== -1 || !orderIdValue) && (match_status.toLowerCase().indexOf(orderStatusValue) !== -1 || !orderStatusValue) && ((match_date.indexOf(datePickerValue) !== -1 || !datePickerValue));
			});
			this.order = temp;
		}
		myfilter();

	}
}
