import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APIService } from '../../../API.service';
import { ChallengeSummaryService } from '../../challenge-summary.service';
import { ChallengesComponent } from '../../challenges/challenges.component';

@Component({
  selector: 'app-cn-reset-popup',
  templateUrl: './cn-reset-popup.component.html',
  styleUrls: ['./cn-reset-popup.component.scss']
})
export class CnResetPopupComponent implements OnInit {

  reset_confirm: string = '';

  constructor(public allChallenge: ChallengeSummaryService,private api: APIService,public router: Router, public challengeComponent: ChallengesComponent) { }

  ngOnInit(): void {
    this.deleteConfirm();
  }

  public waytoPDF() { 
    $('.pdf-modal').css('display','block')
    // console.log(window.location);
      localStorage.setItem('pdf_type','allContent');
    // this.router.navigate(['/challenges/summary']);
    this.challengeComponent.arguments('allContent');
    // window.open(window.location.origin + '/challenges/summary','_blank');
  }

  public async deleteConfirm() {
    if(this.reset_confirm == 'RESET'){
      $('.loader-container').css('display','flex');
     await this.waytoPDF()
     setTimeout(() => {   // need 10 sec timer because passing in lambda taking few sec
       this.resetData();
       this.close();
     }, 10000); 
  }
}
  public async resetData(){
    var that = this;
    this.allChallenge.getChallengeIds().then(function (challenge) {
        console.log(challenge);
        for(var i=0; i<challenge.length;i++){
            if(challenge[i]){
                that.api.DeleteFramework({id:challenge[i]}).then(function(){
                    console.log(i, challenge.length);
                })
            }
        }
        console.log(that.router);
        setTimeout(function() {
          this.toastr.options = {
              closeButton: true,
              progressBar: true,
              timeOut: 2000
          };
          this.toastr.success('Your thoughts has been deleted!', 'Nice!');
      }, 1000);
        that.router.navigate(['/challenges/ideate']);
        setTimeout(()=>{
          that.router.navigate(['/challenges/clarify']);
          $('.loader-container').css('display','none');
        },500)
    });
  }

  public resetValue(e) {
    this.reset_confirm = e.target.value;
  }

  public close(){
    $('.reset-modal').css('display','none');
  }

}
