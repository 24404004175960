import { Component, Input, Output, OnChanges, SimpleChanges, OnInit, EventEmitter } from '@angular/core';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-slide-thankyou',
  templateUrl: './slide-thankyou.component.html',
  styleUrls: ['./slide-thankyou.component.scss']
})
export class SlideThankyouComponent implements OnInit {

    @Input() profile:Array<Object>;
    public author;
    public env;
    public profiles : any;
    public description:any;
    @Output() contentChange = new EventEmitter();

    constructor() { }

    ngOnInit() {
        this.env=environment;
        this.author = {
			image: "author-pic.jpg",
            smallLogo: 'foursight-grey-logo-logotype-trans-w160.png',
            logoicon: 'foursight-grey-logo-icon.png',
			logotext: 'foursight-grey-logo-text.png',
			logoFoursight: "foursight-grey-logo-logotype-trans-w846.png"
		};
    }

    public ngOnChanges( changes: SimpleChanges ) : void {

        if ( changes.profile && ! changes.profile.isFirstChange() ) {
        	this.profiles = changes.profile.currentValue;
            this.description=this.profiles.profile_description;
            this.description=this.description.replace("<span class='profile-desc'>",'');
            this.description=this.description.replace('</span>','');

        }

    }

}
